import React, { Component } from 'react'
import { Modal, Spinner } from 'react-bootstrap'
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import { Input } from '../../usefulcomps/HexInput/InputBox'

export default class Lawasondate extends Component {
  constructor(props) {
    const getdate = () => {
      let year = `${new Date().getFullYear()}`,
          month = `${new Date().getMonth()+1}`,
          date = `${new Date().getDate()}`
          if(month.length==1){
            month = '0'+month
          }
          if(date.length == 1){
            date = '0'+date
          }
          return `${year}-${month}-${date}`
    }
    super(props)
    this.state = {
      isComponentRender: false,
      date: getdate()
    }
  }
  componentDidMount() {
    this.setState({ isComponentRender: true })
  }
  render() {
    return (
      <>
        {
          this.state.isComponentRender &&
          <Modal id={'search_options_modal'} size="sm" centered style={{ backgroundColor: 'rgba(0,0,0,0.6)' }} show={true} onHide={() => this.props.onCloseModal()} keyboard={false} backdrop='static'>
            <Modal.Header>
              <div className="header">
                <span>Law as on date</span>
                <IconButton onClick={() => this.props.onCloseModal()}>
                  <CloseIcon />
                </IconButton>
              </div>
            </Modal.Header>
            <Modal.Body style={{ backgroundColor: "white" }}>
              <Input
                htmlFor={"date"}
                label={"Select date"}
                type="date"
                inline={true}
                labelStyle={{ fontSize: "16px", fontWeight: "600" }}
                value={this.state.date}
                onChange={(e) => this.setState({ date: e.target.value })}
              />
              <div className="lawasondate_footer">
                <span onClick={() => this.props.onSubmit(this.state.date)}>Submit</span>
              </div>
            </Modal.Body>
          </Modal>
        }
      </>
    )
  }
}
