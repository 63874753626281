import React, { Component } from 'react'
import Mynavbar from './Mynavbar'
import ContentFrame from './ContentFrame';


export default class Form extends Component {
    constructor(props) {
        super(props)
        this.state = {
            lawData: [
                {
                    name: "GST",
                    link: 'Assets/Data5t/gt/gtcfrm/index_category.htm'
                },
                {
                    name: "CUSTOM",
                    link: 'Assets/Data5t/cu/cuform/index.htm'
                },
                {
                    name: "EXCISE",
                    link: 'Assets/Data5t/ce/ceform/index.htm'
                },
                {
                    name: "FEMA",
                    link: 'Assets/Data5t/rb/rbform/index.htm'
                },
                {
                    name: "SERVICE TAX",
                    link: 'Assets/Data5t/st/stform/index.htm'
                },
            ],
            openCustomFrame: false,
            targetFile: '',
            subfolder: '', mainFolder: '',
            isOpenPPTModal: false
        }
    }
    onFormClick(child) {
        this.setState({ targetFile: child.link, openCustomFrame: true })
        let childName = child.name.toLowerCase()
        console.log(childName)
        if(childName == "gst"){
            this.setState({ subfolder: "gtcfrm", mainFolder: 'gt' })
        }else if(childName == "custom"){
            this.setState({ subfolder: "cuform", mainFolder: 'cu' })
        }else if(childName == "excise"){
            this.setState({ subfolder: "ceform", mainFolder: 'ce' })
        }else if(childName == "fema"){
            this.setState({ subfolder: "rbform", mainFolder: 'rb' })
        }else if(childName == "service tax"){
            this.setState({ subfolder: "stform", mainFolder: 'st' })
        }
    }
    render() {
        console.log(this.state.subfolder)
        return (
            <>
                <div>
                    <Mynavbar
                        goBack={() => this.props.goBack()}
                        searchapi={this.props.searchApi}
                        header={'Form'}
                        rootUrl={this.props.rootUrl}
                        userid={this.props.userid}
                        goHome={() => this.props.goBack()}
                    />
                    <div className="mainBox">
                        <div className="row">
                            {
                                this.state.lawData.map((data, index) => {
                                    return (
                                        <div className="col-md-2 col-12 text-center" key={index}>
                                            <span style={{ fontSize: "18px", fontWeight: "600", cursor: "pointer" }} onClick={() => this.onFormClick(data)}>{data.name}</span>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
                {
                    this.state.openCustomFrame ?
                        <ContentFrame
                            goBack={() => this.setState({ openCustomFrame: false })}
                            filepath={this.state.targetFile}
                            searchApi={this.props.searchApi}
                            rootUrl={this.props.rootUrl}
                            userid={this.props.userid}
                            MainFolder={this.state.mainFolder}
                            SubFolder={this.state.subfolder}
                            goHome={() => this.props.goBack()}
                        // header={'Form'}
                        />
                        : null
                }
            </>
        )
    }
}
