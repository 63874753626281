import React, { Component } from 'react'
import Backdrop from '@mui/material/Backdrop';
import './magicwond.css'
import Tooltip from '@mui/material/Tooltip';
import FAQIcon from '../../images/magicwond/faq.png'
import InterpretationIcon from '../../images/magicwond/interpretation.png'
import SummaryPPtIcon from '../../images/magicwond/ppt.png'
import IllustrationIcon from '../../images/magicwond/illustration.png'
import DictionaryIcon from '../../images/magicwond/dictionary.png'
import FlowchartIcon from '../../images/magicwond/flowchart.png'
import ComparelawsIcon from '../../images/magicwond/comparelaws.png'
import CaselawsIcon from '../../images/magicwond/caselaws.png'
import Calendar from '../../images/magicwond/calendar.png';
import NotificationIcon from '../../images/magicwond/notification.png'
import ExclamationIcon from '../../images/magicwond/caution.png'
import BookmarkfillIcon from '../../images/magicwond/bookmark_fill.png'
import BookmarkemptyIcon from '../../images/magicwond/bookmark_empty.png'
import NotesIcon from '../../images/magicwond/notes.png'
import Hexapi from '../../usefulcomps/HexAPI/Hexapi';
import Notes from './Notes'
import Summary from './Summary'
import Magicwondframes from './Magicwondframes';
import Comparelaws from './Comparelaws';
import CaselawExclamation from './CaselawExclamation2';
import Searched from './Searched'
import { Modal, Spinner } from 'react-bootstrap'
import Lawasondate from './Lawasondate';

export default class Magicwond extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isComponentRender: false, userid: '', filepath: '', isnoti: false,
            isBookmarkAddComplete: false, pagedata: {}, header: '', isOpenClExclamation: false,
            isReferedByCaseStatus: false, fileData: [], isLoading: false,
            iconsList: [
                {
                    title: 'FAQ',
                    icon: FAQIcon,
                    side: "L",
                    disable: true,
                    key: 'faq'
                },
                {
                    title: 'Interpretation',
                    icon: InterpretationIcon,
                    side: "L",
                    disable: true,
                    key: 'comentry'
                },
                {
                    title: 'Summary',
                    icon: SummaryPPtIcon,
                    side: "L",
                    disable: true,
                    key: 'summary'
                },
                {
                    title: 'Illustration',
                    icon: IllustrationIcon,
                    side: "L",
                    disable: true,
                    key: 'illustration'
                },
                // {
                //     title: 'Legal Dictionary',
                //     icon: DictionaryIcon,
                //     side: "L",
                //     disable: true,
                //     key: 'dictionary'
                // },
                {
                    title: 'Flow Chart',
                    icon: FlowchartIcon,
                    side: "L",
                    disable: true,
                    key: 'ppt'
                },
                // {
                //     title: 'Compare Laws',
                //     icon: ComparelawsIcon,
                //     side: "L",
                //     disable: false,
                //     key: 'compare law'
                // },
                {
                    title: 'Laws as on date',
                    icon: Calendar,
                    side: "L",
                    disable: false,
                    key: 'laws as on date'
                },
                // {
                //     title: 'Case Law',
                //     icon: CaselawsIcon,
                //     side: "L",
                //     disable: true,
                //     key: 'caselaw'
                // },
                {
                    title: 'Notification/Circular/Press-release',
                    icon: NotificationIcon,
                    side: "L",
                    disable: true,
                    key: 'notification'
                },
                // {
                //     title: "Exclamation",
                //     icon:ExclamationIcon,
                //     side:'L',
                //     disable:true,
                //     key:'exclamation'
                // },
                // {
                //     title: 'Remove Bookmark',
                //     icon: BookmarkfillIcon,
                //     side: "R",
                //     disable: true,
                //     key: '1'
                // },
                // {
                //     title: 'Add Bookmark',
                //     icon: BookmarkemptyIcon,
                //     side: "R",
                //     disable: true,
                //     key: '0'
                // },
                {
                    title: 'Add Note',
                    icon: NotesIcon,
                    side: "R",
                    disable: true,
                    key: 'note'
                },
            ],
            isOpenNotesModal: false, usernotes: '', isOpenSummaryModal: false, summaryData: null, isOpenMagicwondFrame: false, frameData: null,
            isOpenCompareLaws: false, isOpenLawAsOnDateModal: false,
        }
    }
    componentDidMount() {
        console.log(this.props.pageData)
        let pageData = this.props.pageData
        let listOptions = this.state.iconsList
        let keys = Object.keys(pageData)
        for (let key of keys) {
            if (key == "") {
                key = pageData[key][0].bookmark
            }
            listOptions.forEach((item) => {
                if (key == item.key) {
                    console.log(key, item.key)
                    item.disable = false
                }
            })
        }
        console.log(listOptions)
        this.setState({ userid: this.props.userid, filepath: this.props.filepath, pagedata: pageData, isComponentRender: true })
    }

    setLegalDict() {
        console.log('hello')
        this.setState({ isLoading: true })
        let obj = {
            query: `[dbo].[Lawcrux_Web_Proc_Dictionary_GetList] @UserID='{0}'`,
            queryArr: [`${this.props.userid}`]
        }
        Hexapi(obj).then(resp => {
            console.log(resp)
            // this.setState({ wordList: resp['']})
            let arr = []
            resp[''].forEach((el) => arr.push(el.word))
            let obj = {
                // filename: ['explanation','extract','obvious','offence'].join('##12##'),
                filename: arr.join('##12##'),
                url: 'findfilename.php'
            }
            Hexapi(obj).then(resp => {
                console.log(resp)
                this.props.onLegalDictClick(resp)
            }).then(() => this.setState({ isLoading: false }))
        }).catch(err => console.error(err))
    }

    openRespectiveScreen(icon, e) {
        e.stopPropagation()
        if (icon.disable === false && icon.key != "compare law" && icon.key != "laws as on date") {
            let data = this.state.pagedata[icon.key][0],
                keys = Object.keys(this.state.pagedata)
            console.log(icon.key, data)
            if (icon.key == 'note') {
                if (data.command == 1) {
                    this.setState({ usernotes: data.note })
                }
                this.setState({ isOpenNotesModal: true })
            }
            if (icon.key == 'summary' || icon.key == 'ppt') {
                // console.log(data)
                if (data.filename != undefined && data.filename.includes('.htm')) {
                    let multipleframes = {}
                    multipleframes[icon.key] = data
                    if (keys.includes('summary')) {
                        multipleframes['summary'] = this.state.pagedata["summary"][0]
                    }
                    this.setState({ isOpenMagicwondFrame: true, header: icon.title, frameData: multipleframes })
                } else {
                    this.setState({ summaryData: data, header: icon.title, isOpenSummaryModal: true })
                }
            }

            if (icon.key == "dictionary") {
                // console.log(icon)
                this.setLegalDict()
            }

            if (icon.key == 'faq' || icon.key == 'caselaw' || icon.key == 'illustration' || icon.key == 'comentry') {
                let obj = {}
                obj[icon.key] = data
                this.setState({ isOpenMagicwondFrame: true, isnoti: false, header: icon.title, frameData: obj })
            }
           

            if (icon.key == 'notification') {
                let multipleframes = {}
                multipleframes[icon.key] = data
                if (keys.includes('circular')) {
                    multipleframes['circular'] = this.state.pagedata["circular"][0]
                }
                if (keys.includes('press release')) {
                    multipleframes['press release'] = this.state.pagedata["press release"][0]
                }
                this.setState({ isOpenMagicwondFrame: true, isnoti: true, header: icon.title, frameData: multipleframes })
            }
        }
        if (icon.key == 'compare law') {
            this.setState({ isOpenCompareLaws: true })
        }
    }
    render() {
        return (
            <>
                <Backdrop
                    style={{
                        color: "green",
                        // backgroundColor: "rgb(240,230,0,0.5)",
                        position: "absolute",
                        zIndex: (theme) => theme.zIndex.drawer - 1,
                        opacity: 1
                    }}
                    open={this.state.isComponentRender}
                    onClick={() => this.setState({ isComponentRender: false }, () => this.props.onCloseModal())}
                >
                    <div className="magicwond_maindiv">
                        <div className="icons_list">
                            {
                                this.state.iconsList.map((icon, index) => {
                                    if (icon.side === "L") {
                                        return (
                                            <Tooltip title={icon.title} placement="right">
                                                <div className={`img_outer_div ${icon.disable ? 'disabled' : ''}`} onClick={(e) => this.openRespectiveScreen(icon, e)}>
                                                    <img src={icon.icon} alt="" />
                                                </div>
                                            </Tooltip>
                                        )
                                    }
                                })
                            }
                            {
                                this.props.filepath.includes('/cl/') &&
                                <Tooltip title={'Status'} placement="left">
                                    <div className="img_outer_div" onClick={(e) => {
                                        e.stopPropagation()
                                        this.setState({ isOpenClExclamation: true })
                                    }}>
                                        <img src={ExclamationIcon} alt="" />
                                    </div>
                                </Tooltip>
                            }

                        </div>
                        <div className="icons_list">
                            {
                                this.state.iconsList.map((icon, index) => {
                                    if (icon.side === "R" && icon.disable === false) {
                                        return (
                                            <Tooltip title={icon.title} placement="left">
                                                <div className="img_outer_div" onClick={(e) => this.openRespectiveScreen(icon, e)}>
                                                    <img src={icon.icon} alt="" />
                                                </div>
                                            </Tooltip>
                                        )
                                    }
                                })
                            }
                        </div>
                    </div>
                </Backdrop>
                {
                    this.state.isOpenNotesModal &&
                    <Notes
                        onCloseModal={() => this.setState({ isOpenNotesModal: false })}
                        filedetails={{ filepath: this.state.filepath, userid: this.state.userid }}
                        oldNote={this.state.usernotes}
                        onSaveNote={() => this.setState({ isOpenNotesModal: false, isComponentRender: false }, () => this.props.fetchPageData())}
                    />
                }
                {
                    this.state.isOpenSummaryModal &&
                    <Summary
                        onCloseModal={() => this.setState({ isOpenSummaryModal: false })}
                        summaryData={this.state.summaryData}
                        header={this.state.header}
                    />
                }
                {
                    this.state.isOpenLawAsOnDateModal &&
                    <Lawasondate
                        onCloseModal={() => this.setState({ isOpenLawAsOnDateModal: false })}
                        onSubmit={(date) => this.setLawAsOnDate(date)}
                    />
                }
                {
                    this.state.isOpenMagicwondFrame &&
                    <Magicwondframes
                        onCloseModal={() => this.setState({ isOpenMagicwondFrame: false })}
                        data={this.state.frameData}
                        header={this.state.header}
                        isnoti={this.state.isnoti}
                        // pageData={this.props.pageData}
                        userid={this.props.userid}
                        isReferedByCaseStatus={this.state.isReferedByCaseStatus}
                        fileData={this.state.fileData}
                        openHistoryFile={(list) => this.setState({ isOpenMagicwondFrame: false }, () => this.props.openHistoryFile(list))}
                    // fetchPageData={() => this.setState({ isMagicWondOpen: false }, () => this.getpagedata(this.state.selectedFileDetails))}
                    />
                }
                {
                    this.state.isOpenCompareLaws &&
                    <Comparelaws
                        onCloseModal={() => this.setState({ isOpenCompareLaws: false })}
                    />
                }
                {
                    this.props.filepath.toLowerCase().includes('/cl/') &&
                    <div style={this.state.showcaseDemo == true ?{position:'relative',zIndex:'4',backgroundColor:'white',pointerEvents:'none'}:{}}>
                        <CaselawExclamation
                            status={this.state.pageData}
                            onCloseModal={() => this.setState({ isOpenClExclamation: false })}
                            userid={this.props.userid}
                            openFrame={(li) => this.setState({ isReferedByCaseStatus: true, fileData: li, isOpenSearchModal: true })}
                        />
                    </div>
                }

                <Modal fullscreen={true} id="documentmodal" animation={true} show={this.state.isOpenSearchModal} onHide={() => this.setState({ isOpenSearchModal: false })}>
                    <Modal.Body>
                        <Searched
                            searchApi={this.props.searchApi}
                            rootUrl={this.props.rootUrl}
                            goBack={() => this.setState({ isOpenSearchModal: false, isReferedByCaseStatus: false })}
                            userid={this.props.userid}
                            isReferedByCaseStatus={this.state.isReferedByCaseStatus}
                            caseStatusData={this.state.fileData}
                            goHome={() => this.props.goBack()}
                        />
                    </Modal.Body>
                </Modal>


                {/* Timer Modal */}
                <Modal scrollable={true} size="sm" centered style={{ backgroundColor: 'rgba(0,0,0,0.6)' }} animation={true} show={this.state.isLoading} onHide={() => this.setState({ isLoading: false })} keyboard={false} backdrop='static'>
                    <Modal.Body>
                        <div className="timer" style={{ display: 'flex', justifyContent: 'center', alignItems: "center", flexDirection: "column", minHeight: "15vh" }}>
                            <Spinner animation="border" variant="dark" />
                            <span id="loadingTimer">
                                Loading...
                            </span>
                        </div>
                    </Modal.Body>
                </Modal>
            </>
        )
    }
}
