import React, { Component } from 'react'
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Aesencryption, Aesdecryption } from '../../usefulcomps/Aes/Aes'
import { Table, Spinner } from 'react-bootstrap'
import Hexapi from '../../usefulcomps/HexAPI/Hexapi'
import TimeFormatter from '../../usefulcomps/TimeFormatter'
import HomeIcon from '@mui/icons-material/Home';

var oldData = []
export default class MyaccountTable extends Component {
    constructor(props) {
        super(props)
        this.state = {
            headers: ['RecID','MemebershipID','Username', 'Name','GST No.', 'Address', 'City', 'State', 'EmailID', 'Contact Number', 'Validity Date'],
            isApiHitComplete: false, isTableDataFound: false, tableData: [],
            search: ''
        }
        this.redirect = this.redirect.bind(this)
        this.setDate = this.setDate.bind(this)
        this.getHeader = this.getHeader.bind(this)
        this.storeSearch = this.storeSearch.bind(this)
    }
    setDate(logtime) {
        if (logtime.date != undefined) {
            let dateObj = TimeFormatter(logtime.date)
            return `${dateObj.userSplitedDate[2]} ${dateObj.shiftFullMonth} ${dateObj.userSplitedDate[0]}`
        }
    }
    getHeader(headers) {
        return headers.map((head) => {
            return (
                <th key={head} style={{ textAlign: "left" }}>{head}</th>
            )
        })
    }
    setRowsFromHeaders(data) {
        return (
            <>
                <td style={{textAlign:"center" }}>{data.recid}</td>
                <td style={{textAlign:"center" }}>{data.membershipid}</td>
                <td>{data.username}</td>
                <td>{data.firstname + ' ' + data.lastname}</td>
                <td>{data.gstno}</td>
                <td>{data.address}</td>
                <td>{data.city}</td>
                <td>{data.state}</td>
                <td>{data.emailid}</td>
                <td>{data.contactnumber}</td>
                <td>{this.setDate(data.validity)}</td>
            </>
        )
    }
    redirect(recid) {
        let queryParameters = Aesencryption(`sessionuserid=${this.props.userid}&userid=${recid}&apipathurl=${sessionStorage.getItem('Apipathurl')}`)
        window.top.location.href = `${sessionStorage.getItem('url')}memberform?${queryParameters}`
    }
    componentDidMount() {
        this.setState({ isApiHitComplete: false, isTableDataFound: false })
        let obj = {
            'query': `[dbo].[Lawcrux_Web_Proc_User_GetList] @RecID='{0}'`,
            'queryArr': [`${this.props.userid}`]
        }

        Hexapi(obj).then(resp => {
            console.log(resp)
            if (resp[''].length > 0) {
                this.setState({
                    tableData: resp[''],
                    isTableDataFound: true
                })
                oldData = resp['']
            } else {
                this.setState({
                    isTableDataFound: false
                })
            }

        }).then(resp => this.setState({ isApiHitComplete: true })).then(err => console.error(err))
    }
    storeSearch(e) {
        this.setState({ isSearchValue: true, search: e.target.value })
        var value = e.target.value;
        if (value == '') {
            this.setState({ tableData: oldData, isTableDataFound: true })
        } else {
            let applyFilter = []
            applyFilter = Object.keys(oldData[0])
                if ((oldData.filter((data) => {
                  if (Array.isArray(applyFilter)) {
                    let StrArr = []
                    for (let i = 0; i < applyFilter.length; i++) {
                      StrArr.push(data[`${applyFilter[i].toLowerCase()}`])
                    }
                    return StrArr.join(' ').toString().toLowerCase().includes(value.toLowerCase())
                  } else {
                    return (data[`${applyFilter[0].toLowerCase()}`]).toString().toLowerCase().includes(value.toLowerCase())
                  }
                })).length > 0) {
                  this.setState({
                    tableData: oldData.filter(data => {
                      if (Array.isArray(applyFilter)) {
                        let StrArr = []
                        for (let i = 0; i < applyFilter.length; i++) {
                          StrArr.push(data[`${applyFilter[i].toLowerCase()}`])
                        }
                        return StrArr.join(' ').toString().toLowerCase().includes(value.toLowerCase())
                      } else {
                        return (data[`${applyFilter[0].toLowerCase()}`]).toString().toLowerCase().includes(value.toLowerCase())
                      }
                    })
                    , isTableDataFound: true
                  }, () => {
                    
                  })
                } else {
                  console.log('sdflksdfl')
                  this.setState({ isTableDataFound: false })
                }
              
        }
    }
    render() {
        return (
            <>
                <div className="navbar">
                    <div className="leftdiv" style={{width:"auto"}}>
                        <Tooltip title="Go back">
                            <IconButton onClick={() => this.props.goBack()}>
                                <ArrowBackIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Go Home">
                            <IconButton onClick={() => this.props.goBack()}>
                                <HomeIcon />
                            </IconButton>
                        </Tooltip>
                    </div>
                    <div className="rightdiv">
                        <input style={{ marginRight: "10px", outline: "1px solid var(--borderColor)" }} type="text" placeholder='Search...' value={this.state.search} id='todolistsearchbox' className="form-control" onChange={(e) => this.storeSearch(e)} autoFocus={true} />
                        <Tooltip title="Add Member">
                            <IconButton onClick={() => this.redirect('-1')}>
                                <AddIcon />
                            </IconButton>
                        </Tooltip>
                    </div>
                </div>
                <div className="tableBox">
                    {
                        this.state.isApiHitComplete ?
                            this.state.isTableDataFound ?
                                <Table responsive id="myReportTable" >
                                    <thead>
                                        <tr style={{ background: "var(--Primary)", whiteSpace: "nowrap", border: "none", color: "white", position: "sticky", top: "0px", zIndex: '1' }}>
                                            {this.getHeader(this.state.headers)}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.state.tableData.map((data, index) => {
                                                return (
                                                    <>
                                                        <tr key={index} style={{ whiteSpace: "nowrap" }} onClick={() => this.redirect(data.recid)}>
                                                            {this.setRowsFromHeaders(data)}
                                                        </tr>
                                                    </>
                                                )
                                            })
                                        }
                                    </tbody>
                                </Table>
                                :
                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: '100%', height: "91vh" }}>
                                    <h5 style={{ color: 'orange' }}>No Record Found!</h5>
                                </div>
                            :
                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: '100%', height: "91vh" }}>
                                <Spinner animation="border" variant="dark" />
                            </div>
                    }
                </div>
            </>
        )
    }
}
