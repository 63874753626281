import React, { Component } from 'react'
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import './hsn.css'
import { Input } from '../../usefulcomps/HexInput/InputBox'
import { Spinner, Modal } from 'react-bootstrap';
import TreeView from '@mui/lab/TreeView';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TreeItem from '@mui/lab/TreeItem';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Hexapi from '../../usefulcomps/HexAPI/Hexapi';
import axios from 'axios'
import $ from 'jquery'
import Mark from 'mark.js'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Countdown from 'react-countdown';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import PrintIcon from '@mui/icons-material/Print';

import { Aesdecryption, Aesencryption } from '../../usefulcomps/Aes/Aes';
const rename = (name) => {
    let arr = [
        { key: 'bu', replace: 'Budget' },
        { key: 'sp', replace: 'specimen' },
        { key: 'ce', replace: 'central excise' },
        { key: 'cl', replace: 'case law' },
        { key: 'cu', replace: 'custom' },
        { key: 'di', replace: 'dictionary' },
        { key: 'fl', replace: 'flowchart' },
        { key: 'ft', replace: 'foreign trade policy' },
        { key: 'gt', replace: 'GST' },
        { key: 'st', replace: 'service tax' },
        { key: 'sz', replace: 'SEZ' },
        { key: 'rb', replace: 'Fema' },
        { key: 'hn', replace: 'Headnote (Summary of Case Law)' },
        { key: 'cutar2', replace: '8 Digit' },
        { key: 'gtrate', replace: '4 Digit' },
    ]
    let newName = -1
    arr.forEach((list) => {
        if (list.key == name) {
            newName = list.replace
        }
    })
    if (newName != -1) {
        return newName
    } else {
        return name
    }
}

// Google Api key - AIzaSyB5xHRaQ4NOzM1w-GpoUEQQ8LiRt3RrwiY

export default class Hsnsearch extends Component {
    constructor(props) {
        super(props)
        this.state = {
            hsnsearchurl: sessionStorage.getItem('hsnsearchurl'),
            hsnsearch: '', hsnsearchvalue: "",
            isOptionModalOpen: false,
            isComponentRender: false,
            isApiHitComplete: false,
            isDataFound: false, totalFilesCount: 0, treeData: [], selectedFile: '', isFetchFileApiHitComplete: false,
            isShowRightBox: false, htmlFileContent: "", allFiles: [], isFileTitleFetched: true, isFileTitleFound: false,
            isTimerModal: false, isOpenInHouseDevelopment: false, InhouseFilepath: '', isShowLeftBox: true, isSortEnable: true,
            isOpenInIframe: false,
            searchResult: [],
            isrender: false,
        }
    }

    //     <script async src="https://cse.google.com/cse.js?cx=a12a2f5b51b514aa5">
    // </script>
    //     <div class="gcse-search"></div>

    onSearch = (e) => {
        // console.log(query);
        console.log(this.state.hsnsearch)

        let obj2 = {
            'query': `[dbo].[Lawcrux_Web_Proc_HSN_Getdata] @UserID='{0}',@Word='{1}'`,
            'queryArr': [`${sessionStorage.getItem('userid')}`, `${this.state.hsnsearch}`]
        }
        Hexapi(obj2).then(res => {
            console.log(res)
            if (res[""][0].command == 0) {
                this.findhsn();
            } else {
                this.setState({ isOptionModalOpen: true })
                const code = res[""][0].hsncode.toString()
                this.setState({ hsnsearch: code})
                this.onHsnSearch(code);
            }
        });

        // const options = {
        //     method: 'GET',
        //     url: 'https://google-search74.p.rapidapi.com/',
        //     params: {
        //         query: `hsncodeof${this.state.hsnsearch}`,
        //         limit: '10',
        //         related_keywords: 'true'
        //     },
        //     headers: {
        //         'X-RapidAPI-Key': '54dd9fe86amsh02e44b9b0759090p11cea3jsnff4eb6576538',
        //         'X-RapidAPI-Host': 'google-search74.p.rapidapi.com'
        //     }
        // };

        // try {
        //     const response = await axios.request(options);
        //     console.log(response.data);
        // } catch (error) {
        //     console.error(error);
        // }

    }


    findhsn = async () => {
        try {
            const resp = await axios.get(
                `https://www.googleapis.com/customsearch/v1?q=hsn code of ${this.state.hsnsearch} &cx=a12a2f5b51b514aa5&key=AIzaSyBliRBSpSkRJw3YbqCidLijmloSnUzaJjY`
            )
            let hsnRegex = "";
            if (this.state.hsnsearch.toLowerCase().includes('service')) {
                hsnRegex = /\b\d{4,8}\b/g;
            }
            else {
                hsnRegex = /\b\d{4,8}\b/g;
            }

            let matchedNumbers = [];
            const hsnCounts = new Map();


            resp.data.items.forEach(text => {
                console.log(text);
                let cleanText = "";

                if (typeof text.title === 'string' && text.title.toLowerCase().includes(this.state.hsnsearch)) {
                    const dateRegex = /[A-Z][a-z]{2} \d{1,2}, \d{4}/g;
                    cleanText = text.title.replace(dateRegex, '');
                    console.log(cleanText);
                }

                const matches = cleanText.match(hsnRegex);
                if (matches && matches.length > 0) {
                    matches.forEach(match => {
                        let hsn = match.trim();
                        hsn = hsn.slice(0, 4)
                        if (hsnCounts.has(hsn)) {
                            hsnCounts.set(hsn, hsnCounts.get(hsn) + 1);
                        } else {
                            hsnCounts.set(hsn, 1);
                        }
                    });
                }
            });

            resp.data.items.forEach(text => {
                // console.log(text);
                let cleanText = "";

                if (typeof text.htmlTitle === 'string' && text.htmlTitle.toLowerCase().includes(this.state.hsnsearch)) {
                    const dateRegex = /[A-Z][a-z]{2} \d{1,2}, \d{4}/g;
                    cleanText = text.htmlTitle.replace(dateRegex, '');
                    console.log(cleanText);
                }

                const matches = cleanText.match(hsnRegex);
                if (matches && matches.length > 0) {
                    matches.forEach(match => {
                        let hsn = match.trim();
                        hsn = hsn.slice(0, 4)
                        if (hsnCounts.has(hsn)) {
                            hsnCounts.set(hsn, hsnCounts.get(hsn) + 1);
                        } else {
                            hsnCounts.set(hsn, 1);
                        }
                    });
                }
            });

            resp.data.items.forEach(text => {
                // console.log(typeof text.htmlSnippet)

                let cleanText = "";
                if (typeof text.htmlSnippet === 'string' && text.htmlSnippet.toLowerCase().includes(this.state.hsnsearch)) {
                    const dateRegex = /[A-Z][a-z]{2} \d{1,2}, \d{4}/g;
                    cleanText = text.htmlSnippet.replace(dateRegex, '');
                    console.log(cleanText);
                }
                const matches = cleanText.match(hsnRegex);
                if (matches && matches.length > 0) {
                    matches.forEach(match => {
                        let hsn = match.trim();
                        hsn = hsn.slice(0, 4)
                        if (hsnCounts.has(hsn)) {
                            hsnCounts.set(hsn, hsnCounts.get(hsn) + 1);
                        } else {
                            hsnCounts.set(hsn, 1);
                        }
                    });
                }
            });



            // console.log(matchedNumbers);
            // if (matchedNumbers.length <= 0) {
            resp.data.items.forEach(text => {
                // console.log(text);
                let cleanText = "";
                if (typeof text.snippet === 'string' && text.snippet.toLowerCase().includes(this.state.hsnsearch)) {
                    const dateRegex = /[A-Z][a-z]{2} \d{1,2}, \d{4}/g;
                    cleanText = text.snippet.replace(dateRegex, '');
                    console.log(cleanText);
                }
                let matches = cleanText.match(hsnRegex);
                // const matches = Array.from(text.snippet.matchAll(hsnRegex), match => match[0]);
                console.log(matches)
                if (matches && matches.length > 0) {
                    matches.forEach(match => {
                        let hsn = match.trim();
                        hsn = hsn.slice(0, 4)
                        if (hsnCounts.has(hsn)) {
                            hsnCounts.set(hsn, hsnCounts.get(hsn) + 1);
                        } else {
                            hsnCounts.set(hsn, 1);
                        }
                    });
                }

            });

            console.log(hsnCounts);
            let cnt = 0, hsncodeofproduct = "";

            // if (this.state.hsnsearch.toLowerCase().includes('service')) {
            //     console.log('service');

            //     // const matches = text.match(regex);
            //     hsnCounts.forEach((count, hsn) => {
            //         let hsncode = hsn;
            //         hsncode = hsncode.slice(0, 2);
            //         if (hsncode.includes('99')) {
            //             if (hsncodeofproduct < hsn) {
            //                 hsncodeofproduct = hsn;

            //             }
            //             console.log(hsn)
            //         }
            //     })

            // } else {
            // console.log('sjkwjsx');

            hsnCounts.forEach((count, hsn) => {
                if (count == cnt && hsn > hsncodeofproduct) {
                    cnt = count;
                    hsncodeofproduct = hsn;
                }
                else if (count > cnt) {
                    cnt = count;
                    hsncodeofproduct = hsn;
                }
            });
            // }
            console.log(hsncodeofproduct)

            if (hsncodeofproduct == "") {
                console.log("lkxsjndbc")
                resp.data.items.forEach(text => {
                    // console.log(text);
                    let cleanText = "";
                    if (typeof text.title === 'string') {
                        const dateRegex = /[A-Z][a-z]{2} \d{1,2}, \d{4}/g;
                        cleanText = text.title.replace(dateRegex, '');
                        // console.log(cleanText);
                    }
                    const matches = cleanText.match(hsnRegex); // Use match() method to find matches
                    if (matches && matches.length > 0) {
                        matches.forEach(match => {
                            let hsn = match.trim();
                            hsn = hsn.slice(0, 4)
                            if (hsnCounts.has(hsn)) {
                                hsnCounts.set(hsn, hsnCounts.get(hsn) + 1);
                            } else {
                                hsnCounts.set(hsn, 1);
                            }
                        });
                    }

                    // Code for Snippet
                    let cleanText1 = "";
                    if (typeof text.snippet === 'string') {
                        const dateRegex = /[A-Z][a-z]{2} \d{1,2}, \d{4}/g;
                        cleanText1 = text.snippet.replace(dateRegex, '');
                    }
                    let matches1 = cleanText1.match(hsnRegex);

                    if (matches1 && matches1.length > 0) {
                        matches1.forEach(match => {
                            let hsn = match.trim();
                            hsn = hsn.slice(0, 4)
                            if (hsnCounts.has(hsn)) {
                                hsnCounts.set(hsn, hsnCounts.get(hsn) + 1);
                            } else {
                                hsnCounts.set(hsn, 1);
                            }
                        });
                    }

                    // Code for htmlTitle
                    let cleanText2 = "";
                    if (typeof text.htmlTitle === 'string') {
                        const dateRegex = /[A-Z][a-z]{2} \d{1,2}, \d{4}/g;
                        cleanText2 = text.htmlTitle.replace(dateRegex, '');
                    }
                    let matches2 = cleanText2.match(hsnRegex);

                    if (matches2 && matches2.length > 0) {
                        matches2.forEach(match => {
                            let hsn = match.trim();
                            hsn = hsn.slice(0, 4)
                            if (hsnCounts.has(hsn)) {
                                hsnCounts.set(hsn, hsnCounts.get(hsn) + 1);
                            } else {
                                hsnCounts.set(hsn, 1);
                            }
                        });
                    }

                    // Code for htmlSnippet
                    let cleanText3 = "";
                    if (typeof text.htmlSnippet === 'string') {
                        const dateRegex = /[A-Z][a-z]{2} \d{1,2}, \d{4}/g;
                        cleanText3 = text.htmlSnippet.replace(dateRegex, '');
                    }
                    let matches3 = cleanText3.match(hsnRegex);

                    if (matches3 && matches3.length > 0) {
                        matches3.forEach(match => {
                            let hsn = match.trim();
                            hsn = hsn.slice(0, 4)
                            if (hsnCounts.has(hsn)) {
                                hsnCounts.set(hsn, hsnCounts.get(hsn) + 1);
                            } else {
                                hsnCounts.set(hsn, 1);
                            }
                        });
                    }
                });
            }

            hsnCounts.forEach((count, hsn) => {
                if (count == cnt && hsn > hsncodeofproduct) {
                    cnt = count;
                    hsncodeofproduct = hsn;
                }
                else if (count > cnt) {
                    cnt = count;
                    hsncodeofproduct = hsn;
                }
            });

            let obj1 = {
                'query': `[dbo].[Lawcrux_Web_Proc_AddHSN_Submit] @UserID='{0}',@Word='{1}',@HSNCode='{2}'`,
                'queryArr': [`${sessionStorage.getItem('userid')}`, `${this.state.hsnsearch}`, `${hsncodeofproduct}`]
            }
            Hexapi(obj1).then(resp => {
                console.log(resp)
            });
            this.setState({ hsnsearch: hsncodeofproduct })


        } catch (error) {

            console.error('Error fetching search results:', error);
        }
        console.log(this.state.searchResult);
        this.setState({ isOptionModalOpen: true })
        this.onHsnSearch(this.state.hsnsearch);
    }


    componentDidMount() {
        this.setState({ hsnsearch: this.props.hsnSearched, isComponentRender: true })
    }

    createIframe(fileData) {
        let filepath = fileData.filepath
        console.log(fileData)
        this.setState({ isOpenInIframe: false, selectedFileDetails: fileData, selectedFile: filepath, isFetchFileApiHitComplete: false, htmlFileContent: "" })
        let url = sessionStorage.getItem('url') + 'fetchfile.php'
        var fd = new FormData();
        fd.append(Aesencryption('filename'), Aesencryption(filepath))
        axios({
            mode: 'cors',
            method: "POST",
            headers: { "Content-Type": "multipart/form-data" },
            url: `${url}`,
            data: fd
        }).then(resp => {
            let respData = resp.data
            if (respData.toLowerCase().includes('<object')) {
                respData = respData.replaceAll(`data="../../`, `data="${sessionStorage.getItem('url')}Assets/Data5t/`)
            }
            let pageTitle = respData.substring(respData.indexOf('<title>') + 7, respData.indexOf('</title>'));
            let headContent = respData.substring(
                respData.toLowerCase().indexOf('<head>') + 6,
                respData.toLowerCase().indexOf('</head>'))
                .replace('<title>' + pageTitle + '</title>', '')

            console.log(headContent, headContent.toLowerCase().includes('<script'))
            // if (headContent.toLowerCase().includes('<script') || headContent.toLowerCase().includes('<link') || headContent.toLowerCase().includes('<style>')) {
            if (headContent.toLowerCase().includes('<script') || headContent.toLowerCase().includes('<link') || respData.toLowerCase().includes('<img')) {
                this.setState({ isOpenInIframe: true })
            }

            let newText = respData.replaceAll('�', '"').replaceAll('&nbsp;', '')
            this.setState({
                htmlFileShowContent: newText
            })
        }).then(() => this.setState({ isFetchFileApiHitComplete: true })).then(() => {
            $('#customIframe').find('a').each(function () {
                let href = $(this).attr('href')
                $(this).attr('href', '#')
            });
        }).then(() => {
            // Only highlighing searched word
            this.markInstance = new Mark(document.getElementById("customIframe"));
            this.markInstance.unmark({
                accuracy: 'exactly',
                done: () => {
                    this.markInstance.mark(this.state.hsnsearch, {
                        accuracy: 'exactly'
                    });
                }
            });
        }).catch(err => console.error(err))
    }

    storeHistory(filedata) {
        console.log(filedata)
        let obj = {
            query: `[dbo].[Lawcrux_Web_Proc_HistoryLog_Submit] @UserID='{0}', @filepath='{1}', @Description='{2}'`,
            queryArr: [`${this.props.userid}`, `${filedata.filepath}`, `${filedata.title}`]
        }
        Hexapi(obj).then(resp => {
            console.log(resp)
        }).then(() => {
            this.createIframe(filedata)
        })
    }

    setRightBoxContent(data) {
        var newPromise = new Promise((resolve, reject) => {
            this.setState({ allFiles: [], isFileTitleFetched: false, isFileTitleFound: false })
            console.log(data)
            let fdata = []
            // if (this.state.isRefinedSearch || this.state.isOnlyParamSearch) {
            //     newData = data.filter((el) => el.type != 'folder')
            // } else {
            //     newData = data.filter((el) => el.type != 'folder' && el.foundCount != 0)
            // }
            function getfiles(d) {
                d.forEach(el => {
                    if (el.type == 'folder') {
                        console.log(el)
                        getfiles(el.children)
                    } else {
                        console.log(el)
                        let obj = {
                            title: el.title,
                            path: `C:/htdocs/Lawcrux/Lawcrux/Web/${el.path}`,
                            type: el.type,
                            name: el.name
                        }
                        fdata.push(obj)
                    }
                })
            }
            getfiles(data)
            console.log(fdata)
            let paths = []
            for (let i = 0; i < fdata.length; i++) {
                paths.push(fdata[i].path)
            }
            console.log(paths)
            let obj = {
                allfiles: paths.join('##12##'),
                url: sessionStorage.getItem('gettitleurl')
            }
            Hexapi(obj).then(resp => {
                fdata.forEach((el, i) => el.title = resp[i])
                console.log(data, fdata, resp)
                resolve(fdata)
            }).catch(err => reject(err))
        })
        // let newData = []
        // if (this.state.isOnlyParamSearch) {
        //     newData = data.filter((el) => el.type != 'folder')
        // } else {
        //     newData = data.filter((el) => el.type != 'folder' && el.foundCount != 0)
        // }
        // console.log(newData)
        // let mainBox = document.getElementById("hsnmainBoxContent")
        // let rightBox = document.getElementById("rightBox")
        // this.setState({ selectedFile: '', isFetchFileApiHitComplete: false, htmlFileContent: "", allFiles: newData, isShowRightBox: true }, () => {
        //     mainBox.style.minWidth = "calc(100% - 700px)"
        // })
        newPromise.then((fileData) => {
            let mainBox = document.getElementById("hsnmainBoxContent")
            // let rightBox = document.getElementById("rightBox")
            this.setState({ allStarts: '', allEnds: '', selectedFile: '', isShowReadWith: false, isFetchFileApiHitComplete: false, isFileTitleFound: true, htmlFileContent: "", allFiles: fileData, isShowRightBox: true, isFileTitleFetched: true }, () => {
                mainBox.style.minWidth = "calc(100% - 700px)"
            })
        }).catch(err => console.error(err))
    }


    createTree(files) {
        // utility function
        const call = (fn, ...args) =>
            fn(...args)

        // main functions
        const nest = (files) => files.reduce((tree, file) => {
            file.filepath.split('/').reduce((node, name, i, a) => {
                return (node[name] || (node[name] = {
                    name,
                    path: i == a.length - 1 ? file.filepath : null,
                    title: i == a.length - 1 ? file.fileTitle : null,
                    // foundCount: i == a.length - 1 ? file.count : null,
                    type: i == a.length - 1 ? 'file' : 'folder',
                    children: {}
                })).children
            }, tree)
            return tree
        }, {})

        const flattenChildren = (tree) =>
            Object.values(tree)
                .map(({ children, ...rest }) => call(
                    (kids) => ({
                        ...rest,
                        ... (kids.length ? { children: kids } : {})
                    }),
                    children ? flattenChildren(children) : []
                ))


        // public function
        return flattenChildren(nest(files))
    }

    onHsnSearch(searchWord) {
        // console.log(this.state.hsnsearch)
        // console.warn(typeof searchWord , searchWord)
        let producthsn = false;
        console.log(searchWord);
        const hsnno = searchWord.slice(0, 2);
        console.log(hsnno);
        if (hsnno != 99) {
            producthsn = true;
        }
        let Arr = []
        this.setState({ isTimerModal: true, isOpenHsnfileModal: true, isFetchFileApiHitComplete: false, isApiHitComplete: false, isDataFound: false, selectedFile: '' })
        let obj = {
            'search': `${searchWord}`,
            'type': producthsn ? 'product' : 'service',
            'url': this.state.hsnsearchurl,
        }
        console.log(obj);
        Hexapi(obj).then(resp => {
            let Arr = resp
            console.log(Arr)
            if (Arr.length > 0) {
                const input = Arr
                // var totalFilesCount = 0
                // for (let i = 0; i < Arr.length; i++) {
                //     totalFilesCount += parseInt(Arr[i].count)
                // }
                input.forEach((data) => {
                    if (data.filepath.includes('\\')) {
                        data.filepath = data.filepath.split('\\Web\\')[1].replaceAll('\\', "/")
                    }
                })

                console.log(input)

                this.setState({ allData: Arr, totalFilesCount: resp.length, treeData: this.createTree(input), isDataFound: true }, () => {
                    console.log(this.state.treeData)
                })
            } else {
                this.setState({ isDataFound: false })
            }
        }).then(() => this.setState({ isTimerModal: false, isApiHitComplete: true }))
            .catch(err => {
                console.error(err);
                document.getElementById('loadingTimer').innerHTML = 'Something went wrong. Please try again.'
            })
        console.log(this.state.isApiHitComplete)
    }

    getLeafCount(data) {
        let count = 0
        console.log(data)

        function getfiles(d) {
            d.forEach(el => {
                if (el.type == 'folder') {
                    // console.log(el)
                    getfiles(el.children)
                } else {
                    // console.log(el)
                    // let obj = {
                    //     title : el.title,
                    //     path : `C:/htdocs/Lawcrux/Lawcrux/Web/${el.path}`,
                    //     type:el.type,
                    //     name:el.name
                    // }
                    count++
                }
            })
        }
        getfiles(data.children)
        // for (let i = 0; i < data.length; i++) {
        //     if (data[i].children != undefined && data[i].children > 0) {
        //         count += this.getLeafCount(data[i].children)
        //     }
        // }
        // if (data.children != undefined) {
        //     data.children.map((child, index) => {
        //         if (child.type == 'folder') {
        //             count = this.getLeafCount(child)
        //         } else {
        //             count++
        //         }
        //     })
        // } else {
        //     count++
        // }

        return count
        // let allfileslist = []
        // data.children.map((child,index) => {
        //     if(child.type == 'folder'){
        //         allfileslist = this.getLeafCount(child)
        //     }else{
        //         allfileslist.push(child)
        //     }
        // })
        // return allfileslist
    }

    setLeftBoxTree(data) {
        // console.log(data)
        if (data.type == 'folder') {
            return (
                data.children.map((child, index) => {
                    if (child.type == 'folder' && 
                    (
                    child.name.toLowerCase() == "cutar2" || 
                    child.name.toLowerCase() == 'gtrate')) {
                        // console.log()
                        let totalFound = this.getLeafCount(child)
                        return (
                            <TreeItem key={child.name + index} nodeId={`${"childFolder" + child.name}`} label={rename(child.name) + ' - ' + totalFound} onClick={() => {
                                totalFound != 0 ? this.setRightBoxContent(child.children) : this.setState({ allFiles: [] })
                            }}>
                                {this.setLeftBoxTree(child)}
                            </TreeItem>
                        )
                    } else {
                        return (
                            this.setLeftBoxTree(child)
                            // <TreeItem key={child.name + index} nodeId={`${"file" + child.name}`} label={rename(child.name) + ' - ' + totalFound} />
                        )
                    }
                    //     if (child.type == "folder") {
                    //         if (child.children != undefined && child.children.length > 0) {
                    //             if (child.children[0].type == "file") {
                    //                 // let totalFound = Number(0)
                    //                 // for (let i = 0; i < child.children.length; i++) {
                    //                 //     totalFound += child.children[i].foundCount
                    //                 // }
                    //                 let totalFound = child.children.length
                    //                 return (
                    //                     <TreeItem key={child.name + index} nodeId={`${"childFolder" + child.name + totalFound}`} label={rename(child.name) + ' - ' + totalFound} onClick={() => {
                    //                         totalFound != 0 ? this.setRightBoxContent(child.children) : this.setState({ allFiles: [] })
                    //                     }}>
                    //                         {this.setLeftBoxTree(child)}
                    //                     </TreeItem>
                    //                 )
                    //             } else {
                    //                 // let totalFound = Number(0)
                    //                 // for (let i = 0; i < child.children.length; i++) {
                    //                 //     for (let k = 0; k < child.children[i].children.length; k++) {
                    //                 //         totalFound += child.children[i].children[k].foundCount
                    //                 //     }
                    //                 // }
                    //                 let totalFound = this.getLeafCount(child.children) - child.children.length
                    //                 return (
                    //                     // <TreeItem key={child.name + index} nodeId={`${"parentFolder" + child.name + totalFound}`} label={rename(child.name) + ' - ' + totalFound}>
                    //                     <>
                    //                         {this.setLeftBoxTree(child)}
                    //                     </>
                    //                     // </TreeItem>
                    //                 )
                    //             }
                    //         }
                    //     }
                })
            )
        }

    }


    renderer = ({ hours, minutes, seconds, completed }) => {
        if (completed) {
            // Render a completed state
            return <span>Server is busy...Please wait</span>;
        } else {
            // Render a countdown
            return <span>Loading...{seconds}sec</span>;
        }
    };
    setMainBoxWidth() {
        let mainbox = document.getElementById('hsnmainBoxContent')
        if (this.state.isShowLeftBox == true && this.state.isShowRightBox == true) {
            mainbox.style.minWidth = 'calc(100% - 700px)'
        } else if (this.state.isShowLeftBox == true && this.state.isShowRightBox == false) {
            mainbox.style.minWidth = 'calc(100% - 300px)'
        } else if (this.state.isShowLeftBox == false && this.state.isShowRightBox == true) {
            mainbox.style.minWidth = 'calc(100% - 400px)'
        } else if (this.state.isShowLeftBox == false && this.state.isShowRightBox == false) {
            mainbox.style.minWidth = 'calc(100%)'
        }
        // mainbox.style.transition = '0.2s all'
    }

    render() {
        return (
            <>
                {
                    this.state.isComponentRender &&
                    <Modal id={'search_options_modal'} size="lg" centered style={{ backgroundColor: 'rgba(0,0,0,0.6)' }} show={true} onHide={() => this.props.onCloseModal()} keyboard={false} backdrop='static'>
                        <Modal.Header>
                            <div className="header">
                                <span>HSN Search</span>
                                <IconButton onClick={() => this.props.onCloseModal()}>
                                    <CloseIcon />
                                </IconButton>
                            </div>
                        </Modal.Header>
                        <Modal.Body style={{ backgroundColor: "khaki" }}>
                            <div className="modal_search">
                                <Input
                                    htmlFor={"productservices"}
                                    label={"Product/Services"}
                                    type="text"
                                    labelStyle={{ fontSize: "16px", fontWeight: "600" }}
                                    inline={true}
                                    onChange={(e) => this.setState({ hsninput:e.target.value,hsnsearch: e.target.value, hsnsearchvalue: e.target.value })}
                                    // value={this.state.searchResult==''?this.state.hsnsearch :this.state.searchResult}
                                    value={this.state.hsninput}
                                    autoFocus={true}
                                />
                                <Tooltip title="Search keyword">
                                    <IconButton onClick={(e) => this.onSearch(this.state.hsnsearch)}>
                                        <SearchIcon />
                                    </IconButton>
                                </Tooltip>
                            </div>
                        </Modal.Body>
                    </Modal>
                }
                {/* <Modal id={'search_options_modal'} size="md" centered style={{ backgroundColor: 'rgba(0,0,0,0.6)' }} show={this.state.isOptionModalOpen} onHide={() => this.setState({ isOptionModalOpen: false })} keyboard={false} backdrop='static'>
                    <Modal.Header>
                        <div className="header">
                            <span>Select the desired option</span>
                            <IconButton onClick={() => this.setState({ isOptionModalOpen: false })}>
                                <CloseIcon />
                            </IconButton>
                        </div>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="hsn_desired_modal_option">
                            <div className="top">
                                <span >Choose An Option</span>
                            </div>
                            <div className="bottom">
                                <span onClick={() => {
                                    this.onHsnSearch(this.state.hsnsearch, 'product')
                                    this.setState({ isOptionModalOpen: false })
                                }}>Product Search</span>
                                <span onClick={() => {
                                    this.onHsnSearch(this.state.hsnsearch, 'service')
                                    this.setState({ isOptionModalOpen: false })
                                }}>Services Search</span>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal> */}



                <Modal id={'search_options_modal'} fullscreen centered style={{ backgroundColor: 'rgba(0,0,0,0.6)' }} show={this.state.isOpenHsnfileModal} onHide={() => this.setState({ isOpenHsnfileModal: false })} keyboard={false} backdrop='static'>
                    <Modal.Header>
                        <div className="header">
                            <div style={{ display: "flex", width: "100%", alignItems: 'center' }}>
                                <span>Tariff Search of {this.state.hsnsearchvalue} - {this.state.hsnsearch}</span>
                                {
                                    this.state.isFetchFileApiHitComplete && (this.props.userid == "1" || this.props.userid == "2") &&
                                    <>
                                        <Tooltip title="Print">
                                            <IconButton onClick={() => {
                                                const printDiv = () => {
                                                    var divContents = document.getElementById("customIframe").innerHTML;
                                                    divContents = divContents.replaceAll('href', 'data-href')
                                                    // var divContents = this.state.htmlFileOriginalContent 
                                                    var a = window.open('', '', 'height=500, width=500');
                                                    a.document.write('<html>');
                                                    // a.document.write('<body > <h1>Div contents are <br>');
                                                    a.document.write(divContents);
                                                    a.document.write('</body></html>');
                                                    a.document.close();
                                                    a.print();
                                                }
                                                const printIframe = () => {
                                                    // var iframe = $('#hsnIframe')[0];
                                                    var iframe = document.getElementById('hsnIframe').contentWindow.document.body.innerHTML;
                                                    // iframe = iframe.replaceAll('href', 'data-href')
                                                    // iframe.contentWindow.focus();
                                                    iframe = iframe.replaceAll('<a', '<span').replaceAll('</a>', '</span>')
                                                    var a = window.open('', '', 'height=500, width=500');
                                                    a.document.write('<html>');
                                                    // a.document.write('<body > <h1>Div contents are <br>');
                                                    a.document.write(iframe);
                                                    a.document.write('</body></html>');
                                                    a.document.close();
                                                    a.print();
                                                }
                                                this.state.isOpenInIframe ?
                                                    // console.log('hello')
                                                    printIframe()
                                                    :
                                                    printDiv()
                                            }}>
                                                <PrintIcon />
                                            </IconButton>
                                        </Tooltip>
                                        <div className="col-md-4 col-12" style={{ padding: '10px' }}>
                                            <span className='read_with' style={{ fontSize: "16px" }} onClick={() => this.setState({ InhouseFilepath: this.state.selectedFile.substring(this.state.selectedFile.indexOf('Assets')), isOpenInHouseDevelopment: true }, () => console.log(this.state.InhouseFilepath))}>
                                                In House Development
                                            </span>
                                        </div>
                                    </>
                                }

                            </div>
                            <IconButton onClick={() => this.setState({ isOpenHsnfileModal: false })}>
                                <CloseIcon />
                            </IconButton>
                        </div>
                    </Modal.Header>
                    <Modal.Body style={{ padding: '0px' }}>

                        {/* {this.state.searchResult.map((result) => (
                            <div key={result.link}>
                                <a href={result.link} onClick={() => this.logLink(result.link)}>
                                    {result.title}
                                </a>
                                <p>{result.snippet}</p>
                            </div>
                        ))} */}
                        {/* {this.state.searchResult.map((result) => (
                            <div key={result.link}>
                                <h3>{result.title}</h3>
                                <p>{result.snippet}</p>
                                {result.link.includes('/HSN/') && (
                                    <p>HSN Code: {result.link.split('/HSN/')[1]}</p>
                                )}
                                <a href={result.link} onClick={() => this.logLink(result.link)}>
                                    {result.link}
                                </a>
                            </div>
                        ))} */}

                        {
                            this.state.isApiHitComplete ?
                                this.state.isDataFound ?
                                    <div className="Search_contentBox">
                                        {
                                            this.state.isShowLeftBox ?
                                                <div className="leftBox noselect">
                                                    <div className="totalFileCount">
                                                        <span>Total Count - {this.state.totalFilesCount}</span>
                                                        <Tooltip title="Collapse Listview">
                                                            <IconButton onClick={() => {
                                                                this.setState({ isShowLeftBox: false }, () => this.setMainBoxWidth())
                                                                // document.getElementById("mainBoxContent").style.transition = '0.5s all'
                                                                // document.getElementById("mainBoxContent").style.minWidth = 'calc(100% - 300px)'
                                                            }}>
                                                                <CloseIcon />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </div>
                                                    {
                                                        this.state.treeData[0].children.map((data, index) => {
                                                            return (
                                                                <TreeView
                                                                    key={data.name}
                                                                    aria-label="file system navigator"
                                                                    defaultCollapseIcon={<ExpandMoreIcon />}
                                                                    defaultExpandIcon={<ChevronRightIcon />}
                                                                    sx={{ height: "calc(91vh - 50px)", flexGrow: 1, maxWidth: 400, overflowY: 'auto' }}
                                                                >
                                                                    {data.children != undefined ? this.setLeftBoxTree(data) : ""}
                                                                </TreeView>
                                                            )
                                                        })
                                                    }
                                                </div>
                                                :
                                                <div className="closedSideMenu_left">
                                                    <Tooltip title="Expand Listview">
                                                        <IconButton onClick={() => {
                                                            this.setState({ isShowLeftBox: true }, () => this.setMainBoxWidth())
                                                            // if(this.state.isShowRightBox){
                                                            //     document.getElementById("mainBoxContent").style.minWidth = 'calc(100% - 300px)'
                                                            // }else{
                                                            //     document.getElementById("mainBoxContent").style.minWidth = 'calc(100% - 700px)'
                                                            // }
                                                            // document.getElementById("mainBoxContent").style.transition = '0.2s all'
                                                        }}>
                                                            <ArrowForwardIcon style={{ color: '#fff' }} />
                                                        </IconButton>
                                                    </Tooltip>
                                                </div>

                                        }
                                        <div className="mainBox" id="hsnmainBoxContent">
                                            {
                                                this.state.selectedFile != "" ?
                                                    this.state.isFetchFileApiHitComplete ?
                                                        this.state.isOpenInIframe ?
                                                            <iframe id={'hsnIframe'} src={`${sessionStorage.getItem('url')}${this.state.selectedFileDetails.filepath.substring(this.state.selectedFileDetails.filepath.indexOf('Assets/'))}`} frameborder="0" width={'100%'} height={"480px"}></iframe>
                                                            :
                                                            <div id="customIframe" dangerouslySetInnerHTML={{ __html: this.state.htmlFileShowContent }}></div>
                                                        :
                                                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: '100%', height: "91vh" }}>
                                                            <Spinner animation="border" variant="dark" />
                                                        </div>
                                                    :
                                                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: '100%', height: "91vh" }}>
                                                        <h5 style={{ color: 'var(--norecord)' }}>No file selected</h5>
                                                    </div>
                                            }
                                        </div>
                                        {
                                            this.state.isShowRightBox ?
                                                <div className="rightBox noselect" id='rightBoxFiles'>
                                                    <div className="toprow">
                                                        <div style={{ display: 'flex', alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                                                            {/* <span>Total Files - {this.state.allFiles.length}</span> */}

                                                            {
                                                                this.state.isSortEnable ?
                                                                    <Tooltip title="Sort descending">
                                                                        <IconButton onClick={() => this.setState({ isSortEnable: false })}>
                                                                            <ArrowDropUpIcon />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                    :
                                                                    <Tooltip title="Sort ascending">
                                                                        <IconButton onClick={() => this.setState({ isSortEnable: true })}>
                                                                            <ArrowDropDownIcon />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                            }

                                                        </div>
                                                        <Tooltip title="Collapse Listview">
                                                            <IconButton onClick={() => {
                                                                this.setState({ isShowRightBox: false }, () => this.setMainBoxWidth())
                                                                // document.getElementById("mainBoxContent").style.transition = '0.5s all'
                                                                // document.getElementById("hsnmainBoxContent").style.minWidth = 'calc(100% - 300px)'
                                                            }}>
                                                                <CloseIcon />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </div>
                                                    <div className="tree">
                                                        {
                                                            this.state.isFileTitleFetched ?
                                                                this.state.isFileTitleFound ?
                                                                    this.state.allFiles.sort((a, b) => {
                                                                        // this.state.isSortEnable && a.title > b.title ? 1 : -1
                                                                        if (this.state.isSortEnable) {
                                                                            if (a.title.toLowerCase() > b.title.toLowerCase()) {
                                                                                return 1
                                                                            } else {
                                                                                return -1
                                                                            }
                                                                        } else {
                                                                            if (b.title.toLowerCase() > a.title.toLowerCase()) {
                                                                                return 1
                                                                            } else {
                                                                                return -1
                                                                            }
                                                                        }
                                                                    }).map((child, index) => {
                                                                        let fileData = {
                                                                            title: child.title,
                                                                            filepath: child.path,
                                                                            userid: this.props.userid
                                                                        }
                                                                        return (
                                                                            <div className="fileRow" style={{ marginBottom: index == this.state.allFiles.length - 1 ? '30px' : "0px", backgroundColor: this.state.selectedFile == child.path ? 'rgba(25, 118, 210, 0.08)' : "transparent", borderBottom: "1px solid rgba(0,0,0,0.3)" }} onClick={() => this.storeHistory(fileData)}>
                                                                                {/* <span className="foundCount">1</span> */}
                                                                                <div className="fileName">{child.title}</div>
                                                                            </div>
                                                                        )
                                                                    })
                                                                    :
                                                                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: '100%', height: "inherit" }}>
                                                                        <h5 style={{ color: 'var(--norecord)' }}>No record</h5>
                                                                    </div>
                                                                :
                                                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: '100%', height: "inherit" }}>
                                                                    <Spinner animation="border" variant="dark" />
                                                                </div>
                                                        }
                                                    </div>
                                                </div>
                                                :
                                                <div className="closedSideMenu">
                                                    <Tooltip title="Expand Listview">
                                                        <IconButton onClick={() => {
                                                            this.setState({ isShowRightBox: true }, () => this.setMainBoxWidth())
                                                            // document.getElementById("hsnmainBoxContent").style.minWidth = 'calc(100% - 700px)'
                                                            // document.getElementById("hsnmainBoxContent").style.transition = '0.2s all'
                                                        }}>
                                                            <ArrowBackIcon style={{ color: '#fff' }} />
                                                        </IconButton>
                                                    </Tooltip>
                                                </div>
                                        }

                                    </div>
                                    :
                                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: '100%', height: "91vh" }}>
                                        <h5 style={{ color: 'var(--norecord)' }}></h5>
                                    </div>
                                :
                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: '100%', height: "91vh" }}>
                                    <Spinner animation="border" variant="dark" />
                                </div>
                        }
                    </Modal.Body>
                </Modal>

                <Modal id={'search_options_modal'} size="md" centered style={{ backgroundColor: 'rgba(0,0,0,0.6)' }} show={this.state.isOpenInHouseDevelopment} onHide={() => this.setState({ isOpenInHouseDevelopment: false })}>
                    <Modal.Body style={{ padding: "10px" }}>
                        <div className='InhouseDevelopment'>
                            <b>Location:</b>&nbsp;&nbsp;
                            <span>{this.state.InhouseFilepath}</span>
                        </div>
                    </Modal.Body>
                </Modal>

                {/* Timer Modal */}
                <Modal scrollable={true} size="sm" centered style={{ backgroundColor: 'rgba(0,0,0,0.6)' }} animation={true} show={this.state.isTimerModal} onHide={() => this.setState({ isTimerModal: false })} keyboard={false} backdrop='static'>
                    <Modal.Body>
                        <div className="timer" style={{ display: 'flex', justifyContent: 'center', alignItems: "center", flexDirection: "column", minHeight: "15vh" }}>
                            <Spinner animation="border" variant="dark" />
                            {/* <span id="loadingTimer">
                                <Countdown
                                    date={Date.now() + 45000}
                                    // intervalDelay={0}
                                    // precision={3}
                                    renderer={this.renderer.bind(this)}
                                />
                            </span> */}
                        </div>
                    </Modal.Body>
                </Modal>
            </>
        )
    }
}
