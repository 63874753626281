import React, { Component } from 'react'
import { Modal, Spinner } from 'react-bootstrap'
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
// import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import HtmlDiff from 'htmldiff-js';
import axios from 'axios'
import { Aesencryption } from '../../usefulcomps/Aes/Aes';
import './comparelaws.css'
import $ from 'jquery'
import ComboBox from '../../usefulcomps/HexInput/Combobox';
const allStates = [
    {
        value: "CGST",
        state: 'CGST',
        subfolder: 'gtcgst',
        mainfolder: 'gt'
    },
    {
        value: "Andhra Pradesh",
        state: 'Andhra Pradesh',
        subfolder: 'gtstap',
        mainfolder: 'gt'
    },
    {
        value: 'Arunachal Pradesh',
        state: 'Arunachal Pradesh',
        subfolder: 'gtstar',
        mainfolder: 'gt'
    },
    {
        value: 'Assam',
        state: 'Assam',
        subfolder: 'gtstas',
        mainfolder: 'gt'
    },
    {
        value: 'Bihar',
        state: 'Bihar',
        subfolder: 'gtstbi',
        mainfolder: 'gt'
    },
    {
        value: 'Chhattisgarh',
        state: 'Chhattisgarh',
        subfolder: 'gtstch',
        mainfolder: 'gt'
    },
    {
        value: 'Delhi',
        state: 'Delhi',
        subfolder: 'gtstde',
        mainfolder: 'gt'
    },
    {
        value: 'Goa',
        state: 'Goa',
        subfolder: 'gtstgo',
        mainfolder: 'gt'
    },
    {
        value: 'Gujarat',
        state: 'Gujarat',
        subfolder: 'gtstgu',
        mainfolder: 'gt'
    },
    {
        value: 'Haryana',
        state: 'Haryana',
        subfolder: 'gtsthr',
        mainfolder: 'gt'
    },
    {
        value: 'Himachal Pradesh',
        state: 'Himachal Pradesh',
        subfolder: 'gtsthi',
        mainfolder: 'gt'
    },
    {
        value: 'Jammu and Kashmir',
        state: 'Jammu and Kashmir',
        subfolder: 'gtstjk',
        mainfolder: 'gt'
    },
    {
        value: 'Jharkhand',
        state: 'Jharkhand',
        subfolder: 'gtstjh',
        mainfolder: 'gt'
    },
    {
        value: 'Karnataka',
        state: 'Karnataka',
        subfolder: 'gtstka',
        mainfolder: 'gt'
    },
    {
        value: 'Kerala',
        state: 'Kerala',
        subfolder: 'gtstke',
        mainfolder: 'gt'
    },
    {
        value: 'Madhya Pradesh',
        state: 'Madhya Pradesh',
        subfolder: 'gtstmp',
        mainfolder: 'gt'
    },
    {
        value: 'Maharashtra',
        state: 'Maharashtra',
        subfolder: 'gtstmh',
        mainfolder: 'gt'
    },
    {
        value: 'Manipur',
        state: 'Manipur',
        subfolder: 'gtstmn',
        mainfolder: 'gt'
    },
    {
        value: 'Meghalaya',
        state: 'Meghalaya',
        subfolder: 'gtstme',
        mainfolder: 'gt'
    },
    {
        value: 'Mizoram',
        state: 'Mizoram',
        subfolder: 'gtstmz',
        mainfolder: 'gt'
    },
    {
        value: 'Nagaland',
        state: 'Nagaland',
        subfolder: 'gtstnl',
        mainfolder: 'gt'
    },
    {
        value: 'Orissa',
        state: 'Orissa',
        subfolder: 'gtstor',
        mainfolder: 'gt'
    },
    {
        value: 'Puducherry',
        state: 'Puducherry',
        subfolder: 'gtstpy',
        mainfolder: 'gt'
    },
    {
        value: 'Punjab',
        state: 'Punjab',
        subfolder: 'gtstpb',
        mainfolder: 'gt'
    },
    {
        value: 'Rajasthan',
        state: 'Rajasthan',
        subfolder: 'gtstrj',
        mainfolder: 'gt'
    },
    {
        value: 'Sikkim',
        state: 'Sikkim',
        subfolder: 'gtstsk',
        mainfolder: 'gt'
    },
    {
        value: 'Tamil Nadu',
        state: 'Tamil Nadu',
        subfolder: 'gtsttm',
        mainfolder: 'gt'
    },
    {
        value: 'Telangana',
        state: 'Telangana',
        subfolder: 'gtstel',
        mainfolder: 'gt'
    },
    {
        value: 'Tripura',
        state: 'Tripura',
        subfolder: 'gtsttp',
        mainfolder: 'gt'
    },
    {
        value: 'Uttarakhand',
        state: 'Uttarakhand',
        subfolder: 'gtstuh',
        mainfolder: 'gt'
    },
    {
        value: 'Uttar Pradesh',
        state: 'Uttar Pradesh',
        subfolder: 'gtsupt',
        mainfolder: 'gt'
    },
    {
        value: 'West Bengal',
        state: 'West Bengal',
        subfolder: 'gtstwb',
        mainfolder: 'gt'
    }
]
export default class Comparelaws extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isComponentRender: false, isCompareModalOpen: false,
            selectedStateObj1: '', selectedStateObj2: '',
            state1: '', state2: '', isCompare: false, isFileFetched: false,
            file1: '', file2: '', section: '', output: ''
        }
        this.onSubmit = this.onSubmit.bind(this)
    }
    componentDidMount() {
        this.setState({ isComponentRender: true })
    }
    fetchFileContent = (filepath, url) => new Promise((resolve, reject) => {
        var fd = new FormData();
        fd.append(Aesencryption('filename'), Aesencryption(filepath))
        axios({
            mode: 'cors',
            method: "POST",
            headers: { "Content-Type": "multipart/form-data" },
            url: `${url}`,
            data: fd
        }).then(resp => {
            let respData = resp.data
            // console.log(respData)
            resolve(respData)
        }).then(r => resolve()).catch(err => reject(err))
    })

    onSubmit(e) {
        e.preventDefault()
        if (this.state.state1 != "" && this.state.state2 != "" && this.state.section != "") {
            let section = this.state.section < 10 ? '0' + this.state.section : this.state.section
            let filename = `CGST_BILL_17_SEC_${section}.htm`
            let filepath1 = `Assets/data5t/${this.state.selectedStateObj1.mainfolder}/${this.state.selectedStateObj1.subfolder}/${filename}`
            let filepath2 = `Assets/data5t/${this.state.selectedStateObj2.mainfolder}/${this.state.selectedStateObj2.subfolder}/${filename}`
            console.log(filepath1, filepath2)



            this.setState({ isComponentRender: false, isCompareModalOpen: true, isFileFetched: false })
            this.fetchFileContent(filepath1, `${sessionStorage.getItem('url')}fetchfile.php`).then(resp => {
                this.setState({ file1: resp.replaceAll('�', '"').replaceAll('[helldod old', '').replaceAll('[helldod', '').replaceAll('helldod]', '').replaceAll('<sup>old</sup>', '') })
            }).then(() => this.fetchFileContent(filepath2, `${sessionStorage.getItem('url')}fetchfile.php`).then(resp => {
                this.setState({ file2: resp.replaceAll('�', '"').replaceAll('[helldod old', '').replaceAll('[helldod', '').replaceAll('helldod]', '').replaceAll('<sup>old</sup>', '') })
            }).then(() => {
                let file1 = this.state.file1
                let file2 = this.state.file2

                this.setState({ output: HtmlDiff.execute(file1, file2) }, () => {
                    this.setState({ isFileFetched: true })
                })
            }).then(() => {
                $('#output,#file_1,#file_2').find('a').each(function () {
                    let href = $(this).attr('href')
                    $(this).attr('href', '#')
                })
            }).catch(err => console.error(err))).catch(err => console.error(err))
        }

    }

    render() {
        return (
            <>
                {
                    this.state.isComponentRender &&
                    <Modal id={'search_options_modal'} size="md" centered style={{ backgroundColor: 'rgba(0,0,0,0.6)' }} show={true} onHide={() => this.props.onCloseModal()} keyboard={false} backdrop='static'>
                        <Modal.Header>
                            <div className="header">
                                <span>Compare Laws</span>
                                <Tooltip title="Close Popup">
                                    <IconButton onClick={() => this.props.onCloseModal()}>
                                        <CloseIcon />
                                    </IconButton>
                                </Tooltip>
                            </div>
                        </Modal.Header>
                        <Modal.Body style={{ backgroundColor: "white", height: "450px" }}>
                            <div className="form-group row" style={{ padding: "10px 0px" }}>
                                <label style={{ fontSize: "16px", fontWeight: "600" }} htmlFor="citation" className="col-sm-3 col-form-label">First State</label>
                                <div className="col-sm-9">
                                    <ComboBox
                                        value={this.state.selectedStateObj1}
                                        options={allStates}
                                        onChange={value => this.setState({ state1: value.state, selectedStateObj1: value })}
                                        getOptionLabel={(option) => option.state}
                                        getOptionValue={(option) => option.value}
                                        placeholder={"Select state"}
                                    />
                                </div>
                            </div>
                            <div className="form-group row" style={{ padding: "10px 0px" }}>
                                <label style={{ fontSize: "16px", fontWeight: "600" }} htmlFor="citation" className="col-sm-3 col-form-label">Second State</label>
                                <div className="col-sm-9">
                                    <ComboBox
                                        value={this.state.selectedStateObj2}
                                        options={allStates}
                                        onChange={value => this.setState({ state2: value.state, selectedStateObj2: value })}
                                        getOptionLabel={(option) => option.state}
                                        getOptionValue={(option) => option.value}
                                        placeholder={"Select state"}
                                    />
                                </div>
                            </div>
                            <div className="form-group row" style={{ padding: "10px 0px" }}>
                                <label style={{ fontSize: "16px", fontWeight: "600" }} htmlFor="staticEmail" className="col-sm-3 col-form-label">Section</label>
                                <div className="col-sm-9">
                                    <input type="text" className="form-control" value={this.state.section} onChange={(e) => this.setState({ section: e.target.value })} />
                                </div>
                            </div>
                            <div className="lawasondate_footer">
                                <span onClick={(e) => this.onSubmit(e)}>Submit</span>
                            </div>
                        </Modal.Body>
                    </Modal>
                }
                <Modal id={'search_options_modal'} fullscreen={true} centered style={{ backgroundColor: 'rgba(0,0,0,0.6)' }} show={this.state.isCompareModalOpen} onHide={() => this.setState({ isCompareModalOpen: false })} keyboard={false} backdrop='static'>
                    <Modal.Header>
                        <div className="header" style={{justifyContent:'flex-start'}}>
                            <Tooltip title="Close View">
                                <IconButton onClick={() => this.setState({ isCompareModalOpen: false }, () => {
                                    this.props.onCloseModal()
                                })}>
                                    <ArrowBackIcon />
                                </IconButton>
                            </Tooltip>
                            <span style={{paddingLeft:"10px"}}>Compare Laws</span>
                        </div>
                    </Modal.Header>
                    <Modal.Body style={{ backgroundColor: "#fff", padding: "0px 5px" }}>
                        {
                            this.state.isFileFetched ?
                                <div className="row main" style={{ display: 'flex' }}>
                                    <div className="col-md-4 col-12">
                                        <div id="file_1" dangerouslySetInnerHTML={{ __html: this.state.file1 }}></div>
                                    </div>
                                    <div className="col-md-4 col-12">
                                        <div id="file_2" dangerouslySetInnerHTML={{ __html: this.state.file2 }}></div>
                                    </div>
                                    <div className="col-md-4 col-12">
                                        <div id="output" dangerouslySetInnerHTML={{ __html: this.state.output }}></div>
                                    </div>
                                </div>
                                :
                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", height: "100%" }}>
                                    <Spinner animation="border" variant="dark" />
                                </div>
                        }
                    </Modal.Body>
                </Modal>
            </>
        )
    }
}
