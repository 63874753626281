import axios from 'axios'
import { StringFormatter } from './StringFormatter';
import { Aesdecryption, Aesencryption } from '../Aes/Aes'
import Swal from 'sweetalert2';
const Hexapi = (props) => new Promise((resolve, reject) => {
    let fd = new FormData();
    if (props.filecode != undefined && props.folderpath != undefined) {
        console.log(props.filecode)
        fd.append(Aesencryption("filecode"), Aesencryption(props.filecode))
        fd.append(Aesencryption("folderpath"), Aesencryption(props.folderpath))
        
    }
    const url = props.url ? `${sessionStorage.getItem('url')}${props.url}` : `${sessionStorage.getItem('Apipathurl')}getfilepath.php`
    axios({
        mode: 'cors',
        method: "POST",
        headers: { "Content-Type": "multipart/form-data" },
        url: `${url}`,
        data: fd
    }).then(resp => {
        let decrypted = Aesdecryption(resp.data)
        resolve(decrypted)
    }).catch(err => console.error(`axios error in query : ${props.query}`, err))
})

export default Hexapi;