import React, { Component } from 'react'
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import SearchIcon from '@mui/icons-material/Search';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Hexapi from '../../usefulcomps/HexAPI/Hexapi'
import '../style.css'
import { Spinner, Modal } from 'react-bootstrap';
import SearchComp from './Searched';
import TreeView from '@mui/lab/TreeView';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TreeItem from '@mui/lab/TreeItem';
import CloseIcon from '@mui/icons-material/Close';
import HighlightOutlinedIcon from '@mui/icons-material/HighlightOutlined';
import HighlightIcon from '@mui/icons-material/Highlight';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
// import AutoFixOffIcon from '@mui/icons-material/AutoFixOff';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import BookmarkBorderRoundedIcon from '@mui/icons-material/BookmarkBorderRounded';
import { Input } from '../../usefulcomps/HexInput/InputBox'
import Hsnsearch from './Hsnsearch';
import QueryStatsOutlinedIcon from '@mui/icons-material/QueryStatsOutlined';
import AlignVerticalBottomIcon from '@mui/icons-material/AlignVerticalBottom';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import HistoryIcon from '@mui/icons-material/History';
import HistoryKeeping from './HistoryKeeping';
import NotesIcon from '@mui/icons-material/Notes';
import Notes from './Notes';
import SummarizeIcon from '@mui/icons-material/Summarize';
import Summary from './Summary';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import Lawasondate from './Lawasondate';
import PublicIcon from '@mui/icons-material/Public';
import Comparelaws from './Comparelaws';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import Countdown from 'react-countdown';
import BookmarksIcon from '@mui/icons-material/Bookmarks';
import BookmarkKeeping from './BookmarkKeeping'
import { styled } from '@mui/material/styles';
import Switch, { SwitchProps } from '@mui/material/Switch';
import HomeIcon from '@mui/icons-material/Home';
import List from './List'
// import Marker from './Marker/Marker'
// import Data from './Marker/Data'
// import { rangeToData } from './Marker/Helper'
// import BookmarkAddedIcon from '@mui/icons-material/BookmarkAdded';
// import Highlighter from 'web-highlighter';
import Mark from "mark.js";
import { Aesdecryption, Aesencryption } from '../../usefulcomps/Aes/Aes';
// import File from '../../113113.htm'
import $ from 'jquery'
import axios from 'axios';
import Parametericsearch from './ParametericsearchV2';
import Dictionary from './Dictionary';
import Magicwond from './Magicwond';
import Swal from 'sweetalert2'
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness4OutlinedIcon from '@mui/icons-material/Brightness4Outlined';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Datatable from '../../usefulcomps/table/IIADVCL';
import PrintIcon from '@mui/icons-material/Print';
import Captcha from '../../usefulcomps/Captcha/CaptchaModal'

var markInstance = ""
// let Range {commonAncestorContainer: div#customIframe, startContainer: text, startOffset: 1, endContainer: text, endOffset: 22, …}
const min = (a, b) => {
    if (a < b) {
        return a
    }
    return b
}
const max = (a, b) => {
    if (a > b) {
        return a
    }
    return b
}


const rename = (name) => {
    let arr = [
        { key: 'bu', replace: 'Budget' },
        { key: 'sp', replace: 'specimen' },
        { key: 'ce', replace: 'central excise' },
        { key: 'cl', replace: 'case law' },
        { key: 'cu', replace: 'custom' },
        { key: 'di', replace: 'dictionary' },
        { key: 'fl', replace: 'flowchart' },
        { key: 'ft', replace: 'foreign trade policy' },
        { key: 'gt', replace: 'GST' },
        { key: 'st', replace: 'service tax' },
        { key: 'sz', replace: 'SEZ' },
        { key: 'rb', replace: 'Fema' },
        { key: 'hn', replace: 'Headnote (Summary of Case Law)' },
    ]
    let newName = -1
    arr.forEach((list) => {
        if (list.key == name) {
            newName = list.replace
        }
    })
    if (newName != -1) {
        return newName
    } else {
        return name
    }
}


const MaterialUISwitch = styled(Switch)(({ theme }) => ({
    width: 62,
    height: 34,
    padding: 7,
    '& .MuiSwitch-switchBase': {
        margin: 1,
        padding: 0,
        transform: 'translateX(6px)',
        '&.Mui-checked': {
            color: '#fff',
            transform: 'translateX(22px)',
            '& .MuiSwitch-thumb:before': {
                backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
                    '#fff',
                )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
            },
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
            },
        },
    },
    '& .MuiSwitch-thumb': {
        backgroundColor: theme.palette.mode === 'dark' ? '#003892' : '#001e3c',
        width: 32,
        height: 32,
        '&:before': {
            content: "''",
            position: 'absolute',
            width: '100%',
            height: '100%',
            left: 0,
            top: 0,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
                '#fff',
            )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
        },
    },
    '& .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
        borderRadius: 20 / 2,
    },
}));


export default class Searched extends Component {
    constructor(props) {
        super(props)
        this.history = []
        this.currentPagePosition = 0
        this.state = {
            ParametericsearchUrl: sessionStorage.getItem('paramsearchurl'),
            Combinesearchurl: sessionStorage.getItem('combinesearchurl'),
            Refinedsearchurl: sessionStorage.getItem('refinedsearchurl'),
            firstTimeSearchedData: [], targetField: '', targetSubField: '',
            newTreeData: [],
            openInitialModal: false, allData: [], treeData: [], selectedFile: '',
            search: '', isApiHitComplete: false, isDataFound: false, isFirstSearchComplete: false,
            highlight: '', searchType: '1', totalFilesCount: 0, firstTimeClick: true,
            ReadWith: 'Read with current law', isFetchFileApiHitComplete: false,
            htmlFileShowContent: '', htmlFileOriginalContent: '',
            isBookmarkAdded: false, selectedFileDetails: [], isBookmarkAddComplete: true,
            savedSelection: null, allStarts: '', allEnds: '', highlightColor: 'lightgreen', startEndArray: [],
            htmlFileShowContentBackup: null, isShowRightBox: false, isShowLeftBox: true, allFiles: [], isOpenHSNSearch: false,
            isOpenParamSearch: false, isOpenDictModal: false, isOpenHistoryModal: false, isHistoryOpen: false, isOpenNotesModal: false,
            usernotes: '', paramSearchObj: null, isOnlyParamSearch: false, summaryData: null, isRefinedSearch: false,
            isOpenLawAsOnDateModal: false, isOpenCompareLaws: false, isMagicWondOpen: false, isFileTitleFetched: true, isFileTitleFound: false,
            searchPath: 'Assets/Data5t/', isDarkMode: false, zoomFrame: 1, isTimerModal: false, isOpenBookmarkModal: false,
            selectedJudge: '', isOpenInIframe: false, isOpenInHouseDevelopment: false, isSortEnable: false, isIframeDarkMode: false,
            // Online state
            isOnline: navigator.onLine, isHistoryTrackBtn: false, isReferedByCircNoti: false, isFileContentFound: false,
            tableData: [], isOpenSearchModal: false,
            searchCategories: [
                {
                    value: '1',
                    label: 'All Word Search'
                },
                {
                    value: '2',
                    label: 'Phrase Search'
                },
                {
                    value: '3',
                    label: 'Any Word Search'
                }
            ], pageData: []
        }
        this.createTree = this.createTree.bind(this)
        this.searchKeyword = this.searchKeyword.bind(this)
        this.storeSearch = this.storeSearch.bind(this)
        this.setLeftBoxTree = this.setLeftBoxTree.bind(this)
        this.handleKeyPress = this.handleKeyPress.bind(this)
        this.getSelectedText = this.getSelectedText.bind(this)
        this.removeSelectedText = this.removeSelectedText.bind(this)
        this.createIframe = this.createIframe.bind(this)
        this.getpagedata = this.getpagedata.bind(this)
        this.storeHistory = this.storeHistory.bind(this)
        this.xWithRef = this.xWithRef.bind(this)
        this.xWithoutRef = this.xWithoutRef.bind(this)
        this.removeBookmark = this.removeBookmark.bind(this)
        this.autoHighlight = this.autoHighlight.bind(this)
        this.addBookmark = this.addBookmark.bind(this)
        this.checkClash = this.checkClash.bind(this)
        this.correctHighlight = this.correctHighlight.bind(this)
        this.setRightBoxContent = this.setRightBoxContent.bind(this)
        this.openHistoryFile = this.openHistoryFile.bind(this)
    }

    trackHistory(filepath) {
        this.history.push(filepath)
        this.currentPagePosition++
        console.log(this.history)
    }

    goBackward_CustomHistory() {
        console.log(this.currentPagePosition)
        if (this.currentPagePosition > 1) {
            console.log(this.currentPagePosition, this.history)
            this.currentPagePosition--
            let fpath = this.history[this.currentPagePosition - 1]
            console.log('==========================================')
            console.log(this.currentPagePosition, this.history)
            console.log('==========================================')

            let url = sessionStorage.getItem('url') + 'fetchfile.php'
            this.setState({ isHistoryTrackBtn: true })
            var fd = new FormData();
            fd.append(Aesencryption('filename'), Aesencryption(fpath))
            axios({
                mode: 'cors',
                method: "POST",
                headers: { "Content-Type": "multipart/form-data" },
                url: `${url}`,
                data: fd
            }).then(resp => {
                let respData = resp.data
                // if (respData.toLowerCase().includes('<object')) {
                //     respData = respData.replaceAll(`data="../../`, `data="${sessionStorage.getItem('url')}Assets/Data5t/`)
                // }

                let pageTitle = respData.substring(respData.indexOf('<title>') + 7, respData.indexOf('</title>'));

                const obj = {
                    filepath: fpath,
                    description: pageTitle,
                    userid: this.state.selectedFileDetails.userid
                }
                console.log(obj)
                // this.setState({ search: '', totalFilesCount: 0, allFiles: [], isFirstSearchComplete: true, isApiHitComplete: true, isDataFound: true, isOpenHistoryModal: false, isOpenBookmarkModal: false, isHistoryOpen: true })
                this.createIframe(obj)
            })
        }
    }

    goForward_CustomHistory() {
        console.log(this.currentPagePosition)
        if (this.currentPagePosition < this.history.length) {
            let fpath = this.history[this.currentPagePosition]
            this.currentPagePosition++
            console.log('==========================================')
            console.log(this.currentPagePosition, this.history)
            console.log('==========================================')

            let url = sessionStorage.getItem('url') + 'fetchfile.php'
            this.setState({ isHistoryTrackBtn: true })
            var fd = new FormData();
            fd.append(Aesencryption('filename'), Aesencryption(fpath))
            axios({
                mode: 'cors',
                method: "POST",
                headers: { "Content-Type": "multipart/form-data" },
                url: `${url}`,
                data: fd
            }).then(resp => {
                let respData = resp.data
                // if (respData.toLowerCase().includes('<object')) {
                //     respData = respData.replaceAll(`data="../../`, `data="${sessionStorage.getItem('url')}Assets/Data5t/`)
                // }

                let pageTitle = respData.substring(respData.indexOf('<title>') + 7, respData.indexOf('</title>'));

                const obj = {
                    filepath: fpath,
                    description: pageTitle,
                    userid: this.state.selectedFileDetails.userid
                }
                console.log(obj)
                // this.setState({ search: '', totalFilesCount: 0, allFiles: [], isFirstSearchComplete: true, isApiHitComplete: true, isDataFound: true, isOpenHistoryModal: false, isOpenBookmarkModal: false, isHistoryOpen: true })
                this.createIframe(obj)
            })
        }
    }

    checkClash(obj1, obj2) {
        // console.log(obj1,obj2)
        if (obj1.start <= obj2.start) {
            if (obj1.end >= obj2.end || obj1.end >= obj2.start) {
                console.log("here")
                return true;
            } else {
                return false;
            }
        } else {
            return this.checkClash(obj2, obj1);
        }
    }
    correctHighlight(array) {
        // console.log(array)
        let newArr = array
        for (let i = 0; i < array.length; i++) {
            // let clash = false
            for (let j = 0; j < array.length; j++) {
                if (i != j && this.checkClash(array[i], array[j])) {

                    let newHi = {
                        start: min(array[i].start, array[j].start),
                        end: max(array[i].end, array[j].end)
                    }
                    newArr.push(newHi)
                    if (i < j) {
                        newArr.splice(j, 1)
                        newArr.splice(i, 1)
                    } else {
                        newArr.splice(i, 1)
                        newArr.splice(j, 1)
                    }

                    newArr = this.correctHighlight(newArr)
                    // console.log(newArr)
                    return newArr;
                }
            }
        }

        return newArr;
    }

    createTree(input) {
        // // utility function
        // const call = (fn, ...args) =>
        //     fn(...args)

        // // main functions
        // const nest = (files) => files.reduce((tree, file) => {
        //     file.filepath.split('/').reduce((node, name, i, a) => {
        //         return (node[name] || (node[name] = {
        //             name,
        //             path: i == a.length - 1 ? file.filepath : null,
        //             title: i == a.length - 1 ? file.fileTitle : null,
        //             foundCount: i == a.length - 1 ? file.count : null,
        //             type: i == a.length - 1 ? 'file' : 'folder',
        //             children: {}
        //         })).children
        //     }, tree)
        //     return tree
        // }, {})

        // const flattenChildren = (tree) =>
        //     Object.values(tree)
        //         .map(({ children, ...rest }) => call(
        //             (kids) => ({
        //                 ...rest,
        //                 ... (kids.length ? { children: kids } : {})
        //             }),
        //             children ? flattenChildren(children) : []
        //         ))


        // // public function
        // return flattenChildren(nest(files))
        var newtree = [
            {
                nodeid: '1',
                name: 'GST',
                children: [
                    {
                        nodeid: '1.1',
                        name: "CGST",
                        children: [
                            {
                                nodeid: '1.1.1',
                                name: 'Act',
                                subfolder: 'gtcgst',
                                mainfolder: 'gt',
                                children: []
                            },
                            {
                                nodeid: '1.1.2',
                                name: 'Rules',
                                subfolder: 'gtstrl',
                                mainfolder: 'gt',
                                children: []
                            },
                            {
                                nodeid: '1.1.3',
                                name: 'Notification - Other than rate of tax',
                                subfolder: 'gtnoti',
                                mainfolder: 'gt',
                                children: []
                            },
                            {
                                nodeid: '1.1.4',
                                name: 'Notification - relating to rate of tax',
                                subfolder: 'gtnoti',
                                mainfolder: 'gt',
                                children: []
                            },
                            {
                                nodeid: '1.1.5',
                                name: 'Circular',
                                subfolder: 'gtnoti',
                                mainfolder: 'gt',
                                children: []
                            },
                            {
                                nodeid: '1.1.6',
                                name: 'Instructions',
                                subfolder: 'gtcgcu',
                                mainfolder: 'gt',
                                children: []
                            },
                            {
                                nodeid: '1.1.7',
                                name: 'Guidelines',
                                subfolder: 'gtcgcu',
                                mainfolder: 'gt',
                                children: []
                            },
                            {
                                nodeid: '1.1.8',
                                name: 'Order',
                                children: [
                                    {
                                        nodeid: '1.1.8.1',
                                        name: 'Order',
                                        subfolder: 'gtnoti',
                                        mainfolder: 'gt',
                                        children: []
                                    },
                                    {
                                        nodeid: '1.1.8.2',
                                        name: 'Removal of Difficulty Order',
                                        subfolder: 'gtnoti',
                                        mainfolder: 'gt',
                                        children: []
                                    },
                                ]
                            },
                        ]
                    },
                    {
                        nodeid: '1.2',
                        name: 'IGST',
                        children: [
                            {
                                nodeid: '1.2.1',
                                name: 'Act',
                                subfolder: 'gtigst',
                                mainfolder: 'gt',
                                children: []
                            },
                            {
                                nodeid: '1.2.2',
                                name: 'Rules',
                                subfolder: 'gtistr',
                                mainfolder: 'gt',
                                children: []
                            },
                            {
                                nodeid: '1.2.3',
                                name: 'Notification - Other than rate of tax',
                                subfolder: 'gtnoti',
                                mainfolder: 'gt',
                                children: []
                            },
                            {
                                nodeid: '1.2.4',
                                name: 'Notification - relating to rate of tax',
                                subfolder: 'gtnoti',
                                mainfolder: 'gt',
                                children: []
                            },
                            {
                                nodeid: '1.2.5',
                                name: 'Circular',
                                subfolder: 'gtnoti',
                                mainfolder: 'gt',
                                children: []
                            },
                        ]
                    },
                    {
                        nodeid: '1.3',
                        name: 'SGST',
                        children: [
                            {
                                nodeid: '1.3.1',
                                name: 'Act',
                                children: []
                            },
                            {
                                nodeid: '1.3.2',
                                name: 'Rules',
                                children: []
                            },
                            {
                                nodeid: '1.3.3',
                                name: 'Notification - Other than rate of tax',
                                children: []
                            },
                            {
                                nodeid: '1.3.4',
                                name: 'Notification - relating to rate of tax',
                                children: []
                            },
                            {
                                nodeid: '1.3.5',
                                name: 'Circular',
                                children: []
                            },
                            {
                                nodeid: '1.3.6',
                                name: 'Order',
                                children: [
                                    {
                                        nodeid: '1.3.6.1',
                                        name: 'Order',
                                        children: []
                                    },
                                    {
                                        nodeid: '1.3.6.2',
                                        name: 'Removal of Difficulty Order',
                                        children: []
                                    },
                                ]
                            },
                        ]
                    },
                    {
                        nodeid: '1.4',
                        name: 'UGST',
                        children: [
                            {
                                nodeid: '1.4.1',
                                name: 'Act',
                                subfolder: 'gtugst',
                                mainfolder: 'gt',
                                children: []
                            },
                            {
                                nodeid: '1.4.2',
                                name: 'Rules',
                                subfolder: 'gtugst',
                                mainfolder: 'gt',
                                children: []
                            },
                            {
                                nodeid: '1.4.3',
                                name: 'Notification - Other than rate of tax',
                                subfolder: 'gtnoti',
                                mainfolder: 'gt',
                                children: []
                            },
                            {
                                nodeid: '1.4.4',
                                name: 'Notification - relating to rate of tax',
                                subfolder: 'gtnoti',
                                mainfolder: 'gt',
                                children: []
                            },
                            // {
                            //     nodeid: '1.4.5',
                            //     name: 'Circular',
                            //     subfolder: 'gtnoti',
                            //     mainfolder: 'gt',
                            //     children: []
                            // },
                            {
                                nodeid: '1.4.5',
                                name: 'Order',
                                children: [
                                    // {
                                    //     nodeid: '1.4.5.1',
                                    //     name: 'Order',
                                    //     subfolder: 'gtnoti',
                                    //     mainfolder: 'gt',
                                    //     children: []
                                    // },
                                    {
                                        nodeid: '1.4.5.1',
                                        name: 'Removal of Difficulty Order',
                                        subfolder: 'gtnoti',
                                        mainfolder: 'gt',
                                        children: []
                                    },
                                ]
                            },
                        ]
                    },
                    {
                        nodeid: '1.5',
                        name: 'GST (Compensation to the States) Act',
                        children: [
                            {
                                nodeid: '1.5.1',
                                name: 'Act',
                                subfolder: 'gtcomp',
                                mainfolder: 'gt',
                                children: []
                            },
                            {
                                nodeid: '1.5.2',
                                name: 'Rules',
                                subfolder: 'gtcomp',
                                mainfolder: 'gt',
                                children: []
                            },
                            {
                                nodeid: '1.5.3',
                                name: 'Notification - Other than rate of tax',
                                subfolder: 'gtnoti',
                                mainfolder: 'gt',
                                children: []
                            },
                            {
                                nodeid: '1.5.4',
                                name: 'Notification - relating to rate of tax',
                                subfolder: 'gtnoti',
                                mainfolder: 'gt',
                                children: []
                            },
                            {
                                nodeid: '1.5.5',
                                name: 'Circular',
                                subfolder: 'gtnoti',
                                mainfolder: 'gt',
                                children: []
                            },
                        ]
                    },
                    {
                        nodeid: '1.6',
                        name: 'Press Release',
                        subfolder: 'gtpres',
                        mainfolder: 'gt',
                        children: []
                    },
                    {
                        nodeid: '1.7',
                        name: 'Portal Related Updates',
                        subfolder: 'gtcgcu',
                        mainfolder: 'gt',
                        children: []
                    },
                    // {
                    //     nodeid: '1.8',
                    //     name: 'GST News',
                    //     children: []
                    // },
                    {
                        nodeid: '1.8',
                        name: 'Council Meeting Updates',
                        subfolder: 'gtport',
                        mainfolder: 'gt',
                        children: []
                    },
                    {
                        nodeid: '1.9',
                        name: 'Form',
                        subfolder: 'gtcfrm',
                        mainfolder: 'gt',
                        children: []
                    },
                ]
            },
            {
                nodeid: '2',
                name: 'Custom',
                children: [
                    {
                        nodeid: '2.1',
                        name: 'Act',
                        subfolder: 'cuacts',
                        mainfolder: 'cu',
                        children: []
                    },
                    {
                        nodeid: '2.2',
                        name: 'Rules',
                        subfolder: 'curule',
                        mainfolder: 'cu',
                        children: []
                    },
                    {
                        nodeid: '2.3',
                        name: 'Circular',
                        subfolder: '',
                        mainfolder: 'cu',
                        children: []
                    },
                    {
                        nodeid: '2.4',
                        name: 'Notification',
                        children: [
                            {
                                nodeid: '2.3.1',
                                name: 'Tariff',
                                children: [
                                    {
                                        nodeid: '2.3.1.1',
                                        name: 'ADD',
                                        subfolder: 'cuncuv',
                                        mainfolder: 'cu',
                                        children: []
                                    },
                                    {
                                        nodeid: '2.3.1.2',
                                        name: 'Safeguard',
                                        subfolder: 'cuncuv',
                                        mainfolder: 'cu',
                                        children: []
                                    },
                                    {
                                        nodeid: '2.3.1.3',
                                        name: 'CVD',
                                        subfolder: 'cuncuv',
                                        mainfolder: 'cu',
                                        children: []
                                    },
                                    {
                                        nodeid: '2.3.1.4',
                                        name: 'Others',
                                        subfolder: 'cuncuv',
                                        mainfolder: 'cu',
                                        children: []
                                    },
                                ]
                            },
                            {
                                nodeid: '2.3.2',
                                name: 'Non Tariff',
                                subfolder: 'cuncuv',
                                mainfolder: 'cu',
                                children: []
                            },
                        ]
                    },
                    {
                        nodeid: '2.5',
                        name: 'Regulations',
                        subfolder: 'curegu',
                        mainfolder: 'cu',
                        children: []
                    },
                    {
                        nodeid: '2.6',
                        name: 'Manual',
                        subfolder: 'cumanu',
                        mainfolder: 'cu',
                        children: []
                    },
                    {
                        nodeid: '2.7',
                        name: 'Form',
                        subfolder: 'cuform',
                        mainfolder: 'cu',
                        children: []
                    },
                ]
            },
            {
                nodeid: '3',
                name: 'Excise',
                children: [
                    {
                        nodeid: '3.1',
                        name: 'Act',
                        subfolder: 'ceacts',
                        mainfolder: 'ce',
                        children: []
                    },
                    {
                        nodeid: '3.2',
                        name: 'Rules',
                        subfolder: 'cerule',
                        mainfolder: 'ce',
                        children: []
                    },
                    {
                        nodeid: '3.3',
                        name: 'Circular',
                        subfolder: 'cencec',
                        mainfolder: 'ce',
                        children: []
                    },
                    {
                        nodeid: '3.4',
                        name: 'Notification',
                        children: [
                            {
                                nodeid: '3.4.1',
                                name: 'Tariff',
                                subfolder: 'cencev',
                                mainfolder: 'ce',
                                children: []
                            },
                            {
                                nodeid: '3.4.2',
                                name: 'Non Tariff',
                                subfolder: 'cencev',
                                mainfolder: 'ce',
                                children: []
                            },
                        ]
                    },
                    {
                        nodeid: '3.6',
                        name: 'Manual',
                        subfolder: 'cemanu',
                        mainfolder: 'ce',
                        children: []
                    },
                    {
                        nodeid: '3.7',
                        name: 'Form',
                        subfolder: 'ceform',
                        mainfolder: 'ce',
                        children: []
                    },
                    {
                        nodeid: '3.8',
                        name: 'Tariff',
                        subfolder: 'cetari',
                        mainfolder: 'ce',
                        children: []
                    }
                ]
            },
            {
                nodeid: '4',
                name: 'Service Tax',
                children: []
            },
            {
                nodeid: '5',
                name: 'FTP',
                children: [
                    {
                        nodeid: '5.1',
                        name: 'Act',
                        subfolder: 'ftacts',
                        mainfolder: 'ft',
                        children: []
                    },
                    {
                        nodeid: '5.2',
                        name: 'Rules',
                        subfolder: 'ftrule',
                        mainfolder: 'ft',
                        children: []
                    },
                    {
                        nodeid: '5.3',
                        name: 'Circular',
                        subfolder: 'ftcirc',
                        mainfolder: 'ft',
                        children: []
                    },
                    {
                        nodeid: '5.4',
                        name: 'Notification',
                        subfolder: 'ftnoti',
                        mainfolder: 'ft',
                        children: []
                    },
                    {
                        nodeid: '5.5',
                        name: 'Appendix(Form)',
                        subfolder: 'ftappe',
                        mainfolder: 'ft',
                        children: []
                    },
                    {
                        nodeid: '5.6',
                        name: 'ITC (HS)',
                        subfolder: 'ftitch',
                        mainfolder: 'ft',
                        children: []
                    },
                    {
                        nodeid: '5.7',
                        name: 'DEPB DrawBack',
                        subfolder: 'ftrate',
                        mainfolder: 'ft',
                        children: []
                    },
                    {
                        nodeid: '5.8',
                        name: 'Policy',
                        subfolder: 'ftpoli',
                        mainfolder: 'ft',
                        children: []
                    },
                    {
                        nodeid: '5.9',
                        name: 'Trade Notice',
                        subfolder: 'fttrad',
                        mainfolder: 'ft',
                        children: []
                    },
                    {
                        nodeid: '5.10',
                        name: 'Public Notice',
                        subfolder: 'ftpubl',
                        mainfolder: 'ft',
                        children: []
                    },
                    {
                        nodeid: '5.11',
                        name: 'HB Procedure',
                        subfolder: 'ftproc',
                        mainfolder: 'ft',
                        children: []
                    },
                    {
                        nodeid: '5.12',
                        name: 'SION',
                        subfolder: 'ftsion',
                        mainfolder: 'ft',
                        children: []
                    },
                ]
            },
            {
                nodeid: '6',
                name: 'FEMA',
                children: [
                    {
                        nodeid: '6.1',
                        name: 'Act',
                        subfolder: 'rbacts',
                        mainfolder: 'rb',
                        children: []
                    },
                    {
                        nodeid: '6.2',
                        name: 'Rules',
                        subfolder: 'rbrule',
                        mainfolder: 'rb',
                        children: []
                    },
                    {
                        nodeid: '6.3',
                        name: 'Circular',
                        subfolder: 'rbcirc',
                        mainfolder: 'rb',
                        children: []
                    },
                    {
                        nodeid: '6.4',
                        name: 'Notification',
                        subfolder: 'rbnoti',
                        mainfolder: 'rb',
                        children: []
                    },
                    {
                        nodeid: '6.5',
                        name: 'Regulations',
                        subfolder: 'rbregu',
                        mainfolder: 'rb',
                        children: []
                    },
                    {
                        nodeid: '6.6',
                        name: 'Citizen Charter',
                        subfolder: 'rbciti',
                        mainfolder: 'rb',
                        children: []
                    },
                    {
                        nodeid: '6.7',
                        name: 'Form',
                        subfolder: 'rbform',
                        mainfolder: 'rb',
                        children: []
                    },
                ]
            },
            {
                nodeid: '7',
                name: 'SEZ',
                children: [
                    {
                        nodeid: '7.1',
                        name: 'Act',
                        subfolder: 'szacts',
                        mainfolder: 'sz',
                        children: []
                    },
                    {
                        nodeid: '7.2',
                        name: 'Rules',
                        subfolder: 'szrule',
                        mainfolder: 'sz',
                        children: []
                    },
                    {
                        nodeid: '7.3',
                        name: 'Policy',
                        subfolder: 'szpoli',
                        mainfolder: 'sz',
                        children: []
                    },
                    {
                        nodeid: '7.4',
                        name: 'Appendix',
                        subfolder: 'szappe',
                        mainfolder: 'sz',
                        children: []
                    },
                    {
                        nodeid: '7.5',
                        name: 'Fema on SEZ',
                        subfolder: 'szfema',
                        mainfolder: 'sz',
                        children: []
                    },
                    {
                        nodeid: '7.6',
                        name: 'Notification',
                        subfolder: 'sznoti',
                        mainfolder: 'sz',
                        children: []
                    },
                    {
                        nodeid: '7.7',
                        name: 'Circular',
                        subfolder: 'szcirc',
                        mainfolder: 'sz',
                        children: []
                    }

                ]
            },
            // {
            //     nodeid: '8',
            //     name: 'EOU',
            //     children: []
            // },
            {
                nodeid: '9',
                name: 'Budget',
                children: []
            },
            {
                nodeid: '10',
                name: 'Question Bank',
                children: [
                    {
                        nodeid: '10.1',
                        name: 'CBIC FAQ',
                        subfolder: 'gtfaqb',
                        mainfolder: 'gt',
                        children: []
                    },
                    {
                        nodeid: '10.2',
                        name: 'Ask-A-Question',
                        subfolder: 'gtillu',
                        mainfolder: 'gt',
                        children: []
                    },
                ]
            },
            // {
            //     nodeid: '11',
            //     name: 'Allied Act',
            //     children: []
            // },
            {
                nodeid: '12',
                name: 'Case Law',
                children: [
                    {
                        nodeid: '12.1',
                        name: 'Supreme Court',
                        subfolder: 'clsupr',
                        mainfolder: 'cl',
                        children: []
                    },
                    {
                        nodeid: '12.2',
                        name: 'High Court',
                        subfolder: 'clhigh',
                        mainfolder: 'cl',
                        children: []
                    },
                    {
                        nodeid: '12.3',
                        name: 'Tribunal',
                        children: [
                            {
                                nodeid: '12.3.1',
                                name: 'Bangalore',
                                subfolder: 'cltban',
                                mainfolder: 'cl',
                                children: []
                            },
                            {
                                nodeid: '12.3.2',
                                name: 'Chennai',
                                subfolder: 'cltche',
                                mainfolder: 'cl',
                                children: []
                            },
                            {
                                nodeid: '12.3.3',
                                name: 'Delhi',
                                subfolder: 'cltdel',
                                mainfolder: 'cl',
                                children: []
                            },
                            {
                                nodeid: '12.3.4',
                                name: 'Kolkata',
                                subfolder: 'cltkol',
                                mainfolder: 'cl',
                                children: []
                            },

                            {
                                nodeid: '12.3.5',
                                name: 'Mumbai',
                                subfolder: 'cltmum',
                                mainfolder: 'cl',
                                children: []
                            },
                            {
                                nodeid: '12.3.6',
                                name: 'Hyderabad',
                                subfolder: 'clthyd',
                                mainfolder: 'cl',
                                children: []
                            },
                            // {
                            //     nodeid: '12.10',
                            //     name: 'Ahmedabad Tribunal',
                            //     subfolder: 'cltahm',
                            //     mainfolder: 'cl',
                            //     children: []
                            // },
                            // {
                            //     nodeid: '12.11',
                            //     name: 'Tribunal Others',
                            //     subfolder: 'cltrib',
                            //     mainfolder: 'cl',
                            //     children: []
                            // },
                            {
                                nodeid: '12.3.7',
                                name: 'Others',
                                subfolder: 'cltrib',
                                mainfolder: 'cl',
                                children: []
                            },
                            {
                                nodeid: '12.3.8',
                                name: 'Larger Bench',
                                subfolder: 'cltlar',
                                mainfolder: 'cl',
                                children: []
                            },
                        ]
                    },

                    {
                        nodeid: '12.4',
                        name: 'Napa',
                        subfolder: 'clnapa',
                        mainfolder: 'cl',
                        children: []
                    },
                    {
                        nodeid: '12.5',
                        name: 'Others',
                        subfolder: 'cloths',
                        mainfolder: 'cl',
                        children: []
                    }
                    // ,
                    // {
                    //     nodeid: '12.14',
                    //     name: 'AAR',
                    //     subfolder: 'claarc',
                    //     mainfolder: 'cl',
                    //     children: []
                    // },
                    // {
                    //     nodeid: '12.15',
                    //     name: 'AAAR',
                    //     subfolder: 'claaar',
                    //     mainfolder: 'cl',
                    //     children: []
                    // },
                ]
            },
            {
                nodeid: '13',
                name: 'Advance Ruling',
                children: [
                    {
                        nodeid: '13.1',
                        name: 'AAR',
                        subfolder: 'claarc',
                        mainfolder: 'cl',
                        children: []
                    },
                    {
                        nodeid: '13.2',
                        name: 'AAAR',
                        subfolder: 'claaar',
                        mainfolder: 'cl',
                        children: []
                    },
                ]
            },
            {
                nodeid: '14',
                name: 'Forms',
                children: []
            },
            {
                nodeid: '15',
                name: 'Tariff',
                subfolder: 'cutari',
                mainfolder: 'cu',
                children: []
            },
        ]



        var map = new Map()

        let showFiles = [
            'gtcgst', 'gtstrl', 'gtigst', 'gtistr', 'gtugst', 'gtcomp', 'gtnoti', 'gtcgcu', 'gtpres', 'gtport', 'gtcfrm', 'gtfaqb', 'gtillu',
            'cuacts', 'curule', 'curegu', 'cuncuv', 'szrule', 'cumanu', 'cuform', , 'cutari',
            'ftacts', 'ftrule', 'ftcirc', 'ftnoti', 'ftappe', 'ftitch', 'ftrate', 'ftpoli', 'fttrad', 'ftpubl', 'ftproc', 'ftsion',
            'szacts', 'szpoli', 'szappe', 'szfema', 'sznoti', 'szcirc',
            'bu',
            'claaar', 'claarc', 'clhigh', 'clnapa', 'cloths', 'clsupr', 'cltahm', 'cltban', 'cltche', 'cltdel', 'clthyd', 'cltkol', 'cltmum', 'cltrib', 'cltlar',
            'ceacts', 'cerule', 'cencec', 'cencev', 'cemanu', 'cetari', 'ceform',
            'rbacts', 'rbrule', 'rbcirc', 'rbnoti', 'rbregu', 'rbciti', 'rbform'
        ]
        showFiles.forEach((subfolder) => {
            let arr = []
            input.forEach((el) => {
                if (el.filepath.includes('/' + subfolder + '/')) {
                    arr.push(el.filepath)
                }
            })
            map.set(subfolder, arr)
        })

        // function checks remarks of gtnoti search excel files
        // And filter the files that are required 
        const checkRemarks = (subfolder, splitArr, escapeChar) => {
            var splitTest = function (str) {
                return str.split('\\').pop().split('/').pop();
            }
            // console.log(escapeChar)
            if (splitArr.length > 0) {
                let arr = []
                if (escapeChar != undefined) {
                    subfolder.forEach((folder) => {
                        arr.push(...map.get(folder).filter((filepath) => splitArr.some((spliter) => splitTest(filepath).toLowerCase().includes(spliter.toLowerCase()) && !splitTest(filepath).toLowerCase().includes(escapeChar.toLowerCase()))))
                    })
                } else {
                    subfolder.forEach((folder) => {
                        arr.push(...map.get(folder).filter((filepath) => splitArr.some((spliter) => splitTest(filepath).toLowerCase().includes(spliter.toLowerCase()))))
                    })
                }
                return arr
            } else {
                let arr = []
                if (escapeChar != undefined) {
                    subfolder.forEach((folder) => {
                        let bigArr = map.get(folder)
                        bigArr.forEach((filepath) => {
                            if (filepath.toLowerCase().includes(escapeChar.toLowerCase())) {
                                arr.push(filepath.split(escapeChar)[0])
                            }
                        })
                    })
                } else {
                    subfolder.forEach((folder) => {
                        arr.push(...map.get(folder))
                    })
                }
                return arr
            }
        }


        console.log(this.state.targetField)
        if (this.state.targetField != "" && this.state.targetField != "All") {
            // This code works when user point a specific folder from search modal
            newtree.forEach((parent) => {
                if (parent.name == "GST") {
                    parent.children.forEach((child) => {
                        // console.log(this.state.targetField, this.state.targetSubField)
                        // if (this.state.targetSubField == "All") {
                        //     this.state.targetSubField = 'Act'
                        // }
                        if (this.state.targetField == child.name && child.name == "CGST") {
                            child.children.forEach((el) => {
                                if (this.state.targetSubField == "All" && this.state.targetField == 'CGST') {
                                    if (el.name == 'Act' && el.subfolder == "gtcgst") {
                                        el.children = checkRemarks(['gtcgst'], [])
                                    } else if (el.name == 'Rules' && el.subfolder == 'gtstrl') {
                                        el.children = checkRemarks(['gtstrl'], [])
                                    } else if (el.name == "Notification - Other than rate of tax" && el.subfolder == 'gtnoti') {
                                        let includeArr = checkRemarks(['gtnoti'], ['gtnoti_cgst', 'gtnoti_cgstcori', 'gtcorri_cgst'])
                                        let notIncludeArr = checkRemarks(['gtnoti'], ['gtnoti_cgst_rate', 'gtcorri_cgst_'])
                                        el.children = includeArr.filter(el => !notIncludeArr.includes(el))
                                    } else if (el.name == 'Notification - relating to rate of tax' && el.subfolder == 'gtnoti') {
                                        el.children = checkRemarks(['gtnoti'], ['gtnoti_cgst_rate', 'gtcorri_cgst_'])
                                    } else if (el.name == 'Circular' && el.subfolder == 'gtnoti') {
                                        el.children = checkRemarks(['gtnoti'], ['cgst_circular_no', 'gtnoti_circular_no', 'cgstcirc'])
                                    } else if (el.name == 'Instructions' && el.subfolder == 'gtcgcu') {
                                        el.children = checkRemarks(['gtcgcu'], ['instruction'])
                                    } else if (el.name == 'Guidelines' && el.subfolder == 'gtcgcu') {
                                        el.children = checkRemarks(['gtcgcu'], ['guide_'])
                                    } else if (el.name == 'Order') {
                                        el.children.forEach((child_el) => {
                                            if (child_el.name == 'Order' && child_el.subfolder == 'gtnoti') {
                                                let includeArr = checkRemarks(['gtnoti'], ['cgst_circular_order', 'cgst_circular_orderno'])
                                                let notIncludeArr = checkRemarks(['gtnoti'], ['ct.htm', 'ct.html', '_centraltax.html', '_centraltax.htm'])
                                                child_el.children = includeArr.filter(el => !notIncludeArr.includes(el))
                                            } else if (child_el.name == 'Removal of Difficulty Order' && child_el.subfolder == 'gtnoti') {
                                                child_el.children = checkRemarks(['gtnoti'], ['ct.htm', 'ct.html', '_centraltax.html', '_centraltax.htm'])
                                            }
                                        })
                                    }
                                } else {
                                    if (this.state.targetSubField == el.name && this.state.targetSubField == 'Act' && el.subfolder == "gtcgst") {
                                        el.children = checkRemarks(['gtcgst'], [])
                                    } else if (this.state.targetSubField == el.name && this.state.targetSubField == 'Rules' && el.subfolder == 'gtstrl') {
                                        el.children = checkRemarks(['gtstrl'], [])
                                    } else if (this.state.targetSubField == el.name && this.state.targetSubField == "Notification - Other than rate of tax" && el.subfolder == 'gtnoti') {
                                        let includeArr = checkRemarks(['gtnoti'], ['gtnoti_cgst', 'gtnoti_cgstcori', 'gtcorri_cgst'])
                                        let notIncludeArr = checkRemarks(['gtnoti'], ['gtnoti_cgst_rate', 'gtcorri_cgst_'])
                                        el.children = includeArr.filter(el => !notIncludeArr.includes(el))
                                    } else if (this.state.targetSubField == el.name && this.state.targetSubField == 'Notification - relating to rate of tax' && el.subfolder == 'gtnoti') {
                                        el.children = checkRemarks(['gtnoti'], ['gtnoti_cgst_rate', 'gtcorri_cgst_'])
                                    } else if (this.state.targetSubField == el.name && this.state.targetSubField == 'Circular' && el.subfolder == 'gtnoti') {
                                        el.children = checkRemarks(['gtnoti'], ['cgst_circular_no', 'gtnoti_circular_no', 'cgstcirc'])
                                    } else if (this.state.targetSubField == el.name && this.state.targetSubField == 'Instructions' && el.subfolder == 'gtcgcu') {
                                        el.children = checkRemarks(['gtcgcu'], ['instruction'])
                                    } else if (this.state.targetSubField == el.name && this.state.targetSubField == 'Guidelines' && el.subfolder == 'gtcgcu') {
                                        el.children = checkRemarks(['gtcgcu'], ['guide_'])
                                    } else if (this.state.targetSubField == el.name && this.state.targetSubField == 'Order') {
                                        el.children.forEach((child_el) => {
                                            if (child_el.name == 'Order' && child_el.subfolder == 'gtnoti') {
                                                let includeArr = checkRemarks(['gtnoti'], ['cgst_circular_order', 'cgst_circular_orderno'])
                                                let notIncludeArr = checkRemarks(['gtnoti'], ['ct.htm', 'ct.html', '_centraltax.html', '_centraltax.htm'])
                                                child_el.children = includeArr.filter(el => !notIncludeArr.includes(el))
                                            } else if (child_el.name == 'Removal of Difficulty Order' && child_el.subfolder == 'gtnoti') {
                                                child_el.children = checkRemarks(['gtnoti'], ['ct.htm', 'ct.html', '_centraltax.html', '_centraltax.htm'])
                                            }
                                        })
                                    }
                                }
                            })
                        }
                        else if (this.state.targetField == child.name && this.state.targetField == 'IGST') {
                            child.children.forEach((el) => {
                                if (this.state.targetSubField == "All" && this.state.targetField == 'IGST') {
                                    if (el.name == 'Act' && el.subfolder == "gtigst") {
                                        el.children = checkRemarks(['gtigst'], [])
                                    } else if (el.name == 'Rules' && el.subfolder == 'gtistr') {
                                        el.children = checkRemarks(['gtistr'], [])
                                    } else if (el.name == "Notification - Other than rate of tax" && el.subfolder == 'gtnoti') {
                                        // el.children = checkRemarks(['gtnoti'], ['gtnoti_igst'])
                                        let includeArr = checkRemarks(['gtnoti'], ['gtnoti_igst'])
                                        let notIncludeArr = checkRemarks(['gtnoti'], ['gtnoti_igst_rate', 'gtcorri_igst_'])
                                        el.children = includeArr.filter(el => !notIncludeArr.includes(el))
                                    } else if (el.name == 'Notification - relating to rate of tax' && el.subfolder == 'gtnoti') {
                                        el.children = checkRemarks(['gtnoti'], ['gtnoti_igst_rate', 'gtcorri_igst_'])
                                    } else if (el.name == 'Circular' && el.subfolder == 'gtnoti') {
                                        el.children = checkRemarks(['gtnoti'], ['igst_circular', 'igst_circular_no'])
                                    }
                                } else {
                                    if (this.state.targetSubField == el.name && this.state.targetSubField == 'Act' && el.subfolder == "gtigst") {
                                        el.children = checkRemarks(['gtigst'], [])
                                    } else if (this.state.targetSubField == el.name && this.state.targetSubField == 'Rules' && el.subfolder == 'gtistr') {
                                        el.children = checkRemarks(['gtistr'], [])
                                    } else if (this.state.targetSubField == el.name && this.state.targetSubField == "Notification - Other than rate of tax" && el.subfolder == 'gtnoti') {
                                        // el.children = checkRemarks(['gtnoti'], ['gtnoti_igst'])
                                        let includeArr = checkRemarks(['gtnoti'], ['gtnoti_igst'])
                                        let notIncludeArr = checkRemarks(['gtnoti'], ['gtnoti_igst_rate', 'gtcorri_igst_'])
                                        el.children = includeArr.filter(el => !notIncludeArr.includes(el))
                                    } else if (this.state.targetSubField == el.name && this.state.targetSubField == 'Notification - relating to rate of tax' && el.subfolder == 'gtnoti') {
                                        el.children = checkRemarks(['gtnoti'], ['gtnoti_igst_rate', 'gtcorri_igst_'])
                                    } else if (this.state.targetSubField == el.name && this.state.targetSubField == 'Circular' && el.subfolder == 'gtnoti') {
                                        el.children = checkRemarks(['gtnoti'], ['igst_circular', 'igst_circular_no'])
                                    }
                                }
                            })
                        }
                        else if (this.state.targetField == child.name && this.state.targetField == 'UGST') {
                            child.children.forEach((el) => {
                                if (this.state.targetSubField == "All" && this.state.targetField == 'UGST') {
                                    if (el.name == 'Act' && el.subfolder == "gtugst") {
                                        el.children = checkRemarks(['gtugst'], ['utgst_bill_17_sec'])
                                    } else if (el.name == 'Rules' && el.subfolder == 'gtugst') {
                                        el.children = checkRemarks(['gtugst'], ['rule'])
                                    } else if (el.name == "Notification - Other than rate of tax" && el.subfolder == 'gtnoti') {
                                        let includeArr = checkRemarks(['gtnoti'], ['gtnoti_ugst', 'gtnoti_utgst'])
                                        let notIncludeArr = checkRemarks(['gtnoti'], ['gtnoti_ugst_rate', 'gtnoti_ugstrate', 'gtcorri_ugst_'])
                                        el.children = includeArr.filter(el => !notIncludeArr.includes(el))
                                    } else if (el.name == 'Notification - relating to rate of tax' && el.subfolder == 'gtnoti') {
                                        el.children = checkRemarks(['gtnoti'], ['gtnoti_ugst_rate', 'gtnoti_ugstrate', 'gtcorri_ugst_'])
                                    } else if (el.name == 'Order') {
                                        el.children.forEach((child_el) => {
                                            if (child_el.name == 'Removal of Difficulty Order' && child_el.subfolder == 'gtnoti') {
                                                child_el.children = checkRemarks(['gtnoti'], ['ugst_circular_order19_', 'cgst_circular_orderno'])
                                            }
                                        })
                                    }
                                } else {
                                    if (this.state.targetSubField == el.name && this.state.targetSubField == 'Act' && el.subfolder == "gtugst") {
                                        el.children = checkRemarks(['gtugst'], ['utgst_bill_17_sec'])
                                    } else if (this.state.targetSubField == el.name && this.state.targetSubField == 'Rules' && el.subfolder == 'gtugst') {
                                        el.children = checkRemarks(['gtugst'], ['rule'])
                                    } else if (this.state.targetSubField == el.name && this.state.targetSubField == "Notification - Other than rate of tax" && el.subfolder == 'gtnoti') {
                                        let includeArr = checkRemarks(['gtnoti'], ['gtnoti_ugst', 'gtnoti_utgst'])
                                        let notIncludeArr = checkRemarks(['gtnoti'], ['gtnoti_ugst_rate', 'gtnoti_ugstrate', 'gtcorri_ugst_'])
                                        el.children = includeArr.filter(el => !notIncludeArr.includes(el))
                                    } else if (this.state.targetSubField == el.name && this.state.targetSubField == 'Notification - relating to rate of tax' && el.subfolder == 'gtnoti') {
                                        el.children = checkRemarks(['gtnoti'], ['gtnoti_ugst_rate', 'gtnoti_ugstrate', 'gtcorri_ugst_'])
                                    } else if (this.state.targetSubField == el.name && this.state.targetSubField == 'Order') {
                                        el.children.forEach((child_el) => {
                                            if (child_el.name == 'Removal of Difficulty Order' && child_el.subfolder == 'gtnoti') {
                                                child_el.children = checkRemarks(['gtnoti'], ['ugst_circular_order19_', 'cgst_circular_orderno'])
                                            }
                                        })
                                    }
                                }

                            })
                        }
                        else if (this.state.targetField == child.name && this.state.targetField == 'GST (Compensation to the States) Act') {
                            child.children.forEach((el) => {
                                if (this.state.targetSubField == "All" && this.state.targetField == 'GST (Compensation to the States) Act') {
                                    if (el.name == 'Act' && el.subfolder == "gtcomp") {
                                        el.children = checkRemarks(['gtcomp'], ['com_act_', 'com_sch'])
                                    } else if (el.name == 'Rules' && el.subfolder == "gtcomp") {
                                        el.children = checkRemarks(['gtcomp'], ['com_Rule_'])
                                    } else if (el.name == "Notification - Other than rate of tax" && el.subfolder == 'gtnoti') {
                                        let includeArr = checkRemarks(['gtnoti'], ['gtnoti_compensation_cess'])
                                        let notIncludeArr = checkRemarks(['gtnoti'], ['gtnoti_compensation_cess_rate', 'gtnoti_comp_rate'])
                                        el.children = includeArr.filter(el => !notIncludeArr.includes(el))
                                    } else if (el.name == 'Notification - relating to rate of tax' && el.subfolder == 'gtnoti') {
                                        el.children = checkRemarks(['gtnoti'], ['gtnoti_compensation_cess_rate', 'gtnoti_comp_rate'])
                                    } else if (el.name == 'Circular' && el.subfolder == 'gtnoti') {
                                        el.children = checkRemarks(['gtnoti'], ['compcess_circular_no'])
                                    }
                                } else {
                                    if (this.state.targetSubField == el.name && this.state.targetSubField == 'Act' && el.subfolder == "gtcomp") {
                                        el.children = checkRemarks(['gtcomp'], ['com_act_', 'com_sch'])
                                    } else if (this.state.targetSubField == el.name && this.state.targetSubField == 'Rules' && el.subfolder == "gtcomp") {
                                        el.children = checkRemarks(['gtcomp'], ['com_Rule_'])
                                    } else if (this.state.targetSubField == el.name && this.state.targetSubField == "Notification - Other than rate of tax" && el.subfolder == 'gtnoti') {
                                        let includeArr = checkRemarks(['gtnoti'], ['gtnoti_compensation_cess'])
                                        let notIncludeArr = checkRemarks(['gtnoti'], ['gtnoti_compensation_cess_rate', 'gtnoti_comp_rate'])
                                        el.children = includeArr.filter(el => !notIncludeArr.includes(el))
                                    } else if (this.state.targetSubField == el.name && this.state.targetSubField == 'Notification - relating to rate of tax' && el.subfolder == 'gtnoti') {
                                        el.children = checkRemarks(['gtnoti'], ['gtnoti_compensation_cess_rate', 'gtnoti_comp_rate'])
                                    } else if (this.state.targetSubField == el.name && this.state.targetSubField == 'Circular' && el.subfolder == 'gtnoti') {
                                        el.children = checkRemarks(['gtnoti'], ['compcess_circular_no'])
                                    }
                                }

                            })
                        } else if (this.state.targetSubField == "All" && this.state.targetField == 'GST-Other') {
                            if (child.name == 'Press Release' && child.subfolder == 'gtpres') {
                                child.children = checkRemarks(['gtpres'], [])
                            } else if (child.name == 'Portal Related Updates' && child.subfolder == 'gtcgcu') {
                                child.children = checkRemarks(['gtcgcu'], ['por', 'pro', 'updates_einvoice', 'hsn_advisry_table_12_2', 'gstr_3b_advisry_190722', 'advisory_hsn_gstr1_fil', 'einvoicing'])
                            }
                            else if (child.name == 'Council Meeting Updates' && child.subfolder == 'gtport') {
                                child.children = checkRemarks(['gtport'], [])
                            } else if (child.name == 'Form' && child.subfolder == 'gtcfrm') {
                                child.children = checkRemarks(['gtcfrm'], [])
                            }
                        } else {
                            if (this.state.targetSubField == child.name && this.state.targetSubField == 'Press Release' && child.subfolder == 'gtpres') {
                                child.children = checkRemarks(['gtpres'], [])
                            } else if (this.state.targetSubField == child.name && this.state.targetSubField == 'Portal Related Updates' && child.subfolder == 'gtcgcu') {
                                child.children = checkRemarks(['gtcgcu'], ['por', 'pro', 'updates_einvoice', 'hsn_advisry_table_12_2', 'gstr_3b_advisry_190722', 'advisory_hsn_gstr1_fil', 'einvoicing'])
                            }
                            else if (this.state.targetSubField == child.name && this.state.targetSubField == 'Council Meeting Updates' && child.subfolder == 'gtport') {
                                child.children = checkRemarks(['gtport'], [])
                            } else if (this.state.targetSubField == child.name && this.state.targetSubField == 'Form' && child.subfolder == 'gtcfrm') {
                                child.children = checkRemarks(['gtcfrm'], [])
                            }
                        }
                    })
                } else if (parent.name == "Custom") {
                    parent.children.forEach((child) => {
                        if (this.state.targetSubField == "All" && this.state.targetField == 'Custom') {
                            if (child.name == 'Act' && child.subfolder == "cuacts") {
                                child.children = checkRemarks(['cuacts'], ['cuacts62', 'cuacts75'])
                            } else if (child.name == 'Rules' && child.subfolder == "curule") {
                                child.children = checkRemarks(['curule'], [])
                            } else if (child.name == 'Regulations' && child.subfolder == "curegu") {
                                child.children = checkRemarks(['curegu', 'curule', 'cuncuv', 'szrule'], ['curegu', 'cureg_', 'cu', 'cus', 'curule_premise_imp_exp_regulation.htm', '83573.htm', 'szrule'])
                            } else if (child.name == 'Manual' && child.subfolder == "cumanu") {
                                child.children = checkRemarks(['cumanu'], ['correlation_custariff', 'correlation_custariff2', 'guido_faceles_ch_', 'guido_faceles_annex_', 'cus_k9_manu_ch_', 'cus_k9_manu_app_', 'cumanu19_2.htm', 'cumanu19_app_'])
                            } else if (child.name == 'Form' && child.subfolder == "cuform") {
                                child.children = checkRemarks(['cuform'], [])
                            } else if (child.name == 'Notification') {
                                child.children.forEach((child_el) => {
                                    if (child_el.name == 'Tariff') {
                                        child_el.children.forEach((child_child_el) => {
                                            if (child_child_el.name == 'ADD' && child_child_el.subfolder == 'cuncuv') {
                                                child_child_el.children = checkRemarks(['cuncuv'], [], '##ADD')
                                            } else if (child_child_el.name == 'Safeguard' && child_child_el.subfolder == 'cuncuv') {
                                                child_child_el.children = checkRemarks(['cuncuv'], [], '##SG')
                                            } else if (child_child_el.name == 'CVD' && child_child_el.subfolder == 'cuncuv') {
                                                child_child_el.children = checkRemarks(['cuncuv'], [], '##CVD')
                                            } else if (child_child_el.name == 'Others' && child_child_el.subfolder == 'cuncuv') {
                                                child_child_el.children = checkRemarks(['cuncuv'], [], '##OTHER')
                                            }
                                        })
                                    } else if (child_el.name == 'Non Tariff' && child_el.subfolder == 'cuncuv') {
                                        child_el.children = checkRemarks(['cuncuv'], [], '##NT')
                                    }
                                })
                            }
                        } else {
                            if (this.state.targetSubField == child.name && this.state.targetSubField == 'Act' && child.subfolder == "cuacts") {
                                child.children = checkRemarks(['cuacts'], ['cuacts62', 'cuacts75'])
                            } else if (this.state.targetSubField == child.name && this.state.targetSubField == 'Rules' && child.subfolder == "curule") {
                                child.children = checkRemarks(['curule'], [])
                            } else if (this.state.targetSubField == child.name && this.state.targetSubField == 'Regulations' && child.subfolder == "curegu") {
                                child.children = checkRemarks(['curegu', 'curule', 'cuncuv', 'szrule'], ['curegu', 'cureg_', 'cu', 'cus', 'curule_premise_imp_exp_regulation.htm', '83573.htm', 'szrule'])
                            } else if (this.state.targetSubField == child.name && this.state.targetSubField == 'Manual' && child.subfolder == "cumanu") {
                                child.children = checkRemarks(['cumanu'], ['correlation_custariff', 'correlation_custariff2', 'guido_faceles_ch_', 'guido_faceles_annex_', 'cus_k9_manu_ch_', 'cus_k9_manu_app_', 'cumanu19_2.htm', 'cumanu19_app_'])
                            } else if (this.state.targetSubField == child.name && this.state.targetSubField == 'Form' && child.subfolder == "cuform") {
                                child.children = checkRemarks(['cuform'], [])
                            } else if (this.state.targetSubField == child.name && this.state.targetSubField == 'Notification') {
                                child.children.forEach((child_el) => {
                                    if (child_el.name == 'Tariff') {
                                        child_el.children.forEach((child_child_el) => {
                                            if (child_child_el.name == 'ADD' && child_child_el.subfolder == 'cuncuv') {
                                                child_child_el.children = checkRemarks(['cuncuv'], [], '##ADD')
                                            } else if (child_child_el.name == 'Safeguard' && child_child_el.subfolder == 'cuncuv') {
                                                child_child_el.children = checkRemarks(['cuncuv'], [], '##SG')
                                            } else if (child_child_el.name == 'CVD' && child_child_el.subfolder == 'cuncuv') {
                                                child_child_el.children = checkRemarks(['cuncuv'], [], '##CVD')
                                            } else if (child_child_el.name == 'Others' && child_child_el.subfolder == 'cuncuv') {
                                                child_child_el.children = checkRemarks(['cuncuv'], [], '##OTHER')
                                            }
                                        })
                                    } else if (child_el.name == 'Non Tariff' && child_el.subfolder == 'cuncuv') {
                                        child_el.children = checkRemarks(['cuncuv'], [], '##NT')
                                    }
                                })
                            }
                        }
                    })
                } else if (parent.name == 'Excise') {
                    parent.children.forEach((child) => {
                        if (this.state.targetSubField == "All" && this.state.targetField == 'Excise') {
                            if (child.name == 'Act' && child.subfolder == "ceacts") {
                                let arr = checkRemarks(['ceacts'], [])
                                arr.push(...checkRemarks(['cetari'], ['cetari08_Ist_sch.htm', 'cetari08_IInd_sch.htm', 'cetari13_IIIrd_sch.htm', 'cetari18_IVth_sch.htm']))
                                child.children = arr
                            } else if (child.name == 'Rules' && child.subfolder == "cerule") {
                                child.children = checkRemarks(['cerule'], [])
                            } else if (child.name == 'Circular' && child.subfolder == "cencec") {
                                child.children = checkRemarks(['cencec'], [])
                            } else if (child.name == 'Notification') {
                                child.children.forEach((child_el) => {
                                    if (child_el.name == 'Non Tariff' && child_el.subfolder == 'cencev') {
                                        child_el.children = checkRemarks(['cencev'], [], '##NT')
                                    } else if (child_el.name == 'Tariff' && child_el.subfolder == 'cencev') {
                                        child_el.children = checkRemarks(['cencev'], [], '##OTHER')
                                    }
                                })
                            } else if (child.name == 'Manual' && child.subfolder == "cemanu") {
                                child.children = checkRemarks(['cemanu'], [])
                            } else if (child.name == 'Form' && child.subfolder == "ceform") {
                                child.children = checkRemarks(['ceform'], [])
                            } else if (child.name == 'Tariff' && child.subfolder == "cetari") {
                                let arr = checkRemarks(['cetari'], [])
                                child.children = arr.filter((filepath) => !['cetari08_Ist_sch.htm', 'cetari08_IInd_sch.htm', 'cetari13_IIIrd_sch.htm', 'cetari18_IVth_sch.htm'].includes(filepath.split('\\').pop().split('/').pop()))
                            }
                        } else {
                            if (this.state.targetSubField == child.name && this.state.targetSubField == 'Act' && child.subfolder == "ceacts") {
                                let arr = checkRemarks(['ceacts'], [])
                                arr.push(...checkRemarks(['cetari'], ['cetari08_Ist_sch.htm', 'cetari08_IInd_sch.htm', 'cetari13_IIIrd_sch.htm', 'cetari18_IVth_sch.htm']))
                                child.children = arr
                            } else if (this.state.targetSubField == child.name && this.state.targetSubField == 'Rules' && child.subfolder == "cerule") {
                                child.children = checkRemarks(['cerule'], [])
                            } else if (this.state.targetSubField == child.name && this.state.targetSubField == 'Circular' && child.subfolder == "cencec") {
                                child.children = checkRemarks(['cencec'], [])
                            } else if (this.state.targetSubField == child.name && this.state.targetSubField == 'Notification') {
                                child.children.forEach((child_el) => {
                                    if (child_el.name == 'Non Tariff' && child_el.subfolder == 'cencev') {
                                        child_el.children = checkRemarks(['cencev'], [], '##NT')
                                    } else if (child_el.name == 'Tariff' && child_el.subfolder == 'cencev') {
                                        child_el.children = checkRemarks(['cencev'], [], '##OTHER')
                                    }
                                })
                            } else if (this.state.targetSubField == child.name && this.state.targetSubField == 'Manual' && child.subfolder == "cemanu") {
                                child.children = checkRemarks(['cemanu'], [])
                            } else if (this.state.targetSubField == child.name && this.state.targetSubField == 'Form' && child.subfolder == "ceform") {
                                child.children = checkRemarks(['ceform'], [])
                            } else if (this.state.targetSubField == child.name && this.state.targetSubField == 'Tariff' && child.subfolder == "cetari") {
                                let arr = checkRemarks(['cetari'], [])
                                child.children = arr.filter((filepath) => !['cetari08_Ist_sch.htm', 'cetari08_IInd_sch.htm', 'cetari13_IIIrd_sch.htm', 'cetari18_IVth_sch.htm'].includes(filepath.split('\\').pop().split('/').pop()))
                            }
                            // if (this.state.targetSubField == child.name && this.state.targetSubField == 'AAR' && child.subfolder == "claarc") {
                            //     child.children = checkRemarks(['claarc'], [])
                            // } else if (this.state.targetSubField == child.name && this.state.targetSubField == 'AAAR' && child.subfolder == "claaar") {
                            //     child.children = checkRemarks(['claaar'], [])
                            // }
                        }
                    })
                } else if (parent.name == 'FTP') {
                    parent.children.forEach((child) => {
                        if (this.state.targetSubField == "All" && this.state.targetField == 'Foreign Trade Policy') {
                            if (child.name == 'Act' && child.subfolder == "ftacts") {
                                child.children = checkRemarks(['ftacts'], [])
                            } else if (child.name == 'Rules' && child.subfolder == "ftrule") {
                                child.children = checkRemarks(['ftrule'], [])
                            } else if (child.name == 'Circular' && child.subfolder == "ftcirc") {
                                child.children = checkRemarks(['ftcirc'], [])
                            } else if (child.name == 'Notification' && child.subfolder == "ftnoti") {
                                child.children = checkRemarks(['ftnoti'], [])
                            } else if (child.name == 'Appendix(Form)' && child.subfolder == "ftappe") {
                                child.children = checkRemarks(['ftappe'], [])
                            } else if (child.name == 'ITC (HS)' && child.subfolder == "ftitch") {
                                child.children = checkRemarks(['ftitch'], [])
                            } else if (child.name == 'DEPB DrawBack' && child.subfolder == "ftrate") {
                                child.children = checkRemarks(['ftrate'], [])
                            } else if (child.name == 'Policy' && child.subfolder == "ftpoli") {
                                child.children = checkRemarks(['ftpoli'], [])
                            } else if (child.name == 'Trade Notice' && child.subfolder == "fttrad") {
                                child.children = checkRemarks(['fttrad'], [])
                            } else if (child.name == 'Public Notice' && child.subfolder == "ftpubl") {
                                child.children = checkRemarks(['ftpubl'], [])
                            } else if (child.name == 'HB Procedure' && child.subfolder == "ftproc") {
                                child.children = checkRemarks(['ftproc'], [])
                            } else if (child.name == 'SION' && child.subfolder == "ftsion") {
                                child.children = checkRemarks(['ftsion'], [])
                            }
                        } else {
                            if (this.state.targetSubField == child.name && this.state.targetSubField == 'Act' && child.subfolder == "ftacts") {
                                child.children = checkRemarks(['ftacts'], [])
                            } else if (this.state.targetSubField == child.name && this.state.targetSubField == 'Rules' && child.subfolder == "ftrule") {
                                child.children = checkRemarks(['ftrule'], [])
                            } else if (this.state.targetSubField == child.name && this.state.targetSubField == 'Circular' && child.subfolder == "ftcirc") {
                                child.children = checkRemarks(['ftcirc'], [])
                            } else if (this.state.targetSubField == child.name && this.state.targetSubField == 'Notification' && child.subfolder == "ftnoti") {
                                child.children = checkRemarks(['ftnoti'], [])
                            } else if (this.state.targetSubField == child.name && this.state.targetSubField == 'Appendix(Form)' && child.subfolder == "ftappe") {
                                child.children = checkRemarks(['ftappe'], [])
                            } else if (this.state.targetSubField == child.name && this.state.targetSubField == 'ITC (HS)' && child.subfolder == "ftitch") {
                                child.children = checkRemarks(['ftitch'], [])
                            } else if (this.state.targetSubField == child.name && this.state.targetSubField == 'DEPB DrawBack' && child.subfolder == "ftrate") {
                                child.children = checkRemarks(['ftrate'], [])
                            } else if (this.state.targetSubField == child.name && this.state.targetSubField == 'Policy' && child.subfolder == "ftpoli") {
                                child.children = checkRemarks(['ftpoli'], [])
                            } else if (this.state.targetSubField == child.name && this.state.targetSubField == 'Trade Notice' && child.subfolder == "fttrad") {
                                child.children = checkRemarks(['fttrad'], [])
                            } else if (this.state.targetSubField == child.name && this.state.targetSubField == 'Public Notice' && child.subfolder == "ftpubl") {
                                child.children = checkRemarks(['ftpubl'], [])
                            } else if (this.state.targetSubField == child.name && this.state.targetSubField == 'HB Procedure' && child.subfolder == "ftproc") {
                                child.children = checkRemarks(['ftproc'], [])
                            } else if (this.state.targetSubField == child.name && this.state.targetSubField == 'SION' && child.subfolder == "ftsion") {
                                child.children = checkRemarks(['ftsion'], [])
                            }
                        }
                    })
                } else if (parent.name == 'SEZ') {
                    parent.children.forEach((child) => {
                        if (this.state.targetSubField == "All" && this.state.targetField == 'SEZ') {
                            if (child.name == 'Act' && child.subfolder == "szacts") {
                                child.children = checkRemarks(['szacts'], [])
                            } else if (child.name == 'Rules' && child.subfolder == "szrule") {
                                child.children = checkRemarks(['szrule'], [])
                            } else if (child.name == 'Policy' && child.subfolder == "szpoli") {
                                child.children = checkRemarks(['szpoli'], [])
                            } else if (child.name == 'Appendix' && child.subfolder == "szappe") {
                                child.children = checkRemarks(['szappe'], [])
                            } else if (child.name == 'Fema on SEZ' && child.subfolder == "szfema") {
                                child.children = checkRemarks(['szfema'], [])
                            } else if (child.name == 'Notification' && child.subfolder == "sznoti") {
                                child.children = checkRemarks(['sznoti'], [])
                            } else if (child.name == 'Circular' && child.subfolder == "szcirc") {
                                child.children = checkRemarks(['szcirc'], [])
                            }
                        } else {
                            if (this.state.targetSubField == child.name && this.state.targetSubField == 'Act' && child.subfolder == "szacts") {
                                child.children = checkRemarks(['szacts'], [])
                            } else if (this.state.targetSubField == child.name && this.state.targetSubField == 'Rules' && child.subfolder == "szrule") {
                                child.children = checkRemarks(['szrule'], [])
                            } else if (this.state.targetSubField == child.name && this.state.targetSubField == 'Policy' && child.subfolder == "szpoli") {
                                child.children = checkRemarks(['szpoli'], [])
                            } else if (this.state.targetSubField == child.name && this.state.targetSubField == 'Appendix' && child.subfolder == "szappe") {
                                child.children = checkRemarks(['szappe'], [])
                            } else if (this.state.targetSubField == child.name && this.state.targetSubField == 'Fema on SEZ' && child.subfolder == "szfema") {
                                child.children = checkRemarks(['szfema'], [])
                            } else if (this.state.targetSubField == child.name && this.state.targetSubField == 'Notification' && child.subfolder == "sznoti") {
                                child.children = checkRemarks(['sznoti'], [])
                            } else if (this.state.targetSubField == child.name && this.state.targetSubField == 'Circular' && child.subfolder == "szcirc") {
                                child.children = checkRemarks(['szcirc'], [])
                            }
                        }
                    })
                } else if (parent.name == 'Budget') {
                    if (this.state.targetSubField == "All") {
                        parent.children = checkRemarks(['bu'], [])
                    }
                } else if (parent.name == 'Tariff') {
                    if (this.state.targetSubField == "All") {
                        parent.children = checkRemarks(['cutari'], [])
                    }
                } else if (parent.name == "Case Law") {
                    parent.children.forEach((child) => {
                        if (this.state.targetSubField == "All" && this.state.targetField == 'Case Law') {
                            if (child.name == 'Supreme Court' && child.subfolder == "clsupr") {
                                child.children = checkRemarks(['clsupr'], [])
                            } else if (child.name == 'High Court' && child.subfolder == "clhigh") {
                                child.children = checkRemarks(['clhigh'], [])
                            } else if (child.name == 'Tribunal') {
                                child.children.forEach((child_el) => {
                                    if (child_el.name == 'Bangalore' && child_el.subfolder == "cltban") {
                                        child_el.children = checkRemarks(['cltban'], [])
                                    } else if (child_el.name == 'Chennai' && child_el.subfolder == "cltche") {
                                        child_el.children = checkRemarks(['cltche'], [])
                                    } else if (child_el.name == 'Delhi' && child_el.subfolder == "cltdel") {
                                        child_el.children = checkRemarks(['cltdel'], [])
                                    } else if (child_el.name == 'Kolkata' && child_el.subfolder == "cltkol") {
                                        child_el.children = checkRemarks(['cltkol'], [])
                                    } else if (child_el.name == 'Mumbai' && child_el.subfolder == "cltmum") {
                                        child_el.children = checkRemarks(['cltmum'], [])
                                    } else if (child_el.name == 'Hyderabad' && child_el.subfolder == "clthyd") {
                                        child_el.children = checkRemarks(['clthyd'], [])
                                    } else if (child_el.name == 'Others' && child_el.subfolder == "cltrib") {
                                        child_el.children = checkRemarks(['cltrib'], [])
                                    } else if (child_el.name == 'Larger Bench' && child_el.subfolder == "cltlar") {
                                        child_el.children = checkRemarks(['cltlar'], [])
                                    }
                                })
                            } else if (child.name == 'Napa' && child.subfolder == "clnapa") {
                                child.children = checkRemarks(['clnapa'], [])
                            } else if (child.name == 'Others' && child.subfolder == "cloths") {
                                child.children = checkRemarks(['cloths'], [])
                            }
                            // else if (child.name == 'AAR' && child.subfolder == "claarc") {
                            //     child.children = checkRemarks(['claarc'], [])
                            // } else if (child.name == 'AAAR' && child.subfolder == "claaar") {
                            //     child.children = checkRemarks(['claaar'], [])
                            // }
                        }
                    })
                } else if (parent.name == 'Advance Ruling') {
                    parent.children.forEach((child) => {
                        if (this.state.targetSubField == "All" && this.state.targetField == 'Advance Ruling') {
                            if (child.name == 'AAR' && child.subfolder == "claarc") {
                                child.children = checkRemarks(['claarc'], [])
                            } else if (child.name == 'AAAR' && child.subfolder == "claaar") {
                                child.children = checkRemarks(['claaar'], [])
                            }
                        } else {
                            if (this.state.targetSubField == child.name && this.state.targetSubField == 'AAR' && child.subfolder == "claarc") {
                                child.children = checkRemarks(['claarc'], [])
                            } else if (this.state.targetSubField == child.name && this.state.targetSubField == 'AAAR' && child.subfolder == "claaar") {
                                child.children = checkRemarks(['claaar'], [])
                            }
                        }
                    })
                } else if (parent.name == 'Question Bank') {
                    parent.children.forEach((child) => {
                        if (this.state.targetSubField == "All" && this.state.targetField == 'Question Bank') {
                            if (child.name == 'CBIC FAQ' && child.subfolder == "gtfaqb") {
                                child.children = checkRemarks(['gtfaqb'], [])
                            } else if (child.name == 'Ask-A-Question' && child.subfolder == "gtillu") {
                                child.children = checkRemarks(['gtillu'], [])
                            }
                        } else {
                            if (this.state.targetSubField == child.name && this.state.targetSubField == 'CBIC FAQ' && child.subfolder == "gtfaqb") {
                                child.children = checkRemarks(['gtfaqb'], [])
                            } else if (this.state.targetSubField == child.name && this.state.targetSubField == 'Ask-A-Question' && child.subfolder == "gtillu") {
                                child.children = checkRemarks(['gtillu'], [])
                            }
                        }
                    })
                } else if (parent.name == 'FEMA') {
                    parent.children.forEach((child) => {
                        if (this.state.targetSubField == "All" && this.state.targetField == 'FEMA') {
                            if (child.name == 'Act' && child.subfolder == "rbacts") {
                                child.children = checkRemarks(['rbacts'], [])
                            } else if (child.name == 'Rules' && child.subfolder == "rbrule") {
                                child.children = checkRemarks(['rbrule'], [])
                            } else if (child.name == 'Circular' && child.subfolder == "rbcirc") {
                                child.children = checkRemarks(['rbcirc'], [])
                            } else if (child.name == 'Notification' && child.subfolder == "rbnoti") {
                                child.children = checkRemarks(['rbnoti'], [])
                            } else if (child.name == 'Regulations' && child.subfolder == "rbregu") {
                                child.children = checkRemarks(['rbregu'], [])
                            } else if (child.name == 'Citizen Charter' && child.subfolder == "rbciti") {
                                child.children = checkRemarks(['rbciti'], [])
                            } else if (child.name == 'Form' && child.subfolder == "rbform") {
                                child.children = checkRemarks(['rbform'], [])
                            }
                        } else {
                            if (this.state.targetSubField == child.name && this.state.targetSubField == 'Act' && child.subfolder == "rbacts") {
                                child.children = checkRemarks(['rbacts'], [])
                            } else if (this.state.targetSubField == child.name && this.state.targetSubField == 'Rules' && child.subfolder == "rbrule") {
                                child.children = checkRemarks(['rbrule'], [])
                            } else if (this.state.targetSubField == child.name && this.state.targetSubField == 'Circular' && child.subfolder == "rbcirc") {
                                child.children = checkRemarks(['rbcirc'], [])
                            } else if (this.state.targetSubField == child.name && this.state.targetSubField == 'Notification' && child.subfolder == "rbnoti") {
                                child.children = checkRemarks(['rbnoti'], [])
                            } else if (this.state.targetSubField == child.name && this.state.targetSubField == 'Regulations' && child.subfolder == "rbregu") {
                                child.children = checkRemarks(['rbregu'], [])
                            } else if (this.state.targetSubField == child.name && this.state.targetSubField == 'Citizen Charter' && child.subfolder == "rbciti") {
                                child.children = checkRemarks(['rbciti'], [])
                            } else if (this.state.targetSubField == child.name && this.state.targetSubField == 'Form' && child.subfolder == "rbform") {
                                child.children = checkRemarks(['rbform'], [])
                            }
                        }
                    })
                }
            })

            const getTotalCount = (nodes) => {
                let total = 0
                nodes.map((node) => {
                    let node_files_count = this.getLeafCount(node)
                    console.log(node_files_count)
                    total += node_files_count
                })
                this.setState({ totalFilesCount: total })
            }
            getTotalCount(newtree)
            this.setState({ newTreeData: newtree })
            console.log(newtree, map)
        } else {
            // This code works when user point all folders from search modal
            newtree.forEach((parent) => {
                if (parent.name == "GST") {
                    parent.children.forEach((child) => {
                        if (child.name == "CGST") {
                            child.children.forEach((el) => {
                                if (el.name == 'Act' && el.subfolder == "gtcgst") {
                                    el.children = checkRemarks(['gtcgst'], [])
                                } else if (el.name == 'Rules' && el.subfolder == 'gtstrl') {
                                    el.children = checkRemarks(['gtstrl'], [])
                                } else if (el.name == "Notification - Other than rate of tax" && el.subfolder == 'gtnoti') {
                                    let includeArr = checkRemarks(['gtnoti'], ['gtnoti_cgst', 'gtnoti_cgstcori', 'gtcorri_cgst'])
                                    let notIncludeArr = checkRemarks(['gtnoti'], ['gtnoti_cgst_rate', 'gtcorri_cgst_'])
                                    el.children = includeArr.filter(el => !notIncludeArr.includes(el))
                                } else if (el.name == 'Notification - relating to rate of tax' && el.subfolder == 'gtnoti') {
                                    el.children = checkRemarks(['gtnoti'], ['gtnoti_cgst_rate', 'gtcorri_cgst_'])
                                } else if (el.name == 'Circular' && el.subfolder == 'gtnoti') {
                                    el.children = checkRemarks(['gtnoti'], ['cgst_circular_no', 'gtnoti_circular_no', 'cgstcirc'])
                                } else if (el.name == 'Instructions' && el.subfolder == 'gtcgcu') {
                                    el.children = checkRemarks(['gtcgcu'], ['instruction'])
                                } else if (el.name == 'Guidelines' && el.subfolder == 'gtcgcu') {
                                    el.children = checkRemarks(['gtcgcu'], ['guide_'])
                                } else if (el.name == 'Order') {
                                    el.children.forEach((child_el) => {
                                        if (child_el.name == 'Order' && child_el.subfolder == 'gtnoti') {
                                            let includeArr = checkRemarks(['gtnoti'], ['cgst_circular_order', 'cgst_circular_orderno'])
                                            let notIncludeArr = checkRemarks(['gtnoti'], ['ct.htm', 'ct.html', '_centraltax.html', '_centraltax.htm'])
                                            child_el.children = includeArr.filter(el => !notIncludeArr.includes(el))
                                        } else if (child_el.name == 'Removal of Difficulty Order' && child_el.subfolder == 'gtnoti') {
                                            child_el.children = checkRemarks(['gtnoti'], ['ct.htm', 'ct.html', '_centraltax.html', '_centraltax.htm'])
                                        }
                                    })
                                }
                            })
                        } else if (child.name == 'IGST') {
                            child.children.forEach((el) => {
                                if (el.name == 'Act' && el.subfolder == "gtigst") {
                                    el.children = checkRemarks(['gtigst'], [])
                                } else if (el.name == 'Rules' && el.subfolder == 'gtistr') {
                                    el.children = checkRemarks(['gtistr'], [])
                                } else if (el.name == "Notification - Other than rate of tax" && el.subfolder == 'gtnoti') {
                                    // el.children = checkRemarks(['gtnoti'], ['gtnoti_igst'])
                                    let includeArr = checkRemarks(['gtnoti'], ['gtnoti_igst'])
                                    let notIncludeArr = checkRemarks(['gtnoti'], ['gtnoti_igst_rate', 'gtcorri_igst_'])
                                    el.children = includeArr.filter(el => !notIncludeArr.includes(el))
                                } else if (el.name == 'Notification - relating to rate of tax' && el.subfolder == 'gtnoti') {
                                    el.children = checkRemarks(['gtnoti'], ['gtnoti_igst_rate', 'gtcorri_igst_'])
                                } else if (el.name == 'Circular' && el.subfolder == 'gtnoti') {
                                    el.children = checkRemarks(['gtnoti'], ['igst_circular', 'igst_circular_no'])
                                }
                            })
                        } else if (child.name == 'UGST') {
                            child.children.forEach((el) => {
                                if (el.name == 'Act' && el.subfolder == "gtugst") {
                                    el.children = checkRemarks(['gtugst'], ['utgst_bill_17_'])
                                } else if (el.name == 'Rules' && el.subfolder == 'gtugst') {
                                    el.children = checkRemarks(['gtugst'], ['rule'])
                                } else if (el.name == "Notification - Other than rate of tax" && el.subfolder == 'gtnoti') {
                                    let includeArr = checkRemarks(['gtnoti'], ['gtnoti_ugst', 'gtnoti_utgst'])
                                    let notIncludeArr = checkRemarks(['gtnoti'], ['gtnoti_ugst_rate', 'gtnoti_ugstrate', 'gtcorri_ugst_'])
                                    el.children = includeArr.filter(el => !notIncludeArr.includes(el))
                                } else if (el.name == 'Notification - relating to rate of tax' && el.subfolder == 'gtnoti') {
                                    el.children = checkRemarks(['gtnoti'], ['gtnoti_ugst_rate', 'gtnoti_ugstrate', 'gtcorri_ugst_'])
                                } else if (el.name == 'Order') {
                                    el.children.forEach((child_el) => {
                                        if (child_el.name == 'Removal of Difficulty Order' && child_el.subfolder == 'gtnoti') {
                                            child_el.children = checkRemarks(['gtnoti'], ['ugst_circular_order19_', 'cgst_circular_orderno'])
                                        }
                                    })
                                }
                            })
                        } else if (child.name == 'GST (Compensation to the States) Act') {
                            child.children.forEach((el) => {
                                if (el.name == 'Act' && el.subfolder == "gtcomp") {
                                    el.children = checkRemarks(['gtcomp'], ['com_act_', 'com_sch'])
                                } else if (el.name == 'Rules' && el.subfolder == "gtcomp") {
                                    el.children = checkRemarks(['gtcomp'], ['com_Rule_'])
                                } else if (el.name == "Notification - Other than rate of tax" && el.subfolder == 'gtnoti') {
                                    let includeArr = checkRemarks(['gtnoti'], ['gtnoti_compensation_cess'])
                                    let notIncludeArr = checkRemarks(['gtnoti'], ['gtnoti_compensation_cess_rate', 'gtnoti_comp_rate'])
                                    el.children = includeArr.filter(el => !notIncludeArr.includes(el))
                                } else if (el.name == 'Notification - relating to rate of tax' && el.subfolder == 'gtnoti') {
                                    el.children = checkRemarks(['gtnoti'], ['gtnoti_compensation_cess_rate', 'gtnoti_comp_rate'])
                                } else if (el.name == 'Circular' && el.subfolder == 'gtnoti') {
                                    el.children = checkRemarks(['gtnoti'], ['compcess_circular_no'])
                                }
                            })
                        } else if (child.name == 'Press Release' && child.subfolder == 'gtpres') {
                            child.children = checkRemarks(['gtpres'], [])
                        } else if (child.name == 'Portal Related Updates' && child.subfolder == 'gtcgcu') {
                            child.children = checkRemarks(['gtcgcu'], ['por', 'pro', 'updates_einvoice', 'hsn_advisry_table_12_2', 'gstr_3b_advisry_190722', 'advisory_hsn_gstr1_fil', 'einvoicing'])
                        }
                        else if (child.name == 'Council Meeting Updates' && child.subfolder == 'gtport') {
                            child.children = checkRemarks(['gtport'], [])
                        } else if (child.name == 'Form' && child.subfolder == 'gtcfrm') {
                            child.children = checkRemarks(['gtcfrm'], [])
                        }
                    })
                } else if (parent.name == "Custom") {
                    parent.children.forEach((child) => {
                        if (child.name == 'Act' && child.subfolder == "cuacts") {
                            child.children = checkRemarks(['cuacts'], ['cuacts62', 'cuacts75'])
                        } else if (child.name == 'Rules' && child.subfolder == "curule") {
                            child.children = checkRemarks(['curule'], [])
                        } else if (child.name == 'Regulations' && child.subfolder == "curegu") {
                            child.children = checkRemarks(['curegu', 'curule', 'cuncuv', 'szrule'], ['curegu', 'cureg_', 'cu', 'cus', 'curule_premise_imp_exp_regulation.htm', '83573.htm', 'szrule'])
                        } else if (child.name == 'Manual' && child.subfolder == "cumanu") {
                            child.children = checkRemarks(['cumanu'], ['correlation_custariff', 'correlation_custariff2', 'guido_faceles_ch_', 'guido_faceles_annex_', 'cus_k9_manu_ch_', 'cus_k9_manu_app_', 'cumanu19_2.htm', 'cumanu19_app_'])
                        } else if (child.name == 'Form' && child.subfolder == "cuform") {
                            child.children = checkRemarks(['cuform'], [])
                        } else if (child.name == 'Notification') {
                            child.children.forEach((child_el) => {
                                if (child_el.name == 'Tariff') {
                                    child_el.children.forEach((child_child_el) => {
                                        if (child_child_el.name == 'ADD' && child_child_el.subfolder == 'cuncuv') {
                                            child_child_el.children = checkRemarks(['cuncuv'], [], '##ADD')
                                        } else if (child_child_el.name == 'Safeguard' && child_child_el.subfolder == 'cuncuv') {
                                            child_child_el.children = checkRemarks(['cuncuv'], [], '##SG')
                                        } else if (child_child_el.name == 'CVD' && child_child_el.subfolder == 'cuncuv') {
                                            child_child_el.children = checkRemarks(['cuncuv'], [], '##CVD')
                                        } else if (child_child_el.name == 'Others' && child_child_el.subfolder == 'cuncuv') {
                                            child_child_el.children = checkRemarks(['cuncuv'], [], '##OTHER')
                                        }
                                    })
                                } else if (child_el.name == 'Non Tariff' && child_el.subfolder == 'cuncuv') {
                                    child_el.children = checkRemarks(['cuncuv'], [], '##NT')
                                }
                            })
                        }
                    })
                } else if (parent.name == 'Excise') {
                    parent.children.forEach((child) => {
                        if (child.name == 'Act' && child.subfolder == "ceacts") {
                            let arr = checkRemarks(['ceacts'], [])
                            arr.push(...checkRemarks(['cetari'], ['cetari08_Ist_sch.htm', 'cetari08_IInd_sch.htm', 'cetari13_IIIrd_sch.htm', 'cetari18_IVth_sch.htm']))
                            child.children = arr
                        } else if (child.name == 'Rules' && child.subfolder == "cerule") {
                            child.children = checkRemarks(['cerule'], [])
                        } else if (child.name == 'Circular' && child.subfolder == "cencec") {
                            child.children = checkRemarks(['cencec'], [])
                        } else if (child.name == 'Notification') {
                            child.children.forEach((child_el) => {
                                if (child_el.name == 'Non Tariff' && child_el.subfolder == 'cencev') {
                                    child_el.children = checkRemarks(['cencev'], [], '##NT')
                                } else if (child_el.name == 'Tariff' && child_el.subfolder == 'cencev') {
                                    child_el.children = checkRemarks(['cencev'], [], '##OTHER')
                                }
                            })
                        } else if (child.name == 'Manual' && child.subfolder == "cemanu") {
                            child.children = checkRemarks(['cemanu'], [])
                        } else if (child.name == 'Form' && child.subfolder == "ceform") {
                            child.children = checkRemarks(['ceform'], [])
                        } else if (child.name == 'Tariff' && child.subfolder == "cetari") {
                            let arr = checkRemarks(['cetari'], [])
                            child.children = arr.filter((filepath) => !['cetari08_Ist_sch.htm', 'cetari08_IInd_sch.htm', 'cetari13_IIIrd_sch.htm', 'cetari18_IVth_sch.htm'].includes(filepath.split('\\').pop().split('/').pop()))
                        }
                    })
                } else if (parent.name == "FTP") {
                    parent.children.forEach((child) => {
                        if (child.name == 'Act' && child.subfolder == "ftacts") {
                            child.children = checkRemarks(['ftacts'], [])
                        } else if (child.name == 'Rules' && child.subfolder == "ftrule") {
                            child.children = checkRemarks(['ftrule'], [])
                        } else if (child.name == 'Circular' && child.subfolder == "ftcirc") {
                            child.children = checkRemarks(['ftcirc'], [])
                        } else if (child.name == 'Notification' && child.subfolder == "ftnoti") {
                            child.children = checkRemarks(['ftnoti'], [])
                        } else if (child.name == 'Appendix(Form)' && child.subfolder == "ftappe") {
                            child.children = checkRemarks(['ftappe'], [])
                        } else if (child.name == 'ITC (HS)' && child.subfolder == "ftitch") {
                            child.children = checkRemarks(['ftitch'], [])
                        } else if (child.name == 'DEPB DrawBack' && child.subfolder == "ftrate") {
                            child.children = checkRemarks(['ftrate'], [])
                        } else if (child.name == 'Policy' && child.subfolder == "ftpoli") {
                            child.children = checkRemarks(['ftpoli'], [])
                        } else if (child.name == 'Trade Notice' && child.subfolder == "fttrad") {
                            child.children = checkRemarks(['fttrad'], [])
                        } else if (child.name == 'Public Notice' && child.subfolder == "ftpubl") {
                            child.children = checkRemarks(['ftpubl'], [])
                        } else if (child.name == 'HB Procedure' && child.subfolder == "ftproc") {
                            child.children = checkRemarks(['ftproc'], [])
                        } else if (child.name == 'SION' && child.subfolder == "ftsion") {
                            child.children = checkRemarks(['ftsion'], [])
                        }
                    })
                } else if (parent.name == "SEZ") {
                    parent.children.forEach((child) => {
                        if (child.name == 'Act' && child.subfolder == "szacts") {
                            child.children = checkRemarks(['szacts'], [])
                        } else if (child.name == 'Rules' && child.subfolder == "szrule") {
                            child.children = checkRemarks(['szrule'], [])
                        } else if (child.name == 'Policy' && child.subfolder == "szpoli") {
                            child.children = checkRemarks(['szpoli'], [])
                        } else if (child.name == 'Appendix' && child.subfolder == "szappe") {
                            child.children = checkRemarks(['szappe'], [])
                        } else if (child.name == 'Fema on SEZ' && child.subfolder == "szfema") {
                            child.children = checkRemarks(['szfema'], [])
                        } else if (child.name == 'Notification' && child.subfolder == "sznoti") {
                            child.children = checkRemarks(['sznoti'], [])
                        } else if (child.name == 'Circular' && child.subfolder == "szcirc") {
                            child.children = checkRemarks(['szcirc'], [])
                        }
                    })
                } else if (parent.name == 'Budget') {
                    parent.children = checkRemarks(['bu'], [])
                } else if (parent.name == 'Tariff') {
                    parent.children = checkRemarks(['cutari'], [])
                } else if (parent.name == 'Case Law') {
                    parent.children.forEach((child) => {
                        if (child.name == 'Supreme Court' && child.subfolder == "clsupr") {
                            child.children = checkRemarks(['clsupr'], [])
                        } else if (child.name == 'High Court' && child.subfolder == "clhigh") {
                            child.children = checkRemarks(['clhigh'], [])
                        } else if (child.name == 'Tribunal') {
                            child.children.forEach((child_el) => {
                                if (child_el.name == 'Bangalore' && child_el.subfolder == "cltban") {
                                    child_el.children = checkRemarks(['cltban'], [])
                                } else if (child_el.name == 'Chennai' && child_el.subfolder == "cltche") {
                                    child_el.children = checkRemarks(['cltche'], [])
                                } else if (child_el.name == 'Delhi' && child_el.subfolder == "cltdel") {
                                    child_el.children = checkRemarks(['cltdel'], [])
                                } else if (child_el.name == 'Kolkata' && child_el.subfolder == "cltkol") {
                                    child_el.children = checkRemarks(['cltkol'], [])
                                } else if (child_el.name == 'Mumbai' && child_el.subfolder == "cltmum") {
                                    child_el.children = checkRemarks(['cltmum'], [])
                                } else if (child_el.name == 'Hyderabad' && child_el.subfolder == "clthyd") {
                                    child_el.children = checkRemarks(['clthyd'], [])
                                } else if (child_el.name == 'Others' && child_el.subfolder == "cltrib") {
                                    child_el.children = checkRemarks(['cltrib'], [])
                                } else if (child_el.name == 'Larger Bench' && child_el.subfolder == "cltlar") {
                                    child_el.children = checkRemarks(['cltlar'], [])
                                }
                            })
                        } else if (child.name == 'Napa' && child.subfolder == "clnapa") {
                            child.children = checkRemarks(['clnapa'], [])
                        } else if (child.name == 'Others' && child.subfolder == "cloths") {
                            child.children = checkRemarks(['cloths'], [])
                        }
                    })
                } else if (parent.name == "Advance Ruling") {
                    parent.children.forEach((child) => {
                        if (child.name == 'AAR' && child.subfolder == "claarc") {
                            child.children = checkRemarks(['claarc'], [])
                        } else if (child.name == 'AAAR' && child.subfolder == "claaar") {
                            child.children = checkRemarks(['claaar'], [])
                        }
                    })
                } else if (parent.name == "Question Bank") {
                    parent.children.forEach((child) => {
                        if (child.name == 'CBIC FAQ' && child.subfolder == "gtfaqb") {
                            child.children = checkRemarks(['gtfaqb'], [])
                        } else if (child.name == 'Ask-A-Question' && child.subfolder == "gtillu") {
                            child.children = checkRemarks(['gtillu'], [])
                        }
                    })
                } else if (parent.name == 'FEMA') {
                    parent.children.forEach((child) => {
                        if (child.name == 'Act' && child.subfolder == "rbacts") {
                            child.children = checkRemarks(['rbacts'], [])
                        } else if (child.name == 'Rules' && child.subfolder == "rbrule") {
                            child.children = checkRemarks(['rbrule'], [])
                        } else if (child.name == 'Circular' && child.subfolder == "rbcirc") {
                            child.children = checkRemarks(['rbcirc'], [])
                        } else if (child.name == 'Notification' && child.subfolder == "rbnoti") {
                            child.children = checkRemarks(['rbnoti'], [])
                        } else if (child.name == 'Regulations' && child.subfolder == "rbregu") {
                            child.children = checkRemarks(['rbregu'], [])
                        } else if (child.name == 'Citizen Charter' && child.subfolder == "rbciti") {
                            child.children = checkRemarks(['rbciti'], [])
                        } else if (child.name == 'Form' && child.subfolder == "rbform") {
                            child.children = checkRemarks(['rbform'], [])
                        }
                    })
                }
            })

            const getTotalCount = (nodes) => {
                let total = 0
                nodes.map((node) => {
                    let node_files_count = this.getLeafCount(node)
                    console.log(node_files_count)
                    total += node_files_count
                })
                this.setState({ totalFilesCount: total })
            }
            getTotalCount(newtree)
            this.setState({ newTreeData: newtree })
            console.log(newtree, map)
        }

    }

    // Call when searching through both content & perameters
    onCombineSearch = (allfiles, searchWord, url) => new Promise((resolve, reject) => {
        let obj = {
            allfiles: allfiles.join('##12##'),
            url: url,
            search: `${searchWord}`,
            searchType: `${this.state.searchType}`,
        }
        console.log(obj)
        Hexapi(obj).then(resp => {
            console.log(resp)
            resolve(resp)
        }).catch(err => reject(err))
    })

    // Call when only serching through parameters
    onParametricSearch = (allfiles, url) => new Promise((resolve, reject) => {
        this.setState({ isHistoryOpen: false, openInitialModal: false, isFetchFileApiHitComplete: false, isFirstSearchComplete: true, isApiHitComplete: false, isDataFound: false, selectedFile: '' })
        let obj = {
            allfiles: allfiles,
            url: url
        }
        Hexapi(obj).then(resp => {
            console.log(resp)
            resolve(resp)
        }).catch(err => reject(err))
    })

    // Call when showing popup of No record found
    noRecordFound() {
        if (this.state.allData.length > 0) {
            Swal.fire({
                text: 'No Record Found',
            }).then(() => {
                this.setState({ isDataFound: true })
            })
        } else {
            this.setState({ isDataFound: false })
        }
    }


    refined_combine_search(searchWord, searchurl) {
        // This if part works on refined search + content search + parametric search
        this.setState({ isOnlyParamSearch: false, isHistoryOpen: false, openInitialModal: false, isFetchFileApiHitComplete: false, isFirstSearchComplete: true, isApiHitComplete: false, selectedFile: '' })
        // let refinedArr = this.state.allData.filter((el) => el.count != 0)
        // let nonRefinedArr = this.state.allData.filter((el) => el.count == 0)

        const refinedArr = this.state.allData
        console.log(refinedArr)
        let filesArr = []
        refinedArr.forEach((el) => {
            if (el.filepath.includes('/cl/')) {
                filesArr.push(el.filepath.substring(el.filepath.lastIndexOf('/') + 1).split('.')[0])
            }
        })
        console.log(filesArr)
        if (filesArr.length > 0) {
            let obj = {
                query: `[dbo].[Lawcrux_Web_Proc_RefinedSearch_GetData]
        @Files='{0}',
        @JudgeID='{1}',
        @PartyID='{2}',
        @Landmark='{3}',
        @Order='{4}',
        @Citation='{5}',
        @CitationValue='{6}',
        @FromDate='{7}',
        @ToDate='{8}',
        @Court='{9}',
        @Favour='{10}'`,
                queryArr: [`${filesArr.join('##12##')}`, `${this.state.paramSearchObj.judge}`, `${this.state.paramSearchObj.party}`, `${this.state.paramSearchObj.landmark}`, `${this.state.paramSearchObj.order}`, `${this.state.paramSearchObj.citation}`, `${this.state.paramSearchObj.citationcode}`, `${this.state.paramSearchObj.fromdate}`, `${this.state.paramSearchObj.todate}`, `${this.state.paramSearchObj.court}`, `${this.state.paramSearchObj.favour}`]
            }
            Hexapi(obj).then(resp => {
                console.log(resp)
                let array_comes_from_paramSearch = [];
                let keys = Object.keys(resp)
                console.log(keys)
                for (let i = 0; i < keys.length; i++) {
                    let rs = resp[keys[i]];
                    for (let j = 0; j < rs.length; j++) {
                        let record = rs[j].code + '.htm';
                        array_comes_from_paramSearch.push(record)
                    }
                }
                if (array_comes_from_paramSearch.length > 0) {

                    let obj = {
                        'search': `${searchWord}`,
                        'searchType': `${this.state.searchType}`,
                        'url': this.props.searchApi,
                        'searchurl': searchurl
                    }
                    Hexapi(obj).then(re => {
                        if (re.length > 0) {
                            let array_comes_from_contentSearch = re
                            array_comes_from_contentSearch.forEach((data) => {
                                if (data.filepath.includes('\\')) {
                                    data.filepath = data.filepath.split('\\Web\\')[1].replaceAll('\\', "/")
                                }
                            })
                            console.log(array_comes_from_contentSearch, array_comes_from_paramSearch, refinedArr)
                            var common = refinedArr.filter(file => array_comes_from_paramSearch.some((filename) => file.filepath.includes(filename)) && array_comes_from_contentSearch.some((newfile) => file.filepath.includes(newfile.filepath)))

                            console.log(common)
                            this.createTree(common)





                            // var totalFilesCount = 0
                            // for (let i = 0; i < resp.length; i++) {
                            //     totalFilesCount += parseInt(resp[i].count)
                            // }
                            this.setState({ allData: common, isDataFound: true }, () => {
                                // console.log(this.state.treeData)
                            })
                        } else {
                            this.setState({ isApiHitComplete: true, paramSearchObj: null }, () => this.noRecordFound())
                        }
                    }).then(() => this.setState({ isApiHitComplete: true, paramSearchObj: null, isTimerModal: false, searchPath: 'Assets/Data5t/', selectedJudge: '' })).catch(err => {
                        console.error(err)
                        document.getElementById('loadingTimer').innerHTML = 'Something went wrong. Please try again.'
                    })
                    // this.onCombineSearch(jsonArr, searchWord, this.state.Combinesearchurl).then(re => {
                    //     if (re.length > 0) {
                    //         const input = [...re, ...nonRefinedArr]
                    //         var totalFilesCount = 0
                    //         for (let i = 0; i < re.length; i++) {
                    //             totalFilesCount += parseInt(re[i].count)
                    //         }
                    //         this.setState({ totalFilesCount: totalFilesCount, treeData: this.createTree(input), isDataFound: true }, () => {
                    //             console.log(this.state.treeData)
                    //         })
                    //     } else {
                    //         this.noRecordFound()
                    //     }
                    // }).then(res => this.setState({ isApiHitComplete: true, paramSearchObj: null })).catch(err => console.error(err))
                } else {
                    this.setState({ isApiHitComplete: true, paramSearchObj: null }, () => this.noRecordFound())
                }
            })
        } else {
            this.setState({ isApiHitComplete: true, paramSearchObj: null }, () => this.noRecordFound())
        }
    }

    refined_content_search(searchWord, searchurl) {
        // This if part works only refined search + content search
        this.setState({ isTimerModal: true, isOnlyParamSearch: false, isHistoryOpen: false, openInitialModal: false, isFetchFileApiHitComplete: false, isFirstSearchComplete: true, isApiHitComplete: false, isDataFound: false, selectedFile: '' })

        console.log(this.state.allData)
        const refinedArr = this.state.allData



        let obj = {
            'search': `${searchWord}`,
            'searchType': `${this.state.searchType}`,
            'url': this.props.searchApi,
            'searchurl': searchurl
        }

        Hexapi(obj).then(allfiles => {
            // console.log(allfiles, refinedArr)
            if (allfiles.length > 0) {
                const input = allfiles
                // for(let i=0;i<input.length;i++){
                //     let filepath = input[i].filepath
                //     if(filepath.includes('\\')){
                //         filepath = filepath.split('\\Web\\')[1]
                //     }
                // }
                var newPromise = new Promise((resolve, reject) => {
                    input.forEach((data) => {
                        if (data.filepath.includes('\\')) {
                            data.filepath = data.filepath.split('\\Web\\')[1].replaceAll('\\', "/")
                        }
                    })
                    // console.log(input,refinedArr)
                    resolve(input)
                })

                newPromise.then((d) => {
                    let common = refinedArr.filter((fileObj) => d.some((refinedObj) => fileObj.filepath.includes(refinedObj.filepath)))

                    console.log(common)
                    this.createTree(common)




                    // var totalFilesCount = 0
                    // for (let i = 0; i < resp.length; i++) {
                    //     totalFilesCount += parseInt(resp[i].count)
                    // }
                    this.setState({ allData: common, isDataFound: true }, () => {
                        // console.log(this.state.treeData)
                    })
                })
                // console.log(input)
                // console.log(this.state.newTreeData)


            } else {
                this.setState({ isDataFound: false })
            }
        }).then(() => this.setState({ isApiHitComplete: true, isTimerModal: false, searchPath: 'Assets/Data5t/', isRefinedSearch: false })).catch(err => {
            console.error(err)
            document.getElementById('loadingTimer').innerHTML = 'Something went wrong. Please try again.'
        })
    }

    refined_judge_search(searchurl) {
        this.setState({ isTimerModal: true, isOnlyParamSearch: false, isHistoryOpen: false, openInitialModal: false, isFetchFileApiHitComplete: false, isFirstSearchComplete: true, isApiHitComplete: false, isDataFound: false, selectedFile: '' })

        console.log(this.state.allData)
        const refinedArr = this.state.allData


        let obj = {
            'search': `${this.state.selectedJudge}`,
            'searchType': `2`,
            'url': this.props.searchApi,
            'searchurl': searchurl
        }
        Hexapi(obj).then(resp => {
            console.log(resp)

            if (resp.length > 0) {
                const input = resp
                var newPromise = new Promise((resolve, reject) => {
                    input.forEach((data) => {
                        if (data.filepath.includes('\\')) {
                            data.filepath = data.filepath.split('\\Web\\')[1].replaceAll('\\', "/")
                        }
                    })
                    resolve(input)
                })

                newPromise.then((d) => {
                    let common = refinedArr.filter((fileObj) => d.some((refinedObj) => fileObj.filepath.includes(refinedObj.filepath)))

                    console.log(common)
                    this.createTree(common)

                    this.setState({ allData: common, isDataFound: true }, () => {
                        // console.log(this.state.treeData)
                    })
                })

            } else {
                this.setState({ isDataFound: false })
            }
        }).then(() => this.setState({ isApiHitComplete: true, isTimerModal: false, searchPath: 'Assets/Data5t/', selectedJudge: '' })).catch(err => {
            console.error(err)
            document.getElementById('loadingTimer').innerHTML = 'Something went wrong. Please try again.'
        })
    }

    refined_parametric_search() {
        // this.setState({ isOnlyParamSearch: false, isHistoryOpen: false, openInitialModal: false, isFetchFileApiHitComplete: false, isFirstSearchComplete: true, isApiHitComplete: false, selectedFile: '' })
        this.setState({ isTimerModal: true, isOnlyParamSearch: false, isHistoryOpen: false, openInitialModal: false, isFetchFileApiHitComplete: false, isFirstSearchComplete: true, isApiHitComplete: false, isDataFound: false, selectedFile: '' })

        console.log(this.state.allData)
        const refinedArr = this.state.allData

        // let refinedArr = this.state.allData.filter((el) => el.count != 0)
        // let nonRefinedArr = this.state.allData.filter((el) => el.count == 0)
        console.log(refinedArr)
        let filesArr = []
        refinedArr.forEach((el) => {
            if (el.filepath.includes('/cl/')) {
                filesArr.push(el.filepath.substring(el.filepath.lastIndexOf('/') + 1).split('.')[0])
            }
        })
        console.log(filesArr)
        if (filesArr.length > 0) {
            let obj = {
                query: `[dbo].[Lawcrux_Web_Proc_RefinedSearch_GetData]
        @Files='{0}',
        @JudgeID='{1}',
        @PartyID='{2}',
        @Landmark='{3}',
        @Order='{4}',
        @Citation='{5}',
        @CitationValue='{6}',
        @FromDate='{7}',
        @ToDate='{8}',
        @Court='{9}',
        @Favour='{10}'`,
                queryArr: [`${filesArr.join('##12##')}`, `${this.state.paramSearchObj.judge}`, `${this.state.paramSearchObj.party}`, `${this.state.paramSearchObj.landmark}`, `${this.state.paramSearchObj.order}`, `${this.state.paramSearchObj.citation}`, `${this.state.paramSearchObj.citationcode}`, `${this.state.paramSearchObj.fromdate}`, `${this.state.paramSearchObj.todate}`, `${this.state.paramSearchObj.court}`, `${this.state.paramSearchObj.favour}`]
            }
            Hexapi(obj).then(resp => {
                console.log(resp)
                let jsonArr = [];
                let keys = Object.keys(resp)
                console.log(keys)
                for (let i = 0; i < keys.length; i++) {
                    let rs = resp[keys[i]];
                    for (let j = 0; j < rs.length; j++) {
                        let record = rs[j].code + '.htm';
                        jsonArr.push(record)
                    }
                }
                if (jsonArr.length > 0) {

                    console.log(refinedArr, jsonArr)
                    let common = refinedArr.filter((fileObj) => jsonArr.some((filename) => fileObj.filepath.includes(filename)))

                    console.log(common)
                    this.createTree(common)




                    // var totalFilesCount = 0
                    // for (let i = 0; i < resp.length; i++) {
                    //     totalFilesCount += parseInt(resp[i].count)
                    // }
                    this.setState({ allData: common, isDataFound: true }, () => {
                        // console.log(this.state.treeData)
                    })

                    // this.onParametricSearch(jsonArr.join('##12##'), this.state.ParametericsearchUrl).then(re => {
                    //     if (re.length > 0) {
                    //         const input = [...re, ...nonRefinedArr]
                    //         const allfiles = [...re, ...nonRefinedArr]
                    //         var totalFilesCount = 0
                    //         for (let i = 0; i < re.length; i++) {
                    //             totalFilesCount += parseInt(re[i].count)
                    //         }
                    //         this.setState({ totalFilesCount: totalFilesCount, treeData: this.createTree(input), isDataFound: true }, () => {
                    //             console.log(this.state.treeData)
                    //         })
                    //     } else {
                    //         this.setState({ isDataFound: false })
                    //     }
                    // }).then(() => this.setState({ isApiHitComplete: true, paramSearchObj: null })).catch(err => console.error(err))
                } else {
                    this.setState({ isApiHitComplete: true, isTimerModal: false, paramSearchObj: null }, () => this.noRecordFound())
                }
            }).then(() => this.setState({ isApiHitComplete: true, paramSearchObj: null, isTimerModal: false, searchPath: 'Assets/Data5t/', selectedJudge: '' })).catch(err => {
                console.error(err)
                document.getElementById('loadingTimer').innerHTML = 'Something went wrong. Please try again.'
            })
        } else {
            this.setState({ isApiHitComplete: true, isTimerModal: false, paramSearchObj: null }, () => this.noRecordFound())
        }
    }

    refined_judge_and_content_search(searchWord, searchurl) {
        this.setState({ isTimerModal: true, isOnlyParamSearch: false, isHistoryOpen: false, openInitialModal: false, isFetchFileApiHitComplete: false, isFirstSearchComplete: true, isApiHitComplete: false, isDataFound: false, selectedFile: '' })

        console.log(this.state.allData)
        const refinedArr = this.state.allData

        let obj = {
            'search': `${searchWord}`,
            'searchType': `${this.state.searchType}`,
            'url': this.props.searchApi,
            'searchurl': searchurl
        }
        Hexapi(obj).then(files => {
            // console.log(resp)
            if (files.length > 0) {
                var array_comes_from_contentSearch = files
                array_comes_from_contentSearch.forEach((data) => {
                    if (data.filepath.includes('\\')) {
                        data.filepath = data.filepath.split('\\Web\\')[1].replaceAll('\\', "/")
                    }
                })

                // let array_comes_from_contentSearch = files
                let obj = {
                    'search': `${this.state.selectedJudge}`,
                    'searchType': `2`,
                    'url': this.props.searchApi,
                    'searchurl': searchurl
                }
                Hexapi(obj).then(judges => {
                    // console.log(resp)
                    if (judges.length > 0) {
                        let array_comes_from_judgeSearch = judges
                        array_comes_from_judgeSearch.forEach((data) => {
                            if (data.filepath.includes('\\')) {
                                data.filepath = data.filepath.split('\\Web\\')[1].replaceAll('\\', "/")
                            }
                        })
                        console.log(array_comes_from_contentSearch, array_comes_from_judgeSearch, refinedArr)
                        var common = refinedArr.filter(file => array_comes_from_judgeSearch.some((newfile) => file.filepath.includes(newfile.filepath)) && array_comes_from_contentSearch.some((newfile) => file.filepath.includes(newfile.filepath)))

                        console.log(common)
                        this.createTree(common)




                        // var totalFilesCount = 0
                        // for (let i = 0; i < resp.length; i++) {
                        //     totalFilesCount += parseInt(resp[i].count)
                        // }
                        this.setState({ allData: common, isDataFound: true }, () => {
                            // console.log(this.state.treeData)
                        })
                    } else {
                        this.setState({ isApiHitComplete: true, isTimerModal: false, paramSearchObj: null, selectedJudge: '' }, () => this.noRecordFound())
                    }
                }).then(() => this.setState({ isApiHitComplete: true, paramSearchObj: null, isTimerModal: false, searchPath: 'Assets/Data5t/', selectedJudge: '' })).catch(err => {
                    console.error(err)
                    document.getElementById('loadingTimer').innerHTML = 'Something went wrong. Please try again.'
                })
            } else {
                this.setState({ isApiHitComplete: true, isTimerModal: false, paramSearchObj: null, selectedJudge: '' }, () => this.noRecordFound())
            }

        })
    }

    normal_combine_search(searchWord, searchurl) {
        this.setState({ isTimerModal: true, isOnlyParamSearch: false, isHistoryOpen: false, openInitialModal: false, isFetchFileApiHitComplete: false, isFirstSearchComplete: true, isApiHitComplete: false, isDataFound: false, selectedFile: '' })
        // this.setState({ isOnlyParamSearch: false, isHistoryOpen: false, openInitialModal: false, isFetchFileApiHitComplete: false, isFirstSearchComplete: true, isApiHitComplete: false, isDataFound: false, selectedFile: '' })
        console.log(this.state.paramSearchObj)
        let obj = {
            query: `[dbo].[Lawcrux_Web_Proc_ParamtericSearch_GetDataV2] @JudgeID='{0}', @PartyID='{1}', @Landmark='{2}', @Order='{3}', @Citation='{4}', @CitationValue='{5}', @FromDate='{6}', @ToDate='{7}', @Court='{8}', @Favour='{9}'`,
            queryArr: [`${this.state.paramSearchObj.judge}`, `${this.state.paramSearchObj.party}`, `${this.state.paramSearchObj.landmark}`, `${this.state.paramSearchObj.order}`, `${this.state.paramSearchObj.citation}`, `${this.state.paramSearchObj.citationcode}`, `${this.state.paramSearchObj.fromdate}`, `${this.state.paramSearchObj.todate}`, `${this.state.paramSearchObj.court}`, `${this.state.paramSearchObj.favour}`]
        }
        Hexapi(obj).then(resp => {
            console.log(resp)
            let files_comes_from_paramSearch = [];
            let keys = Object.keys(resp)
            console.log(keys)
            for (let i = 0; i < keys.length; i++) {
                let rs = resp[keys[i]];
                for (let j = 0; j < rs.length; j++) {
                    let record = rs[j].code + '.htm';
                    files_comes_from_paramSearch.push(record)
                }
            }
            console.log(files_comes_from_paramSearch)
            if (files_comes_from_paramSearch.length > 0) {
                let obj = {
                    'search': `${searchWord}`,
                    'searchType': `${this.state.searchType}`,
                    'url': this.props.searchApi,
                    'searchurl': searchurl
                }
                Hexapi(obj).then(files => {
                    let files_comes_from_contentSearch = files
                    console.log(files_comes_from_contentSearch)
                    // let common = files_comes_from_contentSearch.filter(file => files_comes_from_paramSearch.includes(file))
                    let common = files_comes_from_contentSearch.filter((fileObj) => files_comes_from_paramSearch.some((spliter) => fileObj.filepath.toLowerCase().includes(spliter)))
                    console.log('Tree Array => ', common)

                    if (common.length > 0) {
                        const input = common
                        input.forEach((data) => {
                            if (data.filepath.includes('\\')) {
                                data.filepath = data.filepath.split('\\Web\\')[1].replaceAll('\\', "/")
                            }
                        })

                        // this function creating tree structure
                        this.createTree(input)

                        this.setState({ allData: common, isDataFound: true })
                    } else {
                        this.setState({ isDataFound: false })
                    }
                }).then(() => this.setState({ isApiHitComplete: true, isTimerModal: false, paramSearchObj: null, searchPath: 'Assets/Data5t/' })).catch(err => {
                    console.error(err)
                    document.getElementById('loadingTimer').innerHTML = 'Something went wrong. Please try again.'
                })
                // .then(res => this.setState({ isApiHitComplete: true, paramSearchObj: null })).catch(err => console.error(err))

                // this.onCombineSearch(files_comes_from_paramSearch, searchWord, this.state.Combinesearchurl).then(re => {
                //     if (re.length > 0) {
                //         const input = re
                //         var totalFilesCount = 0
                //         for (let i = 0; i < re.length; i++) {
                //             totalFilesCount += parseInt(re[i].count)
                //         }
                //         this.setState({ allData: re, totalFilesCount: totalFilesCount, treeData: this.createTree(input), isDataFound: true }, () => {
                //             console.log(this.state.treeData)
                //         })
                //     } else {
                //         this.setState({ isDataFound: false })
                //     }
                // }).then(res => this.setState({ isApiHitComplete: true, paramSearchObj: null })).catch(err => console.error(err))
            } else {
                this.setState({ isApiHitComplete: true, isDataFound: false, paramSearchObj: null })
            }

            // this.props.onSearch(jsonArr.join('##12##'),this.state.ParametericsearchUrl)
            // this.props.onCloseModal()
        }).catch(err => console.error(err))
    }

    normal_content_search(searchWord, searchurl) {
        this.setState({ isTimerModal: true, isOnlyParamSearch: false, isHistoryOpen: false, openInitialModal: false, isFetchFileApiHitComplete: false, isFirstSearchComplete: true, isApiHitComplete: false, isDataFound: false, selectedFile: '' })
        let obj = {
            'search': `${searchWord}`,
            'searchType': `${this.state.searchType}`,
            'url': this.props.searchApi,
            'searchurl': searchurl
        }
        Hexapi(obj).then(resp => {
            console.log(resp)

            if (resp.length > 0) {
                const input = resp
                // for(let i=0;i<input.length;i++){
                //     let filepath = input[i].filepath
                //     if(filepath.includes('\\')){
                //         filepath = filepath.split('\\Web\\')[1]
                //     }
                // }
                input.forEach((data) => {
                    if (data.filepath.includes('\\')) {
                        data.filepath = data.filepath.split('\\Web\\')[1].replaceAll('\\', "/")
                    }
                })
                // console.log(input)
                // console.log(this.state.newTreeData)


                // this.setState({ newTreeData: this.copyNewTree }, () => {
                //     console.log(this.state.newTreeData)
                this.createTree(input)
                // })




                // var totalFilesCount = 0
                // for (let i = 0; i < resp.length; i++) {
                //     totalFilesCount += parseInt(resp[i].count)
                // }
                this.setState({ allData: resp, isDataFound: true }, () => {
                    // console.log(this.state.treeData)
                })
            } else {
                this.setState({ isDataFound: false })
            }
        }).then(() => this.setState({ isApiHitComplete: true, isTimerModal: false, searchPath: 'Assets/Data5t/' })).catch(err => {
            console.error(err)
            document.getElementById('loadingTimer').innerHTML = 'Something went wrong. Please try again.'
        })
        // let obj = {
        //     'url': 'foldersearch.php',
        //     'searchurl': searchurl
        // }
        // Hexapi(obj).then(resp => {
        //     console.log(resp)
        //     let obj = {
        //         allObj : {
        //             pathArray:resp,
        //             searchword: `${searchWord}`,
        //             searchtype:`${this.state.searchType}`
        //         }
        //     }      
        //     Hexapi(obj).then(d => {
        //         console.log(d)
        //     })      
        //     // if (resp.length > 0) {
        //     //     const input = resp
        //     //     var totalFilesCount = 0
        //     //     for (let i = 0; i < resp.length; i++) {
        //     //         totalFilesCount += parseInt(resp[i].count)
        //     //     }
        //     //     this.setState({ allData: resp, totalFilesCount: totalFilesCount, treeData: this.createTree(input), isDataFound: true }, () => {
        //     //         console.log(this.state.treeData)
        //     //     })
        //     // } else {
        //     //     this.setState({ isDataFound: false })
        //     // }
        // })
        // // .then(res => this.setState({ isApiHitComplete: true })).catch(err => console.error(err))
    }

    normal_parametric_search() {
        // this.setState({ isOnlyParamSearch: true, isHistoryOpen: false, openInitialModal: false, isFetchFileApiHitComplete: false, isFirstSearchComplete: true, isApiHitComplete: false, isDataFound: false, selectedFile: '' })
        this.setState({ isTimerModal: true, isOnlyParamSearch: false, isHistoryOpen: false, openInitialModal: false, isFetchFileApiHitComplete: false, isFirstSearchComplete: true, isApiHitComplete: false, isDataFound: false, selectedFile: '' })
        console.log(this.state.paramSearchObj)
        let obj = {
            query: `[dbo].[Lawcrux_Web_Proc_ParamtericSearch_GetDataV2] @JudgeID='{0}', @PartyID='{1}', @Landmark='{2}', @Order='{3}', @Citation='{4}', @CitationValue='{5}', @FromDate='{6}', @ToDate='{7}', @Court='{8}', @Favour='{9}'`,
            queryArr: [`${this.state.paramSearchObj.judge}`, `${this.state.paramSearchObj.party}`, `${this.state.paramSearchObj.landmark}`, `${this.state.paramSearchObj.order}`, `${this.state.paramSearchObj.citation}`, `${this.state.paramSearchObj.citationcode}`, `${this.state.paramSearchObj.fromdate}`, `${this.state.paramSearchObj.todate}`, `${this.state.paramSearchObj.court}`, `${this.state.paramSearchObj.favour}`]
        }
        Hexapi(obj).then(resp => {
            console.log(resp)
            let jsonArr = [];
            let keys = Object.keys(resp)
            console.log(keys)
            for (let i = 0; i < keys.length; i++) {
                let rs = resp[keys[i]];
                console.log(rs)
                for (let j = 0; j < rs.length; j++) {
                    let record = rs[j].code + '.htm';
                    jsonArr.push(record)
                }
            }
            console.log(jsonArr)
            if (jsonArr.length > 0) {
                this.onParametricSearch(jsonArr.join('##12##'), this.state.ParametericsearchUrl).then(re => {
                    console.log(re)
                    if (re.length > 0) {
                        const input = re
                        input.forEach((data) => {
                            if (data.filepath.includes('\\')) {
                                data.filepath = data.filepath.split('\\Web\\')[1].replaceAll('\\', "/")
                            }
                        })

                        // this function creating tree structure
                        this.createTree(input)

                        this.setState({ allData: input, isDataFound: true })
                    } else {
                        this.setState({ isDataFound: false })
                    }
                }).then(() => this.setState({ isApiHitComplete: true, isTimerModal: false, paramSearchObj: null, searchPath: 'Assets/Data5t/' })).catch(err => {
                    console.error(err)
                    document.getElementById('loadingTimer').innerHTML = 'Something went wrong. Please try again.'
                })
            } else {
                this.setState({ isApiHitComplete: true, isDataFound: false, paramSearchObj: null })
            }
        })
    }

    normal_judge_search(searchurl) {
        this.setState({ isTimerModal: true, isOnlyParamSearch: false, isHistoryOpen: false, openInitialModal: false, isFetchFileApiHitComplete: false, isFirstSearchComplete: true, isApiHitComplete: false, isDataFound: false, selectedFile: '' })
        let obj = {
            'search': `${this.state.selectedJudge}`,
            'searchType': `2`,
            'url': this.props.searchApi,
            'searchurl': searchurl
        }
        Hexapi(obj).then(resp => {
            console.log(resp)

            if (resp.length > 0) {
                const input = resp
                input.forEach((data) => {
                    if (data.filepath.includes('\\')) {
                        data.filepath = data.filepath.split('\\Web\\')[1].replaceAll('\\', "/")
                    }
                })

                this.createTree(input)
                this.setState({ allData: resp, isDataFound: true }, () => {
                })
            } else {
                this.setState({ isDataFound: false })
            }
        }).then(() => this.setState({ isApiHitComplete: true, isTimerModal: false, searchPath: 'Assets/Data5t/', selectedJudge: '' })).catch(err => {
            console.error(err)
            document.getElementById('loadingTimer').innerHTML = 'Something went wrong. Please try again.'
        })
    }


    normal_judge_and_content_search(searchWord, searchurl) {
        this.setState({ isTimerModal: true, isOnlyParamSearch: false, isHistoryOpen: false, openInitialModal: false, isFetchFileApiHitComplete: false, isFirstSearchComplete: true, isApiHitComplete: false, isDataFound: false, selectedFile: '' })
        let obj = {
            'search': `${searchWord}`,
            'searchType': `${this.state.searchType}`,
            'url': this.props.searchApi,
            'searchurl': searchurl
        }
        Hexapi(obj).then(files => {
            // console.log(resp)
            let array_comes_from_contentSearch = files
            console.log(array_comes_from_contentSearch)
            let obj = {
                'search': `${this.state.selectedJudge}`,
                'searchType': `2`,
                'url': this.props.searchApi,
                'searchurl': searchurl
            }
            Hexapi(obj).then(judges => {
                // console.log(resp)
                let array_comes_from_judgeSearch = judges
                // let files_comes_from_contentSearch = files
                console.log(array_comes_from_judgeSearch)
                // let common = files_comes_from_contentSearch.filter(file => files_comes_from_paramSearch.includes(file))
                // let common = array_comes_from_contentSearch.filter((fileObj) => array_comes_from_judgeSearch.some((spliter) => fileObj.filepath.includes(spliter)))
                // let common = array_comes_from_contentSearch.filter((fileObj) => array_comes_from_judgeSearch.some((spliter) => fileObj.filepath.includes(spliter)))
                var common = array_comes_from_contentSearch.filter((newfile) => array_comes_from_judgeSearch.some((spliter) => newfile.filepath.includes(spliter.filepath)))
                console.log(common)

                if (common.length > 0) {
                    const input = common
                    input.forEach((data) => {
                        if (data.filepath.includes('\\')) {
                            data.filepath = data.filepath.split('\\Web\\')[1].replaceAll('\\', "/")
                        }
                    })

                    // this function creating tree structure
                    this.createTree(input)

                    this.setState({ allData: common, isDataFound: true })
                } else {
                    this.setState({ isDataFound: false })
                }
                // var common = array_comes_from_contentSearch.filter(file => array_comes_from_judgeSearch.includes(file))
                // console.log(common)
            }).then(() => this.setState({ isApiHitComplete: true, isTimerModal: false, searchPath: 'Assets/Data5t/', selectedJudge: '' })).catch(err => {
                console.error(err)
                document.getElementById('loadingTimer').innerHTML = 'Something went wrong. Please try again.'
            })
        })
    }



    searchKeyword(searchWord, searchurl) {
        console.log(this.state.isRefinedSearch)
        searchWord = searchWord.trim()
        if (this.state.isRefinedSearch) {
            // if (searchWord != "" && this.state.paramSearchObj != null) {
            //     // this case is COMBINE+REFINED 
            //     console.log("this case is COMBINE+REFINED ")
            //     this.refined_combine_search(searchWord)
            // } else if (searchWord != "") {
            //     // this case is CONTENT+REFINED
            //     console.log("this case is CONTENT+REFINED")
            //     this.refined_content_search(searchWord)
            // } else if (this.state.paramSearchObj != null) {
            //     // this case is PARAMETRIC+REFINED
            //     console.log('this case is PARAMETRIC+REFINED')
            //     this.refined_parametric_search()
            // } else if (this.state.selectedJudge != "") {
            //     this.normal_judge_search(searchurl)
            // }

            if (searchWord == "" && this.state.paramSearchObj == null && this.state.selectedJudge != "") {
                // this case is ONLY JUDGE SEARCH as CONTENT SEARCH in refined
                console.log("this case is JUDGE SEARCH as CONTENT SEARCH in refined")
                // this.normal_judge_search(searchurl)
                this.refined_judge_search(searchurl)
            } else if (searchWord == "" && this.state.paramSearchObj != null) {
                // this case is PARAMETRIC+REFINED
                console.log("this case is PARAMETRIC+REFINED")
                // this.normal_parametric_search()
                this.refined_parametric_search()
            } else if (searchWord != "" && this.state.paramSearchObj == null && this.state.selectedJudge != "") {
                // this case is CONTENT+JUDGE+REFINED
                console.log('this case is CONTENT+JUDGE+REFINED')
                this.refined_judge_and_content_search(searchWord, searchurl)
                // this.normal_content_search(searchWord, searchurl)
            } else if (searchWord != "" && this.state.paramSearchObj == null && this.state.selectedJudge == "") {
                // this case is CONTENT+REFINED
                console.log('this case is CONTENT+REFINED')
                // this.normal_content_search(searchWord, searchurl)
                this.refined_content_search(searchWord, searchurl)
            } else if (searchWord != "" && this.state.paramSearchObj != null) {
                // this case is COMBINE+REFINED  
                console.log('this case is COMBINE+REFINED')
                // this.normal_combine_search(searchWord, searchurl)
                this.refined_combine_search(searchWord, searchurl)
            }

        } else {
            // if (searchWord != "" && this.state.paramSearchObj != null) {
            //     // this case is COMBINE+NORMAL 
            //     console.log('this case is COMBINE+NORMAL')
            //     this.normal_combine_search(searchWord)
            // } else if (searchWord != "") {
            //     // this case is CONTENT+NORMAL
            //     console.log('this case is CONTENT+NORMAL')
            //     this.normal_content_search(searchWord, searchurl)
            // } else if (this.state.paramSearchObj != null) {
            //     // this case is PARAMETRIC+NORMAL
            //     console.log("this case is PARAMETRIC+NORMAL")
            //     this.normal_parametric_search()
            // } else if (this.state.selectedJudge != "") {
            //     this.normal_judge_search(searchurl)
            // }

            if (searchWord == "" && this.state.paramSearchObj == null && this.state.selectedJudge != "") {
                // this case is ONLY JUDGE SEARCH as CONTENT SEARCH
                console.log("this case is JUDGE SEARCH as CONTENT SEARCH")
                this.normal_judge_search(searchurl)
            } else if (searchWord == "" && this.state.paramSearchObj != null) {
                // this case is PARAMETRIC+NORMAL
                console.log("this case is PARAMETRIC+NORMAL")
                this.normal_parametric_search()
            } else if (searchWord != "" && this.state.paramSearchObj == null && this.state.selectedJudge != "") {
                // this case is CONTENT+JUDGE+NORMAL
                console.log('this case is CONTENT+JUDGE+NORMAL')
                this.normal_judge_and_content_search(searchWord, searchurl)
                // this.normal_content_search(searchWord, searchurl)
            } else if (searchWord != "" && this.state.paramSearchObj == null && this.state.selectedJudge == "") {
                // this case is CONTENT+NORMAL
                console.log('this case is CONTENT+NORMAL')
                this.normal_content_search(searchWord, searchurl)
            } else if (searchWord != "" && this.state.paramSearchObj != null) {
                // this case is COMBINE+NORMAL 
                console.log('this case is COMBINE+NORMAL')
                this.normal_combine_search(searchWord, searchurl)
            }
        }
    }
    storeSearch(e) {
        this.setState({ search: e.target.value })
    }
    handleKeyPress(e) {
        if (e.keyCode === 13) {
            this.searchKeyword(this.state.search, this.state.searchPath)
        }
    }

    setRightBoxContent(data) {
        // console.log(data)

        var newPromise = new Promise((resolve, reject) => {
            this.setState({ allFiles: [], isFileTitleFetched: false, isFileTitleFound: false })
            this.setState({ isHistoryTrackBtn: false })
            console.log(data)

            let fdata = []

            let obj = {
                allfiles: data.join('##12##'),
                url: sessionStorage.getItem('gettitleurl')
            }
            Hexapi(obj).then(resp => {
                data.forEach((path, i) => {
                    let obj = {
                        path: path,
                        title: resp[i]
                    }
                    fdata.push(obj)
                })
                // fdata.forEach((el, i) => el.title = resp[i])
                console.log(data, fdata, resp)
                resolve(fdata)
            }).catch(err => reject(err))
        })
        newPromise.then((fileData) => {
            let mainBox = document.getElementById("mainBoxContent")
            // let rightBox = document.getElementById("rightBox")
            this.setState({ allStarts: '', allEnds: '', selectedFile: '', isShowReadWith: false, isFetchFileApiHitComplete: false, isFileTitleFound: true, htmlFileContent: "", allFiles: fileData, isShowRightBox: true, isFileTitleFetched: true }, () => {
                mainBox.style.minWidth = "calc(100% - 700px)"
            })
        }).catch(err => console.error(err))

    }
    getLeafCount(data) {
        let count = 0
        // console.log(data)
        function getfiles(d) {
            if (d.length > 0) {
                if (d.some(value => { return typeof value == "object" })) {
                    d.forEach(el => {
                        // console.log(el)
                        if (el.children.length > 0) {
                            getfiles(el.children)
                        }
                        // else {
                        //     count++
                        // }
                    })
                }
                else {
                    count += d.length
                }
            }
        }
        getfiles(data.children)
        return count
    }
    setLeftBoxTree(nodes) {
        return nodes.map((node, index) => {
            let totalFound = this.getLeafCount(node)
            // console.log(totalFound)
            return (
                <TreeItem key={node.nodeid} nodeId={node.nodeid} label={node.name + ':' + totalFound} onClick={() => {
                    node.children.some(value => { return typeof value !== "object" }) && this.setRightBoxContent(node.children)
                }}>
                    {node.children.length > 0 && node.children.some(value => { return typeof value == "object" })
                        ? this.setLeftBoxTree(node.children)
                        : null}
                </TreeItem>
            )
        })

        // if (data.type == 'folder') {
        //     return data.children.map((child, index) => {
        //         if (child.type == 'folder') {
        //             let totalFound = this.getLeafCount(child)
        //             return (
        //                 <TreeItem key={child.name + index} nodeId={`${"childFolder" + child.name}`} label={rename(child.name) + ' - ' + totalFound} onClick={() => {
        //                     // child.children.some((el) => el.children == undefined) ? this.setRightBoxContent(child.children) : this.setState({ allFiles: [] })
        //                 }}>
        //                     {this.setLeftBoxTree(child)}
        //                 </TreeItem>
        //             )
        //         }
        //     })
        // }
    }
    onIframeLoad() {
        console.log('============OriginalIframe')
        this.markInstance = new Mark(document.getElementById("originalIframe"));
        this.markInstance.unmark({
            iframes: true,
            done: () => {
                this.markInstance.mark(this.state.search, {
                    accuracy: this.state.search.includes(' ') && this.state.searchType == "2" ? 'partially' : "exactly",
                    separateWordSearch: this.state.searchType == 2 ? false : true,
                    caseSensitive: false,
                    iframes: true
                });
            }
        });
    }
    autoHighlight() {
        let scope = this
        const restoreSelection = function (containerEl, savedSel) {
            var charIndex = 0, range = document.createRange();
            range.setStart(containerEl, 0);
            range.collapse(true);
            var nodeStack = [containerEl], node, foundStart = false, stop = false;

            while (!stop && (node = nodeStack.pop())) {
                if (node.nodeType == 3) {
                    var nextCharIndex = charIndex + node.length;
                    if (!foundStart && savedSel.start >= charIndex && savedSel.start <= nextCharIndex) {
                        range.setStart(node, savedSel.start - charIndex);
                        foundStart = true;
                    }
                    if (foundStart && savedSel.end >= charIndex && savedSel.end <= nextCharIndex) {
                        range.setEnd(node, savedSel.end - charIndex);
                        stop = true;
                    }
                    charIndex = nextCharIndex;
                } else {
                    var i = node.childNodes.length;
                    while (i--) {
                        nodeStack.push(node.childNodes[i]);
                    }
                }
            }

            var sel = window.getSelection();
            sel.removeAllRanges();
            sel.addRange(range);
            document.designMode = "on";
            if (!document.execCommand("HiliteColor", false, scope.state.highlightColor)) {
                document.execCommand("BackColor", false, scope.state.highlightColor);
            }
            document.designMode = "off";
            if (scope.state.isDarkMode) {
                document.getElementById('customIframe').style.backgroundColor = 'black'
            }
            // console.log(range)
        }

        // if (this.state.savedSelection != null) {
        //     restoreSelection(document.getElementById("customIframe"), this.state.savedSelection);
        // }

        let obj = {
            query: `[dbo].[Lawcrux_Web_Proc_Highlight_GetValue] @UserID='{0}', @filepath='{1}'`,
            queryArr: [`${this.state.selectedFileDetails.userid}`, `${this.state.selectedFileDetails.filepath}`]
        }

        Hexapi(obj).then(resp => {
            console.log(resp)
            if (resp.highlight != undefined && resp.highlight.length > 0) {
                let start = resp.highlight[0].start
                let end = resp.highlight[0].end
                this.setState({ allStarts: start, allEnds: end })
                let newStart = [],
                    newEnd = []
                if (start.toString().indexOf('##12##') > -1) {
                    newStart = start.split('##12##')
                } else {
                    newStart.push(start)
                }
                if (end.toString().indexOf('##12##') > -1) {
                    newEnd = end.split('##12##')
                } else {
                    newEnd.push(end)
                }
                let arr = []
                for (let i = 0; i < newStart.length; i++) {
                    let obj = {
                        start: parseInt(newStart[i]),
                        end: parseInt(newEnd[i])
                    }
                    arr.push(obj)
                    console.log(obj)
                    restoreSelection(document.getElementById("customIframe"), obj);
                }
                this.setState({ startEndArray: arr })
                console.log(newStart, newEnd, this.state.startEndArray)
            }
        }).then(re => {
            console.log(this.state.search, this.state.isOpenInIframe)
            // Only highlighing searched word
            this.markInstance = new Mark(document.getElementById("customIframe"));
            this.markInstance.unmark({
                done: () => {
                    this.markInstance.mark(this.state.search, {
                        accuracy: this.state.search.includes(' ') && this.state.searchType == "2" ? 'partially' : "exactly",
                        separateWordSearch: this.state.searchType == 2 ? false : true,
                        caseSensitive: false
                    });
                }
            });
        }).then(() => {
            let scope = this
            let filepath = this.state.selectedFileDetails.filepath
            let mainfolder = filepath.split('/')[2] + '/'
            let subfolder = filepath.split('/')[3] + '/'
            console.table(mainfolder, subfolder)
            !this.state.isHistoryTrackBtn && this.trackHistory(filepath)

            // ========================================================================================================
            // This code used to replace the anchor tag href attribute url
            // And also open then in new tab 
            $('#customIframe').find('a').each(function () {
                let href = $(this).attr('href')
                let isJsFun = false,
                    isSamePageLink = false
                if (href != undefined && href.toString().toLowerCase().includes('javascript:void(0)')) {
                    isJsFun = true
                }
                if (href != undefined && href.includes('#')) {
                    isSamePageLink = true
                }
                let file_n = href
                // console.log(isJsFun)
                if (href != undefined && !isJsFun) {
                    $(this).mousedown(function (e) {
                        if (e.button == 2) {
                            let r_href = href
                            if (r_href.includes('../../')) {
                                r_href = r_href.replace('../../', '')
                            } else if (r_href.includes('../')) {
                                r_href = r_href.replace('../', mainfolder)
                                subfolder = r_href.split(mainfolder)[1].substring(0, r_href.split(mainfolder)[1].indexOf('/')) + '/'
                            } else {
                                r_href = mainfolder + subfolder + r_href
                            }
                            let temp_url = 'Assets/data5t/' + r_href
                            console.log(mainfolder, subfolder, temp_url)
                            const isPPT = ['.pptx', '.ppt'].some((el) => temp_url.toLowerCase().includes(el))
                            if (!isPPT) {
                                $(this).attr('href', sessionStorage.getItem('url') + temp_url)
                            }
                        }
                    });
                    $(this).on('click', () => {
                        let l_href = href
                        if (l_href.includes('../../')) {
                            l_href = l_href.replace('../../', '')
                        } else if (l_href.includes('../')) {
                            l_href = l_href.replace('../', mainfolder)
                            subfolder = l_href.split(mainfolder)[1].substring(0, l_href.split(mainfolder)[1].indexOf('/')) + '/'
                        } else {
                            l_href = mainfolder + subfolder + l_href
                        }
                        let temp_url = 'Assets/data5t/' + l_href
                        console.log(mainfolder, subfolder, temp_url)
                        const isPPT = ['.pptx', '.ppt'].some((el) => temp_url.toLowerCase().includes(el))
                        // $(this).attr('href', '#')
                        if (!isSamePageLink) {
                            $(this).attr('href', '#')
                        }
                        if (!temp_url.includes('#')) {
                            if (isPPT) {
                                console.log(temp_url)
                                // scope.setState({ isOpenSummaryModal: true, summaryData: { foldername: mainfolder + subfolder, filename: file_n } })
                            } else {
                                // scope.setState({ filepath: temp_url, allEnds: "0", allStarts: '0', startEndArray: [] }, () => {
                                //     anchorTags(temp_url)
                                // })
                                const hist = () => {
                                    scope.history = scope.history.filter((el, i) => i <= scope.currentPagePosition - 1)
                                    console.log(scope.history)
                                }

                                scope.state.isHistoryTrackBtn && hist()

                                scope.setState({ isHistoryTrackBtn: false })
                                const obj = {
                                    filepath: temp_url,
                                    description: scope.state.selectedFileDetails.description,
                                    userid: scope.state.selectedFileDetails.userid
                                }
                                console.log(obj)
                                scope.createIframe(obj)
                            }
                        }
                    })
                }
            });
            // ========================================================================================================

            // ========================================================================================================
            // This code used to correct the object tag data attribute url
            // In frames
            // $('#customIframe').find('object').each(function () {
            //     let href = $(this).attr('data')
            //     let r_href = href
            //     if (r_href.includes('../../')) {
            //         r_href = r_href.replace('../../', '')
            //     } else if (r_href.includes('../')) {
            //         r_href = r_href.replace('../', mainfolder)
            //         subfolder = r_href.split(mainfolder)[1].substring(0, r_href.split(mainfolder)[1].indexOf('/')) + '/'
            //     } else {
            //         r_href = mainfolder + subfolder + r_href
            //     }
            //     let temp_url = 'Assets/data5t/' + r_href
            //     $(this).attr('data', sessionStorage.getItem('url') + temp_url)
            // });
            // ========================================================================================================
        })
    }
    getSelectedText() {
        if (this.state.selectedFile != "") {
            let scope = this

            function makeEditableAndHighlight(colour) {
                var range, sel = window.getSelection();
                if (sel.rangeCount && sel.getRangeAt) {
                    range = sel.getRangeAt(0);
                }

                document.designMode = "on";
                if (range) {
                    sel.removeAllRanges();
                    sel.addRange(range);
                }
                // Use HiliteColor since some browsers apply BackColor to the whole block
                if (!document.execCommand("HiliteColor", false, colour)) {
                    console.log('1')
                    document.execCommand("BackColor", false, colour);
                }
                document.designMode = "off";
                if (scope.state.isDarkMode) {
                    document.getElementById('customIframe').style.background = 'black'
                }
            }
            // function makeEditableAndHighlight(colour) {
            //     var range, sel = window.getSelection();
            //     if (sel.rangeCount && sel.getRangeAt) {
            //         range = sel.getRangeAt(0);
            //     }

            //     document.designMode = "on";
            //     if (range) {
            //         sel.removeAllRanges();
            //         sel.addRange(range);
            //     }
            //     // Use HiliteColor since some browsers apply BackColor to the whole block
            //     if (!document.execCommand("HiliteColor", false, colour)) {
            //         document.execCommand("BackColor", false, colour);
            //     }
            //     document.designMode = "off";
            //     if (scope.state.isDarkMode) {
            //         document.getElementById('customIframe').style.background = 'black'
            //     } else {
            //         document.getElementById('customIframe').style.background = 'transparent'
            //     }
            // }
            function highlight(colour) {
                var range;
                console.log('hightlight call')
                if (window.getSelection) {
                    // IE9 and non-IE
                    try {
                        if (!document.execCommand("BackColor", false, colour)) {
                            console.log('backColor working')
                            makeEditableAndHighlight(colour);
                        }
                    } catch (ex) {
                        console.log('backColor not working')
                        makeEditableAndHighlight(colour)
                    }
                } else if (document.selection && document.selection.createRange) {
                    // IE <= 8 case
                    console.log('window getselectiong not working')
                    range = document.selection.createRange();
                    range.execCommand("BackColor", false, colour);
                }
            }
            // function highlight(colour) {
            //     var range;
            //     if (window.getSelection) {
            //         // IE9 and non-IE
            //         try {
            //             if (!document.execCommand("BackColor", false, colour)) {
            //                 makeEditableAndHighlight(colour);
            //             }
            //         } catch (ex) {
            //             makeEditableAndHighlight(colour)
            //         }
            //     } else if (document.selection && document.selection.createRange) {
            //         // IE <= 8 case
            //         range = document.selection.createRange();
            //         range.execCommand("BackColor", false, colour);
            //     }
            // }
            const saveSelection = function (containerEl) {
                if (window.getSelection().getRangeAt(0) != undefined) {
                    var range = window.getSelection().getRangeAt(0);
                    var preSelectionRange = range.cloneRange();
                    preSelectionRange.selectNodeContents(containerEl);
                    preSelectionRange.setEnd(range.startContainer, range.startOffset);
                    var start = preSelectionRange.toString().length;

                    return {
                        start: start,
                        end: start + range.toString().length
                    }
                } else {
                    alert('Select text first')
                }

            };

            // const saveSelection = function (containerEl) {
            //     var range = window.getSelection().getRangeAt(0);
            //     var preSelectionRange = range.cloneRange();
            //     preSelectionRange.selectNodeContents(containerEl);
            //     preSelectionRange.setEnd(range.startContainer, range.startOffset);
            //     var start = preSelectionRange.toString().length;

            //     return {
            //         start: start,
            //         end: start + range.toString().length
            //     }
            // };


            let selectedIndexObj = saveSelection(document.getElementById("customIframe"))
            if (this.state.allStarts != "" && this.state.allEnds != "") {
                let newStarts = ''
                let newEnds = ''
                this.setState({ startEndArray: this.state.startEndArray.concat(selectedIndexObj) }, () => {
                    let updatedObj = this.correctHighlight(this.state.startEndArray)
                    console.log(updatedObj)
                    let start = [],
                        end = []
                    for (let i = 0; i < updatedObj.length; i++) {
                        start.push(updatedObj[i].start)
                        end.push(updatedObj[i].end)
                    }

                    newStarts = start.join('##12##')
                    newEnds = end.join('##12##')

                    // Updating file highlights
                    this.setState({ allStarts: newStarts, allEnds: newEnds }, () => {
                        console.log(this.state.allStarts, this.state.allEnds)
                        highlight(scope.state.highlightColor)
                        let obj = {
                            query: `[dbo].[Lawcrux_Web_Proc_UpdateHighlight_Submit] @UserID='{0}', @Filepath='{1}', @Start='{2}', @End='{3}'`,
                            queryArr: [`${this.state.selectedFileDetails.userid}`, `${this.state.selectedFileDetails.filepath}`, `${newStarts}`, `${newEnds}`]
                        }

                        Hexapi(obj).then(resp => {
                            console.log(resp)
                        })
                    })
                })

            } else {
                //Inserting very first highlight
                this.setState({ allStarts: selectedIndexObj.start, allEnds: selectedIndexObj.end }, () => {
                    highlight(scope.state.highlightColor)
                    let obj = {
                        query: `[dbo].[Lawcrux_Web_Proc_AddHighlighter_Submit] @UserID='{0}', @Filepath='{1}', @Start='{2}', @End='{3}'`,
                        queryArr: [`${this.state.selectedFileDetails.userid}`, `${this.state.selectedFileDetails.filepath}`, `${selectedIndexObj.start}`, `${selectedIndexObj.end}`]
                    }

                    Hexapi(obj).then(resp => {
                        console.log(resp)
                    })
                })
            }
        }
    }
    removeSelectedText() {
        if (this.state.selectedFile != "") {
            let scope = this
            function makeEditableAndHighlight(colour) {
                var range, sel = window.getSelection();
                if (sel.rangeCount && sel.getRangeAt) {
                    range = sel.getRangeAt(0);
                }
                // console.log(range)
                document.designMode = "on";
                if (range) {
                    sel.removeAllRanges();
                    sel.addRange(range);
                }
                // Use HiliteColor since some browsers apply BackColor to the whole block
                if (!document.execCommand("HiliteColor", false, colour)) {
                    document.execCommand("BackColor", false, colour);
                }
                document.designMode = "off";
                if (scope.state.isDarkMode) {
                    document.getElementById('customIframe').style.backgroundColor = 'black'
                }
            }

            // function makeEditableAndHighlight(colour) {
            //     var range, sel = window.getSelection();
            //     if (sel.rangeCount && sel.getRangeAt) {
            //         range = sel.getRangeAt(0);
            //     }
            //     // console.log(range)
            //     document.designMode = "on";
            //     if (range) {
            //         sel.removeAllRanges();
            //         sel.addRange(range);
            //     }
            //     // Use HiliteColor since some browsers apply BackColor to the whole block
            //     if (!document.execCommand("HiliteColor", false, colour)) {
            //         document.execCommand("BackColor", false, colour);
            //     }

            //     document.designMode = "off";
            // }

            function highlight(colour) {
                var range;
                if (window.getSelection) {
                    // IE9 and non-IE
                    try {
                        if (!document.execCommand("BackColor", false, colour)) {
                            makeEditableAndHighlight(colour);
                        }
                    } catch (ex) {
                        makeEditableAndHighlight(colour)
                    }
                } else if (document.selection && document.selection.createRange) {
                    // IE <= 8 case
                    range = document.selection.createRange();
                    range.execCommand("BackColor", false, colour);
                }
            }


            const saveSelection = function (containerEl) {
                var range = window.getSelection().getRangeAt(0);
                var preSelectionRange = range.cloneRange();
                preSelectionRange.selectNodeContents(containerEl);
                preSelectionRange.setEnd(range.startContainer, range.startOffset);
                var start = preSelectionRange.toString().length;

                return {
                    start: start,
                    end: start + range.toString().length
                }
            };

            let removeCoordinates = saveSelection(document.getElementById("customIframe"))
            if (this.state.allStarts != "" && this.state.allEnds != "") {

                this.setState({ startEndArray: this.state.startEndArray.concat(removeCoordinates) }, () => {
                    console.log(removeCoordinates)
                    let rStart = removeCoordinates.start,
                        rEnd = removeCoordinates.end
                    let Arr = this.correctHighlight(this.state.startEndArray)
                    let newArr = [],
                        newStarts = '',
                        newEnds = '',
                        scope = this
                    for (let i = 0; i < Arr.length; i++) {
                        if (Arr[i].start != rStart && Arr[i].end != rEnd) {
                            if (rStart < Arr[i].start && rEnd > Arr[i].start && rEnd < Arr[i].end) {
                                newArr.push({ start: min(rEnd, Arr[i].end), end: max(rEnd, Arr[i].end) })
                            } else if (rStart > Arr[i].start && rStart < Arr[i].end && rEnd > Arr[i].start && rEnd < Arr[i].end) {
                                newArr.push({ start: min(rStart, Arr[i].start), end: max(rStart, Arr[i].start) })
                                newArr.push({ start: min(rEnd, Arr[i].end), end: max(rEnd, Arr[i].end) })
                            } else if (rStart > Arr[i].start && rStart < Arr[i].end && rEnd > Arr[i].end) {
                                newArr.push({ start: min(rStart, Arr[i].start), end: max(rStart, Arr[i].start) })
                            } else {
                                newArr.push(Arr[i])
                            }
                        }
                    }
                    console.log(newArr)

                    let start = [],
                        end = []
                    for (let i = 0; i < newArr.length; i++) {
                        start.push(newArr[i].start)
                        end.push(newArr[i].end)
                    }

                    newStarts = start.join('##12##')
                    newEnds = end.join('##12##')

                    // Updating file highlights
                    this.setState({ allStarts: newStarts, allEnds: newEnds, startEndArray: newArr }, () => {
                        console.log(this.state.allStarts, this.state.allEnds)
                        highlight('transparent')
                        let obj = {
                            query: `[dbo].[Lawcrux_Web_Proc_UpdateHighlight_Submit] @UserID='{0}', @Filepath='{1}', @Start='{2}', @End='{3}'`,
                            queryArr: [`${this.state.selectedFileDetails.userid}`, `${this.state.selectedFileDetails.filepath}`, `${newStarts}`, `${newEnds}`]
                        }

                        Hexapi(obj).then(resp => {
                            console.log(resp)
                        })
                    })
                })
            }
        }
    }

    componentDidMount() {
        console.log(this.props)
        if (this.props.isReferedByHsn) {
            this.setState({ search: this.props.hsnSearchWord }, () => {
                this.searchKeyword(this.props.hsnSearchWord, this.props.hsnPath)
            })
        } else if (this.props.isReferedByHistory) {
            this.openHistoryFile(this.props.historyFileData)
            console.log(this.props.historyFileData)
            // this.setState({ search: this.props.hsnSearchWord }, () => {
            //     this.searchKeyword(this.props.hsnSearchWord, this.props.hsnPath)
            // })
        } else if (this.props.isReferedByCaseStatus) {
            this.openHistoryFile(this.props.caseStatusData)
        } else if (this.props.isReferedByCircNoti) {
            console.log('hello')
            this.setState({ isReferedByCircNoti: true, isFirstSearchComplete: true, isApiHitComplete: true, isDataFound: true })
        } else {
            this.setState({
                search: '', searchType: '1', openInitialModal: true
            })
        }
        this.setState({ isApiHitComplete: false, isTimerModal: true })
        let obj = {}
        console.log(this.props.clickedLink);
        if (this.props.clickedLink != undefined) {
            if (this.props.clickedLink.name.toLowerCase() == 'circular' && this.props.clickedLink.type == 'custom') {
                obj = {
                    query: `[dbo].[Lawcrux_Web_Proc_Circular_GetData] @UserID='{0}', @Type='{1}'`,
                    queryArr: [`${this.props.userid}`, `custom`]
                }
            } else if (this.props.clickedLink.name.toLowerCase() == 'circular' && this.props.clickedLink.type == 'excise') {
                obj = {
                    query: `[dbo].[Lawcrux_Web_Proc_Circular_GetData] @UserID='{0}', @Type='{1}'`,
                    queryArr: [`${this.props.userid}`, `excise`]
                }
            } else if (this.props.clickedLink.name.toLowerCase() == 'circular' && this.props.clickedLink.type == 'service tax') {
                obj = {
                    query: `[dbo].[Lawcrux_Web_Proc_Circular_GetData] @UserID='{0}', @Type='{1}'`,
                    queryArr: [`${this.props.userid}`, `service tax`]
                }
            } else if (this.props.clickedLink.name.toLowerCase() == 'non tariff' && this.props.clickedLink.type == 'custom nt') {
                obj = {
                    query: `[dbo].[Lawcrux_Web_Proc_Notification_GetData] @UserID='{0}', @Type='{1}',@Subtype='{2}'`,
                    queryArr: [`${this.props.userid}`, 'custom nt', '1']
                }
            } else if (this.props.clickedLink.name.toLowerCase() == 'tariff' && this.props.clickedLink.type == 'custom tariff') {
                obj = {
                    query: `[dbo].[Lawcrux_Web_Proc_Notification_GetData] @UserID='{0}', @Type='{1}',@Subtype='{2}'`,
                    queryArr: [`${this.props.userid}`, 'custom tariff', '0']
                }
            } else if (this.props.clickedLink.name.toLowerCase() == 'non tariff' && this.props.clickedLink.type == 'excise nt') {
                obj = {
                    query: `[dbo].[Lawcrux_Web_Proc_Notification_GetData] @UserID='{0}', @Type='{1}',@Subtype='{2}'`,
                    queryArr: [`${this.props.userid}`, 'excise nt', '1']
                }
            } else if (this.props.clickedLink.name.toLowerCase() == 'tariff' && this.props.clickedLink.type == 'excise tariff') {
                obj = {
                    query: `[dbo].[Lawcrux_Web_Proc_Notification_GetData] @UserID='{0}', @Type='{1}',@Subtype='{2}'`,
                    queryArr: [`${this.props.userid}`, 'excise tariff', '0']
                }
            } else if (this.props.clickedLink.name.toLowerCase() == 'history' && this.props.clickedLink.type == 'service tax') {
                obj = {
                    query: `[dbo].[Lawcrux_Web_Proc_Notification_GetData] @UserID='{0}', @Type='{1}',@Subtype='{2}'`,
                    queryArr: [`${this.props.userid}`, 'service tax', '0']
                }
            }
        }

        // else if (this.props.clickedLink.name.toLowerCase() == 'non tariff' && this.props.clickedLink.type == 'custom nt' ) {
        //     obj = {
        //         query: `[dbo].[Lawcrux_Web_Proc_Notification_GetData] @UserID='{0}', @Type='{1}'`,
        //         queryArr: [`${this.props.userid}`,'custom nt','1']
        //     }
        // } else if (this.props.clickedLink.name.toLowerCase() == 'tariff' && this.props.clickedLink.type == 'custom tariff') {
        //     obj = {
        //         query: `[dbo].[Lawcrux_Web_Proc_Notification_GetData] @UserID='{0}', @Type='{1}',@Subtype='{2}'`,
        //         queryArr: [`${this.props.userid}`,'custom tariff','0']
        //     }
        // }
        Hexapi(obj).then(resp => {
            console.log(resp)
            // let filterarr = resp[''].filter((el => el.subject.toLowerCase().includes('circular')))
            let filterarr = resp['']
            this.setState({ tableData: filterarr, isApiHitComplete: true, isShowRightBox: true, isTimerModal: false, })
        })

    }
    xWithRef(text, mode) {
        if (mode === 1) {
            let newText = text.replaceAll('�', ' " ')
            const removeHelldodv2 = (string, removeWord) => {
                let updatedString = string
                while (updatedString.indexOf(`${removeWord}`) > -1 && updatedString.indexOf('helldod]', updatedString.indexOf(`${removeWord}`))) {
                    let endIndex = updatedString.indexOf('helldod]', updatedString.indexOf(`${removeWord}`));
                    let openAfterOprn = updatedString.indexOf(`${removeWord}`, updatedString.indexOf(`${removeWord}`) + 8);
                    while (true) {
                        if (endIndex > openAfterOprn && openAfterOprn > -1) {
                            endIndex = updatedString.indexOf('helldod]', endIndex + 8);
                            openAfterOprn = updatedString.indexOf(`${removeWord}`, openAfterOprn + 8);
                            //console.log(`OpenAfterOpen = ${openAfterOprn} || endIndex = ${endIndex}`)
                        } else {
                            break;
                        }
                    }
                    if (endIndex == -1) {
                        console.log("null found")
                        endIndex = updatedString.indexOf('helldod]', updatedString.indexOf(`${removeWord}`));
                    }
                    //console.log(endIndex)
                    let helldodPart = updatedString.substring(
                        updatedString.indexOf(`${removeWord}`),
                        endIndex + 8)
                    console.log(helldodPart)
                    updatedString = updatedString.replace(helldodPart, '')
                }
                //updatedString = updatedString.replace(`[helldod<SUP>old</SUP></FONT></SUP></B><font face="Arial" size="2">[Articles of apparel and clothing accessories, knitted or crocheted, of sale value not exceeding Rs. 1000 per piece]<B><SUP>helldod]`,'shubhamsharma')

                return updatedString;
            }

            newText = removeHelldodv2(newText, '[helldod')

            this.setState({
                htmlFileShowContent: newText,
                htmlFileShowContentBackup: newText
            })
        } else {
            this.setState({
                htmlFileShowContent: this.state.htmlFileShowContentBackup
            })
        }
    }

    xWithoutRef(text) {
        let newText = text.replaceAll('�', '"').replaceAll('[helldod old', '').replaceAll('[helldod', "").replaceAll('helldod]', "").replaceAll('<sup>old</sup>', '')
        this.setState({
            htmlFileShowContent: newText
        })
    }

    storeHistory(filedata) {
        console.log(filedata)
        let obj = {
            query: `[dbo].[Lawcrux_Web_Proc_HistoryLog_Submit] @UserID='{0}', @filepath='{1}', @Description='{2}'`,
            queryArr: [`${filedata.userid}`, `${filedata.filepath}`, `${filedata.title}`]
        }
        Hexapi(obj).then(resp => {
            console.log(resp)
        }).then(() => {
            this.createIframe(filedata)
        })
    }

    getpagedata = (file) => new Promise((resolve, reject) => {
        let obj = {
            query: `[dbo].[Lawcrux_Web_Proc_Page_GetDataV2] @UserID='{0}', @filepath='{1}'`,
            queryArr: [`${file.userid}`, `${file.filepath}`]
        }
        Hexapi(obj).then(resp => {
            console.log(resp)
            this.setState({ isBookmarkAddComplete: false })
            if (resp[''][0].bookmark == 1) {
                this.setState({ isBookmarkAdded: true })
            } else {
                this.setState({ isBookmarkAdded: false })
            }
            if (resp['note'][0].command == 1) {
                this.setState({ usernotes: resp.note[0].note })
            } else {
                this.setState({ usernotes: '' })
            }
            if (resp['summary'] != undefined) {
                this.setState({ summaryData: resp.summary[0] })
            } else {
                this.setState({ summaryData: null })
            }
            this.setState({ pageData: resp })
        }).then(() => this.setState({ isBookmarkAddComplete: true })).then(() => resolve()).catch(err => reject(err))
    })

    createIframe(fileData) {
        let filepath = fileData.filepath
        console.log(fileData)
        this.setState({ isOpenInIframe: false, allStarts: '', allEnds: '', isShowReadWith: false, selectedFileDetails: fileData, selectedFile: filepath, isFetchFileApiHitComplete: false, htmlFileContent: "" })
        // let url = `${sessionStorage.getItem('Apipathurl')}fetchfile.php`; //this.props.rootUrl+filepath;
        let url = sessionStorage.getItem('url') + 'fetchfile.php'

        var fd = new FormData();
        fd.append(Aesencryption('filename'), Aesencryption(filepath))
        axios({
            mode: 'cors',
            method: "POST",
            headers: { "Content-Type": "multipart/form-data" },
            url: `${url}`,
            data: fd
        }).then(resp => {
            let respData = resp.data
            if (respData == '') {
                this.setState({ isFileContentFound: false, isFetchFileApiHitComplete: true })
            } else {
                // if (respData.toLowerCase().includes('<object')) {
                //     respData = respData.replaceAll(`data="../../`, `data="${sessionStorage.getItem('url')}Assets/Data5t/`)
                // }

                let pageTitle = respData.substring(respData.indexOf('<title>') + 7, respData.indexOf('</title>'));
                let headContent = respData.substring(
                    respData.toLowerCase().indexOf('<head>') + 6,
                    respData.toLowerCase().indexOf('</head>'))
                    .replace('<title>' + pageTitle + '</title>', '')

                console.log(headContent, headContent.toLowerCase().includes('<script'))
                let isIframe = false
                // if (headContent.toLowerCase().includes('<script') || headContent.toLowerCase().includes('<link') || headContent.toLowerCase().includes('<style>')) {
                if (headContent.toLowerCase().includes('<script') || respData.toLowerCase().includes('<img')) {
                    this.setState({ isOpenInIframe: true })
                    isIframe = true
                }


                this.setState({ htmlFileOriginalContent: respData }, () => {
                    let copyText = this.state.htmlFileOriginalContent
                    this.xWithRef(copyText, 1)
                })
                this.getpagedata(this.state.selectedFileDetails)
                    .then(res => !isIframe && this.autoHighlight())
                    .then((re) => this.setState({ isFileContentFound: true, isFetchFileApiHitComplete: true }))
                    .then(() => {
                        // if (document.getElementById('mainboxdiv') && document.getElementById('mainnavbar')) {
                        //     let mainnvabarHeight = document.getElementById('mainnavbar').scrollHeight
                        //     let o = document.getElementById('mainboxdiv').clientHeight
                        //     console.log(mainnvabarHeight, o)
                        //     document.getElementById("mainboxdiv").style.height = `calc(100% - 200px)`
                        // }
                    })
                    .catch(err => console.error(err))
            }
        }).catch(err => console.error(err))

    }
    removeBookmark() {
        this.setState({ isBookmarkAddComplete: false })
        let obj = {
            query: `[dbo].[Lawcrux_Web_Proc_RemoveBookmark_Submit] @UserID='{0}', @filepath='{1}'`,
            queryArr: [`${this.state.selectedFileDetails.userid}`, `${this.state.selectedFileDetails.filepath}`]
        }
        Hexapi(obj).then(resp => {
            console.log(resp)
            if (resp[''][0].command == 1) {
                this.setState({ isBookmarkAdded: false })
            }
        }).then(re => this.setState({ isBookmarkAddComplete: true }))
    }
    addBookmark() {
        console.log(this.state.selectedFileDetails)
        this.setState({ isBookmarkAddComplete: false })
        let obj = {
            query: `[dbo].[Lawcrux_Web_Proc_AddBookmark_Submit] @UserID='{0}', @filepath='{1}',@Description='{2}'`,
            queryArr: [`${this.state.selectedFileDetails.userid}`, `${this.state.selectedFileDetails.filepath}`, `${this.state.selectedFileDetails.description}`]
        }
        Hexapi(obj).then(resp => {
            console.log(resp)
            if (resp[''][0].command == 1) {
                this.setState({ isBookmarkAdded: true })
            }
        }).then(re => this.setState({ isBookmarkAddComplete: true }))
    }





    openHistoryFile(fileData) {
        console.log(fileData)
        const obj = {
            filepath: fileData.filepath,
            description: fileData.description,
            userid: fileData.userid
        }
        console.log(obj)
        this.setState({ search: '', totalFilesCount: 0, allFiles: [], isFirstSearchComplete: true, isApiHitComplete: true, isDataFound: true, isOpenHistoryModal: false, isOpenBookmarkModal: false, isHistoryOpen: true })
        this.createIframe(obj)
    }
    getVerificationStatus(verified){
        if (verified){
            const printDiv = () => {
                var divContents = document.getElementById("customIframe").innerHTML;
                divContents = divContents.replaceAll('href', 'data-href');
                var style = '<div style= "position: absolute;top: 0px;left : 0px ;right: 440px;bottom: 600px;background-image: url(lawcrux.png); background-repeat: no-repeat; background-positon: center;background-size: 164px 67px; width: 159.875 ;height: 72.111; "></div>';
                style += '<div style= "position: absolute;top: 9px;left : 160px ;right: 0px;bottom: 600px; width: 188.875 ;height: 56.111;border-left: 1px solid #000; "></div>';

                style += '<div style= "position: absolute;top: 9px;left : 170px ;right: 0px;bottom: 600px; width: 188.875 ;height: 87.111; ">Smartbook <br> For <br> Indirect Tax</div>'; style += '<div style="position: absolute; top: 6px; right: 17px; padding: 0px; color: white; background-color: white; color: black ;font-size: 15px ;border-bottom: 1px solid black;word-spacing: 0.5rem;padding-bottom: 0.2rem;height: 18.313 ; width: 178.698; text-align: center;">www.lawcrux.com</div>';
                style += '</div>';
                style += '<div style="position: absolute; top: 32px; right: 16px; padding: 0px; color: white; background-color: white; color: black ;font-size: 9px ;word-spacing: 0.3rem">• GST • Custom • FTP • FEMA • SEZ</div>';
                style += '</div>';
                style += '<div style="position: absolute; top: 47px; right: 16px; padding: 0px; color: white; background-color: white; color: black ;font-size: 9px ;word-spacing: 0.2rem">• Central Excise • Service Tax • Allied Acts </div>';
                style += '</div>';

                var a = window.open('', '', 'height=500, width=500');

                a.document.write(style);
                a.document.write('<html>');
                a.document.write('<body>');
                a.document.write('<br>');
                a.document.write('<br>');
                a.document.write('<br>');
                a.document.write('<hr style="border: none; border-top: 1px solid black; font-weight: bold;">');
                a.document.write('</body></html>');
                a.document.write('<html>');
                a.document.write(divContents);
                setTimeout(()=>{

                    a.document.close();
                    a.print();
                },1000);

                // var style = '<div style= "position: absolute;top: 0px;left : 0px ;right: 440px;bottom: 600px;background-image: url(lawcrux.png); background-repeat: no-repeat; background-positon: center;background-size: 100px "></div>';
                // style += '<div style="position: absolute; top: 15px; right: 0px; padding: 10px; color: white; background-color: white; color: black">www.LawCrux.com</div>';
                // style += '</div>';
                // var a = window.open('', '', 'height=500, width=500');
                // a.document.write(style);
                // a.document.write('<html>');
                // a.document.write('<body>');
                // a.document.write('<br>');
                // a.document.write('<br>');
                // a.document.write('<br>');
                // a.document.write('<hr style="border: none; border-top: 1px solid black; font-weight: bold;">');
                // a.document.write('</body></html>');
                // a.document.write('<html>');
                // a.document.write(divContents);

                // var style= '<div style="position: fixed; top: 400px; left: 200px; right: 50px; bottom: 50px; background-image: url(lawcrux.png); background-repeat: no-repeat; background-position: center center;  opacity: 0.7;"></div>';
                // var divContents = this.state.htmlFileOriginalContent 
                // var a = window.open('', '', 'height=500, width=500');
                // a.document.write('<html>');
                // // a.document.write('<body > <h1>Div contents are <br>');
                // a.document.write(divContents);
                // a.document.write('</body></html>');
                // a.document.close();
                // a.print();
            }
            const printIframe = () => {
                // var iframe = $('#originalIframe')[0];
                var iframe = document.getElementById('originalIframe').contentWindow.document.body.innerHTML;
                // iframe = iframe.replaceAll('href', 'data-href')
                iframe = iframe.replaceAll('<a', '<span').replaceAll('</a>', '</span>')

                var style = '<div style= "position: absolute;top: 0px;left : 0px ;right: 440px;bottom: 600px;background-image: url(lawcrux.png); background-repeat: no-repeat; background-positon: center;background-size: 164px 67px; width: 159.875 ;height: 72.111; "></div>';
                style += '<div style= "position: absolute;top: 9px;left : 160px ;right: 0px;bottom: 600px; width: 188.875 ;height: 56.111;border-left: 1px solid #000; "></div>';

                style += '<div style= "position: absolute;top: 9px;left : 170px ;right: 0px;bottom: 600px; width: 188.875 ;height: 87.111; ">Smartbook <br> For <br> Indirect Tax</div>'; style += '<div style="position: absolute; top: 6px; right: 17px; padding: 0px; color: white; background-color: white; color: black ;font-size: 15px ;border-bottom: 1px solid black;word-spacing: 0.5rem;padding-bottom: 0.2rem;height: 18.313 ; width: 178.698; text-align: center;">www.lawcrux.com</div>';
                style += '</div>';
                style += '<div style="position: absolute; top: 32px; right: 16px; padding: 0px; color: white; background-color: white; color: black ;font-size: 9px ;word-spacing: 0.3rem">• GST • Custom • FTP • FEMA • SEZ</div>';
                style += '</div>';
                style += '<div style="position: absolute; top: 47px; right: 16px; padding: 0px; color: white; background-color: white; color: black ;font-size: 9px ;word-spacing: 0.2rem">• Central Excise • Service Tax • Allied Acts </div>';
                style += '</div>';
                var a = window.open('', '', 'height=500, width=500');

                a.document.write(style);
                a.document.write('<html>');
                a.document.write('<body>');
                a.document.write('<br>');
                a.document.write('<br>');
                a.document.write('<br>');
                a.document.write('<hr style="border: none; border-top: 1px solid black; font-weight: bold;">');
                a.document.write('</body></html>');
                a.document.write('<html>');
                a.document.write(iframe);
                setTimeout(()=>{

                    a.document.close();
                    a.print();
                },1000);

                // var style= '<div style="position: fixed; top: 200px; left: 100px; right: 100px; bottom: 200px; background-image: url(lawcrux.png); background-repeat: no-repeat; background-position: center;  opacity: 0.7;"></div>';
                // var style = '<div style= "position: absolute;top: 0px;left : 0px ;right: 440px;bottom: 600px;background-image: url(lawcrux.png); background-repeat: no-repeat; background-positon: center;background-size: 100px "></div>';
                // style += '<div style="position: absolute; top: 15px; right: 0px; padding: 10px; color: white; background-color: white; color: black">www.LawCrux.com</div>';
                // style += '</div>';
                // var a = window.open('', '', 'height=500, width=500');
                // a.document.write(style);
                // a.document.write('<html>');
                // a.document.write('<body>');
                // a.document.write('<br>');
                // a.document.write('<br>');
                // a.document.write('<br>');
                // a.document.write('<hr style="border: none; border-top: 1px solid black; font-weight: bold;">');
                // a.document.write('</body></html>');
                // a.document.write('<html>');
                // a.document.write(iframe);

                // // iframe.contentWindow.focus();
                // var a = window.open('', '', 'height=500, width=500');
                // a.document.write('<html>');
                // // a.document.write('<body > <h1>Div contents are <br>');
                // a.document.write(iframe + style);
                // a.document.write('</body></html>');
                // a.document.close();
                // setTimeout(()=>{
                //     a.print();
                //     a.document.close();
                // },1000);
            }
            this.state.isOpenInIframe ?
                // console.log('hello')
                printIframe()
                :
                printDiv()
            
        }
    }

    setLawAsOnDate(date) {
        console.log(date)

        this.setState({ isOpenLawAsOnDateModal: false })
        date = date.split('-')
        let user_selected_date = new Date(date[1] + '/' + date[1] + '/' + date[0])
        let showing_content = this.state.htmlFileShowContentBackup

        const checkDates = (string) => {
            let updatedString = string
            let lastIndex = 0
            while (true) {
                let xstart_index = updatedString.indexOf("<!--zisqxyx", lastIndex);
                let xend_index = updatedString.indexOf("<!--zieqxyx", lastIndex);
                let dateintext = updatedString.substring(xstart_index + 11, xstart_index + 19)
                console.log(xstart_index, xend_index, lastIndex)

                if (xstart_index < 0 || xstart_index > xend_index) {
                    break;
                }


                let mentioned_date = new Date(`${dateintext.substring(2, 4)}/${dateintext.substring(0, 2)}/${dateintext.substring(4)}`)
                const diffTime = mentioned_date - user_selected_date;
                const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));


                if (diffDays > 0) {
                    /// we have to replace the text
                    // add replace logic in updatedstring
                    let middle_part = updatedString.substring(xstart_index, xend_index + 22);
                    console.log('================middle====================')
                    console.log(middle_part)
                    console.log('================middle _END====================')
                    updatedString = updatedString.replace(middle_part, ' ');

                } else {
                    lastIndex = xend_index + 1;
                }
            }

            lastIndex = 0
            while (true) {
                let xstart_index = updatedString.indexOf("<!--zosqxyx", lastIndex);
                let xend_index = updatedString.indexOf("<!--zoeqxyx", lastIndex);
                let dateintext = updatedString.substring(xstart_index + 11, xstart_index + 19)

                if (xstart_index < 0 || xstart_index > xend_index) {
                    break;
                }
                console.log(xstart_index, xend_index)


                let mentioned_date = new Date(`${dateintext.substring(2, 4)}/${dateintext.substring(0, 2)}/${dateintext.substring(4)}`)
                const diffTime = mentioned_date - user_selected_date;
                const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

                if (diffDays > 0) {
                    lastIndex = xend_index + 1;
                } else {
                    /// we have to replace the text
                    // add replace logic in updatedstring
                    let middle_part = updatedString.substring(xstart_index, xend_index + 22);
                    updatedString = updatedString.replace(middle_part, ' ');

                    console.log('================middle====================')
                    console.log(middle_part)
                    console.log('================middle _END====================')
                }
            }


            for (let i = 0; i < 200; i++) {
                try {
                    let found = 'N'
                    if (updatedString.includes("<a href=\"#" + i + "\">")) {
                        found = "Y";
                    }
                    if (updatedString.includes("<a HREF=\"#" + i + "\">")) {
                        found = "Y";
                    }
                    if (updatedString.includes("<A HREF=\"#" + i + "'\">")) {
                        found = "Y";
                    }

                    if (found == "N") {
                        let xsearch = `<a name=${i}`,
                            start_index = updatedString.indexOf(xsearch)

                        if (start_index < 0) {
                            xsearch = `<a name="${i}"`
                            start_index = updatedString.indexOf(xsearch)
                        }

                        let xsearch_next = `<a name=${i + 1}`,
                            xend_index = updatedString.indexOf(xsearch_next)

                        if (xend_index < 0) {
                            xsearch_next = `<a name="${i + 1}"`
                            xend_index = updatedString.indexOf(xsearch_next)
                        }



                        if (start_index > 1 && xend_index > start_index + 4) {
                            let middle_part = updatedString.substring(start_index, xend_index);
                            updatedString = updatedString.replace(middle_part, ' ');
                            // xfirst_part = xbalance_content.Substring(0, xstart_index);
                            // xsecond_part = xbalance_content.Substring(xend_index, xbalance_content.Length - xend_index);
                            // xcut_content = xbalance_content.Substring(xstart_index, xend_index - xstart_index);
                            // xbalance_content = xfirst_part + " " + xsecond_part;
                        }


                        if (start_index > 1 && xend_index < 0) {
                            let xfirst_part = updatedString.substring(0, start_index);
                            updatedString = xfirst_part + "</body> ";

                        }
                        // if (updatedString.includes(searchPart)) {

                        // }
                        // let xsearch = "<a name=" + xnumber;
                        // let xstart_index = xbalance_content.ToUpper().IndexOf(xsearch.ToUpper());
                    }

                } catch {
                    console.error('some error in law as on date')
                }
            }


            let newText = updatedString.replaceAll('�', '"').replaceAll('[helldod old', '').replaceAll('[helldod', "").replaceAll('helldod]', "").replaceAll('<sup>old</sup>', '')
            return newText
        }
        let newText = checkDates(showing_content)

        this.setState({
            htmlFileShowContent: newText,
            // htmlFileShowContentBackup: newText
        })
    }


    renderer = ({ hours, minutes, seconds, completed }) => {
        console.log(seconds)
        if (completed) {
            if (this.state.isOnline) {
                // Render a completed state
                return (
                    <>
                        <span>Server is busy...Please wait</span>;
                        <br />
                        <span style={{ color: 'blue', cursor: "pointer" }} onClick={() => this.setState({ isRenderAgaing: true }, () => this.searchKeyword(this.state.search, this.state.searchPath))}>Retry</span>
                        &nbsp;Or&nbsp;
                        <span style={{ color: 'blue', cursor: "pointer" }} onClick={() => this.setState({ isDataFound: false, isTimerModal: false, firstTimeClick: true, openInitialModal: true, isRefinedSearch: false, targetField: '', targetSubField: '' })}>Cancel</span>
                    </>
                )
                // return <span>Server is busy...Please wait</span>;
                // return <span>Server is busy...Please wait &nbsp;Or&nbsp; <span style={{color:'blue',cursor:"pointer"}} onClick={() => this.setState({isTimerModal:false})}>Exit</span></span>;
            } else {
                return <span>Internet Connetion Lost.</span>
            }
        } else {
            if (this.state.isOnline) {
                // Render a countdown
                return <span>Loading...{seconds}sec</span>;
            } else {
                return <span>Internet Connetion Lost.</span>
            }
        }
    };
    setMainBoxWidth() {
        let mainbox = document.getElementById('mainBoxContent')
        if (this.state.isShowRightBox == true) {
            mainbox.style.minWidth = 'calc(100% - 500px)'
        } else {
            mainbox.style.minWidth = 'calc(100%)'
        }
        // mainbox.style.transition = '0.2s all'
    }
    handleStatusChange = () => {
        this.setState({ isOnline: navigator.onLine })
    }
    render() {
        // Listen to the online status
        window.addEventListener('online', this.handleStatusChange);
        // Listen to the offline status
        window.addEventListener('offline', this.handleStatusChange);
        console.log(this.state.isOnline)
        return (
            <>
                {
                    this.state.isOpenSearchModal ?
                        <Modal fullscreen={true} id="documentmodal" animation={true} show={this.state.isOpenSearchModal} onHide={() => this.setState({ isOpenSearchModal: false })}>
                            <Modal.Body>
                                <SearchComp
                                    searchApi={this.props.searchApi}
                                    rootUrl={this.props.rootUrl}
                                    goBack={() => this.setState({ isOpenSearchModal: false })}
                                    userid={this.props.userid}
                                    isReferedByHsn={false}
                                    hsnSearchWord={''}
                                    hsnPath={''}
                                    isReferedByHistory={false}
                                    historyFileData={[]}
                                    goHome={() => this.props.goHome()}
                                />
                            </Modal.Body>
                        </Modal>
                        :
                        <>
                            <div style={{ backgroundColor: '#f8f9fb' }}>
                                <div className="navbar">
                                    <div className="row leftdiv">
                                        {/* <div className="col-1">
                                <Tooltip title="Go back">
                                    <IconButton onClick={() => this.props.goBack()}>
                                        <ArrowBackIcon />
                                    </IconButton>
                                </Tooltip>
                            </div> */}
                                        <div className="col-1">
                                            <Tooltip title="Go Home">
                                                <IconButton onClick={() => this.props.goHome()}>
                                                    <HomeIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </div>
                                        <div className="col-1">
                                            <Tooltip title="Go back">
                                                {/* <IconButton onClick={() => this.props.goBack()}> */}
                                                <IconButton onClick={() => {
                                                    if (this.currentPagePosition > 1) {
                                                        this.goBackward_CustomHistory()
                                                    } else {
                                                        // skipCount=0
                                                        this.props.goBack()
                                                    }
                                                }}>
                                                    <ArrowBackIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </div>
                                        <div className="col-1">
                                            <Tooltip title="Go Forward">
                                                {/* <IconButton onClick={() => this.props.goBack()}> */}
                                                <IconButton onClick={() => this.goForward_CustomHistory()}>
                                                    <ArrowForwardIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </div>
                                        <div className="col-1">
                                            <Tooltip title="Search keyword">
                                                {/* <IconButton onClick={() => this.setState({isOpenSearchModal:true, firstTimeClick: false, openInitialModal: true, isRefinedSearch: false, targetField: '', targetSubField: '' })}> */}
                                                <IconButton onClick={() => this.setState({ isOpenSearchModal: true, firstTimeClick: false, openInitialModal: false, isRefinedSearch: false, targetField: '', targetSubField: '' })}>
                                                    <SearchIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </div>
                                        <div className="col-1">
                                            <Tooltip title="HSN Search">
                                                <IconButton onClick={() => this.setState({ isOpenHSNSearch: true })}>
                                                    <QueryStatsOutlinedIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </div>
                                        <div className="col-1">
                                            <Tooltip title="Dictionary">
                                                <IconButton onClick={() => this.setState({ isOpenDictModal: true })}>
                                                    <LibraryBooksIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </div>
                                        <div className="col-1">
                                            <Tooltip title="History">
                                                <IconButton onClick={() => this.setState({ isOpenHistoryModal: true })}>
                                                    <HistoryIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </div>
                                        <div className="col-1">
                                            <Tooltip title="Bookmark List">
                                                <IconButton onClick={() => this.setState({ isOpenBookmarkModal: true })}>
                                                    <BookmarksIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </div>
                                        <div className="col-1">
                                                <Tooltip title="Print">
                                                    <IconButton onClick={() => {this.setState({Printmodal:true})}}>
                                                        <PrintIcon />
                                                    </IconButton>
                                                </Tooltip>
                                        </div>
                                        {
                                            !this.state.isOpenInIframe ?
                                                <>
                                                    <div className="col-1">
                                                        <Tooltip title="Utilities">
                                                            <IconButton onClick={() => this.setState({ isMagicWondOpen: true })}>
                                                                <AutoFixHighIcon />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </div>
                                                    <div className="col-1">
                                                        <Tooltip title={this.state.isDarkMode ? "Light Mode" : "Dark Mode"}>
                                                            <IconButton onClick={() => this.setState({ isDarkMode: !this.state.isDarkMode }, () => {
                                                                if (!this.state.isDarkMode) {
                                                                    document.getElementById('customIframe').innerHTML = document.getElementById('customIframe').innerHTML.replaceAll('background-color: rgb(0, 0, 0);', 'background-color: transparent;')
                                                                    this.setState({ highlightColor: 'lightgreen' }, () => {
                                                                        this.autoHighlight()
                                                                        console.log(this.state.highlightColor)
                                                                    })
                                                                } else {
                                                                    this.setState({ highlightColor: 'red' }, () => {
                                                                        this.autoHighlight()
                                                                        console.log(this.state.highlightColor)
                                                                    })
                                                                }
                                                            })}>
                                                                {
                                                                    this.state.isDarkMode ?
                                                                        <Brightness4Icon />
                                                                        :
                                                                        <Brightness4OutlinedIcon />
                                                                }
                                                            </IconButton>
                                                        </Tooltip>
                                                    </div>
                                                </>
                                                :
                                                <div className="col-1">
                                                    <Tooltip title={this.state.isIframeDarkMode ? "Light Mode" : "Dark Mode"}>
                                                        <IconButton onClick={() => this.setState({ isIframeDarkMode: !this.state.isIframeDarkMode }, () => {
                                                            const iframes = document.querySelectorAll('iframe');
                                                            function toggleTheme() {
                                                                for (let i = 0; i < iframes.length; i++) {
                                                                    iframes[i].classList.toggle('is-dark');
                                                                }
                                                            }
                                                            toggleTheme()
                                                        })}>
                                                            {
                                                                this.state.isIframeDarkMode ?
                                                                    <Brightness4Icon />
                                                                    :
                                                                    <Brightness4OutlinedIcon />
                                                            }
                                                        </IconButton>
                                                    </Tooltip>
                                                </div>
                                        }

                                        {
                                            !this.state.isOpenInIframe &&
                                            this.state.isFetchFileApiHitComplete && (this.props.userid == "1" || this.props.userid == "2") &&
                                            // <div className="col-md-3 col-12" style={{ padding: '10px' }}>
                                            //     <span className='read_with' onClick={() => this.setState({ isOpenInHouseDevelopment: true })}>
                                            //         In House Development
                                            //     </span>
                                            // </div>
                                            <div className="col-1">
                                                <Tooltip title="In House Development">
                                                    <IconButton onClick={() => this.setState({ isOpenInHouseDevelopment: true })}>
                                                        <span style={{ fontSize: "14px", fontWeight: "600" }}>IHD</span>
                                                    </IconButton>
                                                </Tooltip>
                                            </div>
                                        }

                                        {
                                            !this.state.isOpenInIframe ?
                                                this.state.isFetchFileApiHitComplete ?
                                                    this.state.isFileContentFound &&
                                                    <>
                                                        <div className="col-1">
                                                            <Tooltip title="Highlight text">
                                                                <IconButton onClick={() => this.getSelectedText()}>
                                                                    <HighlightIcon />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </div>
                                                        <div className="col-1">
                                                            <Tooltip title="Zoom In">
                                                                <IconButton onClick={() => {
                                                                    if (this.state.zoomFrame < 6) {
                                                                        this.setState({ zoomFrame: this.state.zoomFrame + 0.4 })
                                                                    }
                                                                }}>
                                                                    <AddIcon />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </div>
                                                        <div className="col-1">
                                                            <Tooltip title="Zoom Out">
                                                                <IconButton onClick={() => {
                                                                    if (this.state.zoomFrame > 1) {
                                                                        this.setState({ zoomFrame: this.state.zoomFrame - 0.4 })
                                                                    }
                                                                }}>
                                                                    <RemoveIcon />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </div>
                                                        {
                                                            this.state.allStarts != "" && this.state.allEnds != "" ?
                                                                <div className="col-1">
                                                                    <Tooltip title="Remove Highlight">
                                                                        <IconButton onClick={() => this.removeSelectedText()}>
                                                                            <HighlightOutlinedIcon />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                </div>
                                                                : null
                                                        }
                                                        <div className="col-1">
                                                            <Tooltip title="Law as on date">
                                                                <IconButton onClick={() => this.setState({ isOpenLawAsOnDateModal: true })}>
                                                                    <CalendarMonthIcon />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </div>
                                                        {
                                                            this.state.isBookmarkAddComplete ?
                                                                this.state.isBookmarkAdded ?
                                                                    <div className="col-1">
                                                                        <Tooltip title="Remove Bookmark">
                                                                            <IconButton onClick={() => this.removeBookmark()}>
                                                                                <BookmarkIcon />
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                    </div>
                                                                    :
                                                                    <div className="col-1">
                                                                        <Tooltip title="Add Bookmark">
                                                                            <IconButton onClick={() => this.addBookmark()}>
                                                                                <BookmarkBorderRoundedIcon />
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                    </div>
                                                                :
                                                                <div className="col-1">
                                                                    <Spinner variant='dark' animation='border' />
                                                                </div>
                                                        }
                                                        {/* <div className="col-md-3 col-12" style={{ padding: '10px' }}>
                                                            <span className='read_with' onClick={() => this.setState({ isShowReadWith: !this.state.isShowReadWith }, () => {
                                                                if (this.state.isShowReadWith) {
                                                                    this.xWithoutRef(this.state.htmlFileOriginalContent)
                                                                } else {
                                                                    this.xWithRef(this.state.htmlFileOriginalContent, 0)
                                                                }
                                                            })}>
                                                                {
                                                                    this.state.isShowReadWith ?
                                                                        "Read Current Law" : "Read With Old Law Reference"
                                                                }
                                                            </span>
                                                        </div> */}
                                                        <div className="col-1">
                                                            {
                                                                this.state.isShowReadWith ?
                                                                    <Tooltip title="Read Current Law">
                                                                        <IconButton onClick={() => this.setState({ isShowReadWith: !this.state.isShowReadWith }, () => this.xWithRef(this.state.htmlFileOriginalContent, 0))}>
                                                                            <span style={{ fontSize: "14px", fontWeight: "600" }}>ABC</span>
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                    :
                                                                    <Tooltip title="Read With Old Law Reference">
                                                                        <IconButton onClick={() => this.setState({ isShowReadWith: !this.state.isShowReadWith }, () => this.xWithoutRef(this.state.htmlFileOriginalContent))}>
                                                                            <span style={{ fontSize: "14px", fontWeight: "600", textDecoration: 'line-through' }}>ABC</span>
                                                                        </IconButton>
                                                                    </Tooltip>
                                                            }
                                                        </div>
                                                    </>
                                                    :
                                                    null
                                                :
                                                <>
                                                    <div className="col-1">
                                                        <Tooltip title="Zoom In">
                                                            <IconButton onClick={() => {
                                                                if (this.state.zoomFrame < 2.3) {
                                                                    this.setState({ zoomFrame: this.state.zoomFrame + 0.1 }, () => {
                                                                        $('#originalIframe').css({
                                                                            'transform': `scale(${this.state.zoomFrame})`,
                                                                            'transform-origin': '0 0'
                                                                        });
                                                                    })
                                                                }

                                                            }}>
                                                                <AddIcon />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </div>
                                                    <div className="col-1">
                                                        <Tooltip title="Zoom Out">
                                                            <IconButton onClick={() => {
                                                                if (this.state.zoomFrame > 1) {
                                                                    this.setState({ zoomFrame: this.state.zoomFrame - 0.1 }, () => {
                                                                        $('#originalIframe').css({
                                                                            'transform': `scale(${this.state.zoomFrame})`,
                                                                            'transform-origin': '0 0'
                                                                        });
                                                                    })
                                                                }
                                                            }}>
                                                                <RemoveIcon />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </div>
                                                </>
                                        }
                                    </div>
                                </div>
                                {
                                    this.state.isApiHitComplete ?
                                        this.state.isDataFound ?
                                            <div className="Search_contentBox">
                                                <div className="mainBox" id="mainBoxContent" style={{ minWidth: 'calc(100% - 500px)', overflow: "auto" }}>
                                                    {
                                                        this.state.selectedFile != "" ?
                                                            this.state.isFetchFileApiHitComplete ?
                                                                this.state.isFileContentFound ?
                                                                    this.state.isOpenInIframe ?
                                                                        <>
                                                                            <iframe onLoad={() => this.onIframeLoad()} id="originalIframe" src={`${sessionStorage.getItem('url')}${this.state.selectedFileDetails.filepath}`} frameborder="0" width={'100%'} height={"480px"}></iframe>
                                                                        </>
                                                                        :
                                                                        <>
                                                                            <div id="customIframe" dangerouslySetInnerHTML={{ __html: this.state.htmlFileShowContent }} style={{ background: this.state.isDarkMode ? '#000' : 'transparent', color: this.state.isDarkMode ? "#fff" : "#000", zoom: this.state.zoomFrame }}></div>
                                                                            {
                                                                                this.state.isMagicWondOpen &&
                                                                                <Magicwond
                                                                                    pageData={this.state.pageData}
                                                                                    onCloseModal={() => this.setState({ isMagicWondOpen: false })}
                                                                                    userid={this.state.selectedFileDetails.userid}
                                                                                    filepath={this.state.selectedFileDetails.filepath}
                                                                                    fetchPageData={() => this.setState({ isMagicWondOpen: false }, () => this.getpagedata(this.state.selectedFileDetails))}
                                                                                    openHistoryFile={(fileData) => this.openHistoryFile(fileData)}
                                                                                />
                                                                            }
                                                                        </>
                                                                    :
                                                                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: '100%', height: "inherit" }}>
                                                                        <h5 style={{ color: 'var(--norecord)' }}>File not found</h5>
                                                                    </div>
                                                                :
                                                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: '100%', height: "inherit" }}>
                                                                    <Spinner animation="border" variant="dark" />
                                                                </div>
                                                            :
                                                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: '100%', height: "inherit" }}>
                                                                <h5 style={{ color: 'var(--norecord)' }}>No file selected</h5>
                                                            </div>
                                                    }
                                                </div>
                                                {
                                                    this.state.isShowRightBox ?
                                                        this.state.isApiHitComplete ?
                                                            <div className="rightBox noselect table">
                                                                <div className="toprow">
                                                                    <div style={{ display: 'flex', alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                                                                        <span></span>
                                                                        {/* Total Files - {this.state.tableData.length} */}
                                                                        {/* {
                                                                this.state.isSortEnable ?
                                                                    <Tooltip title="Sort descending">
                                                                        <IconButton onClick={() => this.setState({ isSortEnable: false })}>
                                                                            <ArrowDropUpIcon />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                    :
                                                                    <Tooltip title="Sort ascending">
                                                                        <IconButton onClick={() => this.setState({ isSortEnable: true })}>
                                                                            <ArrowDropDownIcon />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                            } */}
                                                                    </div>
                                                                    <Tooltip title="Collapse Listview">
                                                                        <IconButton style={{ padding: "0px" }} onClick={() => {
                                                                            this.setState({ isShowRightBox: false }, () => this.setMainBoxWidth())
                                                                        }}>
                                                                            <CloseIcon style={{ fontSize: "40px" }} />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                </div>
                                                                <Datatable
                                                                    data={this.state.tableData}
                                                                    customId={'testing'}
                                                                    uppercase={false}                                                                    
                                                                    onRowClick={(rowData) => {
                                                                        let subfolder = ''
                                                                        let mainfolder = ''
                                                                        // if(this.props.clickedLink.name.toLowerCase() == 'tariff' &&  this.props.clickedLink.type == 'custom')|| this.props.clickedLink.name.toLowerCase() == 'non tariff' ? 'cuncuv' : 'cuncuc')
                                                                        if (this.props.clickedLink.name.toLowerCase() == 'circular' && this.props.clickedLink.type == 'custom') {
                                                                            mainfolder = 'cu'
                                                                            subfolder = 'cuncuc'
                                                                        }
                                                                        if (this.props.clickedLink.name.toLowerCase() == 'circular' && this.props.clickedLink.type == 'excise') {
                                                                            mainfolder = 'ce'
                                                                            subfolder = 'cencec'
                                                                        }
                                                                        if (this.props.clickedLink.name.toLowerCase() == 'circular' && this.props.clickedLink.type == 'service tax') {
                                                                            mainfolder = 'st'
                                                                            subfolder = 'stncsc'
                                                                        }
                                                                        if (this.props.clickedLink.name.toLowerCase() == 'non tariff' && this.props.clickedLink.type == 'custom nt') {
                                                                            mainfolder = 'cu'
                                                                            subfolder = 'cuncun'
                                                                        }
                                                                        if (this.props.clickedLink.name.toLowerCase() == 'tariff' && this.props.clickedLink.type == 'custom tariff') {
                                                                            mainfolder = 'cu'
                                                                            subfolder = 'cuncut'
                                                                        }
                                                                        if (this.props.clickedLink.name.toLowerCase() == 'non tariff' && this.props.clickedLink.type == 'excise nt') {
                                                                            mainfolder = 'ce'
                                                                            subfolder = 'cencen'
                                                                        }
                                                                        if (this.props.clickedLink.name.toLowerCase() == 'tariff' && this.props.clickedLink.type == 'excise tariff') {
                                                                            mainfolder = 'ce'
                                                                            subfolder = 'cencet'
                                                                        }
                                                                        if (this.props.clickedLink.name.toLowerCase() == 'history' && this.props.clickedLink.type == 'service tax') {
                                                                            mainfolder = 'st'
                                                                            subfolder = 'stncst'
                                                                        }
                                                                        console.table(mainfolder, subfolder, this.props.clickedLink)
                                                                        let obj = {
                                                                            userid: this.props.userid,
                                                                            filepath: `Assets/Data5t/${mainfolder}/${subfolder}/${rowData.code}.htm`,
                                                                            title: rowData.subject
                                                                        }
                                                                        // console.log(obj)
                                                                        this.storeHistory(obj)
                                                                    }}
                                                                    inputProps={{
                                                                        thead: {
                                                                            backgroundColor: "orange",
                                                                            color: '#fff'
                                                                        }
                                                                    }}
                                                                cols={['Code','Number',"date","year","subject"]}

                                                                // navbar={{
                                                                //     isBackBtn: false,
                                                                //     isFilterBtn: true,
                                                                //     isDenseBtn: true,
                                                                //     isDownloadBtn: true,
                                                                //     isSearchBtn: true,
                                                                //     heading: 'FG GOODS MASTER',
                                                                //     searchOnKeys: [],
                                                                //     downloadOptions: 'pdf,excel',
                                                                //     downloadUrl: 'https://web.trackhrapp.com/Web/Examples/GenerateExcel.php',
                                                                //     callbacks: {
                                                                //         onBackBtn: (e) => {
                                                                //             console.log(e)
                                                                //         },
                                                                //         onFilterBtn: (e) => {
                                                                //             console.log(e)
                                                                //         },
                                                                //     },
                                                                //     Actions:
                                                                //         <div style={{ display: 'flex', justifyContent: "space-between", alignItems: "center" }}>
                                                                //             <Tooltip title="Delete">
                                                                //                 <IconButton onClick={() => this.deleterowdata()}>
                                                                //                     <DeleteIcon />
                                                                //                 </IconButton>
                                                                //             </Tooltip>
                                                                //         </div>
                                                                // }}
                                                                // replaceColumns={[
                                                                //     { name: 'recid', with: 'Id' },
                                                                //     { name: 'partname', with: 'Part Name' },
                                                                //     { name: 'partno', with: 'Part No' },
                                                                //     { name: 'ppcname', with: 'Ppc Name' },
                                                                // ]}
                                                                // pagination={{
                                                                //     show: true,
                                                                //     rowsPerPage: 1,
                                                                //     rowsPerPageOptions: [1, 8, 20, 40, 80, 100, { label: 'All', value: -1 }]
                                                                // }}
                                                                />
                                                            </div>
                                                            :
                                                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: '100%', height: "inherit" }}>
                                                                <Spinner animation="border" variant="dark" />
                                                            </div>
                                                        // <div className="rightBox noselect" id='rightBoxFiles'>
                                                        //     <div className="toprow">
                                                        //         <div style={{ display: 'flex', alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                                                        //             <span>Total Files - {this.state.allFiles.length}</span>

                                                        //             {
                                                        //                 this.state.isSortEnable ?
                                                        //                     <Tooltip title="Sort descending">
                                                        //                         <IconButton onClick={() => this.setState({ isSortEnable: false })}>
                                                        //                             <ArrowDropUpIcon />
                                                        //                         </IconButton>
                                                        //                     </Tooltip>
                                                        //                     :
                                                        //                     <Tooltip title="Sort ascending">
                                                        //                         <IconButton onClick={() => this.setState({ isSortEnable: true })}>
                                                        //                             <ArrowDropDownIcon />
                                                        //                         </IconButton>
                                                        //                     </Tooltip>
                                                        //             }

                                                        //         </div>
                                                        //         <Tooltip title="Collapse Listview">
                                                        //             <IconButton onClick={() => {
                                                        //                 this.setState({ isShowRightBox: false }, () => this.setMainBoxWidth())
                                                        //                 // document.getElementById("mainBoxContent").style.transition = '0.5s all'
                                                        //                 // document.getElementById("mainBoxContent").style.minWidth = 'calc(100% - 300px)'
                                                        //             }}>
                                                        //                 <CloseIcon />
                                                        //             </IconButton>
                                                        //         </Tooltip>
                                                        //     </div>
                                                        //     <div className="tree">
                                                        //         {
                                                        //             this.state.isFileTitleFetched ?
                                                        //                 this.state.isFileTitleFound ?
                                                        //                     this.state.allFiles.sort((a, b) => {
                                                        //                         // this.state.isSortEnable && a.title > b.title ? 1 : -1
                                                        //                         if (this.state.isSortEnable) {
                                                        //                             if (a.title.toLowerCase() > b.title.toLowerCase()) {
                                                        //                                 return 1
                                                        //                             } else {
                                                        //                                 return -1
                                                        //                             }
                                                        //                         } else {
                                                        //                             if (b.title.toLowerCase() > a.title.toLowerCase()) {
                                                        //                                 return 1
                                                        //                             } else {
                                                        //                                 return -1
                                                        //                             }
                                                        //                         }
                                                        //                     }).map((child, index) => {
                                                        //                         console.log(this.state.isSortEnable)
                                                        //                         let fileData = {
                                                        //                             title: child.title,
                                                        //                             filepath: child.path.replace("C:/htdocs/Lawcrux/Lawcrux/Web/", ''),
                                                        //                             userid: this.props.userid
                                                        //                         }
                                                        //                         return (
                                                        //                             <div className="fileRow" style={{ marginBottom: index == this.state.allFiles.length - 1 ? '30px' : "0px", backgroundColor: this.state.selectedFile == child.path ? 'rgba(25, 118, 210, 0.08)' : "transparent", borderBottom: "1px solid rgba(0,0,0,0.1)" }} onClick={() => this.storeHistory(fileData)}>
                                                        //                                 {/* <span className="foundCount">{child.foundCount}</span> */}
                                                        //                                 <div className="fileName">{child.title}</div>
                                                        //                             </div>
                                                        //                         )
                                                        //                     })
                                                        //                     // <List
                                                        //                     //     allFiles={this.state.allFiles}
                                                        //                     //     isSortEnable={this.state.isSortEnable}
                                                        //                     //     userid={this.props.userid}
                                                        //                     //     selectedField={this.state.selectedFile}
                                                        //                     //     storeHistory={(fileData) => this.storeHistory(fileData)}
                                                        //                     // />
                                                        //                     :
                                                        //                     <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: '100%', height: "inherit" }}>
                                                        //                         <h5 style={{ color: 'var(--norecord)' }}>No record</h5>
                                                        //                     </div>
                                                        //                 :
                                                        //                 <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: '100%', height: "inherit" }}>
                                                        //                     <Spinner animation="border" variant="dark" />
                                                        //                 </div>
                                                        //         }
                                                        //     </div>
                                                        // </div>
                                                        :
                                                        <div className="closedSideMenu">
                                                            <Tooltip title="Expand Listview">
                                                                <IconButton onClick={() => {
                                                                    this.setState({ isShowRightBox: true }, () => this.setMainBoxWidth())
                                                                    // document.getElementById("mainBoxContent").style.minWidth = 'calc(100% - 700px)'
                                                                    // document.getElementById("mainBoxContent").style.transition = '0.2s all'
                                                                }}>
                                                                    <ArrowBackIcon style={{ color: '#fff' }} />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </div>
                                                }

                                            </div>
                                            :
                                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: '100%', height: "91vh" }}>
                                                <h5 style={{ color: 'var(--norecord)' }}>No Record!</h5>
                                            </div>
                                        :
                                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: '100%', height: "91vh" }}>
                                            <Spinner animation="border" variant="dark" />
                                        </div>

                                }
                            </div>
                            <Modal id={'search_options_modal'} size="md" centered style={{ backgroundColor: 'rgba(0,0,0,0.6)' }} show={this.state.isOpenInHouseDevelopment} onHide={() => this.setState({ isOpenInHouseDevelopment: false })}>
                                <Modal.Body style={{ padding: "10px" }}>
                                    <div className='InhouseDevelopment'>
                                        <b>Location:</b>&nbsp;&nbsp;
                                        <span>{this.state.selectedFileDetails.filepath}</span>
                                    </div>
                                </Modal.Body>
                            </Modal>
                            {
                                this.state.isOpenHSNSearch &&
                                <Hsnsearch
                                    onCloseModal={() => this.setState({ isOpenHSNSearch: false })}
                                    userid={this.props.userid}
                                />
                            }
                            {
                                this.state.isOpenDictModal &&
                                <Dictionary
                                    rootUrl={this.props.rootUrl}
                                    userid={this.props.userid}
                                    onCloseModal={() => this.setState({ isOpenDictModal: false })}
                                />
                            }
                            {
                                this.state.isOpenHistoryModal &&
                                <HistoryKeeping
                                    rootUrl={this.props.rootUrl}
                                    userid={this.props.userid}
                                    onCloseModal={() => this.setState({ isOpenHistoryModal: false })}
                                    openHistoryFile={(fileData) => this.openHistoryFile(fileData)}
                                />
                            }
                            {
                                this.state.isOpenBookmarkModal &&
                                <BookmarkKeeping
                                    rootUrl={this.props.rootUrl}
                                    userid={this.props.userid}
                                    onCloseModal={() => this.setState({ isOpenBookmarkModal: false })}
                                    openHistoryFile={(fileData) => this.openHistoryFile(fileData)}
                                />
                            }
                            {/* {
                    this.state.isOpenNotesModal &&
                    <Notes
                        onCloseModal={() => this.setState({ isOpenNotesModal: false })}
                        filedetails={this.state.selectedFileDetails}
                        oldNote={this.state.usernotes}
                        onSaveNote={() => this.setState({ isOpenNotesModal: false }, () => this.getpagedata(this.state.selectedFileDetails))}
                    />
                } */}
                            {/* {
                    this.state.isOpenSummaryModal &&
                    <Summary
                        onCloseModal={() => this.setState({ isOpenSummaryModal: false })}
                        summaryData={this.state.summaryData}
                    />
                } */}
                            {
                                this.state.isOpenLawAsOnDateModal &&
                                <Lawasondate
                                    onCloseModal={() => this.setState({ isOpenLawAsOnDateModal: false })}
                                    onSubmit={(date) => this.setLawAsOnDate(date)}
                                />
                            }
                            {
                                this.state.Printmodal &&
                                <Captcha 
                                    onCloseModal={() => this.setState({ Printmodal: false })}
                                    Verification={(val)=>this.getVerificationStatus(val)}></Captcha>  
                            }
                            {/* {
                    this.state.isOpenCompareLaws &&
                    <Comparelaws
                        onCloseModal={() => this.setState({ isOpenCompareLaws: false })}
                    />
                } */}
                            {/* {
                            this.state.openInitialModal &&
                            <Modal id={'search_options_modal'} scrollable={true} size="lg" centered style={{ backgroundColor: 'rgba(0,0,0,0.6)' }} animation={true} show={this.state.openInitialModal} onHide={() => this.setState({ openInitialModal: false, firstTimeClick: false })} keyboard={false} backdrop='static'>
                                <Modal.Header>
                                    <div className="header">
                                        <span>Search</span>
                                        <IconButton onClick={() => this.state.firstTimeClick ? this.props.goBack() : this.setState({ openInitialModal: false })}>
                                            <CloseIcon />
                                        </IconButton>
                                    </div>
                                </Modal.Header>
                                <Modal.Body style={{ backgroundColor: "khaki" }}>
                                    <div className="modal_search">
                                        <input style={{ marginRight: "10px" }} type="text" placeholder='Search...' value={this.state.search} id='todolistsearchbox' className="form-control" onChange={(e) => this.storeSearch(e)} onKeyUp={(e) => this.handleKeyPress(e)} autoFocus={true} />
                                        <Tooltip title="Search keyword">
                                            <IconButton onClick={() => this.searchKeyword(this.state.search, this.state.searchPath)}>
                                                <SearchIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </div>
                                    <div className="inputBoxes">
                                        <div className="topBox">
                                            <div className="main_rightBox">
                                                <Parametericsearch
                                                    searchCategories={this.state.searchCategories}
                                                    searchType={this.state.searchType}
                                                    onRadioChange={(type) => this.setState({ searchType: type })}
                                                    userid={this.props.userid}
                                                    // onSearch={(allfiles, url) => this.onParametricSearch(allfiles, url)}
                                                    onCloseModal={() => this.setState({ isOpenParamSearch: false })}
                                                    onInputsChange={(data) => data == null ? this.setState({ paramSearchObj: data, selectedJudge: '' }) : this.setState({ paramSearchObj: data })}
                                                    onFieldsChange={(obj) => this.setState({ searchPath: obj.fullPath, targetField: obj.selectedField, targetSubField: obj.selectedSubField })}
                                                    onJudgeChange={(judgeName) => this.setState({ selectedJudge: judgeName })}
                                                // paramSearchObj={this.state.paramSearchObj}
                                                // isRefinedSearch={this.state.isRefinedSearch}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </Modal.Body>
                            </Modal>
                        } */}



                            {/* Timer Modal */}
                            <Modal scrollable={true} size="sm" centered style={{ backgroundColor: 'rgba(0,0,0,0.6)' }} animation={true} show={this.state.isTimerModal} onHide={() => this.setState({ isTimerModal: false })} keyboard={false} backdrop='static'>
                                <Modal.Body>
                                    <div className="timer" style={{ display: 'flex', justifyContent: 'center', alignItems: "center", flexDirection: "column", minHeight: "15vh" }}>
                                        <Spinner animation="border" variant="dark" />
                                        {/* <span id="loadingTimer">
                                            <Countdown
                                                date={Date.now() + 45000}
                                                // intervalDelay={0}
                                                // precision={3}
                                                renderer={(hrs, mins, secs, status) => this.renderer(hrs, mins, secs, status)}
                                            />
                                        </span> */}
                                    </div>
                                </Modal.Body>
                            </Modal>
                        </>
                }
            </>
        )
    }
}
