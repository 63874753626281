import React, { Component } from 'react'
import { Modal, Spinner } from 'react-bootstrap'
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import Magicwond from './Magicwond';
import Hexapi from '../../usefulcomps/HexAPI/Hexapi';
import { Aesencryption } from '../../usefulcomps/Aes/Aes';
import axios from 'axios';

export default class Magicwondframes extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isComponentRender: false, isMagicWondOpen: false, pagedata: [], header: '', urls: []
        }
    }
    getpagedata = (file) => new Promise((resolve, reject) => {
        let obj = {
            query: `[dbo].[Lawcrux_Web_Proc_Page_GetDataV2] @UserID='{0}', @filepath='{1}'`,
            queryArr: [`${this.props.userid}`, `${file}`]
        }
        Hexapi(obj).then(resp => {
            console.log(resp)
            this.setState({ pagedata: resp })
        }).then(() => resolve()).catch(err => reject(err))
    })
    componentDidMount() {
        this.setState({ isComponentRender: false })
        let url = []
        let framedata = this.props.data
        let keys = Object.keys(framedata)
        console.log(framedata)
        let mainfolder = ''
        let subfolder = ''
        let filename = ''
        console.log(keys)
        for (let i = 0; i < keys.length; i++) {
            mainfolder = framedata[keys[i]].foldername.substring(0, 2);
            subfolder = framedata[keys[i]].foldername.substring(2);
            filename = framedata[keys[i]].filename
            if (filename != "") {
                let path = sessionStorage.getItem('url') + 'Assets/Data5t/' + mainfolder + '/' + subfolder + '/' + filename
                var fd = new FormData();
                fd.append(Aesencryption('filename'), Aesencryption(path))
                axios({
                    mode: 'cors',
                    method: "POST",
                    headers: { "Content-Type": "multipart/form-data" },
                    url: `${sessionStorage.getItem('url')}fetchfile.php`,
                    data: fd
                }).then(resp => {
                    console.log(resp)
                    if (resp.data != "") {
                        url.push(path)
                    } else {
                        let path = sessionStorage.getItem('url') + 'Assets/Data5t/' + mainfolder + '/' + subfolder + '/' + filename.replaceAll('_', "z")
                        url.push(path)
                    }
                    // console.log(url.length, url)
                }).then(() => {
                    this.setState({ urls: url, header: this.props.header != undefined ? this.props.header : '' })
                    // this.getpagedata(url[0]).then(() => {
                    //     this.setState({ isComponentRender: true })
                    // })
                    setTimeout(() => {
                        if (i == keys.length - 1) {
                            console.log(url)
                            if (this.state.urls.length == 1) {
                                let obj = {
                                    userid: sessionStorage.getItem('userid'),
                                    filepath: url[0].substring(url[0].indexOf('Assets/')),
                                    description: 'testing'
                                }
                                this.props.openHistoryFile(obj)
                                console.log(obj)
                            } else {
                                this.setState({ isComponentRender: true })
                            }
                        }
                    }, 1000);

                }).catch(err => console.error(err))
            }


        }
    }
    render() {
        return (
            <>
                {
                    this.state.isComponentRender ?
                        <Modal fullscreen={true} id="pptModal" animation={true} show={true} onHide={() => this.props.onCloseModal()}>
                            <Modal.Body>
                                <div className="navbar" style={{ backgroundColor: "#fff" }}>
                                    <div className="leftdiv">
                                        <Tooltip title="Go back">
                                            <IconButton onClick={() => this.props.onCloseModal()}>
                                                <ArrowBackIcon />
                                            </IconButton>
                                        </Tooltip>
                                        {/* <span style={{ padding: "0px 10px", fontSize: "18px", fontWeight: "600" }}>{this.state.header}</span> */}
                                        {/* {
                                            this.state.urls.length > 1 &&
                                            < Tooltip title="Utilities">
                                                <IconButton onClick={() => this.setState({ isMagicWondOpen: true })}>
                                                    <AutoFixHighIcon />
                                                </IconButton>
                                            </Tooltip>
                                        } */}

                                    </div>
                                </div>
                                <div className="ppt_viewer_box">
                                    <div unselectable='on' className="footer" id="ppt_footer"></div>
                                    {
                                        this.state.urls.length > 1 &&
                                        this.state.urls.map((url, index) => {
                                            let d = this.state.urls.length
                                            return (
                                                <iframe key={index} src={url} width='100%' style={{ height: `calc(100% / ${d})` }}></iframe>
                                            )
                                        })
                                        // :
                                        // <Modal fullscreen={true} id="documentmodal" animation={true} show={this.state.isOpenSearchModal} onHide={() => this.setState({ isOpenSearchModal: false })}>
                                        //     <Modal.Body>
                                        //         <Searched
                                        //             searchApi={this.props.searchApi}
                                        //             rootUrl={this.props.rootUrl}
                                        //             goBack={() => this.setState({ isOpenSearchModal: false, isReferedByHistory: false, isReferedByHsn: false })}
                                        //             userid={this.props.userid}
                                        //             isReferedByHsn={this.state.isReferedByHsn}
                                        //             hsnSearchWord={this.state.hsnSearchWord}
                                        //             hsnPath={this.state.hsnPath}
                                        //             isReferedByHistory={this.state.isReferedByHistory}
                                        //             historyFileData={this.state.historyFileData}
                                        //         />
                                        //     </Modal.Body>
                                        // </Modal>
                                        // <iframe src={this.state.urls[0]} width='100%' style={{ height: "100%" }}></iframe>
                                    }
                                    {
                                        this.state.isMagicWondOpen &&
                                        <Magicwond
                                            pageData={this.state.pagedata}
                                            onCloseModal={() => this.setState({ isMagicWondOpen: false })}
                                            userid={this.props.userid}
                                            filepath={this.state.urls[0]}
                                            fetchPageData={() => this.setState({ isMagicWondOpen: false }, () => this.getpagedata(this.state.urls[0]))}
                                        />
                                        // <Magicwond
                                        //     pageData={this.state.pageData}
                                        //     onCloseModal={() => this.setState({ isMagicWondOpen: false })}
                                        // />
                                    }
                                </div>
                            </Modal.Body>
                        </Modal>
                        :
                        <Modal centered size="sm" id="pptModal" animation={true} show={true}>
                            <Modal.Body>
                                <div className="timer" style={{ display: 'flex', justifyContent: 'center', alignItems: "center", flexDirection: "column", minHeight: "15vh" }}>
                                    <Spinner animation="border" variant="dark" />
                                </div>
                            </Modal.Body>
                        </Modal>
                }
            </>
        )
    }
}
