import React, { Component } from 'react'
import { Modal,Spinner } from 'react-bootstrap'
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import './notes.css'
import { Input } from '../../usefulcomps/HexInput/InputBox'
import { Textbox } from '../../usefulcomps/HexInput/Textbox'
import Hexapi from '../../usefulcomps/HexAPI/Hexapi';
import Swal from 'sweetalert2'
export default class Notes extends Component {
    constructor(props) {
        super(props)
        this.state = {
            notes: '',
            isOptionModalOpen: false,
            isComponentRender: false
        }
    }
    onSearch(e) {
        this.setState({ isOptionModalOpen: true })
    }
    componentDidMount() {
        this.setState({ notes:this.props.oldNote,isComponentRender: true })
        console.log(this.props.filedetails)
    }
    saveNotes() {
        // alert('saved')
        if (this.state.notes != "") {
            this.setState({ isComponentRender: false })
            let obj = {
                query: `[dbo].[Lawcrux_Web_Proc_Note_Submit] @UserID='{0}', @filepath='{1}', @Note='{2}'`,
                queryArr: [`${this.props.filedetails.userid}`, `${this.props.filedetails.filepath}`, `${this.state.notes}`]
            }
            Hexapi(obj).then(resp => {
                console.log(resp)
                Swal.fire({
                    confirmButtonColor: '#28b6f6',
                    html: resp[''][0].msg,
                }).then(() => {
                    if (resp[''][0].command == 1) {
                        this.props.onSaveNote()
                    }
                })
            }).then(() => this.setState({ isComponentRender: true })).catch(err => console.error(err))
        }
    }
    render() {
        return (
            <>

                <Modal id={'search_options_modal'} size="lg" centered style={{ backgroundColor: 'rgba(0,0,0,0.6)' }} show={true} onHide={() => this.props.onCloseModal()} keyboard={false} backdrop='static'>
                    <Modal.Header>
                        <div className="header">
                            <span>Notes</span>
                            <IconButton onClick={() => this.props.onCloseModal()}>
                                <CloseIcon />
                            </IconButton>
                        </div>
                    </Modal.Header>
                    <Modal.Body style={{ backgroundColor: "white" }}>
                        {
                            this.state.isComponentRender ?
                                <div className="notes_main_box">
                                    <label htmlFor="notes" style={{ fontWeight: '600', fontSize: '16px' }}>Your Notes <span style={{ color: 'red', fontSize: "18px" }}>*</span> </label>
                                    <Textbox
                                        name="note_description"
                                        id="notes"
                                        placeholder='Write your note here...'
                                        required={true}
                                        value={this.state.notes}
                                        onChange={(e) => {
                                            this.setState({ notes: e.target.value })
                                        }}
                                    />
                                </div>
                                :
                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: '100%', height: "40vh" }}>
                                    <Spinner animation="border" variant="dark" />
                                </div>
                        }
                        <div className="footer_notes_div">
                            <span onClick={() => this.saveNotes()}>Save</span>
                        </div>
                    </Modal.Body>
                </Modal>
            </>
        )
    }
}
