
import React, { useEffect, useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css'
// import 'bootstrap/dist/css/bootstrap.min.css';
import Home from "./components/Home";
import './App.css'
import 'bootstrap/dist/js/bootstrap.bundle';
import 'react-confirm-alert/src/react-confirm-alert.css'
import ComboBox from "./usefulcomps/HexInput/Combobox";
import Select from 'react-select'
import Hexapi from "./usefulcomps/HexAPI/HexapiV3";
import Demo from "./components/Demo";
import Homescreen from "./components/Homescreen";
import Navbar from '../src/components/Menuitems/Navbar'

// import Checklist from "./Checklist";
// import Classes from ''
// import { TimeFormatterV2 } from "./usefulcomps/TimeFormatter";
// import ReactGoogleSlides from "react-google-slides";
// import PptxToHtml from 'pptxgenjs'

// var url = encodeURIComponent('http://lawcruxtest.hexbis.com/Web/new.ppsx');
sessionStorage.setItem("url", 'https://web.lawcrux.com/newversion/web/')//uncommentted
// sessionStorage.setItem("userid", '1')
// sessionStorage.setItem("console", 'true')
// sessionStorage.setItem("login",'true')
// sessionStorage.setItem("searchapi", 'searchnew2.php');
sessionStorage.setItem('Apipathurl', 'https://web.lawcrux.com/')//uncommentted
// sessionStorage.setItem('paramsearchurl', 'filesearch.php');
// sessionStorage.setItem('combinesearchurl', 'combinesearch.php');
// sessionStorage.setItem('hsnsearchurl', 'hsnsearch.php')
// sessionStorage.setItem('refinedsearchurl', 'refinedsearch.php')
// sessionStorage.setItem('gettitleurl', 'gettitles.php')
// sessionStorage.setItem('username', "LCX_test")
// localStorage.setItem('auth_lawcrux_id','0zhc7Z5Lcn')

function App() {


  return (
    <div className="App scrollbar-overflow">
      {/* <Home /> */}
      <Homescreen />
      {/* <Navbar/> */}
      {/* <Sample/> */}
      {/* <Checklist/> */}
      {/* <Combox/> */}
    </div>
    // <Demo/>
  );
}

export default App;
