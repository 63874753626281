import React, { Component } from 'react'
import Table from 'react-bootstrap/Table'
import './TableStyle.css'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import Tooltip from '@mui/material/Tooltip';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import PaginationBar from './PaginationBar'
import ReactVisibilitySensor from 'react-visibility-sensor';
import Hexapi from '../HexAPI/Hexapi';
import { Modal } from 'react-bootstrap'
import { Spinner } from 'react-bootstrap'
import Thousandthree from '../../Checklist';
import Thousandtwo from "../../Thousandtwo";
import ContentFrame from '../../components/subcomps/ContentFrame';
import HomeIcon from '@mui/icons-material/Home';
import { FormatColorReset } from '@mui/icons-material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import Searched from '../../components/subcomps/Searched';
import Dictionary from '../../components/subcomps/Dictionary';
import FindInPageSharpIcon from '@mui/icons-material/FindInPageSharp';


// import TimeFormatter from '../../../../usefulcomps/TimeFormatter';

var oldData = []
export default class Sgstcomparitive extends Component {
    constructor() {
        super()
        this.searchInputRef = React.createRef();

        this.state = {
            isListFound: false, search: '', isLastList: false, titlesLength: 0, datatab: [],
            typing: false, currentOffset: 0, visible: false, isNavbar: true, endIndex: 100,
            typingTimeout: 0, totalData: [], newtable: [], pageIndex: 0, PAGE_SIZE: 100, heading: "SGST Comparitive Chart",
            rowData: [], isDataFound: false, currentPaginationPage: 0, showPaginationRows: 0, visible: false,
            isShowSearchBox: true, searchValue: '', selectedColumns: [], updatedColumns: [], allcheckedfilters: "", filtercount: "",
            visible: false, maxres: 1000, lastcode: 0, code: null, subject: false, untickedValues: [], filternamecounts: [],
            field: false, searched: '', year: false, all: false, counts: 0, subject1: false, val: '', OpencustomFrame: false,
            filterData: [], filtername: '', isfilterset: false, isloadcomplete: false, defaultValue: [], columns: [],
            mainfolder: "", subfolder: "", UpdatedfilterStates: "", untickedvalues: [], filterstates: [],
            thousanddata: [], activeElement: '', OpenRowmodal: false, replaceTextdata: [], replacedLinkdata: [],
            rowIndexno: '', isOpenSearchModal: false, isOpenDictModal: false, isReferedByHsn: false, hsnSearchWord: '', hsnPath: '',
            isReferedByHistory: false, historyFileData: [],
        }
    }




    componentDidMount() {
        document.addEventListener('keydown', this.handleKeyDown);

        var vari = [];
        var items = [];
        let obj1 = {
            // 'query': `[dbo].[Lawcrux_Web_Proc_InteractiveIndex_GetData]@RecID='{0}'`,
            'query': `[dbo].[Lawcrux_Web_Proc_SGSTComparitiveChart_GetData]@UserID='{0}'`,
            'queryArr': ['1']
        }
        console.log(obj1)

        Hexapi(obj1).then(resp => {
            console.log(resp[""]);
            let vari = Object.keys(resp[""][0]);
            console.log(vari)
            // this.state.columns=vari;
            vari = vari.filter(column => column !== "record_no1" && column !== 'xno');
            // this.state.selectedColumns = vari;
            // this.state.selectedColumns = resp[""];
            this.setState({ selectedColumns: resp[""] })
            this.setState({ oldData: vari })
            this.state.clearcolumn = vari;
            items = vari.slice(2);
            this.state.columns = items;

            this.setState({ datatab: resp[""] })
            this.setState({ rowData: resp[""], isDataFound: true })

            oldData = resp[""];
            this.setState({ newtable: resp[""] });
            let cd = resp[""][resp[""].length - 1].code;
            this.setState({ lastcode: cd })
            console.log(cd);
            console.log(items);
            var obj = { values: {}, def: {}, oldvalues: {} }
            var Filters = [
                {
                    "filtername": 'States',
                    "filtertype": 1003,
                    "values": `${items}`,
                    "defval": `${items}`

                }
            ]
            console.log(this.state.selectedColumns);

            this.setState({ originalfilterData: Filters, filterData: Filters })
            // setoriginalfilterData(Filters);
            console.log(Filters)
            for (var i = 0; i < Filters.length; i++) {
                // obj.def[Filters[i].FilterName] = Filters[i].DefVal
                // obj.values[Filters[i].FilterName] = Filters[i].Values
                // obj.oldValues[Filters[i].FilterName] = Filters[i].DefVal
                obj.def[Filters[i].filtername] = Filters[i].defval
                obj.values[Filters[i].filtername] = Filters[i].values
                obj.oldvalues[Filters[i].filtername] = Filters[i].defval
            }
            console.log(this.state.filterData)
            console.log(obj)

            this.state.defaultValue.push(obj);
            console.log(this.state.defaultValue[0].values)
        });



    }

    applyFilters() {
        // this.storeSearch(this.state.untickedValues);
        console.log(this.state.untickedValues);
        console.log(this.state.defaultValue[0].def)
        // let values = 'sgst,subject,andhra pradesh,' + this.state.defaultValue[0].def.States;
        let values = 'sgst,subject,' + this.state.UpdatedfilterStates;
        values = values.split(",");
        console.log(values)

        this.setState({ filtercount: this.state.filternamecounts.length })
        console.log(this.state.filternamecounts.length)

        this.setState({ filterstates: values })
        console.log(this.state.filterstates)
        this.setState({ isOpenModal: false, isfilterset: true });
    }


    handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            this.storeSearch();
        } else if (event.key === 'Backspace' && this.state.searchValue.length == 1) {
            this.clearSearch();
        }
    };

    componentWillUnmount() {
        document.removeEventListener('keydown', this.handleKeyDown);
    }

    clearSearch(e) {
        this.setState({ selectedColumns: oldData, rowData: oldData, isDataFound: true, isShowSearchBox: true, searchValue: '' })
    }

    setDate(date) {
        // if (date != undefined) {
        //     // return date.split(' ')[0]
        //     console.log(date)
        //     var transformdate = date.split(".")[0];
        //     console.log(transformdate)
        //     //   console.log(date);
        //     var exitdate = TimeFormatter(transformdate);
        //     console.log(exitdate);
        //     var visitorinfo = this.state.visitorinfo
        //     //   console.log(visitorinfo)
        //     // console.log(this.state.visitorinfo)
        //     var newReverseDate =
        //         exitdate.ShiftTime +
        //         "," +
        //         exitdate.userSplitedDate[2] +
        //         "-" +
        //         exitdate.ShiftMonth +
        //         "-" +
        //         exitdate.CurYear.toString().slice(-2);
        //     // console.log(newReverseDate);

        //     return newReverseDate;
        // }
    }


    checkfiltervalues() {
        var filtercount = 0;
        for (var i = 0; i < this.state.originalfilterData.length; i++) {
            var currentfilter = this.state.defaultValue[0].def[this.state.originalfilterData[i].filtername];
            if (this.state.originalfilterData[i].filtername == 'Columns') {
                if (currentfilter.includes('Code') && currentfilter.includes('Icx') && currentfilter.includes('order') && currentfilter.includes('party1')) {

                } else {
                    filtercount = filtercount + 1
                }
            }
        }
        this.setState({ filtercount: filtercount })
        if (filtercount == 0) {
            return true
        } else {
            return false
        }
    }



    getHeader(data) {
        // const fixedColumns = Object.keys(data[0]).slice(0, 3);
        // const movableColumns = Object.keys(data[0]).slice(3);

        // const columns = this.state.selectedColumns.filter(column => column !== "record_no1")
        // console.log(columns)

        let columns;
        // console.log(this.state.filterstates)
        if (this.state.filterstates.length > 0) {
            columns = this.state.filterstates;
            // this.setState({filterstates:this.state.filterstates})
        } else {
            columns = Object.keys(this.state.selectedColumns[0]).filter(column => column !== "record_no1" && column !== 'xno')
        }

        // console.log(columns)


        const setColumnFix = (currentIndex) => {
            if (currentIndex == 0) {
                return { position: "sticky", minWidth: '150px', maxWidth: "150px", left: currentIndex * 150 + "px", background: "black", boxShadow: currentIndex === 1 ? " inset -4px 0px 0px -1px #ABABAB" : ' inset 0px 0px 0px -1px #ABABAB' };
            }
            else if (currentIndex == 1) {
                return { position: "sticky", minWidth: '400px', maxWidth: "400px", left: currentIndex * 150 + "px", background: "black", boxShadow: currentIndex === 1 ? " inset -4px 0px 0px -1px #ABABAB" : ' inset 0px 0px 0px -1px #ABABAB' };
            }

            else {
                return { minWidth: '150px', maxWidth: '300px' };
            }
        };
        // return Object.keys(data[0]).filter(column => this.state.selectedColumns.includes(column)).map((column, index) => {
        return columns.map((column, index) => {
            // return Object.keys(data[0]).map((column, index) => {
            if (column == "sgst") {
                column = "CGST";
            }
            return (
                <th key={index} style={setColumnFix(index)} >{column.toLocaleUpperCase()}</th>
            )
        })


    }

    onsubjectclick(file) {
        let link = file[0];
        if (link.includes("data5t")) {
            let folder = link.split("/");
            this.setState({ mainfolder: folder[1] })
            this.setState({ subfolder: folder[2] })
            if (folder[2].includes(".htm")) {
                this.setState({ targetfile: "Assets/Data5t/" + folder[1] + "/" + folder[2] })
            } else if (folder[3].includes(".htm")) {
                this.setState({ targetfile: "Assets/Data5t/" + folder[1] + "/" + folder[2] + "/" + folder[3] })
            }
            else if (folder[4].includes(".htm")) {
                this.setState({ targetfile: "Assets/Data5t/" + folder[1] + "/" + folder[2] + "/" + folder[3] + "/" + folder[4] })
            }
            else {
                this.setState({ targetfile: "Assets/Data5t/" + folder[1] + "/" + folder[2] + "/" + folder[3] + "/" + folder[4] + "/" + folder[5] })
            }
            // this.setState({ targetfile: "Assets/Data5t/" + folder[1] + "/" + folder[2] + "/" + folder[3] })
            this.setState({ OpencustomFrame: true });
        }
    }

    onlinkclick(file) {

        console.log(file);
        
        this.setState({ isDataFound: false });
        
        // console.log(file['replacedLink'])
        
        let link;
        
        if (typeof file == "string") {
        
        link = file;
        
        } else {
        
        link = file['replacedLink'][0];
        
        }
        
        console.log(link);
        
        if (link.includes("data5t")) {
        
        let folder = link.split("/");
        
        this.setState({ mainfolder: folder[1] })
        
        this.setState({ subfolder: folder[2] })
        
        if (folder[2].includes(".htm")) {
        
        this.setState({ targetfile: "Assets/Data5t/" + folder[1] + "/" + folder[2] })
        
        } else if (folder[3].includes(".htm")) {
        
        this.setState({ targetfile: "Assets/Data5t/" + folder[1] + "/" + folder[2] + "/" + folder[3] })
        
        }
        
        else if (folder[4].includes(".htm")) {
        
        this.setState({ targetfile: "Assets/Data5t/" + folder[1] + "/" + folder[2] + "/" + folder[3] + "/" + folder[4] })
        
        } else {
        
        this.setState({ targetfile: "Assets/Data5t/" + folder[1] + "/" + folder[2] + "/" + folder[3] + "/" + folder[4] + "/" + folder[5] })
        
        }
        
        // this.setState({ targetfile: "Assets/Data5t/" + folder[1] + "/" + folder[2] + "/" + folder[3] })
        
        this.setState({ OpencustomFrame: true });
        
        }
        
        this.setState({ isDataFound: true });
    }

    getRowsData(tabledata) {
        // console.log(this.state.defaultValue);

        // console.log(this.state.defaultValue[0]);

        // console.log(this.state.defaultValue[0].values[this.state.filterData.FilterName])
        let columns;
        if (this.state.filterstates.length > 0) {
            columns = this.state.filterstates;
            // this.setState({filterstates:""})
        } else {
            columns = Object.keys(this.state.selectedColumns[0]).filter(column => column !== "record_no1" && column !== 'xno')
        }
        // console.log(columns)

        const setColumnFix = (currentIndex) => {
            if (currentIndex == 0) {
                // return { position: "sticky", left: 0, background: "#fff", boxShadow: currentIndex === 2 ? " inset -4px 0px 0px -1px #ABABAB" : ' inset 0px 0px 0px -1px #ABABAB' };
                return { position: "sticky", minWidth: '150px', maxWidth: "150px", left: currentIndex * 150 + "px", background: "#fff", boxShadow: currentIndex === 1 ? " inset -4px 0px 0px -1px #ABABAB" : ' inset 0px 0px 0px -1px #ABABAB' };
            }
            else if (currentIndex == 1) {
                return { position: "sticky", minWidth: '400px', maxWidth: "400px", left: currentIndex * 150 + "px", background: "#fff", boxShadow: currentIndex === 1 ? " inset -4px 0px 0px -1px #ABABAB" : ' inset 0px 0px 0px -1px #ABABAB' };
            }
            else {
                return { minWidth: '150px', maxWidth: '300px' }
            }
        };



        const removeData5tToHtm = (value) => {
            if (typeof value === 'string' && value.includes("data5t")) {
                const parts = value.split(/(data5t.*?htm)/g);
                const updatedValue = parts.map((part, index) => {
                    if (index % 2 !== 1 && part !== "") {
                        const linkText = part.replace(/u/g, '/').replace(/z/g, '_');
                        // console.log(linkText)
                        return linkText;
                    } else {
                        return '';
                    }
                })
                    .filter((item) => item !== '');
                // .join('');
                // console.log(updatedValue)
                return updatedValue;
            }
            else {
                if (typeof value == 'string' && (value == "" || value == "-")) {
                    return []
                }
            }
            // console.log(value)
            return [value];
        };

        const removeData5tToLink = (value) => {
            if (typeof value === 'string' && value.includes("data5t")) {
                const parts = value.split(/(data5t.*?htm)/g);
                // console.log(parts);
                var updatedValue = parts.map((part, index) => {
                    if (index % 2 !== 1 && part !== "") {
                        // console.log(parts[index+1])

                        let linktext='';
                        if(parts[index+1]){
                            let linklength=parts[index+1].length;
                            linktext= parts[index+1].substring(0,6)+"/"+parts[index+1].substring(7,linklength);
                            linktext=linktext.substring(0,9)+"/"+linktext.substring(10,linklength);
                            linktext=linktext.substring(0,16)+"/"+linktext.substring(17,19)+ linktext.substring(19,linklength).replace(/z/g, '_');
                            console.log(parts[index+1])
                        }
                        // console.log(linktext)
                        return linktext;

                        // const hrefText = parts[index + 1] ? parts[index + 1].replace(/u/g, '/').replace(/z/g, '_') : '';
                        // console.log(hrefText)
                        // return hrefText;
                    } else {
                        return '';
                    }
                })
                    .filter((item) => item !== '');
                // .join('');
                // console.log(updatedValue)
                return updatedValue;

            } else {
                if (typeof value == 'string' && (value == "" || value == "-")) {
                    return []
                }
            }
            // console.log(value)
            return [value];
        };
        // return tabledata.slice(0, this.state.endIndex).map((row, rowIndex) => (
        return tabledata.map((row, rowIndex) => (
            <tr key={rowIndex} onClick={() => {this.getid(rowIndex) }}>
                {columns.map((column, colIndex) => {
                    if (Object.keys(this.state.selectedColumns[0]).includes(column)) {
                        const replacedText = removeData5tToHtm(row[column]);
                        const replacedLink = removeData5tToLink(row[column]);
                        if (colIndex <= 2) {
                            if (column == "subject") {
                                let text = row.subject;
                                // text = text.slice(0, 50);
                                return (
                                    <td key={colIndex} style={setColumnFix(colIndex)}>
                                        <Tooltip title={row.subject}>
                                            <div>{
                                                <span onClick={() => { this.onsubjectclick(removeData5tToLink(row["sgst"])); this.getid(colIndex) }} >{text}</span>
                                            }</div>
                                        </Tooltip>
                                    </td>
                                );

                            }

                            else if (column == "sgst") {
                                let text="";
                                if (replacedText[0] != "" && replacedText[0] != undefined && replacedText[0] != null) {
                                    text = replacedText[0]
                                    if(text.includes('Dt')){
                                        text = text.split('Dt');
                                        if(text[0].includes(",")){
                                            const data=text[0].split(",");
                                            text[0]=data;
                                        }
                                        
                                    }else{
                                        text = text.split('dt');

                                    }
                                    // console.log(text[0])
                                }
                                return (
                                    <td key={colIndex} style={setColumnFix(colIndex)}>
                                        <span key={colIndex} onClick={() => { this.showModaldata(replacedLink, replacedText, rowIndex) }}>{
                                            replacedText[0] != "" && replacedText[0] != undefined && replacedText[0] != null ? text[0] : replacedText[0]
                                          
                                        }</span>
                                    </td>
                                );
                            }
                            else {

                                return (
                                    <td key={colIndex} style={setColumnFix(colIndex)}>
                                        <span key={colIndex} onClick={() => { this.showModaldata(replacedLink, replacedText, rowIndex) }}>{
                                            replacedText[0] != "" && replacedText[0] != undefined && replacedText[0] != null ? replacedText[0] : replacedText[0]
                                            // replacedText != null && replacedText.map((text, index) => {
                                            //     return (
                                            //         <>
                                            //             <span key={index} onClick={() => { this.onlinkclick(replacedLink[index]); this.getid(colIndex) }} >{text}</span>
                                            //             <br />
                                            //         </>
                                            //     )
                                            // })

                                        }</span>
                                    </td>
                                );
                            }
                        } else {
                            return (
                                <td key={colIndex} style={{ maxWidth: "150px" }}>
                                    {/* <div onClick={() => this.onlinkclick({ replacedLink })} dangerouslySetInnerHTML={{ __html: replacedText }} /> */}
                                    {/* <div>{
                                        replacedText != null && replacedText.map((text, index) => {
                                            return (
                                                <>
                                                    <span key={index} onClick={() => { this.onlinkclick(replacedLink[index]); this.getid(rowIndex) }} >{text}</span>
                                                    <br />
                                                </>
                                            )
                                        })

                                    }</div> */}
                                    <span key={colIndex} onClick={() => { this.showModaldata(replacedLink, replacedText, rowIndex) }}>{
                                        replacedText[0] != "" && replacedText[0] != " " && replacedText[0] != undefined && replacedText[0] != null ? replacedText[0] + "..." : replacedText[0]}</span>
                                </td>
                            );
                        }
                    } else {
                        return null;
                    }
                })}
            </tr>
        ));
    }

    showModaldata = (replacedLink, replacedText, rowIndex) => {
        console.log(rowIndex)
        console.log(replacedText.length);

        if (replacedText.length == 1) {
            this.onlinkclick(replacedLink[0])
        }

        if (replacedText != null && replacedText.length > 1) {
            this.setState({ OpenRowmodal: true });
            console.log(this.state.OpenRowmodal)
            this.setState({
                replaceTextdata: replacedText,
                replacedLinkdata: replacedLink,
                rowIndexno: rowIndex
            })

            // return (
            //     <Modal size="lg" style={{ marginTop: '3%' }} show={true} >
            //         <Modal.Body style={{ display: 'flex', justifyContent: 'space-around' }}>
            //             {/* {replacedText.map((text, index) => {
            //                 return (
            //                     <React.Fragment key={index}>
            //                         <span key={index} onClick={() => { this.onlinkclick(replacedLink[index]); this.getid(rowIndex) }} >{text}</span>
            //                         <br />
            //                     </React.Fragment>
            //                 )
            //             })} */}
            //         </Modal.Body>
            //     </Modal>
            // )
        }

    }


    getid = (i) => {
        let foundIndex = null;
        this.state.rowData.map((item, index) => {
            if (index < i - 2) {
                foundIndex = index;
                console.log(foundIndex)
            }
        })
        if (foundIndex != null) {
            // console.log(foundIndex);
            this.setState({ isDataFound: true, activeElement: foundIndex })
        }
    }

    scollback = () => {
        const element = document.getElementById(this.state.activeElement);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' })
        }
        this.setState({ activeElement: ''});
    }



    storeSearch(e) {
        console.log(oldData)
        let value = this.state.searchValue;
        console.log(value);
        let applyFilter = Object.keys(this.state.selectedColumns[0]).filter((column) => column === "sgst" || column === "subject");
        console.log(applyFilter)


        if (value === '') {
            if (oldData.length > 0) {
                console.log(oldData);
                this.setState({ rowData: oldData, isDataFound: true });
            } else {
                this.setState({ isDataFound: false });
            }
        } else {
            this.setState({ isDataFound: false });
            let filteredData = oldData.filter((row) => {
                if (Array.isArray(applyFilter)) {
                    let StrArr = [];
                    for (let i = 0; i < applyFilter.length; i++) {
                        StrArr.push(row[`${applyFilter[i]}`]);
                    }
                    return StrArr.join(' ').toString().toLowerCase().includes(value.toLowerCase());
                } else {
                    return (oldData[`${applyFilter[0]}`]).toString().toLowerCase().includes(value.toLowerCase());
                }
            });

            console.log(filteredData);
            console.log(filteredData.length);

            if (filteredData.length > 0) {
                this.setState({
                    selectedColumns: filteredData,
                    rowData: filteredData,
                    isDataFound: true,
                    isOpenModal: false,
                    currentPaginationPage: 0
                });
            } else {
                this.setState({ isDataFound: true, rowData: filteredData, })
            }

        }
    }

    openFilterModal = (e) => {
        this.setState({ isOpenModal: true })
        if (this.state.originalfilterData != null) {
            console.log(this.state.originalfilterData)
            this.state.originalfilterData.map((data, index) => {
                if (index == 0) {
                    console.log(data);
                    this.setState({ filterData: data, filtername: data.filtername })
                }
            })
        }
    }

    clearFilters = () => {
        if (this.state.defaultValue[0].def == this.state.defaultValue[0].oldvalues) {
            console.log(true)
        } else {
            console.log(false)
        }
        // this.setState({isloadcomplete:false,isfilterset:false})
        this.setState({ isloadcomplete: false, isfilterset: false })
        // console.log(this.state.originalfilterData.length)
        for (var i = 0; i < this.state.originalfilterData.length; i++) {
            this.state.defaultValue[0].def[this.state.originalfilterData[i].filtername] = this.state.defaultValue[0].oldvalues[this.state.originalfilterData[i].filtername]
        }
        this.setState({ isOpenModal: false })
        // this.setState({ selectedColumns: this.state.clearcolumn })
        this.state.filterstates = this.state.clearcolumn;
    }


    setFilter = (data) => {
        console.log(data.filtername)
        this.setState({ filterData: data, filtername: data.filtername })
    }

    handleforward = () => {
        const value = false;
        this.props.callback(value)
    }

    updateDefaultvalues = (data) => {
        // if(this.state.isOpenModal)
        console.log(this.state.defaultValue[0].oldValues)

        let filtercounts = this.state.filtername;

        let found = false;
        if (this.state.filternamecounts.length == 0) {
            let names = [];
            names.push(filtercounts)
            setTimeout(() => {
                this.setState({ filternamecounts: names })
            }, 100);
        }
        for (let i = 0; i < this.state.filternamecounts.length; i++) {
            if (filtercounts.toString() == this.state.filternamecounts[i]) {
                found = true;
                break;
            } else {
                found = false;
            }
        }
        console.log(found);

        if (found == false) {
            let names = this.state.filternamecounts;
            names.push(filtercounts)
            this.setState({ filternamecounts: names })
        }

        console.log(this.state.filtername, data)
        // defaultValue[0].def[this.state.filtername] = data
        this.setState({ counts: this.state.counts + 1 })
        this.state.defaultValue[0].def[this.state.filtername] = data
        console.log(this.state.defaultValue[0].def);
        console.log(this.state.defaultValue[0].values)
        // this.setState({ UpdatedfilterStates: this.state.defaultValue[0].def.States })
        // let defstates = this.state.defaultValue[0].def.States.split(",");
        // let valuestates = this.state.defaultValue[0].values.States.split(",");
        // for (let i = 0; i < valuestates.length; i++) {
        //     if (defstates[i] != valuestates[i]) {
        //         this.setState({ UpdatedfilterStates: this.state.defaultValue[0].def.States })
        //         break;
        //     } else {
        //         this.setState({ UpdatedfilterStates: this.state.defaultValue[0].values.States })
        //         console.log(this.state.UpdatedfilterStates)
        //     }
        // }
        // ==================>Function to get unticked value

        var items = [];
        for (let filtername in this.state.defaultValue[0].values) {
            let values = this.state.defaultValue[0].values[filtername].split(',');
            let defVals = this.state.defaultValue[0].def[filtername].split(',');
            let untickedSubItems = [];
            for (let i = 0; i < values.length; i++) {
                if (!defVals.includes(values[i])) {
                    untickedSubItems.push(values[i]);
                }
            }
            if (untickedSubItems.length > 0) {
                let val = Object.values(untickedSubItems).toString();
                items.push(val);
                // console.log(Object.values(untickedSubItems).toString())
                // this.setState({untickedvalues:Object.values(untickedSubItems).toString()})
                // this.state.untickedvalues = untickedSubItems.toString();
            }
        }
        console.log(items)
        if (items.length > 0) {
            this.setState({ UpdatedfilterStates: this.state.defaultValue[0].def.States })
        } else {
            this.setState({ UpdatedfilterStates: this.state.defaultValue[0].values.States })

        }
        // this.setState({ untickedvalues: Object.values(items).toString() })
        // console.log('Unticked values:', this.state.untickedvalues);
    }

    render() {
        // console.log(this.state.rowData);
        // console.log(this.props.pagination)
        // console.log(this.state.isDataFound)
        // console.log(this.state.rowData);
        // console.log(this.state.newtable);


        return (
            <>
                {
                    this.state.OpencustomFrame ?
                        <ContentFrame
                            // style={{backgroundImage:'',opacity:'0.2',backgroundRepeat:' no-repeat', backgroundPosition: 'center center',}}
                            goBack={() => { this.setState({ OpencustomFrame: false}); this.scollback(); }}
                            filepath={this.state.targetfile}
                            searchApi={sessionStorage.getItem('searchapi')}
                            rootUrl={sessionStorage.getItem('url')}
                            userid={sessionStorage.getItem('userid')}
                            MainFolder={this.state.mainfolder}
                            SubFolder={this.state.subfolder}
                            goHome={() =>{ this.setState({ OpencustomFrame: false });  this.handleforward() }}
                        /> :

                        null
                }

                <div style={{ position: 'relative', height: "inherit" }}>
                    {
                        this.state.isNavbar &&
                        <div className="_tablenavbar">
                            <div className="_left" style={{ display: 'flex', alignItems: 'center' }}>
                                <div className="col-1">
                                    <Tooltip title="Go Home"
                                        onClick={() => this.handleforward()}>
                                        <IconButton >
                                            <HomeIcon style={{ color: "black" }} />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                                <span style={{ textTransform: "Capitalize", marginLeft: '10%', whiteSpace: 'nowrap' }}>
                                    {this.state.heading}
                                </span>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'space-between', width: '30%' }}>
                                <div className="col-1" >
                                    <Tooltip title="Go back">
                                        <IconButton onClick={() => this.handleforward()}>
                                            <ArrowBackIcon style={{ color: "black" }} />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                                <div className="col-1" >
                                    <Tooltip title="Go Forward">
                                        <IconButton onClick={() => { this.setState({ OpencustomFrame: true }) }}>
                                            <ArrowForwardIcon style={{ color: "black" }} />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                                <div className="col-1" >
                                    <Tooltip title="Search keyword">
                                        <IconButton onClick={() => this.setState({ isOpenSearchModal: true })}>
                                            <SearchIcon />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                                <div className="col-1" >
                                    <Tooltip title="Dictionary">
                                        <IconButton onClick={() => this.setState({ isOpenDictModal: true })}>
                                            <LibraryBooksIcon />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                            </div>
                            <div className="_right" style={{ display: 'flex', justifyContent: 'center' }}>
                                <input type="text" ref={this.searchInputRef} id="searchBarFocus" autoFocus={true} value={this.state.searchValue}
                                    style={{ width: this.state.isShowSearchBox ? "250px" : '0px', padding: this.state.isShowSearchBox ? "6px 12px" : "6px 0px", marginLeft: this.state.isShowSearchBox ? "10px" : '0px' }}
                                    className="editable" placeholder='Search...' onChange={(e) => this.setState({ searchValue: e.target.value })} />
                                {this.state.searchValue.length > 0 ?
                                    <Tooltip title="Close">
                                        <IconButton style={{ color: 'black' }} onClick={() => this.clearSearch()}>
                                            <CloseIcon />
                                        </IconButton>
                                    </Tooltip> : <></>}
                                {
                                    this.state.isShowSearchBox ?
                                        <>
                                            <Tooltip title="Search">
                                                <IconButton style={{ color: 'black' }} onClick={(e) => this.storeSearch(e)}>
                                                    <SearchIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </>


                                        :
                                        <Tooltip title="Open Search">
                                            <IconButton style={{ color: 'black' }} onClick={() => this.setState({ isShowSearchBox: true }, () => {
                                                if (document.getElementById('searchBarFocus')) {
                                                    document.getElementById('searchBarFocus').focus()
                                                }
                                            })}>
                                                <SearchIcon />
                                            </IconButton>
                                        </Tooltip>

                                }
                                {/* {
                                            this.state.isShowSearchBox ?
                                                <Tooltip title="Close Search">
                                                    <IconButton style={{ color: '#fff' }} onClick={() => this.clearSearch()}>
                                                        <CloseIcon />
                                                    </IconButton>
                                                </Tooltip>

                                                :
                                                <Tooltip title="Open Search">
                                                    <IconButton style={{ color: '#fff' }} onClick={() => this.setState({ isShowSearchBox: true }, () => {
                                                        if (document.getElementById('searchBarFocus')) {
                                                            document.getElementById('searchBarFocus').focus()
                                                        }
                                                    })}>
                                                        <SearchIcon />
                                                    </IconButton>
                                                </Tooltip>

                                        } */}
                                <Tooltip title={'Filter'}>
                                    <IconButton style={{ color: 'black' }} onClick={(e) => this.openFilterModal(e)}>
                                        <FilterAltIcon />
                                    </IconButton>
                                </Tooltip>
                                {
                                    this.state.isfilterset ?
                                        <div style={{ backgroundColor: 'red', borderRadius: '50%', height: '20px', width: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                            <span style={{ color: 'white', }}>{this.state.filtercount}</span>
                                        </div>
                                        : ''
                                }
                            </div>
                        </div>
                    }
                    {/* <ReactVisibilitySensor onChange={this.handleVisibilityChange} > */}
                    <Table id='_tableGrid' className={'table-hover'} responsive style={{}}>
                        <thead style={{
                            backgroundColor: "black",
                            color: '#fff'
                        }} >
                            {/* style={this.props.headStyle}} */}
                            <tr>
                                {this.state.isDataFound &&
                                    this.state.rowData.length > 0 && this.getHeader(this.state.rowData)}
                            </tr>
                        </thead>
                        <>

                            {
                                this.state.isDataFound ?
                                    this.state.rowData.length > 0 ?
                                        <tbody >
                                            {
                                                this.getRowsData(this.state.rowData)
                                            }
                                        </tbody>
                                        :
                                        <tbody>
                                            <div style={{ marginTop: '10%', marginLeft: '4%' }}>
                                                <h3>No Data Found!</h3>
                                            </div>
                                        </tbody>
                                    : 
                                    <tbody>
                                        <div style={{ marginTop: '10%', marginLeft: '4%' }}>
                                            <h3>
                                                Loading...<Spinner variant='dark' animation='border' />
                                            </h3>
                                        </div>
                                    </tbody>

                            }
                        </>
                    </Table>
                    {/* </ReactVisibilitySensor> */}
                    {/* {this.state.visible && (
                        <ReactVisibilitySensor onChange={this.handleVisibilityChange} > Loading more ...</ReactVisibilitySensor>
                    )} */}
                    {
                        this.props.pagination &&
                        this.state.isDataFound &&
                        <div className="_tablefooter">

                            <PaginationBar
                                rowsPerPage={this.props.pagination.rowsPerPage}
                                count={this.state.rowData.length}
                                rowsPerPageOptions={this.props.pagination.rowsPerPageOptions}
                                onPageChange={(currentPage) => this.setState({ currentPaginationPage: currentPage })}
                                onRowsChange={(rows) => this.setState({ showPaginationRows: rows, currentPaginationPage: 0 })}
                            />
                        </div>
                    }
                </div >

                {
                    this.state.isOpenDictModal &&
                    <Dictionary
                        rootUrl={sessionStorage.getItem('url')}
                        userid={sessionStorage.getItem('userid')}
                        onCloseModal={() => this.setState({ isOpenDictModal: false })}
                    />
                }

                <Modal fullscreen={true} id="documentmodal" animation={true} show={this.state.isOpenSearchModal} onHide={() => this.setState({ isOpenSearchModal: false })}>
                    <Modal.Body>
                        <Searched
                            searchApi={sessionStorage.getItem("searchapi")}
                            rootUrl={sessionStorage.getItem('url')}
                            goBack={() => this.setState({ isOpenSearchModal: false, isReferedByHistory: false, isReferedByHsn: false })}
                            userid={sessionStorage.getItem('userid')}
                            isReferedByHsn={this.state.isReferedByHsn}
                            hsnSearchWord={this.state.hsnSearchWord}
                            hsnPath={this.state.hsnPath}
                            isReferedByHistory={this.state.isReferedByHistory}
                            historyFileData={this.state.historyFileData}
                            goHome={() => this.setState({ isOpenSearchModal: false })}
                        />
                    </Modal.Body>
                </Modal>
                <Modal size="md" centered style={{ marginTop: '3%' }} show={this.state.OpenRowmodal} onHide={() => this.setState({ OpenRowmodal: false })}>
                    <Modal.Body style={{ display: 'flex', justifyContent: 'space-around', padding: '10px' }}>
                        <div style={{ minHeight: '10vh' }}>
                            {this.state.replaceTextdata.map((text, index) => {
                                let textdata;
                                if (text.includes(";")) {
                                    textdata = text.split(";");
                                    textdata = textdata[1];
                                } else {
                                    textdata = text;
                                }
                                // if (this.state.replaceTextdata[index] != "" && this.state.replaceTextdata[index] != ";") {
                                if ((textdata != " " || textdata != "") && this.state.replacedLinkdata[index] != undefined) {
                                    console.log(textdata);
                                    console.log(this.state.replacedLinkdata[index])
                                    return (
                                        <div style={{ marginTop: '5px' }}>
                                            <span >{index + 1}.</span>
                                            <span key={index} style={{ color: 'blue', }} onClick={() => { this.onlinkclick(this.state.replacedLinkdata[index]); this.getid(this.state.rowIndexno); console.log(textdata) }} >{textdata}</span>
                                            <br />
                                        </div>
                                    )

                                }
                            })}
                        </div>
                    </Modal.Body>
                </Modal >

                <Modal size="lg" centered style={{ backgroundColor: 'rgba(0,0,0,0.6)' }} show={this.state.isOpenModal} keyboard={false} backdrop='static' >{/*  keyboard={false} backdrop='static' */}
                    <Modal.Header>
                        <h3>Filters</h3>
                        <div style={{ display: "flex", justifyContent: "space-between", width: "280px", alignItems: 'center' }}>
                            <div class="btn btn-warning" style={{
                                color: "#fff", backgroundColor: 'red', width: '40%',
                                textAlign: 'center', height: '30px',
                                fontSize: '18px',
                                display: 'flex',
                                justifyContent: 'center'
                            }} onClick={() => this.clearFilters()}>Clear Filters</div>
                            <div id='applyfilter' className="btn btn-warning"
                                style={{
                                    color: "#fff", backgroundColor: 'yellowgreen', width: '40%',
                                    textAlign: 'center', height: '30px',
                                    fontSize: '18px',
                                    display: 'flex',
                                    justifyContent: 'center'
                                }}
                                onClick={() => this.applyFilters()}
                            >Apply Filters</div>
                            <div>
                                <Tooltip title="Close Filter">
                                    <IconButton onClick={() => { this.setState({ isOpenModal: false }); this.state.defaultValue[0].def = this.state.defaultValue[0].def }}>
                                        <CloseIcon style={{ color: 'black' }} />
                                    </IconButton>
                                </Tooltip>
                            </div>
                        </div>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <div className="col-3">
                                <div className='row filternames' >
                                    {
                                        this.state.originalfilterData != null ?
                                            this.state.originalfilterData.map((data, index) => {
                                                return (
                                                    <div key={index}
                                                        // className='leftitems'
                                                        className={'leftitems' + (data.filtername == this.state.filtername ? " active" : '')}
                                                        //  className={'col-12' + (data.filtername == this.state.filtername ? " active" : '')}
                                                        style={{
                                                            marginTop: '5%', marginLeft: '6%', fontSize: '18px', height: '40px',
                                                            paddingLeft: '10%', paddingTop: '2%', overflow: "hidden", borderRadius: '3px'
                                                        }}
                                                        onClick={() => this.setFilter(data)}>{data.filtername}</div>
                                                )
                                            })
                                            : ""
                                    }
                                </div>
                            </div>
                            <div className="col-8" style={{ maxHeight: "400px", overflow: "hidden", marginLeft: '7%' }}>
                                {this.state.filterData != null ?
                                    this.state.filterData.filtertype == 1003 ?
                                        <Thousandthree filterData={this.state.defaultValue[0].values[this.state.filterData.filtername]} defdata={this.state.defaultValue[0].def[this.state.filterData.filtername]}
                                            perdata={this.state.defaultValue[0].oldvalues[this.state.filterData.filtername]}
                                            filterSelected={this.state.filterData.filtername}
                                            filteredData={this.state.defaultValue}
                                            onChange={(val) => { this.updateDefaultvalues(val); console.log(this.state.defaultValue[0].values[this.state.filterData.filtername]) }}
                                        />
                                        :
                                        this.state.filterData.FilterType == 1002 ?
                                            <Thousandtwo filterData={this.state.defaultValue[0].values[this.state.filterData.filtername]} defdata={this.state.defaultValue[0].def[this.state.filterData.filtername]}
                                                onChange={(val) => this.updateDefaultvalues(val)} />

                                            : <></>
                                    :
                                    <></>
                                }
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </>

        )
    }
}
