import React, { Component } from 'react'
import { Modal, Spinner } from 'react-bootstrap'
import { Input } from '../../usefulcomps/HexInput/InputBox'
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import './dict.css'
import Hexapi from '../../usefulcomps/HexAPI/Hexapi'

var oldData = []
export default class Dictionary extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isComponentRender: false, search: '', wordList: [], isApiHitComplete: false, isListFound: false,
            filepath: '', selectedCode: '',isWordSearched:false
        }
    }
    componentDidMount() {
        this.setState({ isComponentRender: true, isApiHitComplete: false, isListFound: false })
        let obj = {
            query: `[dbo].[Lawcrux_Web_Proc_Dictionary_GetList] @UserID='{0}'`,
            queryArr: [`${this.props.userid}`]
        }
        Hexapi(obj).then(resp => {
            console.log(resp)
            if (resp[''].length > 0) {
                oldData = resp['']
                this.setState({ wordList: resp[''], isListFound: true })
            } else {
                this.setState({ isListFound: false })
            }
        }).then(() => this.setState({ isApiHitComplete: true })).catch(err => console.error(err))
    }
    storeSearch(e) {
        this.setState({ search: e.target.value })
        if (e.target.value == '') {
            this.setState({ wordList: oldData })
            this.setState({ isWordSearched: false })
        } else {
            if ((oldData.filter(wordList => (wordList.word).toLowerCase().includes(e.target.value.toLowerCase()))).length > 0) {
                this.setState({ wordList: oldData.filter(wordList => (wordList.word).toLowerCase().includes(e.target.value.toLowerCase())) })
                this.setState({ isWordSearched: false })
            } else {
                this.setState({ isWordSearched: true })
            }
        }
    }
    render() {
        return (
            <>
                {
                    this.state.isComponentRender &&
                    <Modal id={'dict_modal'} size="lg" centered style={{ backgroundColor: 'rgba(0,0,0,0.6)' }} show={true} onHide={() => this.props.onCloseModal()} keyboard={false} backdrop='static'>
                        <Modal.Header>
                            <div className="header">
                                <span>Legal-Dictionary</span>
                                <IconButton onClick={() => this.props.onCloseModal()}>
                                    <CloseIcon />
                                </IconButton>
                            </div>
                        </Modal.Header>
                        <Modal.Body style={{ backgroundColor: "white" }}>
                            <div className="dictOuterBox">
                                <div className="searchBox">
                                    <Input
                                        htmlFor={"forwordsearch"}
                                        label={"For Word Search - Write here"}
                                        type="text"
                                        placeholder="Search..."
                                        labelStyle={{ fontSize: "18px", fontWeight: "600", paddingBottom: "10px" }}
                                        onChange={(e) => this.storeSearch(e)}
                                        value={this.state.search}
                                        autoFocus={true}
                                    />
                                </div>
                                <span class="waplist">Word and Phrases List</span>
                                <div className="dict_mainBox">
                                    <div className="left_list">
                                        {
                                            this.state.isApiHitComplete ?
                                                this.state.isListFound ?
                                                    this.state.isWordSearched ?
                                                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: '100%', minHeight: "50vh" }}>
                                                            <h5 style={{ color: 'orange' }}>No record</h5>
                                                        </div>
                                                        :
                                                        this.state.wordList.map((list, index) => {
                                                            return (
                                                                <span style={{ backgroundColor: this.state.selectedCode == list.keycode ? "rgba(0,0,0,0.1)" : "" }} onClick={() => this.setState({ selectedCode: list.keycode, filepath: `Assets/Data5t/di/didict/${list.keycode}.html` })}>
                                                                    <span style={{ marginRight: "5px" }}></span>
                                                                    {list.word}
                                                                </span>
                                                            )
                                                        })
                                                    :
                                                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: '100%', minHeight: "50vh" }}>
                                                        <h5 style={{ color: 'orange' }}>No record</h5>
                                                    </div>
                                                :
                                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: '100%', minHeight: "50vh" }}>
                                                    <Spinner animation="border" variant="dark" />
                                                </div>

                                        }
                                    </div>
                                    <div className="file_box">
                                        {
                                            this.state.filepath ?
                                                <iframe src={`${this.props.rootUrl + this.state.filepath}`} frameborder="0"></iframe>
                                                :
                                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: '100%', minHeight: "50vh" }}>
                                                    <h5 style={{ color: 'orange' }}>No word selected</h5>
                                                </div>
                                        }
                                    </div>
                                </div>
                            </div>

                        </Modal.Body>
                    </Modal>
                }
            </>
        )
    }
}
