import React, { Component } from 'react'
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import SearchIcon from '@mui/icons-material/Search';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Hsnsearch from './Hsnsearch';
import QueryStatsOutlinedIcon from '@mui/icons-material/QueryStatsOutlined';
import { Modal } from 'react-bootstrap';
import Searched from './Searched';
import Dictionary from './Dictionary';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import HistoryIcon from '@mui/icons-material/History';
import HistoryKeeping from './HistoryKeeping';
import PublicIcon from '@mui/icons-material/Public';
import Comparelaws from './Comparelaws';
import HomeIcon from '@mui/icons-material/Home';
import BookmarksIcon from '@mui/icons-material/Bookmarks';
import BookmarkKeeping from './BookmarkKeeping'


export default class Mynavbar extends Component {
    constructor() {
        super()
        this.state = {
            isOpenHSNSearch: false, isOpenSearchModal: false, isOpenDictModal: false, isOpenHistoryModal: false, isOpenNotesModal: false,
            isReferedByHsn: false, hsnSearchWord: '', hsnPath: '', isReferedByHistory: false, historyFileData: [], isOpenSummaryModal: false,
            isOpenBookmarkModal: false,
        }
    }
    render() {
        console.log(this.state)
        return (
            <>
                <div className="navbar">
                    <div className="row leftdiv">
                        {/* <div className="col-1">
                            <Tooltip title="Go back">
                                <IconButton onClick={() => this.props.goBack()}>
                                    <ArrowBackIcon />
                                </IconButton>
                            </Tooltip>
                        </div> */}

                        {
                            this.props.header &&
                            <div className="col-11 col-md-2">
                                <span style={{ padding: "0px 10px", fontSize: "18px", fontWeight: "600" }}>{this.props.header}</span>
                            </div>
                        }
                        <div className="col-1">
                            <Tooltip title="Go Home">
                                <IconButton onClick={() => this.props.goHome()}>
                                    <HomeIcon />
                                </IconButton>
                            </Tooltip>
                        </div>
                        <div className="col-1">
                            <Tooltip title="Search keyword">
                                <IconButton onClick={(e) => this.setState({ isOpenSearchModal: true })}>
                                    <SearchIcon />
                                </IconButton>
                            </Tooltip>
                        </div>
                        <div className="col-1">
                            <Tooltip title="HSN Search">
                                <IconButton onClick={() => this.setState({ isOpenHSNSearch: true })}>
                                    <QueryStatsOutlinedIcon />
                                </IconButton>
                            </Tooltip>
                        </div>
                        <div className="col-1">
                            <Tooltip title="Dictionary">
                                <IconButton onClick={() => this.setState({ isOpenDictModal: true })}>
                                    <LibraryBooksIcon />
                                </IconButton>
                            </Tooltip>
                        </div>
                        <div className="col-1">
                            <Tooltip title="History">
                                <IconButton onClick={() => this.setState({ isOpenHistoryModal: true })}>
                                    <HistoryIcon />
                                </IconButton>
                            </Tooltip>
                        </div>
                        <div className="col-1">
                            <Tooltip title="Bookmark List">
                                <IconButton onClick={() => this.setState({ isOpenBookmarkModal: true })}>
                                    <BookmarksIcon/>
                                </IconButton>
                            </Tooltip>
                        </div>
                    </div>
                </div>
                {
                    this.state.isOpenHSNSearch &&
                    <Hsnsearch
                        userid={this.props.userid}
                        onCloseModal={() => this.setState({ isOpenHSNSearch: false })}
                    />
                }
                {
                    this.state.isOpenDictModal &&
                    <Dictionary
                        rootUrl={this.props.rootUrl}
                        userid={this.props.userid}
                        onCloseModal={() => this.setState({ isOpenDictModal: false })}
                    />
                }
                {
                    this.state.isOpenHistoryModal &&
                    <HistoryKeeping
                        rootUrl={this.props.rootUrl}
                        userid={this.props.userid}
                        onCloseModal={() => this.setState({ isOpenHistoryModal: false })}
                        openHistoryFile={(fileData) => this.setState({ historyFileData: fileData, isReferedByHistory: true, isOpenSearchModal: true, isOpenHistoryModal: false })}
                    />
                }
                {
                    this.state.isOpenBookmarkModal &&
                    <BookmarkKeeping
                        rootUrl={this.props.rootUrl}
                        userid={this.props.userid}
                        onCloseModal={() => this.setState({ isOpenBookmarkModal: false })}
                        openHistoryFile={(fileData) => this.setState({ historyFileData: fileData, isReferedByHistory: true, isOpenSearchModal: true, isOpenBookmarkModal: false })}
                    />
                }
                {
                    <Modal fullscreen={true} id="documentmodal" animation={true} show={this.state.isOpenSearchModal} onHide={() => this.setState({ isOpenSearchModal: false })}>
                        <Modal.Body>
                            <Searched
                                searchApi={this.props.searchapi}
                                rootUrl={this.props.rootUrl}
                                goBack={() => this.setState({ isOpenSearchModal: false, isReferedByHistory: false, isReferedByHsn: false })}
                                userid={this.props.userid}
                                isReferedByHsn={this.state.isReferedByHsn}
                                hsnSearchWord={this.state.hsnSearchWord}
                                hsnPath={this.state.hsnPath}
                                isReferedByHistory={this.state.isReferedByHistory}
                                historyFileData={this.state.historyFileData}
                                goHome={() => this.props.goHome()}
                            />
                        </Modal.Body>
                    </Modal>
                }
            </>
        )
    }
}
