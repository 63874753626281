import React, { Component } from 'react'
import Table from 'react-bootstrap/Table'
import './TableStyle.css'
// import './Newticketlist.css'
import { Spinner } from 'react-bootstrap'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import Tooltip from '@mui/material/Tooltip';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import PaginationBar from './PaginationBar'
import ReactVisibilitySensor from 'react-visibility-sensor';
import Hexapi from '../HexAPI/Hexapi';
import HexapiCL from '../HexAPI/HexapiCL';
import { Modal } from 'react-bootstrap'
import Thousandthree from '../../Checklist';
import Thousandtwo from "../../Thousandtwo";
import TextFormatIcon from '@mui/icons-material/TextFormat';
import ContentFrame from '../../components/subcomps/ContentFrame';
import BookmarksIcon from '@mui/icons-material/Bookmarks';
import HomeIcon from '@mui/icons-material/Home';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import BookmarkBorderRoundedIcon from '@mui/icons-material/BookmarkBorderRounded';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import Searched from '../../components/subcomps/Searched';
import Dictionary from '../../components/subcomps/Dictionary';
import FindInPageSharpIcon from '@mui/icons-material/FindInPageSharp';
import moment from 'moment'



// import TimeFormatter from '../../../../usefulcomps/TimeFormatter';

var oldData = []
export default class Circular extends Component {
    constructor() {
        super()
        this.searchInputRef = React.createRef();
        this.state = {
            isListFound: false, search: '', isLastList: false, titlesLength: 0, datatab: [],
            typing: false, currentOffset: 0, visible: false, isNavbar: true, endIndex: 100,
            typingTimeout: 0, totalData: [], newtable: [], pageIndex: 0, PAGE_SIZE: 100, heading: "Circular",
            rowData: [], isDataFound: false, currentPaginationPage: 0, showPaginationRows: 0, visible: false,
            isShowSearchBox: true, searchValue: '', OpencustomFrame: false, targetfile: '', mainfolder: "", subfolder: "",
            selectedColumns: ['number', 'subject', 'date'], petitionrowdata: "", clickedRowIndex: -1, filtercount: "",
            visible: false, maxres: 1000, lastcode: 0, code: null, subject: false, untickedvalues: [], filternamecounts: [],
            field: false, searched: '', year: false, all: false, counts: 0, subject1: false, val: '', pagerefresh: false,
            filterData: [], filtername: '', isfilterset: false, isloadcomplete: false, defaultValue: [], petitiondata: false,
            userid: sessionStorage.getItem('userid'), nullhandle: false, allcheckedvalues: "-1", filterloading: false,
            thousanddata: [], activeElement: '', isOpenSearchModal: false, isOpenDictModal: false, isReferedByHsn: false, hsnSearchWord: '', hsnPath: '',
            isReferedByHistory: false, historyFileData: [],bookmarkindex: [],ShowPageCount:false,pageCount:0,Filtervalue:"-1",

        }
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.handleKeyDown);
    }

    handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            this.storeSearch();
        } else if (event.key === 'Backspace' && this.state.searchValue.length == 1) {
            this.clearSearch();
        }else if (event.ctrlKey && (event.key === "M" || event.key === "m")) {
            event.preventDefault();
            this.Pagegetdata();
        }
    };
    Pagegetdata=()=>{

        var searchvalues;
        if (this.state.searchValue == "") {
            searchvalues = -1;
        } else {
            searchvalues = this.state.searchValue;
        }

        let filtercol = "";
        // let clname = "";
        console.log(this.props.deffilter)

        if (this.props.deffilter != "" && this.props.deffilter != undefined && this.props.deffilter != null) {
            filtercol = this.props.deffilter;
            // clname = this.props.clname;
            // console.log(clname)
        } else {
            filtercol = -1;
        }


         if(this.state.Filtervalue == "-1"){
            filtercol = -1;
        }else{
            filtercol=this.state.Filtervalue;
        }

        let allcodeno = this.props.codeno
        if(this.props.homedirect == true){
            let obj = {
                'query': `[dbo].[Lawcrux_Web_Proc_InteractiveIndexOrder_GetCount]@LastCode ='{0}', @Field ='{1}',@Filter ='{2}', @UserID ='{3}', @Search='{4}' `,// @RecID='{0}'
                'queryArr': ['-1', `${this.props.field}`, `${filtercol}`, `${this.state.userid}`, '-1']
            }
            Hexapi(obj).then(resp => { 
                console.log(resp);
                this.setState({ShowPageCount:true,pageCount: resp["total"][0].count});
            });
        }else{
            let obj = {
                'query': `[dbo].[Lawcrux_Web_Proc_InteractiveIndexCirc_SearchGetCount]@LastCode ='{0}', @Field ='{1}',@Filter ='{2}', @UserID ='{3}', @Search='{4}',@Parametericsearch='{5}' `,// @RecID='{0}'
                'queryArr': ['-1', `${this.props.field}`, `${filtercol}`, `${this.state.userid}`, '-1', `${allcodeno}`]
            }
            Hexapi(obj).then(resp => { 
                console.log(resp);
                this.setState({ShowPageCount:true,pageCount: resp["total"][0].count});
            });
        }
    }
    componentDidMount() {

        document.addEventListener('keydown', this.handleKeyDown);

        let obj1 = {
            'query': `[dbo].[Lawcrux_Web_Proc_InteractiveIndexOrder_GetData]@lastCode='{0}' ,@field='{1}', @filter='{2}' , @userid='{3}', @search='{4}'`,
            'queryArr': ['-1', `${this.props.field}`, '-1', `${this.state.userid}`, '-1']
        }
        console.log(obj1)

        Hexapi(obj1).then(resp => {
            console.log(resp);
            console.log(resp[""]);
            if (resp[""] != undefined && resp[""] != null && resp[""].length > 0) {
                const updatedData = resp[""]

                const codes =[];
                updatedData.map((data)=>{
                    if(data.isbookmarked == 1){
                        codes.push(data.code);
                    }
                })
                // console.warn(codes)
                this.setState({bookmarkindex:codes})

                // console.log(updatedData);
                this.setState({ datatab: updatedData })
                this.setState({ rowData: updatedData, isDataFound: true })
                oldData = updatedData;
                // oldData = resp[""];
                this.setState({ newtable: resp[""] });
                let cd = resp[""][resp[""].length - 1].code;
                this.setState({ lastcode: cd })
                // console.log(cd);
            } else {
                this.setState({ nullhandle: true })
            }
        });


    }

    getHeader(data) {
        return Object.keys(data[0]).filter(column => this.state.selectedColumns.includes(column)).map((column, index) => {
            let columnname = column.toUpperCase();
            const columnstyle = (col) => {
                console.log(col);
                if (col == "date") {
                    return { minWidth: '150px' }
                }
            }
            return (
                <th key={index} style={columnstyle(column)}>{columnname}</th>
            )
        })
    }

    clearSearch(e) {
        this.setState({ isDataFound: false })

        var filtervalue;
        if (this.state.allcheckedvalues != "-1") {
            filtervalue = this.state.allcheckedvalues;
        } else {
            filtervalue = -1;
        }

        let obj1;
        if (this.props.codeno == undefined || this.props.codeno == "" || this.props.codeno == null) {
            obj1 = {
                'query': `[dbo].[Lawcrux_Web_Proc_InteractiveIndexOrder_GetData]@lastCode='{0}' ,@field='{1}', @filter='{2}' , @userid='{3}', @search='{4}'`,
                'queryArr': ['-1', `${this.props.field}`, `${filtervalue}`, `${this.state.userid}`, '-1']
            }

        } else {
            let allcodeno = this.props.codeno
            obj1 = {
                'query': `[dbo].[Lawcrux_Web_Proc_InteractiveIndexOrder_GetData]@lastCode='{0}' ,@field='{1}', @filter='{2}' , @userid='{3}', @search='{4}',@Parametericsearch='{5}'`,
                'queryArr': ['-1', `${this.props.field}`, `${filtervalue}`, `${this.state.userid}`, '-1', `${allcodeno}`]
            }
        }
        console.log(obj1)

        Hexapi(obj1).then(resp => {
            let updatedData = resp[""];
            this.setState({ rowData: updatedData })
            this.setState({ isDataFound: true })

        }
        )
        this.setState({ searchValue: '' })
    }


    checkfiltervalues() {
        var filtercount = 0;
        for (var i = 0; i < this.state.originalfilterData.length; i++) {
            var currentfilter = this.state.defaultValue[0].def[this.state.originalfilterData[i].filtername];
            if (this.state.originalfilterData[i].filtername == 'Columns') {
                if (currentfilter.includes('Code') && currentfilter.includes('Icx') && currentfilter.includes('order') && currentfilter.includes('party1')) {

                } else {
                    filtercount = filtercount + 1
                }
            }
        }
        this.setState({ filtercount: filtercount })
        if (filtercount == 0) {
            return true
        } else {
            return false
        }
    }


    applyFilters() {
        let count = 0;

        for (let filter2 in this.state.defaultValue[0].def) {
            // console.log(filter);
            let data1 = this.state.defaultValue[0].def[filter2].split(',').sort();
            console.log(this.state.defaultValue[0].def[filter2]);
            console.log(this.state.defaultValue[0].values[filter2])
            let data2 = this.state.defaultValue[0].oldvalues[filter2].split(',').sort();
            if (JSON.stringify(data1) != JSON.stringify(data2)) {
                count++;
            }
        }

        if (count <= 0) {
            this.setState({ isfilterset: false })
        } else {
            this.setState({ filtercount: count })
            this.setState({ isfilterset: true })

        }
        // let allcheckedvalues;
        if (Object.keys(this.state.defaultValue[0].def).length != 0) {
            this.setState({ isOpenModal: false })
            var filterStr = ''
            var keys = Object.keys(this.state.defaultValue[0].def)
            for (var i = 0; i < keys.length; i++) {
                if (i < keys.length - 1) {
                    if (keys[i] == "Year") {
                        const value = this.state.defaultValue[0].def["Year"].split(",");
                        let allyear = "";
                        for (let i = 0; i <= value.length; i++) {
                            if (value[i] != undefined) {
                                let yeardata = value[i].split("-");
                                const to = yeardata[0];
                                const from = yeardata[1];
                                for (let i = to; i <= from; i++) {
                                    allyear += i + ",";
                                }
                            }
                        }
                        // console.log(allyear.split(0,allyear.length-1) )
                        console.log(allyear.slice(0, allyear.length - 1));
                        filterStr += allyear.slice(0, allyear.length - 1) + '$#12$$$';
                    } else {
                        filterStr += this.state.defaultValue[0].def[keys[i]] + '$#12$$$'
                    }
                    // filterStr += this.state.defaultValue[0].def[keys[i]] + '$#12$$$'
                } else {
                    filterStr += this.state.defaultValue[0].def[keys[i]]
                }
            }
            // console.log(filterStr);
            this.state.allcheckedvalues = filterStr
        }
        // this.setState({ filtercount: this.state.filternamecounts.length })
        // console.log(this.state.filternamecounts.length)

        var filtervalue;
        if (this.state.allcheckedvalues != "-1") {
            filtervalue = this.state.allcheckedvalues;
        } else {
            filtervalue = -1;
        }

        var searchvalues;
        if (this.state.searchValue == "") {
            searchvalues = -1;
        } else {
            searchvalues = this.state.searchValue;
            console.log(this.state.searchValue)
        }
        console.log(searchvalues)
        this.setState({Filtervalue: filtervalue});
        let obj1;
        if (this.props.codeno == undefined || this.props.codeno == "" || this.props.codeno == null) {
            obj1 = {
                'query': `[dbo].[Lawcrux_Web_Proc_InteractiveIndexCirc_GetData]@lastCode='{0}' ,@field='{1}', @filter='{2}' , @userid='{3}', @search='{4}'`,
                'queryArr': ['-1', `${this.props.field}`, `${filtervalue}`, `${this.state.userid}`, `${searchvalues}`]
            }

        } else {
            let allcodeno = this.props.codeno
            obj1 = {
                'query': `[dbo].[Lawcrux_Web_Proc_InteractiveIndexCirc_SearchGetData]@lastCode='{0}' ,@field='{1}', @filter='{2}' , @userid='{3}', @search='{4}',@Parametericsearch='{5}'`,
                'queryArr': ['-1', `${this.props.field}`, `${filtervalue}`, `${this.state.userid}`, `${searchvalues}`, `${allcodeno}`]
            }
        }
        console.log(obj1)

        // this.setState({ filterloading: true })
        this.setState({ isDataFound: false })
        Hexapi(obj1).then(resp => {
            console.log(resp[""]);
            if (resp[""].length <= 1) {
                this.setState({ nullhandle: true })
            }
            else {
                this.setState({ nullhandle: false })

                const columns = resp[""].map(item => {
                    return { ...item, Icons: '' };
                });
                // this.setState({ filterloading: false })
                this.setState({ rowData: columns })
                this.setState({ isDataFound: true })
            }
        });

        console.log(this.state.allcheckedvalues)
        // console.log(applyFilter)
        console.log(this.state.untickedvalues);
        // this.setState({ isShowSearchBox: true })
        this.setState({ isOpenModal: false })
    }



    onRowClick(rowData) {
        this.setState({ isDataFound: false })
        console.log(rowData);
        // console.log(file);
        let obj1 = {
            "query": `[dbo].[Lawcrux_Web_Proc_FileRead_IIOrder_Submit]@UserID ='{0}', @Code='{1}'`,
            "queryArr": [`${sessionStorage.getItem('userid')}`, `${rowData.code}`]
        }
        Hexapi(obj1).then((res) => {
            console.log(res);
        })

        let file = "";
        file = "cu/cuordr/" + rowData.year + "/"+ rowData.code + ".htm";

        let mainf = file.split("/");

        let path = "Assets/Data5t/" + file;
        this.setState({ isDataFound: true })


        this.setState({ targetfile: path, mainfolder: mainf[0], subfolder: mainf[1] })
        this.setState({ OpencustomFrame: true });

        // let obj = {
        //     filecode: `${file}`,
        //     folderpath: `${this.props.folderpath}`
        // }
        // HexapiCL(obj).then(resp => {
        //     console.log(resp);
        //     let data = resp;
        //     let folder = data.split("\\")
        //     // console.log(folder[0], "----",folder[1],"----",folder[2])
        //     console.log(folder);
        //     let main = folder[0].split("/");
        //     console.log(main[main.length - 1])
        //     this.setState({ mainfolder: main[main.length - 1] })
        //     this.setState({ subfolder: folder[1] })
        //     if (folder[2].includes(".htm")) {
        //         this.setState({ targetfile: "Assets/Data5t/" + folder[1] + "/" + folder[2] })
        //     } else if (folder[3].includes(".htm")) {
        //         this.setState({ targetfile: "Assets/Data5t/" + folder[1] + "/" + folder[2] + "/" + folder[3] })
        //     }
        //     else if (folder[4].includes(".htm")) {
        //         this.setState({ targetfile: "Assets/Data5t/" + folder[1] + "/" + folder[2] + "/" + folder[3] + "/" + folder[4] })
        //     } else {
        //         this.setState({ targetfile: "Assets/Data5t/" + folder[1] + "/" + folder[2] + "/" + folder[3] + "/" + folder[4] + "/" + folder[5] })

        //     }
        //     // this.setState({ targetfile: "Assets/Data5t/" + main[main.length - 1] + "/" + folder[1] + "/" + folder[2] })
        // })
        this.setState({ OpencustomFrame: true });

    }

    getid = (i) => {
        console.log(i);
        let foundIndex = null;
        this.state.rowData.map((item, index) => {
            if (index < i - 2) {
                foundIndex = index;
                console.log(foundIndex)
            }
        })
        if (foundIndex != null) {
            this.setState({ activeElement: foundIndex })
        }
    }

    getRowsData(data) {
        var newTableData = [].concat(this.state.rowData)
        let newData = newTableData;
        // console.log(newData);
        if (this.props.pagination) {
            newData = newTableData.filter((row, index) => {
                let rowPerPage = this.state.showPaginationRows < 0 ? newTableData.length : this.state.showPaginationRows
                let start = this.state.currentPaginationPage * rowPerPage
                let end = (this.state.currentPaginationPage + 1) * rowPerPage
                if (index >= start && index < end) return true

            })
        }
        return newData.slice(0, this.state.endIndex).map((row, i) => {
            return (
                <>
                    <ReactVisibilitySensor key={i}
                        onChange={(isVisible) => {
                            if (i == this.state.endIndex - 1 && isVisible) {
                                this.setState((prevState) => ({
                                    endIndex: prevState.endIndex + 100,
                                    visible: true,
                                }));
                            }

                            var value;
                            if (this.state.searchValue == "") {
                                value = "-1";
                            } else {
                                value = this.state.searchValue;
                            }

                            var filtervalue;
                            if (this.state.allcheckedvalues != "-1") {
                                filtervalue = this.state.allcheckedvalues;
                            } else {
                                filtervalue = -1;
                            }

                            if (i + 1 == 1000) {
                                let lastcodevalue = this.state.lastcode - 1000;
                                console.log(lastcodevalue);

                                let obj1;
                                if (this.props.codeno == undefined || this.props.codeno == "" || this.props.codeno == null) {
                                    obj1 = {
                                        'query': `[dbo].[Lawcrux_Web_Proc_InteractiveIndexOrder_GetData] @lastCode='{0}' ,@field='{1}', @filter='{2}' , @userid='{3}', @search='{4}'`,
                                        'queryArr': ['-1', `${this.props.field}`, `${filtervalue}`, `${this.state.userid}`, `${value}`]
                                    }

                                } else {
                                    let allcodeno = this.props.codeno
                                    obj1 = {
                                        'query': `[dbo].[Lawcrux_Web_Proc_InteractiveIndexOrder_GetData] @lastCode='{0}' ,@field='{1}', @filter='{2}' , @userid='{3}', @search='{4}',@Parametericsearch='{5}'`,
                                        'queryArr': ['-1', `${this.props.field}`, `${filtervalue}`, `${this.state.userid}`, `${value}`, `${allcodeno}`]
                                    }
                                }
                                Hexapi(obj1).then(resp => {
                                    console.log(resp[""]);
                                    this.setState((prevState) => ({
                                        rowData: prevState.rowData.concat(resp[""])
                                    }));
                                    console.log(resp[""].length - 1);
                                    console.log(resp[""][resp[""].length - 1].code);
                                    let lastco = resp[""][resp[""].length - 1].code;
                                    console.log(lastco);
                                    this.setState({
                                        lastcode: lastco
                                    }
                                    );
                                });
                            }
                        }
                        }
                    >
                        <>
                            <tr id={i} key={i} onClick={() => { this.onRowClick(row); this.getid(i); row.isread = 1; }} style={row.isread == 0 ? { fontWeight: 'bold' } : {}}>
                                {Object.keys(row).filter(heading => this.state.selectedColumns.includes(heading)).map((heading) => {

                                    if (typeof row[heading] != 'object' && heading != "date" && heading != "year" && heading != "number") {
                                        return <td key={row[heading]} dangerouslySetInnerHTML={{ __html: row[heading] }} />
                                    }
                                    else if (heading == "number") {
                                        let data = row.number;
                                        return <td key={i} style={{ textAlign: 'left' }}>
                                            {
                                                <span>
                                                    {/* {
                                                        this.state.bookmarkindex.some((element) => element == row.code) ?
                                                            <Tooltip title="Remove Bookmark">
                                                                <IconButton onClick={(e) => { e.stopPropagation(); this.handleBookmarkClick(row, i) }}>
                                                                    <BookmarksIcon />
                                                                </IconButton>
                                                            </Tooltip>
                                                            :
                                                            <Tooltip title="Add Bookmark">
                                                                <IconButton onClick={(e) => { e.stopPropagation(); this.handleBookmarkClick(row, i) }}>
                                                                    <BookmarkBorderRoundedIcon />
                                                                </IconButton>
                                                            </Tooltip>
                                                    } */}
                                                </span>
                                            }
                                            {data}</td>
                                    }

                                    else if (heading == "date") {
                                        let datetext = "";
                                        if (row["date"] != null) {
                                            datetext = moment(row["date"].date).format('DD-MM-YYYY');
                                            // datetext = row["date"].date.slice(0, 11)
                                        }
                                        return <td key={row} >{datetext}</td>
                                    }

                                    // else if (heading == "Icons") {
                                    //     const year = row["year"];
                                    //     let yeartext = row["date"].date;
                                    //     yeartext = yeartext.slice(0, 11);


                                    //     return (
                                    //         <td key={heading} className="favour-column">
                                    //             <Tooltip title={yeartext}>
                                    //                 <span style={{ zIndex: '0.5' }} className={`favour-value`}>
                                    //                     {year}{" "}
                                    //                 </span>
                                    //             </Tooltip>
                                    //         </td>
                                    //     );
                                    // }

                                    else {
                                        return <td key={row[heading]} dangerouslySetInnerHTML={{ __html: row[heading] }} />

                                    }
                                })}
                            </tr>
                        </>
                    </ReactVisibilitySensor>
                </>
            )
        }
        )
    }


    handleBookmarkClick(row, i) {

        if(this.state.bookmarkindex.some((element) => element == row.code)){
            let arr = this.state.bookmarkindex.filter(ele => ele != row.code)
            this.setState({bookmarkindex : arr})
            this.removeBookmark(row.code, i, row.isbookmarked);
        }else{
            this.setState(previousState => ({
                bookmarkindex: [...previousState.bookmarkindex, row.code]
            }),()=>{
                // console.warn(this.state.bookmarkindex)
            });
            this.addBookmark(row.name, row.code, i, row);
        }
        console.log(row.isbookmarked)


        // this.setState({ bookmarkindex: row.code })
        // console.log(row.isbookmarked)
        // if (row.isbookmarked !== 0) {
        //     this.removeBookmark(row.code, i,row.isbookmarked);
        // } else {
        //     this.addBookmark(row.subject, row.code, i, row);
        // }
    }

    removeBookmark(filepath, i , bookmark) {
        // this.setState({ isBookmarkAddComplete: false })
        let obj = {
            query: `[dbo].[Lawcrux_Web_Proc_RemoveBookmark_Submit] @UserID='{0}', @filepath='{1}'`,
            queryArr: [`${this.state.userid}`, `${filepath}`]
        }
        console.log(obj)
        Hexapi(obj).then(resp => {
            console.log(resp)
            if (resp[''][0].command == 1) {
                const newData = [...this.state.rowData];
                newData[i].isbookmarked = 0;
                this.setState({
                    rowData: newData,
                    isBookmarkAdded: true
                });
            }
        })
    }

    addBookmark(subject, filepath, i, rowData) {
        console.log(rowData);

        let file = "";
        if (this.props.field == "customs") {
            file = "cu/cuncuc/" + rowData.code + ".htm";
        }
        else if (this.props.field == "service tax") {
            file = "cu/stncsc/" + rowData.code + ".htm";
        }
        else if (this.props.field == "excise") {
            file = "cu/cencec/" + rowData.code + ".htm";
        }


        let path = "Assets/Data5t/" + file;
        let title = subject;
        // this.setState({ isBookmarkAddComplete: false })/
        let obj = {
            query: `[dbo].[Lawcrux_Web_Proc_AddBookmark_Submit] @UserID='{0}', @filepath='{1}',@Description='{2}'`,
            queryArr: [`${this.state.userid}`, `${path}`, `${title}`]
        }
        console.log(obj)

        Hexapi(obj).then(resp => {
            console.log(resp)
            if (resp[''][0].command == 1) {
                const newData = [...this.state.rowData];
                newData[i].isbookmarked = 1; // Update isbookmarked property directly
                this.setState({
                    rowData: newData,
                    isBookmarkAdded: false
                });
            }
        })

    }




    storeSearch(e) {
        this.setState({ isDataFound: false })
        console.log(this.state.searchValue)
        let value = this.state.searchValue;
        // console.log(value);
        // this.setState({ searchValue: value })
        let applyFilter = Object.keys(oldData[0])

        var filtervalue;
        if (this.state.allcheckedvalues != "-1") {
            filtervalue = this.state.allcheckedvalues;
        } else {
            filtervalue = -1;
        }

        var searchvalues;
        if (this.state.searchValue == "") {
            searchvalues = -1;
        } else {
            searchvalues = this.state.searchValue;
        }

        if (value == '') {
            if (oldData.length > 0) {
                this.setState({ rowData: oldData, isDataFound: true })
                // console.log(rowData)
            } else {
                this.setState({ isDataFound: false })
            }
        } else {

            let obj1;
            if (this.props.codeno == undefined || this.props.codeno == "" || this.props.codeno == null) {
                obj1 = {
                    'query': `[dbo].[Lawcrux_Web_Proc_InteractiveIndexCirc_GetData]@lastCode='{0}' ,@field='{1}', @filter='{2}' , @userid='{3}', @search='{4}'`,
                    'queryArr': ['-1', `${this.props.field}`, `${filtervalue}`, `${this.state.userid}`, `${searchvalues}`]
                }

            } else {
                let allcodeno = this.props.codeno
                obj1 = {
                    'query': `[dbo].[Lawcrux_Web_Proc_InteractiveIndexCirc_SearchGetData]@lastCode='{0}' ,@field='{1}', @filter='{2}' , @userid='{3}', @search='{4}',@Parametericsearch='{5}'`,
                    'queryArr': ['-1', `${this.props.field}`, `${filtervalue}`, `${this.state.userid}`, `${searchvalues}`, `${allcodeno}`]
                }
            }

            Hexapi(obj1).then(resp => {
                console.log(resp[""]);
                const columns = resp[""];
                this.setState({ rowData: columns })
                this.setState({ isDataFound: true })

            });
        }
    }

    openFilterModal = (e) => {
        this.setState({ isOpenModal: true })
        if (this.state.originalfilterData != null) {
            console.log(this.state.originalfilterData)
            this.state.originalfilterData.map((data, index) => {
                if (index == 0) {
                    this.setState({ filterData: data, filtername: data.filtername })
                }
            })
        }
    }

    clearFilters = () => {
        if (this.state.defaultValue[0].def == this.state.defaultValue[0].oldvalues) {
            console.log(true)
        } else {
            console.log(false)
        }
        // this.setState({isloadcomplete:false,isfilterset:false})
        this.setState({ isloadcomplete: false, isfilterset: false })
        // console.log(this.state.originalfilterData.length)
        for (var i = 0; i < this.state.originalfilterData.length; i++) {
            this.state.defaultValue[0].def[this.state.originalfilterData[i].filtername] = this.state.defaultValue[0].oldvalues[this.state.originalfilterData[i].filtername]
        }

        var searchvalues;
        if (this.state.searchValue == "") {
            searchvalues = -1;
        } else {
            searchvalues = this.state.searchValue;
        }

        let obj1;
        if (this.props.codeno == undefined || this.props.codeno == "" || this.props.codeno == null) {
            obj1 = {
                'query': `[dbo].[Lawcrux_Web_Proc_InteractiveIndexCirc_GetData]@lastCode='{0}' ,@field='{1}', @filter='{2}' , @userid='{3}', @search='{4}'`,
                'queryArr': ['-1', `${this.props.field}`, '-1', `${this.state.userid}`, `${searchvalues}`]
            }

        } else {
            let allcodeno = this.props.codeno
            obj1 = {
                'query': `[dbo].[Lawcrux_Web_Proc_InteractiveIndexCirc_SearchGetData]@lastCode='{0}' ,@field='{1}', @filter='{2}' , @userid='{3}', @search='{4}',@Parametericsearch='{5}'`,
                'queryArr': ['-1', `${this.props.field}`, '-1', `${this.state.userid}`, `${searchvalues}`, `${allcodeno}`]
            }
        }
        console.log(obj1)
        this.setState({ isDataFound: false })

        Hexapi(obj1).then(resp => {
            const updatedData = resp[""].map(item => {
                return { ...item, Icons: '' };
            });
            console.log(updatedData);
            this.setState({ rowData: updatedData })
            this.setState({ isDataFound: true })
        });
        this.setState({ isOpenModal: false })
        this.setState({ nullhandle: false })
    }


    updateDefaultvalues = (data) => {
        // if(this.state.isOpenModal)
        // let filtercounts = this.state.filtername;

        // let found = false;
        // if (this.state.filternamecounts.length == 0) {
        //     let names = [];
        //     names.push(filtercounts)
        //     setTimeout(() => {
        //         this.setState({ filternamecounts: names })
        //     }, 100);
        // }
        // for (let i = 0; i < this.state.filternamecounts.length; i++) {
        //     if (filtercounts.toString() == this.state.filternamecounts[i]) {
        //         found = true;
        //         break;
        //     } else {
        //         found = false;
        //     }
        // }
        // console.log(found);

        // if (found == false) {
        //     let names = this.state.filternamecounts;
        //     names.push(filtercounts)
        //     this.setState({ filternamecounts: names })
        // }

        console.log(this.state.filtername, data)
        // defaultValue[0].def[this.state.filtername] = data
        this.setState({ counts: this.state.counts + 1 })
        this.state.defaultValue[0].def[this.state.filtername] = data
        console.log(this.state.defaultValue[0].def);
        console.log('update 1')
        // console.log(defaultValue)
    }

    setFilter = (data) => {
        console.log(data.filtername)
        this.setState({ filterData: data, filtername: data.filtername })
    }

    pageload = () => {

        var searchvalues;
        if (this.state.searchValue == "") {
            searchvalues = -1;
        } else {
            searchvalues = this.state.searchValue;
        }

        var filtervalue;
        if (this.state.allcheckedvalues != "-1") {
            filtervalue = this.state.allcheckedvalues;
        } else {
            filtervalue = -1;
        }

        let obj1;
        if (this.props.codeno == undefined || this.props.codeno == "" || this.props.codeno == null) {
            obj1 = {
                'query': `[dbo].[Lawcrux_Web_Proc_InteractiveIndexCirc_GetData]@lastCode='{0}' ,@field='{1}', @filter='{2}' , @userid='{3}', @search='{4}'`,
                'queryArr': ['-1', `${this.props.field}`, `${filtervalue}`, `${this.state.userid}`, `${searchvalues}`]
            }

        } else {
            let allcodeno = this.props.codeno
            obj1 = {
                'query': `[dbo].[Lawcrux_Web_Proc_InteractiveIndexCirc_SearchGetData]@lastCode='{0}' ,@field='{1}', @filter='{2}' , @userid='{3}', @search='{4}',@Parametericsearch='{5}'`,
                'queryArr': ['-1', `${this.props.field}`, `${filtervalue}`, `${this.state.userid}`, `${searchvalues}`, `${allcodeno}`]
            }
        }
        console.log(obj1)

        Hexapi(obj1).then(resp => {
            const updatedData = resp[""].map(item => {
                return { ...item, Icons: '' };
            });
            this.setState({ rowData: updatedData })
        }
        )
    }
    handleforward = () => {
        const value = false;
        this.props.callback(value)
    }

    scollback = () => {
        console.log("move")
        const element = document.getElementById(this.state.activeElement);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' })
        }
        this.setState({ activeElement: '' });
    }

    render() {

        return (
            <>
                {/* dangerouslySetInnerHTML={__html: this.state.html} */}

                {
                    this.state.OpencustomFrame ?
                        <ContentFrame
                            // style={{backgroundImage:'',opacity:'0.2',backgroundRepeat:' no-repeat', backgroundPosition: 'center center',}}
                            goBack={() => { this.setState({ OpencustomFrame: false }); this.scollback() }}
                            filepath={this.state.targetfile}
                            searchApi={sessionStorage.getItem('searchapi')}
                            rootUrl={sessionStorage.getItem('url')}
                            userid={sessionStorage.getItem('userid')}
                            MainFolder={this.state.mainfolder}
                            SubFolder={this.state.subfolder}
                            goHome={() => { this.setState({ OpencustomFrame: false }); this.handleforward() }}
                            showHighlightttext={this.props.showHighlightttext}
                            searchitem={this.props.searchitem}
                        />
                        :
                        null
                }

                <div style={{ position: 'relative', height: "inherit" }}>
                    {
                        this.props.showheadernavbar ?
                            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                                <div className="col-1">
                                    <Tooltip title="Go Home"
                                        onClick={() => this.handleforward()}>
                                        <IconButton >
                                            <HomeIcon style={{ color: "black" }} />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                                <div className="col-1" >
                                    <Tooltip title="Go back">
                                        <IconButton onClick={() => this.handleforward()}>
                                            <ArrowBackIcon style={{ color: "black" }} />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                                <div className="col-1" >
                                    <Tooltip title="Go Forward">
                                        <IconButton onClick={() => { this.setState({ OpencustomFrame: true }) }}>
                                            <ArrowForwardIcon style={{ color: "black" }} />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                                <div className="col-1" >
                                    <Tooltip title="Search keyword">
                                        <IconButton onClick={() => this.setState({ isOpenSearchModal: true })}>
                                            <SearchIcon />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                                <div className="col-1" >
                                    <Tooltip title="Dictionary">
                                        <IconButton onClick={() => this.setState({ isOpenDictModal: true })}>
                                            <LibraryBooksIcon />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                            </div>
                            :
                            null}
                    {
                        this.state.isNavbar &&
                        <div className="_tablenavbar" style={{ backgroundColor: '#f9f9f9' }}>
                            <div className="_left" style={{ display: 'flex', alignItems: 'center' }}>
                                {
                                    this.props.showHome ?
                                        <div className="col-1">
                                            <Tooltip title="Go Home"
                                                onClick={() => this.handleforward()}>
                                                <IconButton >
                                                    <HomeIcon style={{ color: "black" }} />
                                                </IconButton>
                                            </Tooltip>
                                        </div>
                                        :
                                        <></>
                                }
                                <span style={{ textTransform: "Capitalize", marginLeft: '25%', whiteSpace: 'nowrap' }}>
                                    {this.props.circularheader != "" && this.props.circularheader != undefined ? this.props.circularheader : "Circular"}
                                </span>
                            </div>

                            <div className="_right" style={{ display: 'flex', justifyContent: "center" }}>
                                <input type="text" ref={this.searchInputRef} id="searchBarFocus" autoFocus={true} value={this.state.searchValue}
                                    style={{
                                        width: this.state.isShowSearchBox ? "250px" : '0px', padding: this.state.isShowSearchBox ? "6px 12px" : "6px 0px",
                                        marginLeft: this.state.isShowSearchBox ? "10px" : '0px'
                                    }} className="editable" placeholder='Search...'
                                    onChange={(e) => this.setState({ searchValue: e.target.value })}
                                ></input>
                                {this.state.searchValue.length > 0 ?
                                    <Tooltip title="Close">
                                        <IconButton style={{ color: 'black' }} onClick={() => this.clearSearch()}>
                                            <CloseIcon />
                                        </IconButton>
                                    </Tooltip> : <></>}
                                {
                                    this.state.isShowSearchBox ?
                                        <>
                                            <Tooltip title="Search">
                                                <IconButton style={{ color: 'black' }} onClick={() => this.storeSearch()}>
                                                    <SearchIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </>


                                        :
                                        <Tooltip title="Open Search">
                                            <IconButton style={{ color: 'black' }} onClick={() => this.setState({ isShowSearchBox: true }, () => {
                                                if (document.getElementById('searchBarFocus')) {
                                                    document.getElementById('searchBarFocus').focus()
                                                }
                                            })}>
                                                <SearchIcon />
                                            </IconButton>
                                        </Tooltip>

                                }
                                {/* <Tooltip title={'Filter'}>
                                    <IconButton style={{ color: 'black' }} onClick={(e) => this.openFilterModal(e)}>
                                        <FilterAltIcon />
                                    </IconButton>
                                </Tooltip> */}
                                {/* {
                                    this.state.isfilterset ?
                                        <div style={{ backgroundColor: 'red', borderRadius: '50%', height: '20px', width: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                            <span style={{ color: 'white', }}>{this.state.filtercount}</span>
                                        </div>
                                        : ''
                                } */}
                            </div>
                        </div>
                    }


                    {this.state.nullhandle ?
                        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '15%' }}>
                            <h3>No Data Found</h3>
                        </div>
                        :

                        <Table id='_tableGrid' className={'table-hover'} responsive style={{ height: this.props.tableheight }}>
                            <thead style={{
                                backgroundColor: "black",
                                color: '#fff'
                            }} >
                                {/* style={this.props.headStyle}} */}
                                <tr>
                                    {this.state.isDataFound &&
                                        this.state.rowData.length > 0 && this.getHeader(this.state.rowData)}
                                </tr>
                            </thead>
                            <>
                                {
                                    this.state.isDataFound ?
                                        this.state.rowData.length > 0 ?
                                            <tbody>
                                                {
                                                    this.getRowsData(this.state.newtable)
                                                }
                                            </tbody>
                                            :
                                            <tbody>
                                                <div style={{ marginTop: '10%', marginLeft: '4%' }}>
                                                    <h3>
                                                        No Data Found
                                                    </h3>
                                                </div>
                                            </tbody>
                                        : <tbody>
                                            <div style={{ marginTop: '10%', marginLeft: '4%' }}>
                                                <h3>
                                                    Loading...<Spinner variant='dark' animation='border' />
                                                </h3>
                                            </div>
                                        </tbody>

                                }
                            </>
                        </Table>
                    }

                    {
                        this.props.pagination &&
                        this.state.isDataFound &&
                        <div className="_tablefooter">

                            <PaginationBar
                                rowsPerPage={this.props.pagination.rowsPerPage}
                                count={this.state.rowData.length}
                                rowsPerPageOptions={this.props.pagination.rowsPerPageOptions}
                                onPageChange={(currentPage) => this.setState({ currentPaginationPage: currentPage })}
                                onRowsChange={(rows) => this.setState({ showPaginationRows: rows, currentPaginationPage: 0 })}
                            />
                        </div>
                    }

                </div >

                {
                    this.state.isOpenDictModal &&
                    <Dictionary
                        rootUrl={sessionStorage.getItem('url')}
                        userid={sessionStorage.getItem('userid')}
                        onCloseModal={() => this.setState({ isOpenDictModal: false })}
                    />
                }

                <Modal fullscreen={true} id="documentmodal" animation={true} show={this.state.isOpenSearchModal} onHide={() => this.setState({ isOpenSearchModal: false })}>
                    <Modal.Body>
                        <Searched
                            searchApi={sessionStorage.getItem("searchapi")}
                            rootUrl={sessionStorage.getItem('url')}
                            goBack={() => this.setState({ isOpenSearchModal: false, isReferedByHistory: false, isReferedByHsn: false })}
                            userid={sessionStorage.getItem('userid')}
                            isReferedByHsn={this.state.isReferedByHsn}
                            hsnSearchWord={this.state.hsnSearchWord}
                            hsnPath={this.state.hsnPath}
                            isReferedByHistory={this.state.isReferedByHistory}
                            historyFileData={this.state.historyFileData}
                            goHome={() => this.setState({ isOpenSearchModal: false })}
                        />
                    </Modal.Body>
                </Modal>
                <Modal id={'search_options_modal'} size="md" centered style={{ backgroundColor: 'rgba(0,0,0,0.6)' }} show={this.state.ShowPageCount} onHide={() => this.setState({ ShowPageCount: false })}>
                    <Modal.Body style={{ padding: "10px" }}>
                        <div style={{display:'flex', justifyContent:'center', alignItems:'center', minHeight:'100px'}}>
                            <b>Count:</b>&nbsp;&nbsp;
                            <span>{this.state.pageCount}</span>
                        </div>
                    </Modal.Body>
                </Modal>
                {/* <Modal size="lg" centered style={{ backgroundColor: 'rgba(0,0,0,0.6)' }} show={this.state.isOpenModal} keyboard={false} backdrop='static' >
                    <Modal.Header>
                        <h3>Filters</h3>
                        <div style={this.state.isfilterset ? { display: "flex", justifyContent: "space-between", width: "280px", alignItems: 'center' }
                            : { display: "flex", justifyContent: "flex-end", width: "280px", alignItems: 'center' }}>
                            {
                                this.state.isfilterset ?
                                    <div class="btn btn-warning" style={{
                                        color: "#fff", backgroundColor: 'red', width: '40%',
                                        textAlign: 'center', height: '30px',
                                        fontSize: '18px',
                                        display: 'flex',
                                        justifyContent: 'center'
                                    }} onClick={() => this.clearFilters()}>Clear Filters</div>
                                    :
                                    ""
                            }
                            <div id='applyfilter' className="btn btn-warning"
                                style={{
                                    color: "#fff", backgroundColor: 'yellowgreen', width: '40%',
                                    textAlign: 'center', height: '30px',
                                    fontSize: '18px',
                                    display: 'flex',
                                    justifyContent: 'center'
                                }}
                                onClick={() => this.applyFilters()}
                            >Apply Filters</div>
                            <div>
                                <Tooltip title="Close Filter">
                                    <IconButton onClick={() => { this.setState({ isOpenModal: false }); this.state.defaultValue[0].def = this.state.defaultValue[0].def; }}>
                                        <CloseIcon style={{ color: 'black' }} />
                                    </IconButton>
                                </Tooltip>
                            </div>
                        </div>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <div className="col-3">
                                <div className='row filternames' >
                                    {
                                        this.state.originalfilterData != null ?
                                            this.state.originalfilterData.map((data, index) => {
                                                return (
                                                    <div key={index}
                                                        className={'leftitems' + (data.filtername == this.state.filtername ? " active" : '')}
                                                         style={{
                                                            marginTop: '5%', marginLeft: '6%', fontSize: '18px', height: '40px',
                                                            paddingLeft: '10%', paddingTop: '2%', overflow: "hidden", borderRadius: '3px'
                                                        }}
                                                        onClick={() => this.setFilter(data)}>{data.filtername}</div>
                                                )
                                            })
                                            : ""
                                    }
                                </div>
                            </div>
                            <div className="col-8" style={{ maxHeight: "400px", overflow: "hidden", marginLeft: '7%' }}>
                                {
                                    this.state.filterData != null ?
                                        this.state.filterData.filtertype == 1003 ?
                                            <Thousandthree filterData={this.state.defaultValue[0].values[this.state.filterData.filtername]} defdata={this.state.defaultValue[0].def[this.state.filterData.filtername]}
                                                perdata={this.state.defaultValue[0].oldvalues[this.state.filterData.filtername]}
                                                onChange={(val) => this.updateDefaultvalues(val)}
                                                filterSelected={this.state.filterData.filtername}
                                                filteredData={this.state.defaultValue}
                                            />
                                            : this.state.filterData.filtertype == 1002 ?
                                                <Thousandtwo filterData={this.state.defaultValue[0].values[this.state.filterData.filtername]} defdata={this.state.defaultValue[0].def[this.state.filterData.filtername]}
                                                    onChange={(val) => this.updateDefaultvalues(val)} />
                                                : ""
                                        : ""
                                }
                            </div>
                        </div>
                    </Modal.Body>
                </Modal> */}
            </>

        )
    }
}