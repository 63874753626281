import React, { Component } from 'react'
import Mynavbar from './Mynavbar'
import Searched from './Searched'
import { Modal } from 'react-bootstrap'
import ContentFrame from './ContentFrame';

export default class PraciceNprocedure extends Component {
    constructor(props) {
        super(props)
        this.state = {
            openCustomFrame: false,
            targetFile: 'Assets/Data5t/gt/gtwork/',
            subfolder: '', mainFolder: '',
        }
    }
    componentDidMount(){
        this.setState({openCustomFrame:true,subfolder:'gtwork',mainFolder:'gt'})
    }
    render() {
        return (
            <div>
                {
                    this.state.openCustomFrame ?
                        <ContentFrame
                            goBack={() => this.setState({ openCustomFrame: false }, this.props.goBack())}
                            filepath={this.state.targetFile + 'index.htm'}
                            searchApi={this.props.searchApi}
                            rootUrl={this.props.rootUrl}
                            userid={this.props.userid}
                            MainFolder={this.state.mainFolder}
                            SubFolder={this.state.subfolder}
                            goHome={() => this.props.goBack()}
                        />
                        : null
                }
            </div>
        )
    }
}
