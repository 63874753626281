/* 
In the function 'InputElem', use 'useState' to create a state variable 'textareaHeight' for the textarea's height, with initial value 1. 
Inside the if statement, use 'useState' to set the rows to 'trows'. 
*/

import React from 'react';
// import autosize from "autosize";

export class Textbox extends React.Component {
  componentDidMount() {
    this.textarea.focus();
    // autosize(this.textarea);
  }
  render() {
    const style = {
      maxHeight: "60vh",
      minHeight: "60vh",
      // resize: "none",
      padding: "9px",
      boxSizing: "border-box",
      fontSize: "15px",
      width: '100%',
      border:'none',
      boxShadow:"none",
      background:"transparent",
      overFlowY:"auto"
    };
    if(document.getElementById(this.props.id)){
      var input = document.getElementById(this.props.id)
      if(input.value==''){
        input.style.height = '41px'
      }
    }
    return (
      <textarea
        id={this.props.id}
        style={style}
        ref={c => (this.textarea = c)}
        placeholder={this.props.placeholder}
        rows={1}
        value={this.props.value}
        className='form-control'
        onChange={(e) => this.props.onChange && this.props.onChange(e)}
        onClick={(e) => this.props.onClick && this.props.onClick(e)}
        required={this.props.required}
        readOnly={this.props.readOnly}
        autoFocus={this.props.autoFocus}
        onKeyDown={(e) => this.props.onKeyDown && this.props.onKeyDown(e)}
      />
    );
  }
}