import React, { Component } from 'react'
import Mynavbar from './Mynavbar'
import Searched from './SearchedV2'
import { Modal } from 'react-bootstrap'
import ContentFrame from './ContentFrame';
import TreeView from '@mui/lab/TreeView';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TreeItem from '@mui/lab/TreeItem';

export default class Oldlaw extends Component {
    constructor(props) {
        super(props)
        this.state = {
            openCustomFrame: false,
            targetFile: '', isError: false,
            subfolder: '', mainFolder: '',openCircNoti:false,
            lawData: [
                {
                    name: "EXCISE",
                    children: [
                        {
                            name: "Act",
                            link: 'Assets/Data5t/ce/ceacts/index.htm',
                            parent: "EXCISE",
                        },
                        {
                            name: "Rules",
                            link: 'Assets/Data5t/ce/cerule/index.htm',
                            parent: "EXCISE",
                        },
                        {
                            name: "Circular",
                            link: '',
                            type: 'excise',
                            parent: "EXCISE",
                        },
                        {
                            name: "Notification",
                            children: [
                                {
                                    name: 'Non Tariff',
                                    link: '',
                                    type: 'excise nt',
                                    parent: "EXCISE",
                                },
                                {
                                    name: 'Tariff',
                                    link: '',
                                    type: 'excise tariff',
                                    parent: "EXCISE",
                                }
                            ]
                        },
                        {
                            name: "Manual",
                            link: 'Assets/Data5t/ce/cemanu/index.htm',
                            parent: "EXCISE",
                        },
                        {
                            name: "Tariff",
                            link: 'Assets/Data5t/ce/cetari/index.htm',
                            parent: "EXCISE",
                        }
                    ]
                },
                {
                    name: "SERVICE TAX",
                    children: [
                        {
                            name: "Act",
                            link: 'Assets/Data5t/st/stacts/index.htm',
                            parent: "SERVICE TAX",
                        },
                        {
                            name: "Rules",
                            link: 'Assets/Data5t/st/strule/index.htm',
                            parent: "SERVICE TAX",
                        },
                        {
                            name: "Circular",
                            link: '',
                            type: 'service tax',
                            parent: "SERVICE TAX",
                        },
                        {
                            name: "Notification",
                            children: [
                                {
                                    name: 'History',
                                    link: '',
                                    type: 'service tax',
                                    parent: "SERVICE TAX",
                                }
                            ]
                        },
                        {
                            name: "Finance Act",
                            link: 'Assets/Data5t/st/stfina/index.htm',
                            parent: "SERVICE TAX",
                        },
                        {
                            name: "Scheme",
                            link: 'Assets/Data5t/st/stsche/index.htm',
                            parent: "SERVICE TAX",
                        },
                        {
                            name: "List of Negative Services",
                            link: 'Assets/Data5t/st/stnews/index.htm',
                            parent: "SERVICE TAX",
                        },
                        {
                            name: "Ready Reckoner",
                            link: 'Assets/Data5t/st/stmisc/index.htm',
                            parent: "SERVICE TAX",
                        }
                    ]
                }
            ]
        }
        this.setInitialPage = this.setInitialPage.bind(this)
    }

    setInitialPage(child_obj, parent) {
        console.log(child_obj, parent)
        if (child_obj.link != "" && child_obj.link != undefined) {
            this.setState({ targetFile: child_obj.link, openCustomFrame: true, openCircNoti: false })

            let child = child_obj.name.toLowerCase()
            parent = parent.toLowerCase()

            if (parent == 'excise') {
                if (child == 'act') {
                    this.setState({ subfolder: 'ceacts', mainFolder: 'ce' })
                } else if (child == 'rules') {
                    this.setState({ subfolder: 'cerule', mainFolder: 'ce' })
                } else if (child == 'circular') {
                    this.setState({ subfolder: 'cencec', mainFolder: 'ce' })
                } else if (child == 'notification') {
                    this.setState({ subfolder: 'cencet/cencev', mainFolder: 'ce' })
                } else if (child == 'manual') {
                    this.setState({ subfolder: 'cemanu', mainFolder: 'ce' })
                } else if (child == 'tariff') {
                    this.setState({ subfolder: 'cetari', mainFolder: 'ce' })
                }
            } else if (parent == 'service tax') {
                if (child == 'act') {
                    this.setState({ subfolder: 'stacts', mainFolder: 'st' })
                } else if (child == 'rules') {
                    this.setState({ subfolder: 'strule', mainFolder: 'st' })
                } else if (child == 'circular') {
                    this.setState({ subfolder: 'stncsc', mainFolder: 'st' })
                } else if (child == 'notification') {
                    this.setState({ subfolder: 'stncsv', mainFolder: 'st' })
                } else if (child == 'finance act') {
                    this.setState({ subfolder: 'stfina', mainFolder: 'st' })
                } else if (child == 'scheme') {
                    this.setState({ subfolder: 'stsche', mainFolder: 'st' })
                } else if (child == 'list of negative services') {
                    this.setState({ subfolder: 'stnews', mainFolder: 'st' })
                } else if (child == 'ready reckoner') {
                    this.setState({ subfolder: 'stmisc', mainFolder: 'st' })
                }
            }
        } else if (child_obj.link == '' && child_obj.type != undefined) {
            this.setState({ targetFile: Object.assign(child_obj, { type: child_obj.type.toLowerCase() }), openCustomFrame: false, openCircNoti: true })
        }

        // switch (parent) {
        //     case 'excise': {
        //         switch (child) {
        //             case 'act': this.setState({ subfolder: 'ceacts', mainFolder: 'ce' })
        //             case 'rules': this.setState({ subfolder: 'cerule', mainFolder: 'ce' })
        //             case 'circular': this.setState({ subfolder: 'cencec', mainFolder: 'ce' })
        //             case 'notification': this.setState({ subfolder: 'cencet/cencev', mainFolder: 'ce' })
        //             case 'manual': this.setState({ subfolder: 'cemanu', mainFolder: 'ce' })
        //             default: return false
        //         }
        //     }
        //     case 'service tax': {
        //         switch (child) {
        //             case 'act': this.setState({ subfolder: 'stacts', mainFolder: 'st' })
        //             case 'rules': this.setState({ subfolder: 'strule', mainFolder: 'st' })
        //             case 'circular': this.setState({ subfolder: 'stncsc', mainFolder: 'st' })
        //             case 'notification': this.setState({ subfolder: 'stncsv', mainFolder: 'st' })
        //             case 'finance act': this.setState({ subfolder: 'stfina', mainFolder: 'st' })
        //             case 'schema': this.setState({ subfolder: 'stsche', mainFolder: 'st' })
        //             case 'list of negative services': this.setState({ subfolder: 'stnews', mainFolder: 'st' })
        //             case 'ready reckoner': this.setState({ subfolder: 'stmisc', mainFolder: 'st' })
        //             default: return false
        //         }
        //     }
        //     default: return false
        // }

    }

    setTree(data) {
        if (data.children != undefined) {
            return (
                <TreeItem key={data.name} nodeId={`${data.name}`} label={data.name}>
                    {data.children.map((child, i) => {
                        return this.setTree(child)
                    })}
                </TreeItem>
            )
        } else {
            return (
                <TreeItem key={data.name} nodeId={`${data.name}`} label={data.name} onClick={() => this.setInitialPage(data, data.parent)} />
            )
        }
    }

    render() {
        return (
            <div>
                <Mynavbar
                    goBack={() => this.props.goBack()}
                    searchapi={this.props.searchApi}
                    header={'Old Law'}
                    rootUrl={this.props.rootUrl}
                    userid={this.props.userid}
                    goHome={() => this.props.goBack()}
                />
                <div className="mainBox">
                    <div className="row">
                        {
                            this.state.lawData.map((data, index) => {
                                return (
                                    <div className="col-md-3 col-12">
                                        <TreeView
                                            key={data.name}
                                            aria-label="file system navigator"
                                            defaultCollapseIcon={<ExpandMoreIcon />}
                                            defaultExpandIcon={<ChevronRightIcon />}
                                            sx={{ height: "100%", flexGrow: 1, maxWidth: 400, overflowY: 'auto' }}
                                        >
                                            {/* <TreeItem key={data.name + index} nodeId={`${data.name}`} label={data.name}>
                                                {data.children.map((child, i) => {
                                                    return (
                                                        <TreeItem key={child.name + i} nodeId={`${child.name}`} label={child.name} onClick={() => this.setInitialPage(child, data.name)} />
                                                    )
                                                })}
                                            </TreeItem> */}
                                            {this.setTree(data)}
                                        </TreeView>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
                {
                    this.state.openCustomFrame ?
                        <ContentFrame
                            goBack={() => this.setState({ openCustomFrame: false })}
                            filepath={this.state.targetFile}
                            searchApi={this.props.searchApi}
                            rootUrl={this.props.rootUrl}
                            userid={this.props.userid}
                            MainFolder={this.state.mainFolder}
                            SubFolder={this.state.subfolder}
                            goHome={() => this.props.goBack()}
                        />
                        : null
                }
                {
                    this.state.openCircNoti &&
                    <Modal fullscreen={true} id="documentmodal" animation={true} show={this.state.openCircNoti} onHide={() => this.setState({ openCircNoti: false })}>
                        <Modal.Body>
                            <Searched
                                goBack={() => this.setState({ openCircNoti: false })}
                                filepath={''}
                                searchApi={this.props.searchApi}
                                rootUrl={this.props.rootUrl}
                                userid={this.props.userid}
                                MainFolder={''}
                                SubFolder={''}
                                goHome={() => this.props.goBack()}
                                isReferedByCircNoti={true}
                                clickedLink={this.state.targetFile}
                            />
                        </Modal.Body>
                    </Modal>
                }
            </div>
        )
    }
}
