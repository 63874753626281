import React, { Component } from 'react'
import Captcha from './Captcha';
import { Modal, Spinner } from 'react-bootstrap'
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { data } from 'jquery';
export default class CaptchaModal extends Component {
    constructor(props) {
      super(props)
      this.state = {
        isComponentRender: false
      }
    }
    componentDidMount() {
      this.setState({ isComponentRender: true ,Modalshow:true})
    }
    isMatched = (val) => {
        if(val === true){
            this.setState({ Modalshow: false })
            setTimeout(() => {
                this.props.Verification(val)
                this.props.onCloseModal()
              }, 500);
        }
        console.log(val)
        
    }
    render() {
      return (
        <>
          {
            this.state.isComponentRender ?
            <Modal id={'search_options_modal'} size="sm" centered style={{ backgroundColor: 'rgba(0,0,0,0.6)' }} show={this.state.Modalshow} onHide={() => this.props.onCloseModal()} keyboard={false} backdrop='static'>
                <Modal.Header>
                        <div className="header">
                            <span>Print</span>
                            <IconButton onClick={() => this.props.onCloseModal()}>
                            <CloseIcon />
                            </IconButton>
                        </div>
                </Modal.Header>
                <Modal.Body style={{ backgroundColor: "white" }}>
                    <Captcha isVerified={(val)=>this.isMatched(val)} />
                </Modal.Body>
            </Modal>
            :
            null
          }
        </>
      )
    }
  }

