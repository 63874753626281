import React from 'react'
import Select, { createFilter } from 'react-select'
import AsyncSelect from 'react-select/async';
import { FixedSizeList as List } from "react-window";

export default class ComboBox extends React.Component {
    // filterColors = (inputValue) => {
    //     return colourOptions.filter((i) =>
    //         i.label.toLowerCase().includes(inputValue.toLowerCase())
    //     );
    // }

    // loadOptions = (inputValue,callback) => {
    //     setTimeout(() => {
    //         callback(filterColors(inputValue));
    //     }, 1000);
    // }
    render() {
        return (
            <Select
                // filterOption={createFilter({ ignoreAccents: false })}
                // isLoading
                // components={{ MenuList }} 
                // cacheOptions 
                // defaultOptions
                // filterOption={createFilter({ignoreAccents:false})}
                // cacheOptions
                value={this.props.value}
                options={this.props.options}
                onChange={value => this.props.onChange(value)}
                getOptionLabel={this.props.getOptionLabel}
                getOptionValue={this.props.getOptionValue}
                placeholder={this.props.placeholder}
            />
        )
    }
}
// const height = 35;
// class MenuList extends React.Component {
//     render() {
//       const { options, children, maxHeight, getValue } = this.props;
//       const [value] = getValue();
//       const initialOffset = options.indexOf(value) * height;
  
//       return (
//         <List
//           height={maxHeight}
//           itemCount={children.length}
//           itemSize={height}
//           initialScrollOffset={initialOffset}
//         >
//           {({ index, style }) => <div style={style}>{children[index]}</div>}
//         </List>
//       );
//     }
//   }
