import React, { Component } from 'react'
import ContentFrame from './ContentFrame';


export default class GSTRates extends Component {
    constructor(props) {
        super(props)
        this.state = {
            openCustomFrame: false,
            targetFile: 'Assets/Data5t/gt/gttari/',
            subfolder: '', mainFolder: '',
            isOpenPPTModal: false
        }
    }
    componentDidMount() {
        this.setState({ openCustomFrame: true, subfolder: 'gttari', mainFolder: 'gt' })
    }
    render() {
        return (
            <div>
                {
                    this.state.openCustomFrame ?
                        <ContentFrame
                            goBack={() => this.setState({ openCustomFrame: false }, this.props.goBack())}
                            filepath={this.state.targetFile + 'gst_tari_index.htm'}
                            searchApi={this.props.searchApi}
                            rootUrl={this.props.rootUrl}
                            userid={this.props.userid}
                            MainFolder={this.state.mainFolder}
                            SubFolder={this.state.subfolder}
                            // header={'GST Rates'}
                            goHome={() => this.props.goBack()}
                        />
                        : null
                }
            </div>
        )
    }
}
