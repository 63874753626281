import React, { Component } from 'react'
import Classes from './style.module.css'
export default class Checklist extends Component {
    constructor(props) {
        super(props)
        this.state = {
            defVal: [],
            value: [], isUpdating: false,
            removeall: true, options: false,
            allSelected: true,
            filterSelected: this.props.filterSelected, 
            filteredData: this.props.filteredData
        }
        this.storeValue = this.storeValue.bind(this)
    }


    componentDidMount() {
        // console.log(this.props.filterData)
        const getValues = (data) => {
            // console.log(data)
            var values = data
            if (values.includes(',')) {
                if (values.indexOf(',') > -1) {
                    var arr = values.split(',')
                    return arr
                }
            } else {
                var arr = []
                arr.push(values)
                // console.log(arr)
                return arr
            }
        }
        const getDefaultValuesArr = (data) => {
            var values = data
            if (values.includes(',')) {
                if (values.indexOf(',') > -1) {
                    var arr = values.split(',')
                    return arr
                }
            } else {
                var arr = []
                arr.push(values)
                // console.log(arr)
                return arr
            }
        }

        this.setState({
            defVal: getDefaultValuesArr(this.props.defdata),
            value: getValues(this.props.filterData)
        }, () => {
            this.setState({ isUpdationDone: true })
        })
    }
    componentDidUpdate(prevProps) {
        // console.log(this.props.filterData)
        if (prevProps.filterData != this.props.filterData || prevProps.defdata != this.props.defdata) {
            this.setState({ isUpdationDone: false })
            const getValues = (data) => {
                // console.log(data)
                var values = data
                if (values.includes(',')) {
                    if (values.indexOf(',') > -1) {
                        var arr = values.split(',')
                        return arr
                    }
                } else {
                    var arr = []
                    arr.push(values)
                    // console.log(arr)
                    return arr
                }
            }
            const getDefaultValuesArr = (data) => {
                var values = data
                if (values.includes(',')) {
                    if (values.indexOf(',') > -1) {
                        var arr = values.split(',')
                        return arr
                    }
                } else {
                    var arr = []
                    arr.push(values)
                    // console.log(arr)
                    return arr
                }
            }

            this.setState({
                defVal: getDefaultValuesArr(this.props.defdata),
                value: getValues(this.props.filterData)
            }, () => {
                this.setState({ isUpdationDone: true })
            })
        }
    }

    checkedall = () => {
        console.log(this.props.perdata)
        let arr = []
        const getDefaultValuesArr = (data) => {
            var values = data
            console.log(values)

            if (values.includes(',')) {
                if (values.indexOf(',') > -1) {
                    arr = values.split(',')
                    return arr
                }
            }
            else {
                // var arr = []
                arr.push(values)
                // console.log(arr)
                return arr
            }
        }
        this.setState({
            value: getDefaultValuesArr(this.props.perdata)
        });
        this.props.onChange(arr.join(','), 'checkall')

    };

    storeValue(e) {
        var arr = [];
        if (this.state.defVal != undefined) {
            arr = this.state.defVal;
        }
        if (e.target.checked == true) {
            arr.push(e.target.value)
        } else {
            arr.splice(arr.indexOf(e.target.value), 1);
        }
        console.log(arr)
        this.props.onChange(arr.join(','))

    }

    checkFilterButton() {
        // if (this.props.filteredData[0].def[this.props.filterSelected] != '' && this.props.filteredData[0].def[this.props.filterSelected] != ',') {
        let perDataArr = this.props.perdata.split(',').sort();    
        console.log(this.props.filteredData)
        let filDataArr =  this.props.filteredData[0].def[this.props.filterSelected].split(',').sort();

        if (perDataArr.indexOf('') != -1) {
            perDataArr.shift();
        }
        if (filDataArr.indexOf('') != -1) {
            filDataArr.shift();
        }
        // console.log('PerData:', perDataArr);
        // console.log('FilData:', filDataArr);
        if (JSON.stringify(perDataArr) !== JSON.stringify(filDataArr)) {
            if (this.state.allSelected) 
                this.setState({allSelected: false});
        } else {
            if (!this.state.allSelected) 
                this.setState({allSelected: true});
        }
        return (this.state.allSelected); 
    }

    render() {
        // console.log(this.props)
        const getID = (val) => {
            if (val.indexOf(' ') > -1) {
                return val.replace(' ', '') + (new Date()).getTime()
            } else {
                return val + (new Date()).getTime()
            }
        }
        return (
            <>
                {
                    this.state.isUpdationDone ?

                        <div
                            className={'row ' + Classes.overflow}
                            style={{ maxHeight: "370px", overflow: "auto" }}>
                            {/* <div className="col-md-10" style={{ display: 'flex', justifyContent: 'space-between' }}>

                                <button className="col-md-4" onClick={() => this.checkedall()} style={{ display: 'flex', alignItems: "center", marginBottom: "5px", border: 'none', backgroundColor: 'transparent' }}>
                                    <span style={{ border: '2px solid black', borderRadius: '5px', color: 'white', backgroundColor: '#161616' }}>
                                        Check All
                                    </span>
                                </button>
                                <button className="col-md-5" onClick={() => { if (this.state.removeall == true) { this.setState({ value: [] }) }; this.setState({ removeall: false }); this.props.onChange(''); }} style={{ display: 'flex', alignItems: "center", marginBottom: "5px", border: 'none', backgroundColor: 'transparent' }}>
                                    <span style={{ border: '2px solid black', borderRadius: '5px', color: 'white', backgroundColor: '#161616' }}>
                                        Uncheck All
                                    </span>
                                </button>
                            </div> */}
                            <div className="col-md-10" style={{ display: 'flex', justifyContent: 'space-between'}}>
                                {this.checkFilterButton() ? 
                                <button className="col-md-5" onClick={() => { 
                                    if (this.state.removeall == true) { 
                                        this.setState({ value: [] }) 
                                    }
                                    this.setState({ removeall: false});
                                    this.props.onChange('');
                                    }} 
                                    style={{ display: 'flex', alignItems: "center", marginBottom: "5px", border: 'none', backgroundColor: 'transparent' }}>
                                    <span style={{ border: '2px solid black', borderRadius: '5px', color: 'white', backgroundColor: '#161616', padding: '0px 2px 0px 2px', marginTop: '2px' }}>
                                        Remove All
                                    </span>
                                </button>
                                :
                                <button className="col-md-4" onClick={() => {this.checkedall();}} style={{ display: 'flex', alignItems: "center", marginBottom: "5px", border: 'none', backgroundColor: 'transparent' }}>
                                    <span style={{ border: '2px solid black', borderRadius: '5px', color: 'white', backgroundColor: '#161616', padding: '0px 2px 0px 2px', marginTop: '2px' }}>
                                        Select All
                                    </span>
                                </button>
                                }
                            </div>

                            {
                                this.state.value.map((val, index) => {
                                    var check = false
                                    if (this.state.defVal != undefined) {
                                        for (let i = 0; i < this.state.defVal.length; i++) {
                                            if (this.state.defVal[i] == val) {
                                                check = true
                                                break
                                            }
                                        }
                                    }
                                    // console.log(val, check)

                                    return (
                                        <>
                                            <div className="col-md-6" key={index} style={{ display: 'flex', alignItems: "center", marginBottom: "5px" }}>
                                                <input type="checkbox" id={getID(val)} value={val} name={val} defaultChecked={check} onChange={(e) => this.storeValue(e)} style={{ width: "20px", height: "20px" }} />
                                                <label htmlFor={getID(val)} style={{ paddingLeft: "10px", cursor: "pointer" }}>{val}</label>
                                            </div>
                                        </>
                                    )
                                })
                            }
                        </div>
                        :
                        "loading"
                }
            </>
        )
    }
}
