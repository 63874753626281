
import React, { Component } from 'react'
import ReactDOM from 'react-dom';
import IconButton from '@mui/material/IconButton';
// import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Modal, Spinner } from 'react-bootstrap'
import Mynavbar from './Mynavbar';
import Searched from './Searched'
import { Aesencryption } from '../../usefulcomps/Aes/Aes';
import axios from 'axios'
import Hexapi from '../../usefulcomps/HexAPI/Hexapi';
import CloseIcon from '@mui/icons-material/Close';
import SlideshowIcon from '@mui/icons-material/Slideshow';
import HighlightOutlinedIcon from '@mui/icons-material/HighlightOutlined';
import HighlightIcon from '@mui/icons-material/Highlight';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
// import AutoFixOffIcon from '@mui/icons-material/AutoFixOff';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import BookmarkBorderRoundedIcon from '@mui/icons-material/BookmarkBorderRounded';
import SearchIcon from '@mui/icons-material/Search';
import Tooltip from '@mui/material/Tooltip';
import $, { data, htmlPrefilter } from 'jquery'
import QueryStatsOutlinedIcon from '@mui/icons-material/QueryStatsOutlined';
import Hsnsearch from './Hsnsearch';
import Dictionary from './Dictionary';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import HistoryIcon from '@mui/icons-material/History';
import HistoryKeeping from './HistoryKeeping';
import NotesIcon from '@mui/icons-material/Notes';
import Notes from './Notes';
import SummarizeIcon from '@mui/icons-material/Summarize';
import Summary from './Summary';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import Lawasondate from './Lawasondate';
import PublicIcon from '@mui/icons-material/Public';
import Comparelaws from './Comparelaws';
import HomeIcon from '@mui/icons-material/Home';
import Magicwond from './Magicwond';
import { styled } from '@mui/material/styles';
import Switch, { SwitchProps } from '@mui/material/Switch';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import BookmarksIcon from '@mui/icons-material/Bookmarks';
import BookmarkKeeping from './BookmarkKeeping'
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness4OutlinedIcon from '@mui/icons-material/Brightness4Outlined';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import PrintIcon from '@mui/icons-material/Print';
import Captcha from '../../usefulcomps/Captcha/CaptchaModal'
import App from '../../App';
import Swal from 'sweetalert2';
// import AbcIcon from '@mui/icons-material/Abc';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import CaselawExclamation from './CaselawExclamation2';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import PsychologySharpIcon from '@mui/icons-material/PsychologySharp';
import SummarizeSharpIcon from '@mui/icons-material/SummarizeSharp';
import AccountTreeSharpIcon from '@mui/icons-material/AccountTreeSharp';
import CalculateIcon from '@mui/icons-material/Calculate';
import Magicwondframes from './Magicwondframes';
import UtilityFrame from './UtilityFrame';
import FiberManualRecordOutlinedIcon from '@mui/icons-material/FiberManualRecordOutlined';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { TimerSharp } from '@material-ui/icons';


const min = (a, b) => {
    if (a < b) {
        return a
    }
    return b
}
const max = (a, b) => {
    if (a > b) {
        return a
    }
    return b
}


const MaterialUISwitch = styled(Switch)(({ theme }) => ({
    width: 62,
    height: 34,
    padding: 7,
    '& .MuiSwitch-switchBase': {
        margin: 1,
        padding: 0,
        transform: 'translateX(6px)',
        '&.Mui-checked': {
            color: '#fff',
            transform: 'translateX(22px)',
            '& .MuiSwitch-thumb:before': {
                backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
                    '#fff',
                )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
            },
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
            },
        },
    },
    '& .MuiSwitch-thumb': {
        backgroundColor: theme.palette.mode === 'dark' ? '#003892' : '#001e3c',
        width: 32,
        height: 32,
        '&:before': {
            content: "''",
            position: 'absolute',
            width: '100%',
            height: '100%',
            left: 0,
            top: 0,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
                '#fff',
            )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
        },
    },
    '& .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
        borderRadius: 20 / 2,
    },
}));


export default class ContentFrame extends Component {
    constructor(props) {
        super(props)
        this.history = []
        this.currentPagePosition = 0
        this.state = {
            userid: '', filepath: '', isFileFound: false, isBookmarkAdded: false,
            isModalOpen: true, isOpenSearchModal: false, htmlFileOriginalContent: '',
            htmlFileShowContent: '', isFetchFileApiHitComplete: false,
            allStarts: '', allEnds: '', highlightColor: 'lightgreen', startEndArray: [], isHelldodVisible: false,
            isOpenPPTModal: false, summaryData: null,utilityheading:'',
            constantFilePath: `${sessionStorage.getItem('url')}Assets/data5t/`, OpenSearchModal: false,
            isOpenHSNSearch: false, isReferedByHsn: false, hsnSearchWord: '', hsnPath: '', isOpenDictModal: false,
            isOpenHistoryModal: false, isReferedByHistory: false, historyFileData: [], isOpenSummary: false, isOpenLawAsOnDateModal: false, isOpenCompareLaws: false, isMagicWondOpen: false,
            pageData: [], isDarkMode: false, zoomFrame: 1, pageTitle: '', isOpenBookmarkModal: false, //isOpenNotesModal: false, usernotes: '',
            isOpenInIframe: false, isOpenInHouseDevelopment: false, isReferedByCaseStatus: false, forIllustration: '',
            isIframeDarkMode: false, showdisplayicons: true, isOpenClExclamation: false, openutilities: false, fileData: {},
            utilfaq: false, utilInterpretation: false, utilIllustration: false, utilFlowchar: false, isOpenMagicwondFrame: false,
            header: '', frameData: null, utilSummary: false, utilityMainfolder: '', utilityFilename: '', utilitySubfolder: '',
            itemclicked1: false, itemclicked2: false, itemclicked3: false, showDemo: false, itemclicked4: false, showDemo2: false,
            content1: false, itemclicked5: false, itemclicked6: false, showIllustrationtext: '', startDemo2: false,markIllustration:false,
            caseillustration1:false,showcaseDemo:false,Addmargin:false,showLoading:false,utilityOpen:false
        }

        this.read_without_helldod = this.read_without_helldod.bind(this)
        this.read_with_helldod = this.read_with_helldod.bind(this)
        this.fetchFileContent = this.fetchFileContent.bind(this)
        this.fetchPageData = this.fetchPageData.bind(this)
        this.autoHighlight = this.autoHighlight.bind(this)
        this.correctHighlight = this.correctHighlight.bind(this)
        this.checkClash = this.checkClash.bind(this)
        this.getSelectedText = this.getSelectedText.bind(this)
        this.removeSelectedText = this.removeSelectedText.bind(this)
        this.addBookmark = this.addBookmark.bind(this)
    }


    trackHistory(filepath) {
        // if(this.history.includes(filepath)){
        //     this.history = this.history.filter((el,i) => i<this.history.indexOf(filepath)+1)
        // }
        // if(this.currentPagePosition < this.history.length){
        // console.log(this.currentPagePosition)
        // console.log(this.history)
        // this.history = this.history.filter((el,i) => i<this.currentPagePosition+1)
        // // this.history.push(filepath)
        // this.currentPagePosition = this.history.length-1
        // console.log('==================================================')
        // console.log('less than')
        // console.log(this.history)
        // console.log(this.currentPagePosition)
        // console.log('==================================================')
        // }else{
        if (this.history.includes(filepath)) {
            this.history = this.history.filter((f) => f != filepath)
            this.history.push(filepath)
        } else {
            this.history.push(filepath)
        }
        this.currentPagePosition++
        console.log('==================================================')
        console.log(this.history)
        console.log(this.currentPagePosition)
        console.log('==================================================')
        // }
    }


    goBackward_CustomHistory() {
        // let fpath = this.history.
        console.log(this.currentPagePosition)
        if (this.currentPagePosition > 1) {
            console.log(this.currentPagePosition, this.history)
            this.currentPagePosition--
            let fpath = this.history[this.currentPagePosition - 1]
            console.log('==========================================')
            console.log(this.currentPagePosition, this.history)
            console.log('==========================================')
            if (fpath != undefined) {
                this.setState({ filepath: fpath })

                let url = sessionStorage.getItem('url') + 'fetchfile.php',
                    userid = this.props.userid,
                    mainfolder = fpath.split('/')[2] + '/',
                    subfolder = fpath.split('/')[3] + '/',
                    scope = this
                console.log(mainfolder)
                console.log(subfolder)
                const anchorTags = (fpath) => {
                    this.fetchFileContent(fpath, url).then(() => {
                        this.fetchPageData(userid, fpath).then(() => {
                            this.autoHighlight(userid, fpath).then(() => {
                                if (document.getElementById('mainboxdiv') && document.getElementById('mainnavbar')) {
                                    let mainnvabarHeight = document.getElementById('mainnavbar').clientHeight + 1
                                    let o = document.getElementById('mainboxdiv').clientHeight
                                    console.log(mainnvabarHeight, o)
                                    document.getElementById("mainboxdiv").style.height = `calc(100% - ${mainnvabarHeight}px)`
                                }
                                // 
                                // ========================================================================================================
                                // This code used to replace the anchor tag href attribute url
                                // And also open then in new tab 
                                $('#SinglePageIframeSearched').find('a').each(function () {
                                    let href = $(this).attr('href')
                                    let isJsFun = false,
                                        isSamePageLink = false
                                    if (href != undefined && href.toString().toLowerCase().includes('javascript:void(0)')) {
                                        isJsFun = true
                                    }
                                    if (href != undefined && href.includes('#')) {
                                        isSamePageLink = true
                                    }
                                    let file_n = href
                                    // console.log(isJsFun)
                                    if (href != undefined && !isJsFun) {
                                        $(this).mousedown(function (e) {
                                            if (e.button == 2) {
                                                let r_href = href
                                                if (r_href.includes('../../')) {
                                                    r_href = r_href.replace('../../', '')
                                                } else if (r_href.includes('../')) {
                                                    r_href = r_href.replace('../', mainfolder)
                                                    subfolder = r_href.split(mainfolder)[1].substring(0, r_href.split(mainfolder)[1].indexOf('/')) + '/'
                                                } else {
                                                    r_href = mainfolder + subfolder + r_href
                                                }
                                                let temp_url = 'Assets/data5t/' + r_href
                                                // console.log(mainfolder, subfolder, temp_url)
                                                const isPPT = ['.pptx', '.ppt'].some((el) => temp_url.toLowerCase().includes(el))
                                                if (!isPPT) {
                                                    // window.open(
                                                    //     'https://hexbss.xyz/lawcrux/lawcrux/web/'+temp_url,
                                                    //     '_blank'
                                                    // )
                                                    $(this).attr('href', sessionStorage.getItem('url') + temp_url)
                                                }
                                            }
                                        });
                                        $(this).on('click', (e) => {
                                            mainfolder = fpath.split('/')[2] + '/';
                                            subfolder = fpath.split('/')[3] + '/';
                                            console.log(e)
                                            let l_href = href
                                            if (l_href.includes('../../')) {
                                                l_href = l_href.replace('../../', '')
                                            } else if (l_href.includes('../')) {
                                                l_href = l_href.replace('../', mainfolder)
                                                subfolder = l_href.split(mainfolder)[1].substring(0, l_href.split(mainfolder)[1].indexOf('/')) + '/'
                                            } else {
                                                l_href = mainfolder + subfolder + l_href
                                            }
                                            let temp_url = 'Assets/data5t/' + l_href
                                            console.log(mainfolder)
                                            console.log(subfolder)
                                            console.log(temp_url)
                                            const isPPT = ['.pptx', '.ppt'].some((el) => temp_url.toLowerCase().includes(el))
                                            if (!isSamePageLink) {
                                                $(this).attr('href', '#')
                                            }
                                            if (!temp_url.includes('#')) {
                                                if (isPPT) {
                                                    scope.setState({ isOpenSummary: true, summaryData: { foldername: mainfolder + subfolder, filename: file_n } })
                                                } else {
                                                    scope.setState({ filepath: temp_url, allEnds: "0", allStarts: '0', startEndArray: [] }, () => {
                                                        // scope.currentPagePosition = scope.history.length-1
                                                        scope.history = scope.history.filter((el, i) => i <= scope.currentPagePosition - 1)
                                                        console.log(scope.history)
                                                        scope.trackHistory(temp_url)
                                                        anchorTags(temp_url)
                                                    })
                                                }
                                            }
                                        })
                                    }
                                });
                                // ========================================================================================================

                                // ========================================================================================================
                                // This code used to correct the object tag data attribute url
                                // In frames
                                // $('#SinglePageIframeSearched').find('object').each(function () {
                                //     let href = $(this).attr('data')
                                //     let r_href = href
                                //     if (r_href.includes('../../')) {
                                //         r_href = r_href.replace('../../', '')
                                //     } else if (r_href.includes('../')) {
                                //         r_href = r_href.replace('../', mainfolder)
                                //         subfolder = r_href.split(mainfolder)[1].substring(0, r_href.split(mainfolder)[1].indexOf('/')) + '/'
                                //     } else {
                                //         r_href = mainfolder + subfolder + r_href
                                //     }
                                //     let temp_url = 'Assets/data5t/' + r_href
                                //     $(this).attr('data', sessionStorage.getItem('url') + temp_url)
                                // });
                                // ========================================================================================================
                            }).catch(err => console.error(err))
                        }).then(() => { this.setState({ isFileFound: true, isFetchFileApiHitComplete: true }) }).catch(err => console.error(err))
                    }).catch(err => this.setState({ isFileFound: false, isFetchFileApiHitComplete: true, isBookmarkAddComplete: true }, () => console.error(err)))
                }
                anchorTags(fpath)
            }
        }
    }

    goForward_CustomHistory() {
        console.log(this.currentPagePosition)
        if (this.currentPagePosition < this.history.length) {
            let fpath = this.history[this.currentPagePosition]
            // console.log(this.currentPagePosition, fpath)
            this.currentPagePosition++
            console.log('==========================================')
            console.log(this.currentPagePosition, this.history)
            console.log('==========================================')
            if (fpath != undefined) {
                this.setState({ filepath: fpath })

                let url = sessionStorage.getItem('url') + 'fetchfile.php',
                    userid = this.props.userid,
                    mainfolder = fpath.split('/')[2] + '/',
                    subfolder = fpath.split('/')[3] + '/',
                    scope = this
                console.log(mainfolder)
                console.log(subfolder)
                const anchorTags = (fpath) => {
                    this.fetchFileContent(fpath, url).then(() => {
                        this.fetchPageData(userid, fpath).then(() => {
                            this.autoHighlight(userid, fpath).then(() => {
                                if (document.getElementById('mainboxdiv') && document.getElementById('mainnavbar')) {
                                    let mainnvabarHeight = document.getElementById('mainnavbar').clientHeight + 1
                                    let o = document.getElementById('mainboxdiv').clientHeight
                                    console.log(mainnvabarHeight, o)
                                    document.getElementById("mainboxdiv").style.height = `calc(100% - ${mainnvabarHeight}px)`
                                }
                                // 
                                // ========================================================================================================
                                // This code used to replace the anchor tag href attribute url
                                // And also open then in new tab 
                                $('#SinglePageIframeSearched').find('a').each(function () {
                                    let href = $(this).attr('href')
                                    let isJsFun = false,
                                        isSamePageLink = false
                                    if (href != undefined && href.toString().toLowerCase().includes('javascript:void(0)')) {
                                        isJsFun = true
                                    }
                                    if (href != undefined && href.includes('#')) {
                                        isSamePageLink = true
                                    }
                                    let file_n = href
                                    // console.log(isJsFun)
                                    if (href != undefined && !isJsFun) {
                                        $(this).mousedown(function (e) {
                                            if (e.button == 2) {
                                                let r_href = href
                                                if (r_href.includes('../../')) {
                                                    r_href = r_href.replace('../../', '')
                                                } else if (r_href.includes('../')) {
                                                    r_href = r_href.replace('../', mainfolder)
                                                    subfolder = r_href.split(mainfolder)[1].substring(0, r_href.split(mainfolder)[1].indexOf('/')) + '/'
                                                } else {
                                                    r_href = mainfolder + subfolder + r_href
                                                }
                                                let temp_url = 'Assets/data5t/' + r_href
                                                console.log(mainfolder, subfolder, temp_url)
                                                const isPPT = ['.pptx', '.ppt'].some((el) => temp_url.toLowerCase().includes(el))
                                                if (!isPPT) {
                                                    // window.open(
                                                    //     'https://hexbss.xyz/lawcrux/lawcrux/web/'+temp_url,
                                                    //     '_blank'
                                                    // )
                                                    $(this).attr('href', sessionStorage.getItem('url') + temp_url)
                                                }
                                            }
                                        });
                                        $(this).on('click', (e) => {
                                            mainfolder = fpath.split('/')[2] + '/';
                                            subfolder = fpath.split('/')[3] + '/';
                                            console.log(e)
                                            let l_href = href
                                            if (l_href.includes('../../')) {
                                                l_href = l_href.replace('../../', '')
                                            } else if (l_href.includes('../')) {
                                                l_href = l_href.replace('../', mainfolder)
                                                subfolder = l_href.split(mainfolder)[1].substring(0, l_href.split(mainfolder)[1].indexOf('/')) + '/'
                                            } else {
                                                l_href = mainfolder + subfolder + l_href
                                            }
                                            let temp_url = 'Assets/data5t/' + l_href
                                            console.log(mainfolder)
                                            console.log(subfolder)
                                            console.log(temp_url)
                                            const isPPT = ['.pptx', '.ppt'].some((el) => temp_url.toLowerCase().includes(el))
                                            if (!isSamePageLink) {
                                                $(this).attr('href', '#')
                                            }
                                            if (!temp_url.includes('#')) {
                                                if (isPPT) {
                                                    scope.setState({ isOpenSummary: true, summaryData: { foldername: mainfolder + subfolder, filename: file_n } })
                                                } else {
                                                    scope.setState({ filepath: temp_url, allEnds: "0", allStarts: '0', startEndArray: [] }, () => {
                                                        // scope.currentPagePosition++
                                                        scope.history = scope.history.filter((el, i) => i <= scope.currentPagePosition - 1)
                                                        scope.trackHistory(temp_url)
                                                        anchorTags(temp_url)
                                                    })
                                                }
                                            }
                                        })
                                    }
                                });
                                // ========================================================================================================

                                // ========================================================================================================
                                // This code used to correct the object tag data attribute url
                                // In frames
                                // $('#SinglePageIframeSearched').find('object').each(function () {
                                //     let href = $(this).attr('data')
                                //     let r_href = href
                                //     if (r_href.includes('../../')) {
                                //         r_href = r_href.replace('../../', '')
                                //     } else if (r_href.includes('../')) {
                                //         r_href = r_href.replace('../', mainfolder)
                                //         subfolder = r_href.split(mainfolder)[1].substring(0, r_href.split(mainfolder)[1].indexOf('/')) + '/'
                                //     } else {
                                //         r_href = mainfolder + subfolder + r_href
                                //     }
                                //     let temp_url = 'Assets/data5t/' + r_href
                                //     $(this).attr('data', sessionStorage.getItem('url') + temp_url)
                                // });
                                // ========================================================================================================
                            }).catch(err => console.error(err))
                        }).then(() => { this.setState({ isFileFound: true, isFetchFileApiHitComplete: true }) }).catch(err => console.error(err))
                    }).catch(err => this.setState({ isFileFound: false, isFetchFileApiHitComplete: true, isBookmarkAddComplete: true }, () => console.error(err)))
                }
                anchorTags(fpath)
            }
        }
    }
    read_without_helldod(text) {
        this.autoHighlight(this.state.userid, this.state.filepath)

        let newText = text.replaceAll('�', '"')
        const removeHelldodv2 = (string, removeWord) => {
            let updatedString = string

            while (updatedString.indexOf(`${removeWord}`) > -1 && updatedString.indexOf('helldod]', updatedString.indexOf(`${removeWord}`))) {
                let endIndex = updatedString.indexOf('helldod]', updatedString.indexOf(`${removeWord}`));
                console.log(endIndex)
                let openAfterOprn = updatedString.indexOf(`${removeWord}`, updatedString.indexOf(`${removeWord}`) + 8);
                while (true) {
                    if (endIndex > openAfterOprn && openAfterOprn > -1) {
                        endIndex = updatedString.indexOf('helldod]', endIndex + 8);
                        openAfterOprn = updatedString.indexOf(`${removeWord}`, openAfterOprn + 8);
                    } else {
                        break;
                    }
                }
                if (endIndex == -1) {
                    console.log("null found")
                    endIndex = updatedString.indexOf('helldod]', updatedString.indexOf(`${removeWord}`));
                }
                //console.log(endIndex)
                let helldodPart = updatedString.substring(
                    updatedString.indexOf(`${removeWord}`),
                    endIndex + 8)
                console.log(helldodPart)
                updatedString = updatedString.replace(helldodPart, '')
            }


            // Formatting removes old, Old, 0ld, 0ld1, "old ", Old8

            // Format for "old"
            while (updatedString.indexOf('>old<') > -1 && updatedString.indexOf(']', updatedString.indexOf('>old<'))) {
                let openIndex = updatedString.indexOf(('['), updatedString.indexOf('>old<'));
                let endIndex = updatedString.indexOf(']', updatedString.indexOf('>old<'));
                if (openIndex == -1 || endIndex == -1) {
                    let oldPart = updatedString.substring(
                        updatedString.indexOf('>old<') - 7,
                        updatedString.indexOf('>old<') + 14
                    );
                    console.log(oldPart);
                    console.log(endIndex);
                    updatedString = updatedString.replace(oldPart, '');
                    continue;
                }

                let lastEndIndex = endIndex;
                let lastOpenIndex = 0;
                endIndex = openIndex;
                while (true) {
                    lastOpenIndex = openIndex;
                    openIndex = updatedString.indexOf(('['), openIndex + 1);
                    endIndex = updatedString.indexOf((']'), endIndex + 1);
                    // console.log(updatedString.substring(openIndex, openIndex + 10))
                    // console.log(updatedString.substring(endIndex - 10, endIndex))
                    if ((openIndex != -1) && (openIndex < endIndex)) {
                        // openIndex = endIndex;
                    } else if ((endIndex == -1)) {
                        break;
                    } else {
                        lastEndIndex = endIndex;
                        break;
                    }
                }

                if ((openIndex == -1) && (endIndex == -1)) {
                    let oldPart = updatedString.substring(
                        updatedString.indexOf('>old<') - 7,
                        updatedString.indexOf('>old<') + 14
                    );
                    console.log(oldPart);
                    updatedString = updatedString.replace(oldPart, '');
                    continue;
                }

                let oldPart = updatedString.substring(
                    updatedString.indexOf('>old<') - 7,
                    lastEndIndex + 1
                );
                console.log(oldPart);
                console.log(endIndex);
                updatedString = updatedString.replace(oldPart, '');
            }

            // Format for "old "
            while (updatedString.indexOf('>old <') > -1 && updatedString.indexOf(']', updatedString.indexOf('>old <'))) {
                let openIndex = updatedString.indexOf(('['), updatedString.indexOf('>old <'));
                let endIndex = updatedString.indexOf(']', updatedString.indexOf('>old <'));
                if (openIndex == -1 || endIndex == -1) {
                    let oldPart = updatedString.substring(
                        updatedString.indexOf('>old <') - 7,
                        updatedString.indexOf('>old <') + 14
                    );
                    console.log(oldPart);
                    console.log(endIndex);
                    updatedString = updatedString.replace(oldPart, '');
                    continue;
                }

                let lastEndIndex = endIndex;
                let lastOpenIndex = 0;
                endIndex = openIndex;
                while (true) {
                    lastOpenIndex = openIndex;
                    openIndex = updatedString.indexOf(('['), openIndex + 1);
                    endIndex = updatedString.indexOf((']'), endIndex + 1);
                    if ((openIndex != -1) && (openIndex < endIndex)) {
                        // openIndex = endIndex;
                    } else if ((endIndex == -1)) {
                        break;
                    } else {
                        lastEndIndex = endIndex;
                        break;
                    }
                }

                if ((openIndex == -1) && (endIndex == -1)) {
                    let oldPart = updatedString.substring(
                        updatedString.indexOf('>old <') - 7,
                        updatedString.indexOf('>old <') + 14
                    );
                    console.log(oldPart);
                    updatedString = updatedString.replace(oldPart, '');
                    continue;
                }

                let oldPart = updatedString.substring(
                    updatedString.indexOf('>old <') - 7,
                    lastEndIndex + 1
                );
                console.log(oldPart);
                console.log(endIndex);
                updatedString = updatedString.replace(oldPart, '');
            }

            // Format for " old"
            while (updatedString.indexOf('> old<') > -1 && updatedString.indexOf(']', updatedString.indexOf('> old<'))) {
                let openIndex = updatedString.indexOf(('['), updatedString.indexOf('> old<'));
                let endIndex = updatedString.indexOf(']', updatedString.indexOf('> old<'));
                if (openIndex == -1 || endIndex == -1) {
                    let oldPart = updatedString.substring(
                        updatedString.indexOf('> old<') - 4,
                        updatedString.indexOf('> old<') + 10
                    );
                    console.log(oldPart);
                    console.log(endIndex);
                    updatedString = updatedString.replace(oldPart, '');
                    continue;
                }

                let lastEndIndex = endIndex;
                let lastOpenIndex = 0;
                endIndex = openIndex;
                while (true) {
                    lastOpenIndex = openIndex;
                    openIndex = updatedString.indexOf(('['), openIndex + 1);
                    endIndex = updatedString.indexOf((']'), endIndex + 1);
                    if ((openIndex != -1) && (openIndex < endIndex)) {
                        // openIndex = endIndex;
                    } else if ((endIndex == -1)) {
                        break;
                    } else {
                        lastEndIndex = endIndex;
                        break;
                    }
                }

                if ((openIndex == -1) && (endIndex == -1)) {
                    let oldPart = updatedString.substring(
                        updatedString.indexOf('> old<') - 4,
                        updatedString.indexOf('> old<') + 10
                    );
                    console.log(oldPart);
                    updatedString = updatedString.replace(oldPart, '');
                    continue;
                }

                let oldPart = updatedString.substring(
                    updatedString.indexOf('> old<') - 4,
                    lastEndIndex + 1
                );
                console.log(oldPart);
                console.log(endIndex);
                updatedString = updatedString.replace(oldPart, '');
            }

            // Formatting for "Old"
            while (updatedString.indexOf('>Old<') > -1 && updatedString.indexOf(']', updatedString.indexOf('>Old<'))) {
                let openIndex = updatedString.indexOf(('['), updatedString.indexOf('>Old<'));
                let endIndex = updatedString.indexOf(']', updatedString.indexOf('>Old<'));
                if (openIndex == -1 || endIndex == -1) {
                    let oldPart = updatedString.substring(
                        updatedString.indexOf('>Old<') - 7,
                        updatedString.indexOf('>Old<') + 14
                    );
                    console.log(oldPart);
                    console.log(endIndex);
                    updatedString = updatedString.replace(oldPart, '');
                    continue;
                }

                let lastEndIndex = endIndex;
                let lastOpenIndex = 0;
                endIndex = openIndex;
                while (true) {
                    lastOpenIndex = openIndex;
                    openIndex = updatedString.indexOf(('['), openIndex + 1);
                    endIndex = updatedString.indexOf((']'), endIndex + 1);
                    if ((openIndex != -1) && (openIndex < endIndex)) {
                        // openIndex = endIndex;
                    } else if ((endIndex == -1)) {
                        break;
                    } else {
                        lastEndIndex = endIndex;
                        break;
                    }
                }

                if ((openIndex == -1) && (endIndex == -1)) {
                    let oldPart = updatedString.substring(
                        updatedString.indexOf('>Old<') - 7,
                        updatedString.indexOf('>Old<') + 14
                    );
                    console.log(oldPart);
                    updatedString = updatedString.replace(oldPart, '');
                    continue;
                }

                let oldPart = updatedString.substring(
                    updatedString.indexOf('>Old<') - 7,
                    lastEndIndex + 1
                );
                console.log(oldPart);
                console.log(endIndex);
                updatedString = updatedString.replace(oldPart, '');
            }

            // Formatting for "Old8"
            while (updatedString.indexOf('>Old8<') > -1 && updatedString.indexOf(']', updatedString.indexOf('>Old<'))) {
                let openIndex = updatedString.indexOf(('['), updatedString.indexOf('>Old8<'));
                let endIndex = updatedString.indexOf(']', updatedString.indexOf('>Old8<'));
                if (openIndex == -1 || endIndex == -1) {
                    let oldPart = updatedString.substring(
                        updatedString.indexOf('>Old8<') - 4,
                        updatedString.indexOf('>Old8<') + 10
                    );
                    console.log(oldPart);
                    console.log(endIndex);
                    updatedString = updatedString.replace(oldPart, '');
                    continue;
                }

                let lastEndIndex = endIndex;
                let lastOpenIndex = 0;
                endIndex = openIndex;
                while (true) {
                    lastOpenIndex = openIndex;
                    openIndex = updatedString.indexOf(('['), openIndex + 1);
                    endIndex = updatedString.indexOf((']'), endIndex + 1);
                    if ((openIndex != -1) && (openIndex < endIndex)) {
                        // openIndex = endIndex;
                    } else if ((endIndex == -1)) {
                        break;
                    } else {
                        lastEndIndex = endIndex;
                        break;
                    }
                }

                if ((openIndex == -1) && (endIndex == -1)) {
                    let oldPart = updatedString.substring(
                        updatedString.indexOf('>Old8<') - 4,
                        updatedString.indexOf('>Old8<') + 10
                    );
                    console.log(oldPart);
                    updatedString = updatedString.replace(oldPart, '');
                    continue;
                }

                let oldPart = updatedString.substring(
                    updatedString.indexOf('>Old8<') - 4,
                    lastEndIndex + 1
                );
                console.log(oldPart);
                console.log(endIndex);
                updatedString = updatedString.replace(oldPart, '');
            }

            // Formatting for "0ld"
            while (updatedString.indexOf('>0ld<') > -1 && updatedString.indexOf(']', updatedString.indexOf('>0ld<'))) {
                let openIndex = updatedString.indexOf(('['), updatedString.indexOf('>0ld<'));
                let endIndex = updatedString.indexOf(']', updatedString.indexOf('>0ld<'));
                if (openIndex == -1 || endIndex == -1) {
                    let oldPart = updatedString.substring(
                        updatedString.indexOf('>0ld<') - 7,
                        updatedString.indexOf('>0ld<') + 14
                    );
                    console.log(oldPart);
                    console.log(endIndex);
                    updatedString = updatedString.replace(oldPart, '');
                    continue;
                }

                let lastEndIndex = endIndex;
                let lastOpenIndex = 0;
                endIndex = openIndex;
                while (true) {
                    lastOpenIndex = openIndex;
                    openIndex = updatedString.indexOf(('['), openIndex + 1);
                    endIndex = updatedString.indexOf((']'), endIndex + 1);
                    if ((openIndex != -1) && (openIndex < endIndex)) {
                        // openIndex = endIndex;
                    } else if ((endIndex == -1)) {
                        break;
                    } else {
                        lastEndIndex = endIndex;
                        break;
                    }
                }

                if ((openIndex == -1) && (endIndex == -1)) {
                    let oldPart = updatedString.substring(
                        updatedString.indexOf('>0ld<') - 7,
                        updatedString.indexOf('>0ld<') + 14
                    );
                    console.log(oldPart);
                    updatedString = updatedString.replace(oldPart, '');
                    continue;
                }

                let oldPart = updatedString.substring(
                    updatedString.indexOf('>0ld<') - 7,
                    lastEndIndex + 1
                );
                console.log(oldPart);
                console.log(endIndex);
                updatedString = updatedString.replace(oldPart, '');
            }

            // Formatting for "0ld1"
            while (updatedString.indexOf('>0ld1<') > -1 && updatedString.indexOf(']', updatedString.indexOf('>0ld1<'))) {
                let openIndex = updatedString.indexOf(('['), updatedString.indexOf('>0ld1<'));
                let endIndex = updatedString.indexOf(']', updatedString.indexOf('>0ld1<'));
                if (openIndex == -1 || endIndex == -1) {
                    let oldPart = updatedString.substring(
                        updatedString.indexOf('>0ld1<') - 7,
                        updatedString.indexOf('>0ld1<') + 11
                    );
                    console.log(oldPart);
                    console.log(endIndex);
                    updatedString = updatedString.replace(oldPart, '');
                    continue;
                }

                let lastEndIndex = endIndex;
                let lastOpenIndex = 0;
                endIndex = openIndex;
                while (true) {
                    lastOpenIndex = openIndex;
                    openIndex = updatedString.indexOf(('['), openIndex + 1);
                    endIndex = updatedString.indexOf((']'), endIndex + 1);
                    if ((openIndex != -1) && (openIndex < endIndex)) {
                        // openIndex = endIndex;
                    } else if ((endIndex == -1)) {
                        break;
                    } else {
                        lastEndIndex = endIndex;
                        break;
                    }
                }

                if ((openIndex == -1) && (endIndex == -1)) {
                    let oldPart = updatedString.substring(
                        updatedString.indexOf('>0ld1<') - 7,
                        updatedString.indexOf('>0ld1<') + 11
                    );
                    console.log(oldPart);
                    updatedString = updatedString.replace(oldPart, '');
                    continue;
                }

                let oldPart = updatedString.substring(
                    updatedString.indexOf('>0ld1<') - 7,
                    lastEndIndex + 1
                );
                console.log(oldPart);
                console.log(endIndex);
                updatedString = updatedString.replace(oldPart, '');
            }


            //updatedString = updatedString.replace(`[helldod<SUP>old</SUP></FONT></SUP></B><font face="Arial" size="2">[Articles of apparel and clothing accessories, knitted or crocheted, of sale value not exceeding Rs. 1000 per piece]<B><SUP>helldod]`,'shubhamsharma')

            return updatedString;

        }
        // const removeHelldodv2 = (string, removeWord) => {
        //     let updatedString = string

        //     while (updatedString.indexOf(`${removeWord}`) > -1 && updatedString.indexOf('helldod]', updatedString.indexOf(`${removeWord}`))) {
        //         let endIndex = updatedString.indexOf('helldod]', updatedString.indexOf(`${removeWord}`));
        //         console.log(endIndex)
        //         let openAfterOprn = updatedString.indexOf(`${removeWord}`, updatedString.indexOf(`${removeWord}`) + 8);
        //         while (true) {
        //             if (endIndex > openAfterOprn && openAfterOprn > -1) {
        //                 endIndex = updatedString.indexOf('helldod]', endIndex + 8);
        //                 openAfterOprn = updatedString.indexOf(`${removeWord}`, openAfterOprn + 8);
        //             } else {
        //                 break;
        //             }
        //         }
        //         if (endIndex == -1) {
        //             console.log("null found")
        //             endIndex = updatedString.indexOf('helldod]', updatedString.indexOf(`${removeWord}`));
        //         }
        //         //console.log(endIndex)
        //         let helldodPart = updatedString.substring(
        //             updatedString.indexOf(`${removeWord}`),
        //             endIndex + 8)
        //         console.log(helldodPart)
        //         updatedString = updatedString.replace(helldodPart, '')
        //     }

        //     while (updatedString.indexOf(`<sup>old</sup>`) > -1 && updatedString.indexOf(']', updatedString.indexOf(`<sup>old</sup>`))) {
        //         let endIndex = updatedString.indexOf(']', updatedString.indexOf(`<sup>old</sup>`));
        //         // console.log(endIndex)
        //         let openAfterOprn = updatedString.indexOf(`<sup>old</sup>`, updatedString.indexOf(`<sup>old</sup>`) + 8);
        //         while (true) {
        //             if (endIndex > openAfterOprn && openAfterOprn > -1) {
        //                 endIndex = updatedString.indexOf(']', endIndex + 8);
        //                 openAfterOprn = updatedString.indexOf(`<sup>old</sup>`, openAfterOprn + 14);
        //                 //console.log(`OpenAfterOpen = ${openAfterOprn} || endIndex = ${endIndex}`)
        //             } else {
        //                 break;
        //             }
        //         }
        //         if (endIndex == -1) {
        //             // console.log("null found")
        //             endIndex = updatedString.indexOf(']]', updatedString.indexOf(`<sup>old</sup>`));
        //         }
        //         let oldPart = updatedString.substring(
        //             updatedString.indexOf(`<sup>old</sup>`),
        //             endIndex + 14)
        //         updatedString = updatedString.replace(oldPart, '')

        //     }

        //     while (updatedString.indexOf(`<B><sup>0ld</sup>`) > -1 && updatedString.indexOf(']', updatedString.indexOf(`<B><sup>0ld</sup>`))) {
        //         let endIndex = updatedString.indexOf(']', updatedString.indexOf(`<B><sup>0ld</sup>`));
        //         // console.log(endIndex)
        //         let openAfterOprn = updatedString.indexOf(`<B><sup>0ld</sup>`, updatedString.indexOf(`<B><sup>0ld</sup>`) + 8);
        //         while (true) {
        //             if (endIndex > openAfterOprn && openAfterOprn > -1) {
        //                 endIndex = updatedString.indexOf(']', endIndex + 8);
        //                 openAfterOprn = updatedString.indexOf(`<B><sup>0ld</sup>`, openAfterOprn + 14);
        //                 //console.log(`OpenAfterOpen = ${openAfterOprn} || endIndex = ${endIndex}`)
        //             } else {
        //                 break;
        //             }
        //         }
        //         if (endIndex == -1) {
        //             // console.log("null found")
        //             endIndex = updatedString.indexOf(']]', updatedString.indexOf(`<B><sup>0ld</sup>`));
        //         }
        //         //console.log(endIndex)
        //         let oldPart = updatedString.substring(
        //             updatedString.indexOf(`<B><sup>0ld</sup>`),
        //             endIndex + 14)
        //         updatedString = updatedString.replace(oldPart, '')
        //         // console.log(updatedString)
        //     }

        //     while (updatedString.indexOf(`<B><sup>0ld1</sup>`) > -1 && updatedString.indexOf(']', updatedString.indexOf(`<B><sup>0ld1</sup>`))) {
        //         let endIndex = updatedString.indexOf(']', updatedString.indexOf(`<B><sup>0ld1</sup>`));
        //         // console.log(endIndex)
        //         let openAfterOprn = updatedString.indexOf(`<B><sup>0ld1</sup>`, updatedString.indexOf(`<B><sup>0ld1</sup>`) + 8);
        //         while (true) {
        //             if (endIndex > openAfterOprn && openAfterOprn > -1) {
        //                 endIndex = updatedString.indexOf(']', endIndex + 8);
        //                 openAfterOprn = updatedString.indexOf(`<B><sup>0ld1</sup>`, openAfterOprn + 14);
        //                 //console.log(`OpenAfterOpen = ${openAfterOprn} || endIndex = ${endIndex}`)
        //             } else {
        //                 break;
        //             }
        //         }
        //         if (endIndex == -1) {
        //             // console.log("null found")
        //             endIndex = updatedString.indexOf(']]', updatedString.indexOf(`<B><sup>0ld1</sup>`));
        //         }
        //         //console.log(endIndex)
        //         let oldPart = updatedString.substring(
        //             updatedString.indexOf(`<B><sup>0ld1</sup>`),
        //             endIndex + 14)
        //         updatedString = updatedString.replace(oldPart, '')
        //         // console.log(updatedString)
        //     }
        //     //updatedString = updatedString.replace(`[helldod<SUP>old</SUP></FONT></SUP></B><font face="Arial" size="2">[Articles of apparel and clothing accessories, knitted or crocheted, of sale value not exceeding Rs. 1000 per piece]<B><SUP>helldod]`,'shubhamsharma')

        //     return updatedString;

        // }

        newText = removeHelldodv2(newText, '[helldod')

        this.setState({
            // htmlFileShowContent: newText,
            htmlFileShowContentBackup: newText
        })
        this.setState({ htmlFileShowContent: newText }, () => {
            let scope = this,
                subfolder = this.props.SubFolder + '/',
                mainfolder = this.props.MainFolder + '/'
            $('#SinglePageIframeSearched').find('a').each(function () {
                let href = $(this).attr('href')
                let isJsFun = false,
                    isSamePageLink = false
                if (href != undefined && href.toString().toLowerCase().includes('javascript:void(0)')) {
                    isJsFun = true
                }
                if (href != undefined && href.includes('#')) {
                    isSamePageLink = true
                }
                let file_n = href
                if (href != undefined && !isJsFun) {
                    $(this).mousedown(function (e) {
                        if (e.button == 2) {
                            let r_href = href
                            if (r_href.includes('../../')) {
                                r_href = r_href.replace('../../', '')
                            } else if (r_href.includes('../')) {
                                r_href = r_href.replace('../', mainfolder)
                                subfolder = r_href.split(mainfolder)[1].substring(0, r_href.split(mainfolder)[1].indexOf('/')) + '/'
                            } else {
                                r_href = mainfolder + subfolder + r_href
                            }
                            let temp_url = 'Assets/data5t/' + r_href
                            console.log(mainfolder, subfolder, temp_url)
                            const isPPT = ['.pptx', '.ppt'].some((el) => temp_url.toLowerCase().includes(el))
                            if (!isPPT) {
                                // window.open(
                                //     'https://hexbss.xyz/lawcrux/lawcrux/web/'+temp_url,
                                //     '_blank'
                                // )
                                $(this).attr('href', sessionStorage.getItem('url') + temp_url)
                            }
                        }
                    });

                    $(this).on('click', (e) => {
                        console.log(mainfolder, subfolder)
                        let l_href = href
                        console.log(href)
                        if (l_href.includes('../../')) {
                            l_href = l_href.replace('../../', '')
                        } else if (l_href.includes('../')) {
                            l_href = l_href.replace('../', mainfolder)
                            subfolder = l_href.split(mainfolder)[1].substring(0, l_href.split(mainfolder)[1].indexOf('/')) + '/'
                        } else {
                            l_href = mainfolder + subfolder + l_href
                        }
                        let temp_url = 'Assets/data5t/' + l_href
                        console.log(mainfolder, subfolder, temp_url)
                        const isPPT = ['.pptx', '.ppt'].some((el) => temp_url.toLowerCase().includes(el))
                        if (!isSamePageLink) {
                            $(this).attr('href', '#')
                        }
                        if (!temp_url.includes('#')) {
                            if (isPPT) {
                                scope.setState({ isOpenSummary: true, summaryData: { foldername: mainfolder + subfolder, filename: file_n } })
                            } else {
                                scope.setState({ filepath: temp_url, allEnds: "0", allStarts: '0', startEndArray: [] }, () => {
                                    scope.anchorTags(temp_url)
                                })
                            }
                        }
                    })
                }
            })
        })
        // } else {
        //     this.setState({
        //         htmlFileShowContent: this.state.htmlFileShowContentBackup
        //     })
        // }
    }



    anchorTags = (fpath) => {
        console.log(fpath)
        let userid = this.props.userid,
            url = sessionStorage.getItem('url') + 'fetchfile.php',
            scope = this,
            subfolder = this.props.SubFolder + '/',
            mainfolder = this.props.MainFolder + '/'
        this.fetchFileContent(fpath, url).then(() => {
            this.fetchPageData(userid, fpath).then(() => {
                this.autoHighlight(userid, fpath).then(() => {
                    if (document.getElementById('mainboxdiv') && document.getElementById('mainnavbar')) {
                        let mainnvabarHeight = document.getElementById('mainnavbar').clientHeight + 1
                        let o = document.getElementById('mainboxdiv').clientHeight
                        console.log(mainnvabarHeight, o)
                        document.getElementById("mainboxdiv").style.height = `calc(100% - ${mainnvabarHeight}px)`
                    }
                    this.trackHistory(fpath)
                    // ========================================================================================================
                    // This code used to replace the anchor tag href attribute url
                    // And also open then in new tab 
                    $('#SinglePageIframeSearched').find('a').each(function () {

                        let href = $(this).attr('href')
                        let isJsFun = false,
                            isSamePageLink = false
                        if (href != undefined && href.toString().toLowerCase().includes('javascript:void(0)')) {
                            isJsFun = false
                            // $(this).attr('onclick','clickHandler();')
                            // const script = document.createElement("script");

                            // script.src = "https://use.typekit.net/foobar.js";
                            // script.async = true;

                            // document.body.appendChild(script);
                        }
                        // console.log(href)
                        if (href != undefined && href.includes('#')) {
                            isSamePageLink = true
                        }
                        let file_n = href
                        // console.log(isJsFun)
                        if (href != undefined && !isJsFun) {
                            $(this).mousedown(function (e) {
                                if (e.button == 2) {
                                    let r_href = href
                                    if (r_href.includes('../../')) {
                                        r_href = r_href.replace('../../', '')
                                    } else if (r_href.includes('../')) {
                                        r_href = r_href.replace('../', mainfolder)
                                        subfolder = r_href.split(mainfolder)[1].substring(0, r_href.split(mainfolder)[1].indexOf('/')) + '/'
                                    } else {
                                        r_href = mainfolder + subfolder + r_href
                                    }
                                    let temp_url = 'Assets/data5t/' + r_href
                                    console.log(mainfolder, subfolder, temp_url)
                                    const isPPT = ['.pptx', '.ppt'].some((el) => temp_url.toLowerCase().includes(el))
                                    if (!isPPT) {
                                        // window.open(
                                        //     'https://hexbss.xyz/lawcrux/lawcrux/web/'+temp_url,
                                        //     '_blank'
                                        // )
                                        $(this).attr('href', sessionStorage.getItem('url') + temp_url)
                                    }
                                }
                            });

                            $(this).on('click', (e) => {
                                mainfolder = fpath.split('/')[2] + '/';
                                subfolder = fpath.split('/')[3] + '/';
                                console.log(e)
                                let l_href = href
                                if (l_href.includes('../../')) {
                                    l_href = l_href.replace('../../', '')
                                } else if (l_href.includes('../')) {
                                    l_href = l_href.replace('../', mainfolder)
                                    subfolder = l_href.split(mainfolder)[1].substring(0, l_href.split(mainfolder)[1].indexOf('/')) + '/'
                                } else {
                                    l_href = mainfolder + subfolder + l_href
                                }
                                let temp_url = 'Assets/data5t/' + l_href
                                console.log(mainfolder, subfolder, temp_url)
                                const isPPT = ['.pptx', '.ppt'].some((el) => temp_url.toLowerCase().includes(el))
                                if (!isSamePageLink) {
                                    $(this).attr('href', '#')
                                }
                                if (!temp_url.includes('#')) {
                                    if (isPPT) {
                                        scope.setState({ isOpenSummary: true, summaryData: { foldername: mainfolder + subfolder, filename: file_n } })
                                    } else {
                                        scope.setState({ filepath: temp_url, allEnds: "0", allStarts: '0', startEndArray: [] }, () => {
                                            scope.anchorTags(temp_url)
                                        })
                                    }
                                }
                            })
                        }
                    });
                    // ========================================================================================================

                    // ========================================================================================================
                    // This code used to correct the object tag data attribute url
                    // In frames
                    // $('#SinglePageIframeSearched').find('object').each(function () {
                    //     let href = $(this).attr('data')
                    //     let r_href = href
                    //     if (r_href.includes('../../')) {
                    //         r_href = r_href.replace('../../', '')
                    //     } else if (r_href.includes('../')) {
                    //         r_href = r_href.replace('../', mainfolder)
                    //         subfolder = r_href.split(mainfolder)[1].substring(0, r_href.split(mainfolder)[1].indexOf('/')) + '/'
                    //     } else {
                    //         r_href = mainfolder + subfolder + r_href
                    //     }
                    //     let temp_url = 'Assets/data5t/' + r_href
                    //     $(this).attr('data', sessionStorage.getItem('url') + temp_url)
                    // });
                    // ========================================================================================================
                }).catch(err => console.error(err))
            }).then(() => { this.setState({ isFileFound: true, isFetchFileApiHitComplete: true }) }).catch(err => console.error(err))
        }).catch(err => this.setState({ isFileFound: false, isFetchFileApiHitComplete: true, isBookmarkAddComplete: true }, () => console.error(err)))
    }
    setLegalDict() {
        console.log('hello')
        this.setState({ showLoading: true })
        let obj = {
            query: `[dbo].[Lawcrux_Web_Proc_Dictionary_GetList] @UserID='{0}'`,
            queryArr: [`${sessionStorage.getItem('userid')}`]
        }
        Hexapi(obj).then(resp => {
            console.log(resp)
            // this.setState({ wordList: resp['']})
            let arr = []
            resp[''].forEach((el) => arr.push(el.word))
            let obj = {
                // filename: ['explanation','extract','obvious','offence'].join('##12##'),
                filename: arr.join('##12##'),
                url: 'findfilename.php'
            }
            Hexapi(obj).then(resp => {
                console.log(resp)
                this.setLegalDictLinks(resp)
                // this.props.onLegalDictClick(resp)
            }).then(()=>{
                this.setState({ showLoading: false })
            })
        }).catch(err => console.error(err))
    }
    setLegalDictLinks(words) {
        console.log(words)
        let content = this.state.htmlFileShowContent
        console.log(content.indexOf('abet'))
        words.forEach((file) => {
            content = content.toLowerCase().replaceAll(' ' + file.word.toLowerCase() + ' ', `<a href="${file.filepath.replace('Assets/Data5t/', '')}" onclick="return false"> <span style="background-color: #00FF99">${file.word}</span> </a>`)
        })
        console.log(content)
        this.setState({ htmlFileShowContent: content }, () => {
            let scope = this,
                subfolder = this.props.SubFolder + '/',
                mainfolder = this.props.MainFolder + '/'
            $('#SinglePageIframeSearched').find('a').each(function () {
                let href = $(this).attr('href')
                let isJsFun = false,
                    isSamePageLink = false
                if (href != undefined && href.toString().toLowerCase().includes('javascript:void(0)')) {
                    isJsFun = true
                }
                if (href != undefined && href.includes('#')) {
                    isSamePageLink = true
                }
                let file_n = href
                if (href != undefined && !isJsFun) {
                    $(this).mousedown(function (e) {
                        if (e.button == 2) {
                            let r_href = href
                            if (!r_href.includes('gtdifi')) {
                                if (r_href.includes('../../')) {
                                    r_href = r_href.replace('../../', '')
                                } else if (r_href.includes('../')) {
                                    r_href = r_href.replace('../', mainfolder)
                                    subfolder = r_href.split(mainfolder)[1].substring(0, r_href.split(mainfolder)[1].indexOf('/')) + '/'
                                } else {
                                    r_href = mainfolder + subfolder + r_href
                                }
                                let temp_url = 'Assets/data5t/' + r_href
                                console.log(mainfolder, subfolder, temp_url)
                                const isPPT = ['.pptx', '.ppt'].some((el) => temp_url.toLowerCase().includes(el))
                                if (!isPPT) {
                                    $(this).attr('href', sessionStorage.getItem('url') + temp_url)
                                }
                            } else {
                                $(this).attr('href', sessionStorage.getItem('url') + 'Assets/data5t/' + r_href)
                            }
                        }
                    });

                    $(this).on('click', (e) => {
                        console.log(e)
                        let l_href = href
                        if (!l_href.includes('gtdifi')) {
                            if (l_href.includes('../../')) {
                                l_href = l_href.replace('../../', '')
                            } else if (l_href.includes('../')) {
                                l_href = l_href.replace('../', mainfolder)
                                subfolder = l_href.split(mainfolder)[1].substring(0, l_href.split(mainfolder)[1].indexOf('/')) + '/'
                            } else {
                                l_href = mainfolder + subfolder + l_href
                            }
                            let temp_url = 'Assets/data5t/' + l_href
                            console.log(mainfolder, subfolder, temp_url)
                            const isPPT = ['.pptx', '.ppt'].some((el) => temp_url.toLowerCase().includes(el))
                            if (!isSamePageLink) {
                                $(this).attr('href', '#')
                            }
                            if (!temp_url.includes('#')) {
                                if (isPPT) {
                                    scope.setState({ isOpenSummary: true, summaryData: { foldername: mainfolder + subfolder, filename: file_n } })
                                } else {
                                    scope.setState({ filepath: temp_url, allEnds: "0", allStarts: '0', startEndArray: [] }, () => {
                                        scope.anchorTags(temp_url)
                                    })
                                }
                            }
                        } else {
                            scope.setState({ filepath: 'Assets/data5t/' + l_href, allEnds: "0", allStarts: '0', startEndArray: [] }, () => {
                                scope.anchorTags('Assets/data5t/' + l_href)
                            })
                        }
                    })
                }
            })
        })
    }

    read_with_helldod(text) {
        this.autoHighlight(this.state.userid, this.state.filepath)

        console.log(text);
        let newText = text.replaceAll('�', '"')
        let arrayOfOmitText = []
        const removeHelldodv2 = (string, removeWord) => {
            let updatedString = string

            while (updatedString.indexOf(`${removeWord}`) > -1 && updatedString.indexOf('helldod]', updatedString.indexOf(`${removeWord}`))) {
                let endIndex = updatedString.indexOf('helldod]', updatedString.indexOf(`${removeWord}`));
                console.log(endIndex)
                let openAfterOprn = updatedString.indexOf(`${removeWord}`, updatedString.indexOf(`${removeWord}`) + 8);
                while (true) {
                    if (endIndex > openAfterOprn && openAfterOprn > -1) {
                        endIndex = updatedString.indexOf('helldod]', endIndex + 8);
                        openAfterOprn = updatedString.indexOf(`${removeWord}`, openAfterOprn + 8);
                        //console.log(`OpenAfterOpen = ${openAfterOprn} || endIndex = ${endIndex}`)
                    } else {
                        break;
                    }
                }
                if (endIndex == -1) {
                    console.log("null found")
                    endIndex = updatedString.indexOf('helldod]', updatedString.indexOf(`${removeWord}`));
                }
                //console.log(endIndex)             
                let helldodPart = updatedString.substring(
                    updatedString.indexOf(`${removeWord}`),
                    endIndex + 8)
                console.log(helldodPart)
                let wordToOmit = "";
                if (helldodPart.indexOf("old") !== -1) {
                    wordToOmit = "old"
                } else {
                    wordToOmit = "[helldod"
                }
                arrayOfOmitText.push({
                    'text': '<em style = "color: red">' + helldodPart.substring(helldodPart.indexOf(`${wordToOmit}`) + wordToOmit.length,
                        helldodPart.indexOf("helldod]", 8)) + '</b></em>',
                    'index': updatedString.indexOf(helldodPart)
                });
                updatedString = updatedString.replace(helldodPart, '')
            }

            // Format for "old"
            while (updatedString.indexOf('>old<') > -1 && updatedString.indexOf(']', updatedString.indexOf('>old<'))) {
                let openIndex = updatedString.indexOf(('['), updatedString.indexOf('>old<'));
                let endIndex = updatedString.indexOf(']', updatedString.indexOf('>old<'));
                if (openIndex == -1 || endIndex == -1) {
                    let oldPart = updatedString.substring(
                        updatedString.indexOf('>old<') - 7,
                        updatedString.indexOf('>old<') + 14
                    );
                    console.log(oldPart);
                    console.log(endIndex);
                    updatedString = updatedString.replace(oldPart, '');
                    continue;
                }

                let lastEndIndex = endIndex;
                let lastOpenIndex = 0;
                endIndex = openIndex;
                while (true) {
                    lastOpenIndex = openIndex;
                    openIndex = updatedString.indexOf(('['), openIndex + 1);
                    endIndex = updatedString.indexOf((']'), endIndex + 1);
                    // console.log(updatedString.substring(openIndex, openIndex + 10))
                    // console.log(updatedString.substring(endIndex - 10, endIndex))
                    if ((openIndex != -1) && (openIndex < endIndex)) {
                        // openIndex = endIndex;
                    } else if ((endIndex == -1)) {
                        break;
                    } else {
                        lastEndIndex = endIndex;
                        console.log(updatedString.substring(lastOpenIndex, endIndex + 1));
                        break;
                    }
                }

                if (/*(openIndex == -1) && */(endIndex == -1)) {
                    let oldPart = updatedString.substring(
                        updatedString.indexOf('>old<') - 7,
                        updatedString.indexOf('>old<') + 14
                    );
                    console.log(oldPart);
                    // Wrong format
                    arrayOfOmitText.push({
                        'text': '<em style = "color: red">' + oldPart + '</b></em>',
                        'index': updatedString.indexOf(oldPart)
                    });
                    updatedString = updatedString.replace(oldPart, '');
                    continue;
                }

                let oldPart = updatedString.substring(
                    updatedString.indexOf('>old<') - 7,
                    lastEndIndex + 1
                );
                console.log(oldPart);
                console.log(endIndex);
                let wordToOmit = '>old<'
                arrayOfOmitText.push({
                    'text': '<em style = "color: red">' + oldPart.substring(oldPart.indexOf(`${wordToOmit}`) + 14,
                        oldPart.length) + '</b></em>',
                    'index': updatedString.indexOf(oldPart)
                });
                updatedString = updatedString.replace(oldPart, '');
            }

            // Format for "Old"
            while (updatedString.indexOf('>Old<') > -1 && updatedString.indexOf(']', updatedString.indexOf('>Old<'))) {
                let openIndex = updatedString.indexOf(('['), updatedString.indexOf('>Old<'));
                let endIndex = updatedString.indexOf(']', updatedString.indexOf('>Old<'));
                if (openIndex == -1 || endIndex == -1) {
                    let oldPart = updatedString.substring(
                        updatedString.indexOf('>Old<') - 7,
                        updatedString.indexOf('>Old<') + 14
                    );
                    console.log(oldPart);
                    console.log(endIndex);
                    updatedString = updatedString.replace(oldPart, '');
                    continue;
                }

                let lastEndIndex = endIndex;
                let lastOpenIndex = 0;
                endIndex = openIndex;
                while (true) {
                    lastOpenIndex = openIndex;
                    openIndex = updatedString.indexOf(('['), openIndex + 1);
                    endIndex = updatedString.indexOf((']'), endIndex + 1);
                    // console.log(updatedString.substring(openIndex, openIndex + 10))
                    // console.log(updatedString.substring(endIndex - 10, endIndex))
                    if ((openIndex != -1) && (openIndex < endIndex)) {
                        // openIndex = endIndex;
                    } else if ((endIndex == -1)) {
                        break;
                    } else {
                        lastEndIndex = endIndex;
                        console.log(updatedString.substring(lastOpenIndex, endIndex + 1));
                        break;
                    }
                }

                if (/*(openIndex == -1) && */(endIndex == -1)) {
                    let oldPart = updatedString.substring(
                        updatedString.indexOf('>Old<') - 7,
                        updatedString.indexOf('>Old<') + 14
                    );
                    console.log(oldPart);
                    // Wrong format
                    arrayOfOmitText.push({
                        'text': '<em style = "color: red">' + oldPart + '</b></em>',
                        'index': updatedString.indexOf(oldPart)
                    });
                    updatedString = updatedString.replace(oldPart, '');
                    continue;
                }

                let oldPart = updatedString.substring(
                    updatedString.indexOf('>Old<') - 7,
                    lastEndIndex + 1
                );
                console.log(oldPart);
                console.log(endIndex);
                let wordToOmit = '>Old<'
                arrayOfOmitText.push({
                    'text': '<em style = "color: red">' + oldPart.substring(oldPart.indexOf(`${wordToOmit}`) + 14,
                        oldPart.length) + '</b></em>',
                    'index': updatedString.indexOf(oldPart)
                });
                updatedString = updatedString.replace(oldPart, '');
            }



            // Format for helldod old
            while (updatedString.indexOf('>helldod old<') > -1 && updatedString.indexOf(']', updatedString.indexOf('>helldod old<'))) {
                let openIndex = updatedString.indexOf(('['), updatedString.indexOf('>helldod old<'));
                let endIndex = updatedString.indexOf(']', updatedString.indexOf('>helldod old<'));
                if (openIndex == -1 || endIndex == -1) {
                    let oldPart = updatedString.substring(
                        updatedString.indexOf('>helldod old<') - 7,
                        updatedString.indexOf('>helldod old<') + 16
                    );
                    console.log(oldPart);
                    console.log(endIndex);
                    updatedString = updatedString.replace(oldPart, '');
                    continue;
                }

                let lastEndIndex = endIndex;
                let lastOpenIndex = 0;
                endIndex = openIndex;
                while (true) {
                    lastOpenIndex = openIndex;
                    openIndex = updatedString.indexOf(('['), openIndex + 1);
                    endIndex = updatedString.indexOf((']'), endIndex + 1);
                    // console.log(updatedString.substring(openIndex, openIndex + 10))
                    // console.log(updatedString.substring(endIndex - 10, endIndex))
                    if ((openIndex != -1) && (openIndex < endIndex)) {
                        // openIndex = endIndex;
                    } else if ((endIndex == -1)) {
                        break;
                    } else {
                        lastEndIndex = endIndex;
                        console.log(updatedString.substring(lastOpenIndex, endIndex + 1));
                        break;
                    }
                }

                if ((endIndex == -1)) {
                    let oldPart = updatedString.substring(
                        updatedString.indexOf('>helldod old<') - 7,
                        updatedString.indexOf('>helldod old<') + 16
                    );
                    console.log(oldPart);
                    // Wrong format
                    arrayOfOmitText.push({
                        'text': '<em style = "color: red">' + oldPart + '</b></em>',
                        'index': updatedString.indexOf(oldPart)
                    });
                    updatedString = updatedString.replace(oldPart, '');
                    continue;
                }

                let oldPart = updatedString.substring(
                    updatedString.indexOf('>helldod old<') - 7,
                    lastEndIndex + 1
                );
                console.log(oldPart);
                console.log(endIndex);
                let wordToOmit = '>helldod old<'
                arrayOfOmitText.push({
                    'text': '<em style = "color: red">' + oldPart.substring(oldPart.indexOf(`${wordToOmit}`) + 16,
                        oldPart.length) + '</b></em>',
                    'index': updatedString.indexOf(oldPart)
                });
                updatedString = updatedString.replace(oldPart, '');
            }



            // Format for "Old8"
            while (updatedString.indexOf('>Old8<') > -1 && updatedString.indexOf(']', updatedString.indexOf('>Old8<'))) {
                let openIndex = updatedString.indexOf(('['), updatedString.indexOf('>Old8<'));
                let endIndex = updatedString.indexOf(']', updatedString.indexOf('>Old8<'));
                if (openIndex == -1 || endIndex == -1) {
                    let oldPart = updatedString.substring(
                        updatedString.indexOf('>Old8<') - 4,
                        updatedString.indexOf('>Old8<') + 10
                    );
                    console.log(oldPart);
                    console.log(endIndex);
                    updatedString = updatedString.replace(oldPart, '');
                    continue;
                }

                let lastEndIndex = endIndex;
                let lastOpenIndex = 0;
                endIndex = openIndex;
                while (true) {
                    lastOpenIndex = openIndex;
                    openIndex = updatedString.indexOf(('['), openIndex + 1);
                    endIndex = updatedString.indexOf((']'), endIndex + 1);
                    // console.log(updatedString.substring(openIndex, openIndex + 10))
                    // console.log(updatedString.substring(endIndex - 10, endIndex))
                    if ((openIndex != -1) && (openIndex < endIndex)) {
                        // openIndex = endIndex;
                    } else if ((endIndex == -1)) {
                        break;
                    } else {
                        lastEndIndex = endIndex;
                        console.log(updatedString.substring(lastOpenIndex, endIndex + 1));
                        break;
                    }
                }

                if (/*(openIndex == -1) && */(endIndex == -1)) {
                    let oldPart = updatedString.substring(
                        updatedString.indexOf('>Old8<') - 4,
                        updatedString.indexOf('>Old8<') + 10
                    );
                    console.log(oldPart);
                    // Wrong format
                    arrayOfOmitText.push({
                        'text': '<em style = "color: red">' + oldPart + '</b></em>',
                        'index': updatedString.indexOf(oldPart)
                    });
                    updatedString = updatedString.replace(oldPart, '');
                    continue;
                }

                let oldPart = updatedString.substring(
                    updatedString.indexOf('>Old8<') - 4,
                    lastEndIndex + 1
                );
                console.log(oldPart);
                console.log(endIndex);
                let wordToOmit = '>Old8<'
                arrayOfOmitText.push({
                    'text': '<em style = "color: red">' + oldPart.substring(oldPart.indexOf(`${wordToOmit}`) + 10,
                        oldPart.length) + '</b></em>',
                    'index': updatedString.indexOf(oldPart)
                });
                updatedString = updatedString.replace(oldPart, '');
            }

            // Format for "0ld"
            while (updatedString.indexOf('>0ld<') > -1 && updatedString.indexOf(']', updatedString.indexOf('>0ld<'))) {
                let openIndex = updatedString.indexOf(('['), updatedString.indexOf('>0ld<'));
                let endIndex = updatedString.indexOf(']', updatedString.indexOf('>0ld<'));
                if (openIndex == -1 || endIndex == -1) {
                    let oldPart = updatedString.substring(
                        updatedString.indexOf('>0ld<') - 7,
                        updatedString.indexOf('>0ld<') + 14
                    );
                    console.log(oldPart);
                    console.log(endIndex);
                    updatedString = updatedString.replace(oldPart, '');
                    continue;
                }

                let lastEndIndex = endIndex;
                let lastOpenIndex = 0;
                endIndex = openIndex;
                while (true) {
                    lastOpenIndex = openIndex;
                    openIndex = updatedString.indexOf(('['), openIndex + 1);
                    endIndex = updatedString.indexOf((']'), endIndex + 1);
                    // console.log(updatedString.substring(openIndex, openIndex + 10))
                    // console.log(updatedString.substring(endIndex - 10, endIndex))
                    if ((openIndex != -1) && (openIndex < endIndex)) {
                        // openIndex = endIndex;
                    } else if ((endIndex == -1)) {
                        break;
                    } else {
                        lastEndIndex = endIndex;
                        console.log(updatedString.substring(lastOpenIndex, endIndex + 1));
                        break;
                    }
                }

                if (/*(openIndex == -1) && */(endIndex == -1)) {
                    let oldPart = updatedString.substring(
                        updatedString.indexOf('>0ld<') - 7,
                        updatedString.indexOf('>0ld<') + 14
                    );
                    console.log(oldPart);
                    // Wrong format
                    arrayOfOmitText.push({
                        'text': '<em style = "color: red">' + oldPart + '</b></em>',
                        'index': updatedString.indexOf(oldPart)
                    });
                    updatedString = updatedString.replace(oldPart, '');
                    continue;
                }

                let oldPart = updatedString.substring(
                    updatedString.indexOf('>0ld<') - 7,
                    lastEndIndex + 1
                );
                console.log(oldPart);
                console.log(endIndex);
                let wordToOmit = '>0ld<'
                arrayOfOmitText.push({
                    'text': '<em style = "color: red">' + oldPart.substring(oldPart.indexOf(`${wordToOmit}`) + 14,
                        oldPart.length) + '</b></em>',
                    'index': updatedString.indexOf(oldPart)
                });
                updatedString = updatedString.replace(oldPart, '');
            }

            // Format for "Old"
            while (updatedString.indexOf('>0ld1<') > -1 && updatedString.indexOf(']', updatedString.indexOf('>0ld1<'))) {
                let openIndex = updatedString.indexOf(('['), updatedString.indexOf('>0ld1<'));
                let endIndex = updatedString.indexOf(']', updatedString.indexOf('>0ld1<'));
                if (openIndex == -1 || endIndex == -1) {
                    let oldPart = updatedString.substring(
                        updatedString.indexOf('>0ld1<') - 7,
                        updatedString.indexOf('>0ld1<') + 11
                    );
                    console.log(oldPart);
                    console.log(endIndex);
                    updatedString = updatedString.replace(oldPart, '');
                    continue;
                }

                let lastEndIndex = endIndex;
                let lastOpenIndex = 0;
                endIndex = openIndex;
                while (true) {
                    lastOpenIndex = openIndex;
                    openIndex = updatedString.indexOf(('['), openIndex + 1);
                    endIndex = updatedString.indexOf((']'), endIndex + 1);
                    // console.log(updatedString.substring(openIndex, openIndex + 10))
                    // console.log(updatedString.substring(endIndex - 10, endIndex))
                    if ((openIndex != -1) && (openIndex < endIndex)) {
                        // openIndex = endIndex;
                    } else if ((endIndex == -1)) {
                        break;
                    } else {
                        lastEndIndex = endIndex;
                        console.log(updatedString.substring(lastOpenIndex, endIndex + 1));
                        break;
                    }
                }

                if (/*(openIndex == -1) && */(endIndex == -1)) {
                    let oldPart = updatedString.substring(
                        updatedString.indexOf('>0ld1<') - 7,
                        updatedString.indexOf('>0ld1<') + 11
                    );
                    console.log(oldPart);
                    // Wrong format
                    arrayOfOmitText.push({
                        'text': '<em style = "color: red">' + oldPart + '</b></em>',
                        'index': updatedString.indexOf(oldPart)
                    });
                    updatedString = updatedString.replace(oldPart, '');
                    continue;
                }

                let oldPart = updatedString.substring(
                    updatedString.indexOf('>0ld1<') - 7,
                    lastEndIndex + 1
                );
                console.log(oldPart);
                console.log(endIndex);
                let wordToOmit = '>0ld1<'
                arrayOfOmitText.push({
                    'text': '<em style = "color: red">' + oldPart.substring(oldPart.indexOf(`${wordToOmit}`) + 11,
                        oldPart.length) + '</b></em>',
                    'index': updatedString.indexOf(oldPart)
                });
                updatedString = updatedString.replace(oldPart, '');
            }
            return updatedString;
        }

        newText = removeHelldodv2(newText, '[helldod')
        console.log(arrayOfOmitText)
        let textbuffer = 0;
        for (let i = 0; i < arrayOfOmitText.length; i++) {
            newText = newText.slice(0, arrayOfOmitText[i].index) + arrayOfOmitText[i].text + newText.slice(arrayOfOmitText[i].index)
            textbuffer += arrayOfOmitText[i].text.length
            if (i !== (arrayOfOmitText.length - 1))
                arrayOfOmitText[i + 1].index = arrayOfOmitText[i + 1].index + textbuffer
        }

        this.setState({
            // htmlFileShowContent: newText,
            htmlFileShowContentBackup: newText
        })
        this.setState({ htmlFileShowContent: newText }, () => {
            console.log(this.state.filepath)
            console.log(this.props.SubFolder,this.props.MainFolder)
            let scope = this,
                mainfolder = this.state.filepath.split('/')[2] + '/',
                subfolder = this.state.filepath.split('/')[3] + '/';
                console.log(mainfolder,subfolder)
                // subfolder = this.props.SubFolder + '/',
                // mainfolder = this.props.MainFolder + '/'
            $('#SinglePageIframeSearched').find('a').each(function () {
                let href = $(this).attr('href')
                let isJsFun = false,
                    isSamePageLink = false
                if (href != undefined && href.toString().toLowerCase().includes('javascript:void(0)')) {
                    isJsFun = true
                }
                if (href != undefined && href.includes('#')) {
                    isSamePageLink = true
                }
                let file_n = href
                if (href != undefined && !isJsFun) {
                    $(this).mousedown(function (e) {
                        if (e.button == 2) {
                            let r_href = href
                            if (r_href.includes('../../')) {
                                r_href = r_href.replace('../../', '')
                            } else if (r_href.includes('../')) {
                                r_href = r_href.replace('../', mainfolder)
                                subfolder = r_href.split(mainfolder)[1].substring(0, r_href.split(mainfolder)[1].indexOf('/')) + '/'
                            } else {
                                r_href = mainfolder + subfolder + r_href
                            }
                            let temp_url = 'Assets/data5t/' + r_href
                            console.log(mainfolder, subfolder, temp_url)
                            const isPPT = ['.pptx', '.ppt'].some((el) => temp_url.toLowerCase().includes(el))
                            if (!isPPT) {
                                // window.open(
                                //     'https://hexbss.xyz/lawcrux/lawcrux/web/'+temp_url,
                                //     '_blank'
                                // )
                                $(this).attr('href', sessionStorage.getItem('url') + temp_url)
                            }
                        }
                    });

                    $(this).on('click', (e) => {
                        console.log(mainfolder, subfolder)
                        let l_href = href
                        console.log(href)
                        if (l_href.includes('../../')) {
                            l_href = l_href.replace('../../', '')
                        } else if (l_href.includes('../')) {
                            l_href = l_href.replace('../', mainfolder)
                            subfolder = l_href.split(mainfolder)[1].substring(0, l_href.split(mainfolder)[1].indexOf('/')) + '/'
                        } else {
                            l_href = mainfolder + subfolder + l_href
                        }
                        let temp_url = 'Assets/data5t/' + l_href
                        console.log(mainfolder, subfolder, temp_url)
                        const isPPT = ['.pptx', '.ppt'].some((el) => temp_url.toLowerCase().includes(el))
                        if (!isSamePageLink) {
                            $(this).attr('href', '#')
                        }
                        if (!temp_url.includes('#')) {
                            if (isPPT) {
                                scope.setState({ isOpenSummary: true, summaryData: { foldername: mainfolder + subfolder, filename: file_n } })
                            } else {
                                scope.setState({ filepath: temp_url, allEnds: "0", allStarts: '0', startEndArray: [] }, () => {
                                    scope.anchorTags(temp_url)
                                })
                            }
                        }
                    })
                }
            })
        })

        // this.autoHighlight(this.state.userid, this.state.filepath)
    }
    //     let newText = text.replaceAll('�', '"').replaceAll('[helldod old', '').replaceAll('[helldod', "").replaceAll('helldod]', "").replaceAll('<sup>old</sup>', '')
    //     // let newText = text.replaceAll('�', '"').replaceAll('&nbsp;', '')
    //     this.setState({ htmlFileShowContent: newText }, () => {
    //         let scope = this,
    //             subfolder = this.props.SubFolder + '/',
    //             mainfolder = this.props.MainFolder + '/'
    //         $('#SinglePageIframeSearched').find('a').each(function () {
    //             let href = $(this).attr('href')
    //             let isJsFun = false,
    //                 isSamePageLink = false
    //             if (href != undefined && href.toString().toLowerCase().includes('javascript:void(0)')) {
    //                 isJsFun = true
    //             }
    //             if (href != undefined && href.includes('#')) {
    //                 isSamePageLink = true
    //             }
    //             let file_n = href
    //             if (href != undefined && !isJsFun) {
    //                 $(this).mousedown(function (e) {
    //                     if (e.button == 2) {
    //                         let r_href = href
    //                         if (r_href.includes('../../')) {
    //                             r_href = r_href.replace('../../', '')
    //                         } else if (r_href.includes('../')) {
    //                             r_href = r_href.replace('../', mainfolder)
    //                             subfolder = r_href.split(mainfolder)[1].substring(0, r_href.split(mainfolder)[1].indexOf('/')) + '/'
    //                         } else {
    //                             r_href = mainfolder + subfolder + r_href
    //                         }
    //                         let temp_url = 'Assets/data5t/' + r_href
    //                         console.log(mainfolder, subfolder, temp_url)
    //                         const isPPT = ['.pptx', '.ppt'].some((el) => temp_url.toLowerCase().includes(el))
    //                         if (!isPPT) {
    //                             // window.open(
    //                             //     'https://hexbss.xyz/lawcrux/lawcrux/web/'+temp_url,
    //                             //     '_blank'
    //                             // )
    //                             $(this).attr('href', sessionStorage.getItem('url') + temp_url)
    //                         }
    //                     }
    //                 });

    //                 $(this).on('click', (e) => {
    //                     console.log(mainfolder, subfolder)
    //                     let l_href = href
    //                     console.log(href)
    //                     if (l_href.includes('../../')) {
    //                         l_href = l_href.replace('../../', '')
    //                     } else if (l_href.includes('../')) {
    //                         l_href = l_href.replace('../', mainfolder)
    //                         subfolder = l_href.split(mainfolder)[1].substring(0, l_href.split(mainfolder)[1].indexOf('/')) + '/'
    //                     } else {
    //                         l_href = mainfolder + subfolder + l_href
    //                     }
    //                     let temp_url = 'Assets/data5t/' + l_href
    //                     console.log(mainfolder, subfolder, temp_url)
    //                     const isPPT = ['.pptx', '.ppt'].some((el) => temp_url.toLowerCase().includes(el))
    //                     if (!isSamePageLink) {
    //                         $(this).attr('href', '#')
    //                     }
    //                     if (!temp_url.includes('#')) {
    //                         if (isPPT) {
    //                             scope.setState({ isOpenSummary: true, summaryData: { foldername: mainfolder + subfolder, filename: file_n } })
    //                         } else {
    //                             scope.setState({ filepath: temp_url, allEnds: "0", allStarts: '0', startEndArray: [] }, () => {
    //                                 scope.anchorTags(temp_url)
    //                             })
    //                         }
    //                     }
    //                 })
    //             }
    //         })
    //     })
    //     // this.setState({
    //     //     htmlFileShowContent: newText
    //     // })
    //     this.autoHighlight(this.state.userid, this.state.filepath)
    // }

    fetchFileContent = (filepath, url) => new Promise((resolve, reject) => {
        const storeHistory = (path, title) => {
            let filepath = ''
            if(path.includes('Web\\')){
                 filepath = path.split('Web\\')[1]
            }else{
                filepath = path
            }
            let obj = {
                query: `[dbo].[Lawcrux_Web_Proc_HistoryLog_Submit] @UserID='{0}', @filepath='{1}', @Description='{2}'`,
                queryArr: [`${this.props.userid}`, `${filepath}`, `${title}`]
            }
            Hexapi(obj).then(resp => {
                console.log(resp)
            }).catch(err => console.error(err))
        }
        this.setState({ isOpenInIframe: false, htmlFileOriginalContent: '', htmlFileShowContent: "", isHelldodVisible: false, isFetchFileApiHitComplete: false })
        var fd = new FormData();
        fd.append(Aesencryption('filename'), Aesencryption(filepath))
        axios({
            mode: 'cors',
            method: "POST",
            headers: { "Content-Type": "multipart/form-data" },
            url: `${url}`,
            data: fd
        }).then(resp => {
            // console.log(resp.data)
            let respData = resp.data
            const re = /<SCRIPT\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/SCRIPT>/gi;
            const results = respData.match(re)
            // console.log(results)
            var scripts = ''
            if (results != undefined) {
                for (var i = 0; i < results.length; i++) {
                    console.log(i)
                    if (results[i] != undefined) {
                        scripts += results[i].replace(/<[^>]*>/g, '').replace("</script>", "").replace("</Script>", "").replace("</SCRIPT>")
                    }
                    // var scripts = .replace(/\<.*\>/, "");
                }
                console.log(scripts)
                window.eval(scripts);
            }

            if (respData != "") {
                // if (respData.toLowerCase().includes('<object')) {
                //     respData = respData.replaceAll(`data="../../`, `data="${sessionStorage.getItem('url')}Assets/Data5t/`)
                // }
                // let subfolder = this.props.SubFolder + '/',
                //     mainfolder = this.props.MainFolder + '/'
                let pageTitle = respData.substring(respData.indexOf('<title>') + 7, respData.indexOf('</title>'));
                let headContent = respData.substring(
                    respData.toLowerCase().indexOf('<head>') + 6,
                    respData.toLowerCase().indexOf('</head>'))
                    .replace('<title>' + pageTitle + '</title>', '')

                console.log(headContent, headContent.toLowerCase().includes('<script'))
                // if (headContent.toLowerCase().includes('<script') || headContent.toLowerCase().includes('<link')) {
                if (headContent.toLowerCase().includes('<script') || respData.toLowerCase().includes('<img')) {
                    this.setState({ isOpenInIframe: false })
                }
                // // ========================================================================================================
                // // This code used to correct the object tag data attribute url
                // // In frames
                // let styleSheet = headContent.substring(headContent.indexOf('<link'), headContent.indexOf('.css">', headContent.indexOf('<link')) + 6)
                // let href = styleSheet.substring(styleSheet.indexOf('href="') + 6, styleSheet.indexOf('.css') + 4)
                // console.log(styleSheet, href)
                // // $('#customIframe').find('object').each(function () {
                // //     let href = $(this).attr('data')
                // let r_href = href
                // if (r_href.includes('../../')) {
                //     r_href = r_href.replace('../../', mainfolder+subfolder)
                // } else if (r_href.includes('../')) {
                //     r_href = r_href.replace('../', mainfolder)
                //     subfolder = r_href.split(mainfolder)[1].substring(0, r_href.split(mainfolder)[1].indexOf('/')) + '/'
                // } else {
                //     r_href = mainfolder + subfolder + r_href
                // }
                // let temp_url = 'Assets/data5t/' + r_href
                // headContent = headContent.replace(href, 'https://hexbss.xyz/lawcrux/lawcrux/web/' + temp_url)
                // if (headContent != '') {
                //     console.log(headContent)
                //     document.head.insertAdjacentHTML('beforeend', headContent)
                //     respData = respData.replace(headContent, ' ')
                // }
                //     
                //     $(this).attr('data', 'https://hexbss.xyz/lawcrux/lawcrux/web/' + temp_url)
                // });
                // ========================================================================================================
                // headContent = headContent.substring(
                //     headContent.toLowerCase().indexOf('<script language="javascript">')+30,
                //     headContent.toLowerCase().indexOf('</script'),
                // )
                // console.log(headContent)

                // var fn = eval(headContent)
                // console.log(fn)
                storeHistory(filepath, pageTitle)
                this.setState({ htmlFileOriginalContent: respData, pageTitle: pageTitle }, () => {
                    this.read_without_helldod(resp.data)
                })
            } else {
                reject('File path not found')
            }
        }).then(() => resolve()).catch(err => reject(err))
        if (this.props.filepath.includes("gt/gtcgst/index_01.htm")) {
            this.setState({ showDemo2: true })
        }
        if (filepath.includes("gt/gtcgst/CGST_BILL_17_SEC_07.htm")) {
            this.setState({ showDemo2: false })
            this.setState({ showDemo: true })
        } else {
            this.setState({ showDemo: false })
        }
        if( this.props.showinternaldemo==true){
            this.setState({showcaseDemo:true})
        }
    })

    fetchPageData = (userid, filepath) => new Promise((resolve, reject) => {
        if (this.state.showDemo2 == true ) {
            // console.warn("ShowDemo2")
            this.HighlightillustartionContent(this.state.htmlFileShowContent, "section-7")

        }
        this.setState({ isBookmarkAddComplete: false, isBookmarkAdded: false })
        let obj = {
            query: `[dbo].[Lawcrux_Web_Proc_Page_GetDataV2] @UserID='{0}', @filepath='{1}'`,
            queryArr: [`${userid}`, `${filepath}`]
        }
        Hexapi(obj).then(resp => {
            console.log(resp)
            this.setState({ isBookmarkAddComplete: false })
            if (resp[''][0].bookmark == 1) {
                this.setState({ isBookmarkAdded: true })
            } else {
                this.setState({ isBookmarkAdded: false })
            }
            // if (resp['note'][0].command == 1) {
            //     this.setState({ usernotes: resp.note[0].note })
            // } else {
            //     this.setState({ usernotes: '' })
            // }
            // if (resp['summary'] != undefined) {
            //     this.setState({ summaryData: resp.summary[0] })
            // } else {
            //     this.setState({ summaryData: null })
            // }
            this.setState({ pageData: resp })
        }).then(resp => this.setState({ isBookmarkAddComplete: true }))
            // .then(res => this.autoHighlight())
            .then(re => resolve())
            .catch(err => reject(err))
    })

    checkClash(obj1, obj2) {
        // console.log(obj1,obj2)
        if (obj1.start <= obj2.start) {
            if (obj1.end >= obj2.end || obj1.end >= obj2.start) {
                console.log("here")
                return true;
            } else {
                return false;
            }
        } else {
            return this.checkClash(obj2, obj1);
        }
    }

    correctHighlight(array) {
        // console.log(array)
        let newArr = array
        for (let i = 0; i < array.length; i++) {
            // let clash = false
            for (let j = 0; j < array.length; j++) {
                if (i != j && this.checkClash(array[i], array[j]) && array[i].start != array[i].end && array[j].start != array[j].end) {

                    let newHi = {
                        start: min(array[i].start, array[j].start),
                        end: max(array[i].end, array[j].end)
                    }
                    newArr.push(newHi)
                    if (i < j) {
                        newArr.splice(j, 1)
                        newArr.splice(i, 1)
                    } else {
                        newArr.splice(i, 1)
                        newArr.splice(j, 1)
                    }

                    newArr = this.correctHighlight(newArr)
                    // console.log(newArr)
                    return newArr;
                }
            }
        }

        return newArr;
    }

    autoHighlight = (userid, filepath) => new Promise((resolve, reject) => {
        let scope = this

        const restoreSelection = function (containerEl, savedSel) {
            var charIndex = 0, range = document.createRange();
            range.setStart(containerEl, 0);
            range.collapse(true);
            var nodeStack = [containerEl], node, foundStart = false, stop = false;

            while (!stop && (node = nodeStack.pop())) {
                if (node.nodeType == 3) {
                    var nextCharIndex = charIndex + node.length;
                    if (!foundStart && savedSel.start >= charIndex && savedSel.start <= nextCharIndex) {
                        range.setStart(node, savedSel.start - charIndex);
                        foundStart = true;
                    }
                    if (foundStart && savedSel.end >= charIndex && savedSel.end <= nextCharIndex) {
                        range.setEnd(node, savedSel.end - charIndex);
                        stop = true;
                    }
                    charIndex = nextCharIndex;
                } else {
                    var i = node.childNodes.length;
                    while (i--) {
                        nodeStack.push(node.childNodes[i]);
                    }
                }
                // console.log(range)
            }

            var sel = window.getSelection();
            sel.removeAllRanges();
            sel.addRange(range);
            document.designMode = "on";
            if (!document.execCommand("HiliteColor", false, scope.state.highlightColor)) {
                document.execCommand("BackColor", false, scope.state.highlightColor);
            }

            document.designMode = "off";
            if (scope.state.isDarkMode) {
                document.getElementById('mainboxdiv').style.backgroundColor = 'black'
            }
        }

        let obj = {
            query: `[dbo].[Lawcrux_Web_Proc_Highlight_GetValue] @UserID='{0}', @filepath='{1}'`,
            queryArr: [`${userid}`, `${filepath}`]
        }

        Hexapi(obj).then(resp => {
            console.log(resp)
            if (resp.highlight != undefined && resp.highlight.length > 0) {
                let start = resp.highlight[0].start
                let end = resp.highlight[0].end
                console.log(start, end)
                this.setState({ allStarts: start, allEnds: end })
                let newStart = [],
                    newEnd = []
                if (start.toString().indexOf('##12##') > -1) {
                    newStart = start.split('##12##')
                } else {
                    newStart.push(start)
                }
                if (end.toString().indexOf('##12##') > -1) {
                    newEnd = end.split('##12##')
                } else {
                    newEnd.push(end)
                }
                let arr = []
                console.log(newStart)
                for (let i = 0; i < newStart.length; i++) {
                    if (newStart[i] == 'NaN' && newStart[i] == '') {
                        newStart[i] = 0
                    }
                    if (newEnd[i] == 'NaN' && newEnd[i] == '') {
                        newEnd[i] = 0
                    }
                    console.log(newStart[i], newEnd[i])
                    let obj = {
                        start: parseInt(newStart[i]),
                        end: parseInt(newEnd[i])
                    }
                    arr.push(obj)
                    if (document.getElementById('SinglePageIframeSearched')) {
                        restoreSelection(document.getElementById("SinglePageIframeSearched"), obj);
                    }
                }
                this.setState({ startEndArray: arr })
                console.log(newStart, newEnd, this.state.startEndArray)
            }
        }).then(() => resolve()).catch(err => reject(err))
    })

    getSelectedText(userid, filepath) {
        // if (this.state.selectedFile != "") {
        let scope = this

        function makeEditableAndHighlight(colour) {
            var range, sel = window.getSelection();
            if (sel.rangeCount && sel.getRangeAt) {
                range = sel.getRangeAt(0);
            }

            document.designMode = "on";
            if (range) {
                sel.removeAllRanges();
                sel.addRange(range);
            }
            // Use HiliteColor since some browsers apply BackColor to the whole block
            if (!document.execCommand("HiliteColor", false, colour)) {
                console.log('1')
                document.execCommand("BackColor", false, colour);
            }
            document.designMode = "off";
            if (scope.state.isDarkMode) {
                document.getElementById('mainboxdiv').style.background = 'black'
            }
        }

        function highlight(colour) {
            var range;
            console.log('hightlight call')
            if (window.getSelection) {
                // IE9 and non-IE
                try {
                    if (!document.execCommand("BackColor", false, colour)) {
                        console.log('backColor working')
                        makeEditableAndHighlight(colour);
                    }
                } catch (ex) {
                    console.log('backColor not working')
                    makeEditableAndHighlight(colour)
                }
            } else if (document.selection && document.selection.createRange) {
                // IE <= 8 case
                console.log('window getselectiong not working')
                range = document.selection.createRange();
                range.execCommand("BackColor", false, colour);
            }
        }

        const saveSelection = function (containerEl) {
            if (window.getSelection().getRangeAt(0) != undefined) {
                var range = window.getSelection().getRangeAt(0);
                var preSelectionRange = range.cloneRange();
                preSelectionRange.selectNodeContents(containerEl);
                preSelectionRange.setEnd(range.startContainer, range.startOffset);
                var start = preSelectionRange.toString().length;

                return {
                    start: start,
                    end: start + range.toString().length
                }
            } else {
                alert('Select text first')
            }

        };


        // function highlightSelection() {
        //     var selection;

        //     //Get the selected stuff
        //     if (window.getSelection)
        //         selection = window.getSelection();
        //     else if (typeof document.selection != "undefined")
        //         selection = document.selection;

        //     //Get a the selected content, in a range object
        //     var range = selection.getRangeAt(0);

        //     //If the range spans some text, and inside a tag, set its css class.
        //     if (range && !selection.isCollapsed) {
        //         if (selection.anchorNode.parentNode == selection.focusNode.parentNode) {
        //             // if(selection.anchorNode.parentNode.className == "un-highlight"){
        //             //     selection.anchorNode.parentNode.className = "highlight"
        //             // }else{
        //             //     var span = document.createElement('span');
        //             //     span.className = 'highlight';
        //             //     range.surroundContents(span);
        //             // }
        //             var span = document.createElement('span');
        //             span.className = 'highlight';
        //             range.surroundContents(span);
        //         }
        //     }
        // }

        // highlightSelection()
        let selectedIndexObj = saveSelection(document.getElementById("SinglePageIframeSearched"))
        console.log(selectedIndexObj)
        if (this.state.allStarts != "" && this.state.allEnds != "" && this.state.allStarts != "0" && this.state.allEnds != "0") {
            let newStarts = ''
            let newEnds = ''
            this.setState({ startEndArray: this.state.startEndArray.concat(selectedIndexObj) }, () => {
                let updatedObj = this.correctHighlight(this.state.startEndArray)
                console.log(updatedObj)
                let start = [],
                    end = []
                for (let i = 0; i < updatedObj.length; i++) {
                    start.push(updatedObj[i].start)
                    end.push(updatedObj[i].end)
                }

                newStarts = start.join('##12##')
                newEnds = end.join('##12##')

                // Updating file highlights
                this.setState({ allStarts: newStarts, allEnds: newEnds }, () => {
                    console.log(this.state.allStarts, this.state.allEnds)
                    highlight(scope.state.highlightColor)
                    // highlightSelection()
                    let obj = {
                        query: `[dbo].[Lawcrux_Web_Proc_UpdateHighlight_Submit] @UserID='{0}', @Filepath='{1}', @Start='{2}', @End='{3}'`,
                        queryArr: [`${userid}`, `${filepath}`, `${newStarts}`, `${newEnds}`]
                    }

                    Hexapi(obj).then(resp => {
                        console.log(resp)
                    })
                })
            })

        } else {
            //Inserting very first highlight
            this.setState({ allStarts: selectedIndexObj.start, allEnds: selectedIndexObj.end }, () => {
                highlight(scope.state.highlightColor)
                // highlightSelection()
                if (selectedIndexObj.start != selectedIndexObj.end) {
                    let obj = {
                        query: `[dbo].[Lawcrux_Web_Proc_AddHighlighter_Submit] @UserID='{0}', @Filepath='{1}', @Start='{2}', @End='{3}'`,
                        queryArr: [`${userid}`, `${filepath}`, `${selectedIndexObj.start}`, `${selectedIndexObj.end}`]
                    }
                    Hexapi(obj).then(resp => {
                        console.log(resp)
                    })
                }
                else {
                    Swal.fire({
                        text: 'No Text selected.',
                    })
                }

            })
        }
    }

    removeSelectedText(userid, filepath) {
        // // if (this.state.selectedFile != "") {
        let scope = this
        function makeEditableAndHighlight(colour) {
            var range, sel = window.getSelection();
            if (sel.rangeCount && sel.getRangeAt) {
                range = sel.getRangeAt(0);
            }
            // console.log(range)
            document.designMode = "on";
            if (range) {
                sel.removeAllRanges();
                sel.addRange(range);
            }
            // Use HiliteColor since some browsers apply BackColor to the whole block
            if (!document.execCommand("HiliteColor", false, colour)) {
                document.execCommand("BackColor", false, colour);
            }
            document.designMode = "off";
            if (scope.state.isDarkMode) {
                document.getElementById('mainboxdiv').style.backgroundColor = 'black'
            }
        }

        function highlight(colour) {
            var range;
            if (window.getSelection) {
                // IE9 and non-IE
                try {
                    if (!document.execCommand("BackColor", false, colour)) {
                        makeEditableAndHighlight(colour);
                    }
                } catch (ex) {
                    makeEditableAndHighlight(colour)
                }
            } else if (document.selection && document.selection.createRange) {
                // IE <= 8 case
                range = document.selection.createRange();
                range.execCommand("BackColor", false, colour);
            }
        }


        const saveSelection = function (containerEl) {
            var range = window.getSelection().getRangeAt(0);
            var preSelectionRange = range.cloneRange();
            preSelectionRange.selectNodeContents(containerEl);
            preSelectionRange.setEnd(range.startContainer, range.startOffset);
            var start = preSelectionRange.toString().length;

            return {
                start: start,
                end: start + range.toString().length
            }
        };

        let removeCoordinates = saveSelection(document.getElementById("SinglePageIframeSearched"))

        // function RemovehighlightSelection() {
        //     var selection;

        //     //Get the selected stuff
        //     if (window.getSelection)
        //         selection = window.getSelection();
        //     else if (typeof document.selection != "undefined")
        //         selection = document.selection;

        //     //Get a the selected content, in a range object
        //     var range = selection.getRangeAt(0);

        //     //If the range spans some text, and inside a tag, set its css class.
        //     if (range && !selection.isCollapsed) {
        //         console.log(selection.anchorNode.parentNode)
        //         console.log(selection.focusNode.parentNode)
        //         if (selection.anchorNode.parentNode == selection.focusNode.parentNode) {
        //             // $('.highlight').contents().unwrap()
        //             // console.log(selection.anchorNode.parentNode.textContent)
        //             // selection.anchorNode.parentNode = selection.anchorNode.parentNode.textContent
        //             // var span = document.createElement('span');
        //             selection.anchorNode.parentNode.className = 'un-highlight';
        //             // range.surroundContents(span);
        //         }
        //     }
        // }
        if (this.state.allStarts != "" && this.state.allEnds != "" && this.state.allStarts != "0" && this.state.allEnds != "0") {

            this.setState({ startEndArray: this.state.startEndArray.concat(removeCoordinates) }, () => {
                console.log(removeCoordinates)
                let Arr = this.correctHighlight(this.state.startEndArray)
                let rStart = removeCoordinates.start,
                    rEnd = removeCoordinates.end
                // let Arr = this.state.startEndArray
                let newArr = [],
                    newStarts = '',
                    newEnds = '',
                    scope = this
                for (let i = 0; i < Arr.length; i++) {
                    if (Arr[i].start != rStart && Arr[i].end != rEnd) {
                        if (rStart < Arr[i].start && rEnd > Arr[i].start && rEnd < Arr[i].end) {
                            newArr.push({ start: min(rEnd, Arr[i].end), end: max(rEnd, Arr[i].end) })
                        } else if (rStart > Arr[i].start && rStart < Arr[i].end && rEnd > Arr[i].start && rEnd < Arr[i].end) {
                            newArr.push({ start: min(rStart, Arr[i].start), end: max(rStart, Arr[i].start) })
                            newArr.push({ start: min(rEnd, Arr[i].end), end: max(rEnd, Arr[i].end) })
                        } else if (rStart > Arr[i].start && rStart < Arr[i].end && rEnd > Arr[i].end) {
                            newArr.push({ start: min(rStart, Arr[i].start), end: max(rStart, Arr[i].start) })
                        } else {
                            newArr.push(Arr[i])
                        }
                    }
                }
                console.log(newArr)

                let start = [],
                    end = []
                for (let i = 0; i < newArr.length; i++) {
                    start.push(newArr[i].start)
                    end.push(newArr[i].end)
                }

                newStarts = start.join('##12##')
                newEnds = end.join('##12##')

                // Updating file highlights
                this.setState({ allStarts: newStarts, allEnds: newEnds, startEndArray: newArr }, () => {
                    console.log(this.state.allStarts, this.state.allEnds)
                    highlight('transparent')
                    // RemovehighlightSelection()
                    let obj = {
                        query: `[dbo].[Lawcrux_Web_Proc_UpdateHighlight_Submit] @UserID='{0}', @Filepath='{1}', @Start='{2}', @End='{3}'`,
                        queryArr: [`${userid}`, `${filepath}`, `${newStarts}`, `${newEnds}`]
                    }

                    Hexapi(obj).then(resp => {
                        console.log(resp)
                    })
                })
            })
        }
    }

    removeBookmark(userid, filepath) {
        this.setState({ isBookmarkAddComplete: false })
        let obj = {
            query: `[dbo].[Lawcrux_Web_Proc_RemoveBookmark_Submit] @UserID='{0}', @filepath='{1}'`,
            queryArr: [`${userid}`, `${filepath}`]
        }
        Hexapi(obj).then(resp => {
            console.log(resp)
            if (resp[''][0].command == 1) {
                this.setState({ isBookmarkAdded: false })
            }
        }).then(re => this.setState({ isBookmarkAddComplete: true }))
    }

    addBookmark(userid, filepath) {
        console.log(this.state)
        this.setState({ isBookmarkAddComplete: false })
        let obj = {
            query: `[dbo].[Lawcrux_Web_Proc_AddBookmark_Submit] @UserID='{0}', @filepath='{1}',@Description='{2}'`,
            queryArr: [`${userid}`, `${filepath}`, `${this.state.pageTitle}`]
        }
        Hexapi(obj).then(resp => {
            console.log(resp)
            if (resp[''][0].command == 1) {
                this.setState({ isBookmarkAdded: true })
            }
        }).then(re => this.setState({ isBookmarkAddComplete: true }))
    }

    //     // Add event listeners for key presses
    // componentDidMount() {
    //     document.addEventListener("keydown", this.handleKeyDown);
    //   }

    // Remove event listeners when the component is unmounted
    componentWillUnmount() {
        document.removeEventListener("keydown", this.handleKeyDown);
    }

    // Handle key presses
    handleKeyDown = (event) => {
        if (event.ctrlKey && (event.key === "h" || event.key === "H")) {
            event.preventDefault();
            this.openHistoryModal();
        } else if (event.ctrlKey && (event.key === "b" || event.key === "B")) {
            event.preventDefault();
            this.openBookmarkModal();
        }else if (event.ctrlKey && (event.key === "i" || event.key === "I")) { event.preventDefault(); if(sessionStorage.getItem('username').includes('LCX_')){ this.setState({ isOpenInHouseDevelopment: true }) } }
    }

    openHistoryModal = () => {
        this.setState({ isOpenHistoryModal: true });
    }

    openBookmarkModal = () => {
        this.setState({ isOpenBookmarkModal: true });
    }
    componentDidUpdate() {
        // console.log(this.state)
    }
    componentDidMount() {


        document.addEventListener("keydown", this.handleKeyDown);
        console.log(this.props.filepath)
        console.log(this.props.filepath.includes('/cl/'))
        let obj2 = {
            'query': `[dbo].[Lawcrux_Web_Proc_Homescreen_GetData] @UserID='{0}' `,// @RecID='{0}'
            'queryArr': [`${sessionStorage.getItem('userid')}`]
        }
        Hexapi(obj2).then(resp => {
            console.log(resp);

            if (resp["illustration"][1].illustration2 == 0) {
                this.setState({markIllustration:false})
            } else {
                this.setState({markIllustration:true})


            }
        })
        if (document.getElementById('mainboxdiv') && document.getElementById('mainnavbar')) {
            let mainnvabarHeight = document.getElementById('mainnavbar').clientHeight + 1
            let o = document.getElementById('mainboxdiv').clientHeight
            console.log(mainnvabarHeight, o)
            document.getElementById("mainboxdiv").style.height = `calc(100% - ${mainnvabarHeight}px)`
        }
        // alert("componentdid mount call")
        this.setState({ filepath: this.props.filepath, userid: this.props.userid })
        let filepath = this.props.filepath,
            userid = this.props.userid,
            url = sessionStorage.getItem('url') + 'fetchfile.php',
            scope = this,
            subfolder = this.props.SubFolder + '/',
            mainfolder = this.props.MainFolder + '/'
            console.log(this.props.filepath,this.props.SubFolder,this.props.MainFolder)


        const anchorTags = (fpath) => {
            this.fetchFileContent(fpath, url).then(() => {
                this.fetchPageData(userid, fpath).then(() => {
                    this.autoHighlight(userid, fpath).then(() => {
                        if (document.getElementById('mainboxdiv') && document.getElementById('mainnavbar')) {
                            let mainnvabarHeight = document.getElementById('mainnavbar').clientHeight + 1
                            let o = document.getElementById('mainboxdiv').clientHeight
                            console.log(mainnvabarHeight, o)
                            document.getElementById("mainboxdiv").style.height = `calc(100% - ${mainnvabarHeight}px)`
                        }
                        this.trackHistory(fpath)
                        // ========================================================================================================
                        // This code used to replace the anchor tag href attribute url
                        // And also open then in new tab 
                        $('#SinglePageIframeSearched').find('a').each(function () {
                            let href = $(this).attr('href')
                            let isJsFun = false,
                                isSamePageLink = false
                            if (href != undefined && href.toString().toLowerCase().includes('javascript:void(0)')) {
                                isJsFun = true
                                // $(this).attr('onclick','clickHandler();')
                                // const script = document.createElement("script");

                                // script.src = "https://use.typekit.net/foobar.js";
                                // script.async = true;

                                // document.body.appendChild(script);
                            }
                            // console.log(href)
                            if (href != undefined && href.includes('#')) {
                                isSamePageLink = true
                            }
                            let file_n = href
                            // console.log(isJsFun)
                            if (href != undefined && !isJsFun) {
                                $(this).mousedown(function (e) {
                                    if (e.button == 2) {
                                        let r_href = href
                                        if (r_href.includes('../../')) {
                                            r_href = r_href.replace('../../', '')
                                            console.log(r_href)
                                        } else if (r_href.includes('../')) {
                                            r_href = r_href.replace('../', mainfolder)
                                            subfolder = r_href.split(mainfolder)[1].substring(0, r_href.split(mainfolder)[1].indexOf('/')) + '/'
                                        } else {
                                            r_href = mainfolder + subfolder + r_href
                                        }
                                        let temp_url = 'Assets/data5t/' + r_href
                                        console.log(mainfolder, subfolder, temp_url)
                                        const isPPT = ['.pptx', '.ppt'].some((el) => temp_url.toLowerCase().includes(el))
                                        if (!isPPT) {
                                            // window.open(
                                            //     'https://hexbss.xyz/lawcrux/lawcrux/web/'+temp_url,
                                            //     '_blank'
                                            // )
                                            $(this).attr('href', sessionStorage.getItem('url') + temp_url)
                                        }
                                    }
                                });

                                $(this).on('click', (e) => {
                                    mainfolder = fpath.split('/')[2] + '/';
                                    subfolder = fpath.split('/')[3] + '/';
                                    let l_href = href
                                    console.log(href)
                                    if (l_href.includes('../../')) {
                                        l_href = l_href.replace('../../', '')
                                    } else if (l_href.includes('../')) {
                                        l_href = l_href.replace('../', mainfolder)
                                        subfolder = l_href.split(mainfolder)[1].substring(0, l_href.split(mainfolder)[1].indexOf('/')) + '/'
                                    } else {
                                        l_href = mainfolder + subfolder + l_href
                                    }
                                    let temp_url = 'Assets/data5t/' + l_href
                                    console.log(mainfolder, subfolder, temp_url)
                                    const isPPT = ['.pptx', '.ppt'].some((el) => temp_url.toLowerCase().includes(el))
                                    if (!isSamePageLink) {
                                        $(this).attr('href', '#')
                                    }
                                    if (!temp_url.includes('#')) {
                                        console.log('Test')
                                        if (isPPT) {
                                            console.log('isPPT')
                                            scope.setState({ isOpenSummary: true, summaryData: { foldername: mainfolder + subfolder, filename: file_n } })
                                        
                                        } else {
                                            scope.setState({ filepath: temp_url, allEnds: "0", allStarts: '0', startEndArray: [] }, () => {
                                                anchorTags(temp_url)
                                            })
                                        }
                                    }
                                })
                            }
                        });
                        // ========================================================================================================

                        // ========================================================================================================
                        // This code used to correct the object tag data attribute url
                        // In frames
                        // $('#SinglePageIframeSearched').find('object').each(function () {
                        //     let href = $(this).attr('data')
                        //     let r_href = href
                        //     if (r_href.includes('../../')) {
                        //         r_href = r_href.replace('../../', '')
                        //     } else if (r_href.includes('../')) {
                        //         r_href = r_href.replace('../', mainfolder)
                        //         subfolder = r_href.split(mainfolder)[1].substring(0, r_href.split(mainfolder)[1].indexOf('/')) + '/'
                        //     } else {
                        //         r_href = mainfolder + subfolder + r_href
                        //     }
                        //     let temp_url = 'Assets/data5t/' + r_href
                        //     $(this).attr('data', sessionStorage.getItem('url') + temp_url)
                        // });
                        // ========================================================================================================
                    }).catch(err => console.error(err))
                }) .then(() => {
                    this.setState({ isFileFound: true, isFetchFileApiHitComplete: true });
                    // console.warn('kkgs')
                    if (this.props.filepath.includes("gt/gtcgst/index_01.htm")) {
                        this.setState({ startDemo2: true })
                        // console.warn("showDemo2")
                    }
                })
            }).catch(err => this.setState({ isFileFound: false, isFetchFileApiHitComplete: true, isBookmarkAddComplete: true }, () => console.error(err)))
        }
        console.log(filepath)
        anchorTags(filepath)
    }
    MarkDoneIllustration2 = () => {
        let obj2 = {
            'query': `[dbo].[Lawcrux_Web_Proc_Illustration_Update] @UserID='{0}', @Illustration='{1}' `,// @RecID='{0}'
            'queryArr': [`${sessionStorage.getItem('userid')}`, '2']
        }
        Hexapi(obj2).then(resp => {
            console.log(resp)
        });
    }

    MarkDoneIllustration3 = () => {
        let obj2 = {
            'query': `[dbo].[Lawcrux_Web_Proc_Illustration_Update] @UserID='{0}', @Illustration='{1}' `,// @RecID='{0}'
            'queryArr': [`${sessionStorage.getItem('userid')}`, '3']
        }
        Hexapi(obj2).then(resp => {
            console.log(resp)
        });
    }
    getVerificationStatus(verified) {
        if (verified) {
            const printDiv = () => {
                var divContents = document.getElementById("SinglePageIframeSearched").innerHTML;
                console.log(divContents)
                divContents = divContents.replaceAll('href', 'data-href')
                var style = '<div style= "position: absolute;top: 0px;left : 0px ;right: 440px;bottom: 600px;background-image: url(lawcrux.png); background-repeat: no-repeat; background-positon: center;background-size: 164px 67px;width: 159.875 ;height: 72.111;"></div>';
                style += '<div style= "position: absolute;top: 9px;left : 160px ;right: 0px;bottom: 600px; width: 188.875 ;height: 56.111;border-left: 1px solid #000; "></div>';

                style += '<div style= "position: absolute;top: 9px;left : 170px ;right: 0px;bottom: 600px; width: 188.875 ;height: 87.111; ">Smartbook <br> For <br> Indirect Tax</div>'; style += '<div style="position: absolute; top: 6px; right: 17px; padding: 0px; color: white; background-color: white; color: black ;font-size: 15px ;border-bottom: 1px solid black;word-spacing: 0.5rem;padding-bottom: 0.2rem;height: 18.313 ; width: 178.698; text-align: center;">www.lawcrux.com</div>';
                style += '</div>';
                style += '<div style="position: absolute; top: 32px; right: 16px; padding: 0px; color: white; background-color: white; color: black ;font-size: 9px ;word-spacing: 0.3rem">• GST • Custom • FTP • FEMA • SEZ</div>';
                style += '</div>';
                style += '<div style="position: absolute; top: 47px; right: 16px; padding: 0px; color: white; background-color: white; color: black ;font-size: 9px ;word-spacing: 0.2rem">• Central Excise • Service Tax • Allied Acts </div>';
                style += '</div>';

                var a = window.open('', '', 'height=500, width=500');

                a.document.write(style);
                a.document.write('<html>');
                a.document.write('<body>');
                a.document.write('<br>');
                a.document.write('<br>');
                a.document.write('<br>');
                a.document.write('<hr style="border: none; border-top: 1px solid black; font-weight: bold;">');
                a.document.write('</body></html>');
                a.document.write('<html>');
                a.document.write(divContents);

                setTimeout(() => {

                    a.document.close();
                    a.print();
                }, 1000);


                // var divContents = this.state.htmlFileOriginalContent 
                // divContents = divContents.getElementsByTagName('a').each(function (index, element) {
                //     element.removeAttribute('href');
                // })
                // a.document.write('<html>');
                // // a.document.write('<body > <h1>Div contents are <br>');
                // a.document.write(divContents);
                // a.document.write('</body></html>');
            }
            // const printIframe = () => {
            //     // var iframe = $('#iframeID')[0];
            //     var iframe = document.getElementById('iframeID').contentWindow.document.body.innerHTML;
            //     iframe = iframe.replaceAll('<a', '<span').replaceAll('</a>', '</span>')
            //     // iframe.contentWindow.focus();
            //     var a = window.open('', '', 'height=500, width=500');
            //     a.document.write('<html>');
            //     // a.document.write('<body > <h1>Div contents are <br>');
            //     a.document.write(iframe);
            //     a.document.write('</body></html>');
            //     a.document.close();
            //     a.print();
            // }
            // this.state.isOpenInIframe ?
            //     // console.log('hello')
            //     printIframe()
            //     :
            printDiv()
        }
    }

    setLawAsOnDate(date) {
        console.log(date)

        this.setState({ isOpenLawAsOnDateModal: false })
        date = date.split('-')
        let user_selected_date = new Date(date[1] + '/' + date[1] + '/' + date[0])
        let showing_content = this.state.htmlFileOriginalContent

        const checkDates = (string) => {
            let updatedString = string
            let lastIndex = 0
            while (true) {
                let xstart_index = updatedString.indexOf("<!--zisqxyx", lastIndex);
                let xend_index = updatedString.indexOf("<!--zieqxyx", lastIndex);
                let dateintext = updatedString.substring(xstart_index + 11, xstart_index + 19)
                console.log(xstart_index, xend_index, lastIndex)

                if (xstart_index < 0 || xstart_index > xend_index) {
                    break;
                }


                let mentioned_date = new Date(`${dateintext.substring(2, 4)}/${dateintext.substring(0, 2)}/${dateintext.substring(4)}`)
                const diffTime = mentioned_date - user_selected_date;
                const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));


                if (diffDays > 0) {
                    /// we have to replace the text
                    // add replace logic in updatedstring
                    let middle_part = updatedString.substring(xstart_index, xend_index + 22);
                    console.log('================middle====================')
                    console.log(middle_part)
                    console.log('================middle _END====================')
                    updatedString = updatedString.replace(middle_part, ' ');

                } else {
                    lastIndex = xend_index + 1;
                }
            }

            lastIndex = 0
            while (true) {
                let xstart_index = updatedString.indexOf("<!--zosqxyx", lastIndex);
                let xend_index = updatedString.indexOf("<!--zoeqxyx", lastIndex);
                let dateintext = updatedString.substring(xstart_index + 11, xstart_index + 19)

                if (xstart_index < 0 || xstart_index > xend_index) {
                    break;
                }
                console.log(xstart_index, xend_index)


                let mentioned_date = new Date(`${dateintext.substring(2, 4)}/${dateintext.substring(0, 2)}/${dateintext.substring(4)}`)
                const diffTime = mentioned_date - user_selected_date;
                const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

                if (diffDays > 0) {
                    lastIndex = xend_index + 1;
                } else {
                    /// we have to replace the text
                    // add replace logic in updatedstring
                    let middle_part = updatedString.substring(xstart_index, xend_index + 22);
                    updatedString = updatedString.replace(middle_part, ' ');

                    console.log('================middle====================')
                    console.log(middle_part)
                    console.log('================middle _END====================')
                }
            }


            for (let i = 0; i < 200; i++) {
                try {
                    let found = 'N'
                    if (updatedString.includes("<a href=\"#" + i + "\">")) {
                        found = "Y";
                    }
                    if (updatedString.includes("<a HREF=\"#" + i + "\">")) {
                        found = "Y";
                    }
                    if (updatedString.includes("<A HREF=\"#" + i + "'\">")) {
                        found = "Y";
                    }

                    if (found == "N") {
                        let xsearch = `<a name=${i}`,
                            start_index = updatedString.indexOf(xsearch)

                        if (start_index < 0) {
                            xsearch = `<a name="${i}"`
                            start_index = updatedString.indexOf(xsearch)
                        }

                        let xsearch_next = `<a name=${i + 1}`,
                            xend_index = updatedString.indexOf(xsearch_next)

                        if (xend_index < 0) {
                            xsearch_next = `<a name="${i + 1}"`
                            xend_index = updatedString.indexOf(xsearch_next)
                        }



                        if (start_index > 1 && xend_index > start_index + 4) {
                            let middle_part = updatedString.substring(start_index, xend_index);
                            updatedString = updatedString.replace(middle_part, ' ');
                            // xfirst_part = xbalance_content.Substring(0, xstart_index);
                            // xsecond_part = xbalance_content.Substring(xend_index, xbalance_content.Length - xend_index);
                            // xcut_content = xbalance_content.Substring(xstart_index, xend_index - xstart_index);
                            // xbalance_content = xfirst_part + " " + xsecond_part;
                        }


                        if (start_index > 1 && xend_index < 0) {
                            let xfirst_part = updatedString.substring(0, start_index);
                            updatedString = xfirst_part + "</body> ";

                        }
                        // if (updatedString.includes(searchPart)) {

                        // }
                        // let xsearch = "<a name=" + xnumber;
                        // let xstart_index = xbalance_content.ToUpper().IndexOf(xsearch.ToUpper());
                    }

                } catch {
                    console.error('some error in law as on date')
                }
            }

            let newText = updatedString.replaceAll('�', '"').replaceAll('[helldod old', '').replaceAll('[helldod', "").replaceAll('helldod]', "").replaceAll('<sup>old</sup>', '')
            return newText
        }
        let newText = checkDates(showing_content)

        this.setState({
            htmlFileShowContent: newText,
            // htmlFileShowContentBackup: newText
        })
    }

    getUtilityFile = (obj) => {
        console.log(obj)
        let keys = Object.keys(obj);
        let url = [];
        let mainfolder = ''
        let subfolder = '';
        let filename = '';
        let filepath = 'Assets/Data5t/'
        console.log(obj[keys[0]].foldername.substring(0, 2))
        for (let i = 0; i < keys.length; i++) {
            if (obj[keys[i]].filename != "") {
                filename = obj[keys[i]].filename
                mainfolder = obj[keys[i]].foldername.substring(0, 2);
                subfolder = obj[keys[i]].foldername.substring(2);
                if (filename != "") {
                    let path = 'Assets/Data5t/' + mainfolder + '/' + subfolder + '/' + filename
                    var fd = new FormData();
                    fd.append(Aesencryption('filename'), Aesencryption(path))
                    axios({
                        mode: 'cors',
                        method: "POST",
                        headers: { "Content-Type": "multipart/form-data" },
                        url: `${sessionStorage.getItem('url')}fetchfile.php`,
                        data: fd
                    }).then(resp => {
                        console.log(resp)
                        if (resp.data == "") {
                            let path = 'Assets/Data5t/' + mainfolder + '/' + subfolder + '/' + filename.replaceAll('_', "z")
                            var fd = new FormData();
                            fd.append(Aesencryption('filename'), Aesencryption(path))
                            axios({
                                mode: 'cors',
                                method: "POST",
                                headers: { "Content-Type": "multipart/form-data" },
                                url: `${sessionStorage.getItem('url')}fetchfile.php`,
                                data: fd
                            }).then(resp => {
                                console.log(resp)
                                this.setState({filepath:path})
                                this.anchorTags(path)
                            })
                        } else {
                            this.setState({filepath:path})
                            this.anchorTags(path)
                        }
                    }).then(() => {
                        // this.setState({ utilityFilename: url[0] });
                        // this.setState(({
                        //     utilityMainfolder: mainfolder,
                        //     utilitySubfolder: subfolder,
                        //     // utilityFilename:filename,
                        //     // utilityFilename: filepath + mainfolder + '/' + subfolder + '/' + obj[keys[i]].filename.replaceAll('_', 'z')

                        // }))
                        // this.setState({ utilityOpen: true })
                        
                    })
                }
            }
        }

    }
    openRespectiveScreen = () => {
        console.log(this.state.pageData)
        if (this.state.utilfaq == true) {
            let obj = {}
            obj['faq'] = this.state.pageData['faq'][0]
            console.log(obj);
            this.setState({ /*isOpenMagicwondFrame: true,*/ header: 'FAQ', utilfaq: false })
            this.setState(() => { this.getUtilityFile(obj); })
        }
        else if (this.state.utilInterpretation == true) {
            let obj = {}
            obj['comentry'] = this.state.pageData['comentry'][0]
            this.setState({ /*isOpenMagicwondFrame: true,*/ header: 'Interpretation', utilInterpretation: false }, () => { this.getUtilityFile(obj); })
        }
        else if (this.state.utilIllustration == true) {
            // let obj={description:'testing',filepath:this.state.filepath,userid:sessionStorage.getItem('userid')}
            // this.setState({fileData:obj})
            // this.setState({OpenSearchModal:true});
            let obj = {}
            obj['illustration'] = this.state.pageData['illustration'][0]
            this.setState({ header: 'Illustration', utilIllustration: false }, () => { this.getUtilityFile(obj); })
        }
        else if (this.state.utilFlowchar == true) {
            let data = this.state.pageData['ppt'][0]
            // console.log(data)
            if (data.filename != undefined && data.filename.includes('.htm')) {
                let multipleframes = {}
                multipleframes['ppt'] = data
                this.setState({header: 'ppt', utilFlowchar: false }, () => { this.getUtilityFile(); })
            } else {
                this.setState({ summaryData: data, header: 'Flow Chart', isOpenSummary: true })
            }

        }
        else if (this.state.utilSummary == true) {
            let data = this.state.pageData['summary'][0] 
            if (data.filename != undefined && data.filename.includes('.htm')) {
                console.log(data)
                let multipleframes = {}
                multipleframes['summary'] = data
                this.setState({ header: 'summary', frameData: multipleframes, utilSummary: false }, () => { this.getUtilityFile(); })
            } else {
                console.log(data)
                this.setState({ summaryData: data, header: 'Summary', isOpenSummary: true })
            }
        }
    }
    DoHighlightContent = (content) => {
        console.log('running')
        if (this.props.searchitem !== "" && this.props.searchitem !== undefined && this.props.searchitem !== null) {
            let searchTerm = this.props.searchitem.toLowerCase();
            searchTerm = searchTerm.trim();
            let highlightedContent;
            if (this.props.SearchType == "1" || this.props.SearchType == "3") {
                const searchvalue = searchTerm.split(" ");
                console.log(searchvalue)
                let valuehighlight=content;
                searchvalue.map(term => {
                    valuehighlight = valuehighlight.replace(/>([^<]*)</g, (match, group) => {
                        let lowerCaseGroup = group;
                        // console.log(lowerCaseGroup)
                        lowerCaseGroup = lowerCaseGroup.replace(/\s+/g, " ");
                        const regex = new RegExp(`\\b${term}\\b`, 'gi')
                        const highlightedText = lowerCaseGroup.replace(regex, (match) => `<em style="background-color: yellow;">${match}</em>`);
                        return `>${highlightedText}<`;
                    });
                });
                highlightedContent = valuehighlight;
            } else {
                highlightedContent = content.replace(/>([^<]*)</g, (match, group) => {
                    let lowerCaseGroup = group;
                    // lowerCaseGroup = lowerCaseGroup.replace(/-/g, '');
                    // console.log(lowerCaseGroup)
                    lowerCaseGroup = lowerCaseGroup.replace(/\s+/g, " ");
                    // const escapedSearchTerm = searchTerm.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&');
                    const regexString = `\\b${searchTerm.replace(/[.\-]/g, '\\$&?')}\\b`;
                    const regex = new RegExp(regexString, 'gi');
                    // const escapedSearchTerm = searchTerm.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&');
                    // const regex = new RegExp(`\\b${escapedSearchTerm.replace(/-/g, '(-)?')}\\b`, 'gi');
                    const highlightedText = lowerCaseGroup.replace(regex, (match) => `<em style="background-color: yellow;">${match}</em>`);
                    // console.log(lowerCaseGroup.match(regex))
                    return `>${highlightedText}<`;
                });

            }
            // console.log(highlightedContent)
            return { __html: highlightedContent };
        }
        return { __html: content };
    }
    ScreenMode=()=>{

        if (!this.state.isDarkMode) {
        
        document.getElementById('mainboxdiv').innerHTML = document.getElementById('mainboxdiv').innerHTML.replaceAll('background-color: black;', 'background-color: transparent;')
        
        this.setState({ highlightColor: 'lightgreen' }, () => {
        
        this.autoHighlight(this.state.userid, this.state.filepath)
        
        console.log(this.state.highlightColor)
        
        })
        
        } else {
        
        this.setState({ highlightColor: 'orange' }, () => {
        
        this.autoHighlight(this.state.userid, this.state.filepath)
        
        console.log(this.state.highlightColor)
        
        })
        
        }
        
        }

        HighlightillustartionContent = (content, text) => {
            // const text = "Relevant Updates";
            console.log('running')
            if (content != "") {
                let searchTerm = text.toLowerCase();
                searchTerm = searchTerm.trim();
                const highlightedContent = content.replace(/>([^<]*)</g, (match, group) => {
                    let lowerCaseGroup = group.toLowerCase();
                    lowerCaseGroup = lowerCaseGroup.replace(/\s+/g, " ");
                    if (lowerCaseGroup.includes(searchTerm)) {
                        let highlightedGroup = "";
                        if (text == "section-7") {
                            highlightedGroup = lowerCaseGroup.replace(searchTerm, `<small style="background-color: white; position:relative; z-index:4;"><span style="pointer-events: none;">${searchTerm}</span></small>`);
                            } else {
                            highlightedGroup = lowerCaseGroup.replace(searchTerm, `<small style="background-color: white; position:relative; z-index:4;pointer-events: none;">${searchTerm}</small>`);
                            }
                        return `>${highlightedGroup}<`;
                    } else {
                        return match;
                    }
                });
                // console.log(highlightedContent)
                this.setState({ showIllustrationtext: highlightedContent })
                return { __html: highlightedContent };
            }
            console.log(content)
            return { __html: content };
        }
    
        MarkDoneIllustration = () => {
            let obj2 = {
                'query': `[dbo].[Lawcrux_Web_Proc_Illustration_Update] @UserID='{0}', @Illustration='{1}' `,// @RecID='{0}'
                'queryArr': [`${sessionStorage.getItem('userid')}`, '0']
            }
            Hexapi(obj2).then(resp => {
                console.log(resp[''])
            });
        }
    render() {
        // console.log(this.state.filepath)
        // console.log(this.props.showHighlightttext)
        return (
            <>
                <Modal fullscreen={true} id={'custom_frame_modal'} centered style={{ backgroundColor: 'rgba(0,0,0,0.6)' }} animation={true} show={this.state.isModalOpen} onHide={() => this.setState({ isModalOpen: false })} keyboard={false} backdrop='static'>
                    <Modal.Body>
                    {
                            this.state.showDemo && this.state.markIllustration==true?
                                <>
                                    <div class="react-portal" style={{ /*height: '100%',*/ overflow: 'hidden', width: '100%' }}>
                                        <div class={this.state.itemclicked3 ? "popover left" : "popover "} id="popovermodal" style={
                                            this.state.itemclicked6 ? {} : this.state.itemclicked5 ? { width: '428px', zIndex: '121', left: '43%', top: '60%' } : this.state.itemclicked4 ? { width: '428px', zIndex: '121', left: '24%', top: '26%' } : this.state.itemclicked3 ? { width: '428px', zIndex: '121', left: '4%', top: '29%' } : this.state.itemclicked2 ? { width: '428px', zIndex: '121', left: '50%', top: '8%' } : this.state.itemclicked1 ? { width: '428px', zIndex: '121', left: '40%', top: '8%' } : { width: '428px', zIndex: '121', left: '33%', top: '8%' }
                                            // itemclicked ? { width: '428px', zIndex: '121', left: '10%', top: '14%' } : itemclicked ? { width: '428px', zIndex: '121', left: '75%', top: '17%' } :itemclicked3 ? { width: '428px', zIndex: '121', left: '75%', top: '17%' }  : { width: '428px', zIndex: '121', left: '75%', top: '13%' }
                                        }>
                                            {/* <span class="popover__arrow-item" style={{ left: '50%' }}></span> */}
                                            <div style={{ width: '96%', margin: 'auto' }}>
                                                <div class="popover__header " style={{ display: 'flex', justifyContent: "space-evenly", alignItems: 'center' }}>
                                                    <div>
                                                        <span style={{ fontFamily: 'Roboto Condensed', fontSize: '22px', display: 'flex', justifyContent: 'center' }} >
                                                            {
                                                                this.state.itemclicked1 ?
                                                                    null
                                                                    :
                                                                    "Viewing Preference :"
                                                            }
                                                            {
                                                                this.state.itemclicked2 == false && this.state.itemclicked1 == true ?
                                                                    this.state.isHelldodVisible ?
                                                                        "Read with Current Law :" : "Read With Old Law :"
                                                                    :
                                                                    null
                                                            }
                                                            {
                                                                this.state.itemclicked2 == true && this.state.itemclicked3 == false ?
                                                                    this.props.MainFolder == "cl" ? "Case Law" : "Utilities"
                                                                    :
                                                                    null
                                                            }
                                                            {
                                                                this.state.itemclicked3 == true && this.state.itemclicked4 == false ?
                                                                    "Live Tagging : "
                                                                    :
                                                                    null
                                                            }
                                                            {
                                                                this.state.itemclicked4 == true && this.state.itemclicked5 == false ?
                                                                    "Relevant Updates : "
                                                                    :
                                                                    null
                                                            }
                                                            {
                                                                this.state.itemclicked5 == true && this.state.itemclicked6 == false ?
                                                                    "Sneak Peak : "
                                                                    :
                                                                    null
                                                            }
                                                            {/* {itemclicked ?"Search Icon "  : itemclicked2 ? "Bookmark Icon" : itemclicked3 ? "GST Menu"  :"History Icon"  } */}
                                                        </span>
                                                    </div>
                                                    <span>
                                                        {
                                                            this.state.itemclicked1 ?
                                                                null
                                                                :
                                                                <RemoveRedEyeIcon />
                                                        }
                                                        {
                                                            this.state.itemclicked2 == false && this.state.itemclicked1 == true ?
                                                                this.state.isHelldodVisible ?
                                                                    <span style={{ fontSize: "14px", fontWeight: "600" }}>ABC</span> : <span style={{ fontSize: "14px", fontWeight: "600", textDecoration: 'line-through' }}>ABC</span>

                                                                :
                                                                null
                                                        }
                                                        {
                                                            this.state.itemclicked2 == true && this.state.itemclicked3 == false ?
                                                                this.props.MainFolder == "cl" ? <ReportProblemIcon /> : <AutoFixHighIcon />
                                                                :
                                                                null
                                                        }

                                                        {/* {itemclicked ? <HistoryIcon /> : itemclicked2 ? <SearchIcon /> : itemclicked3 ? <BookmarksIcon /> : null} */}
                                                    </span>
                                                </div>
                                                <div class="popover__body=" style={{ marginTop: '0' }}>
                                                    <span >
                                                        {
                                                            this.state.itemclicked1 ?
                                                                null
                                                                :
                                                                "Adjust the font size and colour mode as per your reading comfort"
                                                        }
                                                        {
                                                            this.state.itemclicked2 == false && this.state.itemclicked1 == true ?
                                                                this.state.isHelldodVisible ?
                                                                    "Toggle between omitted law and current law.Omitted law is shown in red" : "Toggle between omitted law and current law.Omitted law is shown in red"
                                                                :
                                                                null
                                                        }
                                                        {
                                                            this.state.itemclicked2 == true && this.state.itemclicked3 == false ?
                                                                this.props.MainFolder == "cl" ? "Case Law features" : "Explore tools for deeper understanding of the law"
                                                                :
                                                                null
                                                        }
                                                        {
                                                            this.state.itemclicked3 == true && this.state.itemclicked4 == false ?
                                                                "Relevant circulars / notifications/ Caselaws are tagged with respective sub-section"
                                                                :
                                                                null
                                                        }
                                                        {
                                                            this.state.itemclicked4 == true && this.state.itemclicked5 == false ?
                                                                "Important notifications, circulars , caselaws and Advance Rulings of this section are stored here"
                                                                :
                                                                null
                                                        }
                                                        {
                                                            this.state.itemclicked5 == true && this.state.itemclicked6 == false ?
                                                                "Rollover Texts provides sneak peak of the definitions specified as per law"
                                                                :
                                                                null
                                                        }
                                                    </span>
                                                </div>
                                                <div class="popover_footer" style={{ display: 'flex', justifyContent: 'space-between', marginTop: '5px' }}>

                                                    <span style={{}} >
                                                        {
                                                            this.state.itemclicked1 == true ?
                                                                <FiberManualRecordOutlinedIcon style={{ height: '8px', width: '15px' }} />
                                                                :
                                                                <FiberManualRecordIcon style={{ height: '15px', width: '15px', color: 'rgb(241, 108, 108)' }} />
                                                        }
                                                        {
                                                            this.state.itemclicked2 == false && this.state.itemclicked1 == true ?
                                                                <FiberManualRecordIcon style={{ height: '15px', width: '15px', color: 'rgb(241, 108, 108)' }} />
                                                                :
                                                                <FiberManualRecordOutlinedIcon style={{ height: '8px', width: '15px' }} />
                                                        }
                                                        {
                                                            this.state.itemclicked2 == true && this.state.itemclicked3 == false ?
                                                                <FiberManualRecordIcon style={{ height: '15px', width: '15px', color: 'rgb(241, 108, 108)' }} />
                                                                :
                                                                <FiberManualRecordOutlinedIcon style={{ height: '8px', width: '15px' }} />
                                                        }
                                                        {
                                                            this.state.itemclicked3 == true && this.state.itemclicked4 == false ?
                                                                <FiberManualRecordIcon style={{ height: '15px', width: '15px', color: 'rgb(241, 108, 108)' }} />
                                                                :
                                                                <FiberManualRecordOutlinedIcon style={{ height: '8px', width: '15px' }} />
                                                        }
                                                        {
                                                            this.state.itemclicked4 == true && this.state.itemclicked5 == false ?
                                                                <FiberManualRecordIcon style={{ height: '15px', width: '15px', color: 'rgb(241, 108, 108)' }} />
                                                                :
                                                                <FiberManualRecordOutlinedIcon style={{ height: '8px', width: '15px' }} />
                                                        }
                                                        {
                                                            this.state.itemclicked5 == true && this.state.itemclicked6 == false ?
                                                                <FiberManualRecordIcon style={{ height: '15px', width: '15px', color: 'rgb(241, 108, 108)' }} />
                                                                :
                                                                <FiberManualRecordOutlinedIcon style={{ height: '8px', width: '15px' }} />
                                                        }
                                                    </span>
                                                    {
                                                        this.state.itemclicked1 ?
                                                            null
                                                            :
                                                            <button style={{ width: '20%', marginBottom: '5px', borderRadius: '5px', backgroundColor: '#f16c6c', color: 'white', border: 'none' }} onClick={() => this.setState({ itemclicked1: true })}>Next</button>
                                                    }
                                                    {
                                                        this.state.itemclicked2 == false && this.state.itemclicked1 == true ?
                                                            <button style={{ width: '20%', marginBottom: '5px', borderRadius: '5px', backgroundColor: '#f16c6c', color: 'white', border: 'none' }} onClick={() => this.setState({ itemclicked2: true })}>Next</button>
                                                            :
                                                            null
                                                    }
                                                    {
                                                        this.state.itemclicked2 == true && this.state.itemclicked3 == false ?
                                                            <button style={{ width: '20%', marginBottom: '5px', borderRadius: '5px', backgroundColor: '#f16c6c', color: 'white', border: 'none' }} onClick={() => { this.setState({ itemclicked3: true }); this.HighlightillustartionContent(this.state.htmlFileShowContent, "*") }}>Next</button>
                                                            :
                                                            null
                                                    }
                                                    {
                                                        this.state.itemclicked3 == true && this.state.itemclicked4 == false ?
                                                            <button style={{ width: '20%', marginBottom: '5px', borderRadius: '5px', backgroundColor: '#f16c6c', color: 'white', border: 'none' }} onClick={() => { this.setState({ itemclicked4: true }); this.HighlightillustartionContent(this.state.htmlFileShowContent, "Relevant Updates") }}>Next</button>
                                                            :
                                                            null
                                                    }
                                                    {
                                                        this.state.itemclicked4 == true && this.state.itemclicked5 == false ?
                                                            <button style={{ width: '20%', marginBottom: '5px', borderRadius: '5px', backgroundColor: '#f16c6c', color: 'white', border: 'none' }} onClick={() => { this.setState({ itemclicked5: true }); this.HighlightillustartionContent(this.state.htmlFileShowContent, "business") }}>Next</button>
                                                            :
                                                            null
                                                    }
                                                    {
                                                        this.state.itemclicked5 == true && this.state.itemclicked6 == false ?
                                                            <button style={{ width: '20%', marginBottom: '5px', borderRadius: '5px', backgroundColor: '#f16c6c', color: 'white', border: 'none' }} onClick={() => {
                                                                this.setState({ itemclicked6: true, showDemo: false ,showDemo2:false,markIllustration:false});
                                                                this.MarkDoneIllustration2()
                                                            }}>Got it</button>
                                                            :
                                                            null
                                                    }
                                                    {/* <button style={{ width: '20%', marginBottom: '5px', borderRadius: '5px', backgroundColor: '#f16c6c', color: 'white', border: 'none' }} onClick={() => { setitemclicked3(true); setshowdemo(false) }}>Got it</button> */}
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </>
                                :
                                <>

                                </>
                        }
                        {
                            this.state.showDemo2 && this.state.startDemo2 && this.state.markIllustration==true?
                                <>
                                    <div class="react-portal" style={{ /*height: '100%',*/ overflow: 'hidden', width: '100%' }}>
                                        <div class="popover left" id="popovermodal" style={
                                            this.state.content1 ? {} : { width: '428px', zIndex: '121', left: '7%', top: '49%' }
                                            // itemclicked ? { width: '428px', zIndex: '121', left: '10%', top: '14%' } : itemclicked ? { width: '428px', zIndex: '121', left: '75%', top: '17%' } :itemclicked3 ? { width: '428px', zIndex: '121', left: '75%', top: '17%' }  : { width: '428px', zIndex: '121', left: '75%', top: '13%' }
                                        }>
                                            {/* <span class="popover__arrow-item" style={{ left: '50%' }}></span> */}
                                            <div style={{ width: '96%', margin: 'auto' }}>
                                                <div class="popover__header " style={{ display: 'flex', justifyContent: "space-evenly", alignItems: 'center' }}>
                                                    <div>
                                                        {/* <span style={{ fontFamily: 'Roboto Condensed', fontSize: '22px', display: 'flex', justifyContent: 'center' }} >
                                                            {
                                                                this.state.content1 ?
                                                                    null
                                                                    :
                                                                    "Section 7"
                                                            }
                                                        </span> */}
                                                    </div>

                                                </div>
                                                <div class="popover__body=" style={{ marginTop: '0' }}>
                                                    <span >
                                                        {
                                                            this.state.content1 ?
                                                                null
                                                                :
                                                                "Click on Section 7 to take a tour of available features"
                                                        }
                                                    </span>
                                                </div>
                                                <div class="popover_footer" style={{ display: 'flex', justifyContent: 'space-between', marginTop: '5px' }}>

                                                    <span style={{}} >
                                                        {
                                                            this.state.content1 == true ?
                                                                <FiberManualRecordOutlinedIcon style={{ height: '8px', width: '15px' }} />
                                                                :
                                                                <FiberManualRecordIcon style={{ height: '15px', width: '15px', color: 'rgb(241, 108, 108)' }} />
                                                        }
                                                    </span>
                                                    {/* {
                                                        this.state.content1 ?
                                                            null
                                                            :
                                                            <button style={{ width: '20%', marginBottom: '5px', borderRadius: '5px', backgroundColor: '#f16c6c', color: 'white', border: 'none' }} onClick={() => { this.setState({ showDemo2: false, content1: true }); }}>Got it</button>
                                                    } */}
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </>
                                :
                                null
                        }

{
                            this.state.showcaseDemo==true?
                                <>
                                    <div class="react-portal" style={{ /*height: '100%',*/ overflow: 'hidden', width: '100%' }}>
                                        <div class="popover left" id="popovermodal" style={
                                            this.state.caseillustration1 ? {} : { width: '428px', zIndex: '121', left: '15%', top: '10%' }
                                            // itemclicked ? { width: '428px', zIndex: '121', left: '10%', top: '14%' } : itemclicked ? { width: '428px', zIndex: '121', left: '75%', top: '17%' } :itemclicked3 ? { width: '428px', zIndex: '121', left: '75%', top: '17%' }  : { width: '428px', zIndex: '121', left: '75%', top: '13%' }
                                        }>
                                            {/* <span class="popover__arrow-item" style={{ left: '50%' }}></span> */}
                                            <div style={{ width: '96%', margin: 'auto' }}>
                                                <div class="popover__header " style={{ display: 'flex', justifyContent: "space-evenly", alignItems: 'center' }}>
                                                    <div>
                                                        <span style={{ fontFamily: 'Roboto Condensed', fontSize: '22px', display: 'flex', justifyContent: 'center' }} >
                                                            {
                                                                this.state.caseillustration1 ?
                                                                    null
                                                                    :
                                                                    "Case Status Bar :"
                                                            }
                                                        </span>
                                                    </div>

                                                </div>
                                                <div class="popover__body=" style={{ marginTop: '0' }}>
                                                        {
                                                            this.state.caseillustration1 ?
                                                                null
                                                                :
                                                                <div>
                                                                    <span>&lt;&lt; view case history</span><br/>
                                                                    <span>&gt;&gt; view next case</span><br/>
                                                                    <span>Cited in : lists the caselaws upon which current judgement is relied upon</span><br/>
                                                                    <span>Referred in : lists the caselaws which rely on this judgement</span>
                                                                </div>
                                                        }
                                                </div>
                                                <div class="popover_footer" style={{ display: 'flex', justifyContent: 'space-between', marginTop: '5px' }}>

                                                    <span style={{}} >
                                                        {
                                                            this.state.caseillustration1 == true ?
                                                                <FiberManualRecordOutlinedIcon style={{ height: '8px', width: '15px' }} />
                                                                :
                                                                <FiberManualRecordIcon style={{ height: '15px', width: '15px', color: 'rgb(241, 108, 108)' }} />
                                                        }
                                                    </span>
                                                    {
                                                        this.state.caseillustration1 ?
                                                            null
                                                            :
                                                            <button style={{ width: '20%', marginBottom: '5px', borderRadius: '5px', backgroundColor: '#f16c6c', color: 'white', border: 'none' }} onClick={() => { this.setState({ showcaseDemo:false,markIllustration:false });  this.MarkDoneIllustration3() }}>Got it</button>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                                :
                                null
                        }
                        <div
                            style={(this.state.showDemo == true && this.state.itemclicked6 == false && this.state.markIllustration==true) || (this.state.showDemo2 == true && this.state.content1 == false && this.state.markIllustration==true) || (this.state.showcaseDemo==true) ? {
                                position: 'absolute', top: 0, left: 0, right: 0, bottom: 0,
                                backgroundColor: 'rgb(107 107 107)', opacity: 0.7, zIndex: 3
                            } : {}
                            }>
                            <div className="navbar" id="mainnavbar" style={(this.state.showDemo && this.state.markIllustration==true )|| (this.state.showDemo2 && this.state.markIllustration==true)  || (this.state.showcaseDemo==true) ? { backgroundColor: "rgb(107 107 107)", height: '0px',pointerEvents:'none' } : { backgroundColor: "#fff", height: '0px' }}>
                                <div className="row leftdiv">
                                    <div className="col-1">
                                        <Tooltip title="Go Home">
                                            <IconButton onClick={() => (this.state.itemclicked6 == true || this.state.markIllustration == false  || this.state.showcaseDemo==false) && this.props.goHome()  }>
                                                <HomeIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </div>
                                    <div className="col-1">
                                        <Tooltip title="Go back">
                                            {/* <IconButton onClick={() => this.props.goBack()}> */}
                                            <IconButton onClick={() => {
                                                // console.log(this.history)
                                                (this.state.itemclicked6 == true || this.state.markIllustration == false  || this.state.showcaseDemo==false) && this.currentPagePosition > 1 ? this.goBackward_CustomHistory() : this.props.goBack()
                                            }}>
                                                <ArrowBackIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </div>
                                    <div className="col-1">
                                        <Tooltip title="Go Forward">
                                            {/* <IconButton onClick={() => this.props.goBack()}> */}
                                            <IconButton onClick={() =>{ (this.state.itemclicked6 == true || this.state.markIllustration == false  || this.state.showcaseDemo==false) && this.goForward_CustomHistory()}}>
                                                <ArrowForwardIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </div>
                                    {
                                        this.props.header &&
                                        <div className="col-11">
                                            <span style={{ padding: "0px 10px", fontSize: "18px", fontWeight: "600" }}>{this.props.header}</span>
                                        </div>
                                    }

                                    <div className="col-1">
                                        <Tooltip title="Search keyword">
                                            <IconButton onClick={() =>{ (this.state.itemclicked6 == true || this.state.markIllustration == false || this.state.showcaseDemo==false) && this.setState({ isOpenSearchModal: true })}}>
                                                <SearchIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </div>
                                    {/* <div className="col-1">
                                        <Tooltip title="HSN Search">
                                            <IconButton onClick={() => this.setState({ isOpenHSNSearch: true })}>
                                                <QueryStatsOutlinedIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </div> */}
                                    <div className="col-1">
                                        <Tooltip title="Dictionary">
                                            <IconButton onClick={() => (this.state.itemclicked6 == true || this.state.markIllustration == false  || this.state.showcaseDemo==false) && this.setLegalDict()}>
                                                <LibraryBooksIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </div>
                                    {
                                        this.state.showdisplayicons ?
                                            <div className="col-1">
                                                <Tooltip title="Display Features">
                                                    <IconButton onClick={() => this.setState({ showdisplayicons: false, openutilities: false })}>
                                                        <span style={this.state.showDemo == true && this.state.itemclicked1 == false ? { zIndex: '3', backgroundColor: 'white' } : {}} onClick={() => this.setState({ showdisplayicons: false, openutilities: false })}><RemoveRedEyeIcon /> </span>
                                                    </IconButton>
                                                </Tooltip>
                                            </div>
                                            :
                                            <div className="col-1">
                                                <Tooltip title="Off Display Features">
                                                    <IconButton onClick={() => (this.state.itemclicked6 == true || this.state.markIllustration == false  || this.state.showcaseDemo==false) && this.setState({ showdisplayicons: true })}>
                                                        <span style={{ fontSize: "14px", fontWeight: "600" }}><VisibilityOffIcon /> </span>
                                                    </IconButton>
                                                </Tooltip>
                                            </div>

                                    }
                                    {
                                        this.state.filepath.toLowerCase().includes('data5t/cl/') ?
                                        null:
                                        <div className="col-1">
                                        {
                                            this.state.isHelldodVisible ?
                                                <Tooltip title="Read Current Law">
                                                    <IconButton style={this.state.showDemo == true && this.state.itemclicked2 == false && this.state.itemclicked1 == true ? { backgroundColor: 'white' } : {}} onClick={() => (this.state.itemclicked6 == true || this.state.markIllustration == false  || this.state.showcaseDemo==false) && this.setState({ isHelldodVisible: !this.state.isHelldodVisible }, () => this.read_without_helldod(this.state.htmlFileOriginalContent))}>
                                                        {/* <AbcIcon /> */}
                                                        <span style={{ fontSize: "14px", fontWeight: "600" }}>ABC</span>
                                                    </IconButton>
                                                </Tooltip>
                                                :
                                                <Tooltip title="Read With Old Law Reference">
                                                    <IconButton style={this.state.showDemo == true && this.state.itemclicked2 == false && this.state.itemclicked1 == true ? { backgroundColor: 'white' } : {}} onClick={() => this.state.itemclicked6 == true || this.setState({ isHelldodVisible: !this.state.isHelldodVisible }, () => this.read_with_helldod(this.state.htmlFileOriginalContent))}>
                                                        {/* <BookmarkBorderRoundedIcon /> */}
                                                        <span style={{ fontSize: "14px", fontWeight: "600", textDecoration: 'line-through' }}>ABC</span>
                                                    </IconButton>
                                                </Tooltip>
                                        }
                                    </div>
                                    }
                                    {
                                        this.state.isFetchFileApiHitComplete ?
                                            this.state.isFileFound ?
                                                !this.state.filepath.toLowerCase().includes('index') &&
                                                <>
                                                    {this.state.filepath.toLowerCase().includes('data5t/gt/') ?
                                                        // <div className="col-1">
                                                        //     <Tooltip title="Case Status">
                                                        //         <IconButton onClick={() => this.setState({ isOpenClExclamation: true })}>
                                                        //             <ReportProblemIcon />
                                                        //         </IconButton>
                                                        //     </Tooltip>
                                                        // </div>
                                                        <div className="col-1">
                                                            <Tooltip title="Utilities">
                                                                <IconButton style={this.state.showDemo == true && this.state.itemclicked2 == true && this.state.itemclicked3 == false ? { backgroundColor: 'white' } : {}} onClick={() => (this.state.itemclicked6 == true || this.state.markIllustration == false  || this.state.showcaseDemo==false) && this.setState({ openutilities: true, showdisplayicons: true })}>
                                                                    <AutoFixHighIcon />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </div>
                                                        :
                                                        null
                                                        
                                                    }
                                                </>
                                                : null
                                            : null
                                    }
                                    
                                    
                                    {/* <div className="col-1">
                                        <Tooltip title="History">
                                            <IconButton onClick={() => this.setState({ isOpenHistoryModal: true })}>
                                                <HistoryIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </div> */}
                                    {/* <div className="col-1">
                                        <Tooltip title="Bookmark List">
                                            <IconButton onClick={() => this.setState({ isOpenBookmarkModal: true })}>
                                                <BookmarksIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </div> */}




                                    {
                                        // (this.props.userid == "1" || this.props.userid == "2") &&

                                        // (sessionStorage.getItem('username').includes('LCX_')) &&
                                        // <div className="col-1">
                                        //     {/* <span className='read_with' onClick={() => this.setState({ isOpenInHouseDevelopment: true })}> */}
                                        //     <Tooltip title="In House Development">
                                        //         <IconButton onClick={() => (this.state.itemclicked6 == true || this.state.markIllustration == false  || this.state.showcaseDemo==false) && this.setState({ isOpenInHouseDevelopment: true })}>
                                        //             <span style={{ fontSize: "14px", fontWeight: "600" }}>IHD</span>
                                        //         </IconButton>
                                        //     </Tooltip>
                                        // </div>
                                    }
                                    {


                                        this.state.isFetchFileApiHitComplete ?
                                            this.state.isFileFound ?
                                                !this.state.filepath.toLowerCase().includes('index') &&
                                                <>
                                                    {
                                                        this.state.isBookmarkAddComplete ?
                                                            this.state.isBookmarkAdded ?
                                                                <div className="col-1">
                                                                    <Tooltip title="Remove Bookmark">
                                                                        <IconButton onClick={() => (this.state.itemclicked6 == true || this.state.markIllustration == false  || this.state.showcaseDemo==false) && this.removeBookmark(this.state.userid, this.state.filepath)}>
                                                                            <BookmarkIcon />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                </div>
                                                                :
                                                                <div className="col-1">
                                                                    <Tooltip title="Add Bookmark">
                                                                        <IconButton onClick={() => (this.state.itemclicked6 == true || this.state.markIllustration == false  || this.state.showcaseDemo==false) && this.addBookmark(this.state.userid, this.state.filepath)}>
                                                                            <BookmarkBorderRoundedIcon />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                </div>
                                                            :
                                                            <Spinner variant='dark' animation='border' />
                                                    }
                                                    <div className="col-1">
                                                        <Tooltip title="Print">
                                                            <IconButton onClick={() => { (this.state.itemclicked6 == true || this.state.markIllustration == false  || this.state.showcaseDemo==false) && this.setState({ Printmodal: true }) }}>
                                                                <PrintIcon />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </div>

                                                    <div className="col-1">
                                                        <Tooltip title="Highlight text">
                                                            <IconButton onClick={() => (this.state.itemclicked6 == true || this.state.markIllustration == false  || this.state.showcaseDemo==false) &&  this.getSelectedText(this.state.userid, this.state.filepath)}>
                                                                <HighlightIcon />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </div>
                                                    {
                                                        this.state.allStarts != "" && this.state.allEnds != "" && this.state.allStarts != "0" && this.state.allEnds != "0" ?
                                                            <div className="col-1">
                                                                <Tooltip title="Remove Highlight">
                                                                    <IconButton onClick={() => (this.state.itemclicked6 == true || this.state.markIllustration == false  || this.state.showcaseDemo==false) && this.removeSelectedText(this.state.userid, this.state.filepath)}>
                                                                        <HighlightOutlinedIcon />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </div>
                                                            :
                                                            null
                                                    }



                                                </>
                                                :
                                                null
                                            :
                                            null

                                    }
                                </div>
                                {/* <div className="darkmodebtn">
                                    <Tooltip title={this.state.isDarkMode ? "Light Mode" : "Dark Mode"}>
                                        <MaterialUISwitch sx={{ m: 1 }} onChange={(e) => this.setState({ isDarkMode: e.target.checked })} checked={this.state.isDarkMode} />
                                    </Tooltip>
                                </div> */}
                            </div>
                        </div>
                        <div className="mainBox" id='mainboxdiv' tabIndex={-1} style={this.state.markIllustration ||  this.state.showcaseDemo? { background: this.state.isDarkMode ? 'black' : 'inherit', color: this.state.isDarkMode ? "#fff" : "#000", overflow: "auto", padding: "10px", marginTop: '60px' } : { background: this.state.isDarkMode ? 'black' : 'inherit', color: this.state.isDarkMode ? "#fff" : "#000", overflow: "auto", padding: "10px" }}>
                            {
                                this.state.isFetchFileApiHitComplete ?
                                    this.state.isFileFound ?
                                        <>

                                            {
                                                this.props.filepath.toLowerCase().includes('/cl/') &&
                                                <div style={this.state.showcaseDemo == true ?{position:'relative',zIndex:'4',backgroundColor:'white',pointerEvents:'none'}:{}}>
                                                    <CaselawExclamation
                                                        status={this.state.pageData}
                                                        onCloseModal={() => this.setState({ isOpenClExclamation: false })}
                                                        userid={this.props.userid}
                                                        openFrame={(li) => {this.setState({filepath:li.filepath});this.anchorTags(li.filepath)}}
                                                    />
                                                </div>
                                            }
                                            {
                                                (this.state.itemclicked3 && this.state.showDemo && this.state.markIllustration) || (this.state.content1 == false && this.state.showDemo2 && this.state.markIllustration) ?
                                                    <div id="SinglePageIframeSearched" dangerouslySetInnerHTML={{ __html: this.state.showIllustrationtext }}></div>
                                                    :
                                                    this.props.showHighlightttext != "" && this.props.showHighlightttext != undefined && this.props.showHighlightttext != null ?
                                                        <div id="SinglePageIframeSearched" dangerouslySetInnerHTML={this.DoHighlightContent(this.state.htmlFileShowContent)}></div>
                                                        :
                                                        <div id="SinglePageIframeSearched" dangerouslySetInnerHTML={{ __html: this.state.htmlFileShowContent }} />
                                            }
                                            {/* {
                                                this.state.isMagicWondOpen &&
                                                <Magicwond
                                                    pageData={this.state.pageData}
                                                    onCloseModal={() => this.setState({ isMagicWondOpen: false })}
                                                    userid={this.state.userid}
                                                    filepath={this.state.filepath}
                                                    searchApi={this.props.searchApi}
                                                    rootUrl={this.props.rootUrl}
                                                    goBack={() => this.props.goHome()}
                                                    onLegalDictClick={(dictWords) => this.setLegalDictLinks(dictWords)}
                                                    openHistoryFile={(fileData) => this.setState({ historyFileData: fileData, isReferedByHistory: true, isOpenSearchModal: true, isOpenBookmarkModal: false })}
                                                    fetchPageData={() => this.setState({ isMagicWondOpen: false }, () => this.fetchPageData(this.state.userid, this.state.filepath))}
                                                />
                                            } */}
                                        </>
                                        :
                                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: '100%', height: "inherit" }}>
                                            <h5 style={{ color: 'orange' }}>File not found</h5>
                                        </div>
                                    :
                                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: '100%', height: "inherit" }}>
                                        <Spinner animation="border" variant="dark" />
                                    </div>
                            }
                        </div>
                    </Modal.Body>
                </Modal>
                <Modal fullscreen={true} id="documentmodal" animation={true} show={this.state.isOpenSearchModal} onHide={() => this.setState({ isOpenSearchModal: false })}>
                    <Modal.Body>
                        <Searched
                            searchApi={this.props.searchApi}
                            rootUrl={this.props.rootUrl}
                            goBack={() => this.setState({ isOpenSearchModal: false, isReferedByHistory: false, isReferedByHsn: false })}
                            userid={this.props.userid}
                            isReferedByHsn={this.state.isReferedByHsn}
                            hsnSearchWord={this.state.hsnSearchWord}
                            hsnPath={this.state.hsnPath}
                            isReferedByHistory={this.state.isReferedByHistory}
                            historyFileData={this.state.historyFileData}
                            goHome={() => this.props.goHome()}
                            caseStatusData={this.state.fileData}
                            isReferedByCaseStatus={this.state.isReferedByCaseStatus}
                            
                        />
                    </Modal.Body>
                </Modal>

                {/* <Modal fullscreen={true} id="documentmodal" animation={true} show={this.state.OpenSearchModal} onHide={() => this.setState({ OpenSearchModal: false })}>
                    <Modal.Body>
                        <Searched
                            searchApi={this.props.searchApi}
                            rootUrl={this.props.rootUrl}
                            goBack={() => this.setState({ OpenSearchModal: false, isReferedByCaseStatus: false })}
                            userid={this.props.userid}
                            // isReferedByHsn={this.state.isReferedByHsn}
                            // hsnSearchWord={this.state.hsnSearchWord}
                            // hsnPath={this.state.hsnPath}
                            // isReferedByHistory={this.state.isReferedByHistory}
                            caseStatusData={this.state.fileData}
                            isReferedByCaseStatus={this.state.isReferedByCaseStatus}
                            isReferedByHistory={true}
                            historyFileData={this.state.fileData}
                            goHome={() => this.props.goHome()}
                        />
                    </Modal.Body>
                </Modal> */}

                <Modal id={'search_options_modal'} size="md" centered style={{ backgroundColor: 'rgba(0,0,0,0.6)' }} show={this.state.isOpenInHouseDevelopment} onHide={() => this.setState({ isOpenInHouseDevelopment: false })}>
                    {/* <Modal.Header>
                        <div className="header">
                            <span></span>
                            <IconButton onClick={() => this.setState({ isOpenInHouseDevelopment: false })}>
                                <CloseIcon />
                            </IconButton>
                        </div>
                    </Modal.Header> */}
                    <Modal.Body style={{ padding: "10px" }}>
                        <div className='InhouseDevelopment'>
                            <b>Location:</b>&nbsp;&nbsp;
                            <span>{this.state.filepath}</span>
                        </div>
                    </Modal.Body>
                </Modal>

                <Modal size="sm" style={{ top: '4%' }} show={!this.state.showdisplayicons} onHide={() => this.setState({ showdisplayicons: true })} >
                    <Modal.Body style={{ display: 'flex', justifyContent: 'space-around' }}>
                        {
                            <div className="col-1">
                                <Tooltip title={this.state.isDarkMode ? "Light Mode" : "Dark Mode"}>
                                    <IconButton onClick={() => this.setState({ isDarkMode: !this.state.isDarkMode }, () => {
                                        if (!this.state.isDarkMode) {
                                            document.getElementById('mainboxdiv').innerHTML = document.getElementById('mainboxdiv').innerHTML.replaceAll('background-color: black;', 'background-color: transparent;')
                                            this.setState({ highlightColor: 'lightgreen' }, () => {
                                                this.autoHighlight(this.state.userid, this.state.filepath)
                                                console.log(this.state.highlightColor)
                                            })
                                        } else {
                                            this.setState({ highlightColor: 'orange' }, () => {
                                                this.autoHighlight(this.state.userid, this.state.filepath)
                                                console.log(this.state.highlightColor)
                                            })
                                        }
                                    })}>
                                        {
                                            this.state.isDarkMode ?
                                                <Brightness4Icon onClick={() => this.setState({ showdisplayicons: true })}/>
                                                :
                                                <Brightness4OutlinedIcon onClick={() => this.setState({ showdisplayicons: true })}/>
                                        }
                                    </IconButton>
                                </Tooltip>
                            </div>
                        }
                        {
                            this.state.isFetchFileApiHitComplete ?
                                this.state.isFileFound ?
                                    <>
                                        <div className="col-1">
                                            <Tooltip title="Zoom In">
                                                <IconButton onClick={() => {
                                                    if (this.state.zoomFrame < 6) {
                                                        this.setState({ zoomFrame: this.state.zoomFrame + 0.4 }, () => {
                                                            document.getElementById('SinglePageIframeSearched').style.zoom = this.state.zoomFrame
                                                        })
                                                    }
                                                }}>
                                                    <AddIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </div>
                                        <div className="col-1">
                                            <Tooltip title="Zoom Out">
                                                <IconButton onClick={() => {
                                                    if (this.state.zoomFrame > 1) {
                                                        this.setState({ zoomFrame: this.state.zoomFrame - 0.4 }, () => {
                                                            document.getElementById('SinglePageIframeSearched').style.zoom = this.state.zoomFrame
                                                        })
                                                    }
                                                }}>
                                                    <RemoveIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </div>
                                    </>
                                    : null
                                : null
                        }
                    </Modal.Body>
                </Modal>
                <Modal size="md" id={'search_options_modal'} style={{ top: '4%' }} show={this.state.openutilities} onHide={() => this.setState({ openutilities: false })}>
                    <Modal.Body style={{ display: 'flex', justifyContent: 'space-around' }}>
                        <div className="col-1">
                            <Tooltip title="FAQ">
                                <span>
                                    <IconButton disabled={this.state.pageData['faq'] == null ? true : false}
                                        onClick={() => {
                                            this.setState({ utilfaq: true }, () => { this.openRespectiveScreen(); this.setState({utilityheading:'FAQ'}) })
                                        }}>
                                        <QuestionMarkIcon />
                                    </IconButton>
                                </span>
                            </Tooltip>
                        </div>
                        <div className="col-1">
                            <Tooltip title="Interpretation">
                                <span>
                                    <IconButton disabled={this.state.pageData['comentry'] == null ? true : false}
                                        onClick={() => {
                                            this.setState({ utilInterpretation: true }, () => { this.openRespectiveScreen();  this.setState({utilityheading:'Interpretation'})})
                                        }}>
                                        <PsychologySharpIcon />
                                    </IconButton>
                                </span>
                            </Tooltip>
                        </div>
                        <div className="col-1">
                            <Tooltip title="Summary">
                                <IconButton disabled={this.state.pageData['summary'] == null ? true : false} 
                                    onClick={() => { this.setState({ utilSummary: true }, () => { this.openRespectiveScreen();this.setState({utilityheading:'Summary'}) }) }}>
                                    <SummarizeSharpIcon />
                                </IconButton>
                            </Tooltip>
                        </div>
                        <div className="col-1">
                            <Tooltip title="Illustration" >
                                <span>
                                    <IconButton disabled={this.state.pageData['illustration'] == null ? true : false}
                                        onClick={() => {
                                            this.setState({ utilIllustration: true }, () => { this.openRespectiveScreen();this.setState({utilityheading:'Illustration'}) })
                                            // let obj={description:'testing',filepath:this.state.filepath,userid:sessionStorage.getItem('userid')}
                                            // this.setState({fileData:obj})
                                            // this.setState({OpenSearchModal:true});

                                        }}>
                                        <CalculateIcon />
                                    </IconButton>
                                </span>
                            </Tooltip>
                        </div>
                        <div className="col-1">
                            <Tooltip title="Flowchart">
                                <span>
                                    <IconButton disabled={this.state.pageData['ppt'] == null ? true : false}
                                        onClick={() => {
                                            this.setState({ utilFlowchar: true }, () => { this.openRespectiveScreen();this.setState({utilityheading:'Flowchart'}) })
                                        }}>
                                        <AccountTreeSharpIcon />
                                    </IconButton>
                                </span>
                            </Tooltip>
                        </div>
                        <div className="col-1">
                            <Tooltip title="Laws as on date">
                                <IconButton onClick={() => { this.setState({ isOpenLawAsOnDateModal: true });this.setState({utilityheading:'Laws as on date'}) }}>
                                    <CalendarMonthIcon />
                                </IconButton>
                            </Tooltip>
                        </div>
                    </Modal.Body>
                </Modal>

                {
                    this.state.isOpenMagicwondFrame &&
                    <Magicwondframes
                        onCloseModal={() => this.setState({ isOpenMagicwondFrame: false })}
                        data={this.state.frameData}
                        header={this.state.header}
                        isnoti={false}
                        // pageData={this.props.pageData}
                        userid={this.props.userid}
                        isReferedByCaseStatus={this.state.isReferedByCaseStatus}
                        fileData={this.state.fileData}
                        openHistoryFile={(list) => this.setState({ isOpenMagicwondFrame: false }, /*() => this.props.openHistoryFile(list)*/)}
                    // fetchPageData={() => this.setState({ isMagicWondOpen: false }, () => this.getpagedata(this.state.selectedFileDetails))}
                    />
                }
                {
                    this.state.isOpenHSNSearch &&
                    <Hsnsearch
                        userid={this.props.userid}
                        onCloseModal={() => this.setState({ isOpenHSNSearch: false })}
                    />
                }
                {
                    this.state.isOpenDictModal &&
                    <Dictionary
                        rootUrl={this.props.rootUrl}
                        userid={this.props.userid}
                        onCloseModal={() => this.setState({ isOpenDictModal: false })}
                    />
                }
                {
                    this.state.isOpenHistoryModal &&
                    <HistoryKeeping
                        rootUrl={this.props.rootUrl}
                        userid={this.props.userid}
                        onCloseModal={() => this.setState({ isOpenHistoryModal: false })}
                        openHistoryFile={(fileData) => this.setState({ historyFileData: fileData, isReferedByHistory: true, isOpenSearchModal: true, isOpenHistoryModal: false })}
                    />
                }
                {
                    this.state.isOpenBookmarkModal &&
                    <BookmarkKeeping
                        rootUrl={this.props.rootUrl}
                        userid={this.props.userid}
                        onCloseModal={() => this.setState({ isOpenBookmarkModal: false })}
                        openHistoryFile={(fileData) => this.setState({ historyFileData: fileData, isReferedByHistory: true, isOpenSearchModal: true, isOpenBookmarkModal: false })}
                    />
                }
                {
                    this.state.isOpenSummary &&
                    <Summary
                        onCloseModal={() => this.setState({ isOpenSummary: false })}
                        summaryData={this.state.summaryData}
                        header={this.state.header}
                    />
                }
                {/* {
                    this.state.isOpenNotesModal &&
                    <Notes
                        onCloseModal={() => this.setState({ isOpenNotesModal: false })}
                        filedetails={{ filepath: this.state.filepath, userid: this.props.userid }}
                        oldNote={this.state.usernotes}
                        onSaveNote={() => this.setState({ isOpenNotesModal: false }, () => this.fetchPageData(this.props.userid, this.state.filepath))}
                    />
                } */}
                {
                    this.state.isOpenLawAsOnDateModal &&
                    <Lawasondate
                        onCloseModal={() => this.setState({ isOpenLawAsOnDateModal: false })}
                        onSubmit={(date) => this.setLawAsOnDate(date)}
                    />
                }
                {
                    this.state.Printmodal &&
                    <Captcha
                        onCloseModal={() => this.setState({ Printmodal: false })}
                        Verification={(val) => this.getVerificationStatus(val)}></Captcha>
                }
                {/* {
                    this.state.isOpenCompareLaws &&
                    <Comparelaws
                        onCloseModal={() => this.setState({ isOpenCompareLaws: false })}
                    />
                } */}
                {/* {
                    this.state.utilityOpen ?
                            <UtilityFrame
                                goBack={() => {this.setState({ utilityOpen: false })}}
                                utilityheading={this.state.utilityheading}
                                filepath={this.state.utilityFilename}
                                searchApi={sessionStorage.getItem('searchapi')}
                                rootUrl={sessionStorage.getItem('url')}
                                userid={sessionStorage.getItem('userid')}
                                MainFolder={this.state.utilityMainfolder}
                                SubFolder={this.state.utilitySubfolder}
                                goHome={() => { this.setState({ utilityOpen: false }); this.props.goHome() }}
                                LightMode={this.state.isDarkMode}
                                ScreenMode={() => { this.setState({ isDarkMode: !this.state.isDarkMode }, () => { this.ScreenMode() }) }}
                            />
                        :
                        null
                } */}
                 <Modal id="documentmodal" size="sm" centered animation={true} show={this.state.showLoading} onHide={this.state.showLoading} style={{ backgroundColor: 'rgba(0, 0, 0, 0.9)' }}>
                    <Modal.Body>
                        <div style={{display:'flex', justifyContent:'center', alignItems:'center', minHeight:'130px'}}>
                            <h3>Loading...<Spinner variant='dark' animation='border' /></h3>
                        </div>
                    </Modal.Body>
                </Modal>
            </>

        )
    }
}
