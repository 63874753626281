import React, { Component } from 'react'
import Mynavbar from './Mynavbar'
import Searched from './SearchedV2'
import { Modal } from 'react-bootstrap'
import IconButton from '@mui/material/IconButton';
// import CloseIcon from '@mui/icons-material/Close';
// import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import TreeView from '@mui/lab/TreeView';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TreeItem from '@mui/lab/TreeItem';
import ContentFrame from './ContentFrame';
// import ContentFrame from './ContentFrame';

export default class Currentlaw extends Component {
    constructor(props) {
        super(props)
        this.state = {
            // isOpenSearchModal: false,
            openCustomFrame: false,
            targetFile: '', isError: false,
            subfolder: '', mainFolder: '',
            lawData: [
                {
                    name: "GST",
                    children: [
                        {
                            name: "IGST",
                            link: 'Assets/Data5t/gt/gtigst/index.htm',
                            parent: "GST",
                        },
                        {
                            name: "CGST",
                            link: 'Assets/Data5t/gt/gtcgst/index.htm',
                            parent: "GST",
                        },
                        {
                            name: "UTGST",
                            link: 'Assets/Data5t/gt/gtugst/utgst.htm',
                            parent: "GST",
                        },
                        {
                            name: "SGST",
                            link: 'Assets/Data5t/gt/gtsupt/index.htm',
                            parent: "GST",
                        },
                        {
                            name: "GST(Compensation to the States) Act",
                            link: 'Assets/Data5t/gt/gtcomp/comp_index.htm',
                            parent: "GST",
                        },
                        {
                            name: "Press Release",
                            link: 'Assets/Data5t/gt/gtpres/index.htm',
                            parent: "GST",
                        },
                        {
                            name: "Council Meeting Updates",
                            link: 'Assets/Data5t/gt/gtport/index.htm',
                            parent: "GST",
                        },
                        {
                            name: "GST News",
                            link: 'Assets/Data5t/gt/gtnews/news_index.htm',
                            parent: "GST",
                        },
                        // {
                        //     name: "SGST Comparative Chart",
                        //     link: '',
                        //     parent: "GST",
                        // }
                    ]
                },
                {
                    name: "CUSTOMS",
                    children: [
                        {
                            name: "Act",
                            link: 'Assets/Data5t/cu/cuacts/index.htm',
                            parent: "CUSTOMS",
                        },
                        {
                            name: "Rules",
                            link: 'Assets/Data5t/cu/curule/index.htm',
                            parent: "CUSTOMS",
                        },
                        {
                            name: "Circular",
                            link: '',
                            type:'custom',
                            parent: "CUSTOMS",
                        },
                        {
                            name: "Notification",
                            children: [
                                {
                                    name: 'Non Tariff',
                                    link: '',
                                    type:'custom nt',
                                    parent: "CUSTOMS",
                                },
                                {
                                    name: 'Tariff',
                                    link: '',
                                    type:'custom tariff',
                                    parent: "CUSTOMS",
                                }
                            ]
                        },
                        {
                            name: "Regulation",
                            link: 'Assets/Data5t/cu/curegu/index.htm',
                            parent: "CUSTOMS",
                        },
                        {
                            name: "Manual",
                            link: 'Assets/Data5t/cu/cumanu/index.htm',
                            parent: "CUSTOMS",
                        },
                        {
                            name: "Tariff",
                            link: 'Assets/Data5t/cu/cutari/index.htm',
                            parent: "CUSTOMS",
                        },
                        {
                            name: "Procedures & Articles",
                            link: 'Assets/Data5t/sp/spartl/index_of_matters_relating_custom.htm',
                            parent: "CUSTOMS",
                        }
                    ]
                },
                {
                    name: "FOREIGN TRADE POLICY",
                    children: [
                        {
                            name: "Act",
                            link: 'Assets/Data5t/ft/ftacts/index.htm',
                            parent: "FOREIGN TRADE POLICY",
                        },
                        {
                            name: "Rules",
                            link: 'Assets/Data5t/ft/ftrule/index.htm',
                            parent: "FOREIGN TRADE POLICY",
                        },
                        {
                            name: "Circular",
                            link: 'Assets/Data5t/ft/ftcirc/index.htm',
                            parent: "FOREIGN TRADE POLICY",
                        },
                        {
                            name: "Notification",
                            link: 'Assets/Data5t/ft/ftnoti/index.htm',
                            parent: "FOREIGN TRADE POLICY",
                        },
                        {
                            name: "Appendix",
                            link: 'Assets/Data5t/ft/ftappe/index.htm',
                            parent: "FOREIGN TRADE POLICY",
                        },
                        {
                            name : "Public Notice",
                            link : 'Assets/Data5t/ft/ftpubl/index.htm',
                           parent: "FOREIGN TRADE POLICY",
                        },
                        {
                            name: "ITC (HS)",
                            link: 'Assets/Data5t/ft/ftitch/index.htm',
                            parent: "FOREIGN TRADE POLICY",
                        },
                        {
                            name: "DEPB Draw",
                            link: 'Assets/Data5t/ft/ftrate/index.htm',
                            parent: "FOREIGN TRADE POLICY",
                        },
                        {
                            name: "Policy",
                            link: 'Assets/Data5t/ft/ftpoli/index.htm',
                            parent: "FOREIGN TRADE POLICY",
                        },
                        {
                            name: "Trade Notice",
                            link: 'Assets/Data5t/ft/fttrad/index.htm',
                            parent: "FOREIGN TRADE POLICY",
                        },
                        // {
                        //     name: "Public Notice",
                        //     link: 'Assets/Data5t/ft/ftpubl/',
                            // parent: "FOREIGN TRADE POLICY",
                        // },
                        {
                            name: "HB Procedure",
                            link: 'Assets/Data5t/ft/ftproc/index.htm',
                            parent: "FOREIGN TRADE POLICY",
                        },
                        {
                            name: "SION",
                            link: 'Assets/Data5t/ft/ftsion/index.htm',
                            parent: "FOREIGN TRADE POLICY",
                        }
                    ]
                },
                {
                    name: "FEMA & OTHERS",
                    children: [
                        {
                            name: "Act",
                            link: 'Assets/Data5t/rb/rbacts/index.htm',
                            parent: "FEMA & OTHERS",
                        },
                        {
                            name: "Rules",
                            link: 'Assets/Data5t/rb/rbrule/index.htm',
                            parent: "FEMA & OTHERS",
                        },
                        {
                            name: "Circular",
                            link: 'Assets/Data5t/rb/rbcirc/index.htm',
                            parent: "FEMA & OTHERS",
                        },
                        {
                            name: "Notification",
                            link: 'Assets/Data5t/rb/rbnoti/index.htm',
                            parent: "FEMA & OTHERS",
                        },
                        {
                            name: "Regulation",
                            link: 'Assets/Data5t/rb/rbregu/index.htm',
                            parent: "FEMA & OTHERS",
                        },
                        {
                            name: "Citizen Charter",
                            link: 'Assets/Data5t/rb/rbciti/index.htm',
                            parent: "FEMA & OTHERS",
                        },
                        {
                            name: "SEZ",
                            link: 'Assets/Data5t/sz/szacts/index.htm',
                            parent: "FEMA & OTHERS",
                        },
                        {
                            name: "EOU",
                            link: 'Assets/Data5t/eo/index.htm',
                            parent: "FEMA & OTHERS",
                        },
                        {
                            name: "Budget",
                            link: 'Assets/Data5t/bu/bubill/index_main.htm',
                            parent: "FEMA & OTHERS",
                        }
                    ]
                }
            ],
        }
        this.setInitialPage = this.setInitialPage.bind(this)
    }

    setInitialPage(child_obj, parent) {
        if (child_obj.link != "" && child_obj.link != undefined) {
            this.setState({ targetFile: child_obj.link, openCustomFrame: true, openCircNoti: false })

            let child = child_obj.name.toLowerCase()
            parent = parent.toLowerCase()
            console.log(child, parent)

            if (parent == 'gst') {
                if (child == 'igst') {
                    this.setState({ subfolder: 'gtigst', mainFolder: 'gt' })
                } else if (child == 'cgst') {
                    this.setState({ subfolder: 'gtcgst', mainFolder: 'gt' })
                } else if (child == 'utgst') {
                    this.setState({ subfolder: 'gtugst', mainFolder: 'gt' })
                } else if (child == 'sgst') {
                    this.setState({ subfolder: 'gtsupt', mainFolder: 'gt' })
                } else if (child == 'gst(compensation to the states) act') {
                    this.setState({ subfolder: 'gtcomp', mainFolder: 'gt' })
                } else if (child == 'press release') {
                    this.setState({ subfolder: 'gtpres', mainFolder: 'gt' })
                } else if (child == 'council meeting updates') {
                    this.setState({ subfolder: 'gtport', mainFolder: 'gt' })
                } else if (child == 'gst news') {
                    this.setState({ subfolder: 'gtnews', mainFolder: 'gt' })
                }
            } else if (parent == 'customs') {
                if (child == 'act') {
                    this.setState({ subfolder: 'cuacts', mainFolder: 'cu' })
                } else if (child == 'rules') {
                    this.setState({ subfolder: 'curule', mainFolder: 'cu' })
                } else if (child == 'regulation') {
                    this.setState({ subfolder: 'curegu', mainFolder: 'cu' })
                } else if (child == 'tariff') {
                    this.setState({ subfolder: 'cutari', mainFolder: 'cu' })
                } else if (child == 'manual') {
                    this.setState({ subfolder: 'cumanu', mainFolder: 'cu' })
                } else if (child == 'procedures & articles') {
                    this.setState({ subfolder: 'spartl', mainFolder: 'sp' })
                }
            } else if (parent == 'foreign trade policy') {
                if (child == 'act') {
                    this.setState({ subfolder: 'ftacts', mainFolder: 'ft' })
                } else if (child == 'rules') {
                    this.setState({ subfolder: 'ftrule', mainFolder: 'ft' })
                } else if (child == 'circular') {
                    this.setState({ subfolder: 'ftcirc', mainFolder: 'ft' })
                } else if (child == 'notification') {
                    this.setState({ subfolder: 'ftnoti', mainFolder: 'ft' })
                } else if (child == 'appendix') {
                    this.setState({ subfolder: 'ftappe', mainFolder: 'ft' })
                } else if (child == 'itc (hs)') {
                    this.setState({ subfolder: 'ftitch', mainFolder: 'ft' })
                } else if (child == 'depb draw') {
                    this.setState({ subfolder: 'ftrate', mainFolder: 'ft' })
                } else if (child == 'policy') {
                    this.setState({ subfolder: 'ftpoli', mainFolder: 'ft' })
                } else if (child == 'trade notice') {
                    this.setState({ subfolder: 'fttrad', mainFolder: 'ft' })
                } else if (child == 'public notice') {
                    this.setState({ subfolder: 'ftpubl', mainFolder: 'ft' })
                } else if (child == 'hb procedure') {
                    this.setState({ subfolder: 'ftproc', mainFolder: 'ft' })
                } else if (child == 'sion') {
                    this.setState({ subfolder: 'ftsion', mainFolder: 'ft' })
                }
            } else if (parent == "fema & others") {
                if (child == 'act') {
                    this.setState({ subfolder: 'rbacts', mainFolder: 'rb' })
                } else if (child == 'rules') {
                    this.setState({ subfolder: 'rbrule', mainFolder: 'rb' })
                } else if (child == 'circular') {
                    this.setState({ subfolder: 'rbcirc', mainFolder: 'rb' })
                } else if (child == 'notification') {
                    this.setState({ subfolder: 'rbnoti', mainFolder: 'rb' })
                } else if (child == 'regulation') {
                    this.setState({ subfolder: 'rbregu', mainFolder: 'rb' })
                } else if (child == 'citizen charter') {
                    this.setState({ subfolder: 'rbciti', mainFolder: 'rb' })
                } else if (child == 'sez') {
                    this.setState({ subfolder: 'szacts', mainFolder: 'sz' })
                } else if (child == 'eou') {
                    this.setState({ subfolder: '', mainFolder: 'eo' })
                } else if (child == 'budget') {
                    this.setState({ subfolder: 'bubill', mainFolder: 'bu' })
                }
            }
        }else if (child_obj.link == '' && child_obj.type != undefined) {
            this.setState({ targetFile: Object.assign(child_obj,{type:child_obj.type.toLowerCase()}), openCustomFrame: false, openCircNoti: true })
        }


        setTimeout(() => {
            console.log(this.state.subfolder, this.state.mainFolder)
        }, 2000);

    }
    componentDidCatch(error) {
        this.setState({ isError: true })
    }

    setTree(data) {
        if (data.children != undefined) {
            return (
                <TreeItem key={data.name} nodeId={`${data.name}`} label={data.name}>
                    {data.children.map((child) => {
                        return this.setTree(child)
                    })}
                </TreeItem>
            )
        }else{
            return (
                <TreeItem key={data.name} nodeId={`${data.name}`} label={data.name} onClick={() => this.setInitialPage(data, data.parent)} />
            ) 
        }
    }
    render() {
        return (
            <>
                {
                    this.state.isError ? <h1>Some error</h1> :
                        <div>
                            <Mynavbar
                                searchapi={this.props.searchApi}
                                goBack={() => this.props.goBack()}
                                header={"Current Law"}
                                rootUrl={this.props.rootUrl}
                                userid={this.props.userid}
                                goHome={() => this.props.goBack()}
                            />
                            <div className="mainBox" >
                                <div className="row">
                                    {
                                        this.state.lawData.map((data, index) => {
                                            return (
                                                <div className="col-md-3 col-12" >
                                                    <TreeView
                                                        key={data.name}
                                                        // style={{backgroundImage:'',opacity:'0.2',backgroundRepeat:' no-repeat', backgroundPosition: 'center center',}}
                                                        aria-label="file system navigator"
                                                        defaultCollapseIcon={<ExpandMoreIcon />}
                                                        defaultExpandIcon={<ChevronRightIcon />}
                                                        sx={{ height: "100%", flexGrow: 1, maxWidth: 400, overflowY: 'auto' }}
                                                    >
                                                        {this.setTree(data)}
                                                    </TreeView>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                            {
                                this.state.openCustomFrame ?
                                    <ContentFrame
                                        // style={{backgroundImage:'',opacity:'0.2',backgroundRepeat:' no-repeat', backgroundPosition: 'center center',}}
                                        goBack={() => this.setState({ openCustomFrame: false })}
                                        filepath={this.state.targetFile}
                                        searchApi={this.props.searchApi}
                                        rootUrl={this.props.rootUrl}
                                        userid={this.props.userid}
                                        MainFolder={this.state.mainFolder}
                                        SubFolder={this.state.subfolder}
                                        goHome={() => this.props.goBack()}
                                    />
                                    : null
                            }
                            {
                                this.state.openCircNoti &&
                                <Modal fullscreen={true} id="documentmodal" animation={true} show={this.state.openCircNoti} onHide={() => this.setState({ openCircNoti: false })}>
                                    <Modal.Body>
                                        {/* <Searched
                                            searchApi={this.props.searchApi}
                                            rootUrl={this.props.rootUrl}
                                            goBack={() => this.setState({ isOpenSearchModal: false, isReferedByHistory: false, isReferedByHsn: false })}
                                            userid={this.props.userid}
                                            isReferedByHsn={this.state.isReferedByHsn}
                                            hsnSearchWord={this.state.hsnSearchWord}
                                            hsnPath={this.state.hsnPath}
                                            isReferedByHistory={this.state.isReferedByHistory}
                                            historyFileData={this.state.historyFileData}
                                            goHome={() => this.props.goHome()}
                                        /> */}
                                        <Searched
                                            goBack={() => this.setState({ openCircNoti: false })}
                                            filepath={''}
                                            searchApi={this.props.searchApi}
                                            rootUrl={this.props.rootUrl}
                                            userid={this.props.userid}
                                            MainFolder={''}
                                            SubFolder={''}
                                            goHome={() => this.props.goBack()}
                                            isReferedByCircNoti={true}
                                            clickedLink={this.state.targetFile}
                                        />
                                    </Modal.Body>
                                </Modal>
                            }
                        </div>
                }
            </>

        )
    }
}
