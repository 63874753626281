import React, { Component } from 'react'
import { Modal, Spinner } from 'react-bootstrap'
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import './clexclamation.css'
import Hexapi from '../../usefulcomps/HexAPI/Hexapi';
export default class CaselawExclamation extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isComponentRender: false,
            isDataFound: false,
            isOpenCasesList: false,
            descriptionList: [], isCaseListFound: false,
            citedFilesList: [], referedFilesList: [],
            previous: '', next: ''
        }
    }

    componentDidMount() {
        console.log('hello')
        console.log(this.props.status)
        let filterStatus = (arr) => {
            return arr.map((el) => el[''])
        }
        // console.log(loop(this.props.status.referedin))
        this.setState({
            isComponentRender: true,
            isDataFound: true,
            citedFilesList: this.props.status.citedcases != undefined ? filterStatus(this.props.status.citedcases) : [],
            referedFilesList: this.props.status.referedin != undefined ? filterStatus(this.props.status.referedin) : [],
            previous: this.props.status.previous != undefined ? filterStatus(this.props.status.previous) : [],
            next: this.props.status.next != undefined ? filterStatus(this.props.status.next) : []
        })
        // console.log(this.props)
    }

    onStatusClick(list, type) {
        console.log(list)
        const listarr = list.map((s, index) => {
            return s.replaceAll("u", '/')
        })
        console.log(listarr);
        this.setState({ isCaseListFound: false })
        let obj = {
            allfiles: listarr.join('##12##'),
            url: sessionStorage.getItem('gettitleurl')
        }
        Hexapi(obj).then(resp => {
            console.log(resp)
            let List = []
            listarr.forEach((el, i) => {
                let obj = {
                    description: resp[i],
                    filepath: el,
                    userid: this.props.userid
                }
                List.push(obj)
            })
            console.log(List)
            if (type == 1) {
                this.setState({ descriptionList: List, isOpenCasesList: true, isCaseListFound: true })
            } else {
                this.props.openFrame(List[0])
                // this.setState({ descriptionList: List, isOpenCasesList: true, isCaseListFound: true })
            }
        })
    }
    onListItemClick(li) {
        // console.log(li)
        this.props.openFrame(li)
        console.log(li)
    }
    render() {
        return (
            <>
                {/* { */}
                <Modal id={'search_options_modal'} size="md" centered style={{ backgroundColor: 'rgba(0,0,0,0.6)' }} show={true} onHide={() => this.props.onCloseModal()} keyboard={false} backdrop='static'>
                    <Modal.Header>
                        <div className="header">
                            <span>Select the Desired Option</span>
                            <IconButton onClick={() => this.props.onCloseModal()}>
                                <CloseIcon />
                            </IconButton>
                        </div>
                    </Modal.Header>
                    <Modal.Body style={{ paddingTop: "0px" }}>
                        {
                            this.state.isComponentRender ?
                                this.state.isDataFound ?
                                    <div className="clexclamation_main_div">
                                        <div className="row">
                                            <div className="boxy col-6">
                                                <span onClick={() => this.state.previous != '' && this.onStatusClick(this.state.previous, 0)}>Previous</span>
                                            </div>
                                            <div className="boxy col-6">
                                                <span onClick={() => this.state.next != '' && this.onStatusClick(this.state.next, 0)}>Next</span>
                                            </div>
                                            <div className="boxy col-6">
                                                <span onClick={() => this.state.referedFilesList.length > 0 && this.onStatusClick(this.state.referedFilesList, 1)}>Reffered In {`(${this.state.referedFilesList.length})`}</span>
                                            </div>
                                            <div className="boxy col-6">
                                                <span onClick={() => this.state.citedFilesList.length > 0 && this.onStatusClick(this.state.citedFilesList, 1)}>Cited Cases {`(${this.state.citedFilesList.length})`}</span>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: '100%', minHeight: "50vh" }}>
                                        <h5>No record</h5>
                                    </div>
                                :
                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: '100%', minHeight: "50vh" }}>
                                    <Spinner animation="border" variant="dark" />
                                </div>
                        }
                    </Modal.Body>
                </Modal>
                {/* } */}


                <Modal id={'cases_list_modal'} size="lg" centered style={{ backgroundColor: 'rgba(0,0,0,0.6)' }} show={this.state.isOpenCasesList} onHide={() => this.setState({ isOpenCasesList: false })} keyboard={false} backdrop='static'>
                    <Modal.Header>
                        <div className="header">
                            <span>Cases</span>
                            <IconButton onClick={() => this.setState({ isOpenCasesList: false })}>
                                <CloseIcon />
                            </IconButton>
                        </div>
                    </Modal.Header>
                    <Modal.Body style={{ paddingTop: "0px" }}>
                        <div className="status_main_boxy">
                            {
                                this.state.isCaseListFound &&
                                <div className="descBox">
                                    <div className="listBox">
                                            <span>Description</span>
                                        {
                                            this.state.descriptionList.map((list, index) => {
                                                return (
                                                        <span onClick={() => this.onListItemClick(list)}>{list.description}</span>


                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            }
                        </div>
                    </Modal.Body>
                </Modal>
            </>
        )
    }
}
