import React, { Component } from 'react'
import Mynavbar from './Mynavbar'
import ContentFrame from './ContentFrame';
import TreeView from '@mui/lab/TreeView';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TreeItem from '@mui/lab/TreeItem';
import Searched from './Searched';
import { Modal } from 'react-bootstrap'
export default class Caselaw extends Component {
    constructor(props) {
        super(props)
        this.state = {
            openCustomFrame: false,
            targetFile: '', isError: false,path:[],field:'',isLandMark:0,
            subfolder: '', mainFolder: '', isOpenTree: false, isCourt: false,
            lawData: [
                {
                    name: "Court",
                    children: [
                        {
                            name: "Supreme Court",
                            link: 'Assets/Data5t/cl/clsupr/'
                        },
                        {
                            name: "High Court",
                            link: 'Assets/Data5t/cl/clhigh/'
                        },
                        {
                            name: "Tribunal",
                            link: 'Assets/Data5t/cl/cltrib/'
                        },
                        // {
                        //     name: "Larger Bench",
                        //     link: 'Assets/Data5t/cl/cltkol/'
                        // },
                        {
                            name: "National Anti-Profiteering Authority",
                            link: 'Assets/Data5t/cl/clnapa/'
                        }
                    ]
                },
                {
                    name: "Subject",
                    children: [
                        {
                            name: "GST",
                            link: ''
                        },
                        {
                            name: "Central Excise",
                            link: ''
                        },
                        {
                            name: "Custom",
                            link: ''
                        },
                        {
                            name: "Service Tax",
                            link: ''
                        },
                        // {
                        //     name: "VAT & Others",
                        //     link: ''
                        // }
                    ]
                },
                {
                    name: "Land Mark Judgements",
                    children: [
                        {
                            name: "GST",
                            link: ''
                        },
                        {
                            name: "Central Excise",
                            link: ''
                        },
                        {
                            name: "Custom",
                            link: ''
                        },
                        {
                            name: "Service Tax",
                            link: ''
                        },
                        // {
                        //     name: "VAT & Others",
                        //     link: ''
                        // }
                    ]
                }
            ]
        }
    }

    setInitialPage(child, parent) {
        console.log(child, parent)
        if (child.link != "") {
            this.setState({ targetFile: child.link })
        }
        child = child.name.toLowerCase()
        parent = parent.toLowerCase()

        if (parent == 'court') {
            if (child == 'supreme court') {
                this.setState({ path: [`Assets/Data5t/cl/clsupr`], isOpenTree: true, isCourt: true })
            } else if (child == 'high court') {
                this.setState({ path: [`Assets/Data5t/cl/clhigh`], isOpenTree: true, isCourt: true })
            } else if (child == 'tribunal') {
                let paths = [`Assets/Data5t/cl/cltban`, `Assets/Data5t/cl/cltche`, `Assets/Data5t/cl/cltdel`, `Assets/Data5t/cl/cltkol`, `Assets/Data5t/cl/cltmum`, `Assets/Data5t/cl/clthyd`, `Assets/Data5t/cl/cltrib`, `Assets/Data5t/cl/cltlar`]
                this.setState({ path: paths, isOpenTree: true, isCourt: true })
            } else if (child == 'larger bench') {
                this.setState({ path: [`Assets/Data5t/cl/cltlar`], isOpenTree: true, isCourt: true })
            } else if (child == 'national anti-profiteering authority') {
                this.setState({ path: [`Assets/Data5t/cl/clnapa`], isOpenTree: true, isCourt: true })
            }
        }else if(parent == 'subject'){
            if(child == 'gst'){
                this.setState({field:'GT', isOpenTree: true})
            }else if(child == 'central excise'){
                this.setState({field:'CE', isOpenTree: true})
            }else if(child == 'custom'){
                this.setState({field:'CU', isOpenTree: true})
            }else if(child == 'service tax'){
                this.setState({field:'ST', isOpenTree: true})
            }
        }else if(parent == 'land mark judgements'){
            if(child == 'gst'){
                this.setState({field:'GT',isLandMark:1, isOpenTree: true})
            }else if(child == 'central excise'){
                this.setState({field:'CE',isLandMark:1, isOpenTree: true})
            }else if(child == 'custom'){
                this.setState({field:'CU',isLandMark:1, isOpenTree: true})
            }else if(child == 'service tax'){
                this.setState({field:'ST',isLandMark:1, isOpenTree: true})
            }
        }
        // switch (parent) {
        //     case 'court': {
        //         switch (child) {
        //             case 'supreme court': this.setState({ subfolder: 'clsupr', mainFolder: 'cl' }); break;
        //             case 'high court': this.setState({ subfolder: 'clhigh', mainFolder: 'cl' }); break;
        //             case 'tribunal': this.setState({ subfolder: 'cltrib', mainFolder: 'cl' }); break;
        //             case 'larger bench': this.setState({ subfolder: 'cltkol', mainFolder: 'cl' }); break;
        //             case 'national anti-profiteering authority': this.setState({ subfolder: 'clnapa', mainFolder: 'cl' }); break;
        //         }
        //     }
        //     // case 'subject': {
        //     //     switch (child) {
        //     //         case 'act': this.setState({ subfolder: 'cuacts', mainFolder: 'cu' }); break;
        //     //         case 'rules': this.setState({ subfolder: 'curule', mainFolder: 'cu' }); break;
        //     //         case 'circular': this.setState({ subfolder: 'cuncuc', mainFolder: 'cu' }); break;
        //     //         case 'notification': this.setState({ subfolder: 'cuncun/cuncuv', mainFolder: 'cu' }); break;
        //     //         case 'regulation': this.setState({ subfolder: 'curegu', mainFolder: 'cu' }); break;
        //     //         case 'tariff': this.setState({ subfolder: 'cutari', mainFolder: 'cu' }); break;
        //     //         case 'procedures & articles': this.setState({ subfolder: 'spartl', mainFolder: 'sp' }); break;
        //     //     }
        //     // }
        //     // case 'land mark judgements': {
        //     //     switch (child) {
        //     //         case 'act': this.setState({ subfolder: 'ftacts', mainFolder: 'ft' }); break;
        //     //         case 'rules': this.setState({ subfolder: 'ftrule', mainFolder: 'ft' }); break;
        //     //         case 'circular': this.setState({ subfolder: 'ftcirc', mainFolder: 'ft' }); break;
        //     //         case 'notification': this.setState({ subfolder: 'ftnoti', mainFolder: 'ft' }); break;
        //     //         case 'appendix': this.setState({ subfolder: 'ftappe', mainFolder: 'ft' }); break;
        //     //         // case 'anf' : this.setState({subfolder:'ftappe',mainFolder:'ft'});break;
        //     //         case 'itc (hs)': this.setState({ subfolder: 'ftitch', mainFolder: 'ft' }); break;
        //     //         case 'depb draw': this.setState({ subfolder: 'ftrate', mainFolder: 'ft' }); break;
        //     //         case 'policy': this.setState({ subfolder: 'ftpoli', mainFolder: 'ft' }); break;
        //     //         case 'trade notice': this.setState({ subfolder: 'fttrad', mainFolder: 'ft' }); break;
        //     //         case 'public notice': this.setState({ subfolder: 'ftpubl', mainFolder: 'ft' }); break;
        //     //         case 'hb procedure': this.setState({ subfolder: 'ftproc', mainFolder: 'ft' }); break;
        //     //         case 'sion': this.setState({ subfolder: 'ftsion', mainFolder: 'ft' }); break;
        //     //     }
        //     // }
        // }

    }
    componentDidCatch(error) {
        this.setState({ isError: true })
    }
    // setTree(data) {
    //     if (data.children != undefined) {
    //         return (
    //             <TreeItem key={data.name} nodeId={`${data.name}`} label={data.name}>
    //                 {data.children.map((child, i) => {
    //                     return this.setTree(child)
    //                 })}
    //             </TreeItem>
    //         )
    //     } else {
    //         return (
    //             <TreeItem key={data.name} nodeId={`${data.name}`} label={data.name} onClick={() => this.setInitialPage(data, data.name)} />
    //         )
    //     }
    // }
    render() {
        console.log(this.state.isOpenTree)
        return (
            <div>
                <Mynavbar
                    goBack={() => this.props.goBack()}
                    searchapi={this.props.searchApi}
                    header={"Case Laws"}
                    rootUrl={this.props.rootUrl}
                    userid={this.props.userid}
                    goHome={() => this.props.goBack()}
                />
                <div className="mainBox">
                    <div className="row">
                        {
                            this.state.lawData.map((data, index) => {
                                return (
                                    <div className="col-md-3 col-12">
                                        <TreeView
                                            key={data.name}
                                            aria-label="file system navigator"
                                            defaultCollapseIcon={<ExpandMoreIcon />}
                                            defaultExpandIcon={<ChevronRightIcon />}
                                            sx={{ height: "100%", flexGrow: 1, maxWidth: 400, overflowY: 'auto' }}
                                        >
                                            <TreeItem key={data.name + index} nodeId={`${data.name}`} label={data.name}>
                                                {data.children.map((child, index) => {
                                                    return (
                                                        <TreeItem key={child.name + index} nodeId={`${child.name}`} label={child.name} onClick={() => this.setInitialPage(child, data.name)} />
                                                    )
                                                })}
                                            </TreeItem>
                                            {/* {this.setTree(data)} */}
                                        </TreeView>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
                {/* {
                    this.state.isOpenTree ?
                        // <ContentFrame
                        //     goBack={() => this.setState({ openCustomFrame: false })}
                        //     filepath={this.state.targetFile + 'index.htm'}
                        //     searchApi={this.props.searchApi}
                        //     rootUrl={this.props.rootUrl}
                        //     userid={this.props.userid}
                        //     MainFolder={this.state.mainFolder}
                        //     SubFolder={this.state.subfolder}
                        //     goHome={() => this.props.goBack()}
                        // /> */}
                <Modal fullscreen={true} id="documentmodal" animation={true} show={this.state.isOpenTree} onHide={() => this.setState({ isOpenTree: false })}>
                    <Modal.Body>
                        <Searched
                            searchApi={this.props.searchApi}
                            rootUrl={this.props.rootUrl}
                            goBack={() => this.setState({ isOpenTree: false,isCourt:false ,path:[],field:'',isLandMark:0})}
                            userid={this.props.userid}
                            goHome={() => this.props.goBack()}
                            isReferedByCaseLaws={true}
                            isCourt={this.state.isCourt}
                            path={this.state.path}
                            field={this.state.field}
                            isLandMark={this.state.isLandMark}
                        />
                    </Modal.Body>
                </Modal>
            </div>
        )
    }
}
