import React, { Component } from 'react'
import { Modal, Spinner } from 'react-bootstrap'
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import { Input } from '../../usefulcomps/HexInput/InputBox'
import Hexapi from '../../usefulcomps/HexAPI/Hexapi'
import ComboBox from '../../usefulcomps/HexInput/Combobox';
import JudgePartylist from './JudgePartylist'

// Provide subfolders to make GST Tree Structure Complete : 
// SGST(Act, Rules, Notification(Rate),Notification,Circular,Order,RDO),
// GST-Other(Press Release,portal Related Updates,Council Meeting Updates)
export default class Parametericsearch extends Component {
    constructor(props) {
        super(props)
        this.state = {
            paramsearch: '',
            isOptionModalOpen: false,
            isComponentRender: false,
            judges: [], parties: [],
            showtargetField: false,
            showtargetSubField: false,
            selectedJudgeCode: "", selectedJudge: '', selectedPartyCode: '', selectedParty: '',
            ParametericsearchUrl: sessionStorage.getItem('paramsearchurl'),
            favours: ['Assesse Favour', 'Revenue Favour', 'Partly Allowed', 'Remand', 'All'],
            selectedFavour: '', selectedCitation: '',
            Citation: ['LCX', 'ELT', 'RLT', 'ECR', 'STR', 'ECC', 'TMI', 'Taxmann', 'SCC', 'TIOL', 'GSTL', 'AIR'],
            citationCode: '', fromdate: '', todate: '', order: '', landmark: '', isRenderAgain: false,
            subcourts: [], isRenderAgain: false, finalCourts: {}, selectedKey: '', finalCourtString: '',
            selectedField: 'CGST', selectedSubField: "All", fullPath: 'Assets/Data5t/gt/',
            circNotiNumber: '', Notifromdate: '', Notitodate: '', isOpenJudgeList: false, isOpenPartyList: false, isInputsChanged: false,
            leftBoxFieldsObj: [
                {
                    field: 'Case Law & Advance Ruling',
                    subfield: [
                        { value: 'Case Law', name: 'Case Law & Advance Ruling', path: '' },
                    ],
                    path: 'cl/'
                },
                // {
                //     field: 'Advance Ruling',
                //     subfield: [
                //         { value: 'Advance Ruling', name: 'Advance Ruling', path: '' },
                //     ],
                //     path: 'cl/'
                // },
                {
                    field: 'CGST',
                    subfield: [
                        { value: 'All', name: 'All', path: '' },
                        { value: 'Act', name: 'Act', path: 'gtcgst/' },
                        { value: 'Rules', name: 'Rules', path: 'gtstrl/' },
                        { value: 'Notification(Rate)', name: 'Notification(Rate)', path: 'ctntra/' },
                        { value: 'Notification(Non-Rate)', name: 'Notification(Non-Rate)', path: 'ctntnr/' },
                        { value: 'Circular', name: 'Circulars', path: 'ctcirc/' },
                        { value: 'Instructions', name: 'Instructions', path: 'gtcgcu/' },
                        { value: 'Guidelines', name: 'Guidelines', path: 'gtcggu/' },
                        { value: 'Order', name: 'Order', path: 'ctordr/' },
                        { value: 'ROD', name: 'ROD', path: 'ctorod/' },
                        { value: 'Case Law & Advance Ruling', name: 'Case Law & Advance Ruling', path: '' },
                        // { value: 'Advance Ruling', name: 'Advance Ruling', path: '' },
                        { value: 'Press Release', name: 'Press Release', path: 'gtpres/' },
                        { value: 'Portal Related Updates', name: 'Portal Related Updates', path: 'gstpru/' },
                        { value: 'Council Meeting Updates', name: 'Council Meeting Updates', path: 'gtport/' },
                        { value: 'Form', name: 'Form', path: 'gtcfrm/' },
                        { value: "CBIC FAQ's", name: "CBIC FAQ's", path: 'gtfaqb/' },
                        // { value:'Case Laws', name: 'Case Laws', path: '' }
                    ],
                    path: 'gt/'
                },
                {
                    field: 'IGST',
                    subfield: [
                        { value: 'All', name: 'All', path: '' },
                        { value: 'Act', name: 'Act', path: 'gtigst/' },
                        { value: 'Rules', name: 'Rules', path: 'gtistr/' },
                        { value: 'Notification(Rate)', name: 'Notification(Rate)', path: 'igntra/' },
                        { value: 'Notification(Non-Rate)', name: 'Notification(Non-Rate)', path: 'igntnr/' },
                        { value: 'Circular', name: 'Circulars', path: 'gtigcu/' },
                        { value: 'Case Law & Advance Ruling', name: 'Case Law & Advance Ruling', path: '' },
                        // { value: 'Advance Ruling', name: 'Advance Ruling', path: '' },
                        { value: 'Press Release', name: 'Press Release', path: 'gtpres/' },
                        { value: 'Portal Related Updates', name: 'Portal Related Updates', path: 'gstpru/' },
                        { value: 'Council Meeting Updates', name: 'Council Meeting Updates', path: 'gtport/' },
                        { value: 'Form', name: 'Form', path: 'gtcfrm/' },
                        { value: "CBIC FAQ's", name: "CBIC FAQ's", path: 'gtfaqb/' },
                        // { value:'', name: 'Order', path: 'gtnoti/' }
                    ],
                    path: 'gt/'
                },
                {
                    field: 'UGST',
                    subfield: [
                        { value: 'All', name: 'All', path: '' },
                        { value: 'Act', name: 'Act', path: 'gtugst/' },
                        { value: 'Rules', name: 'Rules', path: 'gtugru/' },
                        { value: 'Notification(Rate)', name: 'Notification(Rate)', path: 'ugntra/' },
                        { value: 'Notification(Non-Rate)', name: 'Notification(Non-Rate)', path: 'ugntnr/' },
                        // { name: 'Circular', path: '' },
                        { value: 'Order/ROD', name: 'Orders & ROD', path: 'ugordr/' },
                        { value: 'Case Law & Advance Ruling', name: 'Case Law & Advance Ruling', path: '' },
                        // { value: 'Advance Ruling', name: 'Advance Ruling', path: '' },
                        { value: 'Press Release', name: 'Press Release', path: 'gtpres/' },
                        { value: 'Portal Related Updates', name: 'Portal Related Updates', path: 'gstpru/' },
                        { value: 'Council Meeting Updates', name: 'Council Meeting Updates', path: 'gtport/' },
                        { value: 'Form', name: 'Form', path: 'gtcfrm/' },
                        { value: "CBIC FAQ's", name: "CBIC FAQ's", path: 'gtfaqb/' },
                    ],
                    path: 'gt/'
                },
                {
                    field: 'GST(Compensation to the States) Act',
                    subfield: [
                        { value: 'All', name: 'All', path: '' },
                        { value: 'Act', name: 'Act', path: 'gtcomp/' },
                        { value: 'Rules', name: 'Rules', path: 'gtcoru/' },
                        { value: 'Notification(Rate)', name: 'Notification(Rate)', path: 'compra/' },
                        { value: 'Notification(Non-Rate)', name: 'Notification(Non-Rate)', path: 'compnr/' },
                        { value: 'Circular', name: 'Circulars', path: 'gtcoci/' },
                        { value: 'Case Law & Advance Ruling', name: 'Case Law & Advance Ruling', path: '' },
                        // { value: 'Advance Ruling', name: 'Advance Ruling', path: '' },
                        { value: 'Press Release', name: 'Press Release', path: 'gtpres/' },
                        { value: 'Portal Related Updates', name: 'Portal Related Updates', path: 'gstpru/' },
                        { value: 'Council Meeting Updates', name: 'Council Meeting Updates', path: 'gtport/' },
                        { value: 'Form', name: 'Form', path: 'gtcfrm/' },
                        { value: "CBIC FAQ's", name: "CBIC FAQ's", path: 'gtfaqb/' },
                        // { value:'Order', name: 'Order', path: 'gtnoti/' }
                    ],
                    path: 'gt/'
                },
                // {
                //     field: 'GST-Other',
                //     subfield: [
                //         { value: 'All', name: 'All', path: '' },
                //         { value: 'Form', name: 'Form', path: 'gtcfrm/' },
                //         { value: 'Press Release', name: 'Press Release', path: 'gtpres/' },
                //         // { value: 'Gst ques', name: 'Gst ques', path: 'gtfaqb/' },
                //         { value: 'Portal Related Updates', name: 'Portal Related Updates', path: 'gtcgcu/' },
                //         { value: 'Council Meeting Updates', name: 'Council Meeting Updates', path: 'gtport/' },
                //         { value: 'Case Laws', name: 'Case Laws', path: '' },
                //         { value: 'Advance Ruling', name: 'Advance Ruling', path: '' }
                //     ],
                //     path: 'gt/'
                // },
                {
                    field: 'Custom',
                    subfield: [
                        { value: 'All', name: 'All', path: '' },
                        { value: 'Act', name: 'Act', path: 'cuacts/' },
                        { value: 'Rules', name: 'Rules', path: 'curule/' },
                        { value: 'Notification(Tariff)', name: 'Notification(Tariff)', path: 'cuncuv/' },
                        { value: 'Notification(Non-Tariff)', name: 'Notification(Non-Tariff)', path: 'cuncuv/' },
                        { value: 'Circular/Instructions/Guidelines', name: 'Circulars/Instructions/Guidelines', path: 'cuncuc/' },
                        { value: 'Regulations', name: 'Regulation', path: 'curegu/' },
                        { value: 'Manual', name: 'Manual', path: 'cumanu/' },
                        { value: 'Tariff', name: 'Tariff', path: 'cutar2/' },
                        // { value: 'Custom ques', name: 'Custom ques', path: 'cufaqb/' },
                        { value: 'Form', name: 'Forms', path: 'cuform/' },
                        { value: 'Case Law & Advance Ruling', name: 'Case Law & Advance Ruling', path: '' },
                        // { value: 'Advance Ruling', name: 'Advance Ruling', path: '' },
                        { value: "CBIC FAQ's", name: "CBIC FAQ's", path: 'cufaqb/' },

                    ],
                    path: 'cu/'
                },
                {
                    field: 'Foreign Trade Policy',
                    subfield: [
                        { value: 'All', name: 'All', path: '' },
                        { value: 'Act', name: 'Act', path: 'ftacts/' },
                        { value: 'Rules', name: 'Rules', path: 'ftrule/' },
                        { value: 'Circular', name: 'Circulars', path: 'ftcirc/' },
                        { value: 'Notification', name: 'Notification', path: 'ftnoti/' },
                        { value: 'Appendix', name: 'Appendix', path: 'ftappe/' },
                        { value: 'ITC (HS)', name: 'ITC(HS)', path: 'ftitch/' },
                        { value: 'DEPB DrawBack', name: 'DEPB DrawBack', path: 'ftrate/' },
                        { value: 'Policy', name: 'Policy', path: 'ftpoli/' },
                        { value: 'Trade Notice', name: 'Trade Notice', path: 'fttrad/' },
                        { value: 'Public Notice', name: 'Public Notice', path: 'ftpubl/' },
                        { value: 'HB Procedure', name: 'HB Procedure', path: 'ftproc/' },
                        { value: 'SION', name: 'SION', path: 'ftsion/' },
                        { value: "DGFT FAQ's", name: "DGFT FAQ's", path: 'ftfaqb/' },
                        { value: 'Case Law & Advance Ruling', name: 'Case Law & Advance Ruling', path: '' },
                    ],
                    path: 'ft/'
                },
                {
                    field: 'Service Tax',
                    subfield: [
                        { value: 'All', name: 'All', path: '' },
                        { value: 'Act', name: 'Act', path: 'stacts/' },
                        { value: 'Rules', name: 'Rules', path: 'strule/' },
                        { value: 'Notification', name: 'Notification', path: 'stncsv/' },
                        { value: 'Circular/Instruction/Guidelines', name: 'Circulars', path: 'stncsc/' },
                        { value: 'Scheme', name: 'Scheme', path: 'stsche/' },
                        { value: 'Forms', name: 'Forms', path: 'stform/' },
                        { value: 'Case Law & Advance Ruling', name: 'Case Law & Advance Ruling', path: '' }
                    ],
                    path: 'st/'
                },
                {
                    field: 'Excise',
                    subfield: [
                        { value: 'All', name: 'All', path: '' },
                        { value: 'Act', name: 'Act', path: 'ceacts/' },
                        // { value: 'Act', name: 'Act', path: 'ceacts/##Assets/Data5t/ce/cetari/' },
                        { value: 'Rules', name: 'Rules', path: 'cerule/' },
                        { value: 'Circular/Instruction/Guidelines', name: 'Circular', path: 'cencec/' },
                        { value: 'Notification(Tariff)', name: 'Notification(Tariff)', path: 'cencev/' },
                        { value: 'Notification(Non-Tariff)', name: 'Notification(Non-Tariff)', path: 'cencev/' },
                        { value: 'Manual', name: 'Manual', path: 'cemanu/' },
                        { value: 'Tariff', name: 'Tariff', path: 'cetari/' },
                        { value: 'Form', name: 'Form', path: 'ceform/' },
                        { value: 'Case Law & Advance Ruling', name: 'Case Law & Advance Ruling', path: '' }
                    ],
                    path: 'ce/'
                },
                {
                    field: 'FEMA',
                    subfield: [
                        { value: 'All', name: 'All', path: '' },
                        { value: 'Act', name: 'Act', path: 'rbacts/' },
                        { value: 'Rules', name: 'Rules', path: 'rbrule/' },
                        { value: 'Circular', name: 'Circular', path: 'rbcirc/' },
                        { value: 'Notification', name: 'Notification', path: 'rbnoti/' },
                        { value: 'Regulations', name: 'Regulation', path: 'rbregu/' },
                        { value: 'Citizen Charter', name: 'Citizen Charter', path: 'rbciti/' },
                        { value: 'Form', name: 'Form', path: 'rbform/' },
                        { value: 'RBI FAQ', name: 'RBI FAQ', path: 'rbfaqb/' },
                    ],
                    path: 'rb/'
                },
                {
                    field: 'SEZ',
                    subfield: [
                        { value: 'All', name: 'All', path: '' },
                        { value: 'Act', name: 'Act', path: 'szacts/' },
                        { value: 'Rules', name: 'Rules', path: 'szrule/' },
                        { value: 'Policy', name: 'Policy', path: 'szpoli/' },
                        { value: 'Appendix', name: 'Appendix', path: 'szappe/' },
                        // { value: 'Fema on SEZ', name: 'FEMA on sez', path: 'szfema/' },
                        { value: 'Notification', name: 'Notification', path: 'sznoti/' },
                        { value: 'Circular', name: 'Circular', path: 'szcirc/' }
                    ],
                    path: 'sz/'
                },
                {
                    field: 'Miscellaneous',
                    subfield: [
                        { value: 'Budget', name: 'Budget', path: 'bu/' },
                        { value: 'Ask-a-Question', name: 'Ask-a-Question', path: 'gt/gtillu/' },
                        { value: 'Allied Act', name: 'Allied Act', path: 'cu/cualar/' },
                        { value: 'Tariff', name: 'Tariff', path: 'cu/cutar2/' },
                    ],
                    path: ''
                },
                // {
                //     field: 'Budget',
                //     subfield: [
                //         { value: 'All', name: 'All', path: '' },
                //     ],
                //     path: 'bu/'
                // },
                // {
                //     field: 'Advance Ruling',
                //     subfield: [
                //         { value: 'All', name: 'All', path: '' },
                //         { value: 'AAR', name: 'AAR', path: 'claarc/' },
                //         { value: 'AAAR', name: 'AAAR', path: 'claaar/' },
                //     ],
                //     path: 'cl/'
                // },
                // {
                //     field: 'Question Bank',
                //     subfield: [
                //         { value: 'All', name: 'All', path: '' },
                //         { value: 'CBIC FAQ', name: 'CBIC FAQ', path: 'gtfaqb/' },
                //         { value: 'Ask-A-Question', name: 'Ask-A-Question', path: 'gtillu/' },
                //     ],
                //     path: 'gt/'
                // },

            ],
            courts: {
                "Supreme Court": {
                    checked: false,
                    children: [],
                    latestclick: false
                },
                "High Court": {
                    checked: false,
                    children: [],
                    latestclick: false
                },
                "Tribunal": {
                    checked: false,
                    children: [],
                    latestclick: false
                },
                "Others": {
                    checked: false,
                    children: [],
                    latestclick: false
                },
                "Anti Profiteering": {
                    checked: false,
                    children: [],
                    latestclick: false
                },
                "AAR": {
                    checked: false,
                    children: [],
                    latestclick: false
                },
                "AAAR": {
                    checked: false,
                    children: [],
                    latestclick: false
                },
            }
        }
    }

    componentDidMount() {
        console.log(this.props.targetField,this.props.targetSubField,this.props);
        
        this.setState({ isComponentRender: false })
        let obj = {
            query: `[dbo].[Lawcrux_Web_Proc_Search_GetData] @UserID='{0}'`,
            queryArr: [`${this.props.userid}`]
        }
        Hexapi(obj).then(resp => {
            console.log(resp)
            if (Object.keys(resp).length > 0) {
                this.state.courts["Supreme Court"].children = resp.supremecourt
                this.state.courts["High Court"].children = resp.highcourt
                this.state.courts["Tribunal"].children = resp.tribunal
                this.state.courts["Others"].children = resp.others
                this.state.courts["Anti Profiteering"].children = resp.antiprofiteering
                this.state.courts["AAR"].children = resp.aar
                this.state.courts["AAAR"].children = resp.aaar
                this.setState({
                    judges: resp.judge,
                    parties: resp.party
                })
            }
        }).then(() => this.setState({ isComponentRender: true })).catch(err => console.error(err))
        if(this.props.targetField != "" && this.props.targetSubField != ""){
            this.setState({ selectedField:this.props.targetField })
            this.setState({ selectedSubField:this.props.targetSubField })
            this.setState({fullPath:this.props.searchpath})
        }else{
            let selectedObj = this.state.leftBoxFieldsObj.filter((obj) => obj.field == this.state.selectedField)[0]
            let fieldPath = selectedObj.path,
            subFieldPath = selectedObj.subfield.filter((obj) => obj.value == this.state.selectedSubField)[0].path
            
            if (this.state.selectedField == 'CGST' && this.state.selectedSubField == 'All') {
                let obj = {
                    fullPath: this.state.fullPath,
                    fieldPath: fieldPath,
                    subFieldPath: subFieldPath,
                    selectedField: this.state.selectedField,
                    selectedSubField: this.state.selectedSubField,
                    field: 'GT'
                }
                this.props.onFieldsChange(obj, '-1', '0')
            }
        }
        
    }

    onInputsChange() {
        let obj = {
            judge: this.state.selectedJudgeCode,
            party: this.state.selectedPartyCode,
            landmark: this.state.landmark ? 1 : 0,
            order: this.state.order,
            citation: this.state.selectedCitation,
            citationcode: this.state.citationCode,
            fromdate: this.state.fromdate,
            todate: this.state.todate,
            favour: this.state.selectedFavour,
            court: this.state.finalCourtString
        }
        this.props.onInputsChange(obj)
        // this.setState({ isInputsChanged: true }, () => {
        //     this.createFullPath()
        // })
        console.log(obj)
    }
    onCircNotiChange() {
        if (this.state.selectedSubField.includes("Circular")) {
            let obj = {
                type: 'circular',
                number: this.state.circNotiNumber,
                field: this.state.selectedField,
                subfield: this.state.selectedSubField
            }
            console.log(obj)
            if (this.state.circNotiNumber == '') {
                this.props.onCircNotiChange(obj, '0')
            } else {
                this.props.onCircNotiChange(obj, '1')
            }
        } else if (this.state.selectedSubField.includes("Notification")) {
            let obj = {
                type: 'Notification',
                number: this.state.circNotiNumber,
                from: this.state.Notifromdate,
                to: this.state.Notitodate,
                field: this.state.selectedField,
                subfield: this.state.selectedSubField
            }
            console.log(obj)
            if (this.state.circNotiNumber == '') {
                this.props.onCircNotiChange(obj, '0')
            } else {
                this.props.onCircNotiChange(obj, '1')
            }
        }
    }
    createFinalString() {
        let arr = []
        for (let i = 0; i < Object.keys(this.state.finalCourts).length; i++) {
            let newkey = Object.keys(this.state.finalCourts)[i],
                value = this.state.finalCourts[newkey].filter((el) => el.checked !== false)
            if (value.length == 0) {
                this.state.courts[this.state.selectedKey].checked = false
                this.setState({ isRenderAgain: true })
            }
            for (let j = 0; j < value.length; j++) {
                arr.push(value[j].courtcode)
            }
            // console.log(value)
        }
        // console.log(arr)
        this.setState({ finalCourtString: arr.join(',') }, () => this.onInputsChange())
    }
    citationplaceholderchange(e) {
        console.log(e.target.value)
        var citation = e.target.value;
        this.setState({ selectedCitation: e.target.value })
        if (citation == 'LCX') {
            this.setState({ citationplaceholder: '2022(08)LCX0101' })
        }
        else if (citation == 'ELT') {
            this.setState({ citationplaceholder: '2019 (367) E.L.T. 172 (Jhar.)' })
        }
        else if (citation == 'RLT') {
            this.setState({ citationplaceholder: '2009 (093) RLT 0121 (P & H)' })
        }
        else if (citation == 'ECR') {
            this.setState({ citationplaceholder: '2007 (143) ECR 0205 (Tri.- Chennai)' })
        }
        else if (citation == 'STR') {
            this.setState({ citationplaceholder: '2017(049) S.T.R. 0484(Guj.)' })
        }
        else if (citation == 'ECC') {
            this.setState({ citationplaceholder: '2007 (117) ECC 0205' })
        }
        else if (citation == 'TMI') {
            this.setState({ citationplaceholder: '2021 (6) TMI 378' })
        }
        else if (citation == 'Taxmann') {
            this.setState({ citationplaceholder: '[2022] 142 taxmann.com 131' })
        }
        else if (citation == 'SCC') {
            this.setState({ citationplaceholder: '(2003) 11 SCC 405' })
        }
        else if (citation == 'TIOL') {
            this.setState({ citationplaceholder: '2019-TIOL-595-HC-ORISSA-CUS' })
        }
        else if (citation == 'GSTL') {
            this.setState({ citationplaceholder: '2017 (3) G.S.T.L. 209 (Raj.)' })
        }
        else if (citation == 'AIR') {
            this.setState({ citationplaceholder: '1962 AIR 1893 or  AIR 1962 SC 1006' })
        }
        else {
            this.setState({ citationplaceholder: '' })
        }
    }
    storeCheckBoxes(key, e) {
        this.state.courts[key].checked = e.target.checked

        let children = this.state.courts[key].children,
            newChildren = []
        for (let i = 0; i < children.length; i++) {
            let obj = children[i]
            obj['checked'] = e.target.checked
            newChildren.push(obj)
        }

        if (e.target.checked) {
            this.state.finalCourts[key] = newChildren
            // console.log(this.state.finalCourts)
            this.createFinalString()
        } else {
            delete this.state.finalCourts[key]
            this.createFinalString()
            // console.log(this.state.finalCourts, this.state.subcourts)
        }

        this.setState({ subcourts: newChildren, selectedKey: key })
    }
    storeFinalCourts(item, index, e) {
        // this.setState({subcourts})
        this.state.subcourts[index].checked = e.target.checked
        this.state.finalCourts[this.state.selectedKey] = this.state.subcourts
        // console.log(this.state.finalCourts)
        this.createFinalString()
        this.setState({ isRenderAgain: true })
    }

    createFullPath() {
        // console.log('Assets/Data5t/')
        let selectedObj = this.state.leftBoxFieldsObj.filter((obj) => obj.field == this.state.selectedField)[0]
        let fieldPath = selectedObj.path,
            subFieldPath = selectedObj.subfield.filter((obj) => obj.value == this.state.selectedSubField)[0].path
        // || this.state.selectedField == "Excise" || this.state.selectedField == "Service Tax" || this.state.selectedField == "Custom"
        console.log(fieldPath, subFieldPath, selectedObj)
        console.log(this.state)
        console.log(this.state.selectedField,this.state.selectedSubField)
        // if (this.state.selectedField == 'Case Laws') {
        //     let obj = {
        //         fullPath: 'Assets/Data5t/cl',
        //         fieldPath: fieldPath,
        //         subFieldPath: subFieldPath,
        //         selectedField: this.state.selectedField,
        //         selectedSubField: this.state.selectedSubField,
        //         field: '-1',
        //         subfield: '-1'
        //     }
        //     this.props.onFieldsChange(obj, '-1', '2')
        // }
        // if (this.state.selectedField == 'Advance Ruling') {
        //         let obj = {
        //             fullPath: 'Assets/Data5t/cl',
        //             fieldPath: fieldPath,
        //             subFieldPath: subFieldPath,
        //             selectedField: this.state.selectedField,
        //             selectedSubField: this.state.selectedSubField,
        //             field: '-1',
        //             subfield: 'adv'
        //         }
        //         this.props.onFieldsChange(obj, '-1', '3')
        // }
        let subjects = ["CGST", "IGST", "UGST", "GST(Compensation to the States) Act"],
            fields = ['Case Laws', 'Advance Ruling', 'All']
        if (subjects.includes(this.state.selectedField) && fields.includes(this.state.selectedSubField)) {
            let obj = {
                type: 'GT',
                value: ''
            }
            if (this.state.selectedSubField == 'Case Laws') {
                let obj = {
                    fullPath: 'Assets/Data5t/gt',
                    fieldPath: fieldPath,
                    subFieldPath: subFieldPath,
                    selectedField: this.state.selectedField,
                    selectedSubField: this.state.selectedSubField,
                    field: 'GT',
                    subfield: '-1'
                }
                console.log(obj)
                // if (this.state.isInputsChanged) {
                //     console.log('=======Input field changed ===========')
                // this.props.onFieldsChange(obj, '-1')
                this.props.onFieldsChange(obj, '-1', '2')
                // this.props.onCaseLawAdvanceRulingChange(obj, '2')
                // this.props.onFieldsChange({ fullPath: 'Assets/Data5t/cl', selectedField: this.state.selectedField, selectedSubField: this.state.selectedSubField })
                // this.props.onCaseLawAdvanceRulingChange(obj)
                // } else {
                //     console.log('=======Input field not changed ===========')
                //     this.props.onFieldsChange(obj)
                // }
            } else if (this.state.selectedSubField == 'Advance Ruling') {
                let obj = {
                    fullPath: 'Assets/Data5t/gt',
                    fieldPath: fieldPath,
                    subFieldPath: subFieldPath,
                    selectedField: this.state.selectedField,
                    selectedSubField: this.state.selectedSubField,
                    field: 'GT',
                    subfield: 'adv'
                }
                console.log('commingshjfjkdsfsdf=-============')
                console.log(obj)
                this.props.onFieldsChange(obj, '-1', '3')
                // this.props.onCaseLawAdvanceRulingChange(obj, '3')
                // obj.value = 'adv'
                // this.props.onFieldsChange({ fullPath: 'Assets/Data5t/cl', selectedField: this.state.selectedField, selectedSubField: this.state.selectedSubField })
                // this.props.onCaseLawAdvanceRulingChange(obj)
            }
            //  else if (this.state.selectedSubField == 'All') {
            //     let obj = {
            //         type: 'GT',
            //         value: '-1'
            //     }
            //     this.props.onFieldsChange({ fullPath: 'Assets/Data5t/', selectedField: this.state.selectedField, selectedSubField: this.state.selectedSubField })
            //     this.props.onCaseLawAdvanceRulingChange(obj, '4')
            // }
             else {
                this.setState({ fullPath: 'Assets/Data5t/' + fieldPath + subFieldPath }, () => {
                    let obj = {
                        fullPath: this.state.fullPath,
                        fieldPath: fieldPath,
                        subFieldPath: subFieldPath,
                        selectedField: this.state.selectedField,
                        selectedSubField: this.state.selectedSubField,
                        field: 'GT'
                    }
                    this.props.onFieldsChange(obj, '-1', '0')
                    console.log(obj)
                })
            }
        } else if (this.state.selectedField == "Excise") {
            let obj = {
                type: 'CE',
                value: ''
            }
            if (this.state.selectedSubField == 'Case Laws') {
                let obj = {
                    fullPath: 'Assets/Data5t/ce',
                    fieldPath: fieldPath,
                    subFieldPath: subFieldPath,
                    selectedField: this.state.selectedField,
                    selectedSubField: this.state.selectedSubField,
                    field: 'CE',
                    subfield: '-1'
                }
                console.log(obj)
                this.props.onFieldsChange(obj, '-1', '2')
                // this.props.onCaseLawAdvanceRulingChange(obj, '2')
                // this.props.onFieldsChange({ fullPath: 'Assets/Data5t/cl', selectedField: this.state.selectedField, selectedSubField: this.state.selectedSubField })
                // this.props.onCaseLawAdvanceRulingChange(obj)
            }
            //  else if (this.state.selectedSubField == 'All') {
            //     let obj = {
            //         type: 'CE',
            //         value: '-1'
            //     }
            //     this.props.onFieldsChange({ fullPath: 'Assets/Data5t/', selectedField: this.state.selectedField, selectedSubField: this.state.selectedSubField })
            //     this.props.onCaseLawAdvanceRulingChange(obj, '4')
            // }
             else {
                this.setState({ fullPath: 'Assets/Data5t/' + fieldPath + subFieldPath }, () => {
                    let obj = {
                        fullPath: this.state.fullPath,
                        fieldPath: fieldPath,
                        subFieldPath: subFieldPath,
                        selectedField: this.state.selectedField,
                        selectedSubField: this.state.selectedSubField
                    }
                    this.props.onFieldsChange(obj, '-1', '0')
                    console.log(obj)
                })
            }
        } else if (this.state.selectedField == "Service Tax") {
            let obj = {
                type: 'ST',
                value: ''
            }
            if (this.state.selectedSubField == 'Case Laws') {
                let obj = {
                    fullPath: 'Assets/Data5t/st',
                    fieldPath: fieldPath,
                    subFieldPath: subFieldPath,
                    selectedField: this.state.selectedField,
                    selectedSubField: this.state.selectedSubField,
                    field: 'ST',
                    subfield: '-1'
                }
                console.log(obj)
                this.props.onFieldsChange(obj, '-1', '2')
                // this.props.onCaseLawAdvanceRulingChange(obj, '2')
                // this.props.onFieldsChange({ fullPath: 'Assets/Data5t/cl', selectedField: this.state.selectedField, selectedSubField: this.state.selectedSubField })
                // this.props.onCaseLawAdvanceRulingChange(obj)
            }
            //  else if (this.state.selectedSubField == 'All') {
            //     let obj = {
            //         type: 'ST',
            //         value: '-1'
            //     }
            //     this.props.onFieldsChange({ fullPath: 'Assets/Data5t/', selectedField: this.state.selectedField, selectedSubField: this.state.selectedSubField })
            //     this.props.onCaseLawAdvanceRulingChange(obj, '4')
            // }
             else {
                this.setState({ fullPath: 'Assets/Data5t/' + fieldPath + subFieldPath }, () => {
                    let obj = {
                        fullPath: this.state.fullPath,
                        fieldPath: fieldPath,
                        subFieldPath: subFieldPath,
                        selectedField: this.state.selectedField,
                        selectedSubField: this.state.selectedSubField
                    }
                    this.props.onFieldsChange(obj, '-1', '0')
                    console.log(obj)
                })
            }
        } else if (this.state.selectedField == "Custom") {
            let obj = {
                type: 'CU',
                value: ''
            }
            if (this.state.selectedSubField == 'Case Laws') {
                let obj = {
                    fullPath: 'Assets/Data5t/cu',
                    fieldPath: fieldPath,
                    subFieldPath: subFieldPath,
                    selectedField: this.state.selectedField,
                    selectedSubField: this.state.selectedSubField,
                    field: 'CU',
                    subfield: '-1'
                }
                console.log('commingshjfjkdsfsdf=-============')
                console.log(obj)
                this.props.onFieldsChange(obj, '-1', '2')
                // this.props.onCaseLawAdvanceRulingChange(obj, '2')
                // this.props.onFieldsChange({ fullPath: 'Assets/Data5t/cl', selectedField: this.state.selectedField, selectedSubField: this.state.selectedSubField })
                // this.props.onCaseLawAdvanceRulingChange(obj)
            } else if (this.state.selectedSubField == 'Advance Ruling') {
                let obj = {
                    fullPath: 'Assets/Data5t/cl',
                    fieldPath: fieldPath,
                    subFieldPath: subFieldPath,
                    selectedField: this.state.selectedField,
                    selectedSubField: this.state.selectedSubField,
                    field: 'CU',
                    subfield: 'adv'
                }
                console.log('commingshjfjkdsfsdf=-============')
                console.log(obj)
                this.props.onFieldsChange(obj, '-1', '3')
                // this.props.onCaseLawAdvanceRulingChange(obj, '3')
                // obj.value = 'adv'
                // this.props.onFieldsChange({ fullPath: 'Assets/Data5t/cl', selectedField: this.state.selectedField, selectedSubField: this.state.selectedSubField })
                // this.props.onCaseLawAdvanceRulingChange(obj)
            }
            //  else if (this.state.selectedSubField == 'All') {
            //     let obj = {
            //         type: 'CU',
            //         value: '-1'
            //     }
            //     this.props.onFieldsChange({ fullPath: 'Assets/Data5t/', selectedField: this.state.selectedField, selectedSubField: this.state.selectedSubField })
            //     this.props.onCaseLawAdvanceRulingChange(obj, '4')
            // }
             else {
                this.setState({ fullPath: 'Assets/Data5t/' + fieldPath + subFieldPath }, () => {
                    let obj = {
                        fullPath: this.state.fullPath,
                        fieldPath: fieldPath,
                        subFieldPath: subFieldPath,
                        selectedField: this.state.selectedField,
                        selectedSubField: this.state.selectedSubField
                    }
                    this.props.onFieldsChange(obj, '-1', '0')
                    console.log(obj)
                })
            }
        } else if (this.state.selectedField == 'All' && this.state.selectedSubField == 'All') {
            let obj = {
                type: '-1',
                value: '-1',
            }
            this.props.onFieldsChange({ fullPath: 'Assets/Data5t/', selectedField: this.state.selectedField, selectedSubField: this.state.selectedSubField })
            this.props.onCaseLawAdvanceRulingChange(obj, '4')
            // console.log('akjdsfkaldskfjkadhfksadf=============================')
            // }  else if (this.state.selectedField == 'All' && this.state.selectedSubField == 'Case Law') {
            //     console.log('come in all')

            //     let obj = {
            //         type: '-1',
            //         value: '-1'
            //     }
            //     this.props.onFieldsChange({ fullPath: 'Assets/Data5t/cl', selectedField: this.state.selectedField, selectedSubField: this.state.selectedSubField })
            //     this.props.onCaseLawAdvanceRulingChange(obj)
            //     // console.log('akjdsfkaldskfjkadhfksadf=============================')
        } else {
            console.log(this.state.selectedSubField)
            this.setState({ fullPath: 'Assets/Data5t/' + fieldPath + subFieldPath }, () => {
                // if (this.state.selectedSubField != "Case Law") {
                //     this.props.onInputsChange(null)
                // }
                // if (this.state.selectedSubField == 'Case Law') {
                //     let obj = {
                //         fullPath: 'Assets/Data5t/cl',
                //         fieldPath: fieldPath,
                //         subFieldPath: subFieldPath,
                //         selectedField: this.state.selectedField,
                //         selectedSubField: this.state.selectedSubField,
                //         field: '-1'
                //     }
                //     console.log(obj)
                //     this.props.onFieldsChange(obj)
                // } else {
                //     let obj = {
                //         fullPath: this.state.fullPath,
                //         fieldPath: fieldPath,
                //         subFieldPath: subFieldPath,
                //         selectedField: this.state.selectedField,
                //         selectedSubField: this.state.selectedSubField
                //     }
                //     this.props.onFieldsChange(obj,'-1','0')//here 0 representing normal search without any store procedure
                // }
                if (this.state.selectedSubField == 'Case Law') {
                    console.log("Testing12345")
                    let obj = {
                        fullPath: 'Assets/Data5t/cl',
                        fieldPath: fieldPath,
                        subFieldPath: subFieldPath,
                        selectedField: this.state.selectedField,
                        selectedSubField: this.state.selectedSubField,
                        field: '-1',
                        subfield: '-1'
                    }
                    console.log(obj)
                    this.props.onFieldsChange(obj, '-1', '2')
                    
                }else if(this.state.selectedSubField == 'Advance Ruling'){
                    console.log("Testing12345")
                    let obj = {
                        fullPath: 'Assets/Data5t/cl',
                        fieldPath: fieldPath,
                        subFieldPath: subFieldPath,
                        selectedField: this.state.selectedField,
                        selectedSubField: this.state.selectedSubField,
                        field: '-1',
                        subfield: 'adv'
                    }
                    this.props.onFieldsChange(obj, '-1', '3')
                }
                else {
                    let obj = {
                        fullPath: this.state.fullPath,
                        fieldPath: fieldPath,
                        subFieldPath: subFieldPath,
                        selectedField: this.state.selectedField,
                        selectedSubField: this.state.selectedSubField
                    }
                    this.props.onFieldsChange(obj, '-1', '0')//here 0 representing normal search without any store procedure
                    console.log("Testing")
                    // if (this.state.selectedJudgeCode != "") {
                    //     let obj = {
                    //         type: '-1',
                    //         value: '-1'
                    //     }
                    //     this.props.onFieldsChange({ fullPath: 'Assets/Data5t/cl', selectedField: this.state.selectedField, selectedSubField: this.state.selectedSubField })
                    //     this.props.onCaseLawAdvanceRulingChange(obj, '2')
                    //     // console.log('skdfdskjf')
                    // } else {
                    
                    // }
                }
            })
        }
    }

    onJudgeChange(value) {
        this.setState({ selectedJudgeCode: value.firstname, selectedJudge: value, isOpenJudgeList: false })
        if (this.state.selectedSubField.includes("Case Law")) {
            if (this.state.selectedField == 'All') {
                // if (this.state.selectedJudgeCode != "") {
                let obj = {
                    type: '-1',
                    value: '-1'
                }
                this.props.onFieldsChange({
                    fullPath: 'Assets/Data5t/cl',
                    selectedField: this.state.selectedField,
                    selectedSubField: this.state.selectedSubField,
                    field: '-1',
                    subfield: '-1'
                })
                // this.props.onCaseLawAdvanceRulingChange(obj, '2')
                this.props.onJudgeChange(obj, value.firstname, '2')
                console.log('skdfdskjf')
                // }
            }

            if (this.state.selectedField == 'Custom') {
                // if (this.state.selectedJudgeCode != "") {
                let obj = {
                    type: '-1',
                    value: '-1'
                }
                this.props.onFieldsChange({
                    fullPath: 'Assets/Data5t/cl',
                    selectedField: this.state.selectedField,
                    selectedSubField: this.state.selectedSubField,
                    field: 'CU',
                    subfield: '-1'
                })
                // this.props.onCaseLawAdvanceRulingChange(obj, '2')
                this.props.onJudgeChange(obj, value.firstname, '2')
                console.log('skdfdskjf')
                // }
            }

            if (this.state.selectedField == 'Service Tax') {
                // if (this.state.selectedJudgeCode != "") {
                let obj = {
                    type: '-1',
                    value: '-1'
                }
                this.props.onFieldsChange({
                    fullPath: 'Assets/Data5t/cl',
                    selectedField: this.state.selectedField,
                    selectedSubField: this.state.selectedSubField,
                    field: 'ST',
                    subfield: '-1'
                })
                // this.props.onCaseLawAdvanceRulingChange(obj, '2')
                this.props.onJudgeChange(obj, value.firstname, '2')
                // }
            }

            if (this.state.selectedField == 'Excise') {
                // if (this.state.selectedJudgeCode != "") {
                let obj = {
                    type: '-1',
                    value: '-1'
                }
                this.props.onFieldsChange({
                    fullPath: 'Assets/Data5t/cl',
                    selectedField: this.state.selectedField,
                    selectedSubField: this.state.selectedSubField,
                    field: 'CE',
                    subfield: '-1'
                })
                // this.props.onCaseLawAdvanceRulingChange(obj, '2')
                this.props.onJudgeChange(obj, value.firstname, '2')
                // }
            }

            if (this.state.selectedField == 'CGST' || this.state.selectedField == 'UGST' || this.state.selectedField == "IGST" || this.state.selectedField == "GST(Compensation to the States) Act") {
                // if (this.state.selectedJudgeCode != "") {
                let obj = {
                    type: '-1',
                    value: '-1'
                }
                this.props.onFieldsChange({
                    fullPath: 'Assets/Data5t/cl',
                    selectedField: this.state.selectedField,
                    selectedSubField: this.state.selectedSubField,
                    field: 'GT',
                    subfield: '-1'
                })
                // this.props.onCaseLawAdvanceRulingChange(obj, '2')
                this.props.onJudgeChange(obj, value.firstname, '2')
                // }
            }
        } else if (this.state.selectedSubField == 'Advance Ruling') {
            if (this.state.selectedField == 'Custom') {
                // if (this.state.selectedJudgeCode != "") {
                let obj = {
                    type: 'CU',
                    value: 'adv'
                }
                this.props.onFieldsChange({
                    fullPath: 'Assets/Data5t/cl',
                    selectedField: this.state.selectedField,
                    selectedSubField: this.state.selectedSubField,
                    field: 'CU',
                    subfield: 'adv'
                })
                // this.props.onCaseLawAdvanceRulingChange(obj, '2')
                this.props.onJudgeChange(obj, value.firstname, '3')
                console.log('skdfdskjf')
                // }
            }

            if (this.state.selectedField == 'CGST' || this.state.selectedField == 'UGST' || this.state.selectedField == "IGST" || this.state.selectedField == "GST(Compensation to the States) Act") {
                // if (this.state.selectedJudgeCode != "") {
                let obj = {
                    type: 'GT',
                    value: 'adv'
                }
                this.props.onFieldsChange({
                    fullPath: 'Assets/Data5t/cl',
                    selectedField: this.state.selectedField,
                    selectedSubField: this.state.selectedSubField,
                    field: 'GT',
                    subfield: 'adv'
                })
                // this.props.onCaseLawAdvanceRulingChange(obj, '2')
                this.props.onJudgeChange(obj, value.firstname, '3')
                // }
            }
        }


        // setTimeout(() => {
        //     this.createFullPath()
        // }, 100);
    }

    render() {
        console.log(this.state.showtargetField)
        return (
            <>
                <div className="inputBoxes">
                    <div className="topBox">
                        {/* <div className="param_leftBox">
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" value={this.state.landmark} onChange={(e) => this.setState({ landmark: e.target.checked })} id="advancephasesearch" />
                                <label className="form-check-label" for="advancephasesearch">
                                    Advance Phase Search
                                </label>
                            </div>
                        </div> */}
                        <div className="search_type">
                            {/* <span>Search Category</span> */}
                            {
                                this.props.searchCategories.map((type) => {
                                    let checked = type.value == this.props.searchType ? true : false
                                    return (
                                        <Tooltip title={`${type.tooltip}`}>
                                            <div className="form-check" style={{display:'inline-block',minHeight:'1.5rem',paddingLeft:'1.5em',marginRight: '10px',marginBottom: '0.125rem'}} key={type.value}>
                                                    <input className="form-check-input" type="radio" name="searchtype" id={`${type.label}`} value={`${type.value}`} defaultChecked={checked} onChange={() => this.props.onRadioChange(type.value)} />
                                                    <label className="form-check-label" htmlFor={`${type.label}`}>
                                                        {type.label}
                                                    </label>
                                                
                                            </div>
                                        </Tooltip>
                                    )
                                })
                            }
                        </div>
                    </div>
                    <div className="bottomBox">
                        <div className="leftBox">
                            <div className="form-group">
                                <label className='customlabel' htmlFor="field">Subject</label>
                                <select className="form-select form-select-sm" onClick={()=>{ this.setState({showtargetField:false})}} onChange={(e)=> this.setState({ selectedField: e.target.value, selectedSubField: this.state.leftBoxFieldsObj.filter((obj) => obj.field == e.target.value)[0].subfield[0].value }, () => this.createFullPath())} value={this.state.selectedField} aria-label=".form-select-sm example">
                                    {
                                        // this.state.showtargetField ?
                                        //     <option  value={this.props.targetField}>{this.props.targetField}</option>
                                        //     :
                                            this.state.leftBoxFieldsObj.map((obj) => {
                                                return (
                                                    <option key={obj.field} value={obj.field}>{obj.field}</option>
                                                )
                                            })
                                    }
                                </select>
                            </div>
                            <div className="form-group">
                                <label className='customlabel' htmlFor="subfield">Field</label>
                                <select className="form-select form-select-sm" onClick={()=>{ this.setState({showtargetSubField:false})}} onChange={ (e) => this.setState({ selectedSubField: e.target.value }, () => this.createFullPath())} value={this.state.selectedSubField} aria-label=".form-select-sm example">
                                    {
                                        // this.state.showtargetSubField ?
                                        // <option  value={this.props.targetSubField}>{this.props.targetSubField}</option>
                                        // :
                                        this.state.leftBoxFieldsObj.filter((obj) => obj.field == this.state.selectedField)[0].subfield.map((obj) => {
                                            return (
                                                <option key={obj.name} value={obj.value}>{obj.name}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                            {/* {
                                this.state.selectedSubField.includes("Circular") || this.state.selectedSubField.includes("Notification") ?
                                    <div className="form-group">
                                        <br></br>
                                        <Input
                                            htmlFor={"Number"}
                                            label={"Number"}
                                            type="text"
                                            inline={true}
                                            labelStyle={{ fontSize: "16px", fontWeight: "600" }}
                                            value={this.state.circNotiNumber}
                                            onChange={(e) => this.setState({ circNotiNumber: e.target.value }, () => this.onCircNotiChange())}
                                        />
                                    </div>
                                    :
                                    null
                            } */}
                            {/* {
                                this.state.selectedSubField.includes("Notification") ?
                                    <div className="form-group">
                                        <br></br>
                                        <Input
                                            htmlFor={"from"}
                                            label={"From"}
                                            type="date"
                                            labelStyle={{ fontSize: "16px", fontWeight: "600" }}
                                            value={this.state.Notifromdate}
                                            onChange={(e) => this.setState({ Notifromdate: e.target.value }, () => this.onCircNotiChange())}
                                        />
                                        <Input
                                            htmlFor={"to"}
                                            label={"To"}
                                            type="date"
                                            labelStyle={{ fontSize: "16px", fontWeight: "600" }}
                                            value={this.state.Notitodate}
                                            onChange={(e) => {
                                                new Date(this.state.Notifromdate) > new Date(e.target.value) ? alert('To date can not be smaller than from date') : this.setState({ Notitodate: e.target.value }, () => this.onCircNotiChange())
                                            }}
                                        />
                                    </div>
                                    :
                                    null
                            } */}
                        </div>
                        {
                            this.state.selectedField == "Case Law" || this.state.selectedField == 'Case Laws' || this.state.selectedField == 'Advance Ruling' ||  this.state.selectedField == 'Case Law & Advance Ruling' ?
                                <div className="rightBox" style={{marginTop:'2%'}}>
                                    {
                                        this.state.isComponentRender ?
                                            <>
                                                {/* <Input
                                                    htmlFor={"order"}
                                                    label={"Order"}
                                                    type="text"
                                                    inline={true}
                                                    labelStyle={{ fontSize: "16px", fontWeight: "600" }}
                                                    value={this.state.order}
                                                    onChange={(e) => this.setState({ order: e.target.value }, () => this.onInputsChange())}
                                                /> */}
                                                {/* <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value={this.state.landmark} onChange={(e) => this.setState({ landmark: e.target.checked }, () => this.onInputsChange())} id="landmarkjudgements" />
                                                    <label className="form-check-label" for="landmarkjudgements">
                                                        Search in landmark judgements
                                                    </label>
                                                </div> */}
                                                {/* <Input
                                                    htmlFor={"Judge"}
                                                    label={"Judge"}
                                                    type="text"
                                                    labelStyle={{ fontSize: "16px", fontWeight: "600" }}
                                                    readOnly={true}
                                                    value={this.state.selectedJudge.firstname}
                                                    onClick={() => this.setState({ isOpenJudgeList: true })}
                                                /> */}
                                                {
                                                    this.state.isOpenJudgeList &&
                                                    <JudgePartylist
                                                        onHide={() => this.setState({ isOpenJudgeList: false })}
                                                        options={this.state.judges}
                                                        searchLabel='Judge'
                                                        optionLabel={'firstname'}
                                                        onChange={value => this.onJudgeChange(value)}
                                                    />
                                                }
                                                {/* <div className="form-group">
                                        <label className='customlabel' htmlFor="judge">Judge</label>
                                        
                                        <ComboBox
                                            value={this.state.selectedJudge}
                                            options={this.state.judges}
                                            onChange={value => this.setState({ selectedJudgeCode: value.firstname, selectedJudge: value }, () => this.props.onJudgeChange(value.firstname))}
                                            getOptionLabel={(option) => option.firstname}
                                            getOptionValue={(option) => option.firstname}
                                            placeholder={"Select Judge"}
                                        />
                                        
                                    </div> */}
                                                {/* <div className="form-group">
                                                    <label className='customlabel' htmlFor="party">Party</label>
                                                    <ComboBox
                                                        value={this.state.selectedParty}
                                                        options={this.state.parties}
                                                        onChange={value => this.setState({ selectedParty: value, selectedPartyCode: value.partycode }, () => this.onInputsChange())}
                                                        getOptionLabel={(option) => option.name}
                                                        getOptionValue={(option) => option.partycode}
                                                        placeholder={"Select Party"}
                                                    />
                                                </div> */}
                                                <Input
                                                    htmlFor={"Party"}
                                                    label={"Party"}
                                                    type="text"
                                                    labelStyle={{ fontSize: "16px", fontWeight: "600" }}
                                                    readOnly={true}
                                                    value={this.state.selectedParty.name}
                                                    onClick={() => this.setState({ isOpenPartyList: true })}
                                                />
                                                {
                                                    this.state.isOpenPartyList &&
                                                    <JudgePartylist
                                                        onHide={() => this.setState({ isOpenPartyList: false })}
                                                        options={this.state.parties}
                                                        searchLabel='Party'
                                                        optionLabel={'name'}
                                                        onChange={value => this.setState({ selectedParty: value, selectedPartyCode: value.partycode, isOpenPartyList: false }, () => this.onInputsChange())}
                                                    />
                                                }
                                                {/* <div className="form-group">
                                                    <label className='customlabel' htmlFor="favour">In Favour Of</label>
                                                    <select className="form-select form-select-sm" value={this.state.selectedFavour} onChange={(e) => this.setState({ selectedFavour: e.target.value }, () => this.onInputsChange())} aria-label=".form-select-sm example">
                                                        <option value="" disabled>Select Favour</option>
                                                        {
                                                            this.state.favours.map((favour, index) => {
                                                                return (
                                                                    <option key={index} value={favour}>{favour}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                </div> */}
                                                <div className="row" style={{ marginTop: "10px" }}>
                                                    <div className="col-sm-3">
                                                        <div className="form-group">
                                                            <label style={{ fontSize: "16px", fontWeight: "600" }} htmlFor="citation">Citation</label>
                                                            <select className="form-select form-select-sm" style={{border:'black',fontSize: '1.05rem'}} value={this.state.selectedCitation} onChange={(e) => this.setState({ selectedCitation: e.target.value }, this.citationplaceholderchange(e), () => this.onInputsChange())} aria-label=".form-select-sm example">
                                                                <option value="">Select</option>
                                                                {
                                                                    this.state.Citation.map((citation, index) => {
                                                                        return (
                                                                            <option value={citation} key={index}>{citation}</option>
                                                                        )
                                                                    })
                                                                }
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-9">
                                                        <Input
                                                            htmlFor={"citationcode"}
                                                            label={"Citation Code"}
                                                            type="text"
                                                            labelStyle={{ fontSize: "16px", fontWeight: "600" }}
                                                            value={this.state.citationCode}
                                                            onChange={(e) => this.setState({ citationCode: e.target.value }, () => this.onInputsChange())}
                                                            placeholder={this.state.citationplaceholder}
                                                            disabled = {this.state.selectedCitation == ''? true:false}
                                                        />
                                                    </div>
                                                </div>
                                                {/* <div className="row" style={{ marginTop: "10px" }}>
                                                    <div className="col-sm-6">
                                                        <Input
                                                            htmlFor={"from"}
                                                            label={"From"}
                                                            type="date"
                                                            labelStyle={{ fontSize: "16px", fontWeight: "600" }}
                                                            value={this.state.fromdate}
                                                            onChange={(e) => this.setState({ fromdate: e.target.value }, () => this.onInputsChange())}
                                                        />
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <Input
                                                            htmlFor={"to"}
                                                            label={"To"}
                                                            type="date"
                                                            labelStyle={{ fontSize: "16px", fontWeight: "600" }}
                                                            value={this.state.todate}
                                                            onChange={(e) => this.setState({ todate: e.target.value }, () => this.onInputsChange())}
                                                        />
                                                    </div>
                                                </div> */}
                                                {/* <div className="row" style={{ marginTop: "10px" }}>
                                                    {
                                                        Object.keys(this.state.courts).map((key, index) => {
                                                            return (
                                                                <div className="col-sm-4" key={index}>
                                                                    <div className="form-check">
                                                                        <input className="form-check-input" type="checkbox" value={this.state.courts[key].checked} checked={this.state.courts[key].checked} onChange={(e) => this.storeCheckBoxes(key, e)} id={key} />
                                                                        <label className="form-check-label" for={key}>
                                                                            {key}
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                                {
                                                    this.state.subcourts.length > 0 &&
                                                    <div className="row allsubcheckboxes">
                                                        {
                                                            this.state.subcourts.map((item, index) => {
                                                                return (
                                                                    <div className="col-sm-4" key={index}>
                                                                        <div className="form-check" style={{ fontSize: "12px" }}>
                                                                            <input className="form-check-input" type="checkbox" value={item.courtcode} checked={item.checked} onChange={(e) => this.storeFinalCourts(item, index, e)} id={item.courtcode} />
                                                                            <label className="form-check-label" for={item.courtcode}>
                                                                                {item.name}
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                } */}
                                            </>
                                            :
                                            <Spinner animation="border" variant="dark" />
                                    }
                                </div>
                                :
                                null
                        }
                    </div>
                </div>
            </>
        )
    }
}
