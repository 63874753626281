import React, { Component } from 'react'
import { Modal, Spinner } from 'react-bootstrap'
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import './bookmarklist.css'
import Hexapi from '../../usefulcomps/HexAPI/Hexapi'
import TimeFormatter from '../../usefulcomps/TimeFormatter';
import Swal from 'sweetalert2'
import { confirmAlert } from 'react-confirm-alert'


export default class BookmarkKeeping extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isOptionModalOpen: false,
            isComponentRender: false,
            bookmarkList: [], isBookmarkFound: false, filesList: [], datesList: [], selectedDateRecid: ''
        }
        this.getDate = this.getDate.bind(this)
    }
    onSearch(e) {
        console.log(this.state.hsnsearch)
    }
    getDate(logtime) {
        if (logtime != null && logtime.date != undefined) {
            let dateObj = TimeFormatter(logtime.date)
            return `${dateObj.userSplitedDate[3]}, ${dateObj.userSplitedDate[2]} ${dateObj.ShiftMonth} ${dateObj.userSplitedDate[0]}`
        }
    }
    componentDidMount() {
        this.setState({ isComponentRender: false, isBookmarkFound: false })
        let obj = {
            query: `[dbo].[Lawcrux_Web_Proc_Bookmark_GetList] @UserID='{0}'`,
            queryArr: [`${this.props.userid}`]
        }
        // console.log(obj);
        Hexapi(obj).then(resp => {
            console.log(resp);
            let data = resp['']
            const arr1 = [...new Map(data.map(item => [this.getDate(item.logdate), item])).values()]
            // console.log(arr1)

            if (resp[''].length > 0) {
                this.setState({ bookmarkList: resp[''], datesList: arr1, isBookmarkFound: true })
            } else {
                this.setState({ isBookmarkFound: false })
            }
        }).then(() => this.setState({ isComponentRender: true })).catch(err => console.error(err))
    }

    onClearHistory() {
        confirmAlert({
            message: 'Are you sure you want to clear Bookmark history? (This action cannot be revert) ',
            buttons: [
                {
                    label: 'No',
                    onClick: () => { }
                },
                {
                    label: 'Yes',
                    onClick: () => {
                        this.setState({ isComponentRender: false, isBookmarkFound: false })
                        let obj = {
                            query: `[dbo].[Lawcrux_Web_Proc_ClearBookmark_Submit] @UserID='{0}'`,
                            queryArr: [`${this.props.userid}`]
                        }
                        Hexapi(obj).then(resp => {
                            console.log(resp)
                            Swal.fire({
                                confirmButtonColor: '#28b6f6',
                                html: resp[''][0].msg,
                            }).then(() => {
                                if (resp[''][0].command == 1) {
                                    // this.setState({ isFormOpen: false }, () => this.fetchEmpList(this.state.userid))
                                }
                            })
                        }).then(() => this.setState({ isComponentRender: true })).catch(err => console.error(err))
                    }
                }
            ],
            overlayClassName: "customOverlay"
        })

    }

    // setFileList(list) {
    //     let files = this.state.bookmarkList.filter((el) => this.getDate(el.logdate) == this.getDate(list.logdate))
    //     console.log(list.recid)
    //     this.setState({ filesList: files, selectedDateRecid: list.recid })
    // }
    render() {
        return (
            <>
                {
                    <Modal id={'search_options_modal'} size="lg" centered style={{ backgroundColor: 'rgba(0,0,0,0.6)' }} show={true} onHide={() => this.props.onCloseModal()} keyboard={false} backdrop='static'>
                        <Modal.Header>
                            <div className="header">
                                <span>All Bookmarks</span>
                                <IconButton onClick={() => this.props.onCloseModal()}>
                                    <CloseIcon />
                                </IconButton>
                            </div>
                        </Modal.Header>
                        <Modal.Body style={{ backgroundColor: "white", paddingTop: "0px" }}>
                            {
                                this.state.isComponentRender ?
                                    this.state.isBookmarkFound ?
                                        <div className="bookmark_main_box">
                                            {/* <div className="datedBox">
                                                <div className="top">
                                                    <span>Dated</span>
                                                </div>
                                                <div className="listBox">
                                                    <span>Record Date</span>
                                                    {
                                                        this.state.datesList.map((list, index) => {
                                                            return (
                                                                <span style={{ backgroundColor: this.state.selectedDateRecid == list.recid ? " rgba(0, 0, 0, 0.08)" : "" }} onClick={() => this.setFileList(list)}>{this.getDate(list.logdate)}</span>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div> */}
                                            <div className="descBox">
                                                <div className="top">
                                                    <span></span>
                                                    <span onClick={() => this.onClearHistory()}>Clear Bookmark</span>
                                                </div>
                                                <div className="listBox">
                                                    <span>Description</span>
                                                    {
                                                        this.state.bookmarkList.map((list, index) => {
                                                            let des = list.description.replaceAll('�',' ')
                                                            return (
                                                                <span onClick={() => this.props.openHistoryFile(list)}>{des}</span>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: '100%', minHeight: "50vh" }}>
                                            <h5>No record</h5>
                                        </div>
                                    :
                                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: '100%', minHeight: "50vh" }}>
                                        <Spinner animation="border" variant="dark" />
                                    </div>
                            }
                        </Modal.Body>
                    </Modal>
                }
            </>
        )
    }
}
