import React, { Component } from 'react'
import Table from 'react-bootstrap/Table'
import './TableStyle.css'
// import './Newticketlist.css'
import { Spinner } from 'react-bootstrap'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import Tooltip from '@mui/material/Tooltip';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import PaginationBar from './PaginationBar'
import ReactVisibilitySensor from 'react-visibility-sensor';
import Hexapi from '../HexAPI/Hexapi';
import HexapiCL from '../HexAPI/HexapiCL';
import { Modal } from 'react-bootstrap'
import HomeIcon from '@mui/icons-material/Home';
import Thousandthree from '../../Checklist';
import Thousandtwo from "../../Thousandtwo";
import TextFormatIcon from '@mui/icons-material/TextFormat';
import ContentFrame from '../../components/subcomps/ContentFrame';
import BookmarksIcon from '@mui/icons-material/Bookmarks';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { Callbacks } from 'jquery';
import BookmarkBorderRoundedIcon from '@mui/icons-material/BookmarkBorderRounded';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import Searched from '../../components/subcomps/Searched';
import Dictionary from '../../components/subcomps/Dictionary';
import FindInPageSharpIcon from '@mui/icons-material/FindInPageSharp';
import { LensTwoTone } from '@material-ui/icons';
import FiberManualRecordOutlinedIcon from '@mui/icons-material/FiberManualRecordOutlined';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import moment from 'moment';


// import TimeFormatter from '../../../../usefulcomps/TimeFormatter';

var oldData = []
export default class AdvanceRulingDatatable extends Component {
    constructor() {
        super()
        this.searchInputRef = React.createRef();

        this.state = {
            isListFound: false, search: '', isLastList: false, titlesLength: 0, datatab: [],
            typing: false, currentOffset: 0, visible: false, isNavbar: true, endIndex: 100,
            typingTimeout: 0, totalData: [], newtable: [], pageIndex: 0, PAGE_SIZE: 100, heading: "Advancing Rules",
            rowData: [], isDataFound: false, currentPaginationPage: 0, showPaginationRows: 0, visible: false,
            isShowSearchBox: true, searchValue: '', OpencustomFrame: false, targetfile: '', mainfolder: "", subfolder: "",
            selectedColumns: ['lcx', 'name', 'Icons'], petitionrowdata: "", clickedRowIndex: -1, filtercount: "",
            visible: false, maxres: 1000, lastcode: 0, code: null, subject: false, untickedvalues: [], filternamecounts: [],
            field: false, searched: '', year: false, all: false, counts: 0, subject1: false, val: '', pagerefresh: false,
            filterData: [], filtername: '', isfilterset: false, isloadcomplete: false, defaultValue: [], petitiondata: false,
            userid: sessionStorage.getItem('userid'), nullhandle: false, allcheckedvalues: "-1", filterloading: false,
            changebookmark: false, thousanddata: [], activeElement: '', showoneliner: false, onelinertext: '',
            isOpenSearchModal: false, isOpenDictModal: false, isReferedByHsn: false, hsnSearchWord: '', hsnPath: '', showloading: false,
            isReferedByHistory: false, historyFileData: [], bookmarkindex: -1, petitionexpanddata: false, showrowcontent: false,
            itemclicked1: false, itemclicked2: false, itemclicked3: false, itemclicked4: false, showDemo: false, showinternaldemo: false,TotalCount:"",bookmarkindex:[],
            ShowPageCount:false,pageCount:0,Filtervalue:"-1",startdemo:false,
        }
    }

    handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            this.storeSearch();
        } else if (event.key === 'Backspace' && this.state.searchValue.length == 1) {
            this.clearSearch();
        }else if (event.ctrlKey && (event.key === "M" || event.key === "m")) {
            event.preventDefault();
            this.Pagegetdata();
        }
    };
    Pagegetdata=()=>{

        var searchvalues;
        if (this.state.searchValue == "") {
            searchvalues = -1;
        } else {
            searchvalues = this.state.searchValue;
        }


        let filtercol = "";
        // let clname = "";
        console.log(this.props.deffilter)

        if (this.props.deffilter != "" && this.props.deffilter != undefined && this.props.deffilter != null) {
            filtercol = this.props.deffilter;
            // clname = this.props.clname;
            // console.log(clname)
        } else {
            filtercol = -1;
        }

        if(this.state.Filtervalue == "-1"){
            filtercol = -1;
        }else{
            filtercol=this.state.Filtervalue;
        }

        let allcodeno = this.props.codeno
        if(this.props.homedirect == true){
            let obj = {
                'query': `[dbo].[Lawcrux_Web_Proc_InteractiveIndexAdvCl_GetCountV2]@LastCode ='{0}', @Field ='{1}',@Filter ='{2}', @UserID ='{3}', @Search='{4}' `,// @RecID='{0}'
                'queryArr': ['-1', `${this.props.field}`, `${filtercol}`, `${this.state.userid}`, `${searchvalues}`]
            }
            Hexapi(obj).then(resp => { 
                console.log(resp);
                this.setState({ShowPageCount:true,pageCount: resp["total"][0].count});
            });
        }else{
            let obj = {
                'query': `[dbo].[Lawcrux_Web_Proc_InteractiveIndexAdvCl_SearchGetCountV2]@LastCode ='{0}', @Field ='{1}',@Filter ='{2}', @UserID ='{3}', @Search='{4}',@Parametericsearch='{5}' `,// @RecID='{0}'
                'queryArr': ['-1', `${this.props.field}`, `${filtercol}`, `${this.state.userid}`,`${searchvalues}`, `${allcodeno}`]
            }
            Hexapi(obj).then(resp => { 
                console.log(resp);
                this.setState({ShowPageCount:true,pageCount: resp["total"][0].count});
            });
        }
    }
    componentWillUnmount() {
        document.removeEventListener('keydown', this.handleKeyDown);
    }

    componentDidMount() {


        let obj2 = {
            'query': `[dbo].[Lawcrux_Web_Proc_Homescreen_GetData] @UserID='{0}' `,// @RecID='{0}'
            'queryArr': [`${sessionStorage.getItem('userid')}`]
        }
        Hexapi(obj2).then(resp => {
            console.log(resp);

            if (resp["illustration"][0].illustration3 == 1 && this.props.field == "cl"&& this.props.showillustration==true) {
                this.setState({ showDemo: true, showinternaldemo: true })
            } else {
                this.setState({ showDemo: false, showinternaldemo: false })
            }
        })

        document.addEventListener('keydown', this.handleKeyDown);

        // var allcodeno;
        let filtercol = "";
        // let clname = "";
        console.log(this.props.deffilter)

        if (this.props.deffilter != "" && this.props.deffilter != undefined && this.props.deffilter != null) {
            filtercol = this.props.deffilter;
            this.setState({Filtervalue: filtercol});
            // clname = this.props.clname;
            // console.log(clname)
        } else {
            filtercol = -1;
        }
        let obj1;

        if (this.props.codeno == undefined || this.props.codeno == "" || this.props.codeno == null) {
            obj1 = {
                'query': `[dbo].[Lawcrux_Web_Proc_InteractiveIndexAdvCl_GetDataV2]@lastCode='{0}' ,@field='{1}', @filter='{2}' , @userid='{3}', @search='{4}'`,
                'queryArr': ['-1', `${this.props.field}`, `${filtercol}`, `${this.state.userid}`, '-1']
            }

        } else {
            let allcodeno = this.props.codeno
            obj1 = {
                'query': `[dbo].[Lawcrux_Web_Proc_InteractiveIndexAdvCl_SearchGetDataV2]@lastCode='{0}' ,@field='{1}', @filter='{2}' , @userid='{3}', @search='{4}',@Parametericsearch='{5}'`,
                'queryArr': ['-1', `${this.props.field}`, `${filtercol}`, `${this.state.userid}`, '-1', `${allcodeno}`]
            }
        }
        console.log(obj1)
        Hexapi(obj1).then(resp => {
            console.log(resp);
            console.log(resp[""]);
            if (resp[""] != undefined && resp[""] != null && resp[""].length > 0) {
                const updatedData = resp[""].map((item) => ({
                    lcx: item.lcx,
                    name: item.name,
                    Icons: item.Icons,
                    ...item,
                }));
                console.log(updatedData);
                const codes =[];
                updatedData.map((data)=>{
                    if(data.isbookmarked == 1){
                        codes.push(data.code);
                    }
                })
                // console.warn(codes)
                this.setState({bookmarkindex:codes})
                // let keys = Object.keys(resp[''][0])
                // setData(resp[""]);
                this.setState({ datatab: updatedData })
                this.setState({ rowData: updatedData, isDataFound: true })
                if(resp["total"] != undefined){
                    this.setState({TotalCount:resp["total"][0]["count"]})
                }
                oldData = updatedData;
                // oldData = resp[""];
                this.setState({ newtable: resp[""] });
                let cd = resp[""][resp[""].length - 1].code;
                this.setState({ lastcode: cd })
                console.log(cd);

            } else {
                this.setState({ nullhandle: true })
            }

            let Filters;
            var obj = { values: {}, def: {}, oldvalues: {} }
            if (this.props.clname != "" && this.props.clname != undefined && this.props.clname != null) {
                let obj = {
                    'query': `[dbo].[Lawcrux_Web_Proc_InteractiveIndexAdvCl_GetFilter]@field='{0}'`,
                    'queryArr': [`${this.props.clname}`]
                }
                Hexapi(obj).then(res => {
                    console.log(res["filters"]);
                    Filters = res["filters"];
                    console.log(Filters.length)
                })
            }
            else {
                Filters = resp["filters"];
                console.log(Filters)
            }
            setTimeout(() => {
                console.log(Filters)
                // console.log(Filters.length)
                this.setState({ originalfilterData: Filters, filterData: Filters })
                for (var i = 0; i < Filters.length; i++) {
                    obj.def[Filters[i].filtername] = Filters[i].defval
                    obj.values[Filters[i].filtername] = Filters[i].values
                    obj.oldvalues[Filters[i].filtername] = Filters[i].defval
                }
                console.log(obj)
                this.state.defaultValue.push(obj);
                console.log(this.state.defaultValue)

            }, 1000);
            
        }).then(()=>{
            let obj2 = {
                'query': `[dbo].[Lawcrux_Web_Proc_Homescreen_GetData] @UserID='{0}' `,// @RecID='{0}'
                'queryArr': [`${sessionStorage.getItem('userid')}`]
            }
            Hexapi(obj2).then(resp => {
                console.log(resp);
    
                if (resp["illustration"][0].illustration3 == 1 && this.props.field == "cl"&& this.props.showillustration==true) {
                    this.setState({startdemo: true })
                } else {
                    this.setState({ startdemo:false })
                }
            })
        })
    }


    getHeader(data) {
        return Object.keys(data[0]).filter(column => this.state.selectedColumns.includes(column)).map((column, index) => {
            // return Object.keys(data[0]).map((column, index) => {
            if (column == "name") {
                column = "Party Name"
            } else if (column == "lcx") {
                column = "citation";
            }
            const columnstyle = (col) => {
                console.log(col);
                if (col == "Icons") {
                    return { minWidth: '150px', maxWidth: '150px' }
                }
            }

            let columnname = column.toUpperCase();
            // console.log(columnname)
            return (
                <th key={index} style={columnstyle(column)}>{columnname}</th>
            )
        })
    }

    clearSearch(e) {
        var filtervalue;
        if (this.state.allcheckedvalues != "-1") {
            filtervalue = this.state.allcheckedvalues;
        } else {
            filtervalue = -1;
        }
        this.setState({ isDataFound: false })
        let obj1;
        if (this.props.codeno == undefined || this.props.codeno == "" || this.props.codeno == null) {
            obj1 = {
                'query': `[dbo].[Lawcrux_Web_Proc_InteractiveIndexAdvCl_GetDataV2]@lastCode='{0}' ,@field='{1}', @filter='{2}' , @userid='{3}', @search='{4}'`,
                'queryArr': ['-1', `${this.props.field}`, `${filtervalue}`, `${this.state.userid}`, '-1']
            }

        } else {
            let allcodeno = this.props.codeno
            obj1 = {
                'query': `[dbo].[Lawcrux_Web_Proc_InteractiveIndexAdvCl_SearchGetDataV2]@lastCode='{0}' ,@field='{1}', @filter='{2}' , @userid='{3}', @search='{4}',@Parametericsearch='{5}'`,
                'queryArr': ['-1', `${this.props.field}`, `${filtervalue}`, `${this.state.userid}`, '-1', `${allcodeno}`]
            }
        }
        console.log(obj1)

        // this.setState({ filterloading: true })
        this.setState({ isDataFound: false })
        Hexapi(obj1).then(resp => {
            console.log(resp[""]);
            if (resp[""].length <= 1) {
                this.setState({ nullhandle: true })
            }
            else {
                // const columns = resp[""]
                const updatedData = resp[""].map((item) => ({
                    lcx: item.lcx,
                    name: item.name,
                    Icons: item.Icons,
                    ...item,
                }));
                this.setState({ rowData: updatedData })
                this.setState({ isDataFound: true })
                if(resp["total"] != undefined){
                    this.setState({TotalCount:resp["total"][0]["count"]})
                }
            }
        });
        this.setState({ searchValue: '' })
    }

    setDate(date) {
        // if (date != undefined) {
        //     // return date.split(' ')[0]
        //     console.log(date)
        //     var transformdate = date.split(".")[0];
        //     console.log(transformdate)
        //     //   console.log(date);
        //     var exitdate = TimeFormatter(transformdate);
        //     console.log(exitdate);
        //     var visitorinfo = this.state.visitorinfo
        //     //   console.log(visitorinfo)
        //     // console.log(this.state.visitorinfo)
        //     var newReverseDate =
        //         exitdate.ShiftTime +
        //         "," +
        //         exitdate.userSplitedDate[2] +
        //         "-" +
        //         exitdate.ShiftMonth +
        //         "-" +
        //         exitdate.CurYear.toString().slice(-2);
        //     // console.log(newReverseDate);

        //     return newReverseDate;
        // }
    }


    applyFilters() {
        // let allcheckedvalues;
        let count = 0;
        // console.log(filterData.length)
        // this.state.defaultValue[0].def.map((val, index) => {
        //     let data1 = this.state.defaultValue[0].def[val].split(',').sort();
        //     let data2 = this.state.defaultValue[0].values.[val].split(',').sort();
        //     if (JSON.stringify(data1) != JSON.stringify(data2)) {
        //         count++;
        //     }
        // })

        for (let filter2 in this.state.defaultValue[0].def) {
            // console.log(filter);
            let data1 = this.state.defaultValue[0].def[filter2].split(',').sort();
            console.log(this.state.defaultValue[0].def[filter2]);
            console.log(this.state.defaultValue[0].values[filter2])
            let data2 = this.state.defaultValue[0].oldvalues[filter2].split(',').sort();
            if (JSON.stringify(data1) != JSON.stringify(data2)) {
                count++;
            }
        }

        if (count <= 0) {
            this.setState({ isfilterset: false })
        } else {
            this.setState({ filtercount: count })
            this.setState({ isfilterset: true })

        }

        if (this.props.field == 'adv') {

            // Filter for Advanced Ruling
            var filterStr = ''
            let filterStrFirst = '';
            let courts = [];
            let states = [];
            if (Object.keys(this.state.defaultValue[0].def).length != 0) {
                this.setState({ isOpenModal: false })
                var keys = Object.keys(this.state.defaultValue[0].def)
                for (var i = 0; i < keys.length; i++) {
                    if (i < keys.length - 1) {
                        // FilterStr contains the filtered string for year and TBD after the loop
                        if (keys[i] == "Year") {
                            const value = this.state.defaultValue[0].def["Year"].split(",");
                            let allyear = "";
                            for (let i = 0; i <= value.length; i++) {
                                if (value[i] != undefined) {
                                    let yeardata = value[i].split("-");
                                    const to = yeardata[0];
                                    const from = yeardata[1];
                                    for (let i = to; i <= from; i++) {
                                        allyear += i + ",";
                                    }
                                }
                            }
                            // console.log(allyear.split(0,allyear.length-1) )
                            console.log(allyear.slice(0, allyear.length - 1));
                            filterStr += allyear.slice(0, allyear.length - 1) + '$#12$$$';
                        } else if (keys[i] == "Authority") {
                            console.log(this.state.defaultValue[0].def[keys[i]].split(','));
                            courts = this.state.defaultValue[0].def[keys[i]].split(',').sort();
                            // filterStr += this.state.defaultValue[0].def[keys[i]] + '$#12$$$'    
                        } else if (keys[i] == "State") {
                            states = this.state.defaultValue[0].def[keys[i]].split(',').sort();
                        } else if (keys[i] == "Subject") {
                            filterStrFirst += this.state.defaultValue[0].def[keys[i]] + ','
                        }
                        // filterStr += this.state.defaultValue[0].def[keys[i]] + '$#12$$$'
                    } else {
                        filterStr += this.state.defaultValue[0].def[keys[i]]
                    }
                }
            }

            if (courts.indexOf('') != -1) {
                courts.shift();
            }

            if (states.indexOf('') != -1) {
                states.shift();
            }
            // console.log("Courts: ", courts);
            // console.log("States: ", states);
            let filterStrCourts = '';
            // FilterStrCourts contains the filtered string for courts and states
            for (let i = 0; i < courts.length; i++) {
                for (let j = 0; j < states.length; j++) {
                    if ((i == (courts.length - 1)) && (j == (states.length - 1))) {
                        filterStrCourts += courts[i] + '-' + states[j] + '$#12$$$';
                    } else {
                        filterStrCourts += courts[i] + '-' + states[j] + ',';
                    }
                }
            }
            filterStr = filterStrFirst + "$#12$$$" + filterStrCourts + filterStr;
            // FilterStr is the final filtered string with courts-states, year and TBD
            console.log("FilterString:" + filterStr);
            this.state.allcheckedvalues = filterStr;
        } else {
            // Filter for Case Laws
            let filterStr = '';
            let filterStrFirst = '';
            let filterStrLast = '';
            let courts = [];
            let states = [];
            if (Object.keys(this.state.defaultValue[0].def).length != 0) {
                this.setState({ isOpenModal: false })
                var keys = Object.keys(this.state.defaultValue[0].def)
                console.log(keys);
                for (var i = 0; i < keys.length; i++) {
                    if (i < keys.length - 1) {
                        // FilterStr contains the filtered string for year and TBD after the loop
                        if (keys[i] == "Year") {
                            const value = this.state.defaultValue[0].def["Year"].split(",");
                            let allyear = "";
                            for (let i = 0; i <= value.length; i++) {
                                if (value[i] != undefined) {
                                    let yeardata = value[i].split("-");
                                    const to = yeardata[0];
                                    const from = yeardata[1];
                                    for (let i = to; i <= from; i++) {
                                        allyear += i + ",";
                                    }
                                }
                            }
                            // console.log(allyear.split(0,allyear.length-1) )
                            console.log(allyear.slice(0, allyear.length - 1));
                            filterStrLast += allyear.slice(0, allyear.length - 1) + '$#12$$$';
                        } else if (keys[i] == "Courts") {
                            console.log(this.state.defaultValue[0].def[keys[i]].split(','));
                            courts = this.state.defaultValue[0].def[keys[i]].split(',').sort();
                            // filterStr += this.state.defaultValue[0].def[keys[i]] + '$#12$$$'    
                        } else if (keys[i] == "State") {
                            states = this.state.defaultValue[0].def[keys[i]].split(',').sort();
                        } else if (keys[i] == "Subject" || keys[i] == "Favour") {
                            filterStrFirst += this.state.defaultValue[0].def[keys[i]] + '$#12$$$';
                        }
                        // filterStr += this.state.defaultValue[0].def[keys[i]] + '$#12$$$'
                    } else {
                        filterStrLast += this.state.defaultValue[0].def[keys[i]];
                    }
                }
            }

            if (courts.indexOf('') != -1) {
                courts.shift();
            }

            if (states.indexOf('') != -1) {
                states.shift();
            }
            // console.log("Courts: ", courts);
            // console.log("States: ", states);
            let filterStrCourts = '';
            // FilterStrCourts contains the filtered string for courts and states
            for (let i = 0; i < courts.length; i++) {
                // If the courts are Supreme Court or NAPA, just add it to the filterStrFirst string for ease of use
                if (courts[i] == "Supreme Court of India" || courts[i] == "National Antiprofiteering Authority") {
                    filterStrFirst += courts[i] + ',';
                    continue;
                }
                for (let j = 0; j < states.length; j++) {
                    // if ((i == (courts.length - 1)) && (j == (states.length - 1))) {
                    //     filterStrCourts += states[j] + ' ' + courts[i] + '$#12$$$';
                    // } else {
                    //     filterStrCourts += states[j] + ' ' + courts[i] + ',';
                    // }
                    filterStrCourts += states[j] + ' ' + courts[i] + ',';
                }
            }
            filterStr = filterStrFirst + filterStrCourts + '$#12$$$' + filterStrLast;
            // FilterStr is the final filtered string with courts-states, year and TBD
            console.log("FilterString:" + filterStr);
            this.state.allcheckedvalues = filterStr;
        }


        // console.log(this.state.filternamecounts.length)

        var searchvalues;
        if (this.state.searchValue == "") {
            searchvalues = -1;
        } else {
            searchvalues = this.state.searchValue;
        }

        var filtervalue;
        if (this.state.allcheckedvalues != "-1") {
            filtervalue = this.state.allcheckedvalues;
        } else {
            filtervalue = -1;
        }

        // console.log(this.state.allcheckedvalues);
        // console.log(filtervalue);
        this.setState({Filtervalue: filtervalue});
        let obj1;
        if (this.props.codeno == undefined || this.props.codeno == "" || this.props.codeno == null) {
            obj1 = {
                'query': `[dbo].[Lawcrux_Web_Proc_InteractiveIndexAdvCl_GetDataV2]@lastCode='{0}' ,@field='{1}', @filter='{2}' , @userid='{3}', @search='{4}'`,
                'queryArr': ['-1', `${this.props.field}`, `${filtervalue}`, `${this.state.userid}`, `${searchvalues}`]
            }

        } else {
            let allcodeno = this.props.codeno
            obj1 = {
                'query': `[dbo].[Lawcrux_Web_Proc_InteractiveIndexAdvCl_SearchGetDataV2]@lastCode='{0}' ,@field='{1}', @filter='{2}' , @userid='{3}', @search='{4}',@Parametericsearch='{5}'`,
                'queryArr': ['-1', `${this.props.field}`, `${filtervalue}`, `${this.state.userid}`, `${searchvalues}`, `${allcodeno}`]
            }
        }
        console.log(obj1)

        // this.setState({ filterloading: true })
        this.setState({ isDataFound: false })
        Hexapi(obj1).then(resp => {
            console.log(resp[""]);
            if (resp[""].length <= 1) {
                this.setState({ nullhandle: true })
                if(resp["total"] != undefined){
                    this.setState({TotalCount:resp["total"][0]["count"]})
                }

            }
            else {
                const updatedData = resp[""].map((item) => ({
                    lcx: item.lcx,
                    name: item.name,
                    Icons: item.Icons,
                    ...item,
                }));
                // const columns = resp[""]
                this.setState({ nullhandle: false })
                // this.setState({ filterloading: false })
                this.setState({ rowData: updatedData })
                this.setState({ isDataFound: true })
                if(resp["total"] != undefined){
                    this.setState({TotalCount:resp["total"][0]["count"]})
                }
            }
        });

        console.log(this.state.allcheckedvalues)
        // console.log(applyFilter)
        console.log(this.state.untickedvalues);
        // this.setState({ isShowSearchBox: true })
        this.setState({ isOpenModal: false })
    }



    onRowClick(rowData) {
        this.setState({ isDataFound: false })
        console.log(rowData);

        let file = '';

        if (rowData.courtlevel == "1") {
            file = `cl/clsupr/${rowData.field}/${rowData.year}/${rowData.code}.htm`
        } else if (rowData.courtlevel == "2") {
            file = `cl/clhigh/${rowData.field}/${rowData.year}/${rowData.code}.htm`
        }
        else if (rowData.courtlevel == "3") {
            if (rowData.courtname.toLowerCase() == 'bangalore tribunal') {
                file = `cl/cltban/${rowData.field}/${rowData.year}/${rowData.code}.htm`
            } else if (rowData.courtname.toLowerCase() == 'chennai tribunal') {
                file = `cl/cltche/${rowData.field}/${rowData.year}/${rowData.code}.htm`
            } else if (rowData.courtname.toLowerCase() == 'delhi tribunal') {
                file = `cl/cltdel/${rowData.field}/${rowData.year}/${rowData.code}.htm`
            } else if (rowData.courtname.toLowerCase() == 'kolkata tribunal') {
                file = `cl/cltkol/${rowData.field}/${rowData.year}/${rowData.code}.htm`
            } else if (rowData.courtname.toLowerCase() == 'larger bench') {
                file = `cl/cltlar/${rowData.field}/${rowData.year}/${rowData.code}.htm`
            } else if (rowData.courtname.toLowerCase() == 'mumbai tribunal') {
                file = `cl/cltmum/${rowData.field}/${rowData.year}/${rowData.code}.htm`
            } else if (rowData.courtname.toLowerCase() == 'tribunal') {
                file = `cl/cltrib/${rowData.field}/${rowData.year}/${rowData.code}.htm`
            } else if (rowData.courtname.toLowerCase() == 'central administrative tribunal') {
                file = `cl/cltrib/${rowData.field}/${rowData.year}/${rowData.code}.htm`
            } else if (rowData.courtname.toLowerCase() == 'hyderabad tribunal') {
                file = `cl/clthyd/${rowData.field}/${rowData.year}/${rowData.code}.htm`
            } else if (rowData.courtname.toLowerCase() == 'ahmedabad tribunal') {
                file = `cl/cltahm/${rowData.field}/${rowData.year}/${rowData.code}.htm`
            } else if (rowData.courtname.toLowerCase() == 'chandigarh tribunal') {
                file = `cl/cltchd/${rowData.field}/${rowData.year}/${rowData.code}.htm`
            } else if (rowData.courtname.toLowerCase() == 'allahabad tribunal') {
                file = `cl/cltald/${rowData.field}/${rowData.year}/${rowData.code}.htm`
            } else {
                file = `cl/cltrib/${rowData.field}/${rowData.year}/${rowData.code}.htm`
            }
        }
        else if (rowData.courtlevel == "5") {
            // courttext = "NAA"
            file = `cl/clnapa/${rowData.field}/${rowData.year}/${rowData.code}.htm`
        }
        else if (rowData.courtlevel == "6") {
            // courttext = "AAR"
            if(rowData.field.toLowerCase()=='cu'){
                file = `cl/cuaarc/${rowData.field}/${rowData.year}/${rowData.code}.htm`
            }else{
                file = `cl/claarc/${rowData.field}/${rowData.year}/${rowData.code}.htm`
            }   
        }
        else if (rowData.courtlevel == "7") {
            // courttext = "AAAR"   
            file = `cl/claaar/${rowData.field}/${rowData.year}/${rowData.code}.htm`
        } else if (rowData.courtlevel == "4") {
            // courttext = "O"
        }
        let mainf = file.split("/");

        let path = "Assets/Data5t/" + file;

        this.setState({ targetfile: path, mainfolder: mainf[0], subfolder: mainf[1] })
        this.setState({ OpencustomFrame: true });

        console.log(file);
        let obj1 = {
            "query": `[dbo].[Lawcrux_Web_Proc_FileRead_Submit]@UserID ='{0}', @Code='{1}'`,
            "queryArr": [`${sessionStorage.getItem('userid')}`, `${rowData.code}`]
        }
        Hexapi(obj1).then((res) => {
            console.log(res);
        })
        this.setState({ showloading: false })
        this.setState({ isDataFound: true })
        // let obj = {
        //     filecode: `${file}`,
        //     folderpath: `${this.props.folderpath}`
        // }
        // HexapiCL(obj).then(resp => {
        //     console.log(resp);
        //     let data = resp;
        //     let folder = data.split("\\")

        //     // console.log(folder[0], "----",folder[1],"----",folder[2])
        //     console.log(folder);
        //     let main = folder[0].split("/");
        //     console.log(main[main.length - 1])
        //     this.setState({ mainfolder: main[main.length - 1] })
        //     this.setState({ subfolder: folder[1] })
        //     if (folder[2].includes(".htm")) {
        //         this.setState({ targetfile: "Assets/Data5t/" + main[main.length - 1] + "/" + folder[1] + "/" + folder[2] })
        //     } else if (folder[3].includes(".htm")) {
        //         this.setState({ targetfile: "Assets/Data5t/" + main[main.length - 1] + "/" + folder[1] + "/" + folder[2] + "/" + folder[3] })
        //     }
        //     else if (folder[4].includes(".htm")) {
        //         this.setState({ targetfile: "Assets/Data5t/" + main[main.length - 1] + "/" + folder[1] + "/" + folder[2] + "/" + folder[3] + "/" + folder[4] })
        //     }
        //     else {
        //         this.setState({ targetfile: "Assets/Data5t/" + main[main.length - 1] + "/" + folder[1] + "/" + folder[2] + "/" + folder[3] + "/" + folder[4] + "/" + folder[5] })
        //     }

        //     // this.setState({ targetfile: "Assets/Data5t/" + main[main.length - 1] + "/" + folder[1] + "/" + folder[2] })

        //     this.setState({ OpencustomFrame: true });

        //     this.setState({ isDataFound: true })
        // })
    }

    getid = (i) => {
        let foundIndex = null;
        this.state.rowData.map((item, index) => {
            if (index < i - 2) {
                foundIndex = index;
                console.log(foundIndex)
            }
        })
        if (foundIndex != null) {
            this.setState({ activeElement: foundIndex })
        }
    }



    getRowsData(data) {
        console.log(this.state.defaultValue)

        console.log(this.state.defaultValue[0])
        var newTableData = [].concat(this.state.rowData)
        let newData = newTableData;
        // console.log(newData);
        if (this.props.pagination) {
            newData = newTableData.filter((row, index) => {
                let rowPerPage = this.state.showPaginationRows < 0 ? newTableData.length : this.state.showPaginationRows
                let start = this.state.currentPaginationPage * rowPerPage
                let end = (this.state.currentPaginationPage + 1) * rowPerPage
                if (index >= start && index < end) return true

            })
        }
        return newData.slice(0, this.state.endIndex).map((row, i) => {
            return (
                <>
                    <ReactVisibilitySensor key={i}
                        onChange={(isVisible) => {
                            if (i == this.state.endIndex - 1 && isVisible) {
                                this.setState((prevState) => ({
                                    endIndex: prevState.endIndex + 100,
                                    visible: true,
                                }));
                            }
                            var value;
                            if (this.state.searchValue == "") {
                                value = "-1";
                            } else {
                                value = this.state.searchValue;
                            }

                            var filtervalue;
                            if (this.state.allcheckedvalues != "-1") {
                                filtervalue = this.state.allcheckedvalues;
                            } else {
                                filtervalue = -1;
                            }

                            if (i + 1 == 1000) {
                                let lastcodevalue = this.state.lastcode - 1000;
                                console.log(lastcodevalue);
                                let obj1;
                                if (this.props.codeno == undefined || this.props.codeno == "" || this.props.codeno == null) {
                                    obj1 = {
                                        'query': `[dbo].[Lawcrux_Web_Proc_InteractiveIndexAdvCl_GetDataV2]@lastCode='{0}' ,@field='{1}', @filter='{2}' , @userid='{3}', @search='{4}'`,
                                        'queryArr': [`${lastcodevalue}`, `${this.props.field}`, `${filtervalue}`, `${this.state.userid}`, `${value}`]
                                    }

                                } else {
                                    let allcodeno = this.props.codeno
                                    obj1 = {
                                        'query': `[dbo].[Lawcrux_Web_Proc_InteractiveIndexAdvCl_SearchGetDataV2]@lastCode='{0}' ,@field='{1}', @filter='{2}' , @userid='{3}', @search='{4}',@Parametericsearch='{5}'`,
                                        'queryArr': [`${lastcodevalue}`, `${this.props.field}`, `${filtervalue}`, `${this.state.userid}`, `${value}`, `${allcodeno}`]
                                    }
                                }

                                Hexapi(obj1).then(resp => {
                                    console.log(resp[""]);
                                    const updatedData = resp[""].map((item) => ({
                                        lcx: item.lcx,
                                        name: item.name,
                                        Icons: item.Icons,
                                        ...item,
                                    }));
                                    this.setState((prevState) => ({
                                        rowData: prevState.rowData.concat(updatedData)
                                    }));
                                    console.log(resp[""].length - 1);
                                    console.log(resp[""][resp[""].length - 1].code);
                                    let lastco = resp[""][resp[""].length - 1].code;
                                    console.log(lastco);
                                    this.setState({
                                        lastcode: lastco
                                    }
                                    );
                                });
                            }
                        }
                        }
                    >
                        <>
                            <tr id={i} key={i} onClick={() => { this.onRowClick(row); row.isread = 1; this.getid(i) }}
                                style={  row["lmark"] == "Y" ? { backgroundColor: '#e49b9b', textAlign: 'left' } : row.isread == 0 ? { fontWeight: 'bold', textAlign: 'left' } : { textAlign: 'left' }}
                            // style={}
                            >
                                {Object.keys(row).filter(heading => this.state.selectedColumns.includes(heading)).map((heading, ind) => {

                                    if (typeof row[heading] != 'object' && heading != "oneliner" && heading != "name" && heading != "year" && heading != "lcx"
                                        && heading != "favourfilter" && heading != "courtlevel" && heading != 'distinguish' && heading != 'isread' && heading != 'Icons'
                                    ) {
                                        return <td key={row[heading]} dangerouslySetInnerHTML={{ __html: row[heading] }} />
                                    }

                                    else if (heading == "lcx") {
                                        let text = row.lcx;
                                        return <td key={row[heading]} >
                                            <div style={this.state.showDemo ? { pointerEvents: 'none', display: 'flex', alignItems: 'center' } : { display: 'flex', alignItems: 'center' }}>

                                                {/* {
                                                    this.state.bookmarkindex.some((element) => element == row.code) ?
                                                        <Tooltip title="Remove Bookmark">
                                                            <IconButton onClick={(e) => { e.stopPropagation(); this.handleBookmarkClick(row, i) }}>
                                                                <BookmarksIcon />
                                                            </IconButton>
                                                        </Tooltip>
                                                        :
                                                        <Tooltip title="Add Bookmark">
                                                            <IconButton onClick={(e) => { e.stopPropagation(); this.handleBookmarkClick(row, i) }}>
                                                                <BookmarkBorderRoundedIcon />
                                                            </IconButton>
                                                        </Tooltip>
                                                } */}
                                                {text}
                                            </div>
                                        </td>


                                    }

                                    else if (heading == "Icons") {
                                        let year = "";
                                        let yeartext = "";
                                        if (row["year"] != null) {
                                            year = row["year"];
                                        }
                                        const field = row["field"];
                                        const favourfilter = row["favourfilter"]
                                        const distinguished = row["distinguished"]
                                        const courtlevel = row["courtlevel"];
                                        const equalsto = row["equalsto"];
                                        const bookmarked = row["isbookmarked"]
                                        if (row["date"] != null) {
                                            // yeartext = row["date"].date;
                                            yeartext = moment(row["date"].date).format('DD-MM-YYYY')
                                        }

                                        let distinguishedtitle;
                                        let distinguishtext;
                                        if (distinguished != undefined || distinguished != undefined) {
                                            const distext = row["distinguishedcitation"].split(",")
                                            distinguishedtitle = distext;
                                            distinguishtext = "≠";
                                        }

                                        let equaltotext;
                                        let equaltotitle;
                                        if (equalsto != undefined || equalsto != undefined) {
                                            const distext = row["equalstocitation"].split(",")
                                            equaltotitle = distext;
                                            equaltotext = "=";
                                        }

                                        const setfavour = (favourfilter) => {
                                            let favourtext = "";
                                            if (favourfilter) {
                                                // console.log(favourfilter)
                                                const value = favourfilter;
                                                if (value == "Assessee Favour") {
                                                    favourtext = "A";
                                                } else if (value == "Partly allowed") {
                                                    favourtext = "P";
                                                } else if (value == "Revenue Favour") {
                                                    favourtext = "R";
                                                } else if (value == "Partly allowed in assessee favour") {
                                                    favourtext = "P(A)"
                                                } else if (value == "Partly allowed in revenue favour") {
                                                    favourtext = "P(R)"
                                                }
                                            }
                                            return favourtext;
                                        }

                                        const setfield = (field) => {
                                            let fieldtitle = "";
                                            if (field) {
                                                if (field.toLowerCase() === "gt") {
                                                    fieldtitle = "GST";
                                                } else if (field.toLowerCase() === "cu") {
                                                    fieldtitle = "Custom";
                                                } else if (field.toLowerCase() === "st") {
                                                    fieldtitle = "Service Tax";
                                                } else if (field.toLowerCase() === "ce") {
                                                    fieldtitle = "Central Excise";
                                                } else {
                                                    fieldtitle = "FOREIGN TRADE (DEVELOPMENT AND REGULATION)";
                                                }
                                            }
                                            return fieldtitle;
                                        }

                                        let courttext;
                                        const setcourt = (courtlevel) => {
                                            let courttittle = row["courtname"].toLowerCase();
                                            if (courtlevel) {
                                                if (courtlevel == "1") {
                                                    courttext = "SC"
                                                } else if (courtlevel == "2") {
                                                    courttext = "HC"
                                                }
                                                else if (courtlevel == "3") {
                                                    courttext = "Tri"
                                                }
                                                else if (courtlevel == "5" && courttittle == 'national antiprofiteering authority') {
                                                    courttext = "NAA"
                                                }else if (courtlevel == "5" && courttittle == 'competition commission of india') {
                                                    courttext = "CCI"
                                                }
                                                else if (courtlevel == "6") {
                                                    courttext = "AAR"
                                                }
                                                else if (courtlevel == "7") {
                                                    courttext = "AAAR"
                                                } else if (courtlevel == "4") {
                                                    courttext = "O"
                                                }
                                            }
                                            return courttext;
                                        }




                                        return <td style={{minWidth:'180px'}}key={row[heading]} >

                                            <div style={row.isread == 0 ? { marginLeft: '5%', fontWeight: 'bold', fontSize: '0.8rem' } : { marginLeft: '5%', fontSize: '0.8rem' }}>
                                                {/* <div style={{ textAlign: 'center', marginBottom: '8px' }}> */}
                                                {/* <Tooltip title={row.name}>
                                                    <span style={{ border: 'none', background: 'black', borderRadius: '20px', color: 'white', padding: '5px', marginRight: '5px', zIndex: '0.5' }} className={`favour-value`}>
                                                        {row.lcx}{" "}
                                                    </span>
                                                </Tooltip> */}
                                                {/* </div>
                                                <div style={{ textAlign: 'center' }}> */}
                                                <Tooltip title={yeartext}>
                                                    <span style={{ border: 'none', background: 'black', borderRadius: '20px', color: 'white', padding: '5px', marginRight: '5px', zIndex: '0.5', cursor: 'pointer' }} className={`favour-value`}>
                                                        {year}{" "}
                                                    </span>
                                                </Tooltip>

                                                {
                                                    row.field != "" && row.field != undefined ?
                                                        <Tooltip title={setfield(field)}>
                                                            <span style={{ border: 'none', background: 'black', borderRadius: '20px', color: 'white', padding: '5px', marginRight: '5px', zIndex: '0.5', cursor: 'pointer' }} className={`favour-value`}>
                                                                {row["field"].toUpperCase()}{" "}
                                                            </span>
                                                        </Tooltip>
                                                        : null
                                                }
                                                {
                                                    row["courtname"] != "" && row["courtname"] != undefined ?
                                                        <Tooltip title={row["courtname"]}>
                                                            <span style={{ border: 'none', background: 'black', borderRadius: '20px', color: 'white', padding: '5px', marginRight: '3px', zIndex: '0.5', cursor: 'pointer' }} className={`favour-value`}>
                                                                {setcourt(row["courtlevel"])}
                                                            </span>
                                                        </Tooltip>
                                                        : null
                                                }
                                                {
                                                    row["favourfilter"] != "" && row["favourfilter"] != undefined ?
                                                        <Tooltip title={row["favourfilter"]}>
                                                            <span style={{ border: 'none', background: 'black', borderRadius: '20px', color: 'white', padding: '5px', marginRight: '3px', zIndex: '0.5', cursor: 'pointer' }} className={`favour-value`}>
                                                                {setfavour(row["favourfilter"])}{" "}
                                                            </span>
                                                        </Tooltip>
                                                        : null

                                                }
                                                {
                                                    row["currstatus"] != "" && row["currstatus"] != undefined && row["currstatus"] != null ?
                                                        <Tooltip title={
                                                            <>
                                                                <div style={{ zIndex: '2', opacity: '2', cursor: 'pointer' }}
                                                                    // onClick={(e) => {
                                                                    //     e.stopPropagation();
                                                                    //     let temppath = row["xnext"]
                                                                    //     let mainf = temppath.split("u");
                                                                    //     let file = temppath.replaceAll("u", '/')
                                                                    //     let path = "Assets/" + file;
                                                                    //     this.setState({ targetfile: path, mainfolder: mainf[1], subfolder: mainf[2] })
                                                                    //     this.setState({ OpencustomFrame: true });
                                                                    // }}
                                                                >{row["currstatus"]}</div>
                                                            </>}
                                                        >
                                                            <ReportProblemIcon color="black"></ReportProblemIcon>
                                                        </Tooltip>
                                                        : null
                                                }
                                                {row["equalstocitation"] != "" && row["equalstocitation"] != null ?
                                                    <Tooltip title={
                                                        <>
                                                            {
                                                                equaltotitle.map((textdata, index) => {
                                                                    return (
                                                                        <>
                                                                            <div style={{ zIndex: '2', opacity: '2', cursor: 'pointer' }}
                                                                                onClick={(e) => {
                                                                                    this.setState({ isDataFound: false })
                                                                                    e.stopPropagation();

                                                                                    const filedata = row["equalsto"].split(",");
                                                                                    console.log(filedata);
                                                                                    const folder = filedata[index].split("/");
                                                                                    this.setState({ mainfolder: folder[2] })
                                                                                    this.setState({ subfolder: folder[3] })
                                                                                    this.setState({ targetfile: filedata[index] })
                                                                                    // this.setState({ targetfile: "Assets/Data5t/" + folder[0] + "/" + folder[1] + "/" + folder[2] })
                                                                                    this.setState({ OpencustomFrame: true });
                                                                                    this.setState({ isDataFound: true })
                                                                                    // calldistinguish(row["distinguished"])
                                                                                    // const file = row["equalsto"] + ".htm";
                                                                                    // console.log(file);
                                                                                    // let obj = {
                                                                                    //     filecode: `${file}`,
                                                                                    //     folderpath: `${this.props.folderpath}`
                                                                                    // }
                                                                                    // HexapiCL(obj).then(resp => {
                                                                                    //     console.log(resp);
                                                                                    //     let data = resp;
                                                                                    //     let folder = data.split('/Assets/Data5t/');
                                                                                    //     console.log(folder)
                                                                                    //     folder = folder[1].split("\\");
                                                                                    //     this.setState({ mainfolder: folder[0] })
                                                                                    //     this.setState({ subfolder: folder[1] })
                                                                                    //     if (folder[2].includes(".htm")) {
                                                                                    //         this.setState({ targetfile: "Assets/Data5t/" + folder[0] + "/" + folder[1] + "/" + folder[2] })
                                                                                    //     } else if (folder[3].includes(".htm")) {
                                                                                    //         this.setState({ targetfile: "Assets/Data5t/" + folder[0] + "/" + folder[1] + "/" + folder[2] + "/" + folder[3] })
                                                                                    //     }
                                                                                    //     else if (folder[4].includes(".htm")) {
                                                                                    //         this.setState({ targetfile: "Assets/Data5t/" + folder[0] + "/" + folder[1] + "/" + folder[2] + "/" + folder[3] + "/" + folder[4] })
                                                                                    //     } else {
                                                                                    //         this.setState({ targetfile: "Assets/Data5t/" + folder[0] + "/" + folder[1] + "/" + folder[2] + "/" + folder[3] + "/" + folder[4] + "/" + folder[5] })
                                                                                    //     }
                                                                                        // this.setState({ targetfile: "Assets/Data5t/" + folder[0] + "/" + folder[1] + "/" + folder[2] })
                                                                                        // this.setState({ OpencustomFrame: true });
                                                                                        // this.setState({ isDataFound: true })
                                                                                    // })
                                                                                }}
                                                                            >{textdata}</div>
                                                                        </>
                                                                    )
                                                                })
                                                            }

                                                        </>

                                                    }>
                                                        <span style={{ border: 'none', background: 'black', borderRadius: '20px', color: 'white', padding: '5px', marginRight: '3px', zIndex: '0.5' }} className={`favour-value`}>
                                                            {"="}{" "}
                                                        </span>
                                                    </Tooltip>
                                                    :
                                                    null
                                                }
                                                {
                                                    distinguishtext != "" && distinguishtext != undefined ?

                                                        <Tooltip title={
                                                            <>
                                                                {
                                                                    distinguishedtitle.map((textdata, index) => {
                                                                        return (
                                                                            <>
                                                                                <div style={{ zIndex: '2', opacity: '2', cursor: 'pointer' }} onClick={(e) => {
                                                                                    this.setState({ isDataFound: false })
                                                                                    e.stopPropagation();
                                                                                    const filedata = row["distinguished"].split(",");
                                                                                    console.log(filedata);
                                                                                    const folder = filedata[index].split("/");
                                                                                    this.setState({ mainfolder: folder[2] })
                                                                                    this.setState({ subfolder: folder[3] })
                                                                                    this.setState({ targetfile: filedata[index] })
                                                                                    // this.setState({ targetfile: "Assets/Data5t/" + folder[0] + "/" + folder[1] + "/" + folder[2] })
                                                                                    this.setState({ OpencustomFrame: true });
                                                                                    this.setState({ isDataFound: true })

                                                                                    // calldistinguish(row["distinguished"])
                                                                                    // const file = row["distinguished"] + ".htm";
                                                                                    // console.log(file);
                                                                                    // let obj = {
                                                                                    //     filecode: `${file}`,
                                                                                    //     folderpath: `${this.props.folderpath}`
                                                                                    // }
                                                                                    // HexapiCL(obj).then(resp => {
                                                                                    //     console.log(resp);
                                                                                    //     let data = resp;
                                                                                    // let folder = data.split('/Assets/Data5t/');
                                                                                    // console.log(folder)
                                                                                    // folder = folder[1].split("\\");
                                                                                    // this.setState({ mainfolder: folder[0] })
                                                                                    // this.setState({ subfolder: folder[1] })
                                                                                    // if (folder[2].includes(".htm")) {
                                                                                    //     this.setState({ targetfile: "Assets/Data5t/" + folder[0] + "/" + folder[1] + "/" + folder[2] })
                                                                                    // } else if (folder[3].includes(".htm")) {
                                                                                    //     this.setState({ targetfile: "Assets/Data5t/" + folder[0] + "/" + folder[1] + "/" + folder[2] + "/" + folder[3] })
                                                                                    // }
                                                                                    // else if (folder[4].includes(".htm")) {
                                                                                    //     this.setState({ targetfile: "Assets/Data5t/" + folder[0] + "/" + folder[1] + "/" + folder[2] + "/" + folder[3] + "/" + folder[4] })
                                                                                    // } else {
                                                                                    //     this.setState({ targetfile: "Assets/Data5t/" + folder[0] + "/" + folder[1] + "/" + folder[2] + "/" + folder[3] + "/" + folder[4] + "/" + folder[5] })
                                                                                    // }
                                                                                    // // this.setState({ targetfile: "Assets/Data5t/" + folder[0] + "/" + folder[1] + "/" + folder[2] })
                                                                                    // this.setState({ OpencustomFrame: true });
                                                                                    // this.setState({ isDataFound: true })
                                                                                    // })
                                                                                }}

                                                                                >{textdata}</div>
                                                                            </>)
                                                                    })
                                                                }
                                                            </>
                                                        }>
                                                            <span style={{ border: 'none', background: 'black', borderRadius: '20px', color: 'white', padding: '5px', marginRight: '3px', zIndex: '0.5' }} className={`favour-value`}>
                                                                {distinguishtext}{" "}
                                                            </span>
                                                        </Tooltip>
                                                        :
                                                        null

                                                }





                                            </div>
                                            {/* </div> */}
                                        </td>
                                    }

                                    else if (heading == "name") {
                                        let year = "";
                                        let yeartext = "";
                                        if (row["year"] != null) {
                                            year = row["year"];
                                        }
                                        const field = row["field"];
                                        const favourfilter = row["favourfilter"]
                                        const distinguished = row["distinguished"]
                                        const courtlevel = row["courtlevel"];
                                        const equalsto = row["equalsto"];
                                        const bookmarked = row["isbookmarked"]
                                        if (row["date"] != null) {
                                            yeartext = row["date"].date;
                                            yeartext = yeartext.slice(0, 11);
                                        }

                                        let distinguishedtitle;
                                        let distinguishtext;
                                        if (distinguished != undefined || distinguished != undefined) {
                                            distinguishedtitle = row["distinguishedcitation"];
                                            distinguishtext = "≠";
                                        }

                                        const setfavour = (favourfilter) => {
                                            let favourtext = "";
                                            if (favourfilter) {
                                                // console.log(favourfilter)
                                                const value = favourfilter;
                                                if (value == "Assessee Favour") {
                                                    favourtext = "A";
                                                } else if (value == "Partly allowed") {
                                                    favourtext = "P";
                                                } else if (value == "Revenue Favour") {
                                                    favourtext = "R";
                                                } else if (value == "Partly allowed in assessee favour") {
                                                    favourtext = "P(A)"
                                                } else if (value == "Partly allowed in revenue favour") {
                                                    favourtext = "P(R)"
                                                }
                                            }
                                            return favourtext;
                                        }

                                        const setfield = (field) => {
                                            let fieldtitle = "";
                                            if (field) {
                                                if (field === "GT") {
                                                    fieldtitle = "GST";
                                                } else if (field === "CU") {
                                                    fieldtitle = "Custom";
                                                } else if (field === "ST") {
                                                    fieldtitle = "Service Tax";
                                                }
                                                else if (field === "CE") {
                                                    fieldtitle = "Central Excise";
                                                } else {
                                                    fieldtitle = "FOREIGN TRADE (DEVELOPMENT AND REGULATION)";
                                                }
                                            }
                                            return fieldtitle;
                                        }

                                        let courttext;
                                        const setcourt = (courtlevel) => {
                                            let courttittle = row["courtname"].toLowerCase();
                                            if (courtlevel) {
                                                if (courtlevel == "1") {
                                                    courttext = "SC"
                                                } else if (courtlevel == "2") {
                                                    courttext = "HC"
                                                }
                                                else if (courtlevel == "3") {
                                                    courttext = "Tri"
                                                }
                                                else if (courtlevel == "5" && courttittle == 'national antiprofiteering authority') {
                                                    courttext = "NAA"
                                                }else if (courtlevel == "5" && courttittle == 'competition commission of india') {
                                                    courttext = "CCI"
                                                }
                                                else if (courtlevel == "6") {
                                                    courttext = "AAR"
                                                }
                                                else if (courtlevel == "7") {
                                                    courttext = "AAAR"
                                                } else if (courtlevel == "4") {
                                                    courttext = "O"
                                                }
                                            }
                                            return courttext;
                                        }




                                        return <td key={row[heading]} style={this.state.showDemo ? { pointerEvents: 'none' } : {}}>

                                            <span style={row.isread == 0 ? { marginLeft: '5%', fontWeight: 'bold' } : { marginLeft: '5%' }}>

                                                <span
                                                // onClick={(e) => {
                                                //     console.log(this.state.petitiondata)
                                                //     // if (this.props.field == "adv") {
                                                //     //     if (row.courtlevel == 7) {
                                                //     //         e.stopPropagation();
                                                //     //     }
                                                //     // }
                                                //     // else {
                                                //     e.stopPropagation();
                                                //     // }
                                                //     let codeno = row["code"].toString()
                                                //     let obj = {
                                                //         'query': `[dbo].[Lawcrux_Web_Proc_InteractiveIndexAdvCl_GetStatusV2]@Code='{0}',@Userid='{1}'`,
                                                //         'queryArr': [`${codeno}`, `${sessionStorage.getItem('userid')}`]
                                                //     }
                                                //     Hexapi(obj).then((res) => {
                                                //         // const res=await Hexapi(obj);
                                                //         console.log(res)
                                                //         if (!res["previous"] && !res["next"]) {
                                                //             // e.stopPropagation();
                                                //             this.onRowClick(row);
                                                //             // this.setState({ showloading: true })

                                                //             console.log('next')
                                                //             this.setState({ showrowcontent: false });

                                                //         } else {
                                                //             this.setState({ showrowcontent: true });
                                                //             // this.setState({ showloading: false })
                                                //             // this.setState({showrowcontent:false});
                                                //         }
                                                //         this.setState(prevState => ({
                                                //             petitionrowdata: {
                                                //                 ...prevState.petitionrowdata,
                                                //                 [row["code"]]: {
                                                //                     previous: res["previous"],
                                                //                     next: res["next"]
                                                //                 }
                                                //             },
                                                //         }));

                                                //         if (this.state.clickedRowIndex == row["code"]) {
                                                //             this.setState({ clickedRowIndex: -1 })
                                                //         } else {
                                                //             this.setState({ clickedRowIndex: row["code"] })
                                                //         }
                                                //     })
                                                // }
                                                // }
                                                >
                                                    {/* {
                                                        row["isbookmarked"] != 0 && row.code == this.state.bookmarkindex ?
                                                            <Tooltip title="Remove Bookmark">
                                                                <IconButton onClick={(e) => { e.stopPropagation(); this.handleBookmarkClick(row, i) }}>
                                                                    <BookmarksIcon />
                                                                </IconButton>
                                                            </Tooltip>
                                                            :
                                                            <Tooltip title="Add Bookmark">
                                                                <IconButton onClick={(e) => { e.stopPropagation(); this.handleBookmarkClick(row, i) }}>
                                                                    <BookmarkBorderRoundedIcon />
                                                                </IconButton>
                                                            </Tooltip>
                                                    } */}
                                                    <a href="javascript:void(0)">{row["name"]}</a>
                                                </span>

                                            </span>
                                            {this.state.clickedRowIndex == row["code"] && this.state.petitionrowdata[row["code"]] && (
                                                <div id={`c${i}`} >
                                                    <blockquote>
                                                        <table border="1" width="100%" id={`table${i}`} cellspacing="0" cellpadding="0">
                                                            <tbody>
                                                                {this.state.petitionrowdata[row["code"]].previous && this.state.petitionrowdata[row["code"]].previous.map((item, index) => (
                                                                    <tr key={index} onClick={(e) => { e.stopPropagation(); this.onRowClick(item); item.isread = 1; }} style={item.isread == 0 ? { fontWeight: 'bold' } : {}}>
                                                                        <td width="30%" style={{ padding: '5px' }}><a href={item.link}>{item.name}
                                                                            {
                                                                                <span style={{ marginLeft: '5%' }}>
                                                                                    <Tooltip title={moment(item.date.date).format('DD-MM-YYYY')}>
                                                                                        <span style={{ border: 'none', background: 'black', borderRadius: '20px', color: 'white', padding: '5px', marginRight: '5px', zIndex: '0.5' }} className={`favour-value`}>
                                                                                            {item.year}{" "}
                                                                                        </span>
                                                                                    </Tooltip>
                                                                                    <Tooltip title={setfield(item.field)}>
                                                                                        <span style={{ border: 'none', background: 'black', borderRadius: '20px', color: 'white', padding: '5px', marginRight: '5px', zIndex: '0.5' }} className={`favour-value`}>
                                                                                            {item.field}{" "}
                                                                                        </span>
                                                                                    </Tooltip>

                                                                                    {
                                                                                        item.distinguished != "" && item.distinguished != null && distinguished != undefined ?
                                                                                            <Tooltip title={
                                                                                                <>
                                                                                                    <div style={{ zIndex: '2', opacity: '2', cursor: 'pointer' }} onClick={(e) => {
                                                                                                        this.setState({ isDataFound: false })
                                                                                                        e.stopPropagation();
                                                                                                        // calldistinguish(row["distinguished"])
                                                                                                        const file = item.distinguished + ".htm";
                                                                                                        console.log(file);
                                                                                                        let obj = {
                                                                                                            filecode: `${file}`,
                                                                                                            folderpath: `${this.props.folderpath}`
                                                                                                        }
                                                                                                        HexapiCL(obj).then(resp => {
                                                                                                            console.log(resp);
                                                                                                            let data = resp;
                                                                                                            let folder = data.split('C:/htdocs/Lawcrux/Lawcrux/Web/Assets/Data5t/');
                                                                                                            folder = folder[1].split("\\");
                                                                                                            this.setState({ mainfolder: folder[0] })
                                                                                                            this.setState({ subfolder: folder[1] })
                                                                                                            if (folder[2].includes(".htm")) {
                                                                                                                this.setState({ targetfile: "Assets/Data5t/" + folder[0] + "/" + folder[1] + "/" + folder[2] })
                                                                                                            } else if (folder[3].includes(".htm")) {
                                                                                                                this.setState({ targetfile: "Assets/Data5t/" + folder[0] + "/" + folder[1] + "/" + folder[2] + "/" + folder[3] })
                                                                                                            }
                                                                                                            else {
                                                                                                                this.setState({ targetfile: "Assets/Data5t/" + folder[0] + "/" + folder[1] + "/" + folder[2] + "/" + folder[3] + "/" + folder[4] })
                                                                                                            }
                                                                                                            // this.setState({ targetfile: "Assets/Data5t/" + folder[0] + "/" + folder[1] + "/" + folder[2] })
                                                                                                            this.setState({ OpencustomFrame: true });
                                                                                                            this.setState({ isDataFound: true })
                                                                                                        })
                                                                                                    }}>{item.distinguished}</div>
                                                                                                </>
                                                                                            }>
                                                                                                <span style={{ border: 'none', background: 'black', borderRadius: '20px', color: 'white', padding: '5px', marginRight: '3px', zIndex: '0.5' }} className={`favour-value`}>
                                                                                                    ≠{" "}
                                                                                                </span>
                                                                                            </Tooltip>
                                                                                            :
                                                                                            null

                                                                                    }

                                                                                    {
                                                                                        item.favourfilter != "" && item.favourfilter != null ?
                                                                                            <Tooltip title={item.favourfilter}>
                                                                                                <span style={{ border: 'none', background: 'black', borderRadius: '20px', color: 'white', padding: '5px', marginRight: '3px', zIndex: '0.5' }} className={`favour-value`}>
                                                                                                    {setfavour(item.favourfilter)}{" "}
                                                                                                </span>
                                                                                            </Tooltip>
                                                                                            : null

                                                                                    }

                                                                                    {row["equalstocitation"] != "" && row["equalstocitation"] != null ?
                                                                                        <Tooltip title={
                                                                                            <>
                                                                                                <div style={{ zIndex: '2', opacity: '2', cursor: 'pointer' }}
                                                                                                    onClick={(e) => {
                                                                                                        e.stopPropagation();
                                                                                                        const file = item.equalsto + ".htm";
                                                                                                        let obj = {
                                                                                                            filecode: `${file}`,
                                                                                                            folderpath: `${this.props.folderpath}`
                                                                                                        }
                                                                                                        HexapiCL(obj).then(resp => {
                                                                                                            console.log(resp);
                                                                                                            let data = resp;
                                                                                                            let folder = data.split('C:/htdocs/Lawcrux/Lawcrux/Web/Assets/Data5t/');
                                                                                                            folder = folder[1].split("\\");
                                                                                                            this.setState({ mainfolder: folder[0] })
                                                                                                            this.setState({ subfolder: folder[1] })
                                                                                                            this.setState({ targetfile: "Assets/Data5t/" + folder[0] + "/" + folder[1] + "/" + folder[2] })
                                                                                                            this.setState({ OpencustomFrame: true });
                                                                                                        })
                                                                                                    }}
                                                                                                >{row["equalstocitation"]}</div>
                                                                                            </>

                                                                                        }>
                                                                                            <span style={{ border: 'none', background: 'black', borderRadius: '20px', color: 'white', padding: '5px', marginRight: '3px', zIndex: '0.5' }} className={`favour-value`}>
                                                                                                {"="}{" "}
                                                                                            </span>
                                                                                        </Tooltip>
                                                                                        : null
                                                                                    }

                                                                                    {
                                                                                        item.courtname != "" && item.courtname != null ?
                                                                                            <Tooltip title={item.courtname}>
                                                                                                <span style={{ border: 'none', background: 'black', borderRadius: '20px', color: 'white', padding: '5px', marginRight: '3px', zIndex: '0.5' }} className={`favour-value`}>
                                                                                                    {setcourt(item.courtlevel)}
                                                                                                </span>
                                                                                            </Tooltip>
                                                                                            : null
                                                                                    }
                                                                                    {/* {
                                                                                        item["isbookmarked"] != 0 && item.code == this.state.bookmarkindex ?
                                                                                            <Tooltip title="Remove Bookmark">
                                                                                                <IconButton onClick={(e) => { e.stopPropagation(); this.handleBookmarkClick(item, i) }}>
                                                                                                    <BookmarksIcon />
                                                                                                </IconButton>
                                                                                            </Tooltip>
                                                                                            :
                                                                                            <Tooltip title="Add Bookmark">
                                                                                                <IconButton onClick={(e) => { e.stopPropagation(); this.handleBookmarkClick(item, i) }}>
                                                                                                    <BookmarkBorderRoundedIcon />
                                                                                                </IconButton>
                                                                                            </Tooltip>
                                                                                    } */}
                                                                                </span>
                                                                            }
                                                                        </a></td>
                                                                        <td width="30%">{item.oneliner}</td>
                                                                        <td width="20%">{item.courtname}</td>
                                                                        <td width="20%">{new Date(item.date.date).toLocaleDateString()}</td>
                                                                    </tr>
                                                                ))}
                                                                {this.state.petitionrowdata[row["code"]].next && this.state.petitionrowdata[row["code"]].next.map((item, index) => (
                                                                    <tr key={index} onClick={(e) => { e.stopPropagation(); this.onRowClick(item); item.isread = 1; }} style={item.isread == 0 ? { fontWeight: 'bold' } : {}}>
                                                                        <td width="30%"><a href={item.link} >{item.name}
                                                                            {
                                                                                <span style={{ marginLeft: '5%' }}>
                                                                                    <Tooltip title={new Date(item.date.date).toLocaleDateString()}>
                                                                                        <span style={{ border: 'none', background: 'black', borderRadius: '20px', color: 'white', padding: '5px', marginRight: '5px', zIndex: '0.5' }} className={`favour-value`}>
                                                                                            {item.year}{" "}
                                                                                        </span>
                                                                                    </Tooltip>
                                                                                    <Tooltip title={setfield(item.field)}>
                                                                                        <span style={{ border: 'none', background: 'black', borderRadius: '20px', color: 'white', padding: '5px', marginRight: '5px', zIndex: '0.5' }} className={`favour-value`}>
                                                                                            {item.field}{" "}
                                                                                        </span>
                                                                                    </Tooltip>
                                                                                    {
                                                                                        item.distinguished != "" && item.distinguished != null && distinguished != undefined ?
                                                                                            <Tooltip title={
                                                                                                <>
                                                                                                    <div style={{ zIndex: '2', opacity: '2', cursor: 'pointer' }} onClick={(e) => {
                                                                                                        this.setState({ isDataFound: false })
                                                                                                        e.stopPropagation();
                                                                                                        // calldistinguish(row["distinguished"])
                                                                                                        const file = item.distinguished + ".htm";
                                                                                                        console.log(file);
                                                                                                        let obj = {
                                                                                                            filecode: `${file}`,
                                                                                                            folderpath: `${this.props.folderpath}`
                                                                                                        }
                                                                                                        HexapiCL(obj).then(resp => {
                                                                                                            console.log(resp);
                                                                                                            let data = resp;
                                                                                                            let folder = data.split('C:/htdocs/Lawcrux/Lawcrux/Web/Assets/Data5t/');
                                                                                                            folder = folder[1].split("\\");
                                                                                                            this.setState({ mainfolder: folder[0] })
                                                                                                            this.setState({ subfolder: folder[1] })
                                                                                                            this.setState({ targetfile: "Assets/Data5t/" + folder[0] + "/" + folder[1] + "/" + folder[2] })
                                                                                                            this.setState({ OpencustomFrame: true });
                                                                                                            this.setState({ isDataFound: true })
                                                                                                        })
                                                                                                    }}>{item.distinguished}</div>
                                                                                                </>
                                                                                            }>
                                                                                                <span style={{ border: 'none', background: 'black', borderRadius: '20px', color: 'white', padding: '5px', marginRight: '3px', zIndex: '0.5' }} className={`favour-value`}>
                                                                                                    ≠{" "}
                                                                                                </span>
                                                                                            </Tooltip>
                                                                                            :
                                                                                            null

                                                                                    }

                                                                                    {
                                                                                        item.favourfilter != "" && item.favourfilter != null ?
                                                                                            <Tooltip title={item.favourfilter}>
                                                                                                <span style={{ border: 'none', background: 'black', borderRadius: '20px', color: 'white', padding: '5px', marginRight: '3px', zIndex: '0.5' }} className={`favour-value`}>
                                                                                                    {setfavour(item.favourfilter)}{" "}
                                                                                                </span>
                                                                                            </Tooltip>
                                                                                            : null

                                                                                    }

                                                                                    {row["equalstocitation"] != "" && row["equalstocitation"] != null ?
                                                                                        <Tooltip title={
                                                                                            <>
                                                                                                <div style={{ zIndex: '2', opacity: '2', cursor: 'pointer' }}
                                                                                                    onClick={(e) => {
                                                                                                        e.stopPropagation();
                                                                                                        const file = item.equalsto + ".htm";
                                                                                                        let obj = {
                                                                                                            filecode: `${file}`,
                                                                                                            folderpath: `${this.props.folderpath}`

                                                                                                        }
                                                                                                        HexapiCL(obj).then(resp => {
                                                                                                            console.log(resp);
                                                                                                            let data = resp;
                                                                                                            let folder = data.split('C:/htdocs/Lawcrux/Lawcrux/Web/Assets/Data5t/');
                                                                                                            folder = folder[1].split("\\");
                                                                                                            this.setState({ mainfolder: folder[0] })
                                                                                                            this.setState({ subfolder: folder[1] })
                                                                                                            this.setState({ targetfile: "Assets/Data5t/" + folder[0] + "/" + folder[1] + "/" + folder[2] })
                                                                                                            this.setState({ OpencustomFrame: true });
                                                                                                        })
                                                                                                    }}
                                                                                                >{row["equalstocitation"]}</div>
                                                                                            </>

                                                                                        }>
                                                                                            <span style={{ border: 'none', background: 'black', borderRadius: '20px', color: 'white', padding: '5px', marginRight: '3px', zIndex: '0.5' }} className={`favour-value`}>
                                                                                                {"="}{" "}
                                                                                            </span>
                                                                                        </Tooltip>
                                                                                        : null
                                                                                    }

                                                                                    {
                                                                                        item.courtname != "" && item.courtname != null ?
                                                                                            <Tooltip title={item.courtname}>
                                                                                                <span style={{ border: 'none', background: 'black', borderRadius: '20px', color: 'white', padding: '5px', marginRight: '3px', zIndex: '0.5' }} className={`favour-value`}>
                                                                                                    {setcourt(item.courtlevel)}
                                                                                                </span>
                                                                                            </Tooltip>
                                                                                            : null
                                                                                    }
                                                                                    {/* {
                                                                                        item["isbookmarked"] != 0 && item.code == this.state.bookmarkindex ?
                                                                                            <Tooltip title="Remove Bookmark">
                                                                                                <IconButton onClick={(e) => { e.stopPropagation(); this.handleBookmarkClick(item, i) }}>
                                                                                                    <BookmarksIcon />
                                                                                                </IconButton>
                                                                                            </Tooltip>
                                                                                            :
                                                                                            <Tooltip title="Add Bookmark">
                                                                                                <IconButton onClick={(e) => { e.stopPropagation(); this.handleBookmarkClick(item, i) }}>
                                                                                                    <BookmarkBorderRoundedIcon />
                                                                                                </IconButton>
                                                                                            </Tooltip>
                                                                                    } */}
                                                                                </span>
                                                                            }
                                                                        </a></td>
                                                                        <td width="30%">{item.oneliner}</td>
                                                                        <td width="20%">{item.courtname}</td>
                                                                        <td width="20%">{new Date(item.date.date).toLocaleDateString()}</td>
                                                                    </tr>
                                                                ))}
                                                                {
                                                                    this.state.showrowcontent ?
                                                                        //    this.state.petitionexpanddata &&
                                                                        // this.state.petitionrowdata[row["code"]].previous.length>0 || this.state.petitionrowdata[row["code"]].next.length>0 ?
                                                                        <tr onClick={(e) => { row.isread = 1; }} style={row.isread == 0 ? { fontWeight: 'bold' } : {}}>
                                                                            <td width="30%" style={{ padding: '5px' }}>
                                                                                <span >{row["name"]}
                                                                                    <Tooltip title={yeartext}>
                                                                                        <span style={{ border: 'none', background: 'black', borderRadius: '20px', color: 'white', padding: '5px', marginRight: '5px', zIndex: '0.5' }} className={`favour-value`}>
                                                                                            {year}{" "}
                                                                                        </span>
                                                                                    </Tooltip>
                                                                                    {
                                                                                        row.field != "" && row.field != undefined ?
                                                                                            <Tooltip title={setfield(field)}>
                                                                                                <span style={{ border: 'none', background: 'black', borderRadius: '20px', color: 'white', padding: '5px', marginRight: '5px', zIndex: '0.5' }} className={`favour-value`}>
                                                                                                    {row["field"]}{" "}
                                                                                                </span>
                                                                                            </Tooltip>
                                                                                            : null
                                                                                    }

                                                                                    {
                                                                                        distinguishtext != "" && distinguishtext != undefined ?
                                                                                            <Tooltip title={
                                                                                                <>
                                                                                                    <div style={{ zIndex: '2', opacity: '2', cursor: 'pointer' }} onClick={(e) => {
                                                                                                        this.setState({ isDataFound: false })
                                                                                                        e.stopPropagation();
                                                                                                        // calldistinguish(row["distinguished"])
                                                                                                        const file = row["distinguished"] + ".htm";
                                                                                                        console.log(file);
                                                                                                        let obj = {
                                                                                                            filecode: `${file}`,
                                                                                                            folderpath: `${this.props.folderpath}`
                                                                                                        }
                                                                                                        HexapiCL(obj).then(resp => {
                                                                                                            console.log(resp);
                                                                                                            let data = resp;
                                                                                                            let folder = data.split('C:/htdocs/LawcruxTest/Web/Assets/Data5t/');
                                                                                                            console.log(folder)
                                                                                                            folder = folder[1].split("\\");
                                                                                                            this.setState({ mainfolder: folder[0] })
                                                                                                            this.setState({ subfolder: folder[1] })
                                                                                                            if (folder[2].includes(".htm")) {
                                                                                                                this.setState({ targetfile: "Assets/Data5t/" + folder[0] + "/" + folder[1] + "/" + folder[2] })
                                                                                                            } else if (folder[3].includes(".htm")) {
                                                                                                                this.setState({ targetfile: "Assets/Data5t/" + folder[0] + "/" + folder[1] + "/" + folder[2] + "/" + folder[3] })
                                                                                                            }
                                                                                                            else if (folder[4].includes(".htm")) {
                                                                                                                this.setState({ targetfile: "Assets/Data5t/" + folder[0] + "/" + folder[1] + "/" + folder[2] + "/" + folder[3] + "/" + folder[4] })
                                                                                                            } else {
                                                                                                                this.setState({ targetfile: "Assets/Data5t/" + folder[0] + "/" + folder[1] + "/" + folder[2] + "/" + folder[3] + "/" + folder[4] + "/" + folder[5] })
                                                                                                            }
                                                                                                            // this.setState({ targetfile: "Assets/Data5t/" + folder[0] + "/" + folder[1] + "/" + folder[2] })
                                                                                                            this.setState({ OpencustomFrame: true });
                                                                                                            this.setState({ isDataFound: true })
                                                                                                        })
                                                                                                    }}>{distinguishedtitle}</div>
                                                                                                </>
                                                                                            }>
                                                                                                <span style={{ border: 'none', background: 'black', borderRadius: '20px', color: 'white', padding: '5px', marginRight: '3px', zIndex: '0.5' }} className={`favour-value`}>
                                                                                                    {distinguishtext}{" "}
                                                                                                </span>
                                                                                            </Tooltip>
                                                                                            :
                                                                                            null

                                                                                    }

                                                                                    {
                                                                                        row["favourfilter"] != "" && row["favourfilter"] != undefined ?
                                                                                            <Tooltip title={row["favourfilter"]}>
                                                                                                <span style={{ border: 'none', background: 'black', borderRadius: '20px', color: 'white', padding: '5px', marginRight: '3px', zIndex: '0.5' }} className={`favour-value`}>
                                                                                                    {setfavour(row["favourfilter"])}{" "}
                                                                                                </span>
                                                                                            </Tooltip>
                                                                                            : null

                                                                                    }

                                                                                    {row.equalstocitation != "" && row.equalstocitation != undefined ?
                                                                                        <Tooltip title={
                                                                                            <>
                                                                                                <div style={{ zIndex: '2', opacity: '2', cursor: 'pointer' }}
                                                                                                    onClick={(e) => {
                                                                                                        this.setState({ isDataFound: false })
                                                                                                        e.stopPropagation();
                                                                                                        console.log("clciked")
                                                                                                        const file = row.equalsto + ".htm";
                                                                                                        console.log(file);
                                                                                                        let obj = {
                                                                                                            filecode: `${file}`,
                                                                                                            folderpath: `${this.props.folderpath}`

                                                                                                        }
                                                                                                        console.log(obj)
                                                                                                        HexapiCL(obj).then(resp => {
                                                                                                            console.log(resp);
                                                                                                            let data = resp;
                                                                                                            let folder = data.split('C:/htdocs/LawcruxTest/Web/Assets/Data5t/');
                                                                                                            folder = folder[1].split("\\");
                                                                                                            console.log(folder)
                                                                                                            this.setState({ mainfolder: folder[0] })
                                                                                                            this.setState({ subfolder: folder[1] })
                                                                                                            if (folder[2].includes(".htm")) {
                                                                                                                this.setState({ targetfile: "Assets/Data5t/" + folder[0] + "/" + folder[1] + "/" + folder[2] })
                                                                                                            } else if (folder[3].includes(".htm")) {
                                                                                                                this.setState({ targetfile: "Assets/Data5t/" + folder[0] + "/" + folder[1] + "/" + folder[2] + "/" + folder[3] })
                                                                                                            }
                                                                                                            else if (folder[4].includes(".htm")) {
                                                                                                                this.setState({ targetfile: "Assets/Data5t/" + folder[0] + "/" + folder[1] + "/" + folder[2] + "/" + folder[3] + "/" + folder[4] })
                                                                                                            } else {
                                                                                                                this.setState({ targetfile: "Assets/Data5t/" + folder[0] + "/" + folder[1] + "/" + folder[2] + "/" + folder[3] + "/" + folder[4] + "/" + folder[5] });

                                                                                                            }
                                                                                                            // this.setState({ targetfile: "Assets/Data5t/" + folder[0] + "/" + folder[1] + "/" + folder[2] })
                                                                                                            this.setState({ OpencustomFrame: true });

                                                                                                        })
                                                                                                        this.setState({ isDataFound: true })
                                                                                                    }}
                                                                                                >{row.equalstocitation}</div>
                                                                                            </>
                                                                                        }>
                                                                                            <span style={{ border: 'none', background: 'black', borderRadius: '20px', color: 'white', padding: '5px', marginRight: '3px', zIndex: '0.5' }} className={`favour-value`}>
                                                                                                ={" "}
                                                                                            </span>
                                                                                        </Tooltip>
                                                                                        : null
                                                                                    }

                                                                                    {
                                                                                        row["courtname"] != "" && row["courtname"] != undefined ?
                                                                                            <Tooltip title={row["courtname"]}>
                                                                                                <span style={{ border: 'none', background: 'black', borderRadius: '20px', color: 'white', padding: '5px', marginRight: '3px', zIndex: '0.5' }} className={`favour-value`}>
                                                                                                    {setcourt(row["courtlevel"])}
                                                                                                </span>
                                                                                            </Tooltip>
                                                                                            : null
                                                                                    }
                                                                                </span></td>
                                                                            <td width="30%">{row.oneliner}</td>
                                                                            <td width="20%">{row["courtname"]}</td>
                                                                            <td width="20%">{new Date(row.date.date).toLocaleDateString()}</td>
                                                                        </tr>
                                                                        :
                                                                        null
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </blockquote>
                                                </div>
                                            )}
                                        </td>
                                    }



                                    else if (heading == "year") {
                                        const value = row[heading];
                                        let displayText = value;
                                        const tooltipTitle = value;
                                        return (
                                            <td key={value} className="favour-column">
                                                <Tooltip title={tooltipTitle}>
                                                    <span style={{ border: 'none', background: 'black', borderRadius: '20px', color: 'white', padding: '5px', marginRight: '5px', zIndex: '0.5' }} className={`favour-value`}>
                                                        {displayText}
                                                    </span>
                                                </Tooltip>
                                            </td>
                                        );
                                    }
                                    // else if (heading == "oneliner") {
                                    //     let text = "";
                                    //     if (row.oneliner != "" && row.oneliner != undefined) {
                                    //         text = row["oneliner"].slice(0, 40);
                                    //         text = text + "..."
                                    //         // console.log(text);
                                    //     }

                                    //     return <td key={row[heading]}
                                    //         onClick={(e) => {
                                    //             if (row.oneliner != "" && row.oneliner != undefined) {
                                    //                 e.stopPropagation();
                                    //                 this.setState({ showoneliner: true, });
                                    //             } this.setState({ onelinertext: row.oneliner })
                                    //         }} >
                                    //         <span >{text}</span>
                                    //     </td>

                                    // }
                                    else {
                                        // return <td key={row} dangerouslySetInnerHTML={{ __html: this.setDate(row[heading].date) }} />
                                        return <td key={row[heading]} dangerouslySetInnerHTML={{ __html: row[heading] }} />

                                    }
                                })}
                            </tr>
                        </>
                    </ReactVisibilitySensor>
                </>
            )
        }
        )
    }
    handleBookmarkClick(row, i) {
        // this.setState({ bookmarkindex: row.code })

        if(this.state.bookmarkindex.some((element) => element == row.code)){
            let arr = this.state.bookmarkindex.filter(ele => ele != row.code)
            this.setState({bookmarkindex : arr})
            this.removeBookmark(row.code, i, row.isbookmarked);
        }else{
            this.setState(previousState => ({
                bookmarkindex: [...previousState.bookmarkindex, row.code]
            }),()=>{
                // console.warn(this.state.bookmarkindex)
            });
            this.addBookmark(row.name, row.code, i, row);
        }
        console.log(row.isbookmarked)


        // if (row.isbookmarked !== 0) {
        //     // let arr = this.state.bookmarkindex.filter(ele => ele != row.code)
        //     // this.setState({bookmarkindex : arr})
        //     this.removeBookmark(row.code, i, row.isbookmarked);
        // } 
        // else {
        //     this.addBookmark(row.name, row.code, i, row);
        // }
    }

    removeBookmark(filepath, i, bookmark) {
        // this.setState({ isBookmarkAddComplete: false })
        let obj = {
            query: `[dbo].[Lawcrux_Web_Proc_RemoveBookmark_Submit] @UserID='{0}', @filepath='{1}'`,
            queryArr: [`${this.state.userid}`, `${filepath}`]
        }
        console.log(obj)
        Hexapi(obj).then(resp => {
            console.log(resp)
            if (resp[''][0].command == 1) {
                const newData = [...this.state.rowData];
                newData[i].isbookmarked = 0;
                // newData.bookmark=0;

                this.setState({
                    rowData: newData,
                    isBookmarkAdded: true
                });
            }
        })
    }

    addBookmark(name, filepath, i, rowData) {

        let file = '';

        if (rowData.courtlevel == "1") {
            file = `cl/clsupr/${rowData.field}/${rowData.year}/${rowData.code}.htm`
        }
        else if (rowData.courtlevel == "2") {
            file = `cl/clhigh/${rowData.field}/${rowData.year}/${rowData.code}.htm`
        }
        else if (rowData.courtlevel == "3") {
            if (rowData.courtname.toLowerCase() == 'bangalore tribunal') {
                file = `cl/cltban/${rowData.field}/${rowData.year}/${rowData.code}.htm`
            } else if (rowData.courtname.toLowerCase() == 'chennai tribunal') {
                file = `cl/cltche/${rowData.field}/${rowData.year}/${rowData.code}.htm`
            } else if (rowData.courtname.toLowerCase() == 'delhi tribunal') {
                file = `cl/cltdel/${rowData.field}/${rowData.year}/${rowData.code}.htm`
            } else if (rowData.courtname.toLowerCase() == 'kolkata tribunal') {
                file = `cl/cltkol/${rowData.field}/${rowData.year}/${rowData.code}.htm`
            } else if (rowData.courtname.toLowerCase() == 'larger bench') {
                file = `cl/cltlar/${rowData.field}/${rowData.year}/${rowData.code}.htm`
            } else if (rowData.courtname.toLowerCase() == 'mumbai tribunal') {
                file = `cl/cltmum/${rowData.field}/${rowData.year}/${rowData.code}.htm`
            } else if (rowData.courtname.toLowerCase() == 'tribunal') {
                file = `cl/cltrib/${rowData.field}/${rowData.year}/${rowData.code}.htm`
            } else if (rowData.courtname.toLowerCase() == 'central administrative tribunal') {
                file = `cl/cltrib/${rowData.field}/${rowData.year}/${rowData.code}.htm`
            } else if (rowData.courtname.toLowerCase() == 'hyderabad tribunal') {
                file = `cl/clthyd/${rowData.field}/${rowData.year}/${rowData.code}.htm`
            } else if (rowData.courtname.toLowerCase() == 'ahmedabad tribunal') {
                file = `cl/cltahm/${rowData.field}/${rowData.year}/${rowData.code}.htm`
            } else if (rowData.courtname.toLowerCase() == 'chandigarh tribunal') {
                file = `cl/cltchd/${rowData.field}/${rowData.year}/${rowData.code}.htm`
            } else if (rowData.courtname.toLowerCase() == 'allahabad tribunal') {
                file = `cl/cltald/${rowData.field}/${rowData.year}/${rowData.code}.htm`
            } else {
                file = `cl/cltrib/${rowData.field}/${rowData.year}/${rowData.code}.htm`
            }
        }
        else if (rowData.courtlevel == "5") {
            // courttext = "NAA"
            file = `cl/clnapa/${rowData.field}/${rowData.year}/${rowData.code}.htm`
        }
        else if (rowData.courtlevel == "6") {
            // courttext = "AAR"
            file = `cl/claarc/${rowData.field}/${rowData.year}/${rowData.code}.htm`
        }
        else if (rowData.courtlevel == "7") {
            // courttext = "AAAR"   
            file = `cl/claaar/${rowData.field}/${rowData.year}/${rowData.code}.htm`
        }
        else if (rowData.courtlevel == "4") {
            // courttext = "O"
        }

        let path = "Assets/Data5t/" + file;
        console.log(path);

        let title = name;
        // this.setState({ isBookmarkAddComplete: false })/
        let obj = {
            query: `[dbo].[Lawcrux_Web_Proc_AddBookmark_Submit] @UserID='{0}', @filepath='{1}',@Description='{2}'`,
            queryArr: [`${this.state.userid}`, `${path}`, `${title}`]
        }
        console.log(obj)

        Hexapi(obj).then(resp => {
            console.log(resp)
            if (resp[''][0].command == 1) {
                const newData = [...this.state.rowData];
                // this.setState({petitionrowdata[file].previous.isbookmarked})
                // data.isbookmarked=1;
                // newData=1;
                console.log(newData.bookmark)
                newData[i].isbookmarked = 1; // Update isbookmarked property directly
                this.setState({
                    rowData: newData,
                    isBookmarkAdded: false
                });
            }
        })

    }



    storeSearch(e) {
        console.log(this.state.searchValue)
        // console.log(value);
        // this.setState({ searchValue: value })
        let applyFilter = Object.keys(oldData[0])

        if (value == '') {
            if (oldData.length > 0) {
                this.setState({ rowData: oldData, isDataFound: true })
                // console.log(rowData)
            } else {
                this.setState({ isDataFound: false })
            }
        } else {
            this.setState({ isDataFound: false })
            var value;
            if (this.state.searchValue == "") {
                value = "-1";
            } else {
                value = this.state.searchValue;
            }

            var filtervalue;
            if (this.state.allcheckedvalues != "-1") {
                filtervalue = this.state.allcheckedvalues;
            } else {
                filtervalue = -1;
            }

            let obj1;
            if (this.props.codeno == undefined || this.props.codeno == "" || this.props.codeno == null) {
                obj1 = {
                    'query': `[dbo].[Lawcrux_Web_Proc_InteractiveIndexAdvCl_GetDataV2]@lastCode='{0}' ,@field='{1}', @filter='{2}' , @userid='{3}', @search='{4}'`,
                    'queryArr': ['-1', `${this.props.field}`, `${filtervalue}`, `${this.state.userid}`, `${value}`]
                }
            } else {
                let allcodeno = this.props.codeno
                obj1 = {
                    'query': `[dbo].[Lawcrux_Web_Proc_InteractiveIndexAdvCl_SearchGetDataV2]@lastCode='{0}' ,@field='{1}', @filter='{2}' , @userid='{3}', @search='{4}',@Parametericsearch='{5}'`,
                    'queryArr': ['-1', `${this.props.field}`, `${filtervalue}`, `${this.state.userid}`, `${value}`, `${allcodeno}`]
                }
            }

            console.log(obj1);
            Hexapi(obj1).then(resp => {
                console.log(resp[""]);
                // const columns = resp[""]
                const updatedData = resp[""].map((item) => ({
                    lcx: item.lcx,
                    name: item.name,
                    Icons: item.Icons,
                    ...item,
                }));
                this.setState({ rowData: updatedData })
                this.setState({ isDataFound: true })
                if(resp["total"] != undefined){
                    this.setState({TotalCount:resp["total"][0]["count"]})
                }

            });
        }
    }

    openFilterModal = (e) => {
        this.setState({ isOpenModal: true })
        if (this.state.originalfilterData != null) {
            console.log(this.state.originalfilterData)
            this.state.originalfilterData.map((data, index) => {
                if (index == 0) {
                    console.log(data);
                    this.setState({ filterData: data, filtername: data.filtername })
                }
            })
        }
    }

    clearFilters = () => {
        this.setState({ filternamecounts: [] })
        if (this.state.defaultValue[0].def == this.state.defaultValue[0].oldvalues) {
            console.log(true)
        } else {
            console.log(false)
        }
        this.setState({ isloadcomplete: false, isfilterset: false })
        // console.log(this.state.originalfilterData.length)
        for (var i = 0; i < this.state.originalfilterData.length; i++) {
            this.state.defaultValue[0].def[this.state.originalfilterData[i].filtername] = this.state.defaultValue[0].oldvalues[this.state.originalfilterData[i].filtername]
        }
        let obj1;
        var value;
        if (this.state.searchValue == "") {
            value = "-1";
        } else {
            value = this.state.searchValue;
        }
        if (this.props.codeno == undefined || this.props.codeno == "" || this.props.codeno == null) {
            obj1 = {
                'query': `[dbo].[Lawcrux_Web_Proc_InteractiveIndexAdvCl_GetDataV2]@lastCode='{0}' ,@field='{1}', @filter='{2}' , @userid='{3}', @search='{4}'`,
                'queryArr': ['-1', `${this.props.field}`, '-1', `${this.state.userid}`, `${value}`]
            }

        } else {
            let allcodeno = this.props.codeno
            obj1 = {
                'query': `[dbo].[Lawcrux_Web_Proc_InteractiveIndexAdvCl_SearchGetDataV2]@lastCode='{0}' ,@field='{1}', @filter='{2}' , @userid='{3}', @search='{4}',@Parametericsearch='{5}'`,
                'queryArr': ['-1', `${this.props.field}`, '-1', `${this.state.userid}`, `${value}`, `${allcodeno}`]
            }
        }
        console.log(obj1)
        this.setState({ isDataFound: false })

        Hexapi(obj1).then(resp => {
            // const updatedData = resp[""].map(item => {
            //     return { ...item, Icons: '' };
            // });
            const updatedData = resp[""].map((item) => ({
                lcx: item.lcx,
                name: item.name,
                Icons: item.Icons,
                ...item,
            }));
            console.log(updatedData);
            this.setState({ rowData: updatedData })
            this.setState({ isDataFound: true })
            if(resp["total"] != undefined){
                    this.setState({TotalCount:resp["total"][0]["count"]})
                }
        });
        this.setState({ isOpenModal: false })
        this.setState({ nullhandle: false })
    }


    updateDefaultvalues = (data, checked) => {
        // if(this.state.isOpenModal)
        console.log(checked);
        console.log(this.state.filtername, data)


        // for()
        console.log(this.state.defaultValue[0].values)
        // defaultValue[0].def[this.state.filtername] = data
        this.setState({ counts: this.state.counts + 1 })
        this.state.defaultValue[0].def[this.state.filtername] = data
        console.log(this.state.defaultValue[0].def);


        // ==================>Function to get unticked value

        // let untickedvalues = {};
        // var items = [];
        // for (let filtername in this.state.defaultValue[0].values) {
        //     let values = this.state.defaultValue[0].values[filtername].split(',');
        //     let defVals = this.state.defaultValue[0].def[filtername].split(',');
        //     let untickedSubItems = [];
        //     for (let i = 0; i < values.length; i++) {
        //         if (!defVals.includes(values[i])) {
        //             untickedSubItems.push(values[i]);
        //         }
        //     }
        //     if (untickedSubItems.length > 0) {
        //         let val = Object.values(untickedSubItems).toString();
        //         items.push(val);
        //         // console.log(Object.values(untickedSubItems).toString())
        //         // this.setState({untickedvalues:Object.evalues(untickedSubItems).toString()})
        //         // this.state.untickedvalues = untickedSubItems.toString();
        //     }
        // }
        // this.setState({ untickedvalues: Object.values(items).toString() })
        // console.log('Unticked values:', this.state.untickedvalues);
        // console.log('update 1')
        // console.log(defaultValue)
    }

    setFilter = (data) => {
        console.log(data.filtername)
        this.setState({ filterData: data, filtername: data.filtername })
    }

    pageload = () => {
        // if (this.state.searchValue == "") {
        //     this.setState({ searchValue: "-1" })
        // }
        var value;
        if (this.state.searchValue == "") {
            value = "-1";
        } else {
            value = this.state.searchValue;
        }

        var filtervalue;
        if (this.state.allcheckedvalues != "-1") {
            filtervalue = this.state.allcheckedvalues;
        } else {
            filtervalue = -1;
        }
        let obj1;
        if (this.props.codeno == undefined || this.props.codeno == "" || this.props.codeno == null) {
            obj1 = {
                'query': `[dbo].[Lawcrux_Web_Proc_InteractiveIndexAdvCl_GetDataV2]@lastCode='{0}' ,@field='{1}', @filter='{2}' , @userid='{3}', @search='{4}'`,
                'queryArr': ['-1', `${this.props.field}`, `${filtervalue}`, `${this.state.userid}`, `${value}`]
            }

        } else {
            let allcodeno = this.props.codeno
            obj1 = {
                'query': `[dbo].[Lawcrux_Web_Proc_InteractiveIndexAdvCl_SearchGetDataV2]@lastCode='{0}' ,@field='{1}', @filter='{2}' , @userid='{3}', @search='{4}',@Parametericsearch='{5}'`,
                'queryArr': ['-1', `${this.props.field}`, `${filtervalue}`, `${this.state.userid}`, `${value}`, `${allcodeno}`]
            }
        }
        console.log(obj1)

        Hexapi(obj1).then(resp => {
            const updatedData = resp[""].map(item => {
                return { ...item, Icons: '' };
            });
            this.setState({ rowData: updatedData })
            if(resp["total"] != undefined){
                    this.setState({TotalCount:resp["total"][0]["count"]})
                }
        }
        )
    }

    handleforward = () => {
        const value = false;
        this.props.callback(value)
    }

    scollback = () => {
        const element = document.getElementById(this.state.activeElement);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' })
        }
        this.setState({ activeElement: '' });
    }


    MarkDoneIllustration = () => {
        // let obj2 = {
        //     'query': `[dbo].[Lawcrux_Web_Proc_Illustration_Update] @UserID='{0}', @Illustration='{1}' `,// @RecID='{0}'
        //     'queryArr': [`${sessionStorage.getItem('userid')}`, '0']
        // }
        // Hexapi(obj2).then(resp => {
        //     console.log(resp)
        // });
    }


    render() {
        // console.log(this.state.rowData);
        // console.log(this.props.pagination)
        // console.log(this.state.isDataFound)
        // console.log(this.state.rowData);
        // console.log(this.state.newtable);


        return (
            <>
                {/* dangerouslySetInnerHTML={__html: this.state.html} */}

                {
                    this.state.OpencustomFrame ?
                        <ContentFrame
                            // style={{backgroundImage:'',opacity:'0.2',backgroundRepeat:' no-repeat', backgroundPosition: 'center center',}}
                            goBack={() => { this.setState({ OpencustomFrame: false }); this.scollback() }}
                            filepath={this.state.targetfile}
                            searchApi={sessionStorage.getItem('searchapi')}
                            rootUrl={sessionStorage.getItem('url')}
                            userid={sessionStorage.getItem('userid')}
                            MainFolder={this.state.mainfolder}
                            SubFolder={this.state.subfolder}
                            goHome={() => { this.setState({ OpencustomFrame: false }); this.handleforward() }}
                            showHighlightttext={this.props.showHighlightttext}
                            searchitem={this.props.searchitem}
                            showABC={this.props.field == "cl" || this.props.field == "adv" ? false : true}
                            showinternaldemo={this.state.showinternaldemo}
                        />
                        :
                        null
                }

                {
                    this.state.showDemo && this.state.startdemo?
                        <>
                            <div class="react-portal" style={{ /*height: '100%',*/ overflow: 'hidden', width: '100%' }}>
                                <div class="popover right" id="popovermodal" style={
                                    this.state.itemclicked4 ? {} : this.state.itemclicked3 ? { width: '428px', zIndex: '121', left: '0%', top: '23%' } : this.state.itemclicked2 ? { width: '428px', zIndex: '121', left: '50%', top: '18%' } : this.state.itemclicked1 ? { width: '428px', zIndex: '121', left: '79%', top: '10%' } : { width: '428px', zIndex: '121', left: '70%', top: '8%' }}>
                                    <div style={{ width: '96%', margin: 'auto' }}>
                                        <div class="popover__header " style={{ display: 'flex', justifyContent: "space-evenly", alignItems: 'center' }}>
                                            <div>
                                                <span style={{ fontFamily: 'Roboto Condensed', fontSize: '22px', display: 'flex', justifyContent: 'center' }} >
                                                    {
                                                        this.state.itemclicked1 ?
                                                            null
                                                            :
                                                            "Search :"
                                                    }
                                                    {
                                                        this.state.itemclicked2 == false && this.state.itemclicked1 == true ?
                                                            "Filter :"
                                                            :
                                                            null
                                                    }
                                                    {
                                                        this.state.itemclicked2 == true && this.state.itemclicked3 == false ?
                                                            "Informative Icons : "
                                                            :
                                                            null
                                                    }
                                                    {
                                                        this.state.itemclicked3 == true && this.state.itemclicked4 == false ?
                                                            "PartyName :"
                                                            :
                                                            null
                                                    }
                                                </span>
                                            </div>

                                        </div>
                                        <div class="popover__body=" style={{ marginTop: '0' }}>
                                            <span >
                                                {
                                                    this.state.itemclicked1 ?
                                                        null
                                                        :
                                                        "Type here to search caselaws by party name or citation"
                                                }
                                                {
                                                    this.state.itemclicked2 == false && this.state.itemclicked1 == true ?
                                                        "Apply filters yo get desired results such as court , favour , subject , year etc"
                                                        :
                                                        null
                                                }
                                                {
                                                    this.state.itemclicked2 == true && this.state.itemclicked3 == false ?
                                                        "Hover on icons to view information related to caselaw"
                                                        :
                                                        null
                                                }
                                                {
                                                    this.state.itemclicked3 == true && this.state.itemclicked4 == false ?
                                                        "Click on partyname to read caselaw"
                                                        :
                                                        null
                                                }
                                            </span>
                                        </div>
                                        <div class="popover_footer" style={{ display: 'flex', justifyContent: 'space-between', marginTop: '5px' }}>

                                            <span style={{}} >
                                                {
                                                    this.state.itemclicked1 == true ?
                                                        <FiberManualRecordOutlinedIcon style={{ height: '8px', width: '15px' }} />
                                                        :
                                                        <FiberManualRecordIcon style={{ height: '15px', width: '15px', color: 'rgb(241, 108, 108)' }} />
                                                }
                                                {
                                                    this.state.itemclicked2 == false && this.state.itemclicked1 == true ?
                                                        <FiberManualRecordIcon style={{ height: '15px', width: '15px', color: 'rgb(241, 108, 108)' }} />
                                                        :
                                                        <FiberManualRecordOutlinedIcon style={{ height: '8px', width: '15px' }} />
                                                }
                                                {
                                                    this.state.itemclicked2 == true && this.state.itemclicked3 == false ?
                                                        <FiberManualRecordIcon style={{ height: '15px', width: '15px', color: 'rgb(241, 108, 108)' }} />
                                                        :
                                                        <FiberManualRecordOutlinedIcon style={{ height: '8px', width: '15px' }} />
                                                }
                                                {
                                                    this.state.itemclicked3 == true && this.state.itemclicked4 == false ?
                                                        <FiberManualRecordIcon style={{ height: '15px', width: '15px', color: 'rgb(241, 108, 108)' }} />
                                                        :
                                                        <FiberManualRecordOutlinedIcon style={{ height: '8px', width: '15px' }} />
                                                }
                                            </span>
                                            {
                                                this.state.itemclicked1 ?
                                                    null
                                                    :
                                                    <button style={{ width: '20%', marginBottom: '5px', borderRadius: '5px', backgroundColor: '#f16c6c', color: 'white', border: 'none' }} onClick={() => this.setState({ itemclicked1: true, })}>Next</button>
                                            }
                                            {
                                                this.state.itemclicked2 == false && this.state.itemclicked1 == true ?
                                                    <button style={{ width: '20%', marginBottom: '5px', borderRadius: '5px', backgroundColor: '#f16c6c', color: 'white', border: 'none' }} onClick={() => this.setState({ itemclicked2: true, })}>Next</button>
                                                    :
                                                    null
                                            }
                                            {
                                                this.state.itemclicked3 == false && this.state.itemclicked2 == true ?
                                                    <button style={{ width: '20%', marginBottom: '5px', borderRadius: '5px', backgroundColor: '#f16c6c', color: 'white', border: 'none' }} onClick={() => this.setState({ itemclicked3: true, })}>Next</button>
                                                    :
                                                    null
                                            }
                                            {
                                                this.state.itemclicked4 == false && this.state.itemclicked3 == true ?
                                                    <button style={{ width: '20%', marginBottom: '5px', borderRadius: '5px', backgroundColor: '#f16c6c', color: 'white', border: 'none' }} onClick={() => this.setState({ itemclicked4: true, showDemo: false,startdemo:false }, () => { this.MarkDoneIllustration() })}>Next</button>
                                                    :
                                                    null
                                            }
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </>
                        :
                        null
                }
                <div
                    style={(this.state.showDemo == true && this.state.itemclicked4 == false) ? {
                        position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, height: '120%',
                        backgroundColor: 'rgb(107 107 107)', opacity: 0.7, zIndex: 3,
                    } : {position: 'relative', height: "inherit"}} >
                    <div style={{ position: 'relative', height: "inherit" }}>
                        {this.props.showheadernavbar ?
                            <div style={this.state.showDemo ? { backgroundColor: 'rgb(107, 107, 107)', display: 'flex', justifyContent: 'space-between', width: '100%',pointerEvents:'none' } : { display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                                <div className="col-1">
                                    <Tooltip title="Go Home"
                                        onClick={() => this.state.showDemo == false && this.handleforward()}>
                                        <IconButton >
                                            <HomeIcon style={{ color: "black" }} />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                                <div className="col-1" >
                                    <Tooltip title="Go back">
                                        <IconButton onClick={() => this.state.showDemo == false && this.handleforward()}>
                                            <ArrowBackIcon style={{ color: "black" }} />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                                <div className="col-1" >
                                    <Tooltip title="Go Forward">
                                        <IconButton onClick={() => { this.state.showDemo == false && this.setState({ OpencustomFrame: true }) }}>
                                            <ArrowForwardIcon style={{ color: "black" }} />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                                <div className="col-1" >
                                    <Tooltip title="Search keyword">
                                        <IconButton onClick={() => this.state.showDemo == false && this.setState({ isOpenSearchModal: true })}>
                                            <SearchIcon />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                                <div className="col-1" >
                                    <Tooltip title="Dictionary">
                                        <IconButton onClick={() => this.state.showDemo == false && this.setState({ isOpenDictModal: true })}>
                                            <LibraryBooksIcon />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                            </div>
                            :
                            null
                        }
                        {
                            this.state.isNavbar &&
                            <div className="_tablenavbar" style={this.state.showDemo ? { backgroundColor: 'rgb(107, 107, 107)' } : { backgroundColor: '#f9f9f9' }}>
                                <div className="_left" style={{ display: 'flex', alignItems: 'center' }}>
                                    {/* {
                                    this.props.showHome == true ?
                                        <div className="col-1">
                                            <Tooltip title="Go Home"
                                                onClick={() => this.handleforward()}>
                                                <IconButton >
                                                    <HomeIcon style={{ color: "black" }} />
                                                </IconButton>
                                            </Tooltip>
                                        </div>
                                        :
                                        <></>} */}
                                    <span style={(this.props.field == "adv" ? { textTransform: "Capitalize", whiteSpace: 'nowrap', marginLeft: '14%' } : { textTransform: "Capitalize", marginLeft: '20%', whiteSpace: 'nowrap' })}>
                                        {this.props.field == "adv" ? "Advance Ruling" : "Case Law"}
                                        {
                                        this.state.TotalCount != undefined &&  this.state.TotalCount != '' ?
                                            " (Total Count -" + this.state.TotalCount + ")"
                                        : null
                                        }
                                    </span>

                                </div>

                                <div className="_right" style={{ display: 'flex', justifyContent: "center" }}>
                                    <input type="text" ref={this.searchInputRef} id="searchBarFocus" autoFocus={true} value={this.state.searchValue}
                                        style={{
                                            width: this.state.isShowSearchBox ? "250px" : '0px', padding: this.state.isShowSearchBox ? "6px 12px" : "6px 0px",
                                            marginLeft: this.state.isShowSearchBox ? "10px" : '0px'
                                        }} className="editable" placeholder='Search...'
                                        onChange={(e) => this.state.showDemo == false && this.setState({ searchValue: e.target.value })}
                                    ></input>
                                    {
                                        this.state.searchValue.length > 0 ?
                                            <Tooltip title="Close">
                                                <IconButton style={{ color: 'black' }} onClick={() => this.clearSearch()}>
                                                    <CloseIcon />
                                                </IconButton>
                                            </Tooltip> : <></>
                                    }
                                    {
                                        this.state.isShowSearchBox ?
                                            <>
                                                <Tooltip title="Search">
                                                    <IconButton style={this.state.itemclicked1 == false ? { position: 'relative', zIndex: '4', backgroundColor: 'white' } : { color: 'black' }} onClick={() => this.state.showDemo == false && this.storeSearch()}>
                                                        <SearchIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </>


                                            :
                                            <Tooltip title="Open Search">
                                                <IconButton style={{ color: 'black' }} onClick={() => this.state.showDemo == false && this.setState({ isShowSearchBox: true }, () => {
                                                    if (document.getElementById('searchBarFocus')) {
                                                        document.getElementById('searchBarFocus').focus()
                                                    }
                                                })}>
                                                    <SearchIcon />
                                                </IconButton>
                                            </Tooltip>

                                    }
                                    <Tooltip title={'Filter'}>
                                        <IconButton style={this.state.itemclicked1 == true && this.state.itemclicked2 == false ? { position: 'relative', zIndex: '4', backgroundColor: 'white' } : { color: 'black' }} onClick={(e) => this.state.showDemo == false && this.openFilterModal(e)}>
                                            <FilterAltIcon />
                                        </IconButton>
                                    </Tooltip>
                                    {
                                        this.state.isfilterset ?
                                            <div style={{ backgroundColor: 'red', borderRadius: '50%', height: '20px', width: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                <span style={{ color: 'white', }}>{this.state.filtercount}</span>
                                            </div>
                                            : 
                                            ''
                                    }
                                    {/* <div className="col-md-2 col-2">
                                            <div style={{ display: 'inline-flex' }}>
                                                <i className="fa fa-filter" id='ticketsRefreshBtn' aria-hidden="true" onClick={(e) => this.openFilterModal(e)}></i>
                                                {
                                                    this.state.isfilterset ?
                                                        <div style={{ backgroundColor: 'red', borderRadius: '50%', height: '20px', width: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                            <span style={{ color: 'white' }}>{this.state.filtercount}</span>
                                                        </div>
                                                        : ''
                                                }
                                            </div>

                                        </div> */}
                                </div>
                            </div>
                        }


                        {this.state.nullhandle ?
                            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '15%' }}>
                                <h3>No Data Found</h3>
                            </div>
                            :

                            <Table id='_tableGrid' className={'table-hover'} responsive style={{ height: this.props.tableheight }}>
                                <thead style={{
                                    backgroundColor: "black",
                                    color: '#fff'
                                }} >
                                    {/* style={this.props.headStyle}} */}
                                    <tr>
                                        {this.state.isDataFound &&
                                            this.state.rowData.length > 0 && this.getHeader(this.state.rowData)}
                                    </tr>
                                </thead>
                                <>
                                    {
                                        this.state.showloading ?

                                            <div style={{ position: 'relative', marginTop: '10%', marginLeft: '50%' }}>
                                                <h3>
                                                    Loading...<Spinner variant='dark' animation='border' />
                                                </h3>
                                            </div>
                                            :
                                            this.state.isDataFound ?
                                                this.state.rowData.length > 0 ?
                                                    <tbody style={this.state.showDemo == true && this.state.itemclicked4 == false ? {pointerEvents:'none'}:{}}>
                                                        {
                                                            this.getRowsData(this.state.newtable)
                                                        }
                                                    </tbody>

                                                    :
                                                    <tbody>
                                                        <div style={{ marginTop: '10%', marginLeft: '4%' }}>
                                                            <h3>
                                                                No Data Found
                                                            </h3>
                                                        </div>
                                                    </tbody>
                                                : <tbody>
                                                    <div style={{ marginTop: '10%', marginLeft: '4%' }}>
                                                        <h3>
                                                            Loading...<Spinner variant='dark' animation='border' />
                                                        </h3>
                                                    </div>
                                                </tbody>

                                    }

                                </>
                            </Table>
                        }

                        {
                            this.props.pagination &&
                            this.state.isDataFound &&
                            <div className="_tablefooter">

                                <PaginationBar
                                    rowsPerPage={this.props.pagination.rowsPerPage}
                                    count={this.state.rowData.length}
                                    rowsPerPageOptions={this.props.pagination.rowsPerPageOptions}
                                    onPageChange={(currentPage) => this.setState({ currentPaginationPage: currentPage })}
                                    onRowsChange={(rows) => this.setState({ showPaginationRows: rows, currentPaginationPage: 0 })}
                                />
                            </div>
                        }

                    </div >
                </div >



                {
                    this.state.isOpenDictModal &&
                    <Dictionary
                        rootUrl={sessionStorage.getItem('url')}
                        userid={sessionStorage.getItem('userid')}
                        onCloseModal={() => this.setState({ isOpenDictModal: false })}
                    />
                }

                <Modal size="md" centered style={{ marginTop: '3%' }} show={this.state.showoneliner} onHide={() => this.setState({ showoneliner: false })}>
                    <Modal.Body style={{ display: 'flex', justifyContent: 'space-around', padding: '10px' }}>
                        <div style={{ minHeight: '10vh' }}>
                            {this.state.onelinertext}
                        </div>
                    </Modal.Body>
                </Modal >

                {/* // <Modal size="md" centered style={{ marginTop: '3%' }} show={this.state.showloading} onHide={() => this.setState({ showloading: false })}>
                //     <Modal.Body >
                //         <div style={{ marginTop: '10%', marginLeft: '4%' }}>
                //             <h3>
                //                 Loading...<Spinner variant='dark' animation='border' />
                //             </h3>
                //         </div>
                //     </Modal.Body>
                // </Modal > */}

                <Modal fullscreen={true} id="documentmodal" animation={true} show={this.state.isOpenSearchModal} onHide={() => this.setState({ isOpenSearchModal: false })}>
                    <Modal.Body>
                        <Searched
                            searchApi={sessionStorage.getItem("searchapi")}
                            rootUrl={sessionStorage.getItem('url')}
                            goBack={() => this.setState({ isOpenSearchModal: false, isReferedByHistory: false, isReferedByHsn: false })}
                            userid={sessionStorage.getItem('userid')}
                            isReferedByHsn={this.state.isReferedByHsn}
                            hsnSearchWord={this.state.hsnSearchWord}
                            hsnPath={this.state.hsnPath}
                            isReferedByHistory={this.state.isReferedByHistory}
                            historyFileData={this.state.historyFileData}
                            goHome={() => this.setState({ isOpenSearchModal: false })}
                        />
                    </Modal.Body>
                </Modal>

                <Modal size="lg" centered style={{ backgroundColor: 'rgba(0,0,0,0.6)' }} show={this.state.isOpenModal} keyboard={false} backdrop='static' >{/*  keyboard={false} backdrop='static' */}
                    <Modal.Header>
                        <h3>Filters</h3>
                        <div style={this.state.isfilterset ? { display: "flex", justifyContent: "space-between", width: "280px", alignItems: 'center' }
                            : { display: "flex", justifyContent: "flex-end", width: "280px", alignItems: 'center' }}>
                            {
                                this.state.isfilterset ?
                                    <div class="btn btn-warning" style={{
                                        color: "#fff", backgroundColor: 'red', width: '40%',
                                        textAlign: 'center', height: '30px',
                                        fontSize: '18px',
                                        display: 'flex',
                                        justifyContent: 'center'
                                    }} onClick={() => this.clearFilters()}>Clear Filters</div>
                                    :
                                    ""
                            }
                            <div id='applyfilter' className="btn btn-warning"
                                style={{
                                    color: "#fff", backgroundColor: 'yellowgreen', width: '40%',
                                    textAlign: 'center', height: '30px',
                                    fontSize: '18px',
                                    display: 'flex',
                                    justifyContent: 'center'
                                }}
                                onClick={() => this.applyFilters()}
                            >Apply Filters</div>
                            <div>
                                <Tooltip title="Close Filter">
                                    <IconButton onClick={() => this.setState({ isOpenModal: false })}>
                                        <CloseIcon style={{ color: 'black' }} />
                                    </IconButton>
                                </Tooltip>
                            </div>
                        </div>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <div className="col-3">
                                <div className='row filternames' >
                                    {
                                        this.state.originalfilterData != null ?
                                            this.state.originalfilterData.map((data, index) => {
                                                return (
                                                    <div key={index}
                                                        // className='leftitems'
                                                        className={'leftitems' + (data.filtername == this.state.filtername ? " active" : '')}
                                                        //  className={'col-12' + (data.filtername == this.state.filtername ? " active" : '')}
                                                        style={{
                                                            marginTop: '5%', marginLeft: '6%', fontSize: '18px', height: '40px',
                                                            paddingLeft: '10%', paddingTop: '2%', overflow: "hidden", borderRadius: '3px'
                                                        }}
                                                        onClick={() => this.setFilter(data)}>{data.filtername}</div>
                                                )
                                            })
                                            : ""
                                    }
                                </div>
                            </div>
                            <div className="col-8" style={{ maxHeight: "400px", overflow: "hidden", marginLeft: '7%' }}>
                                {
                                    this.state.filterData != null ?
                                        this.state.filterData.filtertype == 1003 ?
                                            <Thousandthree filterData={this.state.defaultValue[0].values[this.state.filterData.filtername]} defdata={this.state.defaultValue[0].def[this.state.filterData.filtername]}
                                                perdata={this.state.defaultValue[0].oldvalues[this.state.filterData.filtername]}
                                                filterSelected={this.state.filterData.filtername}
                                                filteredData={this.state.defaultValue}
                                                onChange={(val, checked) => this.updateDefaultvalues(val, checked)}
                                            />
                                            : this.state.filterData.filtertype == 1002 ?
                                                <Thousandtwo filterData={this.state.defaultValue[0].values[this.state.filterData.filtername]} defdata={this.state.defaultValue[0].def[this.state.filterData.filtername]}
                                                    onChange={(val) => this.updateDefaultvalues(val)} />
                                                : ""
                                        : ""
                                }
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
                <Modal id={'search_options_modal'} size="md" centered style={{ backgroundColor: 'rgba(0,0,0,0.6)' }} show={this.state.ShowPageCount} onHide={() => this.setState({ ShowPageCount: false })}>
                    <Modal.Body style={{ padding: "10px" }}>
                        <div style={{display:'flex', justifyContent:'center', alignItems:'center', minHeight:'100px'}}>
                            <b>Count:</b>&nbsp;&nbsp;
                            <span>{this.state.pageCount}</span>
                        </div>
                    </Modal.Body>
                </Modal>
            </>

        )
    }
}
