import React, { Component } from 'react'
import { Modal, Spinner } from 'react-bootstrap'
import './support.css'
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Hexapi from '../../usefulcomps/HexAPI/Hexapi';
import Swal from 'sweetalert2';
export default class Support extends Component {
    constructor() {
        super()
        this.state = {
            isComponentRender: false, openInstantSupport: false, queryError: ''
        }
    }
    componentDidMount() {
        this.setState({ isComponentRender: this.props.openModal })
    }

    callBack() {
        if(this.props.userDetails.contactnumber != null){
            this.setState({ isLoadingModalOpen: true })
            let obj = {
                'query': `[dbo].[Lawcrux_Web_Proc_HelpDesk_Submit]
                @UserID='{0}',
                @ContactNumber='{1}',
                @Name='{2}'`,
                'queryArr': [`${this.props.userDetails.recid}`, `${this.props.userDetails.contactnumber}`, `${this.props.userDetails.username}`]
            }
            Hexapi(obj).then(resp => {
                console.log(resp)
                if (resp[''][0].command == '1') {
                    Swal.fire({
                        html: resp[''][0].msg,
                        allowOutsideClick: false,
                        confirmButtonColor: 'var(--Primary)',
                    }).then(re => {
                        this.props.closeModal()
                    })
                } else {
                    this.setState({ openInstantSupport: true })
                }
    
            }).then(() => this.setState({ isLoadingModalOpen: false }))
        }else{
            Swal.fire({
                html: 'Phone number not available.',
                allowOutsideClick: false,
                confirmButtonColor: 'var(--Primary)',
            }).then(re => {
                // this.props.closeModal()
            })
        }
    }
    render() {
        return (
            <>
                {
                    this.state.isComponentRender &&
                    <Modal centered id="supportModal" animation={true} show={true} onHide={() => this.props.closeModal()} style={{ backgroundColor: 'rgba(0,0,0,0.6)' }} backdrop="static" keyboard={false}>
                        <Modal.Header>
                            <div className="header">
                                <span></span>
                                <IconButton onClick={() => this.props.closeModal()}>
                                    <CloseIcon />
                                </IconButton>
                            </div>
                        </Modal.Header>
                        <Modal.Body>
                            <div style={{ height: "auto", padding: '10px' }}>
                                <div className="descrition">
                                    <span>We will call you at <b>{this.props.userDetails.contactnumber != null ? this.props.userDetails.contactnumber : "Your Phone Number"}</b> within 24 Hours.
                                        If your phone number is not updated please update through <b>My Account</b></span>
                                </div>
                                <div className="buttons">
                                    <span onClick={() => this.setState({ openInstantSupport: true })}>Instant Support</span>
                                    <span onClick={() => this.callBack()}>Request a Call Back</span>
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>
                }

                <Modal centered id="supportModal" animation={true} show={this.state.openInstantSupport} onHide={() => this.setState({ openInstantSupport: false })} style={{ backgroundColor: 'rgba(0,0,0,0.6)' }}>
                    <Modal.Header>
                        <div className="header">
                            <span></span>
                            <IconButton onClick={() => this.setState({ openInstantSupport: false })}>
                                <CloseIcon />
                            </IconButton>
                        </div>
                    </Modal.Header>
                    <Modal.Body>
                        <div style={{ minHeight: "100px", display: 'flex', alignItems: "center", textAlign: "center", padding: '10px' }}>
                            <span style={{ fontSize: "18px" }}>Call/Whatsapp at <b>9212336582</b> or Mail us at <a href="mailto:support@lawcrux.com">support@lawcrux.com</a></span>
                        </div>
                    </Modal.Body>
                </Modal>


                <Modal centered size="sm" id="loadingModal" animation={true} show={this.state.isLoadingModalOpen} onHide={() => this.setState({ isLoadingModalOpen: false })} style={{ backgroundColor: 'rgba(0,0,0,0.6)' }} backdrop="static" keyboard={false}>
                    <Modal.Body>
                        <div style={{ display: 'flex', alignItems: 'center', padding: "8px", paddingBottom: "16px" }}>
                            <Spinner variant='dark' animation='border' />
                            <span style={{ paddingLeft: "20px" }}>{this.state.queryError}</span>
                            <span style={{ paddingLeft: "20px" }}>Loading...</span>
                        </div>
                    </Modal.Body>
                </Modal>
            </>
        )
    }
}
