import React, { Component } from 'react'
import { Modal, Spinner } from 'react-bootstrap'
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Aesencryption } from '../../usefulcomps/Aes/Aes';
import axios from 'axios';

export default class Summary extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isComponentRender: false, url: null, header: '',
        }
    }
    componentDidMount() {
        this.setState({ isComponentRender: false })
        const FetchFile = (path) => new Promise((resolve, reject) => {
            var fd = new FormData();
            fd.append(Aesencryption('filename'), Aesencryption(path))
            axios({
                mode: 'cors',
                method: "POST",
                headers: { "Content-Type": "multipart/form-data" },
                url: `${sessionStorage.getItem('url')}fetchfile.php`,
                data: fd
            }).then(resp => {
                console.log(resp)
                resolve(resp.data)
            }).catch(err => reject(err))
        })
        let url = null
        let summarydata = this.props.summaryData
        if (summarydata != null) {
            let mainfolder = summarydata.foldername.substring(0, 2);
            let subfolder = summarydata.foldername.substring(2);
            let filename = summarydata.filename
            console.log(filename, mainfolder, subfolder)
            url = sessionStorage.getItem('url') + 'Assets/Data5t/' + mainfolder + '/' + subfolder + '/' + filename
            const isPPTX = (filename) => {
                return filename.substring(filename.lastIndexOf('.')).includes('x')
            }
            const is_ = (filename) => {
                // console.log(filename)
                return filename.includes('_')
            }

            console.log(isPPTX(filename), is_(filename))

            let breakRecursion = []
            const recursion = (filename) => {
                // let isFound = false
                breakRecursion.push(filename)
                let url = sessionStorage.getItem('url') + 'Assets/Data5t/' + mainfolder + '/' + subfolder + '/' + filename
                if (isPPTX(filename) && is_(filename)) {
                    console.log('1 => : ', filename)
                    FetchFile(url).then(resp => {
                        if (resp == "") {
                            let newfilename = filename.split('.')[0] + '.ppt'
                            if(breakRecursion.length < 5){
                                recursion(newfilename)
                            }
                        } else {
                            this.setState({ url: url, header: this.props.header != undefined ? this.props.header : 'Summary', isComponentRender: true })
                        }
                    })
                } else if (isPPTX(filename) && !is_(filename)) {
                    console.log('2 => : ', filename)
                    FetchFile(url).then(resp => {
                        if (resp == "") {
                            let newfilename = filename.replaceAll('z', '_')
                            if(breakRecursion.length < 5){
                                recursion(newfilename)
                            }
                        } else {
                            this.setState({ url: url, header: this.props.header != undefined ? this.props.header : 'Summary', isComponentRender: true })
                        }
                    })
                } else if (!isPPTX(filename) && is_(filename)) {
                    console.log('3 => : ', filename)
                    FetchFile(url).then(resp => {
                        if (resp == "") {
                            let newfilename = filename.replaceAll('_', 'z')
                            if(breakRecursion.length < 5){
                                recursion(newfilename)
                            }
                        } else {
                            this.setState({ url: url, header: this.props.header != undefined ? this.props.header : 'Summary', isComponentRender: true })
                        }
                    })
                } else if (!isPPTX(filename) && !is_(filename)) {
                    console.log('4 => : ', filename)
                    FetchFile(url).then(resp => {
                        if (resp == "") {
                            let newfilename = filename.split('.')[0] + '.pptx'
                            if(breakRecursion.length < 5){
                                recursion(newfilename)
                            }
                        } else {
                            this.setState({ url: url, header: this.props.header != undefined ? this.props.header : 'Summary', isComponentRender: true })
                        }
                    })
                }
                // setTimeout(() => {
                //     if (isFound) {
                //         console.log(url)
                //         this.setState({ url: url, header: this.props.header != undefined ? this.props.header : 'Summary', isComponentRender: true })
                //     }
                //     console.log(isFound, url)
                // }, 5000);
            }
            recursion(filename)
            // if (isPPTX(filename)) {
            //     FetchFile(url).then(resp1 => {
            //         if (resp1 != "") {
            //             this.setState({ url: url, header: this.props.header != undefined ? this.props.header : 'Summary', isComponentRender: true })
            //         } else {
            //             // this.setState({ url: sessionStorage.getItem('url') + 'Assets/Data5t/' + mainfolder + '/' + subfolder + '/' + filename.replace('_','z')})
            //             // this.setState({header: this.props.header != undefined ? this.props.header : 'Summary', isComponentRender: true })
            //             if (is_(filename)) {
            //                 let url2 = sessionStorage.getItem('url') + 'Assets/Data5t/' + mainfolder + '/' + subfolder + '/' + filename.replace('_', 'z')
            //                 FetchFile(url).then(resp2 => {
            //                     if (resp2 != "") {
            //                         this.setState({ url: url, header: this.props.header != undefined ? this.props.header : 'Summary', isComponentRender: true })
            //                     }
            //                 })
            //             }
            //         }
            //     })
            // } else if (is_(filename)) {

            // } else if (isz(filename)) {

            // } else {
            //     console.table(filename)
            // }
            // if(isPPTX(filename)){
            //     if(is_(filename)){
            // FetchFile(url).then(resp1 => {
            //     if(resp1 != ""){
            //         this.setState({ url: url })
            //         this.setState({header: this.props.header != undefined ? this.props.header : 'Summary', isComponentRender: true })
            //     }else{
            //         this.setState({ url: sessionStorage.getItem('url') + 'Assets/Data5t/' + mainfolder + '/' + subfolder + '/' + filename.replace('_','z')})
            //         this.setState({header: this.props.header != undefined ? this.props.header : 'Summary', isComponentRender: true })
            //     }
            // })
            //     }else{
            //         FetchFile(url).then(resp1 => {
            //             if(resp1 != ""){
            //                 this.setState({ url: url })
            //                 this.setState({header: this.props.header != undefined ? this.props.header : 'Summary', isComponentRender: true })
            //             }else{
            //                 this.setState({ url: sessionStorage.getItem('url') + 'Assets/Data5t/' + mainfolder + '/' + subfolder + '/' + filename.replace('z','_')})
            //                 this.setState({header: this.props.header != undefined ? this.props.header : 'Summary', isComponentRender: true })
            //             }
            //         })
            //     }
            // }else{
            //     if(is_(filename)){
            //         FetchFile(url).then(resp1 => {
            //             if(resp1 != ""){
            //                 this.setState({ url: url })
            //                 this.setState({header: this.props.header != undefined ? this.props.header : 'Summary', isComponentRender: true })
            //             }else{
            //                 this.setState({ url: sessionStorage.getItem('url') + 'Assets/Data5t/' + mainfolder + '/' + subfolder + '/' + filename.replace('_','z')})
            //                 this.setState({header: this.props.header != undefined ? this.props.header : 'Summary', isComponentRender: true })
            //             }
            //         })
            //     }else{
            //         FetchFile(url).then(resp1 => {
            //             if(resp1 != ""){
            //                 this.setState({ url: url })
            //                 this.setState({header: this.props.header != undefined ? this.props.header : 'Summary', isComponentRender: true })
            //             }else{
            //                 this.setState({ url: sessionStorage.getItem('url') + 'Assets/Data5t/' + mainfolder + '/' + subfolder + '/' + filename.replace('z','_')})
            //                 this.setState({header: this.props.header != undefined ? this.props.header : 'Summary', isComponentRender: true })
            //             }
            //         })
            //     }
            // }
            // FetchFile(url).then(resp1 => {
            //     if (resp1 == "") {
            //         if(isPPTX(filename)){
            //             let newfilename = filename.split('.')[0] + '.ppt'
            //             let file2 = sessionStorage.getItem('url') + 'Assets/Data5t/' + mainfolder + '/' + subfolder + '/' + newfilename
            //             FetchFile(file2).then(resp2 => {
            //                 if(resp2 == ""){
            //                     if(is_(newfilename)){
            //                         newfilename = newfilename.rep
            //                     }
            //                 }else{
            //                     this.setState({ url: file2 })
            //                 }
            //             })
            //         }
            //         // if (filename.substring(filename.lastIndexOf('.')).includes('x')) {
            //         //     let fname = filename.split('.')[0] + '.ppt'
            //         //     let filepath = sessionStorage.getItem('url') + 'Assets/Data5t/' + mainfolder + '/' + subfolder + '/' + fname
            //         //     FetchFile(filepath).then(resp => {
            //         //         if (resp == "") {   
            //         //             let newFilepath = filepath.replaceAll('_', "z")
            //         //             FetchFile(newFilepath).then(resp => {
            //         //                 if (resp == "") {   
            //         //                     this.setState({url : filepath})
            //         //                     // let newFilepath = filepath.replaceAll('_', "z")
            //         //                 } else {
            //         //                     this.setState({ url: newFilepath })
            //         //                 }
            //         //             })
            //         //         } else {
            //         //             this.setState({ url: filepath })
            //         //         }
            //         //     })
            //         //     // this.setState({ url: sessionStorage.getItem('url') + 'Assets/Data5t/' + mainfolder + '/' + subfolder + '/' + fname })
            //         // } else {
            //         //     let path = url + 'x'
            //         //     FetchFile(path).then(resp => {
            //         //         if (resp == "") {
            //         //             let path = sessionStorage.getItem('url') + 'Assets/Data5t/' + mainfolder + '/' + subfolder + '/' + filename.replaceAll('_', "z")
            //         //             // this.setState({ url: path })
            //         //             FetchFile(path).then(resp => {
            //         //                 if (resp == "") {

            //         //                 } else {
            //         //                     this.setState({ url: path })
            //         //                 }
            //         //             })
            //         //         } else {
            //         //             this.setState({ url: path })
            //         //         }
            //         //     })
            //         // }
            //     } else {
            //         this.setState({ url: url })
            //     }
            // }).catch(err => console.error(err))
            // this.setState({header: this.props.header != undefined ? this.props.header : 'Summary', isComponentRender: true })
            // }).catch(err => console.error(err))
            // console.log(url)
        }
        // console.log(summarydata)
        // console.log(this.props.header)

    }
    render() {

        return (
            <>
                {
                    this.state.isComponentRender ?
                        <Modal fullscreen={true} id="pptModal" animation={true} show={true} onHide={() => this.props.onCloseModal()}>
                            <Modal.Body>
                                <div className="navbar" style={{ backgroundColor: "#fff",height:'0px' }}>
                                    <div className="leftdiv">
                                        <Tooltip title="Go back">
                                            <IconButton onClick={() => this.props.onCloseModal()}>
                                                <ArrowBackIcon />
                                            </IconButton>
                                        </Tooltip>
                                        <span style={{ padding: "0px 10px", fontSize: "18px", fontWeight: "600" }}>{this.state.header}</span>
                                    </div>
                                </div>
                                <div className="ppt_viewer_box">
                                    <div unselectable='on' className="footer" id="ppt_footer"></div>
                                    {
                                        this.state.url != null &&
                                        <iframe id='pptFrame' onLoad={() => {
                                            setTimeout(() => {
                                                document.getElementById('ppt_footer').style.display = "block"
                                            }, 1000)
                                        }} src={`https://view.officeapps.live.com/op/embed.aspx?src=${this.state.url}`} width='100%' height='100%'></iframe>
                                    }
                                </div>
                            </Modal.Body>
                        </Modal>
                        :
                        <Modal centered size="sm" id="pptModal" animation={true} show={true}>
                            <Modal.Body>
                                <div className="timer" style={{ display: 'flex', justifyContent: 'center', alignItems: "center", flexDirection: "column", minHeight: "15vh" }}>
                                    <Spinner animation="border" variant="dark" />
                                </div>
                            </Modal.Body>
                        </Modal>
                }
            </>
        )
    }
}
