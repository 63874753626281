import React, { Component } from 'react'
import MyAccountIcon from '../images/account.png'
import SearchIcon from '../images/magnifier.png'
import currentLaw from '../images/current_law.png'
import oldLaw from '../images/old_law.png'
import flowChart from '../images/flow_chart.png'
import questionBank from '../images/question_bank.png'
import specimen from '../images/specimen.png'
import alliedAct from '../images/allied_act.png'
import caseLaw from '../images/case_law.png'
import form from '../images/form.png'
import gstRates from '../images/gst_rates.png'
import practiceProcedure from '../images/practice_procedure.png'
import advanceRuling from '../images/advance_ruling.png'
import LogoutIcon from '../images/logout.png'
import SupportIcon from '../images/support.png'
import Currentlaw from './subcomps/Currentlaw'
import Oldlaw from './subcomps/Oldlaw'
import FlowChart from './subcomps/FlowChart'
import QuestionBank from './subcomps/QuestionBank'
import AlliedAct from './subcomps/AlliedAct'
import Caselaw from './subcomps/Caselaw'
import Form from './subcomps/Form'
import GSTRates from './subcomps/GSTRates'
import PraciceNprocedure from './subcomps/PraciceNprocedure'
import AdvanceRuling from './subcomps/AdvanceRuling'
import './style.css'
import MyaccountTable from './subcomps/MyaccountTable';
// import Searched from './subcomps/Searched'
import buildNumber from '../buildNumber.json'
import { Aesencryption } from '../usefulcomps/Aes/Aes'
import Specimen from './subcomps/Specimen'
import Tooltip from '@mui/material/Tooltip';
import { confirmAlert } from 'react-confirm-alert'
import Support from './subcomps/Support'
import Hexapi from '../usefulcomps/HexAPI/Hexapi'
import Snackbar from '@mui/material/Snackbar';
import SignalWifi4BarIcon from '@mui/icons-material/SignalWifi4Bar';
import { Modal, Spinner } from 'react-bootstrap'
import ContentFrame from './subcomps/ContentFrame';
import './Arrow.css';
import Demo from './Demo'

export default class Home extends Component {
  constructor() {
    super()
    this.state = {
      isMyAccountClicked: false, isSearchClicked: false, isCurrentLawClicked: false, openBudget: false,
      isOldLawClicked: false, isFlowChartClicked: false, isQuestionBankClicked: false,
      isAlliedActClicked: false, isCaseLawClicked: false, isFormClicked: false,
      isPracticeNProcedureClicked: false, isSpecimenClicked: false, cont: true,
      isGSTRatesClicked: false, isAdvanceRulingClicked: false, isLoadingModalOpen: false,
      step: true,
      userid: sessionStorage.getItem('userid'),
      remaininglogincount: '0',
      searchApi: sessionStorage.getItem('searchapi'),
      rootUrl: sessionStorage.getItem('url'),
      isOpenSupport: false, userDetails: [],
      // Online state
      isOnline: navigator.onLine,
      HomeOptions: [
        {
          name: "Current Law",
          icon: currentLaw
        },
        {
          name: "Old Law",
          icon: oldLaw
        },
        {
          name: "Flow Chart",
          icon: flowChart
        },
        {
          name: "Question Bank",
          icon: questionBank
        },
        {
          name: "Specimen",
          icon: specimen
        },
        {
          name: "Allied Act",
          icon: alliedAct
        },
        {
          name: "Case Law",
          icon: caseLaw
        },
        {
          name: "Advance Ruling",
          icon: advanceRuling
        },
        {
          name: "Form",
          icon: form
        },
        {
          name: "GST Rates",
          icon: gstRates
        },
        {
          name: "Practice & Procedure",
          icon: practiceProcedure
        },
        {
          name: "My Account",
          icon: MyAccountIcon
        },
        // {
        //   name: "Logout",
        //   icon: LogoutIcon
        // }

      ]
    }
    this.openPage = this.openPage.bind(this)
  }

  confirmBox() {
    confirmAlert({
      message: 'Are you sure you want to logout?',
      buttons: [
        {
          label: 'No',
          onClick: () => {
            // window.location.hash = "#Home"
            // this.addActiveClass("Home", Home, window.location.hash, 0)
            // this.setContentBox("Home")
          }
        },
        {
          label: 'Yes',
          onClick: () => {
            this.setState({ isLoadingModalOpen: true })
            let obj = {
              query: `[dbo].[Lawcrux_Web_Proc_User_Logout] @UserID = '{0}'`,
              queryArr: [`${this.state.userid}`]
            }
            Hexapi(obj).then(resp => {
              console.log(resp)
              this.setState({ isLoadingModalOpen: false })
              localStorage.clear()
            }).then(() => window.top.location.href = `${sessionStorage.getItem('Apipathurl')}login`)// 
              .catch(err => console.error(err))
          }
        }
      ]
    })
  }
  openPage(list) {
    if (list.name === "My Account") {
      if (this.state.userid == 1) {
        this.setState({ isMyAccountClicked: true })
      } else {
        let queryParameters = Aesencryption(`sessionuserid=${this.state.userid}&userid=${this.state.userid}&apipathurl=${sessionStorage.getItem('Apipathurl')}`)
        window.top.location.href = `${sessionStorage.getItem('url')}memberform?${queryParameters}`
      }
    } else if (list.name === 'Current Law') {
      this.setState({ isCurrentLawClicked: true })
    } else if (list.name === 'Old Law') {
      this.setState({ isOldLawClicked: true })
    } else if (list.name === 'Flow Chart') {
      this.setState({ isFlowChartClicked: true })
    } else if (list.name === 'Question Bank') {
      this.setState({ isQuestionBankClicked: true })
    } else if (list.name === 'Specimen') {
      this.setState({ isSpecimenClicked: true })
    } else if (list.name === 'Allied Act') {
      this.setState({ isAlliedActClicked: true })
    } else if (list.name === 'Case Law') {
      this.setState({ isCaseLawClicked: true })
    } else if (list.name === 'Form') {
      this.setState({ isFormClicked: true })
    } else if (list.name === 'GST Rates') {
      this.setState({ isGSTRatesClicked: true })
    } else if (list.name === 'Practice & Procedure') {
      this.setState({ isPracticeNProcedureClicked: true })
    } else if (list.name === 'Advance Ruling') {
      this.setState({ isAdvanceRulingClicked: true })
    }
    // else if (list.name == 'Logout') {
    //   window.top.location.href = `${sessionStorage.getItem('Apipathurl')}login.php`
    // }
  }


  checkLoginStatus() {
    console.log('checkLoginStatus is calling')
    let obj = {
      query: `[dbo].[Lawcrux_Web_Proc_User_Validation]
      @Details='{0}'`,
      queryArr: [`${localStorage.getItem('auth_lawcrux_id')}`]
    }
    Hexapi(obj).then(resp => {
      console.log(resp)
      if (resp[''][0].command == 0) {
        localStorage.clear()
        // window.top.location.href = `${sessionStorage.getItem('Apipathurl')}login`
      }
    })
  }
  componentDidMount() {

    // // Specify how to clean up after this effect for performance improvment
    // return () => {
    //   window.removeEventListener('online', handleStatusChange);
    //   window.removeEventListener('offline', handleStatusChange);
    // };
    this.checkLoginStatus()
    // ===================================
    // detecting if a tab is active or not
    // ===================================
    var firstIntervalID = false
    var newIntervalID = false
    const getInfo = (isrun) => {
      if (isrun) {
        firstIntervalID = setInterval(() => {
          // console.log('firsttimecalling')
          this.checkLoginStatus()
        }, 30000);
      } else {
        newIntervalID = setInterval(() => {
          // console.log('newintervalcalling')
          this.checkLoginStatus()
        }, 30000);
      }
    }

    getInfo(0)

    document.addEventListener("visibilitychange", (event) => {
      event.preventDefault()
      if (document.visibilityState != "visible") {
        console.log("tab is inactive", new Date())
        clearInterval(firstIntervalID)
        clearInterval(newIntervalID)
      } else {
        console.log("tab is active", new Date())
        this.checkLoginStatus()
        getInfo(0)
      }
    });
    var obj = {
      'query': `[dbo].[Lawcrux_Web_Proc_Homescreen_GetData] @UserID='{0}'`,// @RecID='{0}'
      'queryArr': [`${this.state.userid}`]
    }
    Hexapi(obj).then(resp => {
      console.log(resp)
      if (resp[''].length > 0) {
        this.setState({ remaininglogincount: resp[''][0].remaininglogins })
      }
    })
    // window.onbeforeunload = function() { return "Your work will be lost."; };
    window.addEventListener("beforeunload", this.handleBeforeUnload.bind(this));
    // return () => {
    //   window.removeEventListener("beforeunload", this.handleBeforeUnload.bind(this));
    // };
    obj = {
      'query': `[dbo].[Lawcrux_Web_Proc_EditUser_GetFormData] @RecID='{0}'`,// @RecID='{0}'
      'queryArr': [`${this.state.userid}`]
    }
    Hexapi(obj).then(resp => {
      console.log(resp)
      if (resp[''].length > 0) {
        this.setState({ userDetails: resp[''][0] })
      }
    }).then(() => {

      let obj = {
        url: 'findcss.php'
      }
      Hexapi(obj).then(resp => {
        console.log(resp)
        resp.forEach((obj) => {
          // document.head.insertAdjacentHTML('beforeend', newHeadContent);
          var link = document.createElement('link');
          link.href = sessionStorage.getItem('url') + obj.filepath;
          link.rel = 'stylesheet'
          document.head.appendChild(link);
        })
        // 
      })
    })
  }

  handleBeforeUnload = (e) => {
    e.preventDefault();
    const message =
      "Are you sure you want to leave? All provided data will be lost.";
    e.returnValue = message;
    return message;
  };
  // Update network status
  handleStatusChange = () => {
    this.setState({ isOnline: navigator.onLine });
  };

  reconnectInternet = () => {
    setTimeout(() => {
      this.setState({ loading: false })
    }, 5000);
  }

  showarrow = () => {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="63" height="63" fill="currentColor" class="bi bi-arrow-right animate" viewBox="0 0 16 16" id="IconChangeColor">
        <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z" id="mainIconPathAttribute" stroke-width="2" stroke="#ff0000"></path>
      </svg>
    )
  }

  changestep=()=>{
    this.setState({step:!this.state.step});
  }

  render() {
    console.log(`LawCRUX Home : ${buildNumber.buildNumber}`)
    // console.log(Aesencryption('allfiles'))
    // setTimeout(() => {
    //   window.history.forward()
    // }, 0);
    // window.onunload = function () { null };
    // window.onbeforeunload = function() { return "Your work will be lost."; };
    // window.location.hash = "no-back-button";

    // // Again because Google Chrome doesn't insert
    // // the first hash into the history
    // window.location.hash = "Again-No-back-button"; 

    // window.onhashchange = function(){
    //     window.location.hash = "no-back-button";
    // }


    // Listen to the online status
    window.addEventListener('online', this.handleStatusChange);
    // Listen to the offline status
    window.addEventListener('offline', this.handleStatusChange);
    console.log(this.state.isOnline)
    return (
      <>

        {/* {this.state.step == true ? <Demo step={this.state.step} changestep={this.changestep} /> : */}
          <div className="container-fluid" style={{ padding: '0px', overflow: "hidden" }}>
            {
              this.state.isMyAccountClicked ?
                <MyaccountTable
                  goBack={() => this.setState({ isMyAccountClicked: false })}
                  userid={this.state.userid}
                />
                :
                this.state.isCurrentLawClicked ?
                  <Currentlaw
                    goBack={() => this.setState({ isCurrentLawClicked: false })}
                    searchApi={this.state.searchApi}
                    rootUrl={this.state.rootUrl}
                    userid={this.state.userid}
                  />
                  :
                  // this.state.cont && this.showarrow() &&
                  this.state.isOldLawClicked ?
                    <Oldlaw
                      goBack={() => this.setState({ isOldLawClicked: false })}
                      searchApi={this.state.searchApi}
                      rootUrl={this.state.rootUrl}
                      userid={this.state.userid}
                    />
                    :
                    this.state.isFlowChartClicked ?
                      <FlowChart
                        goBack={() => this.setState({ isFlowChartClicked: false })}
                        searchApi={this.state.searchApi}
                        rootUrl={this.state.rootUrl}
                        userid={this.state.userid}
                      />
                      :
                      this.state.isQuestionBankClicked ?
                        <QuestionBank
                          goBack={() => this.setState({ isQuestionBankClicked: false })}
                          searchApi={this.state.searchApi}
                          rootUrl={this.state.rootUrl}
                          userid={this.state.userid}
                          directShow={this.state.isQuestionBankClicked}
                        />
                        :
                        this.state.isSpecimenClicked ?
                          <Specimen
                            goBack={() => this.setState({ isSpecimenClicked: false })}
                            searchApi={this.state.searchApi}
                            rootUrl={this.state.rootUrl}
                            userid={this.state.userid}
                            directShow={this.state.isSpecimenClicked}
                          />
                          :
                          this.state.isAlliedActClicked ?
                            <AlliedAct
                              goBack={() => this.setState({ isAlliedActClicked: false })}
                              searchApi={this.state.searchApi}
                              rootUrl={this.state.rootUrl}
                              userid={this.state.userid}
                            />
                            :
                            this.state.isCaseLawClicked ?
                              <Caselaw
                                goBack={() => this.setState({ isCaseLawClicked: false })}
                                searchApi={this.state.searchApi}
                                rootUrl={this.state.rootUrl}
                                userid={this.state.userid}
                              />
                              :
                              this.state.isFormClicked ?
                                <Form
                                  goBack={() => this.setState({ isFormClicked: false })}
                                  searchApi={this.state.searchApi}
                                  rootUrl={this.state.rootUrl}
                                  userid={this.state.userid}
                                />
                                :
                                this.state.isGSTRatesClicked ?
                                  <GSTRates
                                    goBack={() => this.setState({ isGSTRatesClicked: false })}
                                    searchApi={this.state.searchApi}
                                    rootUrl={this.state.rootUrl}
                                    userid={this.state.userid}
                                  />
                                  :
                                  this.state.isPracticeNProcedureClicked ?
                                    <PraciceNprocedure
                                      goBack={() => this.setState({ isPracticeNProcedureClicked: false })}
                                      searchApi={this.state.searchApi}
                                      rootUrl={this.state.rootUrl}
                                      userid={this.state.userid}
                                    />
                                    :
                                    this.state.isAdvanceRulingClicked ?

                                      <AdvanceRuling
                                        goBack={() => this.setState({ isAdvanceRulingClicked: false })}
                                        searchApi={this.state.searchApi}
                                        rootUrl={this.state.rootUrl}
                                        userid={this.state.userid}
                                      />
                                      :
                                      <div className="row">
                                        {
                                          this.state.HomeOptions.map((list, index) => {
                                            return (
                                              <div className="col-md-3 col-6 HomeOuterBox" key={list.name}>
                                                <div className='HomeTile' onClick={() => this.openPage(list)}>
                                                  <img src={list.icon} alt="" />
                                                  <span>{list.name}</span>
                                                </div>
                                              </div>
                                            )
                                          })
                                        }
                                      </div>
            }
            {
              this.state.openBudget ?
                <ContentFrame
                  goBack={() => this.setState({ openBudget: false })}
                  filepath={'Assets/Data5t/ft/ftpoli/index.html'}
                  searchApi={this.state.searchApi}
                  rootUrl={this.state.rootUrl}
                  userid={this.state.userid}
                  MainFolder={'ft'}
                  SubFolder={'ftpoli'}
                  goHome={() => this.setState({ openBudget: false })}
                />
                : null
            }
            <div className='budget'>
              <u><a style={{ color: 'blue' }} onClick={() => this.setState({ openBudget: true })}>Foreign Trade Policy 2023</a></u>
            </div>
            <div className='logincount'>
              <p style={{ fontWeight: 700 }}>Remaining login count :- {this.state.remaininglogincount}</p>
            </div>
            {/* {this.state.cont && this.showarrow()} */}
            <Tooltip title="Logout" >
              <div className='logoutBtn' onClick={() => this.confirmBox()}>
                <img src={LogoutIcon} alt="" />
              </div>
            </Tooltip >
            <Tooltip title="Help" >
              <div className='supportBtn' onClick={() => this.setState({ isOpenSupport: true })}>
                <img src={SupportIcon} alt="" />
              </div>
            </Tooltip >


            {
              this.state.isOpenSupport &&
              <Support
                openModal={this.state.isOpenSupport}
                closeModal={() => this.setState({ isOpenSupport: false })}
                userDetails={this.state.userDetails}
              />
            }
            <Snackbar
              anchorOrigin={{ vertical: 'center', horizontal: 'center' }}
              // open={true}
              open={!this.state.isOnline}
              // onClose={handleClose}
              message={
                <>
                  {
                    this.state.loading ?
                      <div>
                        <SignalWifi4BarIcon /> &nbsp;
                        <span>Connecting...</span>
                      </div>
                      :
                      <>
                        <h4>Internet not connected</h4>
                        <span>Make sure your device has an active internet connection.</span>
                        <div className='reconnectBtn'>
                          <span onClick={() => this.setState({ loading: true }, () => this.reconnectInternet())}>Reconnect</span>
                        </div>
                      </>
                  }

                </>
              }
            />
            <Modal centered size="sm" id="loadingModal" animation={true} show={this.state.isLoadingModalOpen} onHide={() => this.setState({ isLoadingModalOpen: false })} style={{ backgroundColor: 'rgba(0,0,0,0.6)' }} backdrop="static" keyboard={false}>
              <Modal.Body>
                <div style={{ display: 'flex', alignItems: 'center', padding: "8px 8px 16px 16px", height: "10vh" }}>
                  <Spinner variant='dark' animation='border' />
                  <span style={{ paddingLeft: "20px" }}>Loading...</span>
                </div>
              </Modal.Body>
            </Modal>
          </div>
        {/* } */}
      </>
    )
  }

}
