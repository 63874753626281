import React, { Component } from 'react'
// import Classes from './style.module.css'
export default class Thousandtwo extends Component {
    constructor(props) {
        super(props)
        const getValues = (props) => {
            console.log(this.props.filterData)
            var values = this.props.filterData
            if(values.includes(',')){
                if (values.indexOf(',') > -1) {
                    var arr = values.split(',')
                    return arr
                }
            }else{
                var arr = []
                arr.push(values)
                return arr
            }
            
        }
        this.state = {
            defVal: this.props.defdata,
            value:getValues()
        }
    }
    componentDidUpdate(preProps){
        if(preProps.filterData != this.props.filterData){
            const getdefValues = (data) => {
                console.log(data)
                var values = data
                if(values.includes(',')){
                    if (values.indexOf(',') > -1) {
                        var arr = values.split(',')
                        return arr
                    }
                }else{
                    var arr = []
                    arr.push(values)
                    console.log(arr)
                    return arr
                }
                
            }
            this.setState({value : getdefValues(this.props.filterData),defVal:this.props.defdata})
        }
    }
    storeValue(e){
        this.setState({defVal:e.target.value})
        this.props.onChange(e.target.value)
    }
    render() {
        console.log(this.state)
        const getID = (val) => {
            if (val.indexOf(' ') > -1) {
                return val.replace(' ','') + (new Date()).getTime()
            }else{
                return val + (new Date()).getTime()
            }
            
        }
        return (
            <>
            <div 
            // className={Classes.overflow}
             style={{maxHeight:"370px",overflow:"auto"}}>
                {   
                    this.state.value.map((val,index)=>{
                        var check = false
                        if(val == this.state.defVal){
                            console.log(val)
                            check = true
                        }
                        return (
                            <>
                            <div key={index} style={{display:"flex",alignItems:"center",marginBottom:"5px"}}>
                                <input key={index} type="radio" id={getID(val)} name={val} value={val} onChange={(e) => this.storeValue(e)} checked={check} style={{width:"20px",height:"20px"}}/>
                                <label htmlFor={getID(val)} style={{paddingLeft:"10px",cursor:'pointer'}}>{val}</label> <br />
                            </div>
                            </>
                        )
                    })
                    
                }
            </div>
            </>        
        )
    }
}