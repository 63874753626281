import React, { Component } from 'react'
import { Modal, Spinner } from 'react-bootstrap'
export default class Hexcountdown extends Component {
    constructor() {
        super();
        this.state = { time: {}, seconds: 10, isOnline: navigator.onLine };
        this.timer = 0;
        this.startTimer = this.startTimer.bind(this);
        this.countDown = this.countDown.bind(this);
        this.renderer = this.renderer.bind(this);
    }
    handleStatusChange = () => {
        this.setState({ isOnline: navigator.onLine })
    }
    secondsToTime(secs) {
        let hours = Math.floor(secs / (60 * 60));

        let divisor_for_minutes = secs % (60 * 60);
        let minutes = Math.floor(divisor_for_minutes / 60);

        let divisor_for_seconds = divisor_for_minutes % 60;
        let seconds = Math.ceil(divisor_for_seconds);

        let obj = {
            "h": hours,
            "m": minutes,
            "s": seconds
        };
        return obj;
    }

    componentDidMount() {
        let timeLeftVar = this.secondsToTime(this.state.seconds);
        this.setState({ time: timeLeftVar }, () => this.startTimer());
    }

    startTimer() {
        if (this.timer == 0 && this.state.seconds > 0) {
            this.timer = setInterval(this.countDown, 1000);
        }
    }

    countDown() {
        // Remove one second, set state so a re-render happens.
        let seconds = this.state.seconds - 1;
        this.setState({
            time: this.secondsToTime(seconds),
            seconds: seconds,
        });

        // Check if we're at zero.
        if (seconds == 0) {
            clearInterval(this.timer);
        }
    }
    renderer = () => {
        console.log(this.state.time)
        if (this.state.time.s < 0) {
            if (this.state.isOnline) {
                // Render a completed state
                return (
                    <>
                        <span>Server is busy...Please wait</span>;
                        <br />
                        <span style={{ color: 'blue', cursor: "pointer" }} onClick={() => this.setState({ isRenderAgaing: true }, () => this.searchKeyword(this.state.search, this.state.searchPath))}>Retry</span>
                        &nbsp;Or&nbsp;
                        <span style={{ color: 'blue', cursor: "pointer" }} onClick={() => this.setState({ isDataFound: false, isTimerModal: false, firstTimeClick: true, openInitialModal: true, isRefinedSearch: false, targetField: '', targetSubField: '' })}>Cancel</span>
                    </>
                )
            } else {
                return <span>Internet Connetion Lost.</span>
            }
        } else {
            if (this.state.isOnline) {
                // Render a countdown
                return <span>Loading...{this.state.time.s}sec</span>;
            } else {
                return <span>Internet Connetion Lost.</span>
            }
        }
    };
    render() {
        // Listen to the online status
        window.addEventListener('online', this.handleStatusChange);
        // Listen to the offline status
        window.addEventListener('offline', this.handleStatusChange);
        return (
            <Modal scrollable={true} size="sm" centered style={{ backgroundColor: 'rgba(0,0,0,0.6)' }} animation={true} show={true} onHide={() => this.props.onHide()} keyboard={false} backdrop='static' >
                <Modal.Body>
                    <div className="timer" style={{ display: 'flex', justifyContent: 'center', alignItems: "center", flexDirection: "column", minHeight: "15vh" }}>
                        <Spinner animation="border" variant="dark" />
                        <span id="loadingTimer">
                            {this.renderer()}
                        </span>
                    </div>
                </Modal.Body>
            </Modal >
            // <div>
            //     <button onClick={this.startTimer}>Start</button>
            //     m: {this.state.time.m} s: {this.state.time.s}
            // </div>
        );
    }
}
