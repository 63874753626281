import React, { Component } from 'react'
import { Modal, Spinner } from 'react-bootstrap'
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import './clexclamation.css'
import Hexapi from '../../usefulcomps/HexAPI/Hexapi';
import Tooltip from '@mui/material/Tooltip';
export default class CaselawExclamation extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isComponentRender: false,
            isDataFound: false,
            isOpenCasesList: false,
            descriptionList: [], isCaseListFound: false,
            citedFilesList: [], referedFilesList: [],
            previous: '', next: '',bannercolor:'',currstatustext:''
        }
    }

    componentDidMount() {
        console.log(this.props)
        if(this.props.status != undefined && this.props.status.length !=0 && this.props.status.currstatus != undefined){
            if(this.props.status.currstatus[0][""] !=undefined && this.props.status.currstatus[0][""] !=null){
                if(this.props.status.currstatus[0][""].toLowerCase().includes('upheld')){
                    this.setState({bannercolor:'green',currstatus:this.props.status.currstatus[0][""]})
                }else if (this.props.status.currstatus[0][""].toLowerCase().includes('reversed')){
                    this.setState({bannercolor:'red',currstatus:this.props.status.currstatus[0][""]})
                }else if (this.props.status.currstatus[0][""].toLowerCase().includes('challenged')){
                    this.setState({bannercolor:'yellow',currstatus:this.props.status.currstatus[0][""]}) 
                }else{
                    this.setState({bannercolor:'black',currstatus:''}) 
                }
            }else{
                this.setState({bannercolor:'black'}) 
            }
        }
        // console.log('hello')
        // console.log(this.props.status)
        let filterStatus = (arr) => {
            return arr.map((el) => el[''])
        }
        // console.log(loop(this.props.status.referedin))
        // console.log(this.props.status.previous[0][""])
        this.setState({
            isComponentRender: true,
            isDataFound: true,
            citedFilesList: this.props.status.citedcases != undefined ? filterStatus(this.props.status.citedcases) : [],
            referedFilesList: this.props.status.referedin != undefined ? filterStatus(this.props.status.referedin) : [],
            previous: this.props.status.previous != undefined && this.props.status.previous[0][""] != null? filterStatus(this.props.status.previous) : [],
            next: this.props.status.next != undefined && this.props.status.next[0][""] != null? filterStatus(this.props.status.next) : []
        })
        // console.log(this.props)
    }

    onStatusClick(list, type) {
        console.log(list)
        const listarr = list.map((s, index) => {
            return s
        })
        console.log(listarr);
        this.setState({ isCaseListFound: false })
        let obj = {
            allfiles: listarr.join('##12##'),
            url: sessionStorage.getItem('gettitleurl')
        }
        Hexapi(obj).then(resp => {
            console.log(resp)
            let List = []
            listarr.forEach((el, i) => {
                let obj = {
                    description: resp[i],
                    filepath: el,
                    userid: this.props.userid
                }
                List.push(obj)
            })
            console.log(List)
            if (type == 1) {
                this.setState({ descriptionList: List, isOpenCasesList: true, isCaseListFound: true })
            } else {
                if(List[0] != undefined){
                    this.props.openFrame(List[0])
                }
                // this.setState({ descriptionList: List, isOpenCasesList: true, isCaseListFound: true })
            }
        })
    }
    onListItemClick(li) {
        // console.log(li)
        this.props.openFrame(li)
        console.log(li)
    }
    render() {
        return (
            <>
                        {
                            this.state.isComponentRender ?
                                this.state.isDataFound &&  this.props.status.currstatus != undefined && this.props.status.currstatus[0][""] != null?
                                    !this.props.status.currstatus[0][""].toLowerCase().includes('challenged') ?
                                        <div className="row" style={{background: this.state.bannercolor}}>
                                        
                                                <div className="col-3" style={{textAlign: 'center',color: 'white'}}>
                                                    {
                                                        this.props.status.previous != undefined && this.props.status.previous[0][""] !=null &&
                                                        <Tooltip title="View Previous Case">
                                                            <span style ={{fontSize: '23px',cursor: 'pointer'}} onClick={() => this.state.previous != '' && this.onStatusClick(this.state.previous, 0)}>&lt;&lt;</span>
                                                            </Tooltip>
                                                    }
                                                </div>
                                                <div className="col-3" style={{textAlign: 'center',color: 'white'}}>
                                                    {
                                                        this.state.referedFilesList.length > 0 &&
                                                        <Tooltip title="View Referred Case">
                                                            <span style ={{fontSize: '20px',cursor: 'pointer'}} onClick={() => this.state.referedFilesList.length > 0 && this.onStatusClick(this.state.referedFilesList, 1)}>Referred In {`(${this.state.referedFilesList.length})`}</span>
                                                        </Tooltip>  
                                                    }
                                                </div>                                        
                                                <div className="col-3" style={{textAlign: 'center',color: 'white'}}>
                                                    {
                                                        this.state.citedFilesList.length > 0 &&
                                                        <Tooltip title="View Cited Cases">
                                                            <span style ={{fontSize: '20px',cursor: 'pointer'}} onClick={() => this.state.citedFilesList.length > 0 && this.onStatusClick(this.state.citedFilesList, 1)}>Cited Cases {`(${this.state.citedFilesList.length})`}</span>
                                                        </Tooltip>
                                                    }
                                                </div>
                                                <div className="col-3" style={{textAlign: 'center',color: 'white'}}>
                                                    {
                                                        this.props.status.next != undefined && this.props.status.next[0][""] !=null &&
                                                        <Tooltip title={this.props.status.currstatus[0][""]}>
                                                            <span style ={{fontSize: '23px',cursor: 'pointer'}} onClick={() => this.state.next != '' && this.onStatusClick(this.state.next, 0)}>&gt;&gt;</span>
                                                        </Tooltip>
                                                    }
                                                </div>
                                        </div>
                                        :
                                        <div className="row" style={{background: this.state.bannercolor}}>
                                            <div className="col-4" style={{textAlign: 'center',color: 'white'}}></div>
                                            <div className="col-4" style={{textAlign: 'center',color: 'crimson'}}>
                                                    {this.props.status.currstatus[0][""]} 
                                            </div>                                        
                                            <div className="col-4" style={{textAlign: 'center',color: 'white'}}></div>
                                        </div>
                                    :
                                    <div></div>
                                :
                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: '100%', minHeight: "50vh" }}>
                                    <Spinner animation="border" variant="dark" />
                                </div>
                        }
                <Modal id={'cases_list_modal'} size="lg" centered style={{ backgroundColor: 'rgba(0,0,0,0.6)' }} show={this.state.isOpenCasesList} onHide={() => this.setState({ isOpenCasesList: false })} keyboard={false} backdrop='static'>
                    <Modal.Header>
                        <div className="header">
                            <span>Cases</span>
                            <IconButton onClick={() => this.setState({ isOpenCasesList: false })}>
                                <CloseIcon />
                            </IconButton>
                        </div>
                    </Modal.Header>
                    <Modal.Body style={{ paddingTop: "0px" }}>
                        <div className="status_main_boxy">
                            {
                                this.state.isCaseListFound &&
                                <div className="descBox">
                                    <div className="listBox">
                                            <span>Description</span>
                                        {
                                            this.state.descriptionList.map((list, index) => {
                                                return (
                                                        <span onClick={() => this.onListItemClick(list)}>{list.description}</span>


                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            }
                        </div>
                    </Modal.Body>
                </Modal>
            </>
        )
    }
}
